import React, { useEffect } from 'react';
import { Button, Col, ListGroup, ListGroupItem, Row } from 'react-bootstrap';
import { useLocation } from 'wouter';
import { formatCode } from '../common/location/formatCode';
import formatPath from '../common/location/formatPath';
import artikelCode from '../common/logic/artikelCode';
import useLocations from '../hooks/useLocations';
import { Stock } from '../modules/locations';
import { resolveHeaders, resolveUrl } from '../utils';
import Spinner from './Spinner';
import { makeTreeIndex } from './Tree/common';

const useArtikelInfo = (recnum: number) => {
  const [artikelInfo, setArtikelInfo] = React.useState<null | any>(null);
  const [image, setImage] = React.useState<'no-image' | Blob | null>(null);
  const [imageUrl, setImageUrl] = React.useState<string | null>(null);

  React.useEffect(() => {
    const fetchArtikelInfo = async () => {
      fetch(resolveUrl(`/artikelinfo/${recnum}`), {
        headers: resolveHeaders()(),
      })
        .then(res => res.json())
        .then(setArtikelInfo);
      fetch(resolveUrl(`/artikelimage/${recnum}`), {
        headers: resolveHeaders()(),
      }).then(async res =>
        res.ok ? res.blob().then(setImage) : setImage('no-image'),
      );
    };

    fetchArtikelInfo();
  }, [recnum]);

  useEffect(() => {
    if (image && image instanceof Blob) {
      const url = URL.createObjectURL(image);
      setImageUrl(url);
      return () => {
        URL.revokeObjectURL(url);
      };
    }
  }, [image]);

  return [artikelInfo, imageUrl] as const;
};

interface StockDetailsProps {
  stock: Stock;
  showLocations?: boolean;
  onBack: () => void;
}

const StockDetails: React.FC<StockDetailsProps> = ({
  stock,
  onBack,
  showLocations = true,
}) => {
  const [artikelInfo, image] = useArtikelInfo(stock.recnum);
  const rootLocations = useLocations();
  const allLocations = React.useMemo(() => makeTreeIndex(rootLocations), [
    rootLocations,
  ]);
  const locations = React.useMemo(
    () =>
      Object.values(allLocations).filter(l =>
        l!.stock?.some(s => s.recnum === stock.recnum),
      ),
    [allLocations, stock.recnum],
  );

  const location = allLocations[stock.location_id];

  const [, goTo] = useLocation();

  return (
    <div className="container">
      <Row>
        <Col md={12}>
          {/* fixme: this button is a ui antipattern */}
          <Button bsStyle="link" /* className="pull-left" */ onClick={onBack}>
            <i className="fa fa-angle-left"></i> back
          </Button>
          <h4>Artikel Information</h4>
          <dl className="dl-horizontal">
            <dt>Code:</dt>
            <dd>{artikelCode(stock)}</dd>
            <dt>Description:</dt>
            <dd>{stock.description}</dd>
            <dt>Unit:</dt>
            <dd>{stock.unit}</dd>
            <dt>Location:</dt>
            <dd>{stock.location_id}</dd>
            <dd>
              {/* TODO: centrally implement to location description logic for consistency  */}
              {location.name_replaces_path
                ? location.description
                : formatPath(location).join(' / ')}{' '}
              ({formatCode(location).join('') || 'geen locatie code'})
            </dd>
            <dt>Weight:</dt>
            <dd>{artikelInfo?.weight || '-'}</dd>
            <dt>Subset Description:</dt>
            <dd>{stock.subset_description}</dd>
          </dl>
        </Col>
      </Row>

      {showLocations && (
        <Row>
          <Col md={12}>
            <h4>Inventory Locations</h4>
            {locations.length === 0 && (
              <dl className="dl-horizontal">
                <dt></dt>
                <dd>
                  <small>no locations</small>
                </dd>
              </dl>
            )}
            <ListGroup>
              {locations.map(l => (
                <ListGroupItem
                  key={l.id}
                  onClick={() => goTo(`/inventory/${l.id}`)}
                >
                  {formatPath(l).join(' / ')} ({formatCode(l).join('')})
                  <i className="pull-right fa fa-angle-right"></i>
                </ListGroupItem>
              ))}
            </ListGroup>
          </Col>
        </Row>
      )}
      <Row>
        <Col md={12}>
          <h4>Item Group Information</h4>
          {artikelInfo?.itemgroup ? (
            <>
              <dl className="dl-horizontal">
                <dt>Description:</dt>
                <dd>{artikelInfo.itemgroup.description}</dd>
                <dt>HS Code:</dt>
                <dd>{artikelInfo.itemgroup.hscode}</dd>
                <dt>Function:</dt>
                <dd>
                  {artikelInfo.itemgroup.function || <small>no function</small>}
                </dd>
                <dt>Dry:</dt>
                <dd>{artikelInfo.itemgroup.dry ? 'Yes' : 'No'}</dd>
                <dt>Shade:</dt>
                <dd>{artikelInfo.itemgroup.shade ? 'Yes' : 'No'}</dd>
                <dt>Above Zero:</dt>
                <dd>{artikelInfo.itemgroup.abovezero ? 'Yes' : 'No'}</dd>
                <dt>Fragile:</dt>
                <dd>{artikelInfo.itemgroup.fragile ? 'Yes' : 'No'}</dd>
              </dl>
              {!image ? (
                <Spinner />
              ) : image === 'no-image' ? (
                <small>no image</small>
              ) : (
                <img
                  className="img-responsive img-thumbnail"
                  src={image}
                  alt="Item Group Image"
                />
              )}
            </>
          ) : (
            <dl className="dl-horizontal">
              <dt></dt>
              <dd>
                <small>no itemgroup</small>
              </dd>
            </dl>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default StockDetails;
