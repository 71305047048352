import Types from 'Types';
import { useSelector } from 'react-redux';
import { ExcludeNull } from '../common/logic/types';
import { StoreTicket } from '../modules/tickets';
import { UsersState } from '../modules/users';
import { mapByNumber } from '../utils';

export function useDevProjectContext(): [
  ExcludeNull<UsersState>,
  Record<string, StoreTicket>,
  Record<number, StoreTicket>,
] {
  return useSelector<
    Types.RootState,
    [
      ExcludeNull<UsersState>,
      Record<string, StoreTicket>,
      Record<number, StoreTicket>,
    ]
  >(s => {
    const projectRecnum: number = parseInt(
      process.env.REACT_APP_BUG_REPORT_PROJECT_ID!,
    );

    const workIds: number[] = Object.values(s.works || {})
      .filter(w => w.project_id === projectRecnum)
      .map(w => w.recnum);

    const localTickets: Record<number, StoreTicket> = mapByNumber(
      Object.values<StoreTicket>(s.tickets || {}).filter(
        (t: any) =>
          t.project_id === projectRecnum || workIds.includes(t.werk_id!),
      ),
    );
    return [s.users || {}, s.tickets || {}, localTickets || {}];
  });
}
