import { softAssertNever } from './utils';

interface CatalogUIToggleGroupAction {
  type: 'TOGGLE_CATALOG_GROUP_CLOSED';
  payload: { catalogId: number; groupId: number; operation: boolean | null };
}
export function toggleCatalogGroup(
  catalogId: number,
  groupId: number,
  closed: boolean | null = null,
): CatalogUIToggleGroupAction {
  return {
    type: 'TOGGLE_CATALOG_GROUP_CLOSED',
    payload: {
      catalogId,
      groupId,
      operation: closed,
    },
  };
}

interface CatalogUIToggleFileAction {
  type: 'TOGGLE_CATALOG_FILE';
  payload: {
    catalogId: number;
    fileId: number;
    operation: boolean | null;
  };
}
export function toggleCatalogFile(
  catalogId: number,
  fileId: number,
  expanded: boolean | null = null,
): CatalogUIToggleFileAction {
  return {
    type: 'TOGGLE_CATALOG_FILE',
    payload: {
      catalogId,
      fileId,
      operation: expanded,
    },
  };
}

interface CatalogUISetClosedGroupsAction {
  type: 'CATALOG_UI_SET_CLOSED_GROUPS';
  payload: {
    catalogId: number;
    closedGroups: number[];
  };
}

export function setClosedCatalogGroups(
  catalogId: number,
  closedGroups: number[],
): CatalogUISetClosedGroupsAction {
  return {
    type: 'CATALOG_UI_SET_CLOSED_GROUPS',
    payload: {
      catalogId,
      closedGroups,
    },
  };
}

interface CatalogUIResetAction {
  type: 'CATALOG_UI_RESET';
  payload: number;
}

export function resetCatalog(catalogId: number): CatalogUIResetAction {
  return {
    type: 'CATALOG_UI_RESET',
    payload: catalogId,
  };
}

export function getCatalogUIFromState(
  state: CatalogUIState,
  catalogId: number | undefined,
): CatalogUI {
  const ui = (catalogId && state[catalogId]) || undefined;
  if (ui) {
    return ui;
  }
  return { closedGroups: [], expandedFile: null };
}

type CatalogUIAction =
  | CatalogUIToggleGroupAction
  | CatalogUIToggleFileAction
  | CatalogUISetClosedGroupsAction
  | CatalogUIResetAction;

export interface CatalogUI {
  // multiple groups van be open/closed
  // groups are open by default
  closedGroups: number[];
  // only one file can be open
  /**
   * @deprecated
   */
  // TODO: remove from codebase
  expandedFile: number | null;
}

export interface CatalogUIState {
  [catalogId: string]: CatalogUI | undefined;
}

function patchCatalogUI(
  catalogId: number,
  state: CatalogUIState,
  patch: Partial<CatalogUI>,
): CatalogUIState {
  return {
    ...state,
    [catalogId]: {
      ...getCatalogUIFromState(state, catalogId),
      ...patch,
    },
  };
}

export default function catalogUI(
  state: CatalogUIState = {},
  action: CatalogUIAction,
): CatalogUIState {
  if (action.type === 'TOGGLE_CATALOG_FILE') {
    const { operation, fileId, catalogId } = action.payload;
    const expandedFile = state[catalogId]?.expandedFile || null;
    let newExpandedFile = expandedFile;

    if (operation === true) {
      newExpandedFile = fileId;
    } else if (operation === false) {
      newExpandedFile = null;
    } else {
      newExpandedFile = newExpandedFile === fileId ? null : fileId; // toggle state
    }

    // PERF: dont create new state if value is unchanged
    if (newExpandedFile === expandedFile) {
      return state;
    }
    return patchCatalogUI(catalogId, state, { expandedFile: newExpandedFile });
  } else if (action.type === 'TOGGLE_CATALOG_GROUP_CLOSED') {
    const { groupId, operation, catalogId } = action.payload;
    const isClosed = !!state[catalogId]?.closedGroups.includes(groupId);
    const closedGroups = state[catalogId]?.closedGroups || [];
    let newClosedGroups = closedGroups;
    if (
      (operation === true && !isClosed) ||
      (operation === null && !isClosed)
    ) {
      newClosedGroups = [...newClosedGroups, groupId];
    } else if (
      operation === false ||
      isClosed ||
      (operation === null && isClosed)
    ) {
      newClosedGroups = newClosedGroups.filter(g => g !== groupId);
    }
    // PERF: dont create new state if value is unchanged
    if (closedGroups === newClosedGroups) {
      return state;
    }
    return patchCatalogUI(catalogId, state, { closedGroups: newClosedGroups });
  } else if (action.type === 'CATALOG_UI_SET_CLOSED_GROUPS') {
    const { catalogId, closedGroups } = action.payload;
    return patchCatalogUI(catalogId, state, { closedGroups });
  } else if (action.type === 'CATALOG_UI_RESET') {
    const catalogId = action.payload;
    const newState = { ...state };
    delete newState[catalogId];
    return newState;
  }
  softAssertNever(action);
  return state;
}
