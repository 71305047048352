import * as React from 'react';
import { Alert } from 'react-bootstrap';
import { Link } from 'wouter';
import Account, { loginnameValidator } from '../../models/Account';
import FormattedRelative from '../FormattedRelative';
import Page from '../Page';
import ParameterizedSearch from '../ParameterizedSearch';
import Spinner from '../Spinner';

interface Props {
  accounts: Account[] | null;
  err: Error | null;
  filter: string;
  setFilter: (f: string) => void;
}

const AccountOverviewComponent: React.FC<Props> = props => {
  const { accounts, err, filter, setFilter } = props;
  let content = <Spinner></Spinner>;
  if (accounts || err) {
    content = (
      <>
        {err && (
          <Alert bsStyle="danger">Could not load accounts: {err.message}</Alert>
        )}
        <ParameterizedSearch
          options={{
            parameters: {},
            small: false,
            noInput: false,
          }}
          value={filter}
          onChange={setFilter}
        />
        {accounts && (
          <table className="table table-striped table-hover ">
            <thead>
              <tr>
                <td colSpan={7}>General</td>
                <td colSpan={5}>Security</td>
                <td>note</td>
              </tr>
              <tr>
                <td>Id</td>
                <td>Relat</td>
                <td>Contact</td>
                <td>loginname</td>
                <td>Name</td>
                <td>email</td>
                <td>department</td>
                <td>
                  pass strength (0-4){' '}
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://lowe.github.io/tryzxcvbn/"
                  >
                    zxcvbn
                  </a>
                </td>
                <td>last login</td>
                <td>failed attempts</td>
                <td>activated</td>
                <td>internal</td>
                <td></td>
              </tr>
            </thead>
            <tbody></tbody>
            <tfoot>
              {accounts.map(a => (
                <tr key={a.recnum}>
                  <td>{a.recnum}</td>
                  <td>{a.relat}</td>
                  <td>{a.contact}</td>
                  <td
                    className={
                      !a.loginname.trim()
                        ? ''
                        : loginnameValidator.test(a.loginname)
                        ? 'ok'
                        : 'danger'
                    }
                  >
                    <Link to={`/accounts/${a.recnum}`}>
                      {a.loginname.trim() ? (
                        '@' + a.loginname
                      ) : (
                        <small style={{ color: 'grey' }}>no loginname</small>
                      )}
                    </Link>
                  </td>
                  <td>{a.name || ' - '}</td>
                  <td>{a.email}</td>
                  <td>{a.department}</td>
                  <td
                    className={
                      { 0: 'danger', 1: 'danger', 2: 'warning', 4: 'success' }[
                        a.password_strength as 0 | 1 | 2 | 4
                      ]
                    }
                  >
                    {a.password_strength !== null ? a.password_strength : ' - '}
                  </td>
                  <td>
                    {a.last_successful_login ? (
                      <FormattedRelative value={a.last_successful_login} />
                    ) : (
                      ' - '
                    )}
                  </td>
                  <td
                    className={
                      a.auth_attempts > 10
                        ? 'danger'
                        : a.auth_attempts > 3
                        ? 'warning'
                        : undefined
                    }
                  >
                    {a.auth_attempts || 0}
                  </td>
                  <td className={a.activated ? 'text-success' : 'text-danger'}>
                    {a.activated ? '✔' : '✗'}
                  </td>
                  <td className={a.internal ? 'text-success' : 'text-danger'}>
                    {a.internal ? '✔' : '✗'}
                  </td>
                  <td>
                    <em>{a.note}</em>
                  </td>
                </tr>
              ))}
              <tr></tr>
            </tfoot>
          </table>
        )}
      </>
    );
  }
  return (
    <Page fluid title="Accounts">
      {content}
      {/* <pre>{JSON.stringify(props, null, 2)}</pre> */}
    </Page>
  );
};

export default AccountOverviewComponent;
