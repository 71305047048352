/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { Component } from 'react';
import Autocomplete from 'react-autocomplete';
import {
  Button,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  Modal,
} from 'react-bootstrap';
import DateTime from 'react-datetime';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { parametersToTags, tagOptions } from '../../../common/logic/list';
import matchUserToTerm from '../../../common/user/matchUserToTerm';
import sortUsers from '../../../common/user/sortUsers';
import { mapById } from '../../../utils';
import InputList from '../../InputList';
import ParameterizedSearch from '../../ParameterizedSearch/index';
import { parseParameters } from '../../ParameterizedSearch/model';
import styles from './ChecklistPropertyDialog.module.scss';

const msg = defineMessages({
  MODAL_TITLE: {
    id: 'LISTS_OVERVIEW_DIALOG.MODAL_TITLE',
    defaultMessage: 'List Properties',
    description: '',
  },
  LABEL_NAME: {
    id: 'LISTS_OVERVIEW_DIALOG.LABEL_NAME',
    defaultMessage: 'Name (english)',
    description: '',
  },
  LABEL_TEMPLATE: {
    id: 'LISTS_OVERVIEW_DIALOG.LABEL_TEMPLATE',
    defaultMessage: 'Template',
    description: '',
  },
  LABEL_USERS: {
    id: 'LISTS_OVERVIEW_DIALOG.ASSOCIATED_USERS',
    defaultMessage: 'Associated users',
    description: '',
  },
  LABEL_START_DATE: {
    id: 'LISTS_OVERVIEW_DIALOG.LABEL_START_DATE',
    defaultMessage: 'Start Date',
    description: '',
  },
  LABEL_END_DATE: {
    id: 'LISTS_OVERVIEW_DIALOG.LABEL_END_DATE',
    defaultMessage: 'End Date',
    description: '',
  },
  BUTTON_SAVE: {
    id: 'LISTS_OVERVIEW_DIALOG.BUTTON_SAVE',
    defaultMessage: 'Save',
    description: '',
  },

  BUTTON_CANCEL: {
    id: 'LISTS_OVERVIEW_DIALOG.BUTTON_CANCEL',
    defaultMessage: 'Cancel',
    description: '',
  },
  FILTER_LABEL_FILTER: {
    id: 'LISTS_OVERVIEW_DIALOG.FILTER_LABEL_FILTER',
    defaultMessage: 'Filters',
    description: '',
  },
  typeToSearch: {
    id: 'LISTS_OVERVIEW_DIALOG.USERS_INPUTLIST_PLACEHOLDER',
    defaultMessage: 'Start typing to search...',
  },
});

function matchTemplateToTerm({ name }, value) {
  return name.toUpperCase().includes(value.toUpperCase());
}

const listDefaults = {
  name: '',
  werk_id: null,
  template_id: null,
  associated_users: [],
  start_date: null,
  end_date: null,
};

const departmentMap = {
  V: 'climate',
  E: 'electrical',
  K: 'greenhouse',
  W: 'irrigation',
  S: 'service',
  C: 'climate-cells',
};

const parseDate = input => {
  if (input === null) {
    return null;
  }
  const result = new Date(input);
  if (isNaN(result.getTime())) {
    throw new Error('Could not parse input for parseDate');
  }
  return result;
};

const parseDates = R.evolve({
  start_date: parseDate,
  end_date: parseDate,
});

export default injectIntl(
  class ChecklistPropertyDialog extends Component {
    static propTypes = {
      list: PropTypes.object,
      lists: PropTypes.object.isRequired,
      works: PropTypes.object.isRequired,
      associated_users: PropTypes.object.isRequired,
      title: PropTypes.string,
      show: PropTypes.bool.isRequired,
      onSubmit: PropTypes.func.isRequired,
      onCancel: PropTypes.func,
      dispatch: PropTypes.func.isRequired,
    };

    static defaultProps = {
      show: false,
      title: null,
    };

    constructor(props) {
      super(props);

      const { list } = this.props;

      this.state = {
        templateName: '',
        list: {
          ...listDefaults,
          ...parseDates(list),
        },
      };
      this.state.filterTags = this.getFilterTags();
    }

    componentWillReceiveProps(nextProps) {
      if (nextProps.list !== this.props.list) {
        this.resetListWithProps(nextProps);
      } else if (
        nextProps.list !== this.props.list ||
        nextProps.works !== this.props.works
      ) {
        this.resetFilters();
      }
    }

    resetFilters() {
      this.setState({ filterTags: this.getFilterTags() });
    }

    resetListWithProps(props) {
      this.setState(
        {
          list: { ...listDefaults, ...parseDates(props.list) },
          templateName: '',
        },
        () => {
          this.setState({ filterTags: this.getFilterTags() });
        },
      );
    }

    getFilterTags() {
      const { works } = this.props;
      const { list } = this.state;

      const work = works[list.werk_id];
      if (work && departmentMap[work.afd]) {
        const department = departmentMap[work.afd];
        return `department:"${department}" label:"composed"`;
      }
      return 'label:"composed"';
    }

    focusInput(input) {
      // normal flow of using componentdidmount not working because
      // cdm is called before ref callback
      try {
        input.focus();
        input.setSelectionRange(0, this.nameInput.value.length);
        // tslint:disable-next-line
      } catch (e) {}
    }

    handleChange = event => {
      const { name, value } = event.target;
      this.setState({
        list: {
          ...this.state.list,
          [name]: value,
        },
      });
    };

    handleWorkChange = werk_id => {
      this.setState(
        {
          list: {
            ...this.state.list,
            werk_id,
          },
        },
        () => {
          this.setState({ filterTags: this.getFilterTags() });
        },
      );
    };

    handleTemplateSelect = (val, item) => {
      this.setState({
        list: {
          ...this.state.list,
          template_id: item.id,
        },
        templateName: val,
      });
    };

    handleAutocompleteChange = async event => {
      const { value, name } = event.target;
      this.setState({ [name]: value });
    };

    handleSelectUser = item => {
      const { list } = this.state;
      this.setState({
        list: {
          ...list,
          associated_users: [...list.associated_users, item.recnum],
        },
      });
    };

    handleDeselectUser = item => {
      const { list } = this.state;
      this.setState({
        list: {
          ...list,
          associated_users: R.without([item.recnum], list.associated_users),
        },
      });
    };

    handleCancel = (...args) => {
      this.resetFilters();
      this.resetListWithProps(this.props);
      this.props.onCancel(...args);
    };

    handleSubmit = event => {
      event.preventDefault();
      this.props.onSubmit(this.state.list);
    };

    renderInput = name => props => (
      <input key="input" {...props} name={name} className="form-control" />
    );

    renderMenu = children => <ul className="dropdown-menu">{children}</ul>;

    renderItem = (item, isHighlighted) => (
      <li className={`item ${isHighlighted ? styles.highlighted : ''}`}>
        <a>{item.name || item.id}</a>
      </li>
    );

    render() {
      const { show, title, lists, users, intl } = this.props;
      const { list, templateName } = this.state;
      let filteredTemplates = Object.values(lists)
        .filter(l => l.tags && l.tags.includes('template'))
        .filter(t => t.deleted === false);

      let filterTags = parametersToTags(parseParameters(this.state.filterTags));
      if (filterTags.length) {
        filteredTemplates = filteredTemplates.filter(t =>
          filterTags.every(tag => t.tags && t.tags.includes(tag)),
        );
      }

      const templateMap = mapById(filteredTemplates);
      const templateOk =
        list.id ||
        (list.template_id &&
          templateMap[list.template_id] &&
          templateMap[list.template_id].name === templateName);

      return (
        <Modal show={show}>
          <Modal.Header>
            {title || <FormattedMessage {...msg.MODAL_TITLE} />}
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={this.handleSubmit} id="checklistForm">
              <FormGroup controlId="name">
                <ControlLabel>
                  <FormattedMessage {...msg.LABEL_NAME} />
                </ControlLabel>
                <FormControl
                  name="name"
                  inputRef={this.focusInput}
                  type="text"
                  value={list.name}
                  placeholder="Lijst 1"
                  onChange={this.handleChange}
                />
                <FormControl.Feedback />
              </FormGroup>
              {// only render this when we're creating a new list
              !list.id && (
                <FormGroup
                  controlId="template"
                  validationState={(templateOk && 'success') || null}
                >
                  <ControlLabel>
                    <FormattedMessage {...msg.LABEL_TEMPLATE} />
                  </ControlLabel>
                  <div className={styles.filterTags}>
                    <span>
                      {' '}
                      <FormattedMessage {...msg.FILTER_LABEL_FILTER} />{' '}
                    </span>
                    <ParameterizedSearch
                      value={this.state.filterTags}
                      options={{ ...tagOptions, small: true, noInput: true }}
                      onChange={newFilterTags => {
                        this.setState({ filterTags: newFilterTags });
                      }}
                    />
                  </div>
                  <Autocomplete
                    value={templateName}
                    items={R.sortBy(R.prop('name'), filteredTemplates)}
                    onChange={this.handleAutocompleteChange}
                    onSelect={this.handleTemplateSelect}
                    getItemValue={item => item.name}
                    shouldItemRender={matchTemplateToTerm}
                    selectOnBlur
                    wrapperProps={{
                      className: 'dropdown open',
                      style: { display: 'block' },
                    }}
                    renderInput={this.renderInput('templateName')}
                    renderItem={this.renderItem}
                    renderMenu={this.renderMenu}
                  />
                  <FormControl.Feedback />
                </FormGroup>
              )}
              <FormGroup>
                <ControlLabel>
                  <FormattedMessage {...msg.LABEL_USERS} />
                </ControlLabel>
                <InputList
                  items={R.sortWith(sortUsers, Object.values(users))}
                  name={'associated_users'}
                  selectedItems={list.associated_users.map(id => users[id])}
                  shouldItemRender={matchUserToTerm}
                  getItemValue={user => user.recnum.toString()}
                  onSelectItem={this.handleSelectUser}
                  onDeselectItem={this.handleDeselectUser}
                  placeholder={intl.formatMessage(msg.typeToSearch)}
                />
              </FormGroup>
              <FormGroup>
                <ControlLabel>
                  <FormattedMessage {...msg.LABEL_START_DATE} />
                </ControlLabel>
                <DateTime
                  timeFormat={false}
                  dateFormat="D MMMM YYYY"
                  onChange={m =>
                    this.handleChange({
                      target: {
                        name: 'start_date',
                        value: (m && m.toDate && m.toDate()) || null,
                      },
                    })
                  }
                  name="start_date"
                  value={list.start_date}
                />
              </FormGroup>
              <FormGroup>
                <ControlLabel>
                  <FormattedMessage {...msg.LABEL_END_DATE} />
                </ControlLabel>
                <DateTime
                  timeFormat={false}
                  dateFormat="D MMMM YYYY"
                  onChange={m =>
                    this.handleChange({
                      target: {
                        name: 'end_date',
                        value: (m && m.toDate && m.toDate()) || null,
                      },
                    })
                  }
                  name="end_date"
                  value={list.end_date}
                />
              </FormGroup>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleCancel}>
              <FormattedMessage {...msg.BUTTON_CANCEL} />
            </Button>
            <Button
              form="checklistForm"
              type="submmit"
              bsStyle="primary"
              disabled={!list.werk_id || !templateOk || !list.name}
            >
              <FormattedMessage {...msg.BUTTON_SAVE} />
            </Button>
          </Modal.Footer>
        </Modal>
      );
    }
  },
);
