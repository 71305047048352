import { Label } from '@certhon/domain-models';
import { CALL_API } from 'redux-api-middleware';
import { mapByRecnum, resolveHeaders, resolveUrl } from '../utils';
import { softAssertNever } from './utils';

export interface RequestFetchLabelsAction {
  type: 'REQUEST_FETCH_LABELS';
}
export interface SuccessFetchLabelsAction {
  type: 'SUCCESS_FETCH_LABELS';
  payload: Label[];
}
export interface FailureFetchLabelsAction {
  type: 'FAILURE_FETCH_LABELS';
  error: true;
}

export function fetchLabels(): any {
  return {
    [CALL_API]: {
      endpoint: resolveUrl('/labels'),
      method: 'GET',
      headers: resolveHeaders(),
      types: [
        'REQUEST_FETCH_LABELS',
        'SUCCESS_FETCH_LABELS',
        'FAILURE_FETCH_LABELS',
      ],
    },
  };
}

export interface RequestPostLabelAction {
  type: 'REQUEST_POST_LABEL';
}
export interface SuccessPostLabelAction {
  type: 'SUCCESS_POST_LABEL';
  payload: Label;
}
export interface FailurePostLabelAction {
  type: 'FAILURE_POST_LABEL';
  error: true;
}

export function postLabel(label: Partial<Label>) {
  return {
    [CALL_API]: {
      endpoint: resolveUrl(`/labels`),
      method: 'POST',
      headers: resolveHeaders(),
      body: JSON.stringify(label),
      types: ['REQUEST_POST_LABEL', 'SUCCESS_POST_LABEL', 'FAILURE_POST_LABEL'],
    },
  };
}

export interface RequestPatchLabelAction {
  type: 'REQUEST_PATCH_LABEL';
}
export interface SuccessPatchLabelAction {
  type: 'SUCCESS_PATCH_LABEL';
  payload: Label;
}
export interface FailurePatchLabelAction {
  type: 'FAILURE_PATCH_LABEL';
  error: true;
}

export function patchLabel(labelOrRecnum: number | Label) {
  const recnum =
    typeof labelOrRecnum === 'number' ? labelOrRecnum : labelOrRecnum.recnum;
  return {
    [CALL_API]: {
      endpoint: resolveUrl(`/labels/${recnum}`),
      method: 'PATCH',
      headers: resolveHeaders(),
      body: JSON.stringify(labelOrRecnum),
      types: [
        'REQUEST_PATCH_LABEL',
        'SUCCESS_PATCH_LABEL',
        'FAILURE_PATCH_LABEL',
      ],
    },
  };
}

export type LabelsAction =
  | RequestFetchLabelsAction
  | SuccessFetchLabelsAction
  | FailureFetchLabelsAction
  | RequestPostLabelAction
  | SuccessPostLabelAction
  | FailurePostLabelAction
  | RequestPatchLabelAction
  | SuccessPatchLabelAction
  | FailurePatchLabelAction;

export type LabelsState = Record<number, Label> | null;

export default function labels(
  state: LabelsState = null,
  action: LabelsAction,
): LabelsState {
  if (action.type === 'SUCCESS_FETCH_LABELS') {
    return mapByRecnum(action.payload);
  } else if (
    action.type === 'SUCCESS_POST_LABEL' ||
    action.type === 'SUCCESS_PATCH_LABEL'
  ) {
    const label = action.payload;
    return { ...state, [label.recnum]: label };
  } else if (
    action.type === 'REQUEST_FETCH_LABELS' ||
    action.type === 'FAILURE_FETCH_LABELS' ||
    action.type === 'REQUEST_POST_LABEL' ||
    action.type === 'FAILURE_POST_LABEL' ||
    action.type === 'REQUEST_PATCH_LABEL' ||
    action.type === 'FAILURE_PATCH_LABEL'
  ) {
    return state;
  }
  softAssertNever(action);
  return state;
}
