import { assertNever } from '../../../../modules/utils';
import { Geotag, GeotagAttachment } from '../../types';
import {
  createModificationCreator,
  ListEventReducer,
} from '../ListModification';
import {
  patchListNode,
  transferModificationPropertiesToAttachment,
} from '../util';

export interface AddGeotagPayload {
  geotag: Geotag;
  targetNodeId: string;
}
export interface EditGeotagPayload {
  geotag: Partial<Geotag>;
  targetNodeId: string;
  geotagId: string;
}
export interface RemoveGeotagPayload {
  targetNodeId: string;
  geotagId: string;
}

export const addGeotag = createModificationCreator<
  AddGeotagPayload,
  'ADD_GEOTAG'
>('ADD_GEOTAG');
export const editGeotag = createModificationCreator<
  EditGeotagPayload,
  'EDIT_GEOTAG'
>('EDIT_GEOTAG');
export const removeGeotag = createModificationCreator<
  RemoveGeotagPayload,
  'REMOVE_GEOTAG'
>('REMOVE_GEOTAG');

export type AddGeotagListModification = ReturnType<typeof addGeotag>;
export type EditGeotagListModification = ReturnType<typeof editGeotag>;
export type RemoveGeotagListModification = ReturnType<typeof removeGeotag>;

export type GeotagListModification =
  | AddGeotagListModification
  | EditGeotagListModification
  | RemoveGeotagListModification;

const geotag: ListEventReducer<GeotagListModification> = (
  list,
  mod: GeotagListModification,
) =>
  patchListNode(list, mod.payload.targetNodeId, targetNode => {
    if (targetNode.type === 'ROOT_FIELD' || targetNode.type === 'GROUP_FIELD') {
      throw new Error(
        `Unexpected non-value targetNode type (${targetNode.type}).`,
      );
    }
    if (mod.type === 'ADD_GEOTAG') {
      const geotagAttachment: GeotagAttachment = {
        type: 'geotag',
        geotag: mod.payload.geotag,
        ...transferModificationPropertiesToAttachment(mod),
      };
      return {
        ...targetNode,
        attachments: [...targetNode.attachments, geotagAttachment],
      };
    } else if (mod.type === 'EDIT_GEOTAG') {
      return {
        ...targetNode,
        attachments: targetNode.attachments.map(attachment => {
          if (
            attachment.id === mod.payload.geotagId &&
            attachment.type === 'geotag'
          ) {
            return {
              ...attachment,
              geotag: {
                ...attachment.geotag,
                ...mod.payload.geotag,
              },
            };
          }
          return attachment;
        }),
      };
    } else if (mod.type === 'REMOVE_GEOTAG') {
      return {
        ...targetNode,
        attachments: targetNode.attachments.filter(
          att => att.id !== mod.payload.geotagId,
        ),
      };
    }
    assertNever(mod);
    throw new Error();
  });

export default geotag;
