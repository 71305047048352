import React from 'react';
import { connect } from 'react-redux';
import useInitialDispatch from '../utils/useInitalDispatch';
import Types from 'Types';
import {
  fetchLabels,
  LabelsState,
  postLabel,
  patchLabel,
} from '../modules/labels';
import LabelsOverviewPage from '../components/LabelsOverviewPage';

export interface LabelEditing {
  postLabel: typeof postLabel;
  patchLabel: typeof patchLabel;
}

interface Props extends LabelEditing {
  labels: LabelsState;
}

const LabelsOverviewContainer: React.FC<Props> = ({ labels, ...rest }) => {
  useInitialDispatch(fetchLabels);

  return <LabelsOverviewPage labels={labels} {...rest} />;
};

function mapStateToProps(state: Types.RootState) {
  return {
    labels: state.labels,
  };
}

export default connect(mapStateToProps, { postLabel, patchLabel })(
  LabelsOverviewContainer,
);
