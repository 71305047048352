import * as React from 'react';
import { useSelector } from 'react-redux';
import Types from 'Types';
import { ProjectState } from '../../modules/projects';
import { WorkState, Work } from '../../modules/works';
import WorkSelectionComponent, { DisplayProps } from './WorkSelectionComponent';

interface Selection {
  projectId: number | null;
  workId: number | null;
}

interface Props extends DisplayProps {
  selection: Selection;
  onSelectionChanged: (selection: Selection) => void;
  disabled?: boolean;
}

const WorkSelectionControl: React.FC<Props> = ({
  selection,
  disabled = false,
  onSelectionChanged,
}) => {
  const { projectId, workId } = selection || {};
  const projects = useSelector<Types.RootState, ProjectState>(s => s.projects);
  const works = useSelector<Types.RootState, WorkState>(s => s.works);
  const [workValue, setWorkValue] = React.useState<string>(
    () => (workId ? works?.[workId]?.id : '') || '',
  );
  const [projectValue, setProjectValue] = React.useState(() => {
    const pId = workId ? works?.[workId]?.project_id : projectId;
    return (pId ? projects?.[pId]?.projectname : '') || '';
  });

  React.useEffect(() => {
    if (projects && works) {
      const projectId =
        Object.values(projects).find(p => p?.projectname === projectValue)
          ?.recnum || null;
      const workId =
        (projectId &&
          Object.values(works).find(
            w => w?.id === workValue && w.project_id === projectId,
          )?.recnum) ||
        null;
      if (projectId !== selection.projectId || workId !== selection.workId) {
        onSelectionChanged({ projectId, workId });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workValue, projectValue, projects, works]);
  const filterredWorks = React.useMemo<Work[]>(() => {
    let rv: Work[] = Object.values(works as any);
    if (projectId) {
      rv = rv.filter(w => w.project_id === projectId);
    }
    return rv;
  }, [projectId, works]);

  return (
    <WorkSelectionComponent
      disabled={disabled}
      projects={Object.values(projects as any)}
      works={filterredWorks}
      projectValue={projectValue}
      workValue={workValue}
      projectOk={!!selection.projectId}
      workOk={!!selection.workId}
      onProjectValueChanged={setProjectValue}
      onWorkValueChanged={setWorkValue}
    />
  );
};

export default WorkSelectionControl;
