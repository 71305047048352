import * as React from 'react';
import { Breadcrumb, BreadcrumbItem } from 'react-bootstrap';
import { useMobileFlag } from '../../hooks/useMobileFlag';
import ErrorBoundry from '../Errorboundry';
import LinkContainer from '../LinkContainer';
import stl from './Page.module.scss';

interface ColumnWrapperProps {
  path: {
    title: string;
    uriPath: string;
  }[];
}
/** internal wrapper */
const ColumnWrapper: React.FC<React.PropsWithChildren<ColumnWrapperProps>> = ({
  children,
  path,
}) => {
  return (
    <div className={stl.column}>
      <div className={stl.inner}>
        <Breadcrumb className="visible-xs">
          {path.map((p, idx) =>
            idx === path.length - 1 ? (
              <BreadcrumbItem key={idx} active={true}>
                {p.title}
              </BreadcrumbItem>
            ) : (
              <LinkContainer key={idx} to={p.uriPath}>
                <BreadcrumbItem>{p.title}</BreadcrumbItem>
              </LinkContainer>
            ),
          )}
        </Breadcrumb>
        {children}
      </div>
    </div>
  );
};

interface ColumnProps {
  title: string;
  uriPath: string;
}
/**
 * Provides required props for Columns
 * additionally adds an error boundary
 */
export const Column: React.FC<React.PropsWithChildren<ColumnProps>> = ({
  children,
}) => {
  return <ErrorBoundry>{children}</ErrorBoundry>;
};

export interface ColumnsProps {
  children:
    | React.ReactElement<ColumnProps, typeof Column>[]
    | React.ReactElement<ColumnProps, typeof Column>;
  actions?: [string, string][];
}

/**
 * component to encapsulate column's
 * provides functionality to render one or more columns based on screen size
 * */
export const Columns: React.FC<ColumnsProps> = ({ children, actions }) => {
  const isMobile = useMobileFlag();
  let columns = Array.isArray(children) ? children : [children];

  let paths = columns.map(({ props: { title, uriPath } }) => ({
    title,
    uriPath,
  }));

  columns = columns.map((column, idx) => {
    const path = paths.slice(0, idx + 1);
    return <ColumnWrapper path={path}>{column}</ColumnWrapper>;
  });

  columns = isMobile ? columns.slice(-1) : columns;

  return <div className={stl.columnLayout}>{columns}</div>;
};
