import classNames from 'classnames';
import * as React from 'react';
import { Dispatch } from 'redux';
import { Link } from 'wouter';
import Meeting, { MeetingSection } from '../../models/Meeting';
import {
  addMeetingSection,
  updateMeetingSection,
} from '../../modules/meetings';
import { UserState } from '../../modules/user';
import { UsersState } from '../../modules/users';
import stl from './Minutes.module.scss';
import MinuteSheetSection from './MinuteSheetSection';

export interface MinuteSheetProps {
  meeting: Meeting;
  user: UserState;
  tickets: any;
  users: UsersState;
  dispatch: Dispatch;
  hasPreviousMeeting: boolean;
  hasBegun: boolean;
  onInsertSections: () => void;
}

export default class MinuteSheet extends React.Component<MinuteSheetProps> {
  // handleChange = (event: React.FormEvent<HTMLDivElement>) => {
  //   event.persist();
  //   // tslint:disable-next-line: no-console
  //   console.log({ event });
  // };

  handleSectionChange = (
    idx: number,
    partialSection: Partial<MeetingSection>,
  ) => {
    const { user } = this.props;
    if (!user) {
      throw new Error('expected user');
    }

    this.props.dispatch(
      updateMeetingSection({
        meetingId: this.props.meeting.id,
        idx,
        partialSection,
        editor: user.recnum,
        edited: new Date(),
      }),
    );
  };
  handleAddSection = () => {
    const { user } = this.props;
    if (!user) {
      throw new Error('expected user');
    }
    const title = prompt('Section title');
    if (title) {
      this.props.dispatch(
        addMeetingSection({
          meetingId: this.props.meeting.id,
          section: {
            title,
            content: [],
            ticketReferences: [],
            ticketDiscipline: null,
            ticketRemainder: false,
            ticketTags: [],
          },
          editor: user.recnum,
          edited: new Date(),
        }),
      );
    }
  };
  render() {
    const sectionElements = this.props.meeting.sections.map((s, idx) => (
      <MinuteSheetSection
        key={idx}
        workId={this.props.meeting.werk_id}
        projectId={this.props.meeting.project_id}
        index={idx}
        user={this.props.user}
        users={this.props.users}
        dispatch={this.props.dispatch}
        {...s}
        tickets={this.props.tickets}
        onChange={this.handleSectionChange}
        meetingId={this.props.meeting.id}
        meetingDate={this.props.meeting.date}
      />
    ));
    return (
      <div className={stl.sheet}>
        {sectionElements}
        <button
          onClick={this.handleAddSection}
          className="btn btn-link hidden-print"
        >
          <i className="fa fa-plus" />
          &nbsp;New section
        </button>
        {!this.props.hasBegun && (
          <div className={stl.overlay}>
            <div className={stl.blur}>
              <div className={stl.frame}>
                <button
                  className={classNames(stl.start, 'btn btn-primary')}
                  onClick={this.props.onInsertSections}
                >
                  Start meeting
                </button>
                <Link to="/docs/4-meetings">
                  Learn more about meetings{' '}
                  <i className="fa fa-info-circle"></i>
                </Link>
              </div>
            </div>
            );
          </div>
        )}
      </div>
    );
  }
}
