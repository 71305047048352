import * as React from 'react';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import Types from 'Types';
import { StoreTicket } from '../../modules/tickets';
import { UserState } from '../../modules/user';
import DownloadButton from '../DownloadButton';
import msg from './msg';
interface TicketDetailsDropdownMenuProps {
  onEdit: () => void;
  onToggleNotifications: () => void;
  ticket: StoreTicket;
}
const TicketDetailsDropdownMenu: React.FC<TicketDetailsDropdownMenuProps> = ({
  ticket,
  onEdit,
  onToggleNotifications,
}) => {
  const user = useSelector<Types.RootState, UserState>(({ user }) => user);

  return (
    <DropdownButton pullRight title={<i className="fa fa-bars" />} id="actions">
      <DownloadButton
        component={MenuItem}
        showLabelXs
        downloadUri={`/api/tickets/${ticket.recnum}/report`}
      />
      <MenuItem disabled={!ticket.events} onClick={onEdit}>
        <i className="fa fa-pencil" /> <FormattedMessage {...msg.EDIT_TICKET} />
      </MenuItem>
      <MenuItem onClick={onToggleNotifications}>
        {ticket.notification_opt_in?.includes(user!.recnum) ? (
          <>
            <i className="fa fa-bell-slash"></i> Turn notifications off
          </>
        ) : (
          <>
            <i className="fa fa-bell"></i> Turn notifications on
          </>
        )}
      </MenuItem>
    </DropdownButton>
  );
};

export default TicketDetailsDropdownMenu;
