import { prop } from 'ramda';
import * as React from 'react';
import Autocomplete from 'react-autocomplete';
import { findDOMNode } from 'react-dom';
import { detectExtraBackspace } from './detectExtraBackspace';
import { renderItem, renderMenu } from './index';
import styles from './ParameterizedSearch.module.scss';

export interface ParameterAutocompleteProps {
  value: string;
  onExtraBackspace: () => void;
  focusOnMount: boolean;
  placeholder?: string;
  onFocus: () => void;
  onBlur: () => void;
  // parameter: Parameter;
  // options: ParameterOptions[];
}
export default class ParameterAutocomplete extends React.Component<
  ParameterAutocompleteProps &
    Partial<Autocomplete.Props> &
    Pick<Autocomplete.Props, 'items'>
> {
  handleKeyDown = detectExtraBackspace(() => this.props.onExtraBackspace());

  componentDidMount() {
    if (this.props.focusOnMount) {
      const node = findDOMNode(this) as Element;
      (node.querySelector('input') as HTMLInputElement).focus();
    }
  }

  render() {
    const { placeholder, onFocus, onBlur, value, ...rest } = this.props;
    return (
      <div className={styles.input}>
        <Autocomplete
          {...rest}
          value={value}
          renderMenu={renderMenu}
          wrapperProps={{ className: 'dropdown open ' + styles.wrapper }}
          getItemValue={prop('name')}
          renderItem={renderItem}
          inputProps={{
            size: 1,
            onFocus,
            onBlur,
            placeholder,
            onKeyDown: this.handleKeyDown,
          }}
        />
      </div>
    );
  }
}
