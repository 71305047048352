import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { QRScanner } from './QRScanner';

interface QRScannerModalProps {}

export interface QRScannerModalRef {
  scanQRCode: () => Promise<string | null>;
}

const QRScannerModal = React.forwardRef<QRScannerModalRef, QRScannerModalProps>(
  (props, ref) => {
    // Add state for promise callbacks
    const [resolveCallback, setResolveCallback] = React.useState<
      ((value: string | null) => void) | null
    >(null);
    const [rejectCallback, setRejectCallback] = React.useState<
      ((reason?: any) => void) | null
    >(null);

    React.useImperativeHandle(ref, () => ({
      scanQRCode: () => {
        return new Promise<string | null>((resolve, reject) => {
          setResolveCallback(() => resolve);
          setRejectCallback(() => reject);
        });
      },
    }));

    const handleClose = () => {
      resolveCallback?.(null);
      setRejectCallback(null);
      setResolveCallback(null);
    };

    const handleScan = (data: string) => {
      resolveCallback?.(data);
      setRejectCallback(null);
      setResolveCallback(null);
    };
    return (
      <>
        <Modal show={!!resolveCallback} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Scan QRCode</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <QRScanner onScan={handleScan} />
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleClose}>Cancel</Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  },
);

export default QRScannerModal;
