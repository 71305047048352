import { defineMessages } from 'react-intl';
export const msg = defineMessages({
  TEMPLATE_HAS_UNSAVED_CHANGES: {
    id: 'TEMPLATE_DETAILS.TEMPLATE_HAS_UNSAVED_CHANGES',
    defaultMessage: 'This template contains unsaved changes',
    description: '',
  },
  TEMPLATE_PANEL_TITLE: {
    id: 'TEMPLATE_DETAILS.TEMPLATE_PANEL_TITLE',
    defaultMessage: 'Template',
    description: '',
  },
  INSPECT_PANEL_TITLE: {
    id: 'TEMPLATE_DETAILS.INSPECT_PANEL_TITLE',
    defaultMessage: 'Inspect',
    description: '',
  },
  PROPS_PANEL_TITLE: {
    id: 'TEMPLATE_DETAILS.PROPS_PANEL_TITLE',
    defaultMessage: 'Properties',
    description: '',
  },
  FEEDBACK_PANEL_TITLE: {
    id: 'TEMPLATE_DETAILS.FEEDBACK_PANEL_TITLE',
    defaultMessage: 'Feedback',
    description: '',
  },
  DEPENDENCIES_PANEL_TITLE: {
    id: 'TEMPLATE_DETAILS.DEPENDENCIES_PANEL_TITLE',
    defaultMessage: 'Dependencies',
    description: '',
  },
  DEPENDENCIES_EXPLANATION: {
    id: 'TEMPLATE_DETAILS.DEPENDENCIES_EXPLANATION',
    defaultMessage: 'These lists are used to build up this list',
    description: '',
  },
  DEPENDENCIES_DISCLAIMER: {
    id: 'TEMPLATE_DETAILS.DEPENDENCIES_DISCLAIMER',
    defaultMessage:
      'This overview is based on local client data and may be incomplete. Lists that are archived, unsynchronised, etc. may be omitted',
    description: '',
  },
  NO_DEPENDENCIES_EXPLANATION: {
    id: 'TEMPLATE_DETAILS.NO_DEPENDENCIES_EXPLANATION',
    defaultMessage: 'This list does not have any dependencies',
    description: '',
  },
  DEPENDANTS_EXPLANATION: {
    id: 'TEMPLATE_DETAILS.DEPENDANTS_EXPLANATION',
    defaultMessage: 'These lists are depending upon this list',
    description: '',
  },
  NO_DEPENDANTS_EXPLANATION: {
    id: 'TEMPLATE_DETAILS.NO_DEPENDANTS_EXPLANATION',
    defaultMessage: 'No lists are depending upon this list',
    description: '',
  },
  ORIGIN_PANEL_TITLE: {
    id: 'TEMPLATE_DETAILS.ORIGIN_PANEL_TITLE',
    defaultMessage: 'Field origin',
    description: '',
  },
  ORIGIN_PANEL_NO_FIELD_SELECTED: {
    id: 'TEMPLATE_DETAILS.ORIGIN_PANEL_NO_FIELD_SELECTED',
    defaultMessage: 'No field selected',
    description: '',
  },
  ORIGIN_PANEL_EXPLANATION: {
    id: 'TEMPLATE_DETAILS.ORIGIN_PANEL_EXPLANATION',
    defaultMessage: 'This field is inherited from the following template(s)',
    description: '',
  },
  ORIGIN_PANEL_NO_EXPLANATION: {
    id: 'TEMPLATE_DETAILS.ORIGIN_PANEL_NO_EXPLANATION',
    defaultMessage: 'This field has been created in this list',
    description: '',
  },
  TEMPLATE_BUTTON_DISCARD_CHANGES: {
    id: 'TEMPLATE_DETAILS.TEMPLATE_BUTTON_DISCARD_CHANGES',
    defaultMessage: 'Discard Changes',
    description: '',
  },
  TEMPLATE_TAG_LABEL: {
    id: 'TEMPLATE_DETAILS.TEMPLATE_TAG_LABEL',
    defaultMessage: 'Tags',
    description: '',
  },
  TEMPLATE_NAME_LABEL: {
    id: 'TEMPLATE_DETAILS.TEMPLATE_NAME_LABEL',
    defaultMessage: 'Name',
    description: '',
  },
  TEMPLATE_BUTTON_SAVE: {
    id: 'TEMPLATE_DETAILS.TEMPLATE_BUTTON_SAVE',
    defaultMessage: 'Save',
    description: '',
  },
});
