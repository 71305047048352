import { CALL_API, RSAAction } from 'redux-api-middleware';
import { Catalog } from '../models/Catalog';
import { mapByRecnum, resolveHeaders, resolveUrl } from '../utils';
import { softAssertNever } from './utils';

export interface RequestFetchCatalogsAction {
  type: 'REQUEST_FETCH_CATALOGS';
}
export interface SuccessFetchCatalogsAction {
  type: 'SUCCESS_FETCH_CATALOGS';
  payload: Catalog[];
}
export interface FailureFetchCatalogsAction {
  type: 'FAILURE_FETCH_CATALOGS';
}

export function fetchCatalogs(): RSAAction<any, any, any> {
  return {
    [CALL_API]: {
      endpoint: resolveUrl('/catalogs'),
      method: 'GET',
      headers: resolveHeaders(),
      types: [
        'REQUEST_FETCH_CATALOGS',
        'SUCCESS_FETCH_CATALOGS',
        'FAILURE_FETCH_CATALOGS',
      ],
    },
  };
}

export interface RequestFetchCatalogDetailsAction {
  type: 'REQUEST_FETCH_CATALOG_DETAILS';
}
export interface SuccessFetchCatalogDetailsAction {
  type: 'SUCCESS_FETCH_CATALOG_DETAILS';
  payload: Catalog;
}
export interface FailureFetchCatalogDetailsAction {
  type: 'FAILURE_FETCH_CATALOG_DETAILS';
}
export function fetchCatalogDetails(
  catalogId: number,
): RSAAction<any, any, any> {
  return {
    [CALL_API]: {
      endpoint: resolveUrl(`/catalogs/${catalogId}`),
      method: 'GET',
      headers: resolveHeaders(),
      types: [
        'REQUEST_FETCH_CATALOG_DETAILS',
        'SUCCESS_FETCH_CATALOG_DETAILS',
        'FAILURE_FETCH_CATALOG_DETAILS',
      ],
    },
  };
}
export interface RequestFetchProjectCatalogDetailsAction {
  type: 'REQUEST_FETCH_PROJECT_CATALOG_DETAILS';
}
export interface SuccessFetchProjectCatalogDetailsAction {
  type: 'SUCCESS_FETCH_PROJECT_CATALOG_DETAILS';
  payload: Catalog;
}
export interface FailureFetchProjectCatalogDetailsAction {
  type: 'FAILURE_FETCH_PROJECT_CATALOG_DETAILS';
}
export function fetchProjectCatalogDetails(projectId: number) {
  return {
    [CALL_API]: {
      endpoint: resolveUrl(`/projects/${projectId}/catalog`),
      method: 'GET',
      headers: resolveHeaders(),
      types: [
        'REQUEST_FETCH_PROJECT_CATALOG_DETAILS',
        'SUCCESS_FETCH_PROJECT_CATALOG_DETAILS',
        'FAILURE_FETCH_PROJECT_CATALOG_DETAILS',
      ],
    },
  };
}

export interface RequestPatchCatalogAction {
  type: 'REQUEST_PATCH_CATALOG';
}
export interface SuccessPatchCatalogAction {
  type: 'SUCCESS_PATCH_CATALOG';
  payload: Catalog;
}
export interface FailurePatchCatalogAction {
  type: 'FAILURE_PATCH_CATALOG';
}

export function patchCatalogDetails(
  catalogId: number,
  changes: Partial<Catalog>,
): RSAAction<any, any, any> {
  return {
    [CALL_API]: {
      endpoint: resolveUrl(`/catalogs/${catalogId}`),
      method: 'PATCH',
      body: JSON.stringify(changes),
      headers: resolveHeaders(),
      types: [
        'REQUEST_PATCH_CATALOG',
        'SUCCESS_PATCH_CATALOG',
        'FAILURE_PATCH_CATALOG',
      ],
    },
  };
}

export type CatalogAction =
  | RequestFetchCatalogsAction
  | SuccessFetchCatalogsAction
  | FailureFetchCatalogsAction
  | RequestFetchCatalogDetailsAction
  | SuccessFetchCatalogDetailsAction
  | FailureFetchCatalogDetailsAction
  | RequestFetchProjectCatalogDetailsAction
  | SuccessFetchProjectCatalogDetailsAction
  | FailureFetchProjectCatalogDetailsAction
  | RequestPatchCatalogAction
  | SuccessPatchCatalogAction
  | FailurePatchCatalogAction;

export const actions = {
  fetchCatalogs,
  fetchCatalogDetails,
  fetchProjectCatalogDetails,
  patchCatalogDetails,
};

export type CatalogState = {
  [recnum: string]: Catalog | undefined;
} | null;

export default function catalogs(
  state: CatalogState = null,
  action: CatalogAction,
): CatalogState {
  if (action.type === 'SUCCESS_FETCH_CATALOGS') {
    // preserve files & groups if they are allready present
    // NOTE: catalogs which are no longer in the response will be removed
    return mapByRecnum(
      action.payload.map(c => {
        const localCatalog = state?.[c.recnum];
        if (localCatalog) {
          const { files, groups } = localCatalog;
          return { ...c, files, groups };
        }
        return c;
      }),
    );
  } else if (
    action.type === 'SUCCESS_FETCH_CATALOG_DETAILS' ||
    action.type === 'SUCCESS_FETCH_PROJECT_CATALOG_DETAILS'
  ) {
    if (!action?.payload) {
      // project possibly has no catalog
      return state;
    }
    return {
      ...state,
      [action.payload.recnum]: action.payload,
    };
  } else if (action.type === 'SUCCESS_PATCH_CATALOG') {
    let { payload: catalog } = action;
    const local = state?.[catalog.recnum];
    if (local) {
      const { files, groups } = local;
      catalog = { ...catalog, files, groups };
    }
    return {
      ...state,
      [catalog.recnum]: catalog,
    };
  } else if (
    action.type === 'REQUEST_FETCH_CATALOGS' ||
    action.type === 'REQUEST_FETCH_CATALOG_DETAILS' ||
    action.type === 'REQUEST_FETCH_PROJECT_CATALOG_DETAILS' ||
    action.type === 'REQUEST_PATCH_CATALOG'
  ) {
    return state;
  } else if (
    action.type === 'FAILURE_FETCH_CATALOGS' ||
    action.type === 'FAILURE_FETCH_CATALOG_DETAILS' ||
    action.type === 'FAILURE_FETCH_PROJECT_CATALOG_DETAILS' ||
    action.type === 'FAILURE_PATCH_CATALOG'
  ) {
    return state;
  }
  softAssertNever(action);
  return state;
}
