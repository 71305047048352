import { pick } from 'ramda';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Types from 'Types';
import { useRouteConfirmation } from '../../AppRouter/createWouterHook';
import { ExcludeNull } from '../../common/logic/types';
import { createAttachment } from '../../modules/attachments';
import { LabelsState } from '../../modules/labels';
import { GeoTag, StoreTicket } from '../../modules/tickets';
import { UserState } from '../../modules/user';
import { UsersState } from '../../modules/users';
import { Lex } from '../../utils/LexicalParsing';
import ErrorBoundry from '../Errorboundry';
import GeotagPanel from '../GeotagPanel/index';
import { createEventList, TimelineEvent } from './Events';
import MessageBox from './MessageBox';

const pickAttachmentFields = pick(['size', 'checksum', 'contentType']);

// FIXME: the types between TicketEvents, ListModifications and Timeline-events and this is a mess, do clean up
export type TimelineCreateEvent =
  | { type: 'MESSAGE'; message: string }
  | { type: 'GEOTAG'; geotag: GeoTag }
  | { type: 'ATTACHMENT'; attachment: any };

interface TimelineProps {
  localTickets: Record<string, StoreTicket>;
  allTickets: Record<string, StoreTicket>;
  users: ExcludeNull<UsersState>;
  onCreateEvent: (e: TimelineCreateEvent) => void;
  onEditEvent: (message: string, e: TimelineEvent) => void;
  onDeleteEvent: (e: TimelineEvent) => void;
  canChangeEvent: (e: TimelineEvent) => boolean;
  lexes: Lex[];
  events: TimelineEvent[];
}
const Timeline: React.FC<TimelineProps> = ({
  onCreateEvent,
  onEditEvent,
  onDeleteEvent,
  localTickets,
  allTickets,
  canChangeEvent,
  events,
  lexes,
}) => {
  const [users, user, labels] = useSelector<
    Types.RootState,
    [UsersState, UserState, LabelsState]
  >(s => [s.users, s.user, s.labels]);
  const [message, setMessage] = React.useState<string>('');
  const [addingGeotag, setAddingGeotag] = React.useState<boolean>(false);
  const dispatch = useDispatch();

  /* Messages */
  const handleNewMessageSubmit = React.useCallback(() => {
    onCreateEvent({ type: 'MESSAGE', message });
    setMessage('');
  }, [message, onCreateEvent]);

  useRouteConfirmation(
    !!message,
    'Do you want to leave this page and discard your message?',
  );

  /* Attachments */
  const handleAttachments = React.useCallback(
    async (files: File[]) => {
      if (files.some(f => f.size > 200e6)) {
        alert(
          'One or more files are larger then 200mb. Please contact support if you need to attach large files',
        );
        return;
      }
      // store locally
      const attachments = await Promise.all(
        files.map(createAttachment).map(dispatch),
      );
      // then attach to list
      attachments.forEach(attachment =>
        onCreateEvent({
          type: 'ATTACHMENT',
          attachment: pickAttachmentFields(attachment),
        }),
      );
    },
    [dispatch, onCreateEvent],
  );

  /* Geotagging */
  const handleStartAddingGeotag = React.useCallback(() => {
    setAddingGeotag(true);
  }, []);

  const handleAddGeotag = React.useCallback(
    (geotag: any) => {
      onCreateEvent({ type: 'GEOTAG', geotag });
      setAddingGeotag(false);
    },
    [onCreateEvent],
  );

  const handleCancelGeotag = React.useCallback(() => {
    setAddingGeotag(false);
  }, []);

  // FIXME: dont instantiate react elements like this. just use jsx
  const eventList = React.useMemo(
    () =>
      createEventList(
        events,
        users || {},
        localTickets,
        allTickets,
        labels,
        lexes,
        {
          onEditEvent,
          onDeleteEvent,
          canChangeEvent,
        },
      ),
    [
      events,
      users,
      localTickets,
      allTickets,
      labels,
      lexes,
      onEditEvent,
      onDeleteEvent,
      canChangeEvent,
    ],
  );

  return (
    <div>
      <ErrorBoundry>
        {eventList}
        {user && (
          <MessageBox
            lexes={lexes}
            user={user}
            localTickets={localTickets}
            allTickets={allTickets}
            message={message}
            users={users}
            onSubmit={handleNewMessageSubmit}
            onAttachments={handleAttachments}
            onGeotag={handleStartAddingGeotag}
            onMessageChange={setMessage}
          />
        )}
        <GeotagPanel
          show={addingGeotag}
          onAddGeotag={handleAddGeotag}
          onCancelGeotag={handleCancelGeotag}
        />
      </ErrorBoundry>
    </div>
  );
};

export default Timeline;
