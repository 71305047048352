import React from 'react';
import treeDownRightSvg from './icons/tree-down-right.svg';
import treeDownSvg from './icons/tree-down.svg';
import treeRightSvg from './icons/tree-right.svg';
import stl from './Tree.module.scss';

const trailIconMap = {
  down: treeDownSvg,
  right: treeRightSvg,
  downRight: treeDownRightSvg,
};
export type TrailIconType = 'down' | 'right' | 'downRight' | 'empty';

interface TrailIconProps {
  type: TrailIconType;
}

const TrailIcon: React.FC<TrailIconProps> = ({ type }) =>
  type === 'empty' ? (
    <div className={stl.trailIcon} />
  ) : (
    <img
      draggable="false"
      alt=""
      className={stl.trailIcon}
      src={trailIconMap[type]}
    />
  );

export default TrailIcon;
