import React from 'react';
import { useSelector } from 'react-redux';
import Types from 'Types';
import { constructTree } from '../components/Tree/common';
import { fetchLocations, LocationWithDetails } from '../modules/locations';
import useInitialDispatch from '../utils/useInitalDispatch';

export default function useLocations() {
  useInitialDispatch(fetchLocations);

  const allLocations = useSelector<
    Types.RootState,
    Types.RootState['locations']
  >(({ locations }) => locations);

  const rootLocations = React.useMemo(() => {
    if (!allLocations) {
      return [];
    }
    return constructTree(Object.values(allLocations) as LocationWithDetails[]);
  }, [allLocations]);

  return rootLocations;
}

// export function useLocation();
