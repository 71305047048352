import * as React from 'react';
import {
  IReportReadyProviderContext,
  ReportReadyConsumer,
} from './ReportReadyProvider';

export default function withReportReady<P>(
  Component: React.ComponentType<P & IReportReadyProviderContext>,
): React.FC<Pick<P, Exclude<keyof P, keyof IReportReadyProviderContext>>> {
  return props => {
    return (
      <ReportReadyConsumer>
        {itf => {
          const C = Component as any;
          return <C {...itf} {...props} />;
        }}
      </ReportReadyConsumer>
    );
  };
}
