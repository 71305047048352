export interface Parameter {
  name: string;
  value: string;
}

export interface ParameterOptions {
  name: string;
  icon?: string;
  options: string[] | any[];
  search?: (item: any, value: any) => boolean;
  getValue?: (item: any) => string;
  getLabel?: (item: any) => string;
  getLabelFromValue?: (value: string) => string;
}

export interface ParametersOptions {
  parameters: { [paramName: string]: ParameterOptions };
  small: boolean;
  noInput: boolean;
}

export interface Preset {
  name: React.ReactNode;
  value: string;
}

export const defaultOptions: ParametersOptions = {
  small: false,
  noInput: false,
  parameters: {},
};

export interface ParameterSet {
  // completed paramters
  parameters: Parameter[];
  // parameter key with incomplete value
  newParam: Parameter | null;
  // rest of string
  rest: string;
}

export const parseParameters = (value: string): ParameterSet => {
  const parameters = [];
  let newParam = null;
  let rest = value;
  let regResult;
  let exit = 0;
  /* tslint:disable:no-conditional-assignment */
  while ((regResult = /^\s*([\w-]*):"([\w\s-]*)"/.exec(rest))) {
    const [v, name, val] = regResult;
    rest = rest.replace(v, '');
    parameters.push({ name, value: val });
    if (++exit > 100) {
      throw new Error('IEEEEEEEE bgshhhewww! (crash)');
    }
  }
  if ((regResult = /^\s*(\w*):"?([\w\s]*)$/.exec(rest))) {
    const [v, name, val] = regResult;
    rest = rest.replace(v, '');
    newParam = { name, value: val };
  }
  return {
    parameters,
    newParam,
    rest,
  };
};

export const serializeParameters = ({
  parameters,
  newParam,
  rest = '',
}: ParameterSet) => {
  return (
    parameters.map(({ name, value }) => `${name}:"${value}"`).join(' ') +
    (newParam ? `${newParam.name}:${newParam.value}` : '') +
    rest
  );
};
