import { v4 as uuid } from 'uuid';

const clientIdentifierKey = 'certhon_app_client_identifier';

let identifier = '';

export function getClientIdentifier() {
  if (!identifier) {
    identifier = localStorage.getItem(clientIdentifierKey) || '';
    if (!identifier) {
      identifier = uuid();
      localStorage.setItem(clientIdentifierKey, identifier);
    }
  }
  return identifier;
}
