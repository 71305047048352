// import PropTypes from 'prop-types';
import * as React from 'react';

import { FormControl, FormGroup, InputGroup } from 'react-bootstrap';

import styles from './NumericControl.module.scss';

export interface INumericControlProps {
  value: number | null;
  disabled: boolean;
  unit?: string;
  onChange: (value: number | null) => void;
}

export function parseUserInputToNumber(value: string): [number | null, string] {
  // extract the valid portion
  let replaceString: string = /-?\d*(,|\.)\d*/.exec(value)?.[0] || '';

  const v = parseFloat(value.replace(',', '.'));

  return [isNaN(v) ? null : v, replaceString];
}

class NumericControl extends React.Component<
  INumericControlProps,
  { value: string }
> {
  static defaultProps = {
    disabled: false,
  };

  constructor(props: INumericControlProps) {
    super(props);
    this.state = {
      value: props.value?.toLocaleString() || '',
    };
  }

  handleChange = (evt: React.MouseEvent<FormControl>) => {
    const value = (evt.target as HTMLInputElement).value;

    const [num, str] = parseUserInputToNumber(value);

    this.setState({ value: str });
    this.props.onChange(num);
  };

  handleClick = (evt: React.MouseEvent<FormControl>) => {
    evt.stopPropagation();
  };

  static getDerivedStateFromProps(
    props: INumericControlProps,
    state: { value: string },
  ) {
    if (props.value !== parseUserInputToNumber(state.value)[0]) {
      // console.log('componentWillReceiveProps', {
      //   prop: props.value,
      //   string: state.value,
      //   newString: props.value?.toLocaleString(undefined, {
      //     useGrouping: false,
      //   }),
      // });

      return {
        value:
          props.value?.toLocaleString(undefined, { useGrouping: false }) || '',
      };
    } else {
      // console.log('componentWillReceiveProps');
      return null;
    }
  }

  render() {
    const { disabled, unit } = this.props;

    return (
      <FormGroup>
        <InputGroup className={`${styles.control} pull-right`} bsSize="small">
          <FormControl
            onClick={this.handleClick}
            onChange={this.handleChange}
            inputMode="decimal"
            type="text"
            value={this.state.value}
            disabled={disabled}
          />
          {unit && <InputGroup.Addon>{unit}</InputGroup.Addon>}{' '}
        </InputGroup>
      </FormGroup>
    );
  }
}

export default NumericControl;
