import uniq from 'lodash/fp/uniq';
import * as React from 'react';
import { Button } from 'react-bootstrap';
import { push, replace } from 'redux-first-history';
import { Dispatch } from 'redux';
import Meeting, { makeMeeting } from '../../models/Meeting';
import { Project } from '../../models/Project';
import meetingReducer, {
  createMeeting,
  deleteMeeting,
  updateMeeting,
} from '../../modules/meetings';
import { fetchTickets } from '../../modules/tickets';
import { UserState } from '../../modules/user';
import { fetchUsers, UsersState } from '../../modules/users';
import { Work, WorkState } from '../../modules/works';
import ignoreRejection from '../../utils/ignoreRejection';
import { ProjectDetailsChildBreadcrumbs } from '../BreadCrumbs';
import Page from '../Page';
import Spinner from '../Spinner';
import MeetingsMetaForm from './MinuteMetaForm';
import MinutesSheet from './MinutesSheet';
import { createStartMeetingsActions } from './meetingUtils';
import userData from '../../stories/userData';

const WEEK = 7 * 24 * 60 * 60e3;

// tslint:disable-next-line: no-empty-interface
export interface MinuteDetailsPageProps {
  work: Work | null;
  works: WorkState;
  project: Project | null;
  meeting: Meeting | null;
  previousMeeting: Meeting | null;
  users: UsersState;
  user: UserState;
  allTicketsHaveEvents: boolean;
  tickets: any;
  dispatch: Dispatch;
}

// tslint:disable-next-line: no-empty-interface
export interface MinuteDetailsPageState {}

class MeetingDetailsPage extends React.Component<
  MinuteDetailsPageProps,
  MinuteDetailsPageState
> {
  componentDidMount() {
    ignoreRejection(
      this.props.dispatch(fetchUsers() as any),
      this.props.dispatch(fetchTickets() as any),
    );
  }

  updateMinute = (partial: Partial<Meeting> & { id: string }) => {
    const { user } = this.props;
    if (!user) {
      throw new Error('expected user');
    }
    this.props.dispatch(
      updateMeeting({ ...partial, edited: new Date(), editor: user.recnum }),
    );
  };

  deleteMeeting = async () => {
    if (
      // eslint-disable-next-line no-restricted-globals
      !confirm(
        'Are you sure you want to delete this meeting?\nIt will no longer be accessible from QA.',
      )
    ) {
      return;
    }
    const { meeting, dispatch, user } = this.props;
    if (!meeting) {
      throw new Error('expect meeting ');
    }
    if (!user) {
      throw new Error('expected user');
    }
    // if the meeting is part of a series, fix the links of the previous/next meeting
    if (meeting.next_meeting) {
      dispatch(
        updateMeeting({
          id: meeting.next_meeting,
          previous_meeting: meeting.previous_meeting,
          edited: new Date(),
          editor: user.recnum,
        }),
      );
    }
    if (meeting.previous_meeting) {
      dispatch(
        updateMeeting({
          id: meeting.previous_meeting,
          next_meeting: meeting.next_meeting,
          edited: new Date(),
          editor: user.recnum,
        }),
      );
    }
    await dispatch(deleteMeeting(meeting.id) as any);
    dispatch(replace(`/project/${meeting.project_id}`));
  };

  scheduleNextMeeting = () => {
    const { meeting, user, dispatch } = this.props;
    if (!user) {
      throw new Error('Expected user');
    }
    if (!meeting) {
      throw new Error('Expected meeting');
    }
    const newMeeting = makeMeeting({
      name: meeting.name,
      date: null,
      number: meeting.number ? meeting.number + 1 : 1,
      present: uniq([...meeting.present, ...meeting.absent]),
      creator: user.recnum,
      werk_id: meeting.werk_id,
      project_id: meeting.project_id,
      previous_meeting: meeting.id,
    });

    dispatch(createMeeting(newMeeting));
    dispatch(
      updateMeeting({
        id: meeting.id,
        editor: user.recnum,
        edited: new Date(),
        next_meeting: newMeeting.id,
      }),
    );

    dispatch(push(`/meeting/${newMeeting.id}`));
  };
  handleInsertTicketSection = () => {
    const { previousMeeting, works, dispatch, user, meeting } = this.props;
    if (!user) {
      throw new Error('user expected');
    }
    if (!meeting) {
      throw new Error('meeting expected');
    }
    if (!works) {
      throw new Error('works expected');
    }

    createStartMeetingsActions(
      meeting,
      previousMeeting,
      this.props.tickets,
      user,
      works,
    ).forEach(dispatch);

    dispatch(
      updateMeeting({
        id: meeting.id,
        date: new Date(),
        edited: new Date(),
        editor: user.recnum,
      }),
    );
  };

  render() {
    const {
      tickets,
      meeting: _meeting,
      users,
      user,
      project,
      previousMeeting,
      works,
    } = this.props;
    let content: React.ReactNode;

    if (!(tickets && _meeting && users && project && user && works)) {
      content = <Spinner />;
    } else {
      const projectName = project && (
        <>
          {project.projectname} {project.id && <em>- {project.id}</em>}
        </>
      );

      const hasBegun = _meeting.sections.length > 1;
      let sections;
      // if the meeting has not been started yet, "display" virtual sections
      let meeting: Meeting;
      if (!hasBegun) {
        const st = createStartMeetingsActions(
          _meeting,
          previousMeeting,
          tickets,
          user,
          works,
        ).reduce(meetingReducer, { [_meeting.id]: _meeting });
        meeting = st[_meeting.id];
      } else {
        meeting = _meeting;
      }

      let sheet = <Spinner />;
      if (this.props.allTicketsHaveEvents) {
        sheet = (
          <MinutesSheet
            user={this.props.user}
            users={this.props.users}
            dispatch={this.props.dispatch}
            meeting={meeting}
            tickets={this.props.tickets}
            hasPreviousMeeting={!!this.props.previousMeeting}
            hasBegun={hasBegun}
            onInsertSections={this.handleInsertTicketSection}
          />
        );
      }

      content = (
        <>
          {/* Breadcrumbs */}
          <ProjectDetailsChildBreadcrumbs
            projectId={project.recnum}
            projectName={projectName}
            name={`${meeting.name || 'meeting'} ${
              meeting.number ? `#${meeting.number}` : ''
            }`}
          />

          <MeetingsMetaForm
            id={meeting.id}
            number={meeting.number}
            name={meeting.name}
            date={meeting.date}
            absent={meeting.absent}
            present={meeting.present}
            users={users}
            previous_meeting={meeting.previous_meeting}
            next_meeting={meeting.next_meeting}
            onChange={this.updateMinute}
            onScheduleNextMeeting={this.scheduleNextMeeting}
            hasBegun={hasBegun}
          />
          {sheet}
          <Button
            onClick={this.deleteMeeting}
            className="danger hidden-print"
            style={{ marginTop: 20 }}
            bsStyle="link"
            bsClass="btn"
          >
            Delete meeting
          </Button>
        </>
      );
    }
    return <Page title="Meeting">{content}</Page>;
  }
}

export default MeetingDetailsPage;
