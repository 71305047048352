import classNames from 'classnames';
import * as React from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { Link } from 'wouter';
import logoImg from '../../assets/images/logo_white.png';
import DownloadButton from '../DownloadButton';
import ErrorBoundry from '../Errorboundry';
import './Paper.scss';
import styles from './Paper.module.scss';

interface IPaperProps {
  downloadLink?: string;
  children: React.ReactNode;
  size: string;
  title: string;
}

const NewPage: React.FC<{
  children?: React.ReactNode;
  className?: string;
  id?: string;
}> = ({ children, className, id }) => (
  <div id={id} className={classNames(styles.newPage, styles.a4, className)}>
    {children}
  </div>
);

export default class Paper extends React.Component<IPaperProps> {
  static defaultProps = {
    size: 'a4',
  };

  static NewPage = NewPage;

  componentWillMount() {
    document.body.classList.toggle('paper', true);
  }
  componentWillUnmount() {
    document.body.classList.toggle('paper', false);
  }

  render() {
    const { size, children, title } = this.props;
    return (
      <div>
        <Navbar fixedTop={true}>
          <Navbar.Header>
            <Link to="/">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a className="navbar-brand">
                <img role="presentation" alt="certhon logo" src={logoImg} />
                {title ? `${title}` : 'Certhon QA'}
              </a>
            </Link>
            <Navbar.Toggle />
          </Navbar.Header>
          {this.props.downloadLink && (
            <Nav pullRight={true}>
              <Navbar.Form>
                <DownloadButton downloadUri={this.props.downloadLink} />
              </Navbar.Form>
            </Nav>
          )}
        </Navbar>

        <div className={classNames(styles.root, styles[size], 'print')}>
          <ErrorBoundry>{children}</ErrorBoundry>
        </div>
      </div>
    );
  }
}
