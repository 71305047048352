import curry from 'lodash/fp/curry';
import { descend, sortWith } from 'ramda';
import * as React from 'react';
import { parseDate } from '../common/logic/misc';
import AccountOverviewComponent from '../components/AccountOverviewComponent';
import useSearchParam from '../hooks/useSearchParam';
import Account from '../models/Account';
import { useInitApiCall } from './useApiCall';

interface Props {}
const stringMatchesAccount = curry((q: string, a: Account) => {
  const Q = q.toUpperCase();
  return (
    a.email?.toUpperCase().includes(Q) ||
    a.loginname?.toUpperCase().includes(Q) ||
    a.note?.toUpperCase().includes(Q) ||
    a.department?.toUpperCase().includes(Q) ||
    a.relat?.toUpperCase().includes(Q) ||
    a.contact?.toUpperCase().includes(Q)
  );
});

const sortAccounts = sortWith<Account>([
  descend(a => parseDate(a.last_successful_login)),
]);

const AccountOverviewContainer: React.FC<Props> = props => {
  let [accounts, err] = useInitApiCall<Account[]>('/admin/accounts');
  const [filter, setFilter] = useSearchParam<string>('q', '');

  if (filter && accounts) {
    const filterFuncs = filter
      .trim()
      .split(/\s+/)
      .map(q => stringMatchesAccount(q));
    accounts = accounts.filter(a => filterFuncs.some(ff => ff(a)));
  }
  return (
    <AccountOverviewComponent
      accounts={accounts && sortAccounts(accounts)}
      err={err}
      filter={filter}
      setFilter={setFilter}
    ></AccountOverviewComponent>
  );
};

export default AccountOverviewContainer;
