import * as React from 'react';
import { Button, DropdownButton, MenuItem } from 'react-bootstrap';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Dispatch } from 'redux';
import { push } from 'redux-first-history';
import createFilters from '../../common/logic/createFilters';
import createWorkFilter from '../../common/logic/createWorkFilter';
import {
  createSortFunction,
  defaultSorting,
  fromString,
  sortingPresets,
  TicketSorting,
} from '../../common/logic/sortTickets';
import { Catalog } from '../../models/Catalog';
import Meeting from '../../models/Meeting';
import { Project } from '../../models/Project';
import { FileDownloadsState } from '../../modules/fileDownloads';
import { FilesState } from '../../modules/files';
import { LabelsState } from '../../modules/labels';
import { createMeeting } from '../../modules/meetings';
import { updateProject } from '../../modules/projects';
import { SortingState } from '../../modules/sorting';
import { UserState } from '../../modules/user';
import { UsersState } from '../../modules/users';
import { Work } from '../../modules/works';
import { useSyncService } from '../../services/ServiceProvider';
import InternalOnly from '../../utils/InternalOnly';
import { ProjectDetailsBreadcrumbs } from '../BreadCrumbs';
import CatalogSection from '../CatalogSection';
import DownloadButton from '../DownloadButton';
import FilterSummary from '../FilterSummary';
import MeetingsList from '../MeetingsList';
import Page from '../Page';
import useWorkFilter from '../ProjectsList/useWorkFilter';
import Spinner from '../Spinner';
import TicketsList from '../TicketsList';
import { WorksList } from '../WorksList';
import ProjectFilter from './ProjectFilter';

import stl from './ProjectDetailsPage.module.scss';

const msg = defineMessages({
  LIST_TICKET_HEADER_TICKET: {
    id: 'PROJECT_DETAILS_COMPONENT.PROJECT_TICKETS_HEADER',
    defaultMessage: 'Tickets',
    description: '',
  },
  NEW_TICKET: {
    id: 'PROJECT_DETAILS_COMPONENT.PROJECT_TICKETS_NEW_TICKET',
    defaultMessage: 'New project ticket',
  },
  MEETINGS_LIST_HEADER: {
    id: 'PROJECT_DETAILS_COMPONENT.MEETINGS_LIST_HEADER',
    defaultMessage: 'Meetings',
  },
  ADD_MEETING_BUTTON_LABEL: {
    id: 'PROJECT_DETAILS_COMPONENT.ADD_MEETING_BUTTON_LABEL',
    defaultMessage: 'New meeting',
  },
  ARCHIVE: {
    id: 'PROJECT_DETAILS_COMPONENT.ARCHIVE_BUTTON_LABEL',
    defaultMessage: 'Archive project',
  },
  UNARCHIVE: {
    id: 'PROJECT_DETAILS_COMPONENT.UNARCHIVE_BUTTON_LABEL',
    defaultMessage: 'Unarchive project',
  },
  FOLLOW: {
    id: 'PROJECT_DETAILS_COMPONENT.FOLLOW_BUTTON_LABEL',
    defaultMessage: 'Follow project',
  },
  UNFOLLOW: {
    id: 'PROJECT_DETAILS_COMPONENT.UNFOLLOW_BUTTON_LABEL',
    defaultMessage: 'Unfollow project',
  },
});

export interface Props {
  id: number;
  project: Project | null;
  works: Work[] | null;
  tickets: any[];
  users: UsersState;
  user: UserState;
  dispatch: Dispatch;
  filter: string;
  catalog?: Catalog;
  fileDownloads: FileDownloadsState;
  files: FilesState;
  sorting: SortingState;
  labels: LabelsState;
  meetings: Meeting[] | null;
  onChangeFilter: (filter: string | null) => void;
  handleChangeSorting: (scope: 'tickets', sorting: string) => void;
}

const WorksSection: React.FC<{
  works: Work[] | null;
  users: UsersState;
  user: UserState;
  dispatch: Dispatch;
}> = ({ works, users, dispatch, user }) => (
  <>
    <h4>Work Items</h4>
    {works && users ? (
      <WorksList works={works} dispatch={dispatch} user={user || undefined} />
    ) : (
      <Spinner />
    )}
  </>
);

const ProjectDetailsPage: React.FC<Props> = ({
  project,
  works,
  id,
  user,
  users,
  dispatch,
  filter,
  onChangeFilter,
  handleChangeSorting,
  tickets,
  catalog,
  sorting,
  files,
  meetings,
  fileDownloads,
  labels,
}) => {
  let name: React.ReactNode = id;
  let content = <Spinner />;
  const filterInterface = useWorkFilter(onChangeFilter, filter, user);
  const syncService = useSyncService();
  const fullSync = React.useCallback(() => {
    syncService?.update(true);
  }, [syncService]);

  const handleNewTicket = React.useCallback(() => {
    dispatch(push(`/tickets/new?project_id=${project?.recnum}`));
  }, [dispatch, project]);

  const handleToggleArchived = React.useCallback<
    React.ChangeEventHandler<any>
  >(() => {
    dispatch(updateProject(project!.recnum, { archived: !project?.archived }));
  }, [dispatch, project]);

  const handleToggleFollowed = React.useCallback<
    React.ChangeEventHandler<any>
  >(() => {
    dispatch(
      updateProject(project!.recnum, {
        [project!.users.includes(user!.recnum)
          ? 'dissociate'
          : 'associate']: user!.recnum,
      }),
    );
  }, [dispatch, project, user]);

  const [sortDescr, sortFun, sortingString] = React.useMemo(() => {
    const sortingString = sorting['tickets'] || defaultSorting;
    const cnf = fromString(sortingString);
    return [
      TicketSorting[cnf[1][0]].toLowerCase(),
      createSortFunction(cnf),
      sortingString,
    ];
  }, [sorting]);

  const sortByDue = React.useCallback(() => {
    handleChangeSorting('tickets', sortingPresets.get('due')!);
  }, [handleChangeSorting]);

  const handleNewMeeting = React.useCallback(() => {
    const name = prompt('Provide meeting name');
    if (name && project && user) {
      dispatch(
        createMeeting({
          name,
          project_id: project.recnum,
          present: [user.recnum],
          creator: user.recnum,
        }),
      );
    }
  }, [dispatch, user, project]);

  if (project && works && user && tickets) {
    const [ticketFilter, , meetingFilter] = createFilters(
      filterInterface.filter,
      works,
    );
    name = (
      <>
        {project.archived && <i title="archived" className="fa fa-archive"></i>}{' '}
        {project.projectname} {project.id && <em>- {project.id}</em>}
      </>
    );

    const filterredWorks = works.filter(
      createWorkFilter(filterInterface.filter, user),
    );
    const workIds = works.map(w => w.recnum);
    // FIX: ticketfilter is hacked work-details-pages-filter
    const filterredTickets = tickets
      .filter(ticketFilter)
      .filter(t => !t.werk_id || workIds.includes(t.werk_id));
    content = (
      <>
        {/* TODO: find out if project has a catalog */}
        <CatalogSection
          files={files}
          catalog={catalog}
          hasCatalog={!!catalog}
          fileDownloads={fileDownloads}
          projectFollowed={project.users.includes(user.recnum)}
        />

        <InternalOnly user={user as any}>
          <WorksSection
            works={filterredWorks}
            users={users}
            user={user}
            dispatch={dispatch}
          />
          <FilterSummary
            descriptive
            total={works.length}
            visible={filterredWorks.length}
            onClear={filterInterface.clearFilter}
            onReset={filterInterface.resetFilter}
          />

          <h4 className="listHeader">
            <span>
              <FormattedMessage {...msg.LIST_TICKET_HEADER_TICKET} />
              {user && user.internal && (
                <DownloadButton
                  bsStyle="link"
                  className="btn-link"
                  downloadUri={`/api/projects/${
                    project.recnum
                  }/ticketsreport?filter=${encodeURIComponent(
                    filterInterface.filter,
                  )}&sorting=${encodeURIComponent(sortingString)}`}
                >
                  <i className="fa fa-download" /> download
                </DownloadButton>
              )}
            </span>
            <span>
              <DropdownButton
                bsStyle="link"
                title={
                  <>
                    order by: {sortDescr}
                    <i className="fa fa-carret-down" />
                  </>
                }
                id={`actions`}
              >
                {Array.from(sortingPresets).map(([name, preset]) => (
                  <MenuItem
                    key={name}
                    disabled={false}
                    onClick={() => handleChangeSorting('tickets', preset)}
                    title={name}
                  >
                    <span>{name}</span>
                  </MenuItem>
                ))}
              </DropdownButton>
            </span>
            <InternalOnly user={user}>
              <Button bsSize="small" bsStyle="link" onClick={handleNewTicket}>
                <i className="fa fa-plus" />{' '}
                <FormattedMessage {...msg.NEW_TICKET} />
              </Button>
            </InternalOnly>
          </h4>
          <TicketsList
            labels={labels}
            users={users}
            // dispatch={dispatch}
            tickets={sortFun(filterredTickets)}
            totalItems={tickets.length}
            showWorkLabels
            works={filterredWorks}
            // onClear={search && this.handleSearchClear}
          />

          {/* Meetings */}
          {meetings && user.settings.meetings_enabled && (
            // FIX: user type should match
            <>
              <h5 className="listHeader">
                <span>
                  <FormattedMessage {...msg.MEETINGS_LIST_HEADER} />
                </span>
                <Button
                  bsSize="small"
                  bsStyle="link"
                  onClick={handleNewMeeting}
                >
                  <i className="fa fa-plus" />{' '}
                  <FormattedMessage {...msg.ADD_MEETING_BUTTON_LABEL} />
                </Button>
              </h5>
              <MeetingsList
                users={users as any} // FIX typing should match
                minutes={meetings.filter(meetingFilter)}
                totalItems={meetings.length}
                onClear={filterInterface.clearFilter}
              />
            </>
          )}
        </InternalOnly>
      </>
    );
  }
  const followed = project?.users.includes(user!.recnum);
  return (
    <Page title="Project details" className={stl.root}>
      <ProjectDetailsBreadcrumbs name={name} />
      <ProjectFilter
        filter={filter}
        onChangeFilter={onChangeFilter}
        onPresetSelected={sortByDue}
        users={users}
        user={user}
        works={works}
        labels={labels}
      />
      <h2>
        {project && project.projectname}
        {followed && <i title="followed" className="fa fa-star"></i>}{' '}
        {project?.archived && (
          <i title="archived" className="fa fa-archive"></i>
        )}{' '}
        <InternalOnly user={user}>
          <div className="pull-right">
            <DropdownButton
              pullRight
              title={<i className="fa fa-bars" />}
              id="actions"
            >
              <MenuItem
                disabled={!user?.permissions.QA_PROJECT_MANAGER}
                title={
                  (!user?.permissions.QA_PROJECT_MANAGER &&
                    'Projectmanager privileges required') ||
                  undefined
                }
                onClick={handleToggleArchived}
              >
                {!project?.archived ? (
                  <>
                    <i className="fa fa-archive" />{' '}
                    <FormattedMessage {...msg.ARCHIVE} />
                  </>
                ) : (
                  <>
                    <i className="fa fa-archive" />{' '}
                    <FormattedMessage {...msg.UNARCHIVE} />
                  </>
                )}
              </MenuItem>
              <MenuItem onClick={handleToggleFollowed}>
                {followed ? (
                  <>
                    <i className="fa fa-star-o" />{' '}
                    <FormattedMessage {...msg.UNFOLLOW} />
                  </>
                ) : (
                  <>
                    <i className="fa fa-star" />{' '}
                    <FormattedMessage {...msg.FOLLOW} />
                  </>
                )}
              </MenuItem>
            </DropdownButton>
          </div>
        </InternalOnly>
      </h2>
      {content}
    </Page>
  );
};

export default ProjectDetailsPage;
