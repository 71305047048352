import { useEffect, useState } from 'react';

const useSearchParams = <T extends Record<string, string>>(
  initialParams: T,
): [T, (newParams: Partial<T>) => void] => {
  const [state, setState] = useState<T>(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const paramsObject = { ...initialParams };

    for (const [key, value] of searchParams) {
      if (paramsObject.hasOwnProperty(key)) {
        (paramsObject as any)[key] = value;
      }
    }

    return paramsObject;
  });

  useEffect(() => {
    const handlePopstate = () => {
      const searchParams = new URLSearchParams(window.location.search);
      const paramsObject = { ...initialParams };

      for (const [key, value] of searchParams) {
        if (paramsObject.hasOwnProperty(key)) {
          (paramsObject as any)[key] = value;
        }
      }

      setState(paramsObject);
    };

    window.addEventListener('popstate', handlePopstate);

    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, [initialParams]);

  const updateState = (newParams: Partial<T>) => {
    const searchParams = new URLSearchParams(window.location.search);
    const paramsObject = { ...state, ...newParams };

    for (const key in paramsObject) {
      if (paramsObject.hasOwnProperty(key)) {
        searchParams.set(key, paramsObject[key]);
      }
    }

    const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
    window.history.pushState({ path: newUrl }, '', newUrl);

    setState(paramsObject);
  };

  return [state, updateState];
};

export default useSearchParams;
