// asserts that the code is not reachable according to type information
// does not do anything when the code IS reached in practise
export function softAssertNever(action: never) {
  return action;
}

// asserts that the code is not reachable according to type information
// throws an error when the code is reached
export function assertNever(action: never): never {
  throw new Error('Unexpected code path due to unexpected object: ' + action);
}
