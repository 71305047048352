import React, { useEffect } from 'react';
import { Button, Col, ListGroup, ListGroupItem, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Link } from 'wouter';
import FormattedRelative from '../components/FormattedRelative';
import Spinner from '../components/Spinner';
import { useMaterialListStore } from '../stores/materialListStore';
import msg from '../components/MaterialListDetailsEditor/msg';
import { useUsersMap } from '../modules/users';
import UserToken from '../components/UserToken';

interface MaterialListOverviewContainerProps {}
const MaterialListOverviewContainer: React.FC<MaterialListOverviewContainerProps> = ({}) => {
  const [{ materialLists }, { fetch }] = useMaterialListStore();
  const [hasFetched, setHasFetched] = React.useState<boolean>(false);
  const [err, setErr] = React.useState<Error | null>(null);
  const users = useUsersMap();
  if (err) {
    throw err;
  }
  useEffect(() => {
    fetch().then(() => setHasFetched(true), setErr);
  }, [fetch]);

  return !hasFetched || !users ? (
    <Spinner></Spinner>
  ) : (
    <>
      <ListGroup>
        <ListGroupItem>
          <Row>
            <Col xs={6} md={4}>
              <small>
                <FormattedMessage {...msg.NAME} />
              </small>
            </Col>
            <Col xs={0} md={4}>
              <small>
                <FormattedMessage {...msg.CREATED} />
              </small>
            </Col>
            <Col xs={6} md={4}>
              <small>
                <FormattedMessage {...msg.STATUS} />
              </small>
            </Col>
          </Row>
        </ListGroupItem>
        {Object.values(materialLists).map((list, index) => (
          <Link to={`/inventory/lists/${list.recnum}`}>
            <ListGroupItem key={index}>
              <Row>
                <Col xs={6} md={4}>
                  {list.name || <span className="text-muted">Unnamed</span>}
                </Col>
                <Col xs={0} md={4}>
                  <FormattedRelative value={list.created_at} />{' '}
                  <FormattedMessage id="BY" defaultMessage="by" />{' '}
                  <UserToken
                    withText
                    small
                    user={users[list.created_by_user_id]}
                  />
                </Col>
                <Col xs={6} md={4}>
                  <small>
                    <FormattedMessage {...msg[list.status]} />
                  </small>
                </Col>
              </Row>
            </ListGroupItem>
          </Link>
        ))}
      </ListGroup>
      <Link to="/inventory/lists/new">
        <Button
          // bsStyle={'secondary'}
          bsSize={'sm'}
          // onClick={}
          className="pull-right"
        >
          <i className="fa fa-plus"></i> New material list
        </Button>
      </Link>
    </>
  );
};
export default MaterialListOverviewContainer;
