import { Label } from '@certhon/domain-models/lib';
import * as React from 'react';
import { ListGroup, ListGroupItem } from 'react-bootstrap';
import { defineMessages, FormattedMessage } from 'react-intl';
import { User } from '../../models/User';
import { LabelsState } from '../../modules/labels';
import { UsersState } from '../../modules/users';
import { Work } from '../../modules/works';
import { mapByRecnum } from '../../utils';
import commonStl from '../commonStyles.module.scss';
import DepartmentToken from '../DepartmentToken';
import FilterSummary from '../FilterSummary';
import FormattedRelative from '../FormattedRelative';
import LabelToken from '../Labels/LabelToken';
import LinkContainer from '../LinkContainer';
import StatusBadge from '../StatusBadge';
import UserToken from '../UserToken/index';
import styles from './TicketsList.module.scss';

const msg = defineMessages({
  NO_TICKETS: {
    id: 'TICKET_LIST.NO_TICKETS',
    defaultMessage: 'Tickets can not be displayed',
  },
  TICKETS: {
    id: 'TICKET_LIST.TICKETS',
    defaultMessage: 'tickets',
  },
  dueIn: {
    id: 'TICKET_LIST.DUE_IN',
    defaultMessage: 'Due ',
  },
});

const itemName = <FormattedMessage {...msg.TICKETS}></FormattedMessage>;

interface Props {
  tickets: any[];
  showWorkLabels?: boolean;
  works?: Work[];
  labels: LabelsState;
  users: UsersState;
  totalItems?: number;
  onClear?: () => void;
  onReset?: () => void;
}

const TicketsList: React.FC<Props> = ({
  tickets,
  users,
  totalItems,
  works,
  labels,
  showWorkLabels = false,
  ...rest
}) => {
  if (!tickets || !users) {
    return (
      <p className={commonStl.noEntities}>
        <FormattedMessage {...msg.NO_TICKETS} />
      </p>
    );
  }
  const workMap = works && mapByRecnum(works);

  const items = tickets.map((ticket: any) => {
    const work = workMap?.[ticket.werk_id] || null;
    return (
      <LinkContainer key={ticket.id} to={`/tickets/${ticket.id}`}>
        <ListGroupItem className={styles.root}>
          <div className={styles.badge}>
            <StatusBadge status={ticket.status} size="small" />
          </div>
          <div className={styles.title}>
            <div className={styles.right}>
              {ticket.labels
                ?.map((recnum: number) => labels?.[recnum])
                .filter(
                  (l: Label) => l?.deleted === false && l?.tickets === true,
                )
                .map((l: Label) => (
                  <LabelToken
                    key={l.recnum}
                    className={styles.departmentToken}
                    label={l}
                  />
                ))}
              {showWorkLabels && work && (
                <DepartmentToken
                  className={styles.departmentToken}
                  value={work.afd as string}
                >
                  {work.id}
                </DepartmentToken>
              )}
              <div className={styles.due}>
                {ticket.due ? (
                  <>
                    <FormattedMessage {...msg.dueIn} />
                    <FormattedRelative value={ticket.due} />
                  </>
                ) : null}
              </div>
              <div className={styles.users}>
                {/* FIXME: Expose involved users on tickets without events */}
                {ticket.notification_opt_in?.map((u: any) => (
                  <UserToken
                    key={u}
                    className={styles.token}
                    small
                    user={users[u] as User}
                  />
                ))}
              </div>
            </div>
            <span className={styles.number}>#{ticket.number || '?'}</span>{' '}
            {ticket.title}
          </div>
        </ListGroupItem>
      </LinkContainer>
    );
  });

  return (
    <>
      <ListGroup>{items}</ListGroup>
      {totalItems !== undefined && (
        <FilterSummary
          itemName={itemName}
          visible={items.length}
          total={totalItems}
          {...rest}
        />
      )}
    </>
  );
};

export default TicketsList;
