import classNames from 'classnames';
import { identity, pluck } from 'ramda';
import * as React from 'react';
import { ListGroup, ListGroupItem } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'wouter';
import { lingualJoin } from '../../utils/messages';
import commonStl from '../commonStyles.module.scss';
import FormattedRelative from '../FormattedRelative';
import LinkContainer from '../LinkContainer';
import { TICKET_FIELDS_PREFIX } from '../TicketDetails/msg';
import UserToken from '../UserToken';
import styles from './ActivityList.module.scss';
import msg from './msg';

const ActivityItem = injectIntl(
  ({ activity, users, tickets, works, lists, intl }) => {
    const us = activity.users.map(u => users[u]).filter(identity);
    const userBadges = us.map(u => (
      <UserToken small={true} key={u.recnum} user={u} />
    ));
    let link;
    let content;
    let icon;
    let className;
    const { type, payload, timestamp } = activity;

    if (!us.length) {
      return null;
    }
    const values = { user: us[0].loginname };

    let objectDesc;
    if (payload.ticket_id) {
      objectDesc = (
        <span className={styles.objectDesc}>
          <Link to={`/works/${payload.werk_id}`}>
            {' '}
            {works[payload.werk_id]?.oms || <em>'Unkown work item</em>}
          </Link>
          {' > '}
          <Link to={`/tickets/${tickets[payload.ticket_id].id}`}>
            <span className={styles.number}>
              #{tickets[payload.ticket_id].number || '?'}
            </span>{' '}
            {tickets[payload.ticket_id].title}
          </Link>
        </span>
      );
    } else if (payload.listRecnum) {
      objectDesc = (
        <span className={styles.objectDesc}>
          {works[payload.werk_id].oms} {'>'} {lists[payload.listRecnum].name}
        </span>
      );
    }
    // list events
    if (type === 'CHECKS') {
      link = `/list/${payload.listRecnum}`;
      icon = 'list';
      const checkUsers = lingualJoin(
        '',
        pluck('loginname', us),
        intl.formatMessage,
      );
      const numUsers = us.length;
      content = (
        <div>
          {objectDesc}
          <FormattedMessage
            {...msg.USER_HAS_PERFORMED_CHECKS}
            values={{
              ...values,
              ...payload,
              users: checkUsers,
              numUsers,
            }}
          />
        </div>
      );
    } else if (type === 'EXPAND_PROTO') {
      link = `/list/${payload.listRecnum}`;
      icon = 'list';
      content = (
        <div>
          {objectDesc}
          <FormattedMessage {...msg.USER_HAS_ENROLLED_LIST} values={values} />
        </div>
      );
    } else if (type === 'CLOSE_LIST') {
      link = `/list/${payload.listRecnum}`;
      icon = 'list';
      content = (
        <div>
          {objectDesc}
          <FormattedMessage {...msg.USER_HAS_CLOSED_LIST} values={values} />
        </div>
      );
    } else if (type === 'OPEN_LIST') {
      link = `/list/${payload.listRecnum}`;
      icon = 'list';
      content = (
        <div>
          {objectDesc}
          <FormattedMessage {...msg.USER_HAS_REOPENED_LIST} values={values} />
        </div>
      );

      // ticket events
    } else if (
      [
        'UPDATE_MESSAGE',
        'UPDATE_LOCATION',
        'DELETE_ATTACHMENT',
        'DELETE_MESSAGE',
        'DELETE_LOCATION',
      ].includes(type)
    ) {
      return null;
    } else if (type === 'ADD_MESSAGE') {
      link = `/tickets/${payload.ticket_id}`;
      icon = 'exclamation-triangle';
      content = (
        <div>
          {objectDesc}
          <FormattedMessage {...msg.USER_HAS_POSTED_MESSAGE} values={values} />
        </div>
      );
    } else if (type === 'CREATE_TICKET') {
      link = `/tickets/${payload.ticket_id}`;
      icon = 'exclamation-triangle';
      content = (
        <div>
          {objectDesc}
          <FormattedMessage {...msg.USER_HAS_CREATED_TICKET} values={values} />
        </div>
      );
    } else if (type === 'ADD_ATTACHMENT') {
      link = `/tickets/${payload.ticket_id}`;
      icon = 'exclamation-triangle';
      content = (
        <div>
          {objectDesc}
          <FormattedMessage
            {...msg.USER_HAS_UPLOADED_ATTACHMENT}
            values={values}
          />
        </div>
      );
    } else if (type === 'UPDATE_TICKET') {
      link = `/tickets/${payload.ticket_id}`;
      icon = 'exclamation-triangle';
      const fields = lingualJoin(
        TICKET_FIELDS_PREFIX,
        Object.keys(payload.payload),
        intl.formatMessage,
      );
      content = (
        <div>
          {objectDesc}
          <FormattedMessage
            {...msg.USER_HAS_UPDATED_TICKET}
            values={{ ...values, fields }}
          />
        </div>
      );
    } else {
      link = '#';
      className = styles.unkown;
      content = (
        <span title={JSON.stringify(activity, null, 2)}>
          {objectDesc}
          <FormattedMessage {...msg.UNKNOWN_ACTIVITY} />
        </span>
      );
      icon = 'question';
    }
    return (
      <LinkContainer to={link}>
        <ListGroupItem className={classNames(styles.item, className)}>
          <div className={styles.badge}>
            <i className={`fa fa-${icon}`} />
          </div>
          <div className={styles.title}>
            <div className={styles.right}>{userBadges}</div>
            <div className={styles.right}>
              <span className={styles.ts}>
                <FormattedRelative value={timestamp} />
              </span>
            </div>
            {content}
          </div>
        </ListGroupItem>
      </LinkContainer>
    );
  },
);

export default class ActivityList extends React.Component {
  static propTypes = {};

  render() {
    const { activity, users, works, lists, tickets } = this.props;
    if (!activity || activity.length === 0) {
      return (
        <p className={commonStl.noEntities}>
          <FormattedMessage {...msg.NO_ACTIVITY} />
        </p>
      );
    }

    const items = activity.map(a => (
      <ActivityItem
        activity={a}
        users={users}
        works={works}
        lists={lists}
        tickets={tickets}
      />
    ));
    return <ListGroup className={styles.root}>{items}</ListGroup>;
  }
}
