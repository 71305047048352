// import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as React from 'react';
import { Button, FormGroup } from 'react-bootstrap';

import styles from './TaskControl.module.scss';

export interface INumericControlProps {
  value: number;
  disabled: boolean;
  onChange: (value: boolean) => void;
}

class NumericControl extends React.Component<INumericControlProps> {
  static defaultProps = {
    disabled: false,
    value: false
  };

  state = {};

  handleClick = (evt: React.MouseEvent<Button>) => {
    evt.stopPropagation();
    this.props.onChange(!this.props.value);
  };

  render() {
    const { value, disabled } = this.props;

    return (
      <FormGroup className={`${styles.control} pull-right`} bsSize="small">
        <Button
          disabled={disabled}
          onClick={this.handleClick}
          className={classNames(value && styles.completed)}
        >
          <i
            className={classNames(
              `fa`,
              `fa-lg`,
              `fa-${value ? 'check-square-o' : 'square-o'}`
            )}
          />
        </Button>
      </FormGroup>
    );
  }
}

export default NumericControl;
