import { Location } from '@certhon/domain-models';
import { MakeTree } from '../../components/Tree/common';
import { locationAncestors } from './locationAncestors';
import { replaceNumberingPlaceholder } from './replaceNumberingPlaceholder';

export function formatCode(location: MakeTree<Location>) {
  return locationAncestors(location).map(l =>
    replaceNumberingPlaceholder(l.code, l.ordering),
  );
}
