import * as React from 'react';
import { Button } from 'react-bootstrap';
import { Dispatch } from 'redux';
import createProjectFilter from '../../common/logic/createProjectFilter';
import { FilterInterface } from '../../hooks/useProjectFilter';
import { Project } from '../../models/Project';
import { UserState } from '../../modules/user';
import { UsersState } from '../../modules/users';
import InternalOnly from '../../utils/InternalOnly';
import FilterSummary from '../FilterSummary';
import Page from '../Page';
import ProjectsList from '../ProjectsList';
import Spinner from '../Spinner';
import ProjectFilter from './ProjectFilter';
import SearchProjectDialog from './SearchProjectDialog';

export interface Props {
  projects: Project[] | null;
  dispatch: Dispatch;
  users: UsersState;
  user: UserState;
  filter: FilterInterface;
}

const ProjectsPage: React.FC<Props> = ({
  projects,
  users,
  user,
  dispatch,
  filter,
}) => {
  const [showProjectSearchDialog, setShowProjectSearchDialog] = React.useState<
    boolean
  >(false);
  const toggleShowProjectSearchDialog = React.useCallback(
    () => setShowProjectSearchDialog(x => !x),
    [],
  );

  let content;
  if (!projects || !user) {
    content = <Spinner />;
  } else {
    const projectFilter = createProjectFilter(filter.filter, user);
    const filterredProjects = projects.filter(projectFilter);
    content = (
      <>
        <ProjectsList
          projects={filterredProjects}
          dispatch={dispatch}
          user={user}
        />
        <FilterSummary
          descriptive
          itemName={'projects (unarchived)'}
          total={projects.filter(p => !p.archived).length}
          visible={filterredProjects.length}
          onClear={filter.clearFilter}
          onReset={filter.resetFilter}
        />
      </>
    );
  }
  return (
    <Page title="Projects">
      <h3 className="listHeader">
        <span>Projects</span>
        <InternalOnly user={user}>
          <Button
            bsSize="small"
            bsStyle="link"
            className="pull-right"
            onClick={toggleShowProjectSearchDialog}
          >
            <i className="fa fa-plus" /> lookup project
          </Button>
        </InternalOnly>
      </h3>
      <ProjectFilter filter={filter} users={users} projects={projects} />
      {content}
      <InternalOnly user={user}>
        <SearchProjectDialog
          visible={showProjectSearchDialog}
          onToggleVisible={toggleShowProjectSearchDialog}
        />
      </InternalOnly>
    </Page>
  );
};

export default ProjectsPage;
