import * as Sentry from '@sentry/react';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

const reload = () => window.location.reload();

export interface IErrorBoundryProps {
  children: React.ReactNode;
}

export interface IErrorBoundryState {
  error: boolean;
}

export default class ErrorBoundry extends React.Component<
  IErrorBoundryProps,
  IErrorBoundryState
> {
  state = { error: false };

  componentDidCatch(error: Error, info: { componentStack: string }) {
    this.setState({ error: true });
    (error as any).info = info;
    // tslint:disable-next-line:no-console
    console.log(error);
    // tslint:disable-next-line:no-console
    console.log(info.componentStack);
    Sentry.captureException(error);
  }

  render() {
    if (this.state.error) {
      return (
        <div className="container">
          <div className="row">
            <FormattedMessage
              id="PAGE.ERROR_TITLE"
              defaultMessage="Something went wrong..."
              description=""
              tagName="h3"
            />
            <FormattedMessage
              id="PAGE.ERROR_MSG1"
              defaultMessage="Unfortunately. Something went wrong rendering this page or component."
              tagName="p"
            />
            <FormattedMessage
              id="PAGE.ERROR_MSG2"
              defaultMessage="You can try to {refresh_link} now or try again later."
              tagName="p"
              values={{
                refresh_link: (
                  <button
                    className="btn btn-link"
                    style={{ padding: 0, verticalAlign: 'baseline' }}
                    onClick={reload}
                  >
                    <FormattedMessage
                      id="PAGE.ERRORP_REFRESH_LINK_TITLE"
                      defaultMessage="refresh"
                    />
                  </button>
                ),
              }}
            />
            <FormattedMessage
              id="PAGE.ERROR_MSG3"
              defaultMessage="If this keeps happening, please get in touch with your contact at Certhon."
              tagName="p"
            />
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}
