import { insert } from 'ramda';
import { PartialField } from '../../types';
import {
  createModificationCreator,
  ListEventReducer,
} from '../ListModification';
import { completePartialField, patchListNode } from '../util';

export interface UpdateListPayload {}
export const updateList = createModificationCreator<
  CreateListPayload,
  'MODIFY_LIST'
>('MODIFY_LIST');
export type UpdateListModification = ReturnType<typeof updateList>;
export interface CreateListPayload {}
export const createList = createModificationCreator<
  CreateListPayload,
  'CREATE_LIST'
>('CREATE_LIST');
export type CreateListModification = ReturnType<typeof createList>;

export interface InsertPayload {
  node: PartialField;
  targetNodeId: string;
  index?: number;
}

export const insertNode = createModificationCreator<InsertPayload, 'INSERT'>(
  'INSERT',
);

export type InsertListModification = ReturnType<typeof insertNode>;

const insertMod: ListEventReducer<InsertListModification> = (
  list,
  mod: InsertListModification,
) => {
  const {
    payload: { index = -1, node, targetNodeId },
  } = mod;

  const field = completePartialField(node, targetNodeId);

  const newList = patchListNode(list, targetNodeId, parentNode => {
    if (parentNode.type !== 'GROUP_FIELD' && parentNode.type !== 'ROOT_FIELD') {
      throw new Error('Expected insert target to be a structure-field');
    }
    return {
      // 1. update inserted node parentNodeId
      // 2. modify the targetNode to include the inserted node
      ...parentNode,
      children: insert(
        // 1.
        index === -1 ? parentNode.children.length : index,
        field, // 2.
        parentNode.children,
      ),
    };
  });
  newList.index[field.id] = field;
  return newList;
};

export default insertMod;
