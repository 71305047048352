import * as React from 'react';
import { CurrentUser } from '../../modules/user';

export default function useWorkFilter(
  onChangeFilter: (filter: string | null) => void,
  filter: string,
  user: CurrentUser | null,
) {
  const resetFilter = React.useCallback(() => onChangeFilter(null), [
    onChangeFilter,
  ]);
  const clearFilter = React.useCallback(() => onChangeFilter(''), [
    onChangeFilter,
  ]);
  const defaultFilter = React.useMemo(() => {
    let f = `projectTickets:"true"`;
    if (user && user.department) {
      f += ` department:"${user.department}"`;
    }
    return f;
  }, [user]);

  const defaultedFilter = React.useMemo<string>(
    () => (filter == null ? defaultFilter : filter),
    [filter, defaultFilter],
  );

  return {
    resetFilter: defaultedFilter !== defaultFilter ? resetFilter : undefined,
    clearFilter: filter !== '' ? clearFilter : undefined,
    filter: defaultedFilter,
  };
}
