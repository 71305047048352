import {
  Label,
  Meta,
  processEditAndDeleteTicketEvents,
  TicketStatus,
} from '@certhon/domain-models';
import { add, setDate, setMonth, setYear, startOfDay } from 'date-fns';
import regEscape from 'escape-string-regexp';
import { fromPairs, isEqual, sortBy, toPairs } from 'lodash';
import qs from 'query-string';
import { evolve, F, filter, omit, pick, pipe, without } from 'ramda';
import React from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { Store } from 'redux';
import { Action } from 'redux-actions';
import { goBack, replace } from 'redux-first-history';
import { TimeoutError } from 'rxjs';
import Types from 'Types';
import * as uuid from 'uuid';
import { useRouteConfirmation } from '../../AppRouter/createWouterHook';
import { parseDateOrNull } from '../../common/logic/misc';
import { MakeOptionalExcept } from '../../common/logic/types';
import useReportReady from '../../hooks/useReportReady';
import { Ticket } from '../../models/Ticket';
import { fetchLabels } from '../../modules/labels';
import { OnlineState } from '../../modules/online';
import { fetchProjects, ProjectState } from '../../modules/projects';
import {
  addTicketAttachment,
  AddTicketEventAction,
  addTicketLocation,
  addTicketMessage,
  createTicket,
  deleteTicketAttachment,
  deleteTicketLocation,
  deleteTicketMessage,
  fetchTicket,
  optInTicketNotifications,
  optOutTicketNotifications,
  StoreTicket,
  TicketsState,
  TicketUpdate,
  updateTicket,
  updateTicketLocation,
  updateTicketMessage,
} from '../../modules/tickets';
import { UserState } from '../../modules/user';
import { fetchUsers, UsersState } from '../../modules/users';
import { assertNever } from '../../modules/utils';
import { fetchWorks, WorkState } from '../../modules/works';
import { useOnlineService } from '../../services/ServiceProvider';
import { diffObjects, includesEvery, mapByNumber } from '../../utils';
import awaitStoreCondition from '../../utils/awaitStoreCondition';
import { withKey } from '../../utils/component';
import { Lex, LexMatch, parseAll } from '../../utils/LexicalParsing';
import useInitialDispatch from '../../utils/useInitalDispatch';
import { Breadcrumb } from '../BreadCrumbs';
import Page from '../Page';
import Spinner from '../Spinner';
import Timeline, { TimelineCreateEvent } from '../Timeline';
import { TimelineEvent } from '../Timeline/Events';
import { constructTicketLexes } from './constructLexes';
import { sanitizeText } from './sanitizeText';
import TicketDetails from './TicketDetails';
import TicketDetailsEditor from './TicketDetailsEditor';
import styles from './Tickets.module.scss';

const parseIntOrNull = (v: any) => parseInt(v, 10) || null;

function modifyDateUsingDateLexArguments(
  date: Date,
  arg: Record<string, string>,
): Date {
  let result = date;
  if (arg.date && arg.month) {
    result = setMonth(setDate(result, parseInt(arg.date)), parseInt(arg.month));
    if (arg.year) {
      result = setYear(result, parseInt(arg.year));
    }
    return result;
  }
  if (arg.unit && arg.number) {
    // the default behavious is to add the duration to the current date
    if (!arg.plus) {
      // when plus sign is added, the duration is added to the due date
      result = new Date();
    }
    return add(result, {
      [{ d: 'days', w: 'weeks', y: 'years' }[arg.unit] as string]: parseInt(
        arg.number,
      ),
    });
  }
  throw new Error('Could not modify date using lexarguments');
}

function getAllLabelsFromLexMatch(result: LexMatch): string[] {
  if (result.arguments?.name) {
    if (result.next) {
      return [result.arguments.name, ...getAllLabelsFromLexMatch(result.next)];
    }
    return [result.arguments.name];
  }
  return [];
}

/**
 *  This abstraction is a bit of a mess.
 * create_ticket lexes are parsed and do populate the meta.referencedTicketIds and newTicketsForDispatch fields
 * however, since ticketnumbers have not yet been assigned, the create_ticket lexes are not replaced by actual ticketreferences and is left to be done
 */
function interpretTicketCommandsAndLexes(
  text: string,
  userId: number,
  ticket: MakeOptionalExcept<Ticket, 'id' | 'project_id' | 'werk_id'>,
  loginnameToRecnum: Record<string, number>,
  labelNameToRecnum: Record<string, number>,
  ticketNumberToId: Record<string, string>,
  lexes: Lex[],
): {
  update: TicketUpdate;
  meta: Meta;
  newTicketsForDispatch: Action<StoreTicket>[];
  sanitized_text: string;
} {
  let meta: Meta = {};
  let update: TicketUpdate = {};
  let newTicketsForDispatch: Action<StoreTicket>[] = [];
  // let add_labels: number[] = [];
  // let remove_labels: number[] = [];
  // let referencedUserIds: number[] = [];
  let newTicketsTitles: string[] = [];
  // let referenced_ticket_ids: string[] = [];
  const matches = parseAll(text, lexes);
  for (const lexMatch of matches) {
    if (lexMatch.id === 'title_command') {
      update.title = lexMatch.next!.arguments!.text;
    } else if (lexMatch.id === 'due_command') {
      update.due = modifyDateUsingDateLexArguments(
        new Date(ticket.due || new Date()),
        lexMatch.arguments!,
      );
    } else if (lexMatch.id === 'close_command') {
      update.status = TicketStatus.CLOSED;
    } else if (lexMatch.id === 'open_command') {
      update.status = TicketStatus.OPENED;
    } else if (lexMatch.id === 'assign_command') {
      update.assignee_user_id =
        loginnameToRecnum[lexMatch.next!.arguments!.loginname];
    } else if (lexMatch.id === 'executor_command') {
      update.executor_user_id =
        loginnameToRecnum[lexMatch.next!.arguments!.loginname];
    } else if (lexMatch.id === 'unassign_command') {
      update.assignee_user_id = null;
    } else if (lexMatch.id === 'unexecutor_command') {
      update.executor_user_id = null;
    } else if (lexMatch.id === 'label_command') {
      update.add_labels = [
        ...(update.add_labels || []),
        ...getAllLabelsFromLexMatch(lexMatch.next!).map(
          labelName => labelNameToRecnum[labelName],
        ),
      ];
    } else if (lexMatch.id === 'unlabel_command') {
      update.remove_labels = [
        ...(update.remove_labels || []),
        ...getAllLabelsFromLexMatch(lexMatch.next!).map(
          labelName => labelNameToRecnum[labelName],
        ),
      ];
    } else if (lexMatch.id === 'user') {
      meta.referencedUserIds = meta.referencedUserIds || [];
      meta.referencedUserIds.push(
        loginnameToRecnum[lexMatch.arguments!.loginname],
      );
    } else if (lexMatch.id === 'ticket') {
      meta.referencedTicketIds = meta.referencedTicketIds || [];
      if (lexMatch.arguments!.number) {
        meta.referencedTicketIds.push(
          ticketNumberToId[lexMatch.arguments!.number],
        );
      } else if (lexMatch.arguments!.id) {
        meta.referencedTicketIds.push(lexMatch.arguments!.id);
      }
    } else if (lexMatch.id === 'create_ticket') {
      // just in case a ticket with the same title is created twice  in the same text
      if (!newTicketsTitles.includes(lexMatch.arguments!.title)) {
        const id = uuid.v4();
        newTicketsForDispatch.push(
          createTicket(
            {
              id,
              title: lexMatch.arguments!.title,
              project_id: ticket.project_id,
              werk_id: ticket.werk_id,
              assignee_user_id: userId,
            },
            userId,
            {
              originTicketId: ticket.id,
            },
          ),
        );
        meta.referencedTicketIds = meta.referencedTicketIds || [];
        meta.referencedTicketIds?.push(id);
      }
    }
  }

  const sanitized_text = sanitizeText(matches, text);

  return {
    update,
    meta,
    newTicketsForDispatch,
    sanitized_text,
  };
}

/** Sanatize input for tickets */
const pickTicketProps = pipe(
  pick([
    'title',
    'description',
    'werk_id',
    'project_id',
    'creator_user_id',
    'assignee_user_id',
    'executor_user_id',
    'due',
    'labels',
  ]),
  evolve({
    werk_id: parseIntOrNull,
    project_id: parseIntOrNull,
    assignee_user_id: parseIntOrNull,
    creator_user_id: parseIntOrNull,
    executor_user_id: parseIntOrNull,
    due: parseDateOrNull,
  }),
);

function parseTicketUrlParams() {
  return pickTicketProps(qs.parse(window.location.search));
}

interface TicketDetailsPageProps {
  params: { ticketId: string };
}
/**
 * Display/edit TicketDetails
 * NOTE: for simplicity, this compontent does not refetch when tickedId changes
 *  it should therefore be keyed with ticketId to ensure it does
 */
const TicketDetailsPage: React.FC<TicketDetailsPageProps> = ({
  params: { ticketId },
}) => {
  const onlineService = useOnlineService();

  const dispatch = useDispatch();

  const [labels, projects, tickets, works, user, users, online] = useSelector<
    Types.RootState,
    [
      Record<number, Label> | null,
      ProjectState,
      TicketsState,
      WorkState,
      UserState,
      UsersState,
      OnlineState,
    ]
  >(({ labels, projects, tickets, works, user, users, online }) => [
    labels && filter(l => !l.deleted && l.tickets, labels),
    projects,
    tickets,
    works,
    user,
    users,
    online,
  ]);

  const [formChanges, setChanges] = React.useState<
    MakeOptionalExcept<Ticket, 'id' | 'project_id' | 'werk_id'>
  >({
    id: uuid.v4(),
    title: '',
    description: '',
    creator_user_id: user!.recnum,
    assignee_user_id: user!.recnum,
    labels: [],
    due: new Date(),
    ...(parseTicketUrlParams() as any) /* FIXME: input validation (project_id&werk_id) */,
  });

  const ticket = (tickets && tickets[ticketId]) || null;

  useInitialDispatch(
    () =>
      ticketId !== 'new' &&
      (!ticket || ticket.recnum) && // don't fetch when we know the ticket is not yet on the server
      fetchTicket(ticketId),
    fetchUsers,
    fetchWorks,
    fetchProjects,
    fetchLabels,
  );

  const work = (works && works[(ticket || formChanges)?.werk_id!]) || null;
  const project = work
    ? projects?.[work.project_id!]
    : projects?.[(ticket || formChanges)?.project_id!] || null;

  const [editing, setEditing] = React.useState<boolean>(ticketId === 'new');

  useRouteConfirmation(
    editing &&
      (ticketId === 'new'
        ? !!(formChanges.title || formChanges.description)
        : !!Object.keys(
            pick(
              [
                'title',
                'description',
                'assignee_user_id',
                'executor_user_id',
                'due',
              ],
              diffObjects(ticket, {
                ...formChanges,
                due: (formChanges.due as any)?.toISOString?.(),
              }),
            ),
          ).length),
    'Are you sure you want leave this page and discard your changes?',
  );

  // Fetch refernced tickets
  const [hasFetchedReferences, setHasFetchedReferences] = React.useState<
    boolean
  >(false);

  React.useEffect(
    () => {
      if (ticketId === 'new') {
        setHasFetchedReferences(true);
      }
      async function inner() {
        if (ticket?.events && !hasFetchedReferences) {
          let referencedTicketIds: string[] = [];
          ticket.events.forEach(({ meta }) => {
            meta?.referencedTicketIds?.forEach(id => {
              if (!referencedTicketIds.includes(id)) {
                if (!tickets![id]) {
                  // Only fetch if completely missing since we only need the title and this will otherwise cause a log of requests in multiticketreport
                  referencedTicketIds.push(id);
                }
              }
            });
          });

          await Promise.all(
            referencedTicketIds
              .filter(x => x) // this should not be nessecary, but leave is in case
              .map(id => dispatch(fetchTicket(id))),
          );
          setHasFetchedReferences(true);
        }
      }
      inner();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ticket?.events, hasFetchedReferences],
  );

  // look up other tickets referencing this ticket and combine the references with events
  // Note that these references do not show up in reports
  const ticketEventsAndReferences = React.useMemo<
    TimelineEvent[] | null
  >(() => {
    if (!tickets || !ticket) {
      return null;
    }
    const ticketId = ticket.id;
    const references = Object.values(tickets)
      .filter(t =>
        t.events?.some(({ meta }) =>
          meta?.referencedTicketIds?.includes(ticketId),
        ),
      )
      .map(t => {
        const events = t.events!.filter(({ meta }) =>
          meta?.referencedTicketIds?.includes(ticketId),
        );
        return events.map<TimelineEvent>(e => ({
          created: e.created,
          user_id: e.user_id,
          type: 'REFERENCE',
          payload: {
            name: `#${t.number || '?'} ${t.title}`,
            link: `/tickets/${t.id}`,
          },
        }));
      })
      .flat();
    // FIXME: should not need to parse dates here
    return sortBy([...ticket.events!, ...references], t => new Date(t.created));
  }, [ticket, tickets]);

  const [eventActionsBuffer, setEventActionsBuffer] = React.useState<
    AddTicketEventAction[]
  >([]);

  const store = useStore();

  const ready = useReportReady(
    () =>
      !!(
        hasFetchedReferences &&
        (ticket || ticketId === 'new') &&
        labels &&
        project &&
        user &&
        users &&
        projects &&
        works &&
        (work || (ticket || formChanges)?.werk_id == null)
      ),
    [
      work,
      project,
      ticket,
      labels,
      user,
      users,
      works,
      projects,
      hasFetchedReferences,
    ],
  );

  const loginnameToRecnum = React.useMemo(
    () =>
      users &&
      fromPairs(Object.values(users).map(u => [u.loginname, u.recnum])),
    [users],
  );

  const labelNameToRecnum = React.useMemo(
    () =>
      labels && fromPairs(Object.values(labels).map(l => [l.name, l.recnum])),
    [labels],
  );

  const ticketNumberToId = React.useMemo(
    () =>
      tickets && fromPairs(Object.values(tickets).map(t => [t.number, t.id])),
    [tickets],
  );

  const localTickets = React.useMemo<Record<number, StoreTicket>>(() => {
    if (!ready) {
      return [];
    }
    const workIds = Object.values(works!)
      .filter(w => w.project_id === project!.recnum)
      .map(w => w.recnum);

    return mapByNumber(
      Object.values(tickets!).filter(
        t => t.project_id === project!.recnum || workIds.includes(t.werk_id!),
      ) || [],
    );
  }, [project, ready, tickets, works]);

  const lexes = React.useMemo(
    () => constructTicketLexes(users, labels, localTickets),
    [labels, localTickets, users],
  );

  const handleEdit = React.useCallback(() => {
    setEditing(true);
    setChanges(pickTicketProps(ticket) as any);
    window.scrollTo(0, 0);
  }, [ticket]);

  const handlePatch = React.useCallback((partial: Partial<Ticket>) => {
    setChanges(changes => ({
      ...changes,
      ...partial,
    }));
  }, []);

  const handleCreateEvent = React.useCallback(
    async (event: TimelineCreateEvent) => {
      /** convert a TimeLine event to a ticket event */
      let ticketEvents: any[] = [];
      if (event.type === 'MESSAGE') {
        const {
          sanitized_text,
          meta,
          update,
          newTicketsForDispatch,
        } = interpretTicketCommandsAndLexes(
          event.message,
          user!.recnum,
          ticket!,
          loginnameToRecnum!,
          labelNameToRecnum!,
          ticketNumberToId!,
          lexes,
        );

        // assign the description with all commands removed
        let message = sanitized_text;

        // create tickets
        let newTickets: Ticket[] = await Promise.all(
          newTicketsForDispatch.map(
            createTicketEvent => dispatch(createTicketEvent).payload,
          ) as any,
        );

        // if we are online, wait 2s for ticketnumbers to be assigned
        // otherwise continue and reference the tickets by id as fallback
        if (newTickets.length && (await onlineService?._check())) {
          try {
            newTickets = await awaitStoreCondition(
              store as Store<Types.RootState>,
              ({ tickets }) =>
                Object.values(
                  pick(
                    newTickets.map(t => t.id),
                    tickets,
                  ),
                ),
              ts => ts.every(t => !!t.number),
            );
          } catch (e) {
            if (!(e instanceof TimeoutError)) {
              throw e;
            }
          }
        }

        // substitute create_ticket lexes for ticket references
        for (const newTicket of newTickets) {
          message = message.replace(
            new RegExp('#(\'|")' + regEscape(newTicket.title) + '\\1', 'g'),
            `#${newTicket.number || newTicket.id}+`,
          );
        }
        if (message.trim().length) {
          ticketEvents = [
            addTicketMessage(ticketId, message, user!.recnum, meta),
          ];
        }
        if (Object.keys(update).length) {
          ticketEvents.push(updateTicket(ticket!.id, update, user!.recnum, {}));
        }
      } else if (event.type === 'GEOTAG') {
        ticketEvents = [
          addTicketLocation(ticketId, event.geotag, user!.recnum),
        ];
      } else if (event.type === 'ATTACHMENT') {
        ticketEvents = [
          addTicketAttachment(ticketId, event.attachment, user!.recnum),
        ];
      } else {
        assertNever(event);
      }
      if (ticket) {
        ticketEvents.forEach(dispatch);
      } else {
        // buffer events for ticket to be created
        setEventActionsBuffer(eventActionsBuffer => [
          ...eventActionsBuffer,
          ...ticketEvents,
        ]);
      }
    },
    [
      ticket,
      user,
      loginnameToRecnum,
      labelNameToRecnum,
      ticketNumberToId,
      lexes,
      onlineService,
      dispatch,
      store,
      ticketId,
    ],
  );

  const handleEditEvent = React.useCallback(
    async (newMessage: string, originalEvent: TimelineEvent) => {
      let ticketEvents: any[] = [];
      if (originalEvent.type === 'ADD_LOCATION') {
        ticketEvents = [
          updateTicketLocation(
            ticket!.id,
            originalEvent.id!,
            { label: newMessage },
            user!.recnum,
          ),
        ];
      } else if (originalEvent.type === 'ADD_MESSAGE') {
        const {
          sanitized_text,
          meta,
          update,
          newTicketsForDispatch,
        } = interpretTicketCommandsAndLexes(
          newMessage,
          user!.recnum,
          ticket!,
          loginnameToRecnum!,
          labelNameToRecnum!,
          ticketNumberToId!,
          lexes,
        );

        // assign the description with all commands removed
        let message = sanitized_text;

        // create tickets
        let newTickets: Ticket[] = await Promise.all(
          newTicketsForDispatch.map(
            createTicketEvent => dispatch(createTicketEvent).payload,
          ) as any,
        );

        // if we are online, wait 2s for ticketnumbers to be assigned
        // otherwise continue and reference the tickets by id as fallback
        if (newTickets.length && (await onlineService?._check())) {
          try {
            newTickets = await awaitStoreCondition(
              store as Store<Types.RootState>,
              ({ tickets }) =>
                Object.values(
                  pick(
                    newTickets.map(t => t.id),
                    tickets,
                  ),
                ),
              ts => ts.every(t => !!t.number),
            );
          } catch (e) {
            if (!(e instanceof TimeoutError)) {
              throw e;
            }
          }
        }

        // substitute create_ticket lexes for ticket references
        for (const newTicket of newTickets) {
          message = message.replace(
            new RegExp('#(\'|")' + regEscape(newTicket.title) + '\\1', 'g'),
            `#${newTicket.number || newTicket.id}+`,
          );
        }

        ticketEvents = [
          updateTicketMessage(
            ticketId,
            originalEvent.id!,
            message,
            user!.recnum,
            meta,
          ),
        ];
        if (Object.keys(update).length) {
          ticketEvents.push(updateTicket(ticket!.id, update, user!.recnum, {}));
        }
      } else {
        throw new Error('unkown type: ' + originalEvent.type);
      }
      if (ticket) {
        ticketEvents.forEach(dispatch);
      } else {
        // buffer events for ticket to be created
        setEventActionsBuffer(eventActionsBuffer => [
          ...eventActionsBuffer,
          ...ticketEvents,
        ]);
      }
    },
    [
      dispatch,
      labelNameToRecnum,
      lexes,
      loginnameToRecnum,
      onlineService,
      store,
      ticket,
      ticketId,
      ticketNumberToId,
      user,
    ],
  );

  const handleDeleteEvent = React.useCallback(
    (originalEvent: TimelineEvent) => {
      if (originalEvent.type === 'ADD_MESSAGE') {
        dispatch(
          deleteTicketMessage(ticket!.id, originalEvent.id!, user!.recnum),
        );
      } else if (originalEvent.type === 'ADD_ATTACHMENT') {
        dispatch(
          deleteTicketAttachment(ticket!.id, originalEvent.id!, user!.recnum),
        );
      } else if (originalEvent.type === 'ADD_GEOTAG') {
        dispatch(
          deleteTicketLocation(ticket!.id, originalEvent.id!, user!.recnum),
        );
      } else {
        throw new Error('unkown type: ' + originalEvent.type);
      }
    },
    [dispatch, ticket, user],
  );

  /* FIXME: this function has become very unreadable.
     Refactor into seperate, independent, well-named functions and process in a pipe/flow  */
  const handleSubmit = React.useCallback(async () => {
    let { labels, due, project_id, werk_id, ..._rest } = formChanges;

    // make sure, when the parent is changed, the other field is cleared
    // the result has to be passed to interpretTicketCommandsAndLexes so that any new tickets have the correct parent
    let parent: Pick<Ticket, 'werk_id' | 'project_id'> = {
      werk_id: null,
      project_id: null,
    };
    if (werk_id) {
      parent.werk_id = werk_id;
    } else if (project_id) {
      parent.project_id = project_id;
    }

    let rest: typeof formChanges = { ..._rest, ...parent };
    if (due) {
      rest.due = due instanceof Date ? due.toISOString() : due; // FIXME: proper dto`s
    }
    const {
      update: { add_labels, remove_labels, ...update },
      meta,
      sanitized_text,
      newTicketsForDispatch,
    } = interpretTicketCommandsAndLexes(
      formChanges.description || '',
      user!.recnum,
      rest,
      loginnameToRecnum!,
      labelNameToRecnum!,
      ticketNumberToId!,
      lexes,
    );

    // assign the description with all commands removed
    rest.description = sanitized_text;

    let newTickets: Ticket[] = await Promise.all(
      newTicketsForDispatch.map(
        createTicketEvent => dispatch(createTicketEvent).payload,
      ) as any,
    );

    // if we are online, wait 2s for ticketnumbers to be assigned
    // otherwise continue and reference the tickets by id as fallback
    if (newTickets.length && (await onlineService?._check())) {
      try {
        newTickets = await awaitStoreCondition(
          store as Store<Types.RootState>,
          ({ tickets }) =>
            Object.values(
              pick(
                newTickets.map(t => t.id),
                tickets,
              ),
            ),
          ts => ts.every(t => !!t.number),
        );
      } catch (e) {
        if (!(e instanceof TimeoutError)) {
          throw e;
        }
      }
    }

    // substitute create_ticket lexes for ticket references
    for (const newTicket of newTickets) {
      rest.description = rest.description.replace(
        new RegExp('#(\'|")' + regEscape(newTicket.title) + '\\1', 'g'),
        `#${newTicket.number || newTicket.id}+`,
      );
    }
    const nonEmptyOrUndefined = <T,>(a: T[]) => (a.length ? a : undefined);
    const labelsMod = {
      add_labels: nonEmptyOrUndefined([
        ...(add_labels || []), // add commands
        ...without(ticket?.labels || [], formChanges.labels || []), // added in form
      ]),
      remove_labels: nonEmptyOrUndefined([
        ...(remove_labels || []), // remove commands
        ...without(formChanges.labels || [], ticket?.labels || []), // removed in form
      ]),
    };

    if (ticketId === 'new') {
      const {
        payload: {
          id,
          events: [{ created }],
        },
      } = await dispatch(
        (createTicket as any)(
          {
            status: TicketStatus.OPENED,
            ...rest,
            ...update,
            ...labelsMod,
          },
          user!.recnum,
          meta,
        ),
      );
      eventActionsBuffer
        // XXX: this is really hacky: should fix
        .map((action, idx) => ({
          ...action,
          payload: {
            ...action.payload,
            event: {
              ...action.payload.event,
              ticket_id: id,
              created: new Date(new Date(created).getTime() + 10 + idx),
            },
          },
        }))
        .forEach(dispatch);
      setEventActionsBuffer([]);
      dispatch(replace(`/tickets/${id}`));
    } else {
      const diff = {
        ...labelsMod,
        ...diffObjects(
          pick(
            [
              'title',
              'description',
              'werk_id',
              'project_id',
              'assignee_user_id',
              'executor_user_id',
              'due',
            ],
            ticket,
          ),
          omit(['labels', 'creator_user_id'], {
            ...rest,
            ...parent,
            ...update,
          }),
        ),
      };

      if (toPairs(diff).filter(([, x]) => x !== undefined).length) {
        await dispatch(
          (updateTicket as any)(ticketId, diff, user!.recnum, meta),
        );
      }
      setEditing(false);
      dispatch(fetchWorks());
    }
  }, [
    formChanges,
    user,
    loginnameToRecnum,
    onlineService,
    store,
    labelNameToRecnum,
    ticketNumberToId,
    lexes,
    ticket,
    ticketId,
    dispatch,
    eventActionsBuffer,
  ]);

  const handleToggleStatus = React.useCallback(() => {
    dispatch(
      updateTicket(
        ticketId,
        {
          status:
            ticket!.status === TicketStatus.OPENED
              ? TicketStatus.CLOSED
              : TicketStatus.OPENED,
        },
        user!.recnum,
        {},
      ),
    );
  }, [dispatch, ticket, ticketId, user]);

  const handleToggleNotifications = React.useCallback(() => {
    dispatch(
      ticket!.notification_opt_in?.includes(user!.recnum)
        ? optOutTicketNotifications(ticketId, user!.recnum)
        : optInTicketNotifications(ticketId, user!.recnum),
    );
  }, [dispatch, ticket, ticketId, user]);

  const handleReset = React.useCallback(() => {
    if (ticketId === 'new') {
      dispatch(goBack());
    } else {
      setEditing(false);
    }
  }, [dispatch, ticketId]);

  let content;
  let storyline;

  const breadcrumbs = (
    <div className="hidden-print">
      <Breadcrumb project={project} work={work} ticket={ticket} />
    </div>
  );

  if (ready) {
    if (editing) {
      content = (
        <TicketDetailsEditor
          lexes={lexes}
          localTickets={localTickets}
          allTickets={tickets!}
          ticket={formChanges as any}
          onPatch={handlePatch}
          onSubmit={handleSubmit}
          onReset={handleReset}
          works={works!}
          projects={projects!}
          users={users!}
          user={user!}
        />
      );
    } else {
      content = (
        <TicketDetails
          work={work!}
          localTickets={localTickets}
          allTickets={tickets!}
          project={project!}
          onToggleStatus={handleToggleStatus}
          users={users!}
          ticket={ticket!}
          onEdit={handleEdit}
          onToggleNotifications={handleToggleNotifications}
        />
      );
    }

    if (ticket && ticketEventsAndReferences) {
      storyline = (
        <Timeline
          localTickets={localTickets}
          allTickets={tickets!}
          users={users!}
          lexes={lexes}
          events={filterIrrelevantEvents(
            processEditAndDeleteTicketEvents(
              ticketEventsAndReferences as any,
            ) as TimelineEvent[],
          )}
          onCreateEvent={handleCreateEvent}
          onDeleteEvent={handleDeleteEvent}
          onEditEvent={handleEditEvent}
          canChangeEvent={e =>
            !['CREATE_TICKET', 'UPDATE_TICKET'].includes(e.type)
          }
        />
      );
    } else if (ticketId === 'new') {
      storyline = (
        <Timeline
          localTickets={localTickets}
          allTickets={tickets!}
          users={users!}
          lexes={lexes}
          events={
            eventActionsBuffer.map(
              eventAction => eventAction.payload.event,
            ) as TimelineEvent[]
          }
          onCreateEvent={handleCreateEvent}
          canChangeEvent={F}
          onDeleteEvent={F}
          onEditEvent={F}
        />
      );
    } else {
      storyline = <Spinner />;
    }
  } else {
    content = <Spinner />;
  }

  return (
    <Page>
      {breadcrumbs}
      <div className={'container ' + styles.detailsContainer}>{content}</div>
      <hr className="visible-print-block" />
      <div className="container">{storyline}</div>
      {/* <Json>{ticket?.events}</Json> */}
    </Page>
  );
};

// make sure the component is recreated when ticket id changes
export default withKey(p => p.params.ticketId, TicketDetailsPage);

/**
 * Filter out irrelevant ticket events for displaying a clean timeline
 *
 * the following is filterred out:
 *   - additional mentions from the same source ticket
 *   - sequential modifications of the same field by the same user on she same day
 *
 * TODO: ideally these events are visibly hidden and accessible to users, for how we simply filter them out
 */
function filterIrrelevantEvents(events: TimelineEvent[]) {
  const references: string[] = [];
  let modificationDay: Date | null = null;
  let modificationUserId: number = 0;
  let modificationFields: string[] = [];
  return events.filter(e => {
    // return true;
    if (e.type === 'REFERENCE') {
      if (references.includes(e.payload.link)) {
        return false;
      } else {
        references.push(e.payload.link);
        return true;
      }
    }
    if (e.type === 'UPDATE_TICKET') {
      const { created, payload, user_id } = e;
      const fields = Object.keys(payload);
      const eventDay = startOfDay(new Date(created));
      if (
        isEqual(eventDay, modificationDay) &&
        modificationUserId === user_id
      ) {
        if (includesEvery(modificationFields, fields)) {
          // this update is by same user and on same day as ther previous update
          // it also contains no new fields
          modificationFields = fields;
          return false;
        }
      } else {
        modificationDay = eventDay;
        modificationUserId = user_id;
      }
    }
    return true;
  });
}
