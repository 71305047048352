import * as React from 'react';
import { Breadcrumb, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Page from '../Page';
import ActivityList from '../ActivityList/index';
import TicketsList from '../TicketsList';
import ChecklistsList from '../ChecklistsList';
import { FormattedMessage, defineMessages } from 'react-intl';
import ActivityFeedExplanation from '../ActivityFeedExplanation';
import LinkContainer from '../LinkContainer';

const msg = defineMessages({
  BREADCRUM_DASHBOARD: {
    id: 'MY_DASHBOARD_COMPONENT.BREADCRUM_DASHBOARD',
    defaultMessage: 'Dashboard',
    description: '',
  },
  HEADER_TASKS: {
    id: 'MY_DASHBOARD_COMPONENT.HEADER_TASKS',
    defaultMessage: 'Tasks',
    description: '',
  },
  BUTTON_SHOW_ALL_TASKS: {
    id: 'MY_DASHBOARD_COMPONENT.BUTTON_SHOW_ALL_TASKS',
    defaultMessage: 'Show all Tasks',
    description: '',
  },
  TICKET_HEADER_ACTIVITY: {
    id: 'MY_DASHBOARD_COMPONENT.TICKET_HEADER_ACTIVITY',
    defaultMessage: 'Activity',
    description: '',
  },
  BUTTON_SHOW_ALL_ACTIVITY: {
    id: 'MY_DASHBOARD_COMPONENT.BUTTON_SHOW_ALL_ACTIVITY',
    defaultMessage: 'Show all Activity',
    description: '',
  },
  TICKET_HEADER_OPEN_TICKETS: {
    id: 'MY_DASHBOARD_COMPONENT.TICKET_HEADER_OPEN_TICKETS',
    defaultMessage: 'Open Tickets',
    description: '',
  },
  BUTTON_SHOW_ALL_TICKETS: {
    id: 'MY_DASHBOARD_COMPONENT.BUTTON_SHOW_ALL_TICKETS',
    defaultMessage: 'Show all Tickets',
    description: '',
  },
  ACTIVITY_ERROR_CREATE_ACTIVITY: {
    id: 'MY_DASHBOARD_COMPONENT.ACTIVITY_ERROR_CREATE_ACTIVITY',
    defaultMessage: 'Something went wrong during creation of the activity',
    //Er gaat iets mis met het samenstellen van activiteit
    description: '',
  },
});

export default class MyDashboardComponent extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  render() {
    const {
      activity,
      checklists,
      filteredTickets,
      tickets,
      users,
      lists,
      works,
    } = this.props;
    return (
      <Page>
        <Breadcrumb>
          <Breadcrumb.Item active>
            <FormattedMessage {...msg.BREADCRUM_DASHBOARD} />
          </Breadcrumb.Item>
        </Breadcrumb>

        {/* Tasks */}
        <h5 className="listHeader">
          <span>
            <FormattedMessage {...msg.HEADER_TASKS} />
          </span>
          <LinkContainer to="/dashboard/tasks">
            <Button bsSize="small" bsStyle="link">
              <FormattedMessage {...msg.BUTTON_SHOW_ALL_TASKS} />{' '}
              <i className="fa fa-arrow-circle-o-right" />
            </Button>
          </LinkContainer>
        </h5>
        <ChecklistsList checklists={checklists} lists={lists} users={users} />

        {/* Tickets */}
        <h5 className="listHeader">
          <span>
            <FormattedMessage {...msg.TICKET_HEADER_OPEN_TICKETS} />
          </span>
          <LinkContainer to="/dashboard/tickets">
            <Button bsSize="small" bsStyle="link">
              <FormattedMessage {...msg.BUTTON_SHOW_ALL_TICKETS} />{' '}
              <i className="fa fa-arrow-circle-o-right" />
            </Button>
          </LinkContainer>
        </h5>
        <TicketsList tickets={filteredTickets} users={users} />

        {/* Activity */}
        <h5 className="listHeader">
          <span>
            <FormattedMessage {...msg.TICKET_HEADER_ACTIVITY} />
            <ActivityFeedExplanation />
          </span>
          <LinkContainer to="/dashboard/activity">
            <Button bsSize="small" bsStyle="link">
              <FormattedMessage {...msg.BUTTON_SHOW_ALL_ACTIVITY} />{' '}
              <i className="fa fa-arrow-circle-o-right" />
            </Button>
          </LinkContainer>
        </h5>
        {activity ? (
          <ActivityList
            activity={activity}
            users={users}
            works={works}
            lists={lists}
            tickets={tickets}
          />
        ) : (
          <span>
            <FormattedMessage {...msg.ACTIVITY_ERROR_CREATE_ACTIVITY} />
          </span>
          // <span>Er gaat iets mis met het samenstellen van activiteit</span>
        )}
      </Page>
    );
  }
}
