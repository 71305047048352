import * as React from 'react';
import { Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

const CloseButton = ({ closed, ...props }) => (
  <Button bsStyle={closed ? '' : 'primary'} className='pull-right' {...props}>
    {closed ? (
      <FormattedMessage id='OPEN' defaultMessage='Reopen' description='' />
    ) : (
      <FormattedMessage id='CLOSE' defaultMessage='Finalize' description='' />
    )}
  </Button>
);

export default CloseButton;
