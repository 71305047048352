import React, { Component } from 'react';
import PropTypes from 'prop-types';

import UserToken from '../UserToken';

export default class InputListItem extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    highlight: PropTypes.bool,
    onDeselectItem: PropTypes.func,
    getItemValue: PropTypes.func,
  };

  componentWillMount() {
    this.handleDeselectItem = this.handleDeselectItem.bind(this);
  }

  handleDeselectItem() {
    const { onDeselectItem, item } = this.props;
    onDeselectItem(item);
  }

  render() {
    const { item, onDeselectItem } = this.props;
    return (
      <>
        <UserToken
          user={item}
          withText
          small
          style={{ zIndex: 99 }}
          onDeselect={onDeselectItem && this.handleDeselectItem}
        />
      </>
    );
  }
}
