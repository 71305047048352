import qs from 'query-string';
import * as React from 'react';
import { Link } from 'wouter';
import { resolveUrl } from '../../utils/request';
import Page from '../Page';
import Spinner from '../Spinner';
interface ConfirmEmailProps {}

enum Status {
  BUSY,
  SUCCESS,
  FAILURE,
}

const ConfirmEmailPage: React.FC<ConfirmEmailProps> = props => {
  const [status, setStatus] = React.useState(Status.BUSY);
  React.useEffect(() => {
    (async () => {
      try {
        const { token } = qs.parse(window.location.search);

        if (!token) {
          setStatus(Status.FAILURE);
          return;
        }

        const { status } = await fetch(resolveUrl('/validate-email'), {
          method: 'POST',
          body: JSON.stringify({ token }),
          headers: { 'content-type': 'application/json' },
        });
        if (status >= 200 && status < 300) {
          setStatus(Status.SUCCESS);
        } else {
          setStatus(Status.FAILURE);
        }
      } catch (e) {
        setStatus(Status.FAILURE);
      }
    })();
  }, []);

  let content;
  switch (status) {
    case Status.BUSY:
      content = (
        <>
          <p style={{ textAlign: 'center' }}>Confirming email...</p>
          <Spinner />
        </>
      );
      break;
    case Status.FAILURE:
      content = (
        <>
          <p style={{ textAlign: 'center' }}>Unable to validate email.</p>
        </>
      );
      break;
    case Status.SUCCESS:
      content = (
        <>
          <p style={{ textAlign: 'center' }}>Email confirmed!</p>
          <p>
            <Link
              style={{ textAlign: 'center', display: 'block' }}
              to="/projects"
            >
              Go home
            </Link>
          </p>
        </>
      );
      break;

    default:
      break;
  }

  return <Page title="Confirm e-mail">{content}</Page>;
};

export default ConfirmEmailPage;
