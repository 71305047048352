import { BrowserHistory } from 'history';
import 'moment/locale/nl';
import * as React from 'react';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import { Store } from 'redux';
import Types from 'Types';
import AppRouter from './AppRouter';
import CustomContextProvider from './components/CustomContextProvicer/index';
import ReplaceStateDialog from './components/ReplaceStateDialog';
import msgs from './locales';
import { CountingRequestStoreProvider } from './stores/countingRequestStore';
import { CountingStoreProvider } from './stores/countingStore';
import { MaterialListStoreProvider } from './stores/materialListStore';

const locale = navigator?.language?.slice(0, 2).toLowerCase() || 'en';

export interface RootProps {
  store: Store<Types.RootState>;
  history: BrowserHistory;
}

const Root: React.FC<RootProps> = ({ store, history }) => (
  <Provider store={store}>
    <CountingStoreProvider>
      <CountingRequestStoreProvider>
        <MaterialListStoreProvider>
          <IntlProvider
            defaultLocale="nl"
            locale={locale}
            messages={(msgs as any)[locale]}
            onError={() => {}}
            onWarn={() => {}}
          >
            <CustomContextProvider>
              <AppRouter history={history} />
              <ReplaceStateDialog />
              {/* some modal components find #modal and mount a react portal in it */}
              <div id="modal"></div>
            </CustomContextProvider>
          </IntlProvider>
        </MaterialListStoreProvider>
      </CountingRequestStoreProvider>
    </CountingStoreProvider>
  </Provider>
);

export default Root;
