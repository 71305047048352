import omit from 'lodash/fp/omit';
import without from 'lodash/fp/without';
import {
  createModificationCreator,
  ListEventReducer,
} from '../ListModification';
import { getAllDescendants, patchListNode } from '../util';

export interface RemovePayload {
  targetNodeId: string;
}

export const removeNode = createModificationCreator<RemovePayload, 'REMOVE'>(
  'REMOVE',
);

export type RemoveListModification = ReturnType<typeof removeNode>;

const remove: ListEventReducer<RemoveListModification> = (
  list,
  mod: RemoveListModification,
) => {
  const {
    payload: { targetNodeId },
  } = mod;

  // 1. delete the target node from its parent
  const targetNode = list.index[targetNodeId];
  if (!targetNode) {
    // dont do anything if the node could not be found
    // this can happen when modifications are merged
    return list;
  }
  if (!targetNode.parentNodeId) {
    throw new Error('Unexpected target-node without parentNodeId (ROOT_FIELD)');
  }

  const newList = patchListNode(list, targetNode.parentNodeId, parentNode => {
    if (parentNode.type !== 'GROUP_FIELD' && parentNode.type !== 'ROOT_FIELD') {
      throw new Error(`Unexpected targetNode type (${parentNode.type})`);
    }

    return {
      ...parentNode,
      children: without([targetNode], parentNode.children),
    };
  });

  // 2. remove the targetnode and all descendants from the index
  newList.index = omit(
    getAllDescendants(targetNode).map(f => f.id),
    newList.index,
  );

  return newList;
};

export default remove;
