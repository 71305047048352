import PropTypes from 'prop-types';
import { identity } from 'ramda';
import * as React from 'react';
import { connect } from 'react-redux';
import WorkDetailsComponent from '../../components/WorkDetailsComponent/index';
import { fetchLabels } from '../../modules/labels';
import { fetchLists } from '../../modules/lists';
import { fetchMeetings } from '../../modules/meetings';
import { fetchProjects } from '../../modules/projects';
import { sorting } from '../../modules/sorting';
import { fetchTickets } from '../../modules/tickets';
import { fetchUsers } from '../../modules/users';
import { fetchWorks } from '../../modules/works';
import assertAndParseInt from '../../utils/assertAndParseInt';
import ignoreRejection from '../../utils/ignoreRejection';

class WorkDetailsContainerComponent extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.handleChangeSorting = this.handleChangeSorting.bind(this);
  }

  componentDidMount() {
    const {
      params: { workRecnum },
    } = this.props;

    ignoreRejection(
      this.props.dispatch(fetchWorks()),
      this.props.dispatch(fetchProjects()),
      this.props.dispatch(fetchUsers()),
      this.props.dispatch(fetchLists({ werk_id: workRecnum })),
      this.props.dispatch(fetchTickets({ werk_id: workRecnum })),
      this.props.dispatch(fetchMeetings()),
      this.props.dispatch(fetchLabels()),
    );
  }

  handleChangeSorting(scope, newFilter) {
    this.props.dispatch(sorting(scope, newFilter));
  }

  render() {
    const {
      works,
      router,
      lists,
      tickets,
      users,
      minutes,
      labels,
      params,
      ...rest
    } = this.props;
    const workRecnum = assertAndParseInt(params.workRecnum);
    let loading = true;
    let checklists;
    let filteredTickets;
    let filteredMinutes;
    if (lists && tickets && users && minutes) {
      loading = false;
      checklists = Object.values(lists)
        .filter(l => l.werk_id === workRecnum)
        .filter(l => !l.deleted);
      filteredTickets = Object.values(tickets).filter(
        ticket => ticket.werk_id === workRecnum,
      );
      filteredMinutes = Object.values(minutes)
        .filter(l => l.werk_id === workRecnum)
        .filter(l => !l.deleted);
    }

    let project = undefined;
    if (works[workRecnum] && rest.projects) {
      project = rest.projects[works[workRecnum].project_id];
    }

    return (
      <WorkDetailsComponent
        loading={loading}
        {...rest}
        works={works}
        project={project}
        minutes={filteredMinutes}
        handleChangeSorting={this.handleChangeSorting}
        work={works[workRecnum]}
        lists={lists}
        checklists={checklists}
        tickets={filteredTickets}
        users={users}
        labels={labels}
      />
    );
  }
}

export default connect(identity)(WorkDetailsContainerComponent);
