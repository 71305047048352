import bytes from 'bytes';
import * as React from 'react';
import { Button } from 'react-bootstrap';
import FormattedRelative from '../components/FormattedRelative';
import Page from '../components/Page';
import Spinner from '../components/Spinner';
import { useApiCall, useInitApiCall } from './useApiCall';

interface Props {}

const CacheAdministrationContainer: React.FC<Props> = ({}) => {
  let [cacheStats, err] = useInitApiCall<any>('/admin/cache');
  const apiCall = useApiCall();

  const handlePatchCache = React.useCallback(async (command: string) => {
    await apiCall<any>(`/admin/cache`, {
      method: 'PATCH',
      body: JSON.stringify({ command }),
    });
    window.location.reload();
  }, []);

  const handleEnableCache = React.useCallback<React.ChangeEventHandler<any>>(
    event => {
      handlePatchCache('enable');
    },
    [handlePatchCache],
  );
  const handleDisableCache = React.useCallback<React.ChangeEventHandler<any>>(
    event => {
      handlePatchCache('disable');
    },
    [handlePatchCache],
  );
  const handleClearCache = React.useCallback<React.ChangeEventHandler<any>>(
    event => {
      handlePatchCache('clear');
    },
    [handlePatchCache],
  );

  let content = <Spinner />;

  if (cacheStats) {
    const rows = [];
    for (const scope in cacheStats) {
      const data = cacheStats[scope];
      const totalServed =
        data.stats.uncachedBytesServed + data.stats.cachedBytesServed;
      rows.push(
        <tr>
          <td>{scope}</td>
          <td>
            <FormattedRelative value={data.uptime} style="narrow" />
          </td>
          <td className={data.enabled ? 'text-success' : 'text-danger'}>
            {data.enabled ? '✔' : '✗'}
          </td>

          <td>{bytes(data.stats.activeCacheSize)}</td>
          <td>
            {bytes(data.stats.uncachedBytesServed)} (
            {Math.round(
              (data.stats.uncachedBytesServed / totalServed || 0) * 100,
            )}
            %)
          </td>
          <td>
            {bytes(data.stats.cachedBytesServed)} (
            {Math.round(
              (data.stats.cachedBytesServed / totalServed || 0) * 100,
            )}
            %)
          </td>
        </tr>,
      );
    }

    content = (
      <>
        <table className="table table-striped">
          <thead>
            <tr>
              <th rowSpan={1}>Scope</th>
              <th rowSpan={1}>uptime</th>
              <th rowSpan={1}>enabled</th>
              <th rowSpan={1}>active cache size</th>
              <th colSpan={2}>data served</th>
            </tr>
            <tr>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th>uncached </th>
              <th>cached </th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
        <hr></hr>
        <Button onClick={handleEnableCache}>Enable caches</Button>
        <Button onClick={handleDisableCache}>Disable caches</Button>
        <Button onClick={handleClearCache} bsStyle="danger">
          clear caches
        </Button>
      </>
    );
  }

  return <Page>{content} </Page>;
};

export default CacheAdministrationContainer;
