import React from 'react';
import { ReportReadyContext } from '../utils/ReportReady/ReportReadyProvider';

enum ReportReadyPhase {
  begin,
  registerred,
  ready,
}

export default function useReportReady(
  readyConditionFn: () => boolean,
  deps: React.DependencyList,
) {
  const { ready, register } = React.useContext(ReportReadyContext);
  const [phase, setPhase] = React.useState(ReportReadyPhase.begin);
  React.useEffect(() => {
    if (phase === ReportReadyPhase.begin) {
      if (readyConditionFn()) {
        setPhase(ReportReadyPhase.ready);
      } else {
        setPhase(ReportReadyPhase.registerred);
        register();
      }
    } else if (phase === ReportReadyPhase.registerred && readyConditionFn()) {
      setPhase(ReportReadyPhase.ready);

      // fixme: dont rely on timeouts to wait for react to finish, use correct lifetime methods
      setTimeout(() => {
        ready();
      }, 1e3);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phase, register, ready, setPhase, readyConditionFn, ...deps]);

  return phase === ReportReadyPhase.ready;
}
