import { omit } from 'lodash/fp';
import * as qs from 'query-string';
import { CALL_API } from 'redux-api-middleware';
import * as _uuid from 'uuid';
import Feedback from '../models/Feedback';
import { mapById, resolveHeaders, resolveUrl } from '../utils';
import { softAssertNever } from './utils';

const uuid = _uuid.v4;

export interface StoreFeedback extends Feedback {
  _sync?: boolean;
}

export interface CreateFeedbackAction {
  type: 'CREATE_FEEDBACK';
  payload: StoreFeedback;
}
export const createFeedback = (
  props: Pick<
    Feedback,
    | 'instance_list_id'
    | 'instance_node_id'
    | 'user_id'
    | 'message'
    | 'proto_list_id'
    | 'proto_node_id'
    | 'updated'
  >,
): CreateFeedbackAction => {
  if (!props.instance_list_id || !props.instance_node_id) {
    throw new Error('incomplete feedback');
  }
  return {
    type: 'CREATE_FEEDBACK',
    payload: {
      ...props,
      handled: false,
      message: props.message,
      _sync: true,
      handled_user_id: null,
      id: uuid(),
      timestamp: new Date(),
      updated: new Date(),
    },
  };
};

export interface RequestSendFeedbackAction {
  type: 'REQUEST_SEND_FEEDBACK';
}
export interface SuccessSendFeedbackAction {
  type: 'SUCCESS_SEND_FEEDBACK';
  payload: Feedback;
}
export interface FailureSendFeedbackAction {
  type: 'FAILURE_SEND_FEEDBACK';
  error: true;
}

export function saveFeedback(feedback: Partial<StoreFeedback>): any {
  if (!feedback.id) {
    throw new Error('id not set');
  }
  let url;
  let method;
  if (feedback._sync) {
    // this is new
    url = '/feedback';
    method = 'POST';
  } else {
    // this is existing
    url = `/feedback/${feedback.id}`;
    method = 'PATCH';
  }
  return {
    [CALL_API]: {
      endpoint: resolveUrl(url),
      method,
      body: JSON.stringify(omit(['_sync'], feedback)),
      headers: resolveHeaders(),
      types: [
        'REQUEST_SEND_FEEDBACK',
        'SUCCESS_SEND_FEEDBACK',
        'FAILURE_SEND_FEEDBACK',
      ],
    },
  };
}

export interface RequestFetchFeedbackAction {
  type: 'REQUEST_FETCH_FEEDBACK';
}
export interface SuccessFetchFeedbackAction {
  type: 'SUCCESS_FETCH_FEEDBACK';
  payload: Feedback[];
}
export interface FailureFetchFeedbackAction {
  type: 'FAILURE_FETCH_FEEDBACK';
  error: true;
}

export interface FeedbackQuery {
  handled?: boolean;
  proto_list_id?: number;
  user_id?: number;
}

export function fetchFeedback(qry: FeedbackQuery = {}): any {
  return {
    [CALL_API]: {
      endpoint: resolveUrl(`/feedback?${qs.stringify(qry)}`),
      method: 'GET',
      headers: resolveHeaders(),
      types: [
        'REQUEST_FETCH_FEEDBACK',
        'SUCCESS_FETCH_FEEDBACK',
        'FAILURE_FETCH_FEEDBACK',
      ],
    },
  };
}

export const fetchFeedbackForList = (proto_list_id: number) =>
  fetchFeedback({ proto_list_id });

export const fetchUnhandledFeedback = () => fetchFeedback({ handled: false }); // FIXME

export const fetchFeedbackFromUser = (user_id: number) =>
  fetchFeedback({ user_id });

export interface FeedbackState {
  [id: string]: StoreFeedback;
}

export type FeedbackAction =
  | RequestFetchFeedbackAction
  | SuccessFetchFeedbackAction
  | FailureFetchFeedbackAction
  | RequestSendFeedbackAction
  | SuccessSendFeedbackAction
  | FailureSendFeedbackAction
  | CreateFeedbackAction;

export default function feedback(
  state: FeedbackState = {},
  action: FeedbackAction,
): FeedbackState {
  if (action.type === 'SUCCESS_FETCH_FEEDBACK') {
    return {
      ...state,
      ...mapById(action.payload),
    };
  } else if (
    action.type === 'SUCCESS_SEND_FEEDBACK' ||
    action.type === 'CREATE_FEEDBACK'
  ) {
    return { ...state, [action.payload.id]: action.payload };
  } else if (
    action.type === 'FAILURE_FETCH_FEEDBACK' ||
    action.type === 'FAILURE_SEND_FEEDBACK' ||
    action.type === 'REQUEST_FETCH_FEEDBACK' ||
    action.type === 'REQUEST_SEND_FEEDBACK'
  ) {
    return state;
  }
  softAssertNever(action);
  return state;
}
