import * as uuid from 'uuid';
import { ObjectMap } from '../../../logic/types';
import { Field } from '../../types';
import {
  createModificationCreator,
  ListEventReducer,
} from '../ListModification';
import { patchListNode } from '../util';
const uuidv5 = uuid.v5;
export interface ExpandProtoPayload {
  targetNodeId: string;
  protoListId: string;
}

export const expandProtoList = createModificationCreator<
  ExpandProtoPayload,
  'EXPAND_PROTO'
>('EXPAND_PROTO');

export type ExpandProtoListModification = ReturnType<typeof expandProtoList>;

const expandProto: ListEventReducer<ExpandProtoListModification> = (
  list,
  mod,
) => {
  const {
    payload: { protoListId, targetNodeId },
  } = mod;

  const proto = list.builtDependencies[protoListId];
  if (!proto) {
    throw new Error('Could not find proto-list for expandProto-modification');
  }

  // 1.  compute new, deterministic ids for the nodes
  //   + also update parentNodeId references
  //   + set the parentNodeId of first level children to id of future parent
  //   + index new nodes
  const newNodes: ObjectMap<Field> = {};

  const namespace = mod.id;
  function newIds(node: Field, parentNodeId: string): Field {
    const id = uuidv5(namespace, node.id);
    if (node.type === 'ROOT_FIELD') {
      throw new Error('Unexpected proto field; cant insert RootField');
    }

    const newNode: Field = {
      ...node,
      protoChain: [
        { listId: protoListId, nodeId: node.id },
        ...node.protoChain,
      ],
      modifiedFields: [],
      id,
      parentNodeId,
    };

    // recursively create new ids for nested structure
    if (newNode.type === 'GROUP_FIELD') {
      newNode.children = newNode.children.map(child => newIds(child, id));
    }

    newNodes[id] = newNode;

    return newNode;
  }
  if (proto.tree.type !== 'ROOT_FIELD') {
    throw new Error('Expected proto to be a root-field');
  }
  const protoFields = proto.tree.children.map(f => newIds(f, targetNodeId));

  let found = false;
  // 2. insert the protolist to the targetNode
  const newList = patchListNode(list, targetNodeId, targetNode => {
    if (targetNode.type !== 'ROOT_FIELD' && targetNode.type !== 'GROUP_FIELD') {
      throw new Error('Expected expandProto target to be a structure-field');
    }
    found = true;
    return {
      ...targetNode,
      children: [...targetNode.children, ...protoFields],
    };
  });

  // 3. add all nodes to the index
  if (found) {
    newList.index = { ...newNodes, ...newList.index };
  }

  return newList;
};

export default expandProto;
