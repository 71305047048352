import { LexMatch } from '../../utils/LexicalParsing';

export function replaceMatch(
  sanitized_text: string,
  match: LexMatch,
  replace: string = '',
) {
  sanitized_text =
    sanitized_text.slice(0, match.index) +
    replace +
    sanitized_text.slice(match.index + match.totalLength);
  return sanitized_text;
}
