import { Label } from '@certhon/domain-models/lib';
import React, { useCallback, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { LabelEditing } from '../../containers/LabelsOverviewContainer';
import { LabelsState } from '../../modules/labels';
import Json from '../Json';
import Page from '../Page';
import ParameterComponent from '../ParameterizedSearch/ParameterComponent';
import classNames from 'classnames';
import stl from './labels.module.scss';
import Spinner from '../Spinner';
import { sortWith, ascend, prop } from 'ramda';

interface Props extends LabelEditing {
  labels: LabelsState;
}

const sortLabels = sortWith<Label>([
  ascend(prop('deleted')),
  ascend(prop('recnum')),
]);

const LabelsOverviewPage: React.FC<Props> = ({
  labels,
  postLabel,
  patchLabel,
}) => {
  const [errorMsg, setErrorMsg] = useState('');
  const [editing, setEditing] = useState<null | number | 'new'>(null);

  const handleDeleteLabel = useCallback(
    async (label: Label) => {
      setErrorMsg('');
      const res = (await patchLabel({
        ...label,
        deleted: !label.deleted,
      })) as any;
    },
    [patchLabel],
  );
  // const handlEditLabel = useCallback(
  //   async (label: Label) => {
  //     setErrorMsg('');
  //     const res = (await deleteLabel(label)) as any;
  //   },
  //   [deleteLabel],
  // );

  const handleCreateSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const form = e.currentTarget; // event will be invalid
      setErrorMsg('');
      const lbl: Label = Object.fromEntries(
        new FormData(form).entries(),
      ) as any;
      lbl.deleted = false;
      lbl.color = (lbl.color as string).replace('#', '');
      lbl.lists = !!lbl.lists;
      lbl.tickets = !!lbl.tickets;
      lbl.recnum = lbl.recnum ? parseInt(lbl.recnum as any) : lbl.recnum;
      const res = (await (lbl.recnum ? patchLabel : postLabel)(lbl)) as any;
      if (res.error) {
        setErrorMsg(
          res.payload?.response?.error || res.payload?.message || 'Error',
        );
        return;
      }
      form.reset();
      setEditing(null);
    },
    [postLabel, patchLabel],
  );
  return (
    <Page title="Labels">
      {labels ? (
        <table className="table table-stiped">
          <thead>
            <tr>
              <th>Name and color</th>
              <th>Description</th>
              <th>tickets</th>
              <th>lists</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {sortLabels(Object.values(labels) as any).map(l =>
              l!.recnum === editing ? (
                <LabelEditingRow
                  key={l?.recnum}
                  label={l}
                  onSubmit={handleCreateSubmit}
                />
              ) : (
                <LabelTableRow
                  label={l!}
                  key={l?.recnum}
                  onDelete={handleDeleteLabel}
                  onEdit={() => setEditing(l!.recnum)}
                />
              ),
            )}
          </tbody>
          <tfoot>
            {editing === 'new' ? (
              <LabelEditingRow onSubmit={handleCreateSubmit}></LabelEditingRow>
            ) : (
              <tr>
                <td colSpan={5}>
                  <button
                    className="pull-right"
                    type="button"
                    onClick={() => {
                      setEditing('new');
                    }}
                  >
                    Create new label
                  </button>
                </td>
              </tr>
            )}
          </tfoot>
        </table>
      ) : (
        <Spinner />
      )}
      {errorMsg && <Alert bsStyle="danger">{errorMsg}</Alert>}
      {/* <Json>{labels}</Json> */}
    </Page>
  );
};

export default LabelsOverviewPage;

const LabelTableRow: React.FC<{
  label: Label;
  onEdit: (l: Label) => void;
  onDelete: (l: Label) => void;
}> = ({ label, onDelete, onEdit }) => {
  return (
    <tr className={classNames(label.deleted && stl.deleted)}>
      <td>
        <ParameterComponent color={`#${label.color}`} value={label.name} />
      </td>
      <td>{label.description}</td>
      <td>
        <input type="checkbox" disabled checked={label.tickets} />
      </td>
      <td>
        <input type="checkbox" disabled checked={label.lists} />
      </td>
      <td>
        {label.deleted ? (
          <button
            title="Restore deleted label"
            className="btn btn-link"
            type="button"
            onClick={() => onDelete(label)}
          >
            <i className="fa fa-undo "></i>
          </button>
        ) : (
          <>
            <button
              title="edit label"
              className="btn btn-link"
              type="button"
              onClick={() => onEdit(label)}
            >
              <i className="fa fa-pencil"></i>
            </button>
            <button
              title="Delete label"
              className="btn btn-link"
              type="button"
              onClick={() => onDelete(label)}
            >
              <i className="fa fa-trash"></i>
            </button>
          </>
        )}
      </td>
    </tr>
  );
};

const LabelEditingRow: React.FC<{
  label?: Label;
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  submitMsg?: string;
}> = ({ label, onSubmit, submitMsg = 'Submit' }) => {
  return (
    <tr>
      <td>
        <form id="labelform" onSubmit={onSubmit}>
          {label?.recnum && (
            <input type="hidden" name="recnum" value={label.recnum} />
          )}
          <input
            name="name"
            type="text"
            style={{ width: 100 }}
            defaultValue={label?.name}
          />
          <br />
          <input
            type="color"
            name="color"
            id=""
            defaultValue={label?.color ? '#' + label.color : '#999999'}
          />
        </form>
      </td>
      <td>
        <textarea
          name="description"
          form="labelform"
          cols={30}
          rows={1}
          defaultValue={label?.description}
        ></textarea>
      </td>
      <td>
        <input
          type="checkbox"
          name="tickets"
          form="labelform"
          id=""
          defaultChecked={
            typeof label?.tickets === 'boolean' ? label.tickets : true
          }
          defaultValue="true"
        />
      </td>
      <td>
        <input
          type="checkbox"
          name="lists"
          form="labelform"
          disabled
          id=""
          defaultChecked={label?.lists}
          defaultValue="true"
        />
      </td>
      <td>
        <input form="labelform" type="submit" value={submitMsg} />
      </td>
    </tr>
  );
};
