import * as React from 'react';
import { useDispatch } from 'react-redux';
import { AugmentedCatalogFile } from '../models/Catalog';
import {
  DownloadFileAction,
  FileDownloadState,
} from '../modules/fileDownloads';
import fileStorage, { filePreviewStorage } from '../services/fileStorage';

const MAX_HEIGHT = 500;
const MAX_WIDTH = 500;

async function generatePreview(blob: Blob): Promise<Blob> {
  const pdfjs = await import('pdfjs-dist/webpack');

  const pdf = await pdfjs.getDocument(blob as any).promise;
  const page1 = await pdf.getPage(1);
  const { width, height } = page1.getViewport({ scale: 1 });
  const viewport = page1.getViewport({
    scale: Math.min(
      Math.min(width, MAX_WIDTH / width),
      Math.min(height, MAX_HEIGHT / height),
    ),
  });

  // Prepare canvas using PDF page dimensions
  const canvas = document.createElement('canvas');
  const canvasContext = canvas.getContext('2d');
  if (!canvasContext) {
    throw new Error('Failed to create canvascontext');
  }
  canvas.height = viewport.height;
  canvas.width = viewport.width;

  // Render PDF page into canvas context
  await page1.render({ canvasContext, viewport });
  return new Promise(resolve => {
    canvas.toBlob(resolve as any); // XXX
  });
}

interface FilePreviewBusy {
  status: 'busy';
  task: string;
  src: null;
}
interface FilePreviewDone {
  status: 'ready';
  src: string;
}
interface FilePreviewFailed {
  status: 'failed';
  error: string;
  src: null;
  errorCode?: FilePreviewError;
}

export enum FilePreviewError {
  FILE_NOT_PDF,
  GENERATING_PREVIEW_FAILED,
  DOWNLOAD_FAILED,
  UNEXPECTED_STATE,
}

type FilePreviewStatus = FilePreviewBusy | FilePreviewDone | FilePreviewFailed;

export default function useFilePreview(file: AugmentedCatalogFile) {
  const [status, setStatus] = React.useState<FilePreviewStatus>({
    status: 'busy',
    src: null,
    task: 'Initializing',
  });

  const [retried, setRetried] = React.useState<boolean>(false);
  const dispatch = useDispatch();
  const retryOnce = React.useCallback(() => {
    if (!retried) {
      setRetried(true);
      const action: DownloadFileAction = {
        type: 'DOWNLOAD_FILE',
        payload: { ...file, punch: true },
      };
      dispatch(action);
    }
  }, [dispatch, file, retried]);

  React.useEffect(() => {
    return function cleanupPreview() {
      if (status?.src) {
        URL.revokeObjectURL(status.src);
      }
    };
  }, [status]);
  function openPreview(preview: Blob) {
    setStatus({
      status: 'ready',
      src: URL.createObjectURL(preview),
    });
  }

  React.useEffect(() => {
    if (file.mime !== 'application/pdf') {
      setStatus({
        status: 'failed',
        error: 'Only PDF preview is supported',
        errorCode: FilePreviewError.FILE_NOT_PDF,
        src: null,
      });
    } else if (file.file) {
      setStatus({ status: 'busy', task: 'Opening preview', src: null });
      filePreviewStorage.getItem<Blob | null>(file.checksum).then(preview => {
        if (preview) {
          openPreview(preview);
        } else {
          setStatus({ status: 'busy', task: 'Generating preview', src: null });
          fileStorage.getItem<Blob>(file.checksum).then(async fileContents => {
            if (!fileContents) {
              setStatus({
                status: 'failed',
                error: 'Generating the preview failed',
                errorCode: FilePreviewError.GENERATING_PREVIEW_FAILED,
                src: null,
              });
              return;
            }
            try {
              const preview = await generatePreview(fileContents);
              openPreview(preview);
              filePreviewStorage.setItem<Blob>(file.checksum, preview);
            } catch (e) {
              setStatus({
                status: 'failed',
                error: 'Generating the preview failed',
                errorCode: FilePreviewError.GENERATING_PREVIEW_FAILED,
                src: null,
              });
              retryOnce();
            }
          });
        }
      });
    } else if (
      !file.download ||
      file.download.state === FileDownloadState.FAILED
    ) {
      setStatus({
        status: 'failed',
        error: 'File download has failed',
        errorCode: FilePreviewError.DOWNLOAD_FAILED,
        src: null,
      });
    } else if (
      file.download &&
      file.download.state === FileDownloadState.DOWNLOADING
    ) {
      setStatus({
        status: 'busy',
        src: null,
        task: 'Downloading file',
      });
    } else if (
      file.download &&
      file.download.state === FileDownloadState.PENDING
    ) {
      setStatus({
        status: 'busy',
        task: 'Waiting for download to begin',
        src: null,
      });
    } else {
      setStatus({
        status: 'failed',
        error: 'Unexpected state',
        errorCode: FilePreviewError.UNEXPECTED_STATE,
        src: null,
      });
    }
  }, [file]);

  if (status === null) {
    throw new Error('status not set');
  }
  return status;
}
