import { Meta, TicketEvent, TicketStatus } from '@certhon/domain-models/lib';
import qs from 'query-string';
import * as R from 'ramda';
import { createAction } from 'redux-actions';
import { CALL_API, RSAAction } from 'redux-api-middleware';
import * as _uuid from 'uuid';
import { parseDate } from '../common/logic/misc';
import { createTicketEvent } from '../common/logic/ticket';
import { MakeOptional, MakeRequired } from '../common/logic/types';
import { Ticket, TicketEventType, TicketWithEvents } from '../models/Ticket';
import { resolveHeaders, resolveUrl } from '../utils';
import { mapById } from '../utils/misc';
import { Attachment } from './attachments';

const uuid = _uuid.v4;

export interface StoreTicketWithEvents
  extends MakeOptional<TicketWithEvents, 'number'> {
  _modified: null | string;
}
export type StoreTicket = MakeOptional<StoreTicketWithEvents, 'events'>;

export interface TicketUpdate extends Partial<Omit<Ticket, 'events'>> {
  /**@deprecated*/ add_tags?: string[];
  /**@deprecated*/ remove_tags?: string[];
  add_labels?: number[];
  remove_labels?: number[];
}

function isStoreTicketWithEvents(t: StoreTicket): t is StoreTicketWithEvents {
  return !!t.events;
}

function assertStoreTicketWithEvents(
  t: StoreTicket,
): asserts t is StoreTicketWithEvents {
  if (!isStoreTicketWithEvents(t)) {
    throw new Error(
      'Assertion failed: expected type StoreticketWithEvents, events were not found',
    );
  }
}

const omitBlob = R.omit(['blob']);

export const REQUEST_FETCH_TICKETS = 'REQUEST_FETCH_TICKETS';
export interface RequestFetchTicketsAction {
  type: 'REQUEST_FETCH_TICKETS';
}
export const SUCCESS_FETCH_TICKETS = 'SUCCESS_FETCH_TICKETS';
export interface SuccessFetchTicketsAction {
  type: 'SUCCESS_FETCH_TICKETS';
  payload: Ticket[];
  meta: { query: any };
}
export const FAILURE_FETCH_TICKETS = 'FAILURE_FETCH_TICKETS';
export interface FailureFetchTicketsAction {
  type: 'FAILURE_FETCH_TICKETS';
}

export type FetchTicketsQuery = (
  | { werk_id?: number }
  | { project_id?: number }
) & {
  shard?: string;
  closed_before?: Date;
  closed_since_or_open?: Date;
  skip_events?: boolean;
};

const formatTicketQueryDates = R.evolve({
  closed_before: (d?: Date) => d?.toISOString().split('T')[0],
  closed_since_or_open: (d?: Date) => d?.toISOString().split('T')[0],
});

export function fetchTickets(query?: FetchTicketsQuery) {
  let successType: any = SUCCESS_FETCH_TICKETS;
  let qryString = '';
  if (query) {
    successType = { type: SUCCESS_FETCH_TICKETS, meta: { query } };
    qryString = '?' + qs.stringify(formatTicketQueryDates(query));
  }

  return {
    [CALL_API]: {
      endpoint: resolveUrl(`/tickets${qryString}`),
      method: 'GET',
      headers: resolveHeaders(),
      types: [REQUEST_FETCH_TICKETS, successType, FAILURE_FETCH_TICKETS],
    },
  };
}

export const REQUEST_FETCH_TICKET = 'REQUEST_FETCH_TICKET';
export interface RequestFetchTicketAction {
  type: 'REQUEST_FETCH_TICKET';
}
export const SUCCESS_FETCH_TICKET = 'SUCCESS_FETCH_TICKET';
export interface SuccessFetchTicketAction {
  type: 'SUCCESS_FETCH_TICKET';
  payload: Ticket;
}
export const FAILURE_FETCH_TICKET = 'FAILURE_FETCH_TICKET';
export interface FailureFetchTicketAction {
  type: 'FAILURE_FETCH_TICKET';
}

export function fetchTicket(id: string): RSAAction<any, any, any> {
  return {
    [CALL_API]: {
      endpoint: resolveUrl('/tickets/' + id),
      method: 'GET',
      headers: resolveHeaders(),
      types: [REQUEST_FETCH_TICKET, SUCCESS_FETCH_TICKET, FAILURE_FETCH_TICKET],
    },
  };
}

export const REQUEST_SAVE_TICKET = 'REQUEST_SAVE_TICKET';
export interface RequestSaveTicketAction {
  type: 'REQUEST_SAVE_TICKET';
}

export const SUCCESS_SAVE_TICKET = 'SUCCESS_SAVE_TICKET';
export interface SuccessSaveTicketAction {
  type: 'SUCCESS_SAVE_TICKET';
  payload: Ticket;
}

export const FAILURE_SAVE_TICKET = 'FAILURE_SAVE_TICKET';
export interface FailureSaveTicketAction {
  type: 'FAILURE_SAVE_TICKET';
}

export function saveTicket(ticket: StoreTicket): RSAAction<any, any, any> {
  let url;
  assertStoreTicketWithEvents(ticket);
  const body = JSON.stringify({ events: ticket.events.filter(e => !e.recnum) });
  if (ticket.recnum) {
    url = `/tickets/${ticket.recnum}/events`;
  } else {
    url = `/tickets`;
  }

  return {
    [CALL_API]: {
      endpoint: resolveUrl(url),
      method: 'POST',
      body,
      headers: resolveHeaders(),
      types: [REQUEST_SAVE_TICKET, SUCCESS_SAVE_TICKET, FAILURE_SAVE_TICKET],
    },
  };
}

export const CREATE_TICKET = 'CREATE_TICKET';
export interface CreateTicketAction {
  type: 'CREATE_TICKET';
  payload: StoreTicket;
}
export const createTicket = createAction(
  CREATE_TICKET,
  (
    {
      id = uuid(),
      creator_user_id,
      description = '',
      status = TicketStatus.OPENED,
      add_tags,
      add_labels,
      due = new Date(),
      ...ticket
    }: MakeRequired<TicketUpdate, 'title'>,
    userId: number,
    meta: Meta,
  ): StoreTicket => {
    const event = createTicketEvent(
      id,
      TicketEventType.CREATE_TICKET,
      { due, description, status, ...ticket, add_tags, add_labels },
      userId,
      meta,
    );

    return {
      /* defaults */
      assignee_user_id: null,
      executor_user_id: null,
      created: new Date(),
      modified: new Date(),
      werk_id: null,
      project_id: null,
      due,

      notification_opt_in: [], // this is incorrect, but is is managed by the server
      notification_opt_out: [], // this is incorrect, but is is managed by the server

      ...ticket,
      id,
      description,
      status,
      tags: add_tags || [],
      labels: add_labels || [],
      creator_user_id: userId,
      // init. with creation event, required by backend
      events: [event],
      _modified: event.id,
    };
  },
);

export const ADD_TICKET_EVENT = 'ADD_TICKET_EVENT';
export interface AddTicketEventAction {
  type: 'ADD_TICKET_EVENT';
  payload: {
    id: string;
    event: TicketEvent;
  };
}
export const updateTicket = createAction(
  ADD_TICKET_EVENT,
  (id: string, partial: Partial<TicketUpdate>, userId: number, meta: Meta) => ({
    id,
    event: createTicketEvent(
      id,
      TicketEventType.UPDATE_TICKET,
      partial,
      userId,
      meta,
    ),
  }),
);

export const optInTicketNotifications = createAction(
  ADD_TICKET_EVENT,
  (id: string, userId: number) => ({
    id,
    event: createTicketEvent(
      id,
      TicketEventType.NOTIFICATION_OPT_IN,
      {},
      userId,
    ),
  }),
);

export const optOutTicketNotifications = createAction(
  ADD_TICKET_EVENT,
  (id: string, userId: number) => ({
    id,
    event: createTicketEvent(
      id,
      TicketEventType.NOTIFICATION_OPT_OUT,
      {},
      userId,
    ),
  }),
);

export const ADD_TICKET_MESSAGE = 'ADD_TICKET_MESSAGE';
export const addTicketMessage = createAction(
  ADD_TICKET_EVENT,
  (id: string, message: string, userId: number, meta: Meta) => {
    if (!message) {
      // FIXME: this is not te place for input validation
      alert('Message cannot be empty');
      throw new Error('Ticket message cannot be empty');
    }
    return {
      id,
      event: createTicketEvent(
        id,
        TicketEventType.ADD_MESSAGE,
        { message },
        userId,
        meta,
      ),
    };
  },
);

export const UPDATE_TICKET_MESSAGE = 'UPDATE_TICKET_MESSAGE';
export const updateTicketMessage = (createAction as any)(
  ADD_TICKET_EVENT,
  (
    // FIXME: too little overloads...
    id: string,
    eventId: string,
    message: string,
    userId: number,
    meta: Meta,
  ) => {
    if (!message) {
      alert('Message cannot be empty'); // FIXME
      throw new Error('Ticket message cannot be empty');
    }
    return {
      id,
      event: createTicketEvent(
        id,
        TicketEventType.UPDATE_MESSAGE,
        { eventId, message },
        userId,
        meta,
      ),
    };
  },
);
export const DELETE_TICKET_MESSAGE = 'DELETE_TICKET_MESSAGE';
export const deleteTicketMessage = createAction(
  ADD_TICKET_EVENT,
  (id: string, eventId: string, userId: number) => ({
    id,
    event: createTicketEvent(
      id,
      TicketEventType.DELETE_MESSAGE,
      { eventId },
      userId,
    ),
  }),
);

export const ADD_TICKET_ATTACHMENT = 'ADD_TICKET_ATTACHMENT';
/**
 * @function addTicketAttachment
 * @description Add a attachment to a ticket
 * @param {string} id The id of the attachment
 * @param {object} payload.attachment
 * @param {object} payload.attachment.checksum
 * @param {object} payload.attachment.contentType
 * @param {object} payload.attachment.size
 * @param {number} userId The id of the editing user
 */
export const addTicketAttachment = createAction(
  ADD_TICKET_EVENT,
  (id: string, attachment: Attachment, userId: number) => ({
    id,
    event: createTicketEvent(
      id,
      TicketEventType.ADD_ATTACHMENT,
      omitBlob(attachment),
      userId,
    ),
  }),
);

export const DELETE_TICKET_ATTACHMENT = 'DELETE_TICKET_ATTACHMENT';
export const deleteTicketAttachment = createAction(
  ADD_TICKET_EVENT,
  (id: string, eventId: string, userId: number) => ({
    id,
    event: createTicketEvent(
      id,
      TicketEventType.DELETE_ATTACHMENT,
      { eventId },
      userId,
    ),
  }),
);

export type GeoTag = { location: GeolocationPosition; label: string };

export const ADD_TICKET_LOCATION = 'ADD_TICKET_LOCATION';
export const addTicketLocation = createAction(
  ADD_TICKET_EVENT,
  (id: string, location: GeoTag, userId: number) => ({
    id,
    event: createTicketEvent(
      id,
      TicketEventType.ADD_LOCATION,
      location,
      userId,
    ),
  }),
);

export const UPDATE_TICKET_LOCATION = 'UPDATE_TICKET_LOCATION';
export const updateTicketLocation = createAction(ADD_TICKET_EVENT, (
  // FIXME: too little overloads...
  id: string,
  eventId: string,
  update: Partial<GeoTag>,
  userId: number,
) => ({
  id,
  event: createTicketEvent(
    id,
    TicketEventType.UPDATE_LOCATION,
    { eventId, ...update },
    userId,
    // meta,
  ),
}));

export const DELETE_TICKET_LOCATION = 'DELETE_TICKET_LOCATION';
export const deleteTicketLocation = createAction(
  ADD_TICKET_EVENT,
  (id: string, eventId: string, userId: number) => ({
    id,
    event: createTicketEvent(
      id,
      TicketEventType.DELETE_LOCATION,
      { eventId },
      userId,
    ),
  }),
);

export type TicketAction =
  | RequestFetchTicketsAction
  | SuccessFetchTicketsAction
  | FailureFetchTicketsAction
  | RequestFetchTicketAction
  | SuccessFetchTicketAction
  | FailureFetchTicketAction
  | RequestSaveTicketAction
  | SuccessSaveTicketAction
  | FailureSaveTicketAction
  | CreateTicketAction
  | AddTicketEventAction;

export type TicketsState = Record<string, StoreTicket> | null;

/**
 * modifies state to add event
 * @param {object} state
 * @param {event} param1
 */
const addTicketEvent = (
  state: TicketsState,
  action: AddTicketEventAction,
): TicketsState => {
  const {
    payload: { event },
  } = action;
  const { type, ticket_id } = event;
  const {
    add_labels = [],
    remove_labels = [],
    add_tags = [],
    remove_tags = [],
    ...changes
  } = event.payload;

  const ticket = state?.[ticket_id];
  if (!ticket) {
    return state;
  }
  assertStoreTicketWithEvents(ticket);

  const newTicket: StoreTicket = {
    ...ticket,
    ...(type === TicketEventType.UPDATE_TICKET ? changes : {}),
    labels: R.uniq(
      R.concat(add_labels, R.without(remove_labels, ticket.labels)),
    ),
    events: [...ticket.events, event],
    _modified: event.id,
  };

  return {
    ...state,
    [ticket_id]: newTicket,
  };
};

// FIXME: strict typing, no any
const onlyUnsyncedTickets = (ts: TicketsState): TicketsState => {
  if (ts === null) {
    return ts;
  }
  return R.pipe(
    R.toPairs,
    R.filter(([id, ticket]) => !ticket.recnum) as any,
    R.fromPairs,
  )(ts) as TicketsState;
};

export default function tickets(
  state: TicketsState = null,
  action: TicketAction,
): TicketsState {
  if (action.type === 'SUCCESS_FETCH_TICKET') {
    const { payload } = action;
    const ticket = state?.[payload.id];
    // if (!ticket) {
    //   return state;
    // }
    // cant overwrite 'modified' tickets
    if (ticket?._modified) {
      return state;
    }
    return {
      ...state,
      [payload.id]: { ...payload, _modified: null },
    };
  } else if (action.type === 'SUCCESS_FETCH_TICKETS') {
    const { payload, meta } = action;
    // this tickets have local changes and must not be overwritten
    // const changedTicketIds: string[] = !state
    // ? []
    // : (Object.values(state)
    // .filter(t => t?._modified)
    // .map(t => t?.id) as string[]);

    let newState: TicketsState = state || {};

    // these tickets have never been synced and must be preserved
    const unsyncedTickets = onlyUnsyncedTickets(newState);
    let locallyEditedOrMoreRecentlySyncedTickets: StoreTicket[] = [];
    let [ticketsToReplace] = R.partition((serverTicket: Ticket) => {
      const localTicket = newState?.[serverTicket.id] || null;
      if (
        !localTicket ||
        (!localTicket._modified && // has no local changes
          parseDate(localTicket.modified) <= parseDate(serverTicket.modified)) // we have received a more recent server-version earlier (http://gitlab.certhon.com/certhon/QA/-/issues/834#note_27014)
      ) {
        return true;
      }
      locallyEditedOrMoreRecentlySyncedTickets.push(localTicket);
      return false;
    }, payload);

    if (meta?.query?.skip_events) {
      // if the tickets do not have events, copy the local events when available
      ticketsToReplace = ticketsToReplace.map(serverTicket => {
        const localTicket = newState?.[serverTicket.id] || null;
        if (localTicket?.events) {
          return { ...serverTicket, events: localTicket.events };
        }
        return serverTicket;
      });
    }

    newState = {
      ...unsyncedTickets,
      ...mapById(locallyEditedOrMoreRecentlySyncedTickets),
      ...mapById(ticketsToReplace),
    };
    if (meta?.query) {
      // this is not a complete representation of all tickets,
      // therefore do not assume that any omitted tickets are deleted and add them back in
      newState = {
        ...state,
        ...newState,
      };
    }
    return newState;
  } else if (action.type === 'SUCCESS_SAVE_TICKET') {
    const { payload } = action;
    const ticket = state?.[payload.id];
    if (!ticket) {
      return state;
    }
    return {
      ...state,
      [payload.id]: { ...payload, _modified: null },
    };
  } else if (action.type === 'CREATE_TICKET') {
    const { payload: ticket } = action;
    const { id } = ticket;
    return {
      ...state,
      [id]: { ...ticket },
    };
  } else if (action.type === 'ADD_TICKET_EVENT') {
    return addTicketEvent(state, action);
  } else if (
    action.type === 'FAILURE_FETCH_TICKET' ||
    action.type === 'FAILURE_FETCH_TICKETS' ||
    action.type === 'FAILURE_SAVE_TICKET' ||
    action.type === 'REQUEST_FETCH_TICKET' ||
    action.type === 'REQUEST_FETCH_TICKETS' ||
    action.type === 'REQUEST_SAVE_TICKET'
  ) {
    return state; // noop
  }
  // softAssertNever(action);
  else return state;
}
