import { Artikel, Location, Stock as _Stock } from '@certhon/domain-models';
import { CALL_API } from 'redux-api-middleware';
import { mapById, resolveHeaders, resolveUrl } from '../utils';
import { softAssertNever } from './utils';

export type Stock = Artikel &
  Pick<_Stock, 'unsalable' | 'modified' | 'location_id'>;

export const labeltypes = ['klein', 'middel', 'a4', 'download'];

export interface LocationWithDetails extends Location {
  stock: Stock[];
}

export function isLocationWithStock(
  location: Location | LocationWithDetails,
): location is LocationWithDetails {
  return !!(location as any).stock;
}

export interface RequestFetchLocations {
  type: 'REQUEST_FETCH_LOCATIONS';
}
export interface SuccessFetchLocations {
  type: 'SUCCESS_FETCH_LOCATIONS';
  payload: Location[];
}
export interface FailureFetchLocations {
  type: 'FAILURE_FETCH_LOCATIONS';
  payload: { error: true };
}

export function fetchLocations() {
  return {
    [CALL_API]: {
      endpoint: resolveUrl(`/locations`),
      method: 'GET',
      headers: resolveHeaders(),
      types: [
        'REQUEST_FETCH_LOCATIONS',
        'SUCCESS_FETCH_LOCATIONS',
        'FAILURE_FETCH_LOCATIONS',
      ],
    },
  };
}

export interface RequestFetchLocation {
  type: 'REQUEST_FETCH_LOCATION';
}
export interface SuccessFetchLocation {
  type: 'SUCCESS_FETCH_LOCATION';
  payload: LocationWithDetails;
}
export interface FailureFetchLocation {
  type: 'FAILURE_FETCH_LOCATION';
  error: true;
}

export function fetchLocation(id: string) {
  return {
    [CALL_API]: {
      endpoint: resolveUrl(`/locations/${id}`),
      method: 'GET',
      headers: resolveHeaders(),
      types: [
        'REQUEST_FETCH_LOCATION',
        'SUCCESS_FETCH_LOCATION',
        'FAILURE_FETCH_LOCATION',
      ],
    },
  };
}

export interface RequestSaveLocation {
  type: 'REQUEST_SAVE_LOCATION';
}
export interface SuccessSaveLocation {
  type: 'SUCCESS_SAVE_LOCATION';
  payload: Location;
}
export interface FailureSaveLocation {
  type: 'FAILURE_SAVE_LOCATION';
  error: true;
}

export function saveLocation(location: Partial<Location>) {
  let { id: recnum, ...props } = location;

  let endpoint = resolveUrl(`/locations`);
  let method = 'POST';
  if (recnum) {
    endpoint = resolveUrl(`/locations/${location.id}`);
    method = 'PATCH';
  }
  return {
    [CALL_API]: {
      endpoint,
      method,
      body: JSON.stringify(props),
      headers: resolveHeaders(),
      types: [
        'REQUEST_SAVE_LOCATION',
        'SUCCESS_SAVE_LOCATION',
        'FAILURE_SAVE_LOCATION',
      ],
    },
  };
}

export interface RequestSaveLocations {
  type: 'REQUEST_SAVE_LOCATIONS';
}
export interface SuccessSaveLocations {
  type: 'SUCCESS_SAVE_LOCATIONS';
  payload: Location[];
}
export interface FailureSaveLocations {
  type: 'FAILURE_SAVE_LOCATIONS';
  error: true;
}

export function saveLocations(locations: Partial<Location>[]) {
  return {
    [CALL_API]: {
      endpoint: resolveUrl(`/locations`),
      method: 'POST',
      body: JSON.stringify(
        locations.map(l => ({ ...l, children: undefined, parent: undefined })),
      ),
      headers: resolveHeaders(),
      types: [
        'REQUEST_SAVE_LOCATIONS',
        'SUCCESS_SAVE_LOCATIONS',
        'FAILURE_SAVE_LOCATIONS',
      ],
    },
  };
}

export interface RequestMarkLocationsPrintedItem {
  type: 'REQUEST_MARK_LOCATIONS_PRINTED';
}
export interface SuccessMarkLocationsPrintedItem {
  type: 'SUCCESS_MARK_LOCATIONS_PRINTED';
  payload: LocationWithDetails[];
}
export interface FailureMarkLocationsPrintedItem {
  type: 'FAILURE_MARK_LOCATIONS_PRINTED';
  error: true;
}

export function markLocationsPrinted(locationIds: string[]) {
  return {
    [CALL_API]: {
      endpoint: resolveUrl(`/locations`),
      method: 'PATCH',
      body: JSON.stringify({ locationIds: locationIds, markPrinted: true }),
      headers: resolveHeaders(),
      types: [
        'REQUEST_MARK_LOCATIONS_PRINTED',
        'SUCCESS_MARK_LOCATIONS_PRINTED',
        'FAILURE_MARK_LOCATIONS_PRINTED',
      ],
    },
  };
}

export interface RequestDeleteLocation {
  type: 'REQUEST_DELETE_LOCATION';
}
export interface SuccessDeleteLocation {
  type: 'SUCCESS_DELETE_LOCATION';
  payload: number;
}
export interface FailureDeleteLocation {
  type: 'FAILURE_DELETE_LOCATION';
  error: true;
}

export function deleteLocation(location: Pick<Location, 'id'>) {
  return {
    [CALL_API]: {
      endpoint: resolveUrl(`/locations/${location.id}`),
      method: 'DELETE',
      headers: resolveHeaders(),
      types: [
        'REQUEST_DELETE_LOCATION',
        { type: 'SUCCESS_DELETE_LOCATION', payload: location.id },
        'FAILURE_DELETE_LOCATION',
      ],
    },
  };
}

export interface RequestPostStock {
  type: 'REQUEST_POST_STOCK';
}
export interface SuccessPostStock {
  type: 'SUCCESS_POST_STOCK';
  payload: LocationWithDetails;
}
export interface FailurePostStock {
  type: 'FAILURE_POST_STOCK';
  error: true;
}

export function postStock(locationId: string, artikel_id: number) {
  return {
    [CALL_API]: {
      endpoint: resolveUrl(`/locations/${locationId}/stock`),
      method: 'POST',
      headers: resolveHeaders(),
      body: JSON.stringify({ artikel_id }),
      types: ['REQUEST_POST_STOCK', 'SUCCESS_POST_STOCK', 'FAILURE_POST_STOCK'],
    },
  };
}

export interface RequestPatchStock {
  type: 'REQUEST_PATCH_STOCK';
}
export interface SuccessPatchStock {
  type: 'SUCCESS_PATCH_STOCK';
  payload: LocationWithDetails;
}
export interface FailurePatchStock {
  type: 'FAILURE_PATCH_STOCK';
  error: true;
}

export function patchStock(
  locationId: string,
  artikelId: number,
  unsalable: boolean,
) {
  return {
    [CALL_API]: {
      endpoint: resolveUrl(`/locations/${locationId}/stock/${artikelId}`),
      method: 'PATCH',
      headers: resolveHeaders(),
      body: JSON.stringify({ unsalable }),
      types: [
        'REQUEST_PATCH_STOCK',
        'SUCCESS_PATCH_STOCK',
        'FAILURE_PATCH_STOCK',
      ],
    },
  };
}

export interface RequestDeleteStock {
  type: 'REQUEST_DELETE_STOCK';
}
export interface SuccessDeleteStock {
  type: 'SUCCESS_DELETE_STOCK';
  payload: LocationWithDetails;
}
export interface FailureDeleteStock {
  type: 'FAILURE_DELETE_STOCK';
  error: true;
}

export function deleteStock(id: string, artikel_id: number) {
  return {
    [CALL_API]: {
      endpoint: resolveUrl(`/locations/${id}/stock/${artikel_id}`),
      method: 'DELETE',
      headers: resolveHeaders(),
      types: [
        'REQUEST_DELETE_STOCK',
        'SUCCESS_DELETE_STOCK',
        'FAILURE_DELETE_STOCK',
      ],
    },
  };
}

export type LocationAction =
  | RequestFetchLocations
  | SuccessFetchLocations
  | FailureFetchLocations
  | RequestFetchLocation
  | SuccessFetchLocation
  | FailureFetchLocation
  | RequestSaveLocation
  | SuccessSaveLocation
  | FailureSaveLocation
  | RequestDeleteLocation
  | SuccessDeleteLocation
  | FailureDeleteLocation
  | RequestSaveLocations
  | SuccessSaveLocations
  | FailureSaveLocations
  | RequestPostStock
  | SuccessPostStock
  | FailurePostStock
  | RequestDeleteStock
  | SuccessDeleteStock
  | FailureDeleteStock
  | RequestPatchStock
  | SuccessPatchStock
  | FailurePatchStock
  | RequestMarkLocationsPrintedItem
  | SuccessMarkLocationsPrintedItem
  | FailureMarkLocationsPrintedItem;

export type LocationsState = {
  [recnum: string]: Location | undefined;
} | null;

export default function locations(
  state: LocationsState = null,
  action: LocationAction,
) {
  if (action.type === 'SUCCESS_FETCH_LOCATIONS') {
    return mapById(action.payload) as LocationsState;
  } else if (
    action.type === 'SUCCESS_SAVE_LOCATIONS' ||
    action.type === 'SUCCESS_MARK_LOCATIONS_PRINTED'
  ) {
    return { ...state, ...(mapById(action.payload) as LocationsState) };
  } else if (
    action.type === 'SUCCESS_SAVE_LOCATION' ||
    action.type === 'SUCCESS_POST_STOCK' ||
    action.type === 'SUCCESS_PATCH_STOCK' ||
    action.type === 'SUCCESS_DELETE_STOCK' ||
    action.type === 'SUCCESS_FETCH_LOCATION'
  ) {
    const { payload } = action;
    return { ...state, [payload.id]: payload };
  } else if (action.type === 'SUCCESS_DELETE_LOCATION') {
    const newState = { ...state };
    delete newState[action.payload];
    return newState;
  } else if (
    action.type === 'FAILURE_DELETE_LOCATION' ||
    action.type === 'FAILURE_DELETE_STOCK' ||
    action.type === 'FAILURE_FETCH_LOCATION' ||
    action.type === 'FAILURE_FETCH_LOCATIONS' ||
    action.type === 'FAILURE_MARK_LOCATIONS_PRINTED' ||
    action.type === 'FAILURE_PATCH_STOCK' ||
    action.type === 'FAILURE_POST_STOCK' ||
    action.type === 'FAILURE_SAVE_LOCATION' ||
    action.type === 'FAILURE_SAVE_LOCATIONS' ||
    action.type === 'REQUEST_DELETE_LOCATION' ||
    action.type === 'REQUEST_DELETE_STOCK' ||
    action.type === 'REQUEST_FETCH_LOCATION' ||
    action.type === 'REQUEST_FETCH_LOCATIONS' ||
    action.type === 'REQUEST_MARK_LOCATIONS_PRINTED' ||
    action.type === 'REQUEST_PATCH_STOCK' ||
    action.type === 'REQUEST_POST_STOCK' ||
    action.type === 'REQUEST_SAVE_LOCATION' ||
    action.type === 'REQUEST_SAVE_LOCATIONS'
  ) {
    return state;
  }
  softAssertNever(action);
  return state;
}
