import React from 'react';
import { MenuItem, Nav, Navbar, NavDropdown, NavItem } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'redux-first-history';
import Types from 'Types';
import { Link } from 'wouter';
import logoImg from '../../assets/images/logo_white.png';
import {
  doesListHaveUnsavedModifications,
  doesListHaveUnsyncedModifications,
} from '../../common/logic/list';
import { doesTicketHaveUnsyncedEvents } from '../../common/logic/ticket';
import { signOut } from '../../modules/user';
import InventoryCounterOnly from '../../utils/CounterOnly';
import InternalOnly from '../../utils/InternalOnly';
import LinkContainer from '../LinkContainer';
import UserToken from '../UserToken';
import { msg } from './msg';
import styles from './Page.module.scss';

interface NavigationProps {
  fluid?: boolean;
}

const Navigation: React.FC<NavigationProps> = ({ fluid }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { tickets, feedback, online, lists, user } = useSelector(
    (state: Types.RootState) => ({
      tickets: state.tickets,
      feedback: state.feedback,
      online: state.online,
      lists: state.lists,
      user: state.user,
    }),
  );

  const hasUnsynced = React.useMemo(() => {
    return (
      (lists &&
        Object.values(lists).some((l: any) =>
          doesListHaveUnsyncedModifications(l),
        )) ||
      (tickets &&
        Object.values(tickets).some((t: any) =>
          doesTicketHaveUnsyncedEvents(t),
        )) ||
      (feedback && Object.values(feedback).some(f => f._sync))
    );
  }, [lists, tickets, feedback]);

  const handleSignOut = () => {
    // eslint-disable-next-line no-restricted-globals
    if (!hasUnsynced || confirm(intl.formatMessage(msg.signOutWarnContent))) {
      // eslint-disable-next-line no-restricted-globals
      if (online || confirm(intl.formatMessage(msg.signOutWarnOffline))) {
        dispatch(signOut());
        dispatch(push('/login'));
      }
    }
  };

  let leftItems = null;

  if (user) {
    let templatesClass = '';
    if (
      lists &&
      Object.values(lists).some((l: any) => doesListHaveUnsavedModifications(l))
    ) {
      templatesClass = styles.unsaved;
    }
    const adminItems = [];
    if (user && user.permissions.QA_ADMIN) {
      adminItems.push(
        <LinkContainer to="/templates" key="templates">
          <MenuItem className={'hidden-xs ' + templatesClass} eventKey={3}>
            <FormattedMessage
              id="NAVIGATION.TEMPLATES"
              defaultMessage="Templates"
              description=""
            />
          </MenuItem>
        </LinkContainer>,
      );
      adminItems.push(
        <LinkContainer to="/labels" key="labels">
          <MenuItem className="hidden-xs" eventKey={3}>
            <FormattedMessage
              id="NAVIGATION.LABELS"
              defaultMessage="Labels"
              description=""
            />
          </MenuItem>
        </LinkContainer>,
      );
      adminItems.push(
        <LinkContainer to="/stats" key="stats">
          <MenuItem className="hidden-xs" eventKey={3}>
            <FormattedMessage
              id="NAVIGATION.STATS"
              defaultMessage="Stats"
              description=""
            />
          </MenuItem>
        </LinkContainer>,
      );
    }
    if (user && user.permissions.USER_ADMIN) {
      adminItems.push(
        <LinkContainer to="/accounts" key="accounts">
          <MenuItem className="hidden-xs" eventKey={3}>
            <FormattedMessage
              id="NAVIGATION.ACCOUNTS"
              defaultMessage="Accounts"
              description=""
            />
          </MenuItem>
        </LinkContainer>,
      );
    }
    if (user && user.permissions.QA_SUPER_USER) {
      adminItems.push(
        <LinkContainer to="/cache-administration" key="cache-administration">
          <MenuItem eventKey={3}>
            <FormattedMessage
              id="NAVIGATION.CACHE_ADMINISTRATION"
              defaultMessage="Cache"
              description=""
            />
          </MenuItem>
        </LinkContainer>,
      );
    }
    leftItems = (
      <>
        <LinkContainer to="/projects" key="projects">
          <NavItem eventKey={1}>
            <FormattedMessage
              id="NAVIGATION.PROJECTS"
              defaultMessage="Projects"
            />
          </NavItem>
        </LinkContainer>
        <InternalOnly user={user}>
          <LinkContainer to="/dashboard" key="dashboard">
            <NavItem eventKey={2}>
              <FormattedMessage
                id="NAVIGATION.DASHBOARD"
                defaultMessage="Dashboard"
              />
            </NavItem>
          </LinkContainer>
        </InternalOnly>
        <InventoryCounterOnly user={user}>
          <NavDropdown id="inventory" title="Inventory" eventKey={3}>
            <LinkContainer to="/inventory" key="inventory">
              <NavItem eventKey={3.1}>
                <FormattedMessage {...msg.inventoryLocations} />
              </NavItem>
            </LinkContainer>
            <InternalOnly user={user}>
              <LinkContainer to="/inventory/search" key="search">
                <NavItem eventKey={3.3}>
                  <FormattedMessage {...msg.inventorySearch} />
                </NavItem>
              </LinkContainer>
            </InternalOnly>
            <InternalOnly user={user}>
              <LinkContainer to="/inventory/lists" key="material-lists">
                <NavItem eventKey={3.4}>
                  <FormattedMessage {...msg.inventoryOrders} />
                </NavItem>
              </LinkContainer>
            </InternalOnly>
            <InternalOnly user={user}>
              <LinkContainer to="/counting-requests" key="counting-requests">
                <NavItem eventKey={3.5}>
                  <FormattedMessage {...msg.countingRequests} />
                </NavItem>
              </LinkContainer>
            </InternalOnly>
            <NavItem disabled eventKey={3.6}>
              Low-stock alerts
            </NavItem>
          </NavDropdown>
        </InventoryCounterOnly>
        {adminItems.length > 1 && (
          <NavDropdown
            eventKey={4}
            title="Admin"
            id="admin"
            className="hidden-xs"
          >
            {adminItems}
          </NavDropdown>
        )}
        {adminItems.length === 1 && adminItems[0]}
      </>
    );
  }

  let rightItems = null;
  if (user) {
    rightItems = (
      <>
        <NavDropdown
          eventKey="4"
          title={
            <span>
              <UserToken small className={styles.userToken} user={user} />{' '}
              {user.loginname}
            </span>
          }
          id="nav-dropdown"
        >
          <LinkContainer to="/settings" key="settings">
            <MenuItem>
              <FormattedMessage
                id="NAVIGATION.SETTINGS"
                defaultMessage="Settings"
              />
            </MenuItem>
          </LinkContainer>
          <LinkContainer to="/feedback" key="feedback">
            <MenuItem>
              <FormattedMessage
                id="NAVIGATION.FEEDBACK"
                defaultMessage="My Feedback"
              />
            </MenuItem>
          </LinkContainer>
          <MenuItem divider />
          <MenuItem onClick={handleSignOut}>
            <FormattedMessage
              id="NAV.SIGN_OUT"
              defaultMessage="Sign out"
              description="Label of signout button"
            />
          </MenuItem>
        </NavDropdown>
      </>
    );
  }

  let environmentFilter = undefined;
  if (window.location.hostname !== 'certhon.app') {
    environmentFilter = {
      style: { filter: 'brightness(50%) sepia(100%) saturate(10000%)' },
      title: 'This is not the production environment!',
    };
  }

  return (
    <Navbar inverse className={styles.nav} collapseOnSelect fluid={fluid}>
      <Navbar.Header>
        <Link to="/">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
          <a className="navbar-brand">
            <img
              role="presentation"
              alt="certhon logo"
              src={logoImg}
              {...environmentFilter}
            />
          </a>
        </Link>
        <Navbar.Toggle />
      </Navbar.Header>
      <Navbar.Collapse>
        <Nav>{leftItems}</Nav>
        <Nav pullRight>{rightItems}</Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Navigation;
