import PropTypes from 'prop-types';
import { identity, prop, reverse, sortBy, values } from 'ramda';
import * as React from 'react';
import { connect } from 'react-redux';
import { isUserInvolvedWithTicket } from '../../common/logic/ticket';
import MyTicketsComponent from '../../components/MyTicketsComponent/index';
import { fetchProjects } from '../../modules/projects';
import { fetchTickets } from '../../modules/tickets';
import { fetchUsers } from '../../modules/users';
import { fetchWorks } from '../../modules/works';
import ignoreRejection from '../../utils/ignoreRejection';

export class MyTicketsContainerComponent extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  componentDidMount() {
    ignoreRejection(
      this.props.dispatch(fetchWorks()),
      this.props.dispatch(fetchProjects()),
      this.props.dispatch(fetchUsers()),
      this.props.dispatch(fetchTickets()),
    );
  }

  render() {
    const { works, dispatch, users, user, tickets, projects } = this.props;
    const assProjectIds = values(projects).filter(p =>
      p.users?.includes(user.recnum),
    );

    const assWorkIds = values(works)
      .filter(w => w.users && w.users.includes(user.recnum))
      .map(prop('recnum'));
    const filterredTickets = values(tickets)
      .filter(t => t.status === 'opened')
      .filter(
        t =>
          t.id && // filter out non-tickets (redux persist crap)
          (assWorkIds.includes(t.werk_id) ||
            assProjectIds.includes(t.project_id) ||
            isUserInvolvedWithTicket(user.recnum, t)),
      );

    return (
      <MyTicketsComponent
        tickets={reverse(sortBy(prop('created'), filterredTickets))}
        users={users}
        dispatch={dispatch}
      />
    );
  }
}

export default connect(identity)(MyTicketsContainerComponent);
