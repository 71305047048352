import { Middleware } from 'redux';
import attachmentStorage from '../services/attachmentStorage';

export function attachmentStorageMiddleware(): Middleware {
  return store => next => action => {
    const { type, payload } = action;

    if (type !== 'CREATE_ATTACHMENT' && type !== 'SUCCESS_FETCH_ATTACHMENT') {
      return next(action);
    }

    async function handle() {
      // store the blob
      const { checksum, blob } = payload;
      await attachmentStorage.setItem(checksum, blob);

      // send on the action
      next(action);
      // & resolve the promise
      return payload;
    }

    // return a Promise
    return handle();
  };
}
