/* eslint-disable jsx-a11y/anchor-is-valid */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { Component } from 'react';
import Autocomplete from 'react-autocomplete';
import styles from './InputList.module.scss';
import InputListItem from './InputListItem';

export default class InputList extends Component {
  static propTypes = {
    onDeselectItem: PropTypes.func.isRequired,
    onSelectItem: PropTypes.func.isRequired,
    renderItem: PropTypes.func,
    getItemValue: PropTypes.func,
    items: PropTypes.array.isRequired,
    selectedItems: PropTypes.array.isRequired,
    single: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      value: '',
    };
  }

  componentWillMount() {
    this.handleSelectItem = this.handleSelectItem.bind(this);
    this.handleDeselectItem = this.handleDeselectItem.bind(this);
    this.handleAutocompleteChange = this.handleAutocompleteChange.bind(this);
    this.handleAutocompleteSelect = this.handleAutocompleteSelect.bind(this);
  }

  handleDeselectItem(item) {
    this.props.onDeselectItem(item);
  }

  handleSelectItem(item) {
    this.setState({ value: '' });
    this.props.onSelectItem(item);
  }

  handleAutocompleteChange(event, value) {
    this.setState({ value });
  }

  handleAutocompleteSelect(_, item) {
    this.setState({ value: '' });
    this.props.onSelectItem(item);
  }

  render() {
    const {
      items,
      selectedItems,
      getItemValue,
      shouldItemRender,
      name,
      small,
      onKeyDown,
      ref,
    } = this.props;
    const { value } = this.state;
    return (
      <div>
        <div>
          {selectedItems.map(item => (
            <InputListItem
              key={getItemValue(item)}
              item={item}
              onDeselectItem={this.handleDeselectItem}
              getItemValue={getItemValue}
            />
          ))}
        </div>
        <Autocomplete
          ref={ref}
          value={value}
          items={items.filter(R.complement(R.includes(R.__, selectedItems)))}
          shouldItemRender={shouldItemRender}
          getItemValue={getItemValue}
          onChange={this.handleAutocompleteChange}
          onSelect={this.handleAutocompleteSelect}
          renderItem={(item, highlight) => (
            <li key={getItemValue(item)} className={highlight ? styles.highlight : ''}>
              <a>
                <InputListItem
                  item={item}
                  getItemValue={getItemValue}
                  highlight={highlight}
                />
              </a>
            </li>
          )}
          renderInput={props => {
            const finalProps = { ...props };
            if (onKeyDown) {
              finalProps.onKeyDown = onkeydown;
            }
            return !this.props.single ||
              this.props.selectedItems.length === 0 ? (
              <input
                name={name}
                key="input"
                {...props}
                placeholder={this.props.placeholder}
                className={classNames('form-control', small && 'input-sm')}
              />
            ) : (
              <span></span>
            );
          }}
          renderMenu={children => (
            <ul className={`dropdown-menu ${styles.dropdown}`}>{children}</ul>
          )}
          wrapperProps={{
            className: 'dropdown open',
            style: { display: 'block' },
          }}
        />
      </div>
    );
  }
}
