import * as React from 'react';
import { connect, useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import Types from 'Types';
import { parseParameters } from '../../components/ParameterizedSearch/model';
import ProjectsPage from '../../components/ProjectsPage';
import useProjectFilter from '../../hooks/useProjectFilter';
import { Project } from '../../models/Project';
import { filter } from '../../modules/filters';
import { fetchProjects } from '../../modules/projects';
import { UserState } from '../../modules/user';
import { fetchUsers, UsersState } from '../../modules/users';
import { Work } from '../../modules/works';
import useInitialDispatch from '../../utils/useInitalDispatch';

const FILTER_KEY = 'PROJECTS_OVERVIEW_FILTER';

interface Props {
  dispatch: Dispatch;
  projects: Project[] | null;
  works: Work[] | null;
  users: UsersState;
  user: UserState;
  filter: string;
}

const ProjectsOverviewContainerComponent: React.FC<Props> = props => {
  const dispatch = useDispatch();

  const handleChangeFilter = React.useCallback(
    (newFilter: string | null) => {
      props.dispatch(filter(FILTER_KEY, newFilter));
    },
    [props],
  );

  const fi = useProjectFilter(handleChangeFilter, props.filter, props.user);

  const archived = React.useMemo(
    () =>
      parseParameters(fi.filter).parameters.find(p => p.name === 'archived')
        ?.value || undefined,
    [fi.filter],
  );

  useInitialDispatch(fetchUsers);

  React.useEffect(() => {
    dispatch(fetchProjects({ archived }));
  }, [archived, dispatch]);

  return (
    <ProjectsPage
      filter={fi}
      projects={props.projects}
      dispatch={props.dispatch}
      users={props.users}
      user={props.user}
    />
  );
};

export default connect((state: Types.RootState) => {
  const { projects, works, filters, users, user } = state;
  return {
    projects: projects ? (Object.values(projects) as Project[]) : null,
    works: works ? (Object.values(works) as Work[]) : null,
    users,
    user,
    filter: filters[FILTER_KEY],
  };
})(ProjectsOverviewContainerComponent);
