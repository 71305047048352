import { Counting, CountingRequest } from '@certhon/domain-models';
import classNames from 'classnames';
import { intersperse } from 'ramda';
import React from 'react';
import { Link, useLocation } from 'wouter';
import { countingInCountingRequestRange } from '../../common/counting/countingInCountingRequestRange';
import { CountingTarget } from '../../common/counting/createCountingList';
import {
  findNextCountingListTargetSatisfyingConstraint,
  findPreviousCountingListTargetSatisfyingConstraint,
} from '../../common/location/findNextCountingListTarget';
import formatPath from '../../common/location/formatPath';
import { locationAncestors } from '../../common/location/locationAncestors';
import { renderPathUntilParentId } from '../../common/location/renderPathUntilParentId';
import { replaceNumberingPlaceholder } from '../../common/location/replaceNumberingPlaceholder';
import artikelCode from '../../common/logic/artikelCode';
import { serialize } from '../../hooks/useLocationStockParamHash';
import { LocationWithDetails, Stock } from '../../modules/locations';
import { CountingState } from '../../stores/countingStore';
import { useContextMenu } from '../ContextMenu';
import CountingInputComponent, {
  CountingInputComponentProps,
} from '../Counting/CountingInput.Component';
import { MakeTree } from '../Tree/common';
import stl from './CountingList.module.scss';

const LocationNavigationalContextMenu: React.FC<React.PropsWithChildren<{
  locationId: string;
}>> = ({ locationId, children }) => {
  const [, goTo] = useLocation();
  const { node, onContextMenuHandler } = useContextMenu([
    {
      type: 'action',
      label: 'Go to location',
      action: () => goTo('/inventory/' + locationId),
    },
  ]);

  return (
    <span onContextMenu={onContextMenuHandler}>
      {children}
      {node}
    </span>
  );
};

const relativeLocationPath = (l1: string[], l2: string[]): string[] => {
  const result: string[] = ['...'];
  l1.forEach((comp, idx) => {
    if (l2[idx] !== comp) {
      result.push(comp);
    } else {
      return result.concat(l1.slice(idx));
    }
  });
  return result;
};
interface CountingListComponentProps
  extends Omit<CountingInputComponentProps, 'location'> {
  list: CountingTarget[];
  /** Id of the root of the sub-tree we are rendering */
  rootLocation: MakeTree<LocationWithDetails>;
  countingState: CountingState;
  countingRequest: CountingRequest;
  selection: { location_id: string; artikel_id: number | null } | null;
  filterFunction: (location_id: string, stock: Stock) => boolean;
}

const CountingListComponent: React.FC<CountingListComponentProps> = ({
  rootLocation,
  list,
  selection,
  countingRequest,
  countingState,
  onGoNext,
  saveCounting,
  filterFunction,
}) => {
  const selectionRef = React.useRef<HTMLTableSectionElement>(null);
  let selectedRowIdx: number | null = null;
  const [, go] = useLocation();

  const rootPath = formatPath(rootLocation);
  const nextRootLocation = findNextCountingListTargetSatisfyingConstraint(
    rootLocation,
  );

  const previousRootLocation = findPreviousCountingListTargetSatisfyingConstraint(
    rootLocation,
  );
  /**
   * Iterate over all list entries, determining the depth of the tree and other factors, in preparation of rendering the table cells
   */
  const rows: ({
    path: string[];
    counting: Counting | null;
    selected: boolean;
  } & CountingTarget)[] = [];
  let lastPath: string[] | null = null;
  let depth = 0;
  for (const [idx, { location, stock }] of list.entries()) {
    const path = renderPathUntilParentId(location, rootLocation.id);
    let newPath = path;
    if (lastPath) {
      // eslint-disable-next-line no-loop-func
      newPath = path.map((value, index) =>
        lastPath![index] === value ? '' : value,
      );
    }

    // is this row selected
    const selected =
      (selection &&
        location.id === selection.location_id &&
        (stock?.recnum || null) === selection.artikel_id) ||
      false;

    if (selected) {
      selectedRowIdx = idx;
    }

    rows.push({
      path: newPath.filter(x => x),
      location,
      stock,
      selected,
      counting:
        countingState[location.id]?.[stock?.recnum!]?.find(
          countingInCountingRequestRange(countingRequest),
        ) || null,
    });
    depth = Math.max(depth, path.length);
    lastPath = path;
  }

  /* scroll to newly selected rows */
  React.useEffect(() => {
    if (selectedRowIdx !== null) {
      const child = selectionRef?.current?.children[selectedRowIdx]!;
      const { top, height } = child.getBoundingClientRect();

      selectionRef.current &&
        window.scrollTo({
          // TODO: mind small devices with no room for three rows
          top: Math.max(top + window.scrollY - 3 * height, 0),
          behavior: 'smooth',
        });
    }
  }, [selectedRowIdx]);

  return (
    <div className="row">
      <div className="container">
        <h4>
          Location counting-list <br />
          <small>
            {intersperse(
              <span> / </span>,
              locationAncestors(rootLocation).map(l => (
                <Link
                  to={`/counting-requests/${countingRequest.recnum}/count/${l.id}`}
                >
                  {replaceNumberingPlaceholder(l.name, l.ordering)}
                </Link>
              )),
            )}
          </small>
        </h4>
      </div>
      <table className={classNames('table table-condensed', stl.table)}>
        <tbody ref={selectionRef}>
          {rows.map(({ path, location, stock, counting, selected }, cidx) => {
            return (
              <React.Fragment key={cidx}>
                <tr
                  className={classNames(
                    selected && stl.selected,
                    stock?.unsalable && stl.stockUnsalable,
                    location?.unsalable && stl.locationUnsalable,
                  )}
                >
                  <td className={stl.path}>
                    {intersperse(
                      <span className={stl.separator}>/</span>,
                      path.map((value, idx) =>
                        idx !== path.length - 1 ? (
                          <span
                            key={idx}
                            className={classNames(value && stl.hasContent)}
                          >
                            {value}
                          </span>
                        ) : (
                          <LocationNavigationalContextMenu
                            locationId={location.id}
                          >
                            <span
                              key={idx}
                              className={classNames(
                                value && stl.hasContent,
                                stl.lastLocation,
                              )}
                            >
                              {value}
                            </span>
                          </LocationNavigationalContextMenu>
                        ),
                      ),
                    )}
                  </td>

                  <td>
                    <Link
                      to={`#${serialize({
                        location_id: location.id,
                        artikel_id: stock?.recnum || null,
                      })}`}
                      onClick={e => {
                        e.preventDefault();
                        go(e.currentTarget.href, { replace: true });
                      }}
                    >
                      {(stock && `${artikelCode(stock)}`) || (
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <a>
                          <em>empty</em>
                        </a>
                      )}
                    </Link>
                  </td>
                  <td>
                    {stock && filterFunction(location.id, stock) && (
                      <div>
                        <input type="checkbox" disabled checked={!!counting} />{' '}
                        {counting &&
                          (counting.context === 'COUNTING_REQUEST' ? (
                            counting.amount
                          ) : (
                            <em style={{ color: 'gray' }}>
                              {counting?.amount}
                            </em>
                          ))}
                      </div>
                    )}
                  </td>
                </tr>
                {selected && (
                  <tr className={classNames(stl.inputRow, stl.selected)}>
                    <td colSpan={3}>
                      <CountingInputComponent
                        onGoNext={onGoNext}
                        location={location}
                        stock={stock}
                        counting={counting}
                        filterFunction={filterFunction}
                        saveCounting={saveCounting}
                      />
                    </td>
                  </tr>
                )}
              </React.Fragment>
            );
          })}
        </tbody>
        {(nextRootLocation || previousRootLocation) && (
          <tfoot>
            <tr>
              <td colSpan={3}>
                {nextRootLocation && (
                  <Link
                    className="btn pull-right"
                    to={window.location.href.replace(
                      rootLocation.id,
                      nextRootLocation.id,
                    )}
                  >
                    Continue counting at{' '}
                    {intersperse(
                      <em> / </em>,
                      relativeLocationPath(
                        formatPath(nextRootLocation),
                        rootPath,
                      ) as any,
                    )}{' '}
                    <i className="fa fa-chevron-right"></i>
                  </Link>
                )}
                {previousRootLocation && (
                  <Link
                    className="btn"
                    to={window.location.href.replace(
                      rootLocation.id,
                      previousRootLocation.id,
                    )}
                  >
                    <i className="fa fa-chevron-left"></i>{' '}
                    {intersperse(
                      <em> / </em>,
                      relativeLocationPath(
                        formatPath(previousRootLocation),
                        rootPath,
                      ) as any,
                    )}{' '}
                  </Link>
                )}
              </td>
            </tr>
          </tfoot>
        )}
      </table>
    </div>
  );
};

export default CountingListComponent;
