import classNames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import Types from 'Types';
import { User } from '../../models/User';
import ErrorBoundry from '../Errorboundry';
import { Columns, ColumnsProps } from './Columns';
import Footer from './Footer';
import Navigation from './Navigation';
import stl from './Page.module.scss';

export type PageStoreProps = Pick<
  Types.RootState,
  'user' | 'online' | 'lists' | 'tickets' | 'syncStatus' | 'feedback'
>;

export interface PageProps {
  user: User | null;
  children?: React.ReactNode | React.ReactNode[];
  columns?: ColumnsProps['children'];
  dispatch: Dispatch;
  title?: string;
  fluid: boolean;
  className?: string;
}

/**
 * Generic Page container for rendering pages
 * Has gotten quite some baggage over the years
 * FIXME: clean up
 */
class PageComponent extends React.Component<PageProps & PageStoreProps> {
  static defaultProps = {
    fluid: false,
  };

  render() {
    const {
      user,
      dispatch,
      title,
      fluid,
      lists,
      tickets,
      online,
      syncStatus,
      feedback,
      children,
      columns,
      className,
    } = this.props;
    let content: React.ReactNode | React.ReactNode[] = '';
    if (children) {
      content = (
        <div
          className={classNames(
            stl.tempTransitionContainer,
            fluid ? 'container-fluid' : 'container',
            className,
          )}
        >
          <h1 className="visible-print">{title}</h1>
          {children}
        </div>
      );
    } else if (columns) {
      content = <Columns>{columns}</Columns>;
    }

    return (
      <>
        <Navigation fluid={fluid} />
        <div id="content">
          <ErrorBoundry>{content}</ErrorBoundry>
        </div>
        <Footer
          dispatch={dispatch}
          user={user}
          online={online}
          lists={lists}
          tickets={tickets}
          syncStatus={syncStatus}
        />
      </>
    );
  }
}

function mapStateToProps(state: Types.RootState): PageStoreProps {
  const { lists, online, user, tickets, syncStatus, feedback } = state;
  return {
    lists,
    online,
    user,
    tickets,
    syncStatus,
    feedback,
  };
}

const Page = connect(mapStateToProps)(PageComponent);

export default Page;
