/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Autocomplete from 'react-autocomplete';
import {
  Button,
  ControlLabel,
  Form,
  FormGroup,
  HelpBlock,
  Modal,
} from 'react-bootstrap';

import styles from './ExpandProtoDialog.module.scss';

import { hasCircularDependency } from '../../common/list';
import { mapById } from '../../utils';

export default class ExpandProtoDialog extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    lists: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = { protoListName: '', protoListId: null };
  }
  handleAutocompleteChange = async event => {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  };

  handleSelect = (val, item) => {
    this.setState({
      protoListId: item.id,
      protoListName: val,
    });
  };

  handleCancel = this.props.onCancel;
  handleSubmit = () => {
    this.props.onSubmit(this.state.protoListId);
  };

  renderInput = props => (
    <input
      key="input"
      name="protoListName"
      {...props}
      className="form-control"
    />
  );

  renderMenu = children => <ul className="dropdown-menu">{children}</ul>;

  renderItem = (item, isHighlighted) => (
    <li className={`item ${isHighlighted ? styles.highlighted : ''}`}>
      <a>{item.name || item.id}</a>
    </li>
  );

  render() {
    const { lists, targetListId, field } = this.props;
    const { protoListName, protoListId } = this.state;

    const protoLists = mapById(
      Object.values(lists)
        .filter(
          l =>
            ((field.type === 'ROOT_FIELD' && l.tags?.includes('template')) ||
              (field.type === 'GROUP_FIELD' &&
                l.tags?.includes('checkpoint'))) &&
            l.deleted === false,
        )
        .filter(list => !hasCircularDependency(targetListId, list.id, lists)),
    );

    const helpBlockText =
      field.type === 'ROOT_FIELD'
        ? 'Controlepunten worden niet weergegeven omdate deze niet in de root toegevoegd kunnen worden'
        : 'Sjablonen worden niet weergegeven omdate deze niet in een groep ingevoegd kunnen worden';

    const protoListOk =
      protoListId && protoLists[protoListId].name === protoListName;

    return (
      <Modal show>
        <Modal.Header>Template invoegen</Modal.Header>
        <Modal.Body>
          <Form>
            <FormGroup
              controlId="protoList"
              validationState={(protoListOk && 'success') || null}
            >
              <ControlLabel>Template</ControlLabel>

              <HelpBlock>{helpBlockText}</HelpBlock>
              <Autocomplete
                value={protoListName}
                items={Object.values(protoLists)}
                onChange={this.handleAutocompleteChange}
                onSelect={this.handleSelect}
                getItemValue={item => item.name}
                shouldItemRender={(l, value) =>
                  l.name?.toUpperCase()?.includes(value?.toUpperCase())
                }
                selectOnBlur
                wrapperProps={{
                  className: 'dropdown open',
                  style: { display: 'block' },
                }}
                renderInput={this.renderInput}
                renderItem={this.renderItem}
                renderMenu={this.renderMenu}
              />
            </FormGroup>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.handleCancel}>Cancel</Button>
          <Button
            onClick={this.handleSubmit}
            bsStyle="primary"
            disabled={!protoListOk}
          >
            Invoegen
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
