import { assertNever } from '../../../../modules/utils';
import { NoteAttachment } from '../../types';
import {
  createModificationCreator,
  ListEventReducer,
} from '../ListModification';
import {
  patchListNode,
  transferModificationPropertiesToAttachment,
} from '../util';

export interface AddNotePayload {
  note: string;
  targetNodeId: string;
}
export interface EditNotePayload {
  text: string;
  targetNodeId: string;
  noteId: string;
}
export interface RemoveNotePayload {
  targetNodeId: string;
  noteId: string;
}

export const addNote = createModificationCreator<AddNotePayload, 'ADD_NOTE'>(
  'ADD_NOTE',
);
export const editNote = createModificationCreator<EditNotePayload, 'EDIT_NOTE'>(
  'EDIT_NOTE',
);
export const removeNote = createModificationCreator<
  RemoveNotePayload,
  'REMOVE_NOTE'
>('REMOVE_NOTE');

export type AddNoteListModification = ReturnType<typeof addNote>;
export type EditNoteListModification = ReturnType<typeof editNote>;
export type RemoveNoteListModification = ReturnType<typeof removeNote>;

export type NoteListModification =
  | AddNoteListModification
  | EditNoteListModification
  | RemoveNoteListModification;

const note: ListEventReducer<NoteListModification> = (
  list,
  mod: NoteListModification,
) =>
  patchListNode(list, mod.payload.targetNodeId, targetNode => {
    if (targetNode.type === 'ROOT_FIELD' || targetNode.type === 'GROUP_FIELD') {
      throw new Error(
        `Unexpected non-value targetNode type (${targetNode.type}).`,
      );
    }
    if (mod.type === 'ADD_NOTE') {
      const noteAttachment: NoteAttachment = {
        type: 'note',
        text: mod.payload.note,
        ...transferModificationPropertiesToAttachment(mod),
      };
      return {
        ...targetNode,
        attachments: [...targetNode.attachments, noteAttachment],
      };
    } else if (mod.type === 'EDIT_NOTE') {
      return {
        ...targetNode,
        attachments: targetNode.attachments.map(attachment => {
          if (
            attachment.id === mod.payload.noteId &&
            attachment.type === 'note'
          ) {
            return {
              ...attachment,
              text: mod.payload.text,
            };
          }
          return attachment;
        }),
      };
    } else if (mod.type === 'REMOVE_NOTE') {
      return {
        ...targetNode,
        attachments: targetNode.attachments.filter(
          att => att.id !== mod.payload.noteId,
        ),
      };
    }
    assertNever(mod);
    throw new Error();
  });

export default note;
