import regEscape from 'escape-string-regexp';
import { complement, identity, prop } from 'ramda';
import { parseParameters } from '../../components/ParameterizedSearch/model';
import { CurrentUser } from '../../modules/user';
import { Work } from '../../modules/works';
import combineFilters from '../../utils/combineFilters';
import departmentToChar from './departmentToChar';

export default function createWorkFilter(search: string, user: CurrentUser) {
  const { parameters, rest } = parseParameters(search);
  const workFilters: Array<(p: Work) => boolean> = [];

  const following = parameters.filter(({ name }) => name === 'following');
  if (following.length) {
    let followFilter = (w: Work) => w.users.includes(user.recnum);
    if (following[0].value === 'true') {
      followFilter = complement(followFilter)
    }
    workFilters.push(followFilter);
  }

  const departments = parameters
    .filter(({ name }) => name === 'department')
    .map(prop('value'))
    .map(departmentToChar);
  if (departments.length) {
    workFilters.push(w => departments.includes(w.afd.toUpperCase()));
  }

  if (rest) {
    const regex = new RegExp(regEscape(rest), 'i');
    workFilters.push(
      w =>
        regex.test(w.id) ||
        regex.test(w.oms) ||
        regex.test(w.oms2) ||
        regex.test(w.klant) ||
        regex.test(w.klant_naam),
    );
  }
  return combineFilters(workFilters);
}
