import PropTypes from 'prop-types';
import * as R from 'ramda';
import * as React from 'react';
import { connect } from 'react-redux';
import { fetchAttachment } from '../../modules/attachments';
import { attachmentStorage } from '../../services';

import noImageUrl from '../List/noimage.svg';
import Spinner from '../Spinner';

import getAttachmentExtension from '../../common/logic/getAttachmentExtension';
import { ReportReadyConsumer } from '../../utils/ReportReady/ReportReadyProvider';
import Lightbox from '../LightBox';
import styles from './Attachment.module.scss';
import geopositionFromExif from './geoPositionFromExif';

const exifProm = import('exif-js').then(module => module.default);

export class Attachment extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    attachment: PropTypes.object.isRequired,
  };

  state = {
    orientationTransform: null,
    coordinate: null,
    showLightBox: false,
  };
  ref = React.createRef();

  constructor(props) {
    super(props);
    props.register();
  }

  componentWillMount() {
    this.getAttachmentUrl();
  }

  componentWillUnmount() {
    this.revokeAttachmentUrl();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.attachment.checksum !== nextProps.attachment.checksum) {
      this.revokeAttachmentUrl(() => this.getAttachmentUrl());
    }
  }

  checkOrientation = async clb => {
    const exif = await exifProm;
    const imgTag = this.ref.current;
    const self = this;
    let orientationTransform = null;
    let coordinate = null;
    if (imgTag && imgTag.tagName === 'IMG') {
      exif.getData(imgTag, function() {
        const orientation = exif.getTag(this, 'Orientation');
        const PixelXDimension = exif.getTag(this, 'PixelXDimension');
        const PixelYDimension = exif.getTag(this, 'PixelYDimension');
        let scale = 0;
        if (orientation === 6) {
          scale = PixelYDimension / PixelXDimension;
          orientationTransform = `rotate(90deg) scale(${scale})`;
        } else if (orientation === 8) {
          scale = PixelYDimension / PixelXDimension;
          orientationTransform = `rotate(270deg) scale(${scale})`;
        } else if (orientation === 3) {
          orientationTransform = 'rotate(180deg)';
        }
        const data = exif.getAllTags(this);
        coordinate = geopositionFromExif(data);
        if (orientationTransform || coordinate) {
          // update and register callback
          self.setState({ orientationTransform, coordinate }, clb);
        } else {
          // image is fine, call clb
          clb();
        }
      });
    } else {
      clb();
    }
  };

  async getAttachmentUrl() {
    const {
      attachments,
      attachment: { checksum, contentType },
      dispatch,
    } = this.props;
    const { attachmentUrl } = this.state;

    if (attachmentUrl) {
      return; // its available
    }

    if (attachmentUrl === null) {
      return; // its being fetched or has failed
    }

    if (attachments[checksum] && (!attachmentUrl || !attachmentUrl.error)) {
      // it needs to be retrieved from storage
      const blob = await attachmentStorage.getItem(checksum, contentType);
      let newAttachmentUrl;
      try {
        newAttachmentUrl = URL.createObjectURL(blob);
      } catch (e) {
        // tslint:disable-next-line
        console.error(e.message, e, { e, blob });
      }
      this.setState(
        { attachmentUrl: newAttachmentUrl },
        this.waitForAttachment,
      );

      return;
    }

    // fetch it
    this.setState({ attachmentUrl: null });
    dispatch(fetchAttachment(checksum)).then(result => {
      if (result.error) {
        this.setState({ attachmentUrl: result });
        return;
      }
      const newAttachmentUrl = URL.createObjectURL(result.blob);
      this.setState(
        { attachmentUrl: newAttachmentUrl },
        this.waitForAttachment,
      );
    });
  }

  once = true;
  handleFileError = () => {
    const {
      dispatch,
      attachment: { checksum },
    } = this.props;
    if (this.once) {
      this.setState({ attachmentUrl: null });
      dispatch(fetchAttachment(checksum, 'medium', true)).then(result => {
        if (result.error) {
          this.setState({ attachmentUrl: result });
          return;
        }
        const newAttachmentUrl = URL.createObjectURL(result.blob);
        this.setState(
          { attachmentUrl: newAttachmentUrl },
          this.waitForAttachment,
        );
      });
      this.once = false;
    }
  };

  // the attachementUrl is available, we are now to wait until the object says its ready displaying the attachment
  waitForAttachment = () => {
    const elem = this.ref.current;

    if (elem.tagName === 'IMG') {
      const handle = () => {
        this.checkOrientation(this.props.ready);

        elem.removeEventListener('load', handle);
        elem.removeEventListener('loadeddata', handle);
      };
      elem.addEventListener('load', handle);
      elem.addEventListener('loadeddata', handle);
    } else {
      // dont wait for video to load
      // also load/loadeddata is unreliable for video tag
      this.props.ready();
    }
  };

  revokeAttachmentUrl(clb) {
    URL.revokeObjectURL(this.state.attachmentUrl);
    this.setState({ attachmentUrl: undefined }, clb);
  }
  toggleLightBox = event => {
    event.preventDefault();
    this.setState(({ showLightBox }) => ({ showLightBox: !showLightBox }));
  };

  render() {
    const { attachment, reportMode } = this.props;
    const { attachmentUrl, showLightBox } = this.state;

    let media =
      attachmentUrl === null ? (
        <Spinner />
      ) : attachmentUrl && attachmentUrl.error ? (
        <img
          ref={this.ref}
          src={noImageUrl}
          alt="Fout bij het ophalen van bijlage"
          className={styles.media}
        />
      ) : attachment.contentType.includes('video') ? (
        <video
          ref={this.ref}
          onError={this.handleFileError}
          controls
          className={styles.media}
          src={attachmentUrl}
          style={{ backgroundColor: 'black', width: '100%' }}
        />
      ) : attachment.contentType.includes('image') ? (
        <>
          <img
            alt="Attachment"
            onError={this.handleFileError}
            className={styles.media}
            style={
              this.state.orientationTransform && {
                transform: this.state.orientationTransform,
              }
            }
            ref={this.ref}
            type={attachment.contentType}
            src={attachmentUrl}
          />
          <Lightbox
            toggler={showLightBox}
            sources={[attachmentUrl]}
            disableLocalStorage={true}
          />
        </>
      ) : (
        <span ref={this.ref}>
          {attachment.checksum}.{getAttachmentExtension(attachment)}
        </span>
      );

    if (!reportMode) {
      media = (
        <a
          rel="noopener noreferrer"
          href={attachmentUrl}
          onClick={this.toggleLightBox}
          target="_blank"
        >
          {media}
        </a>
      );
    }

    return (
      <div className={styles.root}>
        {media}
        {this.state.coordinate && (
          <a
            rel="noopener noreferrer"
            target="_blank"
            className={`${styles.geolink} btn btn-link`}
            href={`https://www.google.com/maps/?q=${this.state.coordinate.latitude},${this.state.coordinate.longitude}`}
          >
            <i className="fa fa-map-marker" /> geolocation available
          </a>
        )}
      </div>
    );
  }
}

const OuterAttachment = props => (
  <ReportReadyConsumer>
    {itf => <Attachment {...{ ...props, ...itf }} />}
  </ReportReadyConsumer>
);

export default connect(R.pick(['attachments']))(OuterAttachment);
