import classNames from 'classnames';
import * as React from 'react';
import { Button, FormGroup } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { parseAll } from '../../utils/LexicalParsing';
import Dropzone from '../Dropzone';
import CerthonMarkdown from '../Markdown';
import CerthonMarkdownEditor from '../MarkdownEditor';
import { sanitizeText } from '../TicketDetails/sanitizeText';
import UserToken from '../UserToken';
import stl from './Timeline.module.scss';

/*
 * FIXME: bad separation of concerns.
 * MessageBox/ReactMDE does parse commands and references but does not return a 'parsed' result, forcing other compontents to do the same thing
 */
export default class MessageBox extends React.Component {
  state = {
    markdownSelection: null,
    selectedTab: 'write',
  };

  dropzoneRef = React.createRef();

  handleMarkdownChange = text => {
    this.props.onMessageChange(text);
  };

  handleTabChange = selectedTab => {
    this.setState({ selectedTab });
  };

  handleSubmit = event => {
    event && event.preventDefault();
    if (!this.props.message) {
      return alert('Message can not be empty');
    }
    this.props.onSubmit();
  };

  componentDidMount = () => {
    // FIXME: fix blocking navigation when RR6 reintroduces it..
    // this.props.router.setRouteLeaveHook(
    //   R.last(this.props.router.routes),
    //   this.routerWillLeave,
    // );
  };

  routerWillLeave = nextLocation => {
    const { message } = this.props;

    if (message) {
      return 'Je hebt tekst ingevoerd. Weet je zeker dat je deze pagina wil verlaten?';
    }
  };

  handlePaste = event => {
    if (event.clipboardData?.files?.length) {
      this.props.onAttachments(Array.from(event.clipboardData.files));
    }
  };

  render() {
    const {
      user,
      message,
      onGeotag,
      lexes,
      users,
      allTickets,
      localTickets,
    } = this.props;

    return (
      <Dropzone
        className="dropzone"
        innerDropzone={this.dropzoneRef}
        accept="image/*,video/*"
        onDropAccepted={this.props.onAttachments}
      >
        <div
          className={classNames(stl.eventBox, stl.messageBox)}
          onPaste={this.handlePaste}
        >
          <div className={stl.userToken + ' ' + stl.msgBoxUserToken}>
            <UserToken collapse user={user} />
          </div>
          <div className={stl.content}>
            <form className={stl.messageForm} onSubmit={this.handleSubmit}>
              <FormGroup>
                <CerthonMarkdownEditor
                  className="column"
                  onChange={this.handleMarkdownChange}
                  lexes={lexes}
                  selectedTab={this.state.selectedTab}
                  onTabChange={this.handleTabChange}
                  generateMarkdownPreview={async text => {
                    const sanitized_text = sanitizeText(
                      parseAll(text, lexes),
                      text,
                    );
                    return (
                      <CerthonMarkdown
                        users={users}
                        allTickets={allTickets}
                        localTickets={localTickets}
                      >
                        {sanitized_text}
                      </CerthonMarkdown>
                    );
                  }}
                  childProps={{
                    textArea: {
                      id: 'message',
                      name: 'message',
                    },
                  }}
                  value={message}
                  visibility={{
                    preview: false,
                    previewHelp: false,
                  }}
                />
                <Button bsStyle="primary" type="submit" disabled={!message}>
                  <FormattedMessage
                    id="SEND"
                    defaultMessage="Send"
                    description=""
                  />
                </Button>
                <div className="pull-right">
                  <Button onClick={() => this.dropzoneRef.current.open()}>
                    <i className="fa fa-camera" />
                  </Button>
                  &nbsp;
                  <Button onClick={onGeotag}>
                    <i className="fa fa-map-marker" />
                  </Button>
                </div>
              </FormGroup>
            </form>
          </div>
        </div>
      </Dropzone>
    );
  }
}
