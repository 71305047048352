import { differenceBy, isNumber, map, negate, property } from 'lodash';
import React, { ChangeEvent } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { LocationWithDetails } from '../../modules/locations';
import { mapById } from '../../utils';
import InventoryLabelSelectionTree from '../InventoryLabelSelectionTree';
import { getChildren, MakeTree, makeTreeIndex } from '../Tree/common';
import { getLocationModificationDate } from './getLocationModificationDate';

function enumValues<T extends {}>(e: T): T[keyof T][] {
  return Object.values(e).filter(isNumber) as any;
}

function parseIntEnum<T extends {}>(e: T, value: any) {
  const v = parseInt(value, 10);
  if ((e as any)[v] === undefined) {
    throw new Error('invalid enum value');
  }
  return (v as unknown) as T[keyof T];
}

enum SelectionSetCriteria {
  other,
  all,
  none,
  withStock,
  withChanges,
  withStockAndChanges,
  notPrinted,
  unsalable,
  salable,
}

const msgs = defineMessages<keyof typeof SelectionSetCriteria>({
  none: {
    id: 'LOCATION_SELECTION_SET_CRITERIA.NONE',
    defaultMessage: 'None',
  },
  all: {
    id: 'LOCATION_SELECTION_SET_CRITERIA.ALL',
    defaultMessage: 'All',
  },
  withStock: {
    id: 'LOCATION_SELECTION_SET_CRITERIA.WITH_STOCK',
    defaultMessage: 'With Stock',
  },
  withChanges: {
    id: 'LOCATION_SELECTION_SET_CRITERIA.WITH_CHANGES',
    defaultMessage: 'With Changes',
  },
  withStockAndChanges: {
    id: 'LOCATION_SELECTION_SET_CRITERIA.WITH_STOCK_AND_CHANGES',
    defaultMessage: 'With Stock And Changes',
  },
  notPrinted: {
    id: 'LOCATION_SELECTION_SET_CRITERIA.NOT_PRINTED',
    defaultMessage: 'Not Printed',
  },
  other: {
    id: 'LOCATION_SELECTION_SET_CRITERIA.OTHER',
    defaultMessage: 'Other',
  },
  salable: {
    id: 'LOCATION_SELECTION_SET_CRITERIA.SALABLE',
    defaultMessage: 'Salable',
  },
  unsalable: {
    id: 'LOCATION_SELECTION_SET_CRITERIA.UNSALABLE',
    defaultMessage: 'Unsalable',
  },
});

const allCriteria = enumValues(SelectionSetCriteria);

const criteria: Record<
  SelectionSetCriteria,
  (l: LocationWithDetails) => boolean
> = {
  [SelectionSetCriteria.all]: () => true,
  [SelectionSetCriteria.none]: () => false,
  [SelectionSetCriteria.withStock]: l => !!l.stock.length,
  [SelectionSetCriteria.withChanges]: l =>
    l.label_created !== null &&
    new Date(l.label_created) < getLocationModificationDate(l),
  [SelectionSetCriteria.withStockAndChanges]: l =>
    !!l.stock.length &&
    l.label_created !== null &&
    new Date(l.label_created) < getLocationModificationDate(l),
  [SelectionSetCriteria.notPrinted]: l => l.label_created === null,

  [SelectionSetCriteria.other]: () => true,
  [SelectionSetCriteria.unsalable]: l =>
    l.unsalable || l.stock.some(s => s.unsalable),
  [SelectionSetCriteria.salable]: l =>
    !l.unsalable && l.stock.every(s => !s.unsalable),
};

const negatedCriteria = map(criteria, negate);
negatedCriteria[SelectionSetCriteria.other] = () => true;

const getId = property('id');

function getMatchingSelectionSetCriteria(
  allLocations: LocationWithDetails[],
  selectedLocations: LocationWithDetails[],
): SelectionSetCriteria[] {
  const excludedLocations = differenceBy(
    allLocations,
    selectedLocations,
    getId,
  );
  return allCriteria.filter(
    crit =>
      selectedLocations.filter(l => !!l.labeltype).every(criteria[crit]) &&
      excludedLocations.filter(l => !!l.labeltype).every(negatedCriteria[crit]),
  );
}

function selectionSetFromCriteria(
  allLocations: LocationWithDetails[],
  crit: SelectionSetCriteria,
): LocationWithDetails[] {
  return allLocations.filter(criteria[crit]);
}

interface InventoryLocationPrintLabelsProps {
  location: MakeTree<LocationWithDetails>;
  onPrintLabels: (locations: LocationWithDetails[]) => void;
}
const InventoryLocationPrintLabels: React.FC<InventoryLocationPrintLabelsProps> = ({
  location,
  onPrintLabels,
}) => {
  const index = React.useMemo(() => makeTreeIndex([location]), [location]);

  const [expandedIds, setExpandedIds] = React.useState<any[]>(() => {
    return [location, ...getChildren(location)].map(l => l.id);
  });

  const [selectedIds, setSelectedIds] = React.useState<any[]>(
    () => expandedIds,
  );

  const allLocations = React.useMemo(
    () => [location, ...getChildren(location)],
    [location],
  );
  const selectedLocations = React.useMemo(() => {
    const index = mapById(allLocations);
    return selectedIds.map(id => index[id]);
  }, [selectedIds, allLocations]);

  const matchingSelectionSetCriteria = React.useMemo(
    () => getMatchingSelectionSetCriteria(allLocations, selectedLocations),
    [allLocations, selectedLocations],
  );

  const [
    stickySelectionSet,
    setStickySelectionSet,
  ] = React.useState<SelectionSetCriteria | null>(null);

  const handleSeletionChange = React.useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      const value = parseIntEnum(SelectionSetCriteria, event.target.value);

      setSelectedIds(selectionSetFromCriteria(allLocations, value).map(getId));
      setStickySelectionSet(value);
    },
    [allLocations],
  );

  React.useEffect(() => {
    if (!matchingSelectionSetCriteria.includes(stickySelectionSet!)) {
      setStickySelectionSet(null);
    }
  }, [matchingSelectionSetCriteria, stickySelectionSet]);

  const handlePrintLabels = React.useCallback(() => {
    const locations = selectedIds.map(id => index[id]).filter(l => l.labeltype);
    onPrintLabels(locations);
  }, [index, onPrintLabels, selectedIds]);

  const toggleSelectedId = React.useCallback(
    (id: any) => {
      if (!selectedIds.includes(id)) {
        setSelectedIds([...selectedIds, id]);
      } else {
        setSelectedIds(selectedIds.filter(existingId => existingId !== id));
      }
    },
    [selectedIds, setSelectedIds],
  );

  return (
    <>
      <h4>Labels printen</h4>

      <label title="* denotes matching selection sets">
        selectie
        <select onChange={handleSeletionChange}>
          {allCriteria.map(c => (
            <option
              key={c}
              disabled={c === SelectionSetCriteria.other}
              selected={
                matchingSelectionSetCriteria.includes(stickySelectionSet!)
                  ? c === stickySelectionSet
                  : Math.max(...matchingSelectionSetCriteria) === c
              }
              value={c}
            >
              <FormattedMessage
                {...msgs[
                  SelectionSetCriteria[c] as keyof typeof SelectionSetCriteria
                ]}
              />
              {matchingSelectionSetCriteria.includes(c) && '*'}
            </option>
          ))}
        </select>
      </label>

      <InventoryLabelSelectionTree
        index={index}
        locations={[location]}
        expandedElementIds={expandedIds}
        onExpandedElementIdsChanged={setExpandedIds}
        selectedIds={selectedIds}
        toggleSelectedId={toggleSelectedId}
      />
      <button
        className="btn btn-primary"
        type="button"
        onClick={handlePrintLabels}
      >
        Print labels
      </button>
      {/* <Json>
        {{
          matchingSelectionSetCriteria,
          stickySelectionSet,
          SelectionSetCriteria,
          selectedIds,
        }}
      </Json> */}
    </>
  );
};

export default InventoryLocationPrintLabels;
