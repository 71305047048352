import * as React from 'react';
import { useSelector } from 'react-redux';
import Types from 'Types';
import { LabelsState } from '../../modules/labels';
import LabelToken from '../Labels/LabelToken';
import styles from './Tickets.module.scss';

const LabelList: React.FC<{ labelIds: number[] }> = ({ labelIds }) => {
  const labels = useSelector<Types.RootState, LabelsState>(
    ({ labels }) => labels,
  );

  return (
    <div>
      {labelIds.length ? (
        labelIds.map(l => labels![l]).map(l => <LabelToken label={l} />)
      ) : (
        <small className={styles.noDescription}>No labels</small>
      )}
    </div>
  );
};

export default LabelList;
