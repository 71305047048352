const typeMap = {
  note: 'ADD_MESSAGE',
  attachment: 'ADD_ATTACHMENT',
  geotag: 'ADD_LOCATION',
};

export default function attachmentToEvent({
  timestamp,
  user_id,
  type,
  text,
  checksum,
  contentType,
  geotag,
  ...rest
}) {
  return {
    created: timestamp,
    user_id,
    type: typeMap[type],
    payload: {
      checksum,
      contentType,
      message: text,
      ...geotag,
    },

    ...rest,
  };
}
