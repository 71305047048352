import QrScanner from 'qr-scanner'; // if installed via package and bundling with a module bundler like webpack or rollup
import * as React from 'react';
import LocationLabels from '../components/LocationLabels';
import Spinner from '../components/Spinner';
import { printingTestLabels } from '../test/testlocations';

export function TestError() {
  throw new Error('error getriggered door /err');
  // eslint-disable-next-line no-unreachable
  return null;
}
export function TestSpinners() {
  return (
    <div>
      <Spinner />
      <Spinner />
      <Spinner />
      <Spinner />
      <Spinner />
    </div>
  );
}
export function TestLocationLabels() {
  return <LocationLabels locations={printingTestLabels} />;
}

export function TestScanner() {
  const videoRef = React.useRef<HTMLVideoElement>(null);
  const [result, setResult] = React.useState<string>('');

  React.useEffect(() => {
    if (!videoRef.current) {
      throw new Error('missing videoRef');
    }
    console.log('lets go');

    const qrScanner = new QrScanner(
      videoRef.current!,
      result => {
        setResult(result.data);
      },
      {
        // returnDetailedScanResult: true,
        /* your options or returnDetailedScanResult: true if you're not specifying any other options */
      },
    );
    qrScanner.start();
    return () => {
      qrScanner.destroy();
    };
  }, [videoRef.current]);
  return (
    <div>
      <h1>scanner</h1>
      <video ref={videoRef}></video>
      <p>{result}</p>
    </div>
  );
}
