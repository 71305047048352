import { CountingRequest } from '@certhon/domain-models/lib';
import React from 'react';
import { Alert } from 'react-bootstrap';
import { countingInCountingRequestRange } from '../../common/counting/countingInCountingRequestRange';
import { CountingTarget } from '../../common/counting/createCountingList';
import { formatPeriod } from '../../containers/formatPeriod';
import { LocationWithDetails, Stock } from '../../modules/locations';
import { CountingState, InsertableCounting } from '../../stores/countingStore';
import CountingListComponent from '../CountingList.Component/CountingList.Component';
import Spinner from '../Spinner';
import { MakeTree } from '../Tree/common';
import stl from './LocationCounting.module.scss';

interface LocationCountingComponentProps {
  parentLocation: MakeTree<LocationWithDetails>;
  countingList: CountingTarget[];
  selectedLocation: MakeTree<LocationWithDetails> | null;
  stock: Stock | null;
  filterFunction: (location_id: string, stock: Stock) => boolean;
  countingRequest: CountingRequest;
  countingState: CountingState;
  saveCounting: (counting: InsertableCounting) => Promise<void>;
  onGoNext: () => void;
}

const LocationCountingComponent: React.FC<LocationCountingComponentProps> = ({
  countingList,
  countingRequest,
  countingState,
  selectedLocation,
  onGoNext,
  parentLocation,
  saveCounting,
  stock,
  filterFunction,
}) => {
  if (!countingList) {
    return <Spinner />;
  }

  const now = new Date();
  const isCountingRequestInEffect = countingRequest
    ? countingRequest.start_date < now && now < countingRequest.end_date
    : undefined;

  /** Latest valid counting for selected location in countingrequest  */
  const counting =
    (selectedLocation &&
      countingState[selectedLocation.id]?.[stock?.recnum!]?.find(
        countingInCountingRequestRange(countingRequest),
      )) ||
    null;

  return (
    <div className={stl.root}>
      {isCountingRequestInEffect === false && (
        <Alert bsStyle="warning">
          {`Counting Request Period is not currently in effect (${formatPeriod(
            countingRequest!.start_date,
            countingRequest!.end_date,
          )})`}
        </Alert>
      )}
      <CountingListComponent
        countingRequest={countingRequest}
        rootLocation={parentLocation}
        list={countingList}
        filterFunction={filterFunction}
        countingState={countingState}
        selection={
          (selectedLocation && {
            location_id: selectedLocation.id,
            artikel_id: stock?.recnum || null,
          }) ||
          null
        }
        onGoNext={onGoNext}
        // location={selectedLocation}
        stock={stock}
        counting={counting}
        saveCounting={saveCounting}
      />
    </div>
  );
};

export default LocationCountingComponent;
