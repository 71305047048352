import PropTypes from 'prop-types';
import * as React from 'react';
import { Button, FormControl } from 'react-bootstrap';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { createFeedback } from '../../../modules/feedback';
import styles from './Feedback.module.scss';

const msg = defineMessages({
  THANK: {
    id: 'FEEDBACK.THANK',
    defaultMessage: 'Thank you!',
    description: 'Message which appears when user has submitted feedback',
  },
  INTERNAL_FEEDBACK_BUTTON_LABEL: {
    id: 'FEEDBACK.INTERNAL_FEEDBACK_BUTTON_LABEL',
    defaultMessage: 'Internal feedback',
    description: 'Label of button for providing internal feedback',
  },
  TEXTAREA_PLACEHOLDER: {
    id: 'FEEDBACK.TEXTAREA_PLACEHOLDER',
    defaultMessage:
      'For example: this question is not relevant in this chapter. \n\n(This information is only internally visible)',
    description: '',
  },
  SUBMIT_BUTTON_LABEL: {
    id: 'FEEDBACK.SUBMIT_BUTTON_LABEL',
    defaultMessage: 'Submit feedback',
    description: 'Label of submit button of feedback form',
  },
  CANCEL_BUTTON_LABEL: {
    id: 'FEEDBACK.CANCEL_BUTTON_LABEL',
    defaultMessage: 'Cancel',
    description: 'Label of cancel button of feedback form',
  },
});

export default injectIntl(
  class Feedback extends React.Component {
    static propTypes = {
      dispatch: PropTypes.func.isRequired,
      list: PropTypes.object.isRequired,
      nodeId: PropTypes.string.isRequired,
      user: PropTypes.object.isRequired,
    };

    constructor(props) {
      super(props);
      this.state = { expanded: false, message: '', done: false };
    }

    componentWillReceiveProps(nextProps) {
      if (
        this.props.listId !== nextProps.listId ||
        this.props.nodeId !== nextProps.nodeId
      ) {
        this.setState({ expanded: false, message: '', done: false });
      }
    }

    toggleExpanded = () => {
      this.setState({
        expanded: !this.state.expanded,
      });
    };

    handleSubmit = event => {
      event.preventDefault();
      const { dispatch, list, nodeId, user } = this.props;
      const { message } = this.state;

      const node = list.index[nodeId];

      let protoListId = list.tree.id; // for some reason the list object contains only the tree & index fields
      let protoNodeId = nodeId;
      if (node.protoChain) {
        protoNodeId = node.protoChain[0].nodeId;
        protoListId = node.protoChain[0].listId;
      }

      dispatch(
        createFeedback({
          proto_list_id: protoListId,
          proto_node_id: protoNodeId,
          instance_list_id: list.tree.id,
          instance_node_id: nodeId,
          user_id: user.recnum,
          message,
        }),
      );
      this.setState({ expanded: false, message: '', done: true });
    };

    handleReset = () => {
      this.setState({ expanded: false, message: '', done: false });
    };

    render() {
      const { intl } = this.props;
      const { expanded, done, message } = this.state;
      return (
        <div className={styles.root}>
          <div className="clearfix">
            <Button
              onClick={this.toggleExpanded}
              className={`pull-right ${styles.feedbackBtn}`}
              bsClass="link"
            >
              <i className={`fa ${done ? 'fa-check' : 'fa-bullhorn'}`} />{' '}
              {done
                ? intl.formatMessage(msg.THANK)
                : intl.formatMessage(msg.INTERNAL_FEEDBACK_BUTTON_LABEL)}
            </Button>
          </div>
          <div
            className={`${styles.formContainer} ${
              expanded ? styles.expanded : ''
            }`}
          >
            <form onReset={this.handleReset} onSubmit={this.handleSubmit}>
              <FormControl
                value={message}
                componentClass="textarea"
                rows="5"
                placeholder={intl.formatMessage(msg.TEXTAREA_PLACEHOLDER)}
                onChange={event =>
                  this.setState({ message: event.target.value })
                }
              />
              <Button className="pull-right" type="submit" bsStyle="primary">
                <FormattedMessage {...msg.SUBMIT_BUTTON_LABEL} />
              </Button>
              <Button type="reset">
                <FormattedMessage {...msg.CANCEL_BUTTON_LABEL} />
              </Button>
            </form>
          </div>
        </div>
      );
    }
  },
);
