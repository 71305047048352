import classNames from 'classnames';
import * as React from 'react';
import charToDepartment from '../../common/logic/charToDepartment';
import departmentToChar from '../../common/logic/departmentToChar';
import stl from './DepartmentToken.module.scss';

interface Props {
  value: string;
  children?: React.ReactNode;
  className?: string;
  inline?: boolean;
}

const DepartmentToken: React.FC<Props> = ({
  value,
  children,
  className,
  inline = false,
}) => {
  let v = departmentToChar(value) || value.toUpperCase();
  let description = charToDepartment(v);
  let firstLetter = description ? description[0] : '?';
  if (firstLetter) {
    firstLetter = firstLetter.toUpperCase();
  }
  return (
    <div
      className={classNames(
        className,
        stl.parameter,
        stl.small,
        inline && stl.inline,
        stl[v],
      )}
    >
      <div className={stl.name}>
        <i className="fa fa-university" />
      </div>
      {children ? (
        <div className={stl.value}>{children}</div>
      ) : (
        <div className={stl.value}>
          <span className={'visible-xs'}>{firstLetter}</span>
          <span className={'hidden-xs'}>{description}</span>
        </div>
      )}
    </div>
  );
};

export default DepartmentToken;
