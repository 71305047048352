import QrScanner from 'qr-scanner';
import React from 'react';

interface QRScannerProps {
  onScan: (code: string) => void;
}

function createIdAssigner(descr: string) {
  const objectIdMap = new WeakMap<object, number>();
  let nextId = 1;

  function assignAndPrintId(obj: object | null | undefined): void {
    let id: number;
    if (!obj) {
      console.log(`${descr} ID: null`);

      return;
    } else if (objectIdMap.has(obj)) {
      id = objectIdMap.get(obj)!;
    } else {
      id = nextId++;
      objectIdMap.set(obj, id);
    }
    console.log(`${descr} ID: ${id}`);
  }

  return assignAndPrintId;
}

const videoIdAssigner = createIdAssigner('video');
const scannerIdAssigner = createIdAssigner('scanner');

export const QRScanner: React.FC<QRScannerProps> = ({ onScan }) => {
  const videoRef = React.useRef<HTMLVideoElement>(null);
  const scannerRef = React.useRef<QrScanner | null>(null);
  const [vidInstance, setVidInstance] = React.useState(0);

  // React.useEffect(() => {
  //   throw new Error('onScan prop cannot be changed after initial render');
  // }, [onScan]);

  React.useEffect(() => {
    logDeps('useEffect', videoRef, scannerRef, vidInstance);
    if (videoRef.current) {
      scannerRef.current = new QrScanner(
        videoRef.current,
        result => {
          console.log('result', result);
          onScan(result.data);
        },
        {
          onDecodeError: () => {},
          highlightCodeOutline: true,
        },
      );
      logDeps('start', videoRef, scannerRef, vidInstance);
      scannerRef.current.start();
      return () => {
        logDeps('destroy', videoRef, scannerRef, vidInstance);
        setVidInstance(vidInstance + 1);
        scannerRef.current?.destroy();
      };
    }
  }, [vidInstance, onScan]);

  return <video ref={videoRef} key={vidInstance} style={{ width: '100%' }} />;
};
function logDeps(
  context: string,
  videoRef: React.RefObject<HTMLVideoElement>,
  scannerRef: React.MutableRefObject<QrScanner | null>,
  vidInstance: number,
) {
  console.group(context);
  videoIdAssigner(videoRef.current);
  scannerIdAssigner(scannerRef.current);
  console.log('vidInstance', vidInstance);
  console.groupEnd();
}
