import classNames from 'classnames';
import * as R from 'ramda';
import * as React from 'react';
import { ListGroup, ListGroupItem } from 'react-bootstrap';
import { defineMessages, FormattedMessage } from 'react-intl';
import Meeting from '../../models/Meeting';
import { User } from '../../models/User';
import commonStl from '../commonStyles.module.scss';
import FilterSummary from '../FilterSummary';
import FormattedRelative from '../FormattedRelative';
import LinkContainer from '../LinkContainer';
import UserToken from '../UserToken';
import styles from './MeetingsList.module.scss';

interface MinutesListProps {
  minutes: Meeting[];
  users: { [recnum: string]: User };
  totalItems?: number;
  onClear?: () => void;
  onReset?: () => void;
}

const msg = defineMessages({
  NO_MEETINGS: {
    id: 'MEETINGS_LIST.NO_MEETINGS',
    defaultMessage: 'Meetings can not be displayed',
  },
  MEETINGS: {
    id: 'MEETINGS_LIST.MEETINGS',
    defaultMessage: 'meetings',
  },
});
const meetingsItemName = <FormattedMessage {...msg.MEETINGS} />;

const sortMinutes = R.sortWith<Meeting>([
  R.descend(R.prop('name')),
  R.descend(R.pipe(R.prop('number'), R.defaultTo(0))),
]);

const MeetingsList: React.FC<MinutesListProps> = ({
  minutes,
  users,
  totalItems,
  ...rest
}) => {
  if (!minutes || !users) {
    return (
      <p className={commonStl.noEntities}>
        <FormattedMessage {...msg.NO_MEETINGS} />
      </p>
    );
  }

  const items = sortMinutes(minutes).map(m => (
    <LinkContainer key={m.id} to={`/meeting/${m.id}`}>
      <ListGroupItem className={styles.root}>
        <div className={styles.title}>
          <div className={styles.right}>
            {m.present.map(u => (
              <UserToken
                className={styles.token}
                small={true}
                withText={false}
                collapse={false}
                user={users[u]}
              />
            ))}
            {m.absent.map(u => (
              <UserToken
                className={classNames(styles.token, styles.absent)}
                small={true}
                withText={false}
                collapse={false}
                user={users[u]}
              />
            ))}
          </div>
          {m.name}
          {' #'}
          {m.number}{' '}
          <em className="text-muted">
            {m.date ? <FormattedRelative value={m.date} /> : ''}
          </em>
        </div>
      </ListGroupItem>
    </LinkContainer>
  ));

  return (
    <>
      <ListGroup>{items}</ListGroup>
      {totalItems !== undefined && (
        <FilterSummary
          total={totalItems}
          visible={items.length}
          itemName={meetingsItemName}
          {...rest}
        />
      )}
    </>
  );
};

export default MeetingsList;
