import { Artikel } from '@certhon/domain-models/lib';
import * as React from 'react';
import Autocomplete from 'react-autocomplete';
import { Button, ControlLabel, Form, FormGroup, Modal } from 'react-bootstrap';
import { defineMessages, FormattedMessage } from 'react-intl';
import useArtikelSearch from '../../hooks/useArtikelSearch';
import stl from '../ProjectsPage/ProjectSelection.module.scss';
import matchArtikelToTerm from './matchArtikelToTerm';

const swappedMatchArtikelToTerm = (artikel: Artikel, term: string) =>
  matchArtikelToTerm(term)(artikel);

const msg = defineMessages({
  SEARCH_HEADER: {
    id: 'ARTIKEL_OVERVIEW_COMPONENT.SEARCH_HEADER',
    defaultMessage: 'Search for Artikel',
    description: '',
  },
  ADD_BUTTON: {
    id: 'ARTIKEL_OVERVIEW_COMPONENT.ADD_BUTTON',
    defaultMessage: 'Add',
    description: '',
  },
  CANCEL_BUTTON: {
    id: 'ARTIKEL_OVERVIEW_COMPONENT.CANCEL_BUTTON',
    defaultMessage: 'Cancel',
    description: '',
  },
});

const renderMenu = (children: React.ReactNode) => (
  <ul className="dropdown-menu">{children}</ul>
);

const renderInput = (props: any) => (
  <input
    key="input"
    {...props}
    name="artikel"
    placeholder={'Typ om te zoeken (minstens 3 karakters)'}
    className="form-control"
  />
);

const renderItem = (p: Artikel, isHighlighted: boolean) => (
  <li className={`item ${stl.item} ${isHighlighted ? stl.highlighted : ''}`}>
    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
    <a>{renderArtikel(p)}</a>
  </li>
);
export function renderArtikel(p: Artikel) {
  return (
    <>
      <span title={p.subset_description}>
        {p.subset}-{p.volgnummer.toString().padEnd(4, ' ')}
      </span>{' '}
      {p.description}
    </>
  );
}
interface ArtikelSearchModalProps {
  onChooseArtikel: (artikelRecnum: number) => void;
  onCancel: () => void;
  show: boolean;
}
const ArtikelSearchModal: React.FC<ArtikelSearchModalProps> = ({
  onChooseArtikel,
  onCancel,
  show,
}) => {
  // search value and selected artikel
  const [value, setValue] = React.useState<string>('');
  const ref = React.useRef<any>();
  React.useEffect(() => {
    show && ref.current?.focus();
  }, [show]);

  const artikels = useArtikelSearch(value);

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.value);
    },
    [setValue],
  );

  const [artikelRecnum, setArtikelRecnum] = React.useState<number | null>(null);
  const handleSelect = React.useCallback(
    (value: string, artikel: Artikel) => {
      setValue(
        `${artikel.subset}-${artikel.volgnummer.toString().padEnd(4, ' ')} ${
          artikel.description
        }`,
      );
      setArtikelRecnum(artikel.recnum);
    },
    [setValue, setArtikelRecnum],
  );

  const handleReset = React.useCallback(() => {
    setArtikelRecnum(null);
    setValue('');
  }, []);

  const handleCancel = React.useCallback<React.FormEventHandler<Form>>(
    async event => {
      onCancel();
      handleReset();
    },
    [onCancel, handleReset],
  );
  const handleSubmit = React.useCallback<React.FormEventHandler<Form>>(
    async event => {
      event.preventDefault();
      onChooseArtikel(artikelRecnum!);
      handleReset();
    },
    [onChooseArtikel, artikelRecnum, handleReset],
  );

  return (
    <Modal show={show} onHide={() => {}}>
      <Modal.Header>Artikel zoeken</Modal.Header>
      <Modal.Body>
        <Form id="daform" onSubmit={handleSubmit} onReset={handleCancel}>
          <FormGroup controlId="project">
            <ControlLabel>Artikel</ControlLabel>

            <Autocomplete
              ref={ref}
              value={value}
              items={artikels}
              onChange={handleChange}
              shouldItemRender={swappedMatchArtikelToTerm}
              getItemValue={a => a.description}
              onSelect={handleSelect}
              selectOnBlur={false}
              renderInput={renderInput}
              renderItem={renderItem}
              wrapperProps={{
                className: 'dropdown open',
                style: { display: 'block' },
              }}
              renderMenu={renderMenu}
            />
          </FormGroup>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button form="daform" type="reset">
          <FormattedMessage {...msg.CANCEL_BUTTON} />
        </Button>
        <Button
          form="daform"
          type="submit"
          bsStyle="primary"
          disabled={!artikelRecnum}
        >
          <FormattedMessage {...msg.ADD_BUTTON} />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ArtikelSearchModal;
