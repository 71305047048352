import { TicketStatus } from '@certhon/domain-models';
import classNames from 'classnames';
import { findAndReplace } from 'mdast-util-find-and-replace';
import { PhrasingContent } from 'mdast-util-find-and-replace/lib';
import * as React from 'react';
import Markdown, { Options } from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Plugin } from 'unified';
import { Link } from 'wouter';
import { ExcludeNull } from '../../common/logic/types';
import { StoreTicket } from '../../modules/tickets';
import { UsersState } from '../../modules/users';
import styles from './Markdown.module.scss';
const components: Options['components'] = {
  a: ({ node, ...props }) => {
    return <Link {...(props as any)} />;
  },
  table: ({ node, ...props }) => {
    return (
      <table
        {...props}
        style={{ display: 'table', width: 'inherit' }}
        className="table table-striped table-bordered"
      >
        {props.children}
      </table>
    );
  },
  img: ({ node, className, alt = 'noalt', src, ...rest }) => {
    if (src && /.*.(webm|mp4|avi|wmv|m4v|mpe?g)$/.test(src)) {
      return (
        <video
          controls
          className={classNames('img-responsive', 'img-thumbnail', className)}
          title={alt}
          src={src}
        />
      );
    }
    return (
      <img
        className={classNames('img-responsive', 'img-thumbnail', className)}
        alt={alt}
        src={src}
        {...rest}
      />
    );
  },
};

const CerthonMarkdown: React.FC<Options & {
  additionalComponents?: Options['components'];
  localTickets: Record<string, StoreTicket>;
  allTickets: Record<string, StoreTicket>;
  users: ExcludeNull<UsersState>;
}> = ({
  className,
  children,
  additionalComponents,
  allTickets = {},
  localTickets = {},
  users = {},
  ...props
}) => {
  const certhonReferences: Plugin = (options: {}) => {
    return (tree, vfile) => {
      findAndReplace(
        tree as any,
        [
          [
            /@(?<loginname>[a-z0-9][a-z0-9_.]+[a-z0-9]+)/,
            (value, loginname, match) => {
              const user = Object.values(users!).find(
                u => u.loginname === loginname,
              );

              return user
                ? {
                    type: 'link',
                    title: user!.name,
                    url: '#',
                    children: [
                      {
                        type: 'strong',
                        children: [
                          { type: 'text', value: '@' + user.loginname },
                        ],
                      },
                    ],
                  }
                : false;
            },
          ],
          [
            /#((?<id>[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})|(?<number>\d+))(?<plus>\+)?/g,
            (
              _value: string,
              _numberOrId: string,
              id: string,
              number: string,
              plus: string,
            ) => {
              let ticket: StoreTicket | null = null;
              if (number) {
                ticket = localTickets[number];
                // replace with a local ticket
              } else if (id) {
                // replace with a global ticket
                ticket = allTickets[id];
              }
              if (!ticket) {
                return false;
              }

              const isLocal =
                !ticket.number ||
                (localTickets[ticket.number as any] &&
                  ticket.id === localTickets[ticket.number as any]?.id);

              // Fixme: use project/work names as projectdescription
              const parentDescription = isLocal ? '' : 'other project';
              let node: PhrasingContent = {
                type: 'text',
                value: `#${ticket.number || '?'} ${
                  plus ? ' ' + ticket!.title : ''
                } ${isLocal ? '' : `(${parentDescription})`}`,
              };
              if (ticket.status === TicketStatus.CLOSED) {
                node = { type: 'delete', children: [node] };
              }
              return {
                type: 'link',
                title: `#${ticket.number} ${ticket.title} ${parentDescription}`,
                url: `/tickets/${ticket.id}`,
                children: [
                  {
                    type: 'strong',
                    children: [node],
                  },
                ],
              };
            },
          ],
        ],
        { ignore: ['link', 'linkReference'] },
      );
    };
  };

  return (
    <Markdown
      children={children}
      className={classNames(styles.root, className)}
      {...props}
      skipHtml={false}
      // allowedElements={['a']}
      linkTarget={href => determineLinkTarget(href)}
      remarkPlugins={[certhonReferences, remarkGfm]}
      components={
        additionalComponents
          ? { ...components, ...additionalComponents }
          : components
      }
    />
  );
};

export default CerthonMarkdown;

function determineLinkTarget(
  href: string,
): React.HTMLAttributeAnchorTarget | undefined {
  const p = document.createElement('a');
  p.href = href;
  if (p.host !== window.location.host) {
    return '_blank';
  }
  return '_self';
}
