import { useCallback } from 'react';
import { Dispatch } from 'redux';
import { Catalog } from '../../models/Catalog';
import { resetCatalog, setClosedCatalogGroups } from '../../modules/catalogUI';

export function useFoldAllHandler(dispatch: Dispatch, catalog?: Catalog) {
  return useCallback(() => {
    catalog &&
      dispatch(
        setClosedCatalogGroups(
          catalog.recnum,
          catalog.groups.map(cg => cg.recnum),
        ),
      );
  }, [dispatch, catalog]);
}
export function useUnfoldAllHandler(dispatch: Dispatch, catalog?: Catalog) {
  return useCallback(() => catalog && dispatch(resetCatalog(catalog?.recnum)), [
    dispatch,
    catalog,
  ]);
}
