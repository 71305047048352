import React from 'react';
import { Link } from 'wouter';
import {
  CountingsStatsIndex,
  CountingStats,
} from '../../common/counting/calculateRemainingCountingsStats';
import { replaceNumberingPlaceholder } from '../../common/location/replaceNumberingPlaceholder';
import { MAX_COUNTINGS_TO_BE_COUNTABLE } from '../../containers/CountingRequestDetails.Container';
import { LocationWithDetails } from '../../modules/locations';
import Icon from '../Icon';
import TreeTable, { TreeTableProps } from '../Tree';
import { MakeTree } from '../Tree/common';
import { Element, TreeElementProps } from '../Tree/TreeElement';

interface InventoryRemainingCountingsTreeItemProps {
  props: TreeElementProps;
  item: MakeTree<LocationWithDetails>;
  linkBase: string;
  stats: CountingStats | null;
}

const InventoryRemainingCountingsTreeItem: React.FC<InventoryRemainingCountingsTreeItemProps> = ({
  props,
  linkBase,
  item,
  stats,
}) => {
  let statsElem: React.ReactNode = '';
  if (stats) {
    if (stats.counted === stats.total) {
      statsElem = (
        <>
          Done <i className="fa fa-check" />
        </>
      );
    } else {
      statsElem = (
        <>
          {stats.counted} / {stats.total} (
          {Math.round((stats.counted / stats.total) * 100)}%){' '}
        </>
      );
    }
  }

  return (
    <Element
      additionalColumns={
        <>
          <td>
            <Link to={`${linkBase}/count/${item.id}`}>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a className="pull-right">
                {stats && stats.total < MAX_COUNTINGS_TO_BE_COUNTABLE ? (
                  <>
                    {statsElem}
                    {stats.counted !== stats.total && (
                      <i className="fa fa-chevron-right" />
                    )}
                  </>
                ) : (
                  <em className="pull-right">{statsElem}</em>
                )}
              </a>
            </Link>
          </td>
        </>
      }
      icons={<>{!item.children?.length && <Icon name={'box'} />}</>}
      {...props}
      name={replaceNumberingPlaceholder(item.name, item.ordering)}
    />
  );
};

interface InventoryRemainingCountingsTreeProps
  extends Pick<
    TreeTableProps<MakeTree<LocationWithDetails>>,
    'onExpandedElementIdsChanged' | 'expandedElementIds' | 'index'
  > {
  linkBase: string;
  locations: MakeTree<LocationWithDetails>[];
  countingStatsIndex: CountingsStatsIndex;
}
const InventoryRemainingCountingsTree: React.FC<InventoryRemainingCountingsTreeProps> = ({
  linkBase,
  locations,
  index,
  expandedElementIds,
  onExpandedElementIdsChanged,
  countingStatsIndex,
}) => (
  <TreeTable<MakeTree<LocationWithDetails>>
    thead={
      <tr>
        <th>Counting</th>
      </tr>
    }
    index={index}
    elements={locations}
    renderRow={(props, item) => (
      <InventoryRemainingCountingsTreeItem
        props={{ ...props }}
        item={item}
        linkBase={linkBase}
        stats={countingStatsIndex[props.id]}
      />
    )}
    onExpandedElementIdsChanged={onExpandedElementIdsChanged}
    expandedElementIds={expandedElementIds}
  ></TreeTable>
);

export default InventoryRemainingCountingsTree;
