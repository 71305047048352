import { Meta, TicketEvent } from '@certhon/domain-models/lib';
import { isBefore } from 'date-fns';
import { curry, findLast, pipe, pluck, prop, uniq } from 'ramda';
import * as uuid from 'uuid';
import { TicketEventType, TicketWithEvents } from '../../models/Ticket';
import { StoreTicket, StoreTicketWithEvents } from '../../modules/tickets';
import { parseDate } from './misc';

export function createTicketEvent(
  ticket_id: string,
  type: TicketEventType,
  payload: TicketEvent['payload'],
  user_id: number,
  meta: Meta | null = null,
): TicketEvent {
  if (!user_id) {
    throw new Error('cant create ticket-event without user_id');
  }

  return {
    type,
    id: uuid.v4(),
    created: new Date(),
    user_id,
    ticket_id,
    payload,
    meta,
  };
}

export function isTicketWithEvents(t: StoreTicket): t is StoreTicketWithEvents {
  return !!t.events;
}
export function assertTicketWithEvents(
  t: StoreTicket,
): asserts t is StoreTicketWithEvents {
  if (!isTicketWithEvents(t)) {
    throw new Error(
      'Assertion failed: expected type StoreticketWithEvents, events were not found',
    );
  }
}

/**
 * Check if a ticket is closed
 * @param  {any}  list
 * @return {false|Date} false when open or a Date of the time of closing
 */
export function isTicketClosed(ticket: StoreTicket) {
  if (ticket.status !== 'closed') {
    return false;
  }
  return true;
}

export function findTicketClosedDate(ticket: TicketWithEvents) {
  if (ticket.status !== 'closed') {
    return false;
  }
  const closeEvent = findLast(
    e => e.type === TicketEventType.UPDATE_TICKET && !!e.payload.status,
    ticket.events,
  );

  if (!closeEvent) {
    // this ticket has been created 'closed'
    if (!ticket.created) {
      // tslint:disable-next-line: no-console
      console.warn('Could not determine date for closed ticket', ticket);
      return new Date(0);
    }
    return parseDate(ticket.created);
  }
  console.assert(
    closeEvent?.payload.status === 'closed',
    `expect last status update to be 'closed'`,
  );
  return new Date(closeEvent.created);
}

export function doesTicketHaveUnsyncedEvents(ticket: StoreTicket) {
  return !!ticket._modified;
}

export function ticketWasOpenAt(date: Date, ticket: TicketWithEvents) {
  const t = getTicketAt(date, ticket);
  return t?.status === 'opened';
}

export function wasTicketCreatedDuringPeriod(
  period_start: Date,
  period_end: Date,
  t: TicketWithEvents,
) {
  const lastStatusChange = findLast(e => {
    const created = parseDate(e.created);
    return !!(
      e.type === 'CREATE_TICKET' &&
      e.payload.status &&
      isBefore(period_start, created) &&
      isBefore(created, period_end)
    );
  }, t.events);
  return lastStatusChange && lastStatusChange.payload.status === 'opened';
}

export function wasTicketReopenedDuringPeriod(
  period_start: Date,
  period_end: Date,
  t: TicketWithEvents,
) {
  const lastStatusChange = findLast(e => {
    const created = parseDate(e.created);
    return !!(
      e.type === 'UPDATE_TICKET' &&
      e.payload.status &&
      isBefore(period_start, created) &&
      isBefore(created, period_end)
    );
  }, t.events);

  return lastStatusChange && lastStatusChange.payload.status === 'opened';
}

export function wasTicketClosedDuringPeriod(
  period_start: Date,
  period_end: Date,
  t: TicketWithEvents,
) {
  const lastStatusChange = findLast(e => {
    const created = parseDate(e.created);
    return !!(
      e.type === 'UPDATE_TICKET' &&
      e.payload.status &&
      isBefore(period_start, created) &&
      isBefore(created, period_end)
    );
  }, t.events);

  return lastStatusChange && lastStatusChange.payload.status === 'closed';
}

export const getEventUserIds: (t: TicketWithEvents) => number[] = pipe(
  prop('events'),
  pluck('user_id'),
  uniq,
);

export function ticketTimeOpen(ticket: TicketWithEvents) {
  const opened = parseDate(ticket.events[0].created).getTime();

  const closed = findTicketClosedDate(ticket) || new Date();
  return closed.getTime() - opened;
}

/**
 * Return the state of the fields of a ticket at a given moment in time
 */
export function getTicketAt(
  date: Date,
  ticket: TicketWithEvents,
): StoreTicket | null {
  const events = ticket.events.filter(
    ({ type, created }) =>
      ['CREATE_TICKET', 'UPDATE_TICKET'].includes(type) &&
      isBefore(new Date(created), date),
  );
  if (events.length === 0) {
    return null;
  }
  return events.reduce((t, a) => ({ ...t, ...a.payload }), {}) as StoreTicket;
}

/**
 * Check wether a user is involved with a ticket
 * NB this function is not consistend with getTicketUsers
 */
export const isUserInvolvedWithTicket = curry((userId, ticket) => {
  if (
    ticket.assignee_user_id === userId ||
    ticket.creator_user_id === userId ||
    ticket.executor_user_id === userId
  ) {
    return true;
  }
  for (const event of ticket.events) {
    if (event.user_id === userId) {
      return true;
    }
  }
  return false;
});

/**
 * Get users involved with a ticket.
 * NB this function is not consistend with isUserinvolvedWithTicket
 * @param Ticket ticket
 */
export function getTicketUsers(ticket: StoreTicket | StoreTicketWithEvents) {
  const assignedRoles: number[] = [];
  const userIds = (ticket.events || []).map(event => {
    if (event.payload && event.payload.assignee_user_id) {
      assignedRoles.push(event.payload.assignee_user_id);
    }
    if (event.payload && event.payload.executor_user_id) {
      assignedRoles.push(event.payload.executor_user_id);
    }
    return event.user_id;
  });
  if (ticket.assignee_user_id) {
    userIds.push(ticket.assignee_user_id);
  }
  if (ticket.executor_user_id) {
    userIds.push(ticket.executor_user_id);
  }
  // FIXME: this field is not optional
  if (ticket.creator_user_id) {
    userIds.push(ticket.creator_user_id);
  }
  return uniq([...userIds, ...assignedRoles]);
}

export function isProjectTicket(ticket: StoreTicket) {
  return !!ticket.project_id;
}

export function isTicketOverdue(ticket: StoreTicket) {
  if (!ticket.due) {
    return false;
  }
  const d = parseDate(ticket.due);
  return d < new Date();
}
