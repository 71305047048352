export * from './attachmentStorageMiddleware';

export const apiErrorMiddleware = store => next => action => {
  if (
    /FAILURE_.*/.test(action.type) &&
    action.payload &&
    action.payload.name === 'ApiError' &&
    action.payload.status === 401 &&
    action.type !== 'FAILURE_AUTHENTICATE'
  ) {
    // alert('You do not have permission to perform this action');
    // console.log({ res: action.payload.response });
    // store.dispatch(signOut());
  }
  return next(action);
};
