interface MaybeGeoExif {
  // "GPSInfoIFDPointer": 1840,
  // "GPSLatitudeRef": "N",
  GPSLatitudeRef: string;
  // "GPSLatitude": [
  //   51,
  //   55,
  //   4.69
  // ],
  GPSLatitude: number[];
  // "GPSLongitudeRef": "E",
  GPSLongitudeRef: string;
  // "GPSLongitude": [
  //   4,
  //   21,
  //   14.44
  // ],
  GPSLongitude: number[];
  // "GPSAltitudeRef": 1,
  GPSAltitudeRef: number;
  // "GPSAltitude": 3.596850874514764,
  GPSAltitude: number;
  // "GPSTimeStamp": [
  //   14,
  //   27,
  //   34
  // ],

  // "GPSSpeedRef": "K",
  // "GPSSpeed": 0,
  // "GPSImgDirectionRef": "T",
  // "GPSImgDirection": 96.07531692766592,
  // "GPSDestBearingRef": "T",
  // "GPSDestBearing": 96.07531692766592,
  // "GPSDateStamp": "2019:05:28",
}
export default function geopositionFromExif(
  data: MaybeGeoExif
): GeolocationCoordinates | null {
  if (data.GPSAltitude && data.GPSLatitude) {
    const aLat = data.GPSLatitude;
    const aLong = data.GPSLongitude;
    const strLatRef = data.GPSLatitudeRef || 'N';
    const strLongRef = data.GPSLongitudeRef || 'W';
    const fLat =
      (aLat[0] + aLat[1] / 60 + aLat[2] / 3600) * (strLatRef === 'N' ? 1 : -1);
    const fLong =
      aLong[0] +
      aLong[1] / 60 +
      (aLong[2] / 3600) * (strLongRef === 'W' ? -1 : 1);
    return {
      latitude: fLat,
      longitude: fLong,
      accuracy: 1,
      altitude: null,
      altitudeAccuracy: null,
      heading: null,
      speed: null
    };
  }
  return null;
}
