import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Types from 'Types';
import { useLocation } from 'wouter';
import Json from '../components/Json';
import MaterialListDetailsEditor from '../components/MaterialListDetailsEditor';
import Spinner from '../components/Spinner';
import { UsersState } from '../modules/users';
import {
  UpdatableMaterialList,
  useMaterialListStore,
} from '../stores/materialListStore';
import { withKey } from '../utils/component';

interface MaterialListDetailsContainerProps {
  recnum: number;
}

const MaterialListDetailsContainer: React.FC<MaterialListDetailsContainerProps> = ({
  recnum,
}) => {
  const [, goTo] = useLocation();
  const [
    { materialLists },
    { fetch, save, remove, ...remainingMaterialMethods },
  ] = useMaterialListStore();
  const [hasFetched, setHasFetched] = React.useState<boolean>(false);
  const originalMaterialList = materialLists?.[recnum];
  const [error, setError] = React.useState<Error | null>(null);

  const handleRemove = React.useCallback(() => {
    remove(originalMaterialList.recnum).then(() => goTo('/inventory/lists'));
  }, [goTo, originalMaterialList?.recnum, remove]);

  useEffect(() => {
    fetch(recnum).then(
      () => setHasFetched(true),
      err => {
        setError(err);
        setHasFetched(true);
      },
    );
  }, [fetch, recnum]);

  const [changes, setChanges] = React.useState<
    Omit<UpdatableMaterialList, 'recnum'>
  >({});
  const users = useSelector<Types.RootState, UsersState>(state => state.users)!;

  const materialList = React.useMemo(
    () => ({ ...originalMaterialList, ...changes }),
    [changes, originalMaterialList],
  );

  const hasChanges = Object.keys(changes).length > 0;

  const handleSubmit = React.useCallback(async () => {
    try {
      await save({ recnum, ...changes });
      setChanges({});
    } catch (error) {
      setError(error as Error);
    }
  }, [changes, recnum, save]);

  const handleChange = React.useCallback(
    async (pml: Partial<UpdatableMaterialList>, saveImmediately?: boolean) => {
      if (saveImmediately) {
        // we cant use handleSubmit because we dont want to want to wait for the state to propagate and use another effect
        try {
          await save({ recnum, ...changes, ...pml });
          setChanges({});
        } catch (error) {
          setError(error as Error);
        }
      } else {
        setChanges({ ...changes, ...pml });
      }
    },
    [changes],
  );

  return !(materialList?.material && originalMaterialList) ? (
    !hasFetched ? (
      <>
        <Spinner></Spinner>
      </>
    ) : (
      <>Material List not found</>
    )
  ) : (
    <>
      <MaterialListDetailsEditor
        materialList={materialList}
        error={error}
        onChange={handleChange}
        onSubmit={hasChanges ? handleSubmit : undefined}
        onRemove={handleRemove}
        users={users}
        {...remainingMaterialMethods}
      />
    </>
  );
};

export default withKey('recnum', MaterialListDetailsContainer);
