import React from 'react';
import { LocationWithDetails } from '../../modules/locations';

export const LocationStockTableData: React.FC<{
  location: LocationWithDetails;
}> = ({ location }) => (
  <td
    title={location.stock
      .map(s => `${s.subset}-${s.volgnummer} ${s.description}`)
      .join('\n')}
  >
    {location.name_replaces_path
      ? location.description
      : location.stock.length === 0
      ? '-'
      : `${location.stock[0].subset}-${location.stock[0].volgnummer}${
          location.stock.length > 1 ? `, +${location.stock.length - 1}` : ''
        }`}
  </td>
);
