import * as R from 'ramda';
import * as React from 'react';
import { ListGroup, ListGroupItem } from 'react-bootstrap';

import { calculateProgress, isListClosed } from '../../common/logic/list';

import ProgressBullet from '../ProgressBullet';

import commonStl from '../commonStyles.module.scss';
import styles from './ChecklistsList.module.scss';

import { defineMessages, FormattedMessage } from 'react-intl';
import { buildListAndDependencies } from '../../common/list/buildList';
import { parseDate } from '../../common/logic/misc';
import { BuiltList, List } from '../../models/List';
import { UsersState } from '../../modules/users';
import FilterSummary from '../FilterSummary';
import FormattedRelative from '../FormattedRelative';
import LinkContainer from '../LinkContainer';
import UserToken from '../UserToken/index';

const msg = defineMessages({
  NO_LISTS: {
    id: 'CHECKLISTS_LIST.NO_LISTS',
    defaultMessage: 'Lists can not be displayed',
  },
  LISTS: {
    id: 'CHECKLISTS_LIST.LISTS',
    defaultMessage: 'lists',
  },
});

const listItemName = <FormattedMessage {...msg.LISTS} />;

const sortChecklists = R.sortWith<BuiltList>([
  R.ascend(isListClosed as any),
  R.ascend(l => (l.start_date ? parseDate(l.start_date) : Infinity)),
  R.ascend(R.prop('name')),
]);

interface Props {
  checklists: List[];
  lists: List[];
  users: UsersState;
  totalItems?: number;
  onClear?: () => void;
  onReset?: () => void;
}

const ChecklistsList: React.FC<Props> = ({
  users,
  totalItems,
  onClear,
  onReset,
  ...rest
}) => {
  const lists = React.useMemo(
    () =>
      sortChecklists(
        rest.checklists.map(l => ({
          ...l,
          ...buildListAndDependencies(l, rest.lists),
        })),
      ),
    [rest.lists, rest.checklists],
  );

  if (!lists || !users) {
    return (
      <p className={commonStl.noEntities}>
        <FormattedMessage {...msg.NO_LISTS} />
      </p>
    );
  }

  const listItems = lists.map(list => {
    return (
      <LinkContainer to={`/list/${list.id}`} key={list.id}>
        <ListGroupItem className={styles.root}>
          <div className={styles.badge}>
            <ProgressBullet
              progress={calculateProgress(list)}
              complete={!!isListClosed(list as any)}
            />
          </div>
          <div className={styles.title}>
            <div className={styles.right}>
              {list.associated_users.map(u => (
                <UserToken
                  className={styles.token}
                  small
                  user={users[u] as any}
                />
              ))}
            </div>
            {list.end_date && (
              <div className={styles.right}>
                <FormattedRelative value={list.end_date} />
              </div>
            )}
            {list.name}
          </div>
        </ListGroupItem>
      </LinkContainer>
    );
  });
  return (
    <>
      <ListGroup>{listItems}</ListGroup>
      {totalItems !== undefined && (
        <FilterSummary
          total={totalItems}
          visible={listItems.length}
          itemName={listItemName}
          onClear={onClear}
          onReset={onReset}
        ></FilterSummary>
      )}
    </>
  );
};

export default ChecklistsList;
