import React from 'react';
import Page from '../components/Page';
import { Column } from '../components/Page/Columns';
import MaterialListCreationContainer from './MaterialListCreation.Container';
import MaterialListDetailsContainer from './MaterialListDetails.Container';
import MaterialListOverviewContainer from './MaterialListOverview.Container';

interface MaterialListLayoutProps {
  params: { recnumOrNew?: string };
}
const MaterialListLayout: React.FC<MaterialListLayoutProps> = ({
  params: { recnumOrNew },
}) => {
  let columns = [
    <Column key="overview" title="Overview" uriPath="/inventory/lists">
      <MaterialListOverviewContainer />
    </Column>,
  ];
  let recnum: number;
  if (recnumOrNew) {
    if (recnumOrNew === 'new') {
      columns.push(
        <Column key="create" title="Create" uriPath={`/inventory/lists/new`}>
          <MaterialListCreationContainer />
        </Column>,
      );
    } else if ((recnum = parseInt(recnumOrNew))) {
      columns.push(
        <Column
          key="details"
          title="Details"
          uriPath={`/inventory/lists/${recnumOrNew}`}
        >
          <MaterialListDetailsContainer recnum={recnum} />
        </Column>,
      );
    }
  }
  return <Page columns={columns}></Page>;
};

export default MaterialListLayout;
