import { BuiltList } from '../../../models/List';
import { assertNever } from '../../../modules/utils';
import { ListEvent } from './ListModification';
import attachment from './operations/attachment';
import close from './operations/close';
import copy from './operations/copy';
import expandProto from './operations/expandProto';
import geotag from './operations/geotag';
import insert from './operations/insert';
import move from './operations/move';
import note from './operations/note';
import open from './operations/open';
import patch from './operations/patch';
import remove from './operations/remove';

export default function listModificationsReducer(
  list: BuiltList,
  event: ListEvent,
): BuiltList {
  switch (event.type) {
    case 'CREATE_LIST':
    case 'MODIFY_LIST':
      return list;
    case 'INSERT':
      return insert(list, event);
    case 'EXPAND_PROTO':
      return expandProto(list, event);
    case 'PATCH':
      return patch(list, event);
    case 'REMOVE':
      return remove(list, event);
    case 'MOVE':
      return move(list, event);
    case 'COPY':
      return copy(list, event);
    case 'EDIT_GEOTAG':
    case 'ADD_GEOTAG':
    case 'REMOVE_GEOTAG':
      return geotag(list, event);
    case 'ADD_ATTACHMENT':
    case 'REMOVE_ATTACHEMNT':
      return attachment(list, event);
    case 'ADD_NOTE':
    case 'EDIT_NOTE':
    case 'REMOVE_NOTE':
      return note(list, event);
    case 'CLOSE_LIST':
      return close(list, event);
    case 'OPEN_LIST':
      return open(list, event);
  }
  assertNever(event);
}
