import React from 'react';
import { useSelector } from 'react-redux';
import Types from 'Types';
import InventoryLocationDetails from '../components/InventoryLocationDetails';
import InventoryLocationPrintLabels from '../components/InventoryLocationPrintLabels';
import InventoryTree from '../components/InventoryTree';
import LocationLabels from '../components/LocationLabels';
import Page from '../components/Page';
import { Column } from '../components/Page/Columns';
import Spinner from '../components/Spinner';
import { MakeTree, makeTreeIndex } from '../components/Tree/common';
import useLocations from '../hooks/useLocations';
import { LocationWithDetails } from '../modules/locations';
import { UsersState } from '../modules/users';
import LocationCountingContainer from './LocationCounting.Container';

export interface InventoryOverviewContainerProps {
  printLabels?: boolean;
  params: {
    selectedLocationId?: string;
    countingRequestRecnum?: string;
  };
}

/** FIXME: this is both a container and a layout. clean up */
const InventoryLocationLayout: React.FC<InventoryOverviewContainerProps> = ({
  printLabels = false,
  params: { selectedLocationId, countingRequestRecnum },
}) => {
  const structure = useLocations();

  const [locations, users] = useSelector<
    Types.RootState,
    [LocationWithDetails[] | null, UsersState]
  >(({ locations, users }) => [
    locations && (Object.values(locations) as LocationWithDetails[]),
    users,
  ]);

  const isInventoryAdmin = useSelector<Types.RootState, boolean>(
    ({ user }) => !!user?.permissions?.INVENTORY_ADMIN,
  );

  const [dragDropLocked, setdragDropLocked] = React.useState<boolean>(true);

  const [printLabelsSet, setPrintLabelsSet] = React.useState<
    MakeTree<LocationWithDetails>[] | null
  >(null);

  const index = React.useMemo(() => makeTreeIndex(structure), [structure]);

  if (!locations) {
    return <Spinner />;
  }

  const selectedLocation = index[selectedLocationId!] || null;

  if (printLabelsSet) {
    return (
      <LocationLabels
        locations={printLabelsSet}
        showDialog
        onDone={() => setPrintLabelsSet(null)}
      />
    );
  }
  const columns = [
    <Column title="Inventory" uriPath="/inventory">
      <InventoryTree
        selectedLocationId={selectedLocationId}
        locations={locations}
        index={index}
        structure={structure}
        isInventoryAdmin={isInventoryAdmin}
        locked={dragDropLocked}
      />
      {isInventoryAdmin && (
        <div className="checkbox">
          <label
            className="text-sm"
            title="Wether reordering and copying elements through drag & drop is locked"
          >
            <input
              type="checkbox"
              checked={dragDropLocked}
              onChange={event => setdragDropLocked(event.target.checked)}
            />{' '}
            <small>Locked</small>
          </label>
        </div>
      )}
    </Column>,
  ];
  if (selectedLocation) {
    if (printLabels) {
      columns.push(
        <Column
          title="Print Labels"
          uriPath={`/inventory/${selectedLocationId}/print-labels`}
        >
          <InventoryLocationPrintLabels
            key={selectedLocationId}
            location={selectedLocation as any}
            onPrintLabels={setPrintLabelsSet as any}
          ></InventoryLocationPrintLabels>
        </Column>,
      );
    } else {
      columns.push(
        <Column title="Details" uriPath={`/inventory/${selectedLocationId}`}>
          <InventoryLocationDetails
            location={selectedLocation}
            isInventoryAdmin={isInventoryAdmin}
            users={users}
          ></InventoryLocationDetails>
        </Column>,
      );
      let recnum: number = 0;
      if (countingRequestRecnum && (recnum = parseInt(countingRequestRecnum))) {
        columns.push(
          <Column
            title="Counting"
            uriPath={`/inventory/${selectedLocationId}/counting-request/${countingRequestRecnum}`}
          >
            <LocationCountingContainer
              recnum={recnum}
              parentLocationId={selectedLocation.id}
            />
          </Column>,
        );
      }
    }
  }
  return <Page fluid title="Magazijn" columns={columns} />;
};

export default InventoryLocationLayout;
