import PropTypes from 'prop-types';
import * as React from 'react';
import { connect } from 'react-redux';

import { Button } from 'react-bootstrap';

import classNames from 'classnames';
import { last } from 'ramda';
import { FormattedMessage } from 'react-intl';
import { Link } from 'wouter';
import {
  calculateProgress,
  isListClosed,
  traverse,
} from '../../../common/logic/list';
import FormattedRelative from '../../FormattedRelative/index';
import LinkContainer from '../../LinkContainer';
import { IsProjectManager } from '../../Permissions';
import ProgressBullet from '../../ProgressBullet';
import UserToken from '../../UserToken';
import styles from './ListDetailsDetails.module.scss';

const CHECKPOINT_FIELD = 'CHECKPOINT_FIELD';

export class ListDetailsDetails extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  render() {
    const {
      list,
      builtList,
      ui,
      users,
      onEditDetails,
      onDeleteList,
      user,
    } = this.props;

    const { showListDetails: open } = ui;
    const creator = users[list.creator_user_id];

    const cps = Object.values(builtList.index).filter(
      n => n.type === CHECKPOINT_FIELD,
    );
    const total = cps.length;
    const complete = cps.filter(f => f.value !== null && f.value !== undefined)
      .length;

    const firstIncomplete = traverse(builtList).find(
      f =>
        f.type === CHECKPOINT_FIELD &&
        (f.value === null || f.value === undefined),
    );
    let firstIncompleteUrl;
    if (firstIncomplete) {
      firstIncompleteUrl = './' + firstIncomplete.id;
    }
    return (
      <div className={classNames(styles.root)}>
        <div className={'container'}>
          <div className={classNames(styles.detailsPanel, open && styles.open)}>
            <IsProjectManager user={user}>
              <Button
                className="pull-right "
                bsStyle="link"
                onClick={onEditDetails}
              >
                <i className="fa fa-pencil" />{' '}
                <FormattedMessage
                  id="LIST_DETAILS.EDIT"
                  defaultMessage="Edit list"
                  description="Label of edit button"
                />
              </Button>
              <LinkContainer to={`/template/${list.id}`}>
                <Button className="pull-right hidden-xs" bsStyle="link">
                  <i className="fa fa-pencil" />{' '}
                  <FormattedMessage
                    id="LIST_DETAILS.EDIT_CONTENTS"
                    defaultMessage="Edit questions"
                    description="Label of edit contents button"
                  />
                </Button>
              </LinkContainer>
              <Button
                className="pull-right  danger"
                bsStyle="link"
                onClick={onDeleteList}
              >
                <i className="fa fa-trash" />{' '}
                <FormattedMessage
                  id="LIST_DETAILS.DELETE"
                  defaultMessage="Delete checklist"
                  description="Label of delete button"
                />
              </Button>
            </IsProjectManager>
            <dl className="dl-horizontal">
              <dt>
                <FormattedMessage
                  id="START_DATE"
                  defaultMessage="Start date"
                  description=""
                />
              </dt>
              <dd>
                {(list.start_date && (
                  <FormattedRelative value={list.start_date} />
                )) || <em>onbekend</em>}
              </dd>
              <dt>
                <FormattedMessage
                  id="END_DATE"
                  defaultMessage="End date"
                  description=""
                />
              </dt>
              <dd>
                {(list.end_date && (
                  <FormattedRelative value={list.end_date} />
                )) || <em>onbekend</em>}
              </dd>
              <dt>
                <FormattedMessage
                  id="CREATED_BY"
                  defaultMessage="Created by"
                  description=""
                />
              </dt>
              <dd>
                <UserToken small withText user={creator} />
              </dd>
              <dt>
                <FormattedMessage
                  id="ASSOCIATED_USERS"
                  defaultMessage="Associated users"
                />
              </dt>
              {list.associated_users.length ? (
                list.associated_users.map(u => (
                  <dd>
                    <UserToken small withText user={users[u]} />
                  </dd>
                ))
              ) : (
                <dd>
                  <em>
                    <FormattedMessage
                      id="LIST_DETAILS.NONE"
                      defaultMessage="None"
                    />
                  </em>
                </dd>
              )}
              <dt>
                <FormattedMessage
                  id="LAST_EDITED"
                  defaultMessage="Last edited"
                  description=""
                />
              </dt>
              <dd>
                <FormattedRelative value={last(list.events).created} />
              </dd>
              <dt>
                <FormattedMessage
                  id="PROGRESS"
                  defaultMessage="Progress"
                  description=""
                />
              </dt>
              <dd className={styles.progressBullet}>
                <ProgressBullet
                  size={15}
                  className={styles.progressBullet}
                  progress={calculateProgress(builtList)}
                />
                {firstIncompleteUrl ? (
                  <Link
                    className={styles.completionUrl}
                    to={firstIncompleteUrl}
                  >
                    {complete}/{total}{' '}
                    <FormattedMessage
                      id="QUESTIONS"
                      defaultMessage="questions"
                      description=""
                    />{' '}
                    <i className="fa fa-arrow-circle-o-right" />
                  </Link>
                ) : (
                  <>
                    {complete}/{total}{' '}
                    <FormattedMessage
                      id="QUESTIONS"
                      defaultMessage="questions"
                      description=""
                    />{' '}
                  </>
                )}
              </dd>
              <dt>
                <FormattedMessage
                  id="STATUS"
                  defaultMessage="Status"
                  description=""
                />
              </dt>
              <dd>
                {isListClosed(list) ? (
                  <FormattedMessage
                    id="CLOSED"
                    defaultMessage="Closed"
                    description=""
                  />
                ) : (
                  <FormattedMessage
                    id="OPENED"
                    defaultMessage="Open"
                    description=""
                  />
                )}
              </dd>
            </dl>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(({ ui, user }) => ({ ui, user }))(ListDetailsDetails);
