import { BrowserHistory } from 'history';
import * as React from 'react';
const LockContext = React.createContext<{ lock: number; message: string }>({
  lock: 0,
  message: '',
});

export const useRouteConfirmation = (lock: boolean, message: string) => {
  const ctx = React.useContext(LockContext);

  React.useEffect(() => {
    if (lock) {
      ctx.lock++;
      return () => {
        ctx.lock--;
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lock]);

  React.useEffect(() => {
    ctx.message = message;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);
};

// the function provided by redux-first-history is lacking
// see: https://github.com/salvoravida/redux-first-history/blob/master/wouter/index.js
export const createWouterHook = (history: BrowserHistory) => {
  return () => {
    const lockContext = React.useContext(LockContext);
    const navigate = (to: string, options: any) => {
      let performNavigation = true;
      if (lockContext.lock) {
        performNavigation = window.confirm(
          lockContext.message || 'Are you sure?',
        );
      }
      if (performNavigation) {
        history[options?.replace ? 'replace' : 'push'](to);
      }
    };

    const [path, update] = React.useState(history.location.pathname);

    React.useEffect(
      () =>
        history.listen(l => {
          return update(l.location ? l.location.pathname : (l as any).pathname);
        }),
      [],
    );
    return [path, navigate] as any;
  };
};
