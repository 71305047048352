import { Location } from '@certhon/domain-models';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { useLocation } from 'wouter';
import { replaceNumberingPlaceholder } from '../../common/location/replaceNumberingPlaceholder';
import { useDispatchWithErrorNotifictions } from '../../hooks/useDispatchWithErrorNotifictions';
import {
  deleteLocation,
  LocationWithDetails,
  saveLocation,
  saveLocations,
} from '../../modules/locations';
import delayed from '../../utils/delayed';
import { useContextMenu } from '../ContextMenu';
import Icon from '../Icon';
import Spinner from '../Spinner';
import TreeTable from '../Tree';
import { MakeTree, makeTreeIndex } from '../Tree/common';
import { useTreeTableDraggableInterface } from '../Tree/Draggable';
import { Element, TreeElementProps } from '../Tree/TreeElement';
import { LocationStockTableData } from './LocationStockTableData';

export function regenerateIds(location: MakeTree<Location>): Location[] {
  const id = uuid();
  const newLocatation = { ...location, id };
  delete (newLocatation as any).children;
  return [
    newLocatation,
    ...location.children
      .map(l => regenerateIds({ ...l, parent_location_id: id }))
      .flat(),
  ];
}

export function hasParentWithId<T extends MakeTree<{ id: any }>>(
  elem: T,
  id: any,
) {
  let parent = elem.parent;
  while (parent) {
    if (parent.id === id) {
      return true;
    }
    parent = parent.parent;
  }
  return false;
}

interface InventoryTreeItemProps {
  props: TreeElementProps;
  item: MakeTree<LocationWithDetails>;
  onOpenId: (id: any) => void;
  onDuplicate: (id: string) => void;
  isInventoryAdmin: boolean;
}
const InventoryTreeItem: React.FC<InventoryTreeItemProps> = ({
  props,
  item,
  onOpenId,
  onDuplicate,
  isInventoryAdmin,
}) => {
  const dispatch = useDispatch();
  const [, navigate] = useLocation();
  const { node, onContextMenuHandler } = useContextMenu([
    {
      type: 'header',
      label: replaceNumberingPlaceholder(item.name, item.ordering),
    },
    { type: 'divider' },
    {
      type: 'action',
      action: () => {
        props.onToggleOpen(item.id, true);
      },
      label: 'Expand/collapse all locations',
    },
    { type: 'divider' },
    {
      type: 'action',
      action: () => navigate(`/inventory/${item.id}/print-labels`), // fixme: centralize urls
      label: 'Print labels',
      disabled: !isInventoryAdmin,
    },
    {
      type: 'action',
      action: () => {
        onOpenId(item.id);
        dispatch(
          saveLocation({
            name: 'Child-' + Math.round(Math.random() * 1000),
            parent_location_id: item.id,
          }),
        );
      },
      disabled: !isInventoryAdmin,
      label: 'Add sub-location',
    },
    {
      type: 'action',
      action: () => onDuplicate(item.id),
      disabled: !isInventoryAdmin,
      label: 'Dupliceren',
    },
    {
      type: 'action',
      disabled: !isInventoryAdmin,
      action: async () => {
        const children = Object.values(makeTreeIndex([item]));
        const numStock = ((children as any[]) as LocationWithDetails[]).reduce(
          (num, loc) => num + loc.stock?.length || 0,
          0,
        );
        if (
          children.length + numStock > 1 &&
          // eslint-disable-next-line no-restricted-globals
          !confirm(
            `Wil je in totaal ${children.length} locaties en ${numStock} voorraadartikelen verwijderen?`,
          )
        ) {
          return;
        }
        const changes = item.parent?.children
          .filter(i => i.id !== item.id)
          .map((i, ordering) => ({ id: i.id, ordering }));
        if (changes) {
          await dispatch(saveLocations(changes));
        }
        await dispatch(deleteLocation({ id: item.id }));
      },
      className: 'danger',
      label: 'Delete',
    },
  ]);

  return (
    <>
      <Element
        href={`/inventory/${item.id}`}
        additionalColumns={
          <>
            <td>{replaceNumberingPlaceholder(item.code, item.ordering)}</td>
            <LocationStockTableData location={item} />
          </>
        }
        onContextMenu={onContextMenuHandler}
        icons={!item.children?.length && <Icon name={'box'} />}
        {...props}
        name={replaceNumberingPlaceholder(item.name, item.ordering)}
      />
      {node}
    </>
  );
};

interface InventoryTreeProps {
  /**?
   * flat list of locations
   */
  locations: LocationWithDetails[];
  index: Record<string, MakeTree<LocationWithDetails>>;
  structure: MakeTree<LocationWithDetails>[];
  selectedLocationId?: string | null;
  isInventoryAdmin: boolean;
  locked: boolean;
}
/**
 * Main visualisation of inventory
 */
const InventoryTree: React.FC<InventoryTreeProps> = ({
  locations,
  structure,
  index,
  isInventoryAdmin,
  selectedLocationId,
  locked,
}) => {
  const dispatch = useDispatchWithErrorNotifictions();

  const [expandedIds, setExpandedIds] = React.useState<any[]>(() => {
    if (selectedLocationId) {
      const items = [];
      let nextItem: string | null = selectedLocationId;
      while (nextItem) {
        nextItem =
          // eslint-disable-next-line no-loop-func
          locations.find(l => l.id === nextItem)?.parent_location_id || null;
        items.push(nextItem);
      }
      return items;
    }
    return [];
  });
  const expandId = React.useCallback(
    (id: any) => {
      if (!expandedIds.includes(id)) {
        setExpandedIds([...expandedIds, id]);
      }
    },
    [expandedIds, setExpandedIds],
  );

  const [loading, setLoading] = React.useState<{ num: number } | null>(null);

  const draggableInterface = useTreeTableDraggableInterface<
    MakeTree<LocationWithDetails>
  >(
    {
      canDropItem(draggedItem, targetItem, operation) {
        if (
          operation === 'move' &&
          (draggedItem.id === targetItem.id ||
            hasParentWithId(targetItem, draggedItem.id))
        ) {
          return [];
        }
        return ['above', 'below', 'inside'];
      },
      async onDropItem(draggedItem, targetItem, location, operation) {
        let subject: Location = draggedItem;
        let saves: Location[] = [];

        if (targetItem.stock.length && location === 'inside') {
          alert(
            `Can't ${operation} a location into a location with 'artikels'. Only leaf locations can have 'artikels'.`,
          );
          return;
        }

        // 3. on 'copy' regenerate ids
        if (operation === 'copy') {
          [subject, ...saves] = regenerateIds(draggedItem);
        }

        let oldSiblings: Location[] = [];
        if (operation === 'move') {
          if (
            (location === 'inside' &&
              targetItem.id !== subject.parent_location_id) ||
            (location !== 'inside' &&
              targetItem.parent_location_id !== subject.parent_location_id)
          ) {
            // subject is moved out of parent, reorder the parents children
            oldSiblings =
              draggedItem.parent?.children.filter(l => l.id !== subject.id) ||
              [];
          }
        }

        // 1. move the subject to its new position
        let siblings: Location[] = [];
        if (location === 'inside') {
          subject.parent_location_id = targetItem.id;
          siblings = [
            ...targetItem.children.filter(i => i.id !== subject.id), // in case of move remove original, NOTE that in case of copy subject allready has a new id
            subject,
          ];
        } else {
          subject.parent_location_id = targetItem.parent_location_id;
          let parent = targetItem.parent;
          // 2. renumber any siblings
          if (parent) {
            siblings = parent.children.filter(i => i.id !== subject.id); // in case of move remove original, NOTE that in case of copy subject allready has a new id
          } else {
            siblings = structure.filter(i => i.id !== subject.id); // in case of move remove original, NOTE that in case of copy subject allready has a new id;
          }
          // note splice is not a copying operation
          siblings.splice(
            siblings.indexOf(targetItem) + (location === 'above' ? 0 : 1),
            0,
            subject as any,
          );
        }
        saves = [
          ...siblings.map((l, ordering) => ({ ...l, ordering })),
          ...oldSiblings.map((l, ordering) => ({ ...l, ordering })),
          ...saves,
        ];

        // 4. push changes to server
        const task = dispatch(saveLocations(saves));

        // 5. show load screen if the task takes long
        const showLoadScreen = await Promise.race([
          task.then(() => false),
          delayed(true, 500),
        ]);
        if (showLoadScreen) {
          setLoading({ num: saves.length });
          await Promise.all([task, delayed(true, 400)]);
          setLoading(null);
        }
      },
    },
    [],
  );

  // FIXME: dont misuse draggableinterface
  const handleDuplicate = React.useCallback(
    (itemId: string) => {
      const draggedItem = index[itemId];

      draggableInterface.onDropItem(draggedItem, draggedItem, 'below', 'copy');
    },
    [draggableInterface, index],
  );

  const { node, onContextMenuHandler } = useContextMenu([
    {
      type: 'action',
      label: 'Nieuwe root-opslaglocatie',
      disabled: !isInventoryAdmin,
      action: () =>
        dispatch(
          saveLocation({
            name: 'Child-' + Math.round(Math.random() * 1000),
          }),
        ),
    },
  ]);

  return (
    <>
      <TreeTable<MakeTree<LocationWithDetails>>
        onContextMenu={onContextMenuHandler}
        thead={
          <tr>
            <th>Locatie</th>
            <th>code</th>
            <th>Voorraad</th>
            {/* <th></th> */}
            {node}
          </tr>
        }
        elements={structure}
        index={index}
        renderRow={(props, item) => (
          <InventoryTreeItem
            onOpenId={expandId}
            props={{ ...props, selected: item.id === selectedLocationId }}
            item={item}
            onDuplicate={handleDuplicate}
            isInventoryAdmin={isInventoryAdmin}
          />
        )}
        expandedElementIds={expandedIds}
        onExpandedElementIdsChanged={setExpandedIds}
        draggableInterface={
          isInventoryAdmin && !locked ? draggableInterface : undefined
        }
      ></TreeTable>
      {loading && (
        <Modal bsSize="sm" size={22} show={true} onHide={() => {}}>
          <Modal.Header>Saving {loading.num} locations</Modal.Header>
          <Modal.Body>
            <Spinner></Spinner>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default InventoryTree;
