import * as React from 'react';
import { Breadcrumb } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Page from '../Page';
import ChecklistsList from '../ChecklistsList';
import { heuristicChronicalGrouping } from '../../utils/heuristicalChronologicalGrouping';
import { parseDate } from '../../common/logic/misc';
import { prop, pipe, reverse } from 'ramda';
import { FormattedMessage } from 'react-intl';
import LinkContainer from '../LinkContainer';

export default class MyTasksComponent extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  render() {
    const { checklists, users, lists } = this.props;
    const groups = heuristicChronicalGrouping(checklists, {
      getTimestamp: pipe(prop('end_date'), parseDate),
    });
    return (
      <Page>
        <Breadcrumb>
          <LinkContainer to="/dashboard">
            <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
          </LinkContainer>
          <Breadcrumb.Item active>
            <FormattedMessage
              id="TASKS"
              defaultMessage="Tasks"
              description=""
            />
          </Breadcrumb.Item>
        </Breadcrumb>

        {/* tasks */}
        <h3>
          <span>
            <FormattedMessage
              id="TASKS"
              defaultMessage="Tasks"
              description=""
            />
          </span>
        </h3>
        {reverse(groups).map(g => (
          <React.Fragment>
            <h5>
              <FormattedMessage {...g.desc} />
            </h5>
            <ChecklistsList
              checklists={g.elements}
              lists={lists}
              users={users}
            />
          </React.Fragment>
        ))}

        <p style={{ marginTop: 50 }}>
          <em>
            <FormattedMessage
              id="MY_TASKS.NO_DATE_WARNING"
              defaultMessage="WARNING! Tasks without end date will not be shown on this page"
              description=""
            />
          </em>
        </p>
      </Page>
    );
  }
}
