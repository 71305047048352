import Types from 'Types';
import { values } from 'ramda';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import MyFeedbackPage from '../../components/MyFeedback';
import { FeedbackState, fetchFeedbackFromUser } from '../../modules/feedback';
import { UserState } from '../../modules/user';
import { UsersState } from '../../modules/users';
import useInitialDispatch from '../../utils/useInitalDispatch';

export interface MyFeedbackContainerProps {
  user: UserState;
  feedback: FeedbackState;
  lists: any;
  users: UsersState;
  dispatch: Dispatch;
}

const MyFeedbackContainer: React.FC<MyFeedbackContainerProps> = props => {
  const { users, user, dispatch, lists } = props;

  useInitialDispatch(() => fetchFeedbackFromUser(user!.recnum));

  if (!user) {
    return null;
  }

  const feedback = props.feedback
    ? values(props.feedback).filter(f => f.user_id === user.recnum)
    : null;
  return (
    <MyFeedbackPage
      lists={lists}
      users={users}
      feedback={feedback}
      dispatch={dispatch}
    />
  );
};

function mapStateToProps(state: Types.RootState) {
  return {
    users: state.users,
    user: state.user,
    feedback: state.feedback,
    lists: state.lists,
  };
}
export default connect(mapStateToProps)(MyFeedbackContainer);
