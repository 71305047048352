/**
 * This module manages the user profile of the authenticated (current) user
 */

import { decode, PermissionsObject } from '@certhon/cloud-permissions/lib';
import { PartialUserSettings } from '@certhon/user-settings/lib';
import * as Sentry from '@sentry/react';
import reduce from 'reduce-reducers';
import { AnyAction } from 'redux';
import { CALL_API } from 'redux-api-middleware';
import Department from '../models/Department';
import { User } from '../models/User';
import { resolveHeaders, resolveUrl } from '../utils';
import Types from 'Types';
import { useSelector } from 'react-redux';

interface BaseCurrentUser extends User {
  internal: boolean;
  department: Department | null;
  accessToken: string;
  email: string;
  settings: PartialUserSettings;
}
export interface CurrentUser extends Omit<BaseCurrentUser, 'permissions'> {
  permissions: PermissionsObject;
}
export interface StoreUser extends CurrentUser {
  invalidAccessToken: boolean;
}
export interface EncodedCurrentUser extends BaseCurrentUser {
  permissions: number;
}

export interface RequestSignInAction {
  type: 'REQUEST_AUTHENTICATE';
}
export interface SuccessSignInAction {
  type: 'SUCCESS_AUTHENTICATE';
  payload: EncodedCurrentUser;
}
export interface FailureSignInAction {
  type: 'FAILURE_AUTHENTICATE';
  payload: Response;
  error: true;
}
// type SignInRSAA = RSAAction<
//   RequestSignInAction,
//   SuccessSignInAction,
//   FailureSignInAction
// > &
//   AnyAction;

export function signIn(username: string, password: string) {
  return {
    [CALL_API]: {
      endpoint: resolveUrl('/authenticate'),
      body: JSON.stringify({ username, password }),
      method: 'POST',
      headers: resolveHeaders(),
      credentials: 'same-origin',
      types: [
        'REQUEST_AUTHENTICATE',
        'SUCCESS_AUTHENTICATE',
        'FAILURE_AUTHENTICATE',
      ],
    },
  };
}

export interface RequestFetchUserAction {
  type: 'REQUEST_FETCH_USER';
}
export interface SuccessFetchUserAction {
  type: 'SUCCESS_FETCH_USER';
  payload: EncodedCurrentUser;
}
export interface FailureFetchUserAction {
  type: 'FAILURE_FETCH_USER';
  error: true;
  payload: Response;
}

export function fetchUser(token?: string, noAuthorization = false): any {
  let headers = {};
  if (token) {
    headers = { Authorization: `Bearer ${token}` };
  }
  if (noAuthorization) {
    headers = { Authorization: null };
  }
  return {
    [CALL_API]: {
      endpoint: resolveUrl('/me'),
      method: 'GET',
      headers: resolveHeaders(headers),
      credentials: 'same-origin',
      types: ['REQUEST_FETCH_USER', 'SUCCESS_FETCH_USER', 'FAILURE_FETCH_USER'],
    },
  };
}

export interface RequestUpdateUserAction {
  type: 'REQUEST_UPDATE_USER';
}
export interface SuccessUpdateUserAction {
  type: 'SUCCESS_UPDATE_USER';
  payload: EncodedCurrentUser;
}
export interface FailureUpdateUserAction {
  type: 'FAILURE_UPDATE_USER';
  error: true;
}

export interface CurrentUserUpdateFields extends CurrentUser {
  email: string;
  actionUrl: string;
  password: string;
}

export type CurrentUserUpdate = Partial<CurrentUserUpdateFields>;

export function updateUser(userUpdate: CurrentUserUpdate): any {
  return {
    [CALL_API]: {
      endpoint: resolveUrl('/me'),
      method: 'PATCH',
      body: JSON.stringify(userUpdate),
      headers: resolveHeaders(),
      types: [
        'REQUEST_UPDATE_USER',
        'SUCCESS_UPDATE_USER',
        'FAILURE_UPDATE_USER',
      ],
    },
  };
}

export interface RequestActivateUserAction {
  type: 'REQUEST_ACTIVATE_USER';
}
export interface SuccessActivateUserAction {
  type: 'SUCCESS_ACTIVATE_USER';
  payload: EncodedCurrentUser;
}
export interface FailureActivateUserAction {
  type: 'FAILURE_ACTIVATE_USER';
  error: true;
}

export type ActivateUserData = {
  token: string;
  loginname: string;
  password: string;
};

export function activateUser(data: ActivateUserData) {
  return {
    [CALL_API]: {
      endpoint: resolveUrl('/activate'),
      body: JSON.stringify(data),
      method: 'POST',
      headers: resolveHeaders(),
      types: [
        'REQUEST_ACTIVATE_USER',
        'SUCCESS_ACTIVATE_USER',
        'FAILURE_ACTIVATE_USER',
      ],
    },
  };
}

export interface RequestRecoverCredentials {
  type: 'REQUEST_RECOVER_CREDENTIALS';
}
export interface SuccessRecoverCredentials {
  type: 'SUCCESS_RECOVER_CREDENTIALS';
}
export interface FailureRecoverCredentials {
  type: 'FAILURE_RECOVER_CREDENTIALS';
}

export function recover(identifier: string) {
  // build action url
  const url = new URL(window.location.href);
  url.pathname = '/recover';
  const actionUrl = url.toString();

  return {
    [CALL_API]: {
      endpoint: resolveUrl('/request-recover-password'),
      body: JSON.stringify({ identifier, actionUrl }),
      method: 'POST',
      headers: resolveHeaders(),
      types: [
        'REQUEST_RECOVER_CREDENTIALS',
        'SUCCESS_RECOVER_CREDENTIALS',
        'FAILURE_RECOVER_CREDENTIALS',
      ],
    },
  };
}

export interface RequestRecoverPassword {
  type: 'REQUEST_RECOVER_PASSWORD';
}
export interface SuccessRecoverPassword {
  type: 'SUCCESS_RECOVER_PASSWORD';
}
export interface FailureRecoverPassword {
  type: 'FAILURE_RECOVER_PASSWORD';
  error: true;
}

export interface PasswordRecoveryData {
  token: string;
  password: string;
}

export function recoverPassword(data: PasswordRecoveryData) {
  return {
    [CALL_API]: {
      endpoint: resolveUrl('/recover-password'),
      body: JSON.stringify(data),
      method: 'POST',
      headers: resolveHeaders(),
      types: [
        'REQUEST_RECOVER_PASSWORD',
        'SUCCESS_RECOVER_PASSWORD',
        'FAILURE_RECOVER_PASSWORD',
      ],
    },
  };
}

// export const REQUEST_ACTIVATE_USER = 'REQUEST_ACTIVATE_USER';
// export const SUCCESS_ACTIVATE_USER = 'SUCCESS_ACTIVATE_USER';
// export const FAILURE_ACTIVATE_USER = 'FAILURE_ACTIVATE_USER';
// export function activateUser(data) {
//   return {
//     [CALL_API]: {
//       endpoint: resolveUrl('/api/activate'),
//       body: JSON.stringify(data),
//       method: 'POST',
//       headers: resolveHeaders(),
//       types: [
//         REQUEST_ACTIVATE_USER,
//         SUCCESS_ACTIVATE_USER,
//         FAILURE_ACTIVATE_USER
//       ]
//     }
//   };
// }

export interface SignOutAction {
  type: 'SIGN_OUT';
}
export const signOut = (): SignOutAction => ({ type: 'SIGN_OUT' });

const decodeUserPermissions = (user: EncodedCurrentUser): StoreUser => ({
  ...user,
  invalidAccessToken: false,
  permissions: decode(user.permissions),
});

export type UserState = StoreUser | null;

export type UserActions =
  | AnyAction
  | FailureActivateUserAction
  | FailureFetchUserAction
  | FailureRecoverPassword
  | FailureSignInAction
  | FailureSignInAction
  | FailureUpdateUserAction
  | RequestActivateUserAction
  | RequestFetchUserAction
  | RequestRecoverPassword
  | RequestUpdateUserAction
  | SignOutAction
  | SuccessActivateUserAction
  | SuccessFetchUserAction
  | SuccessRecoverPassword
  | SuccessSignInAction
  | SuccessUpdateUserAction;

/**
 * A reducing function that changes the `invalidAccessToken` field to true when
 * it determines the accessToken is invalid based on a `fuzzy` search in api-call actions
 */
function invalidateAccessTokenReducer(
  state: UserState,
  action: UserActions,
): UserState {
  const a = action as AnyAction;
  if (
    state &&
    /FAILURE_.*/.test(a.type) &&
    a.payload &&
    a.payload.name === 'ApiError' &&
    a.payload.status === 401 &&
    a.type !== 'FAILURE_AUTHENTICATE' &&
    a.payload.response &&
    a.payload.response.error === 'Invalid token'
  ) {
    return { ...state, invalidAccessToken: true };
  }
  return state;
}

/**
 * Main user state reducer
 */
function userStateReducer(state: UserState = null, action: UserActions) {
  if (
    action.type === 'SUCCESS_AUTHENTICATE' ||
    action.type === 'SUCCESS_UPDATE_USER' ||
    action.type === 'SUCCESS_FETCH_USER' ||
    action.type === 'SUCCESS_ACTIVATE_USER'
  ) {
    const user = decodeUserPermissions((action as AnyAction).payload);
    if (state && !user.accessToken) {
      // UPDATE doesnt return a new token so preserve the existing one
      user.accessToken = state.accessToken;
    }
    Sentry.setUser({
      id: user.recnum,
    });
    return user;
  } else if (
    action.type === 'SIGN_OUT' ||
    action.type === 'FAILURE_AUTHENTICATE'
  ) {
    Sentry.setUser(null);

    return null;
  } else if (
    action.type === 'FAILURE_FETCH_USER' ||
    action.type === 'FAILURE_RECOVER_PASSWORD' ||
    action.type === 'FAILURE_UPDATE_USER' ||
    action.type === 'REQUEST_FETCH_USER' ||
    action.type === 'REQUEST_RECOVER_PASSWORD' ||
    action.type === 'REQUEST_UPDATE_USER' ||
    action.type === 'SUCCESS_RECOVER_PASSWORD'
  ) {
    return state;
  }
  // softAssertNever(action);
  return state;
}

const reducer = reduce(invalidateAccessTokenReducer, userStateReducer);

export default reducer;

export function useCurrentUser() {
  const user = useSelector<Types.RootState, UserState>(s => s.user);
  if (!user) {
    throw new Error('useUser called in unauthenticated context');
  }
  return user;
}
