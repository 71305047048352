import {
  createModificationCreator,
  ListEventReducer,
} from '../ListModification';

export const openList = createModificationCreator<{}, 'OPEN_LIST'>('OPEN_LIST');

export type OpenListModification = ReturnType<typeof openList>;

const open: ListEventReducer<OpenListModification> = (
  list,
  mod: OpenListModification,
) => {
  return list;
};

export default open;
