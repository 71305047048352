import * as React from 'react';
import { Link } from 'wouter';
import { Documentation } from '../../documentation';

import stl from './TableOfContents.module.scss';

interface Props {
  documentation: Documentation[];
  activeDoc: string;
  internal: boolean;
  lvl?: number;
}

const TableOfContents: React.FC<Props> = ({
  documentation,
  activeDoc,
  internal,
}) => {
  // external users cannot see interal documentation
  const chapters = (internal
    ? documentation
    : documentation.filter(d => !d.internal)
  ).map(doc => {
    const root = doc.toc.find(t => t.lvl === 1);

    return doc.path === activeDoc ? (
      <li key={doc.path} className={stl.active}>
        <span>
          {root?.content}{' '}
          {doc.internal && (
            <i title="internal documentation" className="fa fa-eye-slash"></i>
          )}
        </span>
        <ul>
          {doc.toc
            .filter(t => t.lvl !== 1)
            .map(t => (
              <li key={t.slug}>
                <Link to={`/docs${doc.path}#${t.slug}`}>{t.content} </Link>
              </li>
            ))}
        </ul>
      </li>
    ) : (
      <li key={doc.path}>
        <Link to={`/docs${doc.path}#${root?.slug}`}>
          {root?.content}{' '}
          {doc.internal && (
            <i title="internal documentation" className="fa fa-eye-slash"></i>
          )}
        </Link>
      </li>
    );
  });
  return <ul className={stl.root}>{chapters}</ul>;
};

export default TableOfContents;
