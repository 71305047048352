import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'wouter';
import { buildListAndDependencies } from '../../common/list/buildList';
import { fetchLists } from '../../modules/lists';
import { fetchUsers } from '../../modules/users';
import { fetchWorks } from '../../modules/works';
import withReportReady from '../../utils/ReportReady/withReportReady';
import ignoreRejection from '../../utils/ignoreRejection';
import List, { LISTMODE_REPORT } from '../List';
import Paper from '../Paper';
import Spinner from '../Spinner';

export class ListDetails extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    lists: PropTypes.object,
    editor: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    props.register();
    this.state = {
      showExpandProtoDialog: false,
      expandProtoTargetFieldId: null,
      hasShownPrintDialog: false,
    };
  }
  componentDidUpdate(prevProps, prevState) {
    const {
      lists,
      works,
      attachments,
      users,
      params: { listId },
    } = this.props;

    let list;
    let work;

    if (works && lists && lists[listId] && users && attachments) {
      list = lists[listId];
      work = works[list.werk_id];
    }
    if (work && list && this.props.ready && !this.called) {
      this.called = true;
      this.props.ready();
    }
  }

  componentWillMount() {
    const { dispatch } = this.props;
    this.fetchList();
    ignoreRejection(dispatch(fetchWorks()), dispatch(fetchUsers()));
  }

  fetchList() {
    const {
      dispatch,
      params: { listId },
    } = this.props;

    ignoreRejection(dispatch(fetchLists({ id: listId })));
  }

  render() {
    const {
      lists,
      works,
      attachments,
      users,
      dispatch,
      params: { listId },
    } = this.props;

    let list;
    let builtList;
    let content;
    let work;

    if (works && lists && lists[listId] && users && attachments) {
      list = lists[listId];
      work = works[list.werk_id];
      builtList = buildListAndDependencies(list, lists);
    }

    if (list && work) {
      content = (
        <List
          title={false}
          list={list}
          work={work}
          mode={LISTMODE_REPORT}
          tree={builtList.tree}
          users={users}
          attachments={attachments}
          dispatch={dispatch}
        />
      );
    } else if (lists && works && !list) {
      content = (
        <center>
          <p>
            De controlelijst kan niet gevonden worden;{' '}
            <Link to="/projects">ga terug naar projecten</Link>{' '}
          </p>
        </center>
      );
    } else {
      content = <Spinner />;
    }

    return (
      <Paper title="List report" downloadLink={`/api/lists/${listId}/report`}>
        {content}
      </Paper>
    );
  }
}

const ListDetailsWithReportReady = withReportReady(ListDetails);

export default connect(x => x)(ListDetailsWithReportReady);
