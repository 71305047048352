import localForage from 'localforage';
import { combineReducers, Reducer } from 'redux';
import { PersistConfig, persistReducer } from 'redux-persist';
import { List } from '../models/List';
import { routerReducer } from '../store/routerContext';
import attachments from './attachments';
import catalogs, { actions as catalogsActions } from './catalogs';
import catalogUI from './catalogUI';
import editor from './editor';
import feedback from './feedback';
import fileDownloads from './fileDownloads';
import files, { actions as filesActions } from './files';
import filters from './filters';
import labels from './labels';
import listsRed from './lists';
import locations from './locations';
import meetings from './meetings';
import online from './online';
import projects, { actions as projectActions } from './projects';
import sorting from './sorting';
import syncStatus from './syncStatus';
import tickets from './tickets';
import ui from './ui';
import user, { UserState } from './user';
import users from './users';
import works from './works';

(window as any).actions = {
  ...catalogsActions,
  ...filesActions,
  ...projectActions,
};

type ListsState = { [recnum: string]: List } | null;
const lists: Reducer<ListsState> = listsRed as any;

const listsPersistConfig: PersistConfig<any, any, any, any> = {
  key: 'lists',
  throttle: 0,
  storage: localForage,
};
const ticketsPersistConfig: PersistConfig<any, any, any, any> = {
  key: 'tickets',
  throttle: 0,
  storage: localForage,
};
const meetingsPersistConfig: PersistConfig<any, any, any, any> = {
  key: 'minutes',
  throttle: 0,
  storage: localForage,
};

const createRootReducer = () =>
  combineReducers({
    attachments,
    editor,
    feedback,
    filters,
    lists: persistReducer(listsPersistConfig, lists),
    online,
    projects,
    router: routerReducer,
    tickets: persistReducer(ticketsPersistConfig, tickets) as Reducer<any>,
    user: user as Reducer<UserState>,
    users,
    works,
    ui,
    syncStatus,
    minutes: persistReducer(meetingsPersistConfig, meetings),
    fileDownloads,
    files,
    catalogs,
    catalogUI,
    sorting,
    labels,
    locations,
  });

export default createRootReducer;
