import { Store } from 'redux';
import { TimeoutError } from 'rxjs';

export default function awaitStoreCondition<State, Selection>(
  store: Store<State>,
  selector: (state: State) => Selection,
  condition: (selection: Selection) => boolean,
  timeout: number = 10e3, // this is to keep it from sticking around indefinitely
): Promise<Selection> {
  return new Promise((res, rej) => {
    let timeoutRef: number;
    const unsubscribe = store.subscribe(() => {
      const sel = selector(store.getState());
      if (condition(sel)) {
        unsubscribe();
        clearTimeout(timeoutRef);
        res(sel);
      }
    });
    timeoutRef = window.setTimeout(() => {
      unsubscribe();
      rej(new TimeoutError());
    }, timeout);
  });
}
