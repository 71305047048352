import { prop, sortBy } from 'ramda';
import * as React from 'react';
import { Button, ListGroup, ListGroupItem } from 'react-bootstrap';
import { Dispatch } from 'redux';
import groupByClient from '../../common/groupByClient';
import { Project } from '../../models/Project';
import { updateProject } from '../../modules/projects';
import { CurrentUser } from '../../modules/user';
import InternalOnly from '../../utils/InternalOnly';
import LinkContainer from '../LinkContainer';
import styles from './ProjectsList.module.scss';

interface Props {
  projects: Project[];
  dispatch: Dispatch;
  user: CurrentUser;
}

const ProjectsList: React.FC<Props> = ({ projects, dispatch, user }) => {
  const groups = groupByClient(projects).map(({ client, clientId, items }) => (
    <React.Fragment key={clientId}>
      <h4>{client}</h4>
      <ProjectGroup projects={items} dispatch={dispatch} user={user} />
    </React.Fragment>
  ));

  // why is this nessecary
  return <>{groups}</>;
};

const ProjectGroup: React.FC<Props> = ({ projects, dispatch, user }) => {
  const projectEntries = sortBy(prop('projectname'), projects).map(p => (
    <ProjectItem key={p.recnum} project={p} dispatch={dispatch} user={user} />
  ));
  return <ListGroup>{projectEntries}</ListGroup>;
};

const ProjectItem: React.FC<{
  project: Project;
  dispatch: Dispatch;
  user: CurrentUser;
}> = ({ project, user, dispatch }) => {
  // handle associate intent
  const onAssociate = React.useCallback<React.MouseEventHandler<Button>>(
    event => {
      event.stopPropagation();
      event.preventDefault();
      const action = project.users.includes(user.recnum)
        ? 'dissociate'
        : 'associate';
      dispatch(
        updateProject(project.recnum, {
          [action]: user.recnum,
        }) as any,
      );
    },
    [dispatch, project, user],
  );

  return (
    <LinkContainer to={`/project/${project.recnum}`}>
      <ListGroupItem className={styles.root}>
        <div className={styles.title}>
          {project.archived && (
            <i title="archived" className="fa fa-archive"></i>
          )}{' '}
          {project.projectname}
        </div>
        <InternalOnly user={user}>
          <div className={styles.right}>
            <Button bsStyle="link" onClick={onAssociate} className="pull-right">
              <i
                className={`fa fa-${
                  project.users.includes(user.recnum) ? 'star' : 'star-o'
                }`}
              />
            </Button>
          </div>
        </InternalOnly>
      </ListGroupItem>
    </LinkContainer>
  );
};

export default ProjectsList;
