import { defineMessages } from 'react-intl';
import { last, init } from 'ramda';

const msg = defineMessages({
  LINGUAL_PLURAL_JOIN: {
    id: 'MISC.LINGUAL_PLURAL_JOIN',
    defaultMessage: '{init} and {last}',
    description: 'Lingually join a list of multiple elements'
  }
});

export function lingualJoin(messageIdPrefix, elems, formatMessage) {
  const translatedElements = messageIdPrefix
    ? elems.map(elem =>
        formatMessage({ id: messageIdPrefix + elem.toUpperCase() })
      )
    : elems;
  if (elems.length <= 1) {
    return translatedElements[0];
  }
  return formatMessage(msg.LINGUAL_PLURAL_JOIN, {
    init: init(elems).join(', '),
    last: last(elems)
  });
}
