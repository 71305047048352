import classNames from 'classnames';
import { concat, uniq, without } from 'lodash';
import React from 'react';
import { getChildren, TreeStructure } from './common';
import { Draggable, useDraggableInternal } from './Draggable';
import stl from './Tree.module.scss';
import { BaseTreeElementProps, TreeElementProps } from './TreeElement';

export interface TreeTableRowProps<T extends {}> {
  props: BaseTreeElementProps;
  item: T;
}

function constructTreeTableRowProps<T extends TreeStructure>(
  structure: T,
  expandedElementIds: any[],
) {
  // results
  let rowData: TreeTableRowProps<T>[] = [];

  function innerIterate(item: T, parents: { hasMore: boolean }[] = []) {
    const isOpen = expandedElementIds.includes(item.id);

    //render the item
    rowData.push({
      props: {
        id: item.id,
        name: item.name,
        open: isOpen,
        hasChildren: !!item.children?.length,
        trailIcons: parents.map(({ hasMore }, idx) =>
          idx === parents.length - 1
            ? hasMore
              ? 'downRight'
              : 'right'
            : hasMore
            ? 'down'
            : 'empty',
        ),
      },
      item,
    });

    // render the items children (conditionally)
    if (isOpen) {
      item.children?.forEach((c, idx) =>
        innerIterate(c as any, [
          ...parents,
          { hasMore: idx !== item.children!.length - 1 },
        ]),
      );
    }
  }

  // start
  innerIterate(structure);

  return rowData;
}

export interface TreeTableProps<T extends TreeStructure> {
  elements: T[];
  index: Record<string, T>;
  onExpandedElementIdsChanged: (ids: any[]) => void;
  expandedElementIds: any[];
  renderRow: (props: TreeElementProps, item: T) => React.ReactNode;
  thead: React.ReactNode;
  onContextMenu?: any;
  draggableInterface?: Draggable<T>;
}

function TreeTable<T extends TreeStructure>({
  elements,
  onExpandedElementIdsChanged,
  expandedElementIds,
  onContextMenu,
  renderRow,
  thead,
  draggableInterface,
  index,
}: TreeTableProps<T>) {
  const handleToggleOpenItemId = React.useCallback(
    (id: any, all: boolean = false) => {
      const ids = all
        ? [id, ...getChildren(index[id]).map(item => item.id)]
        : [id];

      const operation = expandedElementIds.includes(id)
        ? (a: any[], b: any[]) => without(a, ...b)
        : concat;
      onExpandedElementIdsChanged(uniq(operation(expandedElementIds, ids)));
    },
    [index, expandedElementIds, onExpandedElementIdsChanged],
  );

  const rowData = elements
    .map(i => constructTreeTableRowProps(i, expandedElementIds))
    .flat();

  const { dragEventHandlers, dropTargetVisualization } = useDraggableInternal(
    rowData,
    draggableInterface || {
      canDropItem: () => [],
      onDropItem: () => {},
    },
  );

  return (
    <table className={classNames(stl.root)} onContextMenu={onContextMenu}>
      {thead && <thead>{thead}</thead>}
      <tbody>
        {rowData.map(({ props, item }) =>
          renderRow(
            {
              ...props,
              ...(draggableInterface ? dragEventHandlers : {}),
              onToggleOpen: handleToggleOpenItemId,
              dropLocationVisualization:
                (item.id === dropTargetVisualization?.id &&
                  dropTargetVisualization?.location) ||
                null,
            },
            item,
          ),
        )}
      </tbody>
    </table>
  );
}

export default TreeTable;
