import { CountingRequest } from '@certhon/domain-models/lib';
import { add } from 'date-fns';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import Types from 'Types';
import { useLocation } from 'wouter';
import CountingRequestDetailsEditor from '../components/CountingRequestDetailsEditor';
import useLocations from '../hooks/useLocations';
import {
  InsertableCountingRequest,
  useCountingRequestStore,
} from '../stores/countingRequestStore';

const CountingRequestCreationContainer: React.FC = () => {
  const [, { save }] = useCountingRequestStore();
  const [, go] = useLocation();
  const rootLocations = useLocations();
  const isCountingRequestAdmin = useSelector<Types.RootState, boolean>(
    ({ user }) => !!user?.permissions?.COUNTING_REQUEST_ADMIN,
  );
  const [error, setError] = React.useState<Error | null>(null);
  const [countingRequest, setCountingRequest] = useState<
    InsertableCountingRequest
  >({
    name: '',
    start_date: new Date(),
    end_date: add(new Date(), { days: 1 }),
    locations: [],
    filter: {
      unsalable: null,
      sampleSeed: Math.floor(1000 * Math.random()),
      samplePercentage: null,
    },
  });

  const handleChange = useCallback((pcr: Partial<CountingRequest>) => {
    setCountingRequest(cr => ({ ...cr, ...pcr }));
  }, []);

  const handleSubmit = useCallback(async () => {
    try {
      setError(null);
      const { recnum } = await save(countingRequest);
      go(`/counting-requests/${recnum}`, { replace: true });
    } catch (error) {
      setError(error as any);
    }
  }, [countingRequest, go, save]);

  return (
    <CountingRequestDetailsEditor
      error={error}
      isCountingRequestAdmin={isCountingRequestAdmin}
      rootLocations={rootLocations}
      countingRequest={countingRequest}
      onChange={handleChange}
      onSubmit={handleSubmit}
    />
  );
};

export default CountingRequestCreationContainer;
