import classNames from 'classnames';
import PropTypes from 'prop-types';
import * as React from 'react';
import { ListGroupItem, Panel } from 'react-bootstrap';
import { ContextMenu, ContextMenuTrigger } from 'react-contextmenu';

import { LISTMODE_MAINTAIN } from '../';
import BaseValueField from '../BaseValueField';
import styles from '../FieldStyles.module.scss';

import { FormattedMessage, injectIntl } from 'react-intl';
import { isListClosed } from '../../../common/logic/list';
import Dropzone from '../../Dropzone';
import Timeline from '../../Timeline';
import attachmentToEvent from '../attachmentToEvent';
import FieldAnnotations from '../FieldAnnotations';
import CheckpointControl from './CheckpointControl';

export default injectIntl(
  class CheckpointField extends BaseValueField {
    static propTypes = {
      dispatch: PropTypes.func.isRequired,
      field: PropTypes.object.isRequired,
    };

    renderReport() {
      const { field, users, dispatch } = this.props;

      const description = this.getLocalizedfield('description');
      const title = this.getLocalizedfield('title');

      let value;
      if (field.value === true) {
        value = (
          <span className={styles.good}>
            <FormattedMessage
              id="LIST_REPORT.OK"
              defaultMessage="✓ OK"
              description="Label describing checkpoint is ok"
            />
          </span>
        );
      } else if (field.value === false) {
        value = (
          <span className={styles.bad}>
            <FormattedMessage
              id="LIST_REPORT.NOT_OK"
              defaultMessage="✗ FAILED"
              description="Label describing checkpoint is not ok"
            />
          </span>
        );
      } else {
        value = <span className={styles.missing}>-</span>;
      }

      let events = [];

      if (field.attachments) {
        events = Object.values(field.attachments).map(attachmentToEvent);
      }
      return (
        <div className={styles.printedCheckpoint}>
          <div>
            <div>
              <p>
                {' '}
                {title} {description && <em>{description}</em>}
              </p>
            </div>
            <div className={styles.answer}>{value}</div>
          </div>
          <Timeline
            dispatch={dispatch}
            users={users}
            events={events}
            onCreateEvent={this.handleCreateEvent}
            onEditEvent={this.handleEditEvent}
            onDeleteEvent={this.handleDeleteEvent}
          />
        </div>
      );
    }

    renderUI() {
      const { field, mode, expanded, dispatch, users, user, list } = this.props;
      const description = this.getLocalizedfield('description');
      const title = this.getLocalizedfield('title');

      const contextMenuOptions = this.renderContextMenuOptions();
      const controlDisabled = mode === LISTMODE_MAINTAIN || isListClosed(list);

      if (expanded) {
        let events = [];

        if (field.attachments) {
          events = Object.values(field.attachments).map(attachmentToEvent);
        }
        return (
          <Dropzone
            className={classNames(styles.expandedValueField)}
            ref={node => {
              this.dropzoneRef = node;
            }}
            accept="image/*, video/mp4"
            style={{}}
            acceptClassName={styles.accept}
            rejectClassName={styles.reject}
            disableClick
            onDropAccepted={this.handleAttachments}
          >
            <Panel>
              <Panel.Body>
                <div className={styles.valueField}>
                  <div className={styles.titleBox}>{title}</div>
                  <div className={styles.controlBox}>
                    <CheckpointControl
                      disabled={controlDisabled}
                      value={field.value}
                      onChange={this.handleValueChange}
                    />
                  </div>
                </div>
                <div className={styles.descriptionBox}>
                  <small>
                    <em>{description}</em>
                  </small>
                </div>
                <div className={styles.timeline}>
                  <Timeline
                    dispatch={dispatch}
                    user={user}
                    users={users}
                    events={events}
                    onCreateEvent={this.handleCreateEvent}
                    onEditEvent={this.handleEditEvent}
                    onDeleteEvent={this.handleDeleteEvent}
                  />
                </div>
              </Panel.Body>
            </Panel>
          </Dropzone>
        );
      }
      return (
        <div id={field.id} className={this.isSelected() ? styles.selected : ''}>
          <ContextMenuTrigger
            id={field.id}
            disable={!contextMenuOptions.length}
          >
            <ListGroupItem className={styles.valueField} onClick={this.select}>
              <div className={styles.titleBox}>{title}</div>
              <div className={styles.annotations}>
                <FieldAnnotations field={field} />
              </div>
              <div className={styles.controlBox}>
                <CheckpointControl
                  disabled={controlDisabled}
                  value={field.value}
                  onChange={this.handleValueChange}
                />
              </div>
            </ListGroupItem>

            <ContextMenu id={field.id}>{contextMenuOptions}</ContextMenu>
          </ContextMenuTrigger>
        </div>
      );
    }
  },
);
