import uniq from 'lodash/fp/uniq';
import without from 'lodash/fp/without';
import * as React from 'react';
import { User } from '../../models/User';
import UserToken from '../UserToken';
import stl from './AttendeesControl.module.scss';
import { UsersDict } from './MinuteMetaForm';
import InputList from '../InputList';
import matchUserToTerm from '../../common/user/matchUserToTerm';
import { sortWith } from 'ramda';
import sortUsers from '../../common/user/sortUsers';
interface IAttendeesControlProps {
  users: UsersDict;
  present: number[];
  absent: number[];
  display: 'present' | 'absent';
  onChange: (change: { present: number[]; absent: number[] }) => void;
}

function modifyGroups(
  absent: number[],
  present: number[],
  newItem: number,
  group: 'present' | 'absent',
) {
  if (group === 'present') {
    return {
      present: uniq([...present, newItem]),
      absent: without([newItem], absent),
    };
  } else {
    return {
      absent: uniq([...absent, newItem]),
      present: without([newItem], present),
    };
  }
}

function allowDrop(event: React.DragEvent<HTMLDivElement>) {
  event.preventDefault();
}

const AttendeesControlToken: React.FC<{
  user: User;
  onRemove: (recnum: number) => void;
}> = ({ user, onRemove }) => {
  function onDrag(event: React.DragEvent<HTMLDivElement>) {
    event.dataTransfer.setData('user', user.recnum.toString());
  }

  const handleRemove = React.useCallback(() => onRemove(user.recnum), [
    user,
    onRemove,
  ]);

  return (
    <div className={stl.tokenRoot} draggable={true} onDragStart={onDrag}>
      <UserToken user={user} small={true} withText={true} collapse={false} />
      <button onClick={handleRemove}>&times;</button>
    </div>
  );
};

const AttendeesControl: React.FC<IAttendeesControlProps> = ({
  users,
  present,
  absent,
  display,
  onChange,
}) => {
  // allow clicking anywhere in control to activate input
  const rootRef = React.createRef<HTMLDivElement>();
  const focusInput = React.useCallback(() => {
    if (rootRef.current) {
      (rootRef.current.querySelector('input') as HTMLInputElement).focus();
    }
  }, [rootRef]);

  // receive user token from other attendees control
  function handleDrop(event: React.DragEvent<HTMLDivElement>) {
    const userRecnum = parseInt(
      event.dataTransfer.getData('user') as string,
      10,
    );

    onChange(modifyGroups(absent, present, userRecnum, display));
  }
  const handleSelectItem = React.useCallback(
    (user: User) => {
      onChange(modifyGroups(absent, present, user.recnum, display));
    },
    [onChange, present, absent, display],
  );
  const handleRemove = React.useCallback(
    (userRecnum: number) =>
      onChange({
        absent,
        present,
        [display]: without(
          [userRecnum],
          display === 'present' ? present : absent,
        ),
      }),
    [onChange, absent, present, display],
  );

  // prepare visible tokens
  const displayGroup = display === 'present' ? present : absent;
  const userTokens = displayGroup
    .map(a => {
      const user = users[a];
      if (!user) {
        throw new Error(`Could not find user for recnum ${a}`);
      }
      return user;
    })
    .map(user => (
      <AttendeesControlToken
        key={user.recnum}
        user={user}
        onRemove={handleRemove}
      />
    ));
  return (
    <div
      ref={rootRef}
      className={stl.root}
      onDragOver={allowDrop}
      onDrop={handleDrop}
      onClick={focusInput}
    >
      {userTokens}
      <InputList
        items={sortWith(sortUsers, Object.values(users as any))}
        name={'users'}
        selectedItems={[]}
        shouldItemRender={matchUserToTerm}
        getItemValue={(user: User) => user.recnum.toString()}
        onSelectItem={handleSelectItem}
        onDeselectItem={() => {}}
      />
    </div>
  );
};

export default AttendeesControl;
