import bytes from 'bytes';
import classNames from 'classnames';
import * as React from 'react';
import {
  Alert,
  Button,
  ButtonGroup,
  Glyphicon,
  ListGroupItem,
} from 'react-bootstrap';
import { FormattedDate, useIntl } from 'react-intl';
import useFilePreview, {
  FilePreviewError,
} from '../../../hooks/useFilePreview';
import {
  AugmentedCatalogFile,
  SUPPORTED_LANGUAGES,
} from '../../../models/Catalog';
import {
  FileDownloadState,
  OpenFileTarget,
} from '../../../modules/fileDownloads';
import ProgressBullet from '../../ProgressBullet';
import Spinner from '../../Spinner';
import { getCatalogFileNodeName, getRevisionString } from '../catalog';
import stl from './CatalogContents.module.scss';

const CatalogFileDetailsSection: React.FC<{
  file: AugmentedCatalogFile;
  openFile: (
    file: AugmentedCatalogFile,
    target?: OpenFileTarget,
    lang?: string,
  ) => void;
  online: boolean;
}> = ({ file, openFile, online }) => {
  // CRA 3.3.0 is broken
  const preview = useFilePreview(file);
  const { locale: lang } = useIntl();

  const openFileSelf = React.useCallback(async () => {
    openFile(file);
  }, [file, openFile]);

  const openFileWindow = React.useCallback(async () => {
    openFile(file, '_blank');
  }, [file, openFile]);

  const downloadFile = React.useCallback(async () => {
    openFile(file, 'download', lang);
  }, [file, openFile, lang]);

  const fileAvailable = file.file;

  return (
    <section className={stl.details}>
      <dl>
        <dt>preview</dt>
        <dd>
          <div className={stl.preview}>
            {preview.status === 'ready' ? (
              <img
                className="img-responsive"
                alt="attachment"
                src={preview.src}
              />
            ) : preview.status === 'failed' ? (
              preview.errorCode === FilePreviewError.FILE_NOT_PDF ? (
                <em>preview not available</em>
              ) : (
                <Alert bsClass="danger">Preview failed - {preview.error}</Alert>
              )
            ) : (
              <>
                <Spinner />
                <p className={stl.previewTask}>{preview.task}</p>
              </>
            )}
          </div>
        </dd>
      </dl>
      <dl className="dl-horizontal">
        <dt>Revision message</dt>
        <dd>{file.revision_message?.trim() || <em>no message</em>}</dd>
        <details>
          <summary>More Details</summary>
          <dt>File size</dt>
          <dd>{bytes(file.size)}</dd>
          <dt>File extension</dt>
          <dd>{file.extension}</dd>
          <dt>Revision</dt>
          <dd>{getRevisionString(file.revision_nr) || 'first'}</dd>
          <dt>Revision date</dt>
          <dd>
            <FormattedDate value={file.date_in} />
          </dd>
          <dt>Content checksum</dt>
          <dd>{file.checksum}</dd>
          <dt>MIME type</dt>
          <dd>{file.mime}</dd>
          {SUPPORTED_LANGUAGES.map(lang => (
            <>
              <dt>{lang}</dt>
              <dd>{file[lang]?.trim() || <em>no translation</em>}</dd>
            </>
          ))}
        </details>{' '}
      </dl>
      <ButtonGroup className={stl.actions}>
        <Button
          disabled={!online && !fileAvailable}
          onClick={openFileSelf}
          className={stl.open}
        >
          <Glyphicon glyph="file" /> Open
        </Button>
        <Button
          disabled={!online && !fileAvailable}
          onClick={openFileWindow}
          className={stl.openWindow}
        >
          <Glyphicon glyph="new-window" /> New window
        </Button>
        <Button
          disabled={!online && !fileAvailable}
          onClick={downloadFile}
          className={stl.download}
        >
          <Glyphicon glyph="download" /> Download
        </Button>
      </ButtonGroup>
    </section>
  );
};

const CatalogFileComponent: React.FC<{
  file: AugmentedCatalogFile;
  depth: number;
  expanded: boolean;
  online: boolean;
  onToggleExpanded: () => void;
  openFile: (file: AugmentedCatalogFile, target?: OpenFileTarget) => void;
}> = ({ file, depth, onToggleExpanded, expanded, openFile, online }) => {
  const revisionAvailable = file.revision_nr !== null;
  const revisionString = getRevisionString(file.revision_nr);
  const { locale } = useIntl();
  let progressBullet;
  if (
    file.download &&
    file.download.state !== FileDownloadState.DONE &&
    file.download.state !== FileDownloadState.FAILED
  ) {
    progressBullet = (
      <ProgressBullet
        progress={
          file.download.total ? file.download.loaded / file.download.total : 0
        }
      />
    );
  }
  return (
    <ListGroupItem
      className={classNames(
        stl.file,
        expanded && stl.expanded,
        revisionAvailable && stl.revisionAvailable,
        file.textSearchMatch && stl.match,
      )}
      // onClick={openFileSelf}
    >
      <div
        onClick={onToggleExpanded}
        className={classNames(stl.primary, stl[`depth_${depth}`])}
      >
        <span className={stl.icon} />
        <span className={stl.title}>
          {getCatalogFileNodeName(file, (locale as any) || 'nl')}
        </span>
        {progressBullet}
        {revisionString && (
          <span className={stl.revision}>{revisionString}</span>
        )}
        {revisionAvailable && <button className={stl.toggleExpanded}></button>}
      </div>
      {expanded && (
        <CatalogFileDetailsSection
          file={file}
          online={online}
          openFile={openFile}
        />
      )}
    </ListGroupItem>
  );
};

export default CatalogFileComponent;
