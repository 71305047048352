import * as React from 'react';
import getFilterOptions from '../../common/logic/getFilterOptions';
import { LabelsState } from '../../modules/labels';
import { UserState } from '../../modules/user';
import { UsersState } from '../../modules/users';
import { Work } from '../../modules/works';
import DepartmentToken from '../DepartmentToken';
import ParameterizedSearch from '../ParameterizedSearch';
import { Preset } from '../ParameterizedSearch/model';
import useWorkFilter from '../ProjectsList/useWorkFilter';

const presets: Preset[] = [
  ...['climate', 'electrical', 'greenhouse', 'irrigation'].map(department => ({
    name: (
      <>
        Internal Meeting <DepartmentToken inline value={department} />
      </>
    ),
    value: `label:"75" department:"${department}" status:"opened"`,
  })),
  {
    name: 'Internal Meeting Overall',
    value: 'label:"75" status:"opened"',
  },
  {
    name: 'External Meeting Overall',
    value: 'label:"76" status:"opened"',
  },
];

interface ProjectFilterProps {
  filter: string;
  onChangeFilter: (filter: string | null) => void;
  works: Work[] | null;
  users: UsersState;
  user: UserState;
  labels: LabelsState;
  /** FIXME: filter component is not intended to handle sorting, but we do want to change sorting when a preset is selected */
  onPresetSelected?: () => void;
}

const ProjectFilter: React.FC<ProjectFilterProps> = ({
  filter,
  labels,
  onChangeFilter,
  onPresetSelected,
  user,
  users,
}) => {
  // filter interface
  const fi = useWorkFilter(onChangeFilter, filter, user);
  const options = React.useMemo(() => getFilterOptions(users, labels), [users]);

  const handleChange = React.useCallback(
    (value: string, fromPreset: boolean) => {
      onChangeFilter(value);
      if (fromPreset) {
        onPresetSelected?.();
      }
    },
    [onChangeFilter, onPresetSelected],
  );

  return (
    <ParameterizedSearch
      value={fi.filter}
      onChange={handleChange}
      options={options}
      onReset={fi.resetFilter}
      onClear={fi.clearFilter}
      presets={presets}
    />
  );
};

export default ProjectFilter;
