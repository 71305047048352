import { filter, identity, map, partition, reverse, uniq, values } from 'ramda';

function peekable(iter) {
  var buffer = undefined;
  if (iter.peek) {
    return iter;
  }
  return {
    hasNext: function() {
      if (buffer === undefined) {
        return iter.hasNext();
      }
      return true;
    },
    next: function() {
      var result;
      if (buffer === undefined) {
        result = iter.next();
      } else {
        result = buffer;
      }
      buffer = undefined;
      return result;
    },
    peek: function() {
      if (buffer === undefined) {
        buffer = iter.next();
      }
      return buffer;
    },
  };
}

export function takeFromIterable(num, iterable) {
  if (!iterable[Symbol.iterator]) {
    throw new Error('not iterable');
  }
  const rv = [];
  let cnt = 0;
  for (const v of iterable) {
    rv.push(v);
    /* eslint-disable no-plusplus */
    if (++cnt === num) {
      break;
    }
  }
  return rv;
}

export function takeFromIterableUntil(evalFn, iterable) {
  if (!iterable[Symbol.iterator]) {
    throw new Error('not iterable');
  }
  const rv = [];
  for (const v of iterable) {
    if (evalFn(v)) {
      break;
    }
    rv.push(v);
  }
  return rv;
}

/**
 * takes an array of mods and makes a discription.
 * !!! there assumptions of constraints on the contents of the array
 *  as performed by createListActivityIterator
 * @param {array} mods
 * @param {string} listId
 * @return {object} description
 */
function describeListMods(mods, { id: listId, recnum: listRecnum, werk_id }) {
  const rmods = reverse(mods);

  let { type, timestamp, payload } = rmods[0];
  let users = [rmods[0].user_id];

  // tslint-disable
  if (rmods[0].type === 'CLOSE_LIST') {
    // tslint-disable
  } else if (rmods[0].type === 'OPEN_LIST') {
    // tslint-disable
  } else if (rmods[0].type === 'EXPAND_PROTO') {
    // tslint-disable
  } else {
    type = 'CHECKS';

    const resultingAnswers = {};
    rmods.forEach(mod => {
      users.push(mod.user_id);
      if (mod.payload.targetNodeId && mod.payload.partialNode) {
        resultingAnswers[mod.payload.targetNodeId] =
          mod.payload.partialNode.value;
      }
    });

    // XXX: we ignore uncecked checkpoints
    const [good, bad] = map(
      a => a.length,
      partition(
        identity,
        filter(v => typeof v === 'boolean', values(resultingAnswers)),
      ),
    );
    users = uniq(users);
    payload = {
      totalChecks: good + bad,
      failedChecks: bad,
    };
  }

  return {
    timestamp,
    users,
    type,
    // these will be processed out of context, so add the list id
    payload: { ...payload, listId, listRecnum, werk_id },
  };
}

export const parseDate = str => {
  const date = new Date(str);
  if (isNaN(date.getTime())) {
    throw new Error(`Could not parse date string: ${str}`);
  }
  return date;
};

export const parseDateOrNull = due => (due === null ? null : parseDate(due));

function shouldSeperate(
  last,
  next,
  { seperationInterval, seperateOnUserChange },
) {
  const interval =
    last &&
    Math.abs(parseDate(next.timestamp) - parseDate(last.timestamp)) >
      seperationInterval;
  const user = seperateOnUserChange && last && last.user_id !== next.user_id;
  return (
    interval ||
    user ||
    last.type === 'CLOSE_LIST' ||
    last.type === 'OPEN_LIST' ||
    last.type === 'EXPAND_PROTO'
  );
}

function createListActivityIterator(
  list,
  { seperationInterval = 3600e3, separateOnUserChange = false },
) {
  return {
    [Symbol.iterator]() {
      const modI = reverse(list.events)[Symbol.iterator]();
      let lastMod = null;
      let nextIt = modI.next();
      return {
        type: 'listAcitivity',
        next() {
          // take modifications until a seperation is reached and return a description
          let mods = [];

          // check if the next mod should be joined or not
          while (true) {
            if (
              nextIt.done ||
              (mods.length &&
                shouldSeperate(lastMod, nextIt.value, {
                  seperationInterval,
                  separateOnUserChange,
                }))
            ) {
              if (mods.length) {
                return { value: describeListMods(mods, list), done: false };
              } else {
                return { value: undefined, done: true };
              }
            } else {
              lastMod = nextIt.value;
              nextIt = modI.next();
              mods.push(lastMod);
            }
          }
        },
      };
    },
  };
}

function createTicketActivityIterator({ events, werk_id }) {
  return reverse(events).map(event => ({
    timestamp: event.created,
    type: event.type,
    users: [event.user_id],
    payload: { ...event, werk_id },
  }));
}

/**
 * Takes a bunch of sources and returns an reverse chronological activity iterator
 *
 * @param {Array} sources.lists
 * @param {Array} sources.tickets
 */
export function createActivityIterator({ lists, tickets }) {
  return {
    [Symbol.iterator]() {
      const activityIterators = [
        ...lists.map(createListActivityIterator),
        ...tickets.map(createTicketActivityIterator),
      ].map(a => peekable(a[Symbol.iterator]()));
      return {
        next() {
          // inspect all activity iterators and take the most recent activity description
          const mostRecent = activityIterators.reduce((mrai, cai) => {
            if (
              cai &&
              cai.peek().value &&
              (!mrai ||
                parseDate(cai.peek().value.timestamp) >
                  parseDate(mrai.peek().value.timestamp))
            ) {
              return cai;
            }
            return mrai;
          }, null);
          let value;
          if (mostRecent) {
            value = mostRecent.next().value;
          }
          if (mostRecent && !value) {
            // tslint:disable-next-line
            console.error('found mostrecent without value! 🤔');
          }
          if (mostRecent) {
            return {
              done: false,
              value,
            };
          } else {
            return {
              done: true,
              value: undefined,
            };
          }
        },
      };
    },
  };
}
