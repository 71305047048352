import * as uuid from 'uuid';
import { BuiltList } from '../../../models/List';
import { AttachmentListModification } from './operations/attachment';
import { CloseListModification } from './operations/close';
import { CopyListModification } from './operations/copy';
import { ExpandProtoListModification } from './operations/expandProto';
import { GeotagListModification } from './operations/geotag';
import {
  CreateListModification,
  InsertListModification,
  UpdateListModification,
} from './operations/insert';
import { MoveListModification } from './operations/move';
import { NoteListModification } from './operations/note';
import { OpenListModification } from './operations/open';
import { PatchListModification } from './operations/patch';
import { RemoveListModification } from './operations/remove';

const uuidv4 = uuid.v4;

export interface BaseListEvent {
  type: string;
  id: string;
  created: Date;
  user_id: number;
}

export function createModificationCreator<Payload, T extends string>(type: T) {
  return (
    payload: Payload,
    user_id: number,
    id: string = uuidv4(),
  ): BaseListEvent & { payload: Payload; type: T } => {
    return {
      type,
      id,
      created: new Date(),
      user_id,
      payload,
    };
  };
}

export type ListEventReducer<M extends BaseListEvent> = (
  list: BuiltList,
  event: M,
) => BuiltList;

export type ListEvent =
  | CreateListModification
  | UpdateListModification
  | ExpandProtoListModification
  | InsertListModification
  | PatchListModification
  | RemoveListModification
  | MoveListModification
  | CopyListModification
  | GeotagListModification
  | NoteListModification
  | AttachmentListModification
  | CloseListModification
  | OpenListModification;
