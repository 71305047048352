import { ascend, descend, Ord, prop, sortWith } from 'ramda';
import { Ticket } from '../../models/Ticket';

export enum TicketSorting {
  TITLE,
  CREATED,
  DUE,
  STATUS,
}

export enum Order {
  DESC,
  ASC,
}

const getOrderingFunc = (k: Order) => (k === Order.ASC ? ascend : descend);

const sortingFunctionMap = new Map<TicketSorting, [Order, (t: Ticket) => Ord]>([
  [TicketSorting.STATUS, [Order.DESC, prop('status')]],
  [TicketSorting.CREATED, [Order.ASC, prop('created')]],
  [TicketSorting.DUE, [Order.ASC, (t: Ticket) => t.due || -Infinity]],
  [TicketSorting.TITLE, [Order.ASC, prop('title')]],
]);

type SortingConfig = Array<[TicketSorting, Order]>;

export function toString(cnf: SortingConfig): string {
  return cnf
    .map(([s, o]) => [TicketSorting[s], Order[o]].join('.'))
    .join(',')
    .toLowerCase();
}
export function fromString(cnf: string): SortingConfig {
  return cnf
    .toUpperCase()
    .split(',')
    .map(e => e.split('.'))
    .map(([s, o]) => [
      TicketSorting[s as keyof typeof TicketSorting],
      Order[o as keyof typeof Order],
    ]);
}

export const sortingPresets = new Map([
  [
    'created',
    toString([
      [TicketSorting.STATUS, Order.DESC],
      [TicketSorting.CREATED, Order.DESC],
      [TicketSorting.DUE, Order.ASC],
      [TicketSorting.TITLE, Order.ASC],
    ]),
  ],
  [
    'due',
    toString([
      [TicketSorting.STATUS, Order.DESC],
      [TicketSorting.DUE, Order.ASC],
      [TicketSorting.CREATED, Order.ASC],
      [TicketSorting.TITLE, Order.ASC],
    ]),
  ],
  [
    'title',
    toString([
      [TicketSorting.STATUS, Order.DESC],
      [TicketSorting.TITLE, Order.ASC],
      [TicketSorting.CREATED, Order.ASC],
      [TicketSorting.DUE, Order.ASC],
    ]),
  ],
]);
export const defaultSorting = sortingPresets.get('due')!;

export const createSortFunction = (cnf: SortingConfig) =>
  sortWith<Ticket>(
    cnf.map(([s, o]) => {
      const comparator = getOrderingFunc(o);
      const getter = sortingFunctionMap.get(s)![1];
      return comparator(getter);
    }),
  );

const sortTickets = sortWith<Ticket>([
  descend(prop('status')),
  descend((t: any) => t.number || Infinity),
]);

export default sortTickets;
