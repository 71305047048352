import * as React from 'react';

interface InternalOnlyProps {
  user: { internal: boolean } | null;
  children: any; // FIX what should be the correct type here, it complains for React.ReactNode and React.ReactElement doesnt allow siblings
}

const InternalOnly: React.FC<InternalOnlyProps> = ({ user, children }) => {
  if (user?.internal) {
    return children;
  }
  return null;
};

export default InternalOnly;
