import React, { ReactNode } from 'react';

import stl from './Cover.module.scss';

type CoverProps = {
  title: string;
  subtitle: string;
  table: ReactNode;
};

function Cover({ title, subtitle, table }: CoverProps) {
  return (
    <>
      <h1>{title}</h1>
      {table}
    </>
  );
}

export default Cover;

type CoverTableProps = { children: ReactNode };

export function CoverTable({ children }: CoverTableProps) {
  return (
    <div className={stl.coverTable}>
      <table>{children}</table>
    </div>
  );
}
