import {
  AugmentedCatalogFile,
  Catalog,
  CatalogFile,
  CatalogGroup,
  CatalogNode,
  CatalogStructure,
  CatalogStructureGroup,
  DocsNodeLangKey,
} from '../../models/Catalog';
import { FileDownloadsState } from '../../modules/fileDownloads';
import { FilesState } from '../../modules/files';

export function getCatalogNodeName(
  node: CatalogNode,
  lang: DocsNodeLangKey = 'en',
) {
  return node[lang] || node.en || node.nl;
}
export function getCatalogFileNodeName(
  node: CatalogFile,
  lang: DocsNodeLangKey = 'en',
) {
  return (node.prefix
    ? [node.prefix, node[lang] || node.en || node.nl]
    : [
        node[lang] || node.en || node.nl,
        (node.duplicatenr && node.duplicatenr + 1) || '',
      ]
  ).join(' ');
}

const BASE_CHAR_CODE = 'A'.charCodeAt(0) - 1;

export function getRevisionString(revision: number) {
  if (revision > 0) {
    const char = String.fromCharCode(BASE_CHAR_CODE + revision);
    return `rev ${char}`;
  }
  return null;
}

/**
 * Create a nested data structure from a set of relations catalog groups/files
 */
export function createCatalogStructure(
  catalog: Catalog,
  files: FilesState,
  fileDownloads: FileDownloadsState,
  textFilter: (fg: CatalogGroup | CatalogFile) => boolean | null,
  paramFilter: (f: CatalogFile) => boolean | null,
  expandedFile?: number,
): CatalogStructure {
  function augmentFile(file: CatalogFile): AugmentedCatalogFile {
    return {
      ...file,
      download: fileDownloads[file.recnum] || null,
      file: files[file.checksum] || null,
      textSearchMatch: textFilter(file),
      paramMatch: paramFilter(file),
    };
  }
  function populateGroupStructure(group: CatalogGroup): CatalogStructureGroup {
    const groups = catalog.groups
      .filter(g => g.catalog_group_id === group.recnum)
      .map(populateGroupStructure);
    const files = catalog.files
      .filter(f => f.catalog_group_id === group.recnum)
      .map(augmentFile);
    return {
      ...group,
      groups,
      files,
      textSearchMatch: textFilter(group),
      containsDeepLinkFile:
        groups.some(g => g.containsDeepLinkFile) ||
        files.some(f => f.recnum === expandedFile),
      containsMatch:
        groups.some(
          g => g.containsMatch !== false || g.textSearchMatch !== false,
        ) ||
        files.some(f => f.textSearchMatch !== false && f.paramMatch !== false),
    };
  }
  return catalog.groups
    .filter(g => g.catalog_group_id === null)
    .map(populateGroupStructure);
}
