import { Artikel } from '@certhon/domain-models';
import debounce from 'lodash/debounce';
import { stringify } from 'query-string';
import { useCallback, useContext, useEffect, useState } from 'react';
import { ReactReduxContext } from 'react-redux';
import { CALL_API } from 'redux-api-middleware';
import { resolveHeaders, resolveUrl } from '../utils/request';
export interface RequestFetchArtikels {
  type: 'REQUEST_FETCH_ARTIKELS';
}
export interface SuccessFetchArtikels {
  type: 'SUCCESS_FETCH_ARTIKELS';
  payload: Artikel[];
}
export interface FailureFetchArtikels {
  type: 'FAILURE_FETCH_ARTIKELS';
  error: true;
}

export function fetchArtikels(q: string): any {
  return {
    [CALL_API]: {
      endpoint: resolveUrl(`/artikels?${stringify({ q })}`),
      method: 'GET',
      headers: resolveHeaders(),
      types: [
        'REQUEST_FETCH_ARTIKELS',
        'SUCCESS_FETCH_ARTIKELS',
        'FAILURE_FETCH_ARTIKELS',
      ],
    },
  };
}

export default function useArtikelSearch(query: string) {
  const [artikels, setAtikels] = useState<Artikel[]>([]);
  const {
    store: { dispatch },
  } = useContext(ReactReduxContext);

  const fetchResults = useCallback(
    // wait for user to stop typing before querying backend
    debounce(async (q: string) => {
      // minimum 3 character search query
      if (q.length < 3) {
        return;
      }
      const result = await dispatch(fetchArtikels(q));

      setAtikels(result.payload);
    }, 400),
    [dispatch],
  );

  useEffect(() => {
    fetchResults(query);
  }, [fetchResults, query]);

  return artikels;
}
