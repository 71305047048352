import * as uuid from 'uuid';

const uuidv4 = uuid.v4;

export interface TicketReference {
  ticketId: string;
  userId: number;
  created: Date;
}
export interface MeetingSection {
  title: string | null;
  content: string[];
  ticketTags: string[];
  ticketDiscipline: null | string;
  ticketReferences: TicketReference[];
  ticketRemainder: boolean;
}

export default interface Meeting {
  id: string;
  name: string;
  number: number | null;

  creator: number;
  editor: number;

  date: Date | string | null;
  created: Date | string;
  edited: Date | string;

  werk_id: number | null;
  project_id: number | null;

  present: number[];
  absent: number[];
  sections: MeetingSection[];

  next_meeting: string | null;
  previous_meeting: string | null;
}

/**
 * creat new minute
 * @param partial
 */
export function makeMeeting(
  partial: Partial<Meeting> & Pick<Meeting, 'creator'>,
): Meeting {
  const now = new Date();
  return {
    id: (partial && partial.id) || uuidv4(),
    number: 1,
    name: '<unnamed>',
    date: null,
    created: now,
    editor: partial.creator,
    edited: now,
    project_id: null,
    werk_id: null,
    present: [],
    absent: [],
    sections: [
      {
        content: [],
        ticketReferences: [],
        title: null,
        ticketTags: [],
        ticketDiscipline: null,
        ticketRemainder: false,
      },
    ],
    next_meeting: null,
    previous_meeting: null,
    ...partial,
  };
}
