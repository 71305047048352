import { DateTime } from 'luxon';
import prop from 'ramda/es/prop';
import reverse from 'ramda/es/reverse';
import sortBy from 'ramda/es/sortBy';
import { defineMessages } from 'react-intl';
import { parseDate } from '../common/logic/misc';
import pipe from 'ramda/es/pipe';

const msg = defineMessages({
  NEXT_MONTH: {
    id: 'NEXT_MONTH',
    defaultMessage: 'Next month',
    description: ''
  },
  THIS_MONTH: {
    id: 'THIS_MONTH',
    defaultMessage: 'This month',
    description: ''
  },
  NEXT_WEEK: {
    id: 'NEXT_WEEK',
    defaultMessage: 'Next week',
    description: ''
  },
  THIS_WEEK: {
    id: 'THIS_WEEK',
    defaultMessage: 'This week',
    description: ''
  },
  TOMORROW: {
    id: 'TOMORROW',
    defaultMessage: 'Tomorrow',
    description: ''
  },
  TODAY: {
    id: 'TODAY',
    defaultMessage: 'Today',
    description: ''
  },
  YESTERDAY: {
    id: 'YESTERDAY',
    defaultMessage: 'Yesterday',
    description: ''
  },
  LAST_WEEK: {
    id: 'LAST_WEEK',
    defaultMessage: 'Last week',
    description: ''
  },
  PREVIOUS_MONTH: {
    id: 'PREVIOUS_MONTH',
    defaultMessage: 'Previous month',
    description: ''
  },
  BEFORE_PREVIOUS_MONTH: {
    id: 'BEFORE_PREVIOUS_MONTH',
    defaultMessage: 'Before last month',
    description: ''
  }
});

export function heuristicChronicalGrouping(elements, options = {}) {
  const {
    getTimestamp = pipe (
      prop('timestamp'),
      parseDate
    )
  } = options;
  const now = DateTime.local();
  const groups = [
    {
      title: 'Volgende maand',
      desc: msg.NEXT_MONTH,
      upperBoundry: now
        .endOf('month')
        .plus({ months: 1 })
        .toJSDate(),
      lowerBoundry: now
        .startOf('month')
        .plus({ months: 1 })
        .toJSDate()
    },
    {
      title: 'Deze maand',
      desc: msg.THIS_MONTH,
      upperBoundry: now.endOf('month').toJSDate(),
      lowerBoundry: now
        .startOf('week')
        .plus({ week: 2 })
        .toJSDate()
    },
    {
      title: 'Volgende week',
      desc: msg.NEXT_WEEK,
      upperBoundry: now
        .endOf('week')
        .plus({ week: 1 })
        .toJSDate(),
      lowerBoundry: now
        .startOf('week')
        .plus({ week: 1 })
        .toJSDate()
    },
    {
      title: 'Deze week',
      desc: msg.THIS_WEEK,
      upperBoundry: now.endOf('week').toJSDate(),
      lowerBoundry: now
        .startOf('day')
        .plus({ day: 2 })
        .toJSDate()
    },
    {
      title: 'Morgen',
      desc: msg.TOMORROW,
      upperBoundry: now
        .endOf('day')
        .plus({ day: 1 })
        .toJSDate(),
      lowerBoundry: now
        .startOf('day')
        .plus({ day: 1 })
        .toJSDate()
    },
    {
      title: 'Vandaag',
      desc: msg.TODAY,
      distance: -1, // non falsy
      upperBoundry: now.endOf('day').toJSDate(),
      lowerBoundry: now.startOf('day').toJSDate()
    },
    {
      title: 'Gisteren',
      desc: msg.YESTERDAY,
      upperBoundry: now
        .endOf('day')
        .minus({ days: 1 })
        .toJSDate(),
      lowerBoundry: now
        .startOf('day')
        .minus({ days: 1 })
        .toJSDate()
    },
    {
      title: 'Deze week',
      desc: msg.THIS_WEEK,
      upperBoundry: now
        .endOf('day')
        .minus({ days: 2 })
        .toJSDate(),
      lowerBoundry: now.startOf('week').toJSDate()
    },
    {
      title: 'Vorige week',
      desc: msg.LAST_WEEK,
      upperBoundry: now
        .endOf('week')
        .minus({ weeks: 1 })
        .toJSDate(),
      lowerBoundry: now
        .startOf('week')
        .minus({ weeks: 1 })
        .toJSDate()
    },
    {
      title: 'Deze maand',
      desc: msg.THIS_MONTH,
      upperBoundry: now
        .endOf('week')
        .minus({ weeks: 2 })
        .toJSDate(),
      lowerBoundry: now.startOf('month').toJSDate()
    },
    {
      title: 'Vorige maand',
      desc: msg.PREVIOUS_MONTH,
      upperBoundry: now
        .endOf('month')
        .minus({ months: 1 })
        .toJSDate(),
      lowerBoundry: now
        .startOf('month')
        .minus({ months: 1 })
        .toJSDate()
    },
    {
      title: 'Voor vorige maand',
      desc: msg.BEFORE_PREVIOUS_MONTH,
      upperBoundry: now
        .endOf('month')
        .minus({ months: 2 })
        .toJSDate()
    }
  ];
  groups.forEach(g => (g.elements = []));
  groups.forEach(g => {
    g.lowerBoundry = g.lowerBoundry || -Infinity;
    g.upperBoundry = g.upperBoundry || Infinity;
  });
  groups.forEach(g => {
    g.distance =
      g.distance ||
      Math.min(Math.abs(g.lowerBoundry - now), Math.abs(g.upperBoundry - now));
  });
  for (const element of elements) {
    // find the group to put this in
    // find a group that matches upper and lower boundry and is closest to today

    const ts = getTimestamp(element); // offset little
    let closestGroup = null;
    for (const group of groups) {
      if (group.lowerBoundry < ts && group.upperBoundry > ts) {
        if (!closestGroup || group.distance < closestGroup.distance) {
          closestGroup = group;
        }
      }
    }
    if (closestGroup) {
      closestGroup.elements.push(element);
    }
  }
  return groups
    .filter(g => g.elements.length)
    .map(g => ({ ...g, elements: reverse(sortBy(getTimestamp, g.elements)) }));
}
