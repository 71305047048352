import { Parser } from '@json2csv/plainjs';
import classNames from 'classnames';
import { groupBy, partition } from 'lodash';
import { intersperse } from 'ramda';
import React from 'react';
import { Alert, Button } from 'react-bootstrap';
import QRCode from 'react-qr-code';
import { useLocation } from 'wouter';
import { formatCode } from '../../common/location/formatCode';
import formatPath from '../../common/location/formatPath';
import { useDispatchWithErrorNotifictions } from '../../hooks/useDispatchWithErrorNotifictions';
import {
  LocationWithDetails,
  markLocationsPrinted,
} from '../../modules/locations';
import { downloadFile } from '../../utils/downloadFile';
import groupArray from '../../utils/groupArray';
import Paper from '../Paper';
import { MakeTree } from '../Tree/common';
import stl from './LocationLabels.module.scss';

const numberLabelsPerPage = {
  a4: 1,
  klein: 8,
  middel: 3,
};

const locationQrUrl = (l: LocationWithDetails) =>
  `${window.location.origin}/qr/location/${l.id}`;

const Label: React.FC<{
  location: MakeTree<LocationWithDetails>;
  back?: boolean;
}> = ({ location, back = false }) => {
  const { stock } = location;
  const canvasElem = <QRCode value={locationQrUrl(location)} size={512} />;

  const backCode = (
    <span className={stl.backCode}>{formatCode(location).join('')}</span>
  );

  const artikel = stock?.length === 1 ? stock[0] : null;
  const artikelDetails =
    artikel && !location.name_replaces_path ? (
      <>
        <span className={stl.artikelName}>{artikel.description}</span>
        <span className={stl.artikelCode}>
          {artikel.subset}-{artikel.volgnummer}
        </span>
        {artikel.unsalable && <span className={stl.unsalable}>incourant</span>}
      </>
    ) : (
      <>
        <span className={stl.artikelName}>
          {location.name_replaces_path
            ? location.description
            : intersperse(
                <span className={stl.sep}> / </span>,
                formatPath(location).map(name => <span>{name}</span>),
              )}
        </span>
        <span className={stl.artikelCode}>{formatCode(location)}</span>
        {location.unsalable && <span className={stl.unsalable}>incourant</span>}
        {/* <span className={stl.locationDesc}>{location.description}</span> */}
      </>
    );

  return (
    <div
      className={classNames(
        stl[location.labeltype!],
        location.description && stl.hasLocationDescr,
        back && stl.back,
      )}
    >
      {back && backCode}
      {!back && canvasElem}
      {!back && artikelDetails}
    </div>
  );
};

interface LocationLabelsProps {
  locations: MakeTree<LocationWithDetails>[];
  showDialog?: boolean;
  onDone?: () => void;
}
/* FIXME: create a graphical wizard instead of showing a series of alerts-components as steps  */
const LocationLabels: React.FC<LocationLabelsProps> = ({
  locations,
  showDialog = false,
  onDone,
}) => {
  // split labels into downloadable (labeltype === 'download') and printable (the rest)
  const [downloadable, locationsByLabeltype] = React.useMemo(() => {
    const [downloadable, printable] = partition(
      locations,
      l => l.labeltype === 'download',
    );
    return [downloadable, Object.values(groupBy(printable, l => l.labeltype))];
  }, [locations]);

  const dispatchWithErr = useDispatchWithErrorNotifictions();
  const [, navigate] = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleMarkPrinted = React.useCallback(async () => {
    if (
      // eslint-disable-next-line no-restricted-globals
      confirm('Wil je alle geselecteerde locaties nu als geprint markeren?')
    ) {
      await dispatchWithErr(markLocationsPrinted(locations.map(l => l.id)));

      navigate(window.location.pathname.replace(/\/print-labels$/, ''), {
        replace: true,
      });
    }
  }, [dispatchWithErr, locations, navigate]);

  const handleDownloadCsV = React.useCallback(() => {
    if (downloadable) {
      const parser = new Parser({
        fields: [
          {
            label: 'code',
            value: (l: MakeTree<LocationWithDetails>) =>
              l.stock.length === 1
                ? `${l.stock[0].subset}-${l.stock[0].volgnummer}`
                : formatCode(l).join(''),
          },
          {
            label: 'name',
            value: (l: MakeTree<LocationWithDetails>) =>
              l.stock.length === 1
                ? l.stock[0].description
                : l.name_replaces_path
                ? l.description
                : formatPath(l).join(' / '),
          },
          { label: 'qr', value: locationQrUrl },
          'id',
          'unsalable',
        ] as (keyof LocationWithDetails)[],
      });
      const csv = parser.parse(downloadable);

      downloadFile('labels.csv', csv, 'text/csv');
    }
  }, [downloadable]);

  let step = 1;

  return (
    <Paper title="Magazijn labels" downloadLink="">
      {downloadable.length !== 0 && (
        <div className="container hidden-print">
          <Alert bsStyle="info clearfix">
            <Button
              bsStyle={'info'}
              className="pull-right"
              style={{ display: 'block' }}
              onClick={handleDownloadCsV}
            >
              Download
            </Button>
            <h4>Step {step++}</h4>
            <strong>{downloadable.length} labels</strong> are ready to download
            as CSV
          </Alert>
        </div>
      )}
      {locationsByLabeltype.length !== 0 && (
        <div className="container hidden-print">
          <Alert bsStyle="info clearfix">
            <Button
              bsStyle={'info'}
              className="pull-right"
              style={{ display: 'block' }}
              onClick={window.print}
            >
              Print
            </Button>
            <h4>Step {step++}</h4>
            <strong>{locationsByLabeltype.flat().length} labels</strong> are
            ready to be printed
          </Alert>
        </div>
      )}
      <div className="container hidden-print">
        <Alert bsStyle="info clearfix">
          <Button
            bsStyle={'info'}
            className="pull-right"
            style={{ display: 'block' }}
            onClick={handleMarkPrinted}
          >
            Mark and leave screen
          </Button>
          <h4>Step {step++}</h4>
          All <strong>{locations.length} labels</strong> can be marked as
          printed
        </Alert>
      </div>
      {locationsByLabeltype.flatMap(ls => {
        if (ls[0].labeltype !== 'a4') {
          return groupArray(
            ls,
            (numberLabelsPerPage as any)[ls[0].labeltype!],
          ).map((lss, idx) => (
            <React.Fragment key={idx}>
              <Paper.NewPage>
                {lss.map(l => (
                  <Label location={l} />
                ))}
              </Paper.NewPage>
              <Paper.NewPage>
                {lss.map(l => (
                  <Label back location={l} />
                ))}
              </Paper.NewPage>
            </React.Fragment>
          ));
        } else {
          return ls.map((l, idx) => (
            <React.Fragment key={idx}>
              <Paper.NewPage>
                <Label location={l} />
              </Paper.NewPage>
              <Paper.NewPage>
                <Label back location={l} />
              </Paper.NewPage>
            </React.Fragment>
          ));
        }
      })}
    </Paper>
  );
};
export default LocationLabels;
