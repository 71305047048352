import { curryN } from 'lodash/fp';
import { User } from '../../models/User';

export default function matchUserToTerm(person: User, value: string) {
  if (!value) {
    return true;
  }
  const lowerCaseValue = value.toLowerCase();
  return !!(
    (person.loginname &&
      person.loginname.toLowerCase().includes(lowerCaseValue)) ||
    (person.name && person.name.toLowerCase().includes(lowerCaseValue))
  );
}

export const curriedMatchUserToTerm = curryN(2, (term: string, user: User) =>
  matchUserToTerm(user, term),
);
