import { DateTime } from 'luxon';
import { Moment } from 'moment';
import { sortWith } from 'ramda';
import * as React from 'react';
import {
  Button,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  HelpBlock,
} from 'react-bootstrap';
import ReactDateTime from 'react-datetime';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import Types from 'Types';
import { ExcludeNull } from '../../common/logic/types';
import matchUserToTerm from '../../common/user/matchUserToTerm';
import sortUsers from '../../common/user/sortUsers';
import { Ticket } from '../../models/Ticket';
import { User } from '../../models/User';
import { LabelsState } from '../../modules/labels';
import { ProjectState } from '../../modules/projects';
import { StoreTicket } from '../../modules/tickets';
import { UserState } from '../../modules/user';
import { UsersState } from '../../modules/users';
import { WorkState } from '../../modules/works';
import { RenderCompletion } from '../../react-mde/components';
import { Lex } from '../../utils/LexicalParsing';
import InputList from '../InputList';
import LabelInput from '../Labels/LabelInput';
import CerthonMarkdownEditor from '../MarkdownEditor';
import WorkSelection from '../WorkSelection/index';
import msg from './msg';

export type TicketEditingFields = Pick<
  Ticket,
  | 'title'
  | 'description'
  | 'assignee_user_id'
  | 'due'
  | 'executor_user_id'
  | 'labels'
  | 'status'
  | 'project_id'
  | 'werk_id'
>;

interface TicketDetailsEditorProps {
  lexes: Lex[];
  onPatch: (partial: Partial<Ticket>) => void;
  onReset: () => void;
  onSubmit: () => void;
  projects: ExcludeNull<ProjectState>;
  ticket: TicketEditingFields;
  user: ExcludeNull<UserState>;
  users: ExcludeNull<UsersState>;
  works: ExcludeNull<WorkState>;
  renderCompletion?: RenderCompletion;
  localTickets: Record<string, StoreTicket>;
  allTickets: Record<string, StoreTicket>;
}
const TicketDetailsEditor: React.FC<TicketDetailsEditorProps> = ({
  lexes,
  onPatch,
  onReset,
  onSubmit,
  projects,
  ticket,
  user,
  users,
  works,
  allTickets,
  localTickets,
}) => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  React.useEffect(() => {
    inputRef.current?.focus();
    inputRef.current?.setSelectionRange(0, inputRef.current?.value.length);
  }, []);
  const availableLabels = useSelector<Types.RootState, LabelsState>(
    ({ labels }) => labels,
  );

  const [labelRecnums, setLabelRecnums] = React.useState<number[]>(
    () => ticket.labels,
  );

  const handleSubmit: React.FormEventHandler<Form> = React.useCallback(
    event => {
      event.preventDefault();
      onSubmit();
    },
    [onSubmit],
  );

  const handleChange = React.useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      onPatch({ [event.currentTarget.name]: event.currentTarget.value });
    },
    [onPatch],
  );

  const handleDescriptionChange = React.useCallback(
    (value: string) => {
      onPatch({ description: value });
    },
    [onPatch],
  );

  const handleChangeDate = React.useCallback(
    (m: Moment | string) => {
      onPatch({
        due:
          typeof m !== 'string'
            ? DateTime.fromJSDate(m.toDate())
                .endOf('day')
                .toJSDate()
            : null,
      });
    },
    [onPatch],
  );

  const handleLabelsChange = React.useCallback(
    (labelRecnums: number[]) => {
      setLabelRecnums(labelRecnums);
      onPatch({
        labels: labelRecnums,
      });
    },
    [onPatch],
  );

  const {
    title,
    description,
    werk_id,
    project_id,
    due,
    assignee_user_id,
    executor_user_id,
  } = ticket;

  let project = null;
  if (werk_id) {
    project = projects[works[werk_id].project_id!]; // FIXME: in QA there always is a project
  } else if (project_id) {
    project = projects[project_id];
  }

  const assignee = users[assignee_user_id!] || null;
  const executor = users[executor_user_id!] || null;

  const titleTooLong = !!title && title.length > 200;

  return (
    <div>
      <Form onSubmit={handleSubmit} onReset={onReset}>
        <WorkSelection
          selection={{
            projectId: project?.recnum || null,
            workId: werk_id || null,
          }}
          onSelectionChanged={({ projectId, workId }) => {
            onPatch({ project_id: projectId, werk_id: workId });
          }}
          inline
          disabled={!user.internal}
        />
        <FormGroup
          controlId="title"
          validationState={titleTooLong ? 'error' : undefined}
        >
          <ControlLabel>
            <FormattedMessage {...msg.FORM_TITLE} />
          </ControlLabel>
          <FormControl
            name="title"
            value={title}
            onChange={handleChange as any} // FormControl <> HTMLInputElement
            inputRef={inputRef as any}
          />
          {titleTooLong && <HelpBlock>Title is too long</HelpBlock>}
        </FormGroup>
        <FormGroup controlId="description">
          <ControlLabel>
            <FormattedMessage {...msg.FORM_DESCRIPTION} />
          </ControlLabel>
          <CerthonMarkdownEditor
            allTickets={allTickets}
            localTickets={localTickets}
            users={users}
            onChange={handleDescriptionChange}
            childProps={{
              textArea: {
                id: 'description',
                name: 'description',
              },
            }}
            lexes={lexes}
            value={description}
          />
        </FormGroup>
        <div className="row">
          <div className="col-sm-12">
            <FormGroup>
              <ControlLabel>Labels</ControlLabel>
              <LabelInput
                value={labelRecnums}
                labels={availableLabels}
                onChange={handleLabelsChange}
              />
            </FormGroup>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-4">
            <FormGroup>
              <ControlLabel>
                <FormattedMessage {...msg.FORM_ASSIGNED_TO} />
              </ControlLabel>

              <InputList
                items={sortWith(sortUsers, Object.values(users))}
                name={'assignee'}
                single={true}
                selectedItems={assignee ? [assignee] : []}
                shouldItemRender={matchUserToTerm}
                getItemValue={(user: User) => user.recnum.toString()}
                onSelectItem={(item: User) =>
                  onPatch({
                    assignee_user_id: item.recnum,
                  })
                }
                onDeselectItem={(item: User) =>
                  onPatch({
                    assignee_user_id: null,
                  })
                }
              />
            </FormGroup>
          </div>

          <div className="col-sm-4">
            <FormGroup>
              <ControlLabel>
                <FormattedMessage {...msg.FORM_EXECUTED_BY} />
              </ControlLabel>

              <InputList
                items={sortWith(sortUsers, Object.values(users))}
                name={'executor'}
                single={true}
                selectedItems={executor ? [executor] : []}
                shouldItemRender={matchUserToTerm}
                getItemValue={(user: User) => user.recnum.toString()}
                onSelectItem={(item: User) =>
                  onPatch({ executor_user_id: item.recnum })
                }
                onDeselectItem={(item: User) =>
                  onPatch({
                    executor_user_id: null,
                  })
                }
              />
            </FormGroup>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-4">
            <FormGroup>
              <ControlLabel>
                <FormattedMessage {...msg.FORM_DUE_DATE} />
              </ControlLabel>
              <ReactDateTime
                timeFormat={false}
                dateFormat="D MMMM YYYY"
                onChange={handleChangeDate}
                value={due!}
              />
            </FormGroup>
          </div>
        </div>
        <br />
        <Button
          disabled={!title || !(project_id || werk_id) || titleTooLong}
          className="pull-right"
          type="submit"
          bsStyle="primary"
        >
          <FormattedMessage {...msg.BUTTON_SAVE} />
        </Button>
        <Button type="reset">
          <FormattedMessage {...msg.BUTTON_CANCEL} />
        </Button>
      </Form>
    </div>
  );
};

export default TicketDetailsEditor;
