import { ascend, either, prop, sortWith } from 'ramda';
import * as React from 'react';
import { ListGroup } from 'react-bootstrap';
import { Dispatch } from 'redux';
import { replace } from 'redux-first-history';
import {
  AugmentedCatalogFile,
  Catalog,
  CatalogFile,
  CatalogGroup,
  CatalogStructureGroup,
} from '../../models/Catalog';
import { CatalogUI, toggleCatalogGroup } from '../../modules/catalogUI';
import {
  downloadFile,
  FileDownloadsState,
  FileDownloadState,
  openFile,
  OpenFileTarget,
} from '../../modules/fileDownloads';
import { FilesState } from '../../modules/files';
import FilterSummary from '../FilterSummary';
import { createCatalogStructure } from './catalog';
import CatalogFileComponent from './leaves/CatalogFileComponent';
import CatalogGroupComponent from './leaves/CatalogGroupComponent';

const sortCatalogStructureComponents: <T extends
  | CatalogStructureGroup
  | AugmentedCatalogFile>(
  l: T[],
) => Array<T> = sortWith([
  ascend(prop('ordering')),
  ascend(either(prop('duplicatenr'), () => 0 as any)),
]);

const CatalogStructureComponent: React.FC<{
  group: CatalogStructureGroup;
  depth?: number;
  catalogUI: CatalogUI;
  expandedFile?: number;
  toggleGroup: (groupId: number) => void;
  toggleFile: (file: AugmentedCatalogFile) => void;
  openFile: (file: CatalogFile, target?: OpenFileTarget, lang?: string) => void;
  online: boolean;
}> = ({ group, depth = 0, ...rest }) => {
  const close = React.useCallback(() => rest.toggleGroup(group.recnum), [
    rest,
    group.recnum,
  ]);

  const isClosed = rest.catalogUI.closedGroups.includes(group.recnum);
  return (
    <>
      <CatalogGroupComponent
        key={'g' + group.recnum}
        group={group}
        closed={isClosed}
        depth={depth}
        onToggleClosed={close}
      />
      {(!isClosed || group.containsDeepLinkFile) &&
        sortCatalogStructureComponents(
          group.groups.filter(
            g =>
              g.containsMatch !== false ||
              g.textSearchMatch !== false ||
              g.containsDeepLinkFile,
          ),
        ).map(g => (
          <CatalogStructureComponent
            key={g.recnum}
            group={g}
            depth={depth + 1}
            {...rest}
          />
        ))}
      {(!isClosed || group.containsDeepLinkFile) &&
        sortCatalogStructureComponents(
          group.files.filter(
            f => f.paramMatch !== false && f.textSearchMatch !== false,
          ),
        ).map(f => (
          <CatalogFileComponent
            expanded={f.recnum === rest.expandedFile}
            key={'f' + f.recnum}
            file={f}
            depth={depth + 1}
            onToggleExpanded={rest.toggleFile.bind(undefined, f, undefined)}
            {...rest}
          />
        ))}
    </>
  );
};

interface Props {
  catalog: Catalog;
  catalogUI: CatalogUI;
  expandedFile?: number;
  dispatch: Dispatch;
  files: FilesState;
  projectRecnum: number;
  fileDownloads: FileDownloadsState;
  catalogTextFilter: (fg: CatalogGroup | CatalogFile) => boolean | null;
  catalogParamFilter: (f: CatalogFile) => boolean | null;
  onClear?: () => void;
  onReset?: () => void;

  online: boolean;
}

const CatalogContents: React.FC<Props> = ({
  catalog,
  catalogUI,
  expandedFile,
  dispatch,
  files,
  fileDownloads,
  catalogTextFilter = () => null,
  catalogParamFilter = () => null,
  online,
  projectRecnum,
  onClear,
  onReset,
}) => {
  const [structure, visisbleFiles] = React.useMemo(() => {
    const struc = createCatalogStructure(
      catalog,
      files,
      fileDownloads,
      catalogTextFilter,
      catalogParamFilter,
      expandedFile,
    );
    const countVisibleFilesInGroup = (group: CatalogStructureGroup): number => {
      return (
        group.files.filter(
          f => f.paramMatch !== false && f.textSearchMatch !== false,
        ).length +
        group.groups.reduce((sum, g) => sum + countVisibleFilesInGroup(g), 0)
      );
    };
    let cnt = struc.reduce(
      (sum, group) => sum + countVisibleFilesInGroup(group),
      0,
    );
    return [struc, cnt];
  }, [
    catalog,
    files,
    fileDownloads,
    catalogTextFilter,
    catalogParamFilter,
    expandedFile,
  ]);

  const toggleGroup = React.useCallback(
    (groupId: number, closed: boolean | null = null) =>
      dispatch(toggleCatalogGroup(catalog.recnum, groupId, closed)),
    [catalog.recnum, dispatch],
  );
  const toggleFile = React.useCallback(
    (file: AugmentedCatalogFile, closed: boolean | null = null) => {
      if (
        !file.file &&
        file.mime === 'application/pdf' &&
        !(file.download?.state === FileDownloadState.FAILED)
      ) {
        dispatch(downloadFile(file));
      }
      let location;
      if (closed === false || file.recnum === expandedFile) {
        location = `/project/${projectRecnum}/docs`;
      } else {
        location = `/project/${projectRecnum}/docs/${file.recnum}`;
      }
      dispatch(replace(location));
    },
    [dispatch, expandedFile, projectRecnum],
  );
  const _openFile = React.useCallback(
    (file: CatalogFile, target?: OpenFileTarget, lang?: string) => {
      dispatch(openFile(file, target, lang));
    },
    [dispatch],
  );

  return (
    <>
      <ListGroup>
        {sortCatalogStructureComponents(
          structure.filter(
            g =>
              g.containsMatch !== false ||
              g.textSearchMatch !== false ||
              g.containsDeepLinkFile,
          ),
        ).map(g => (
          <CatalogStructureComponent
            key={g.recnum?.toString()}
            group={g}
            catalogUI={catalogUI}
            expandedFile={expandedFile}
            toggleFile={toggleFile}
            toggleGroup={toggleGroup}
            openFile={_openFile}
            online={online}
          />
        ))}
      </ListGroup>
      <FilterSummary
        total={catalog.files.length}
        visible={visisbleFiles}
        onClear={onClear}
        onReset={onReset}
        descriptive
        itemName="files"
      />
      {/* <pre>{JSON.stringify(structure, null, 2)}</pre> */}
    </>
  );
};

export default CatalogContents;
