import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import stl from './FilterSummary.module.scss';
interface Props {
  total: number;
  visible: number;
  itemName?: JSX.Element | string;
  descriptive?: boolean;
  onClear?: () => void;
  onReset?: () => void;
  postScript?: React.ReactNode;
}

const genericItemName = (
  <FormattedMessage
    id="FILTER_SUMMARY.GENERIC_ITEM_NAME_PLURAL"
    defaultMessage="items"
  />
);

const FilterSummary: React.FC<Props> = ({
  total,
  visible,
  onClear,
  onReset,
  descriptive = false,
  itemName = genericItemName,
  postScript,
}) => {
  let clearFilters = null;
  if (onClear) {
    clearFilters = (
      <button className="btn btn-link" onClick={onClear}>
        <FormattedMessage
          id="FILTER_SUMMARY.CLEAR_FILTERS"
          defaultMessage="clear"
        />
      </button>
    );
  }

  let resetFilters = null;
  if (onReset) {
    resetFilters = (
      <button className="btn btn-link" onClick={onReset}>
        <FormattedMessage
          id="FILTER_SUMMARY.RESET_FILTERS"
          defaultMessage="reset"
        />
      </button>
    );
  }

  let clearAndOrReset = null;
  if (descriptive) {
    if (clearFilters && resetFilters) {
      clearAndOrReset = (
        <FormattedMessage
          id="FILTER_SUMMARY.TWO_ACTIONS"
          defaultMessage="If the results are not what you expect you can {clearFilters} or {resetFilters} the filter."
          description="describe TWO available filter action"
          values={{ resetFilters, clearFilters }}
        />
      );
    } else if (clearFilters || resetFilters) {
      clearAndOrReset = (
        <FormattedMessage
          id="FILTER_SUMMARY.ONE_ACTION"
          defaultMessage="If the results are not what you expect you can {action} the filter."
          description="describe ONE available filter action"
          values={{ action: clearFilters || resetFilters }}
        />
      );
    }
  } else {
    if (clearFilters && resetFilters) {
      clearAndOrReset = (
        <FormattedMessage
          id="FILTER_SUMMARY.TWO_ACTIONS_SHORT"
          defaultMessage="{clearFilters} or {resetFilters} filter."
          description="describe TWO available filter action"
          values={{ resetFilters, clearFilters }}
        />
      );
    } else if (clearFilters || resetFilters) {
      clearAndOrReset = (
        <FormattedMessage
          id="FILTER_SUMMARY.ONE_ACTION_SHORT"
          defaultMessage="{action} filter."
          description="describe ONE available filter action"
          values={{ action: clearFilters || resetFilters }}
        />
      );
    }
  }

  return (
    <div className={stl.root}>
      <p>
        <FormattedMessage
          id="FILTER_SUMMARY"
          defaultMessage="{visible} of {total} {itemName} are displayed."
          values={{ total, visible, itemName }}
        />{' '}
        {clearAndOrReset} {postScript}
      </p>
    </div>
  );
};

export default FilterSummary;
