import { sortBy } from 'ramda';
import * as React from 'react';
import { Button, ListGroup, ListGroupItem } from 'react-bootstrap';
import { Dispatch } from 'redux';
import groupByClient from '../../common/groupByClient';
import { CurrentUser } from '../../modules/user';
import { associateWorkUser, Work } from '../../modules/works';
import DepartmentToken from '../DepartmentToken';
import styles from './WorksList.module.scss';
import sortWith from 'ramda/es/sortWith';
import descend from 'ramda/es/descend';
import prop from 'ramda/es/prop';
import ascend from 'ramda/es/ascend';
import LinkContainer from '../LinkContainer';
/* 
  😭
  KLANT <-> CLIENT  !!!!
 */

const sortWorks = sortWith<Work>([
  ascend(prop('afd')),
  ascend(w => (w.oms && w.oms.trim()) || w.id),
]);

const WorkItem: React.FC<{
  work: Work;
  user?: CurrentUser;
  dispatch: Dispatch;
}> = ({ work: { id, oms, recnum, users, afd }, user, dispatch }) => {
  const handleAssocitate = React.useCallback<React.MouseEventHandler<Button>>(
    event => {
      event.stopPropagation();
      event.preventDefault();
      if (!user) {
        return;
      }
      const action =
        users && users.includes(user.recnum) ? 'dissociate' : 'associate';
      dispatch(associateWorkUser(recnum, { [action]: user.recnum }) as any);
    },
    [recnum, dispatch, users, user],
  );

  return (
    <LinkContainer to={`/works/${recnum}`}>
      <ListGroupItem className={styles.root}>
        <div className={styles.title}>
          {users && user && user.internal && (
            <div className={styles.right}>
              <DepartmentToken value={afd} />

              <Button
                bsStyle="link"
                onClick={handleAssocitate}
                className="pull-right"
              >
                <i
                  className={`fa fa-${
                    users.includes(user.recnum) ? 'star' : 'star-o'
                  }`}
                />
              </Button>
            </div>
          )}
          {(oms && oms.trim()) || id}
        </div>
      </ListGroupItem>
    </LinkContainer>
  );
};

interface Props {
  dispatch: Dispatch;
  works: Work[];
  user?: CurrentUser;
}

const GroupedWorksList: React.FC<Props> = ({ works, dispatch, user }) => {
  const clientSections = groupByClient<Work>(works).map(
    ({ client, items: works, clientId }) => {
      return (
        <React.Fragment key={clientId}>
          <h4>{client}</h4>
          <WorksList works={works} dispatch={dispatch} user={user}></WorksList>
        </React.Fragment>
      );
    },
  );
  return <>{clientSections}</>;
};

export const WorksList: React.FC<Props> = ({ works, dispatch, user }) => (
  <ListGroup>
    {sortWorks(works).map(w => (
      <WorkItem key={w.recnum} work={w} user={user} dispatch={dispatch} />
    ))}
  </ListGroup>
);

export default GroupedWorksList;
