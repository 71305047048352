import PropTypes from 'prop-types';
import * as React from 'react';
import { Subscription } from 'rxjs';
import ListBuilder from '../common/list/listBuilder';
import { BuiltList } from '../models/List';
import List from './List';

interface Props {
  listId: string;
  children: (list: BuiltList | null) => React.ReactNode;
}
interface State {
  builtList: BuiltList | List | null;
}

export default class WithBuiltList extends React.Component<Props, State> {
  static contextTypes = {
    listBuilder: PropTypes.object.isRequired,
  };

  state = {
    builtList: null,
  };

  componentDidMount() {
    this.subscribe();
  }

  componentWillReceiveProps(newProps: Props) {
    if (newProps.listId !== this.props.listId) {
      this.subscribe(newProps.listId);
    }
  }

  private subscribe(listId?: string) {
    if (!listId) {
      listId = this.props.listId;
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.subscription = this.context.listBuilder
      .getList$(listId)
      .subscribe(builtList => this.setState({ builtList }));
  }

  context!: {
    listBuilder: ListBuilder;
  };
  subscription: Subscription | undefined;

  render() {
    return this.props.children(this.state.builtList);
  }
}
