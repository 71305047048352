import isEqual from 'lodash/fp/isEqual';
import pick from 'lodash/fp/pick';
import { Store } from 'redux';
import { combineLatest, ConnectableObservable, Observable, of } from 'rxjs';
import {
  distinctUntilChanged,
  map,
  pluck,
  publishBehavior,
  scan,
  switchAll,
} from 'rxjs/operators';
import Types from 'Types';
import { BuiltList, List } from '../../models/List';
import { mapById } from '../../utils';
import { ObjectMap } from '../logic/types';
import { initializeBuiltList } from './buildList';
import listModificationsReducer from './modifications/reducer';
import { isExpandProtoListModification } from './modifications/util';

const pickNonReducableListFields = pick<
  List | BuiltList,
  'associated_users' | 'tags' | 'werk_id' | 'name' | 'events'
>(['associated_users', 'tags', 'werk_id', 'name', 'events']);

// https://medium.com/@fahad19/streaming-redux-state-as-an-observable-with-rxjs-390a8f7bc08c
function getState$(store: Store): Observable<Types.RootState> {
  return new Observable(observer => {
    // emit the current state as first value:
    observer.next(store.getState());
    const unsubscribe = store.subscribe(() => {
      // emit on every new state changes
      observer.next(store.getState());
    });

    // let's return the function that will be called
    // when the Observable is unsubscribed
    return unsubscribe;
  });
}

/**
 * A listbuilder minimizes the amount of resources spent building lists by:
 *  - caching build results
 *  - building incrementally
 */
export default class ListBuilder {
  private incrementalBuildCache: WeakMap<
    object,
    Observable<BuiltList | null>
  > = new WeakMap();
  private cacheLookupTable: { [id: string]: {} } = {};

  private _lists$: Observable<ObjectMap<List>> | undefined;

  constructor(
    private store: Store,
    private _listModificationsReducer: typeof listModificationsReducer = listModificationsReducer,
  ) {}

  get lists$(): Observable<ObjectMap<List>> {
    if (!this._lists$) {
      this._lists$ = (getState$(this.store).pipe(pluck('lists')) as Observable<
        ObjectMap<List>
      >).pipe(distinctUntilChanged((a, b) => a === b));
    }
    return this._lists$;
  }

  /**
   * Creates a stream of incremental list builds as changes come in.
   * currently operates entirely synchronous.
   *
   * it returns either a list or a builtlist, this allows usibility in the future async implementation
   *
   * When webworkers become easily accessible in CRA we will be able to dispatch the build in an asynchronous fasion.
   * see: https://github.com/facebook/create-react-app/pull/5886
   *
   * @param listId The id of the list from the store to be built
   */
  getList$(listId: string): Observable<BuiltList | null> {
    let builtList$: Observable<BuiltList | null> | undefined;
    if (!this.cacheLookupTable[listId]) {
      this.cacheLookupTable[listId] = {};
    }
    builtList$ = this.incrementalBuildCache.get(this.cacheLookupTable[listId]);
    if (!builtList$) {
      builtList$ = this.createList$(listId);
      this.incrementalBuildCache.set(this.cacheLookupTable[listId], builtList$);
    }
    return builtList$;
  }

  createList$(listId: string): Observable<BuiltList | null> {
    const lists: ObjectMap<List> | null = this.store.getState().lists;
    if (lists === null || !lists[listId]) {
      throw new Error('Could not find list');
    }

    /*
      pipeline model:
       
       list$ ----------------------------------------------- builtList$ 
              \-- builtDependencies$ --/
     */
    // let ll: List;
    const list$: Observable<List> = this.lists$.pipe(
      pluck(listId),
      map(l => {
        // tslint:disable-next-line: no-console
        if (!l) {
          throw new Error('Did not receive list');
        }
        return l;
      }),
      distinctUntilChanged((x, y) => x === y),
    );

    const dependentBuiltLists$ = list$.pipe(
      pluck('events'),
      map(events =>
        events
          .filter(isExpandProtoListModification)
          .map(event => event.payload.protoListId),
      ),
      distinctUntilChanged<string[]>(isEqual),
      map(ids =>
        ids.length
          ? combineLatest(...ids.map(id => this.getList$(id)))
          : of({}),
      ),
      switchAll(),
      map(mapById),
    ) as Observable<ObjectMap<BuiltList>>;

    // const nonModListChanges$ = list$.pipe(
    //   map(pickNonReducableListFields),
    //   distinctUntilChanged(isEqual),
    // ) as Observable<Partial<List>>;

    const cacheBust$ = combineLatest(
      // bust cache when _modified flag becomes falsy to support "revert local changes"
      list$.pipe(
        scan<List, [number, string | false]>(
          ([flag, prev_modified], { _modified }) => {
            return [
              !!prev_modified && !_modified ? flag + 1 : flag,
              _modified || false,
            ];
          },
          [0, false],
        ),
        map(([flag]) => flag),
      ),
      // bust cache when the list is updated from the server (modified)
      // NOTE: _persist was also set to bust the cache, not sure why, have removed this
      list$.pipe(map(l => [l.modified])),
    ).pipe(distinctUntilChanged(isEqual));

    const result = combineLatest(dependentBuiltLists$, cacheBust$).pipe(
      map(([dependentBuiltLists]) => {
        let modIdx = 0;
        return list$.pipe(
          scan<List, BuiltList | null>((builtList, list) => {
            const newModifications = list.events.slice(modIdx);
            modIdx = list.events.length;
            const result = {
              ...newModifications.reduce(
                this._listModificationsReducer,
                builtList || initializeBuiltList(list, dependentBuiltLists),
              ),
              ...pickNonReducableListFields(list),
            };
            return result;
          }, null),
        );
      }),
      switchAll(),
      publishBehavior<BuiltList | null>(null),
    ) as ConnectableObservable<null | BuiltList>;

    result.connect();

    return result;
  }
}
