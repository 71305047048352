import PropTypes from 'prop-types';
import { identity, prop, reverse, sortBy, values } from 'ramda';
import * as React from 'react';
import { connect } from 'react-redux';
import { isListClosed } from '../../common/logic/list';
import {
  createActivityIterator,
  takeFromIterable,
} from '../../common/logic/misc';
import { isUserInvolvedWithTicket } from '../../common/logic/ticket';
import MyDashboardComponent from '../../components/MyDashboardComponent/index';
import { fetchLists } from '../../modules/lists';
import { fetchProjects } from '../../modules/projects';
import { fetchTickets } from '../../modules/tickets';
import { fetchUsers } from '../../modules/users';
import { fetchWorks } from '../../modules/works';
import ignoreRejection from '../../utils/ignoreRejection';

export class MyDashboardContainerComponent extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  componentDidMount() {
    ignoreRejection(
      this.props.dispatch(fetchWorks()),
      this.props.dispatch(fetchProjects()),
      this.props.dispatch(fetchUsers()),
      this.props.dispatch(fetchLists()),
      this.props.dispatch(fetchTickets()),
    );
  }

  render() {
    const {
      works,
      dispatch,
      users,
      lists,
      user,
      tickets,
      projects,
    } = this.props;

    const assProjectIds = values(projects).filter(p =>
      p.users?.includes(user.recnum),
    );

    const assWorkIds = values(works)
      .filter(
        w =>
          w.users?.includes(user.recnum) ||
          assProjectIds.includes(w.project_id),
      )
      .map(prop('recnum'));

    const filterredTickets = values(tickets)
      .filter(t => t.status === 'opened')
      .filter(
        t =>
          t.id && // filter out non-tickets (redux persist crap)
          (assWorkIds.includes(t.werk_id) ||
            assProjectIds.includes(t.project_id) ||
            isUserInvolvedWithTicket(user.recnum, t)),
      );

    const filterredChecklists = values(lists)
      .filter(list => !!list.werk_id)
      .filter(list => !list.deleted)
      .filter(
        list =>
          list.creator_user_id === user.recnum ||
          assWorkIds.includes(list.werk_id) ||
          (list.associated_users &&
            list.associated_users.some(u => u === user.recnum)), // tslint:disable-line
      );

    let activity = false;
    try {
      const activityIterator = createActivityIterator({
        lists: filterredChecklists,
        tickets: filterredTickets,
      });
      activity = takeFromIterable(10, activityIterator);
    } catch (error) {} //tslint:disable-line

    return (
      <MyDashboardComponent
        activity={activity}
        filteredTickets={reverse(
          sortBy(
            prop('created'),
            filterredTickets.filter(t => t.status === 'opened'),
          ),
        )}
        tickets={tickets}
        checklists={sortBy(
          prop('end_date'),
          filterredChecklists.filter(cl => !isListClosed(cl)),
        )}
        lists={lists}
        users={users}
        dispatch={dispatch}
        works={works}
      />
    );
  }
}

export default connect(identity)(MyDashboardContainerComponent);
