import localForage from 'localforage';
import * as R from 'ramda';
import { attachmentStorage } from '../services';

export const dropDataOnSignOut = reducer => (state, action) => {
  if (action.type === 'SIGN_OUT') {
    // clear all data exwhen the user signs out
    state = R.pick(['online'], state); // eslint-disable-line
    // XXX: side effects !!! - implement as service
    document.cookie =
      'authorization=null; expires=' +
      new Date(Date.now() - 30e3).toUTCString();

    dropDatabases();
  }
  return reducer(state, action);
};

async function dropDatabases() {
  await attachmentStorage.drop();
  await localForage.dropInstance();
  await localForage.dropInstance({ name: 'files' });
  await localForage.dropInstance({ name: 'file-previews' });
  window.location.reload();
}
