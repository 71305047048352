import classNames from 'classnames';
import * as React from 'react';
import { Button } from 'react-bootstrap';
import DateTime from 'react-datetime';
import { FormattedDate } from 'react-intl';
import { Link } from 'wouter';
import { parseDateOrNull } from '../../common/logic/misc';
import Meeting from '../../models/Meeting';
import { User } from '../../models/User';
import AttendeesControl from './AttendeesControl';
import stl from './Minutes.module.scss';

export interface UsersDict {
  [recnum: string]: User | undefined;
}

export interface MeetingsMetaFormProps
  extends Pick<
    Meeting,
    | 'absent'
    | 'date'
    | 'id'
    | 'name'
    | 'next_meeting'
    | 'number'
    | 'present'
    | 'previous_meeting'
  > {
  hasBegun: boolean;
  users: UsersDict;
  onChange(partial: Partial<Meeting>): void;
  onScheduleNextMeeting(): void;
}
interface State {
  editDate: Date | null;
  editingDate: boolean;
}
const DateTimeAny: any = DateTime;
// const createToken = curry((users: UsersDict, recnum: number | string) => {
//   const user = users[recnum];
//   if (!user) {
//     return null;
//   }
//   return (
//     <UserToken
//       key={recnum}
//       className={stl.userToken}
//       user={user}
//       small={true}
//       withText={true}
//       collapse={false}
//     />
//   );
// });

export default class MeetingsMetaForm extends React.PureComponent<
  MeetingsMetaFormProps,
  State
> {
  dateInputRef = React.createRef<HTMLInputElement>();
  constructor(props: MeetingsMetaFormProps) {
    super(props);
    this.state = { editDate: null, editingDate: false };
  }
  editName = () => {
    const name = prompt('Update meeting name', this.props.name);
    if (name && name !== this.props.name) {
      this.props.onChange({ id: this.props.id, name });
    }
  };
  editDate = () => {
    this.setState({
      editDate: parseDateOrNull(this.props.date),
      editingDate: true,
    });
  };

  handleChangeDate = (m: any) => {
    this.setState({ editDate: (m && m.toDate && m.toDate()) || null });
  };
  handleDateSubmit = () => {
    if (this.state.editingDate) {
      this.props.onChange({
        id: this.props.id,
        date: this.state.editDate,
      });
      this.setState({ editDate: null, editingDate: false });
    }
  };
  handleDateReset = () => {
    if (this.state.editDate) {
      this.setState({ editDate: null, editingDate: false });
    }
  };

  handleChangeAttendees = (attendees: Pick<Meeting, 'present' | 'absent'>) => {
    this.props.onChange({ ...attendees, id: this.props.id });
  };
  componentDidUpdate(prevProps: MeetingsMetaFormProps, prevState: State) {
    if (
      !prevState.editingDate &&
      this.state.editingDate &&
      this.dateInputRef.current
    ) {
      this.dateInputRef.current.focus();
    }
  }

  render() {
    const { date, name, present, absent, number } = this.props;
    // const labels = [
    //   <div key="1" className={stl.label} style={{ backgroundColor: 'red' }}>
    //     maandelijks
    //   </div>,
    //   <div key="2" className={stl.label} style={{ backgroundColor: 'blue' }}>
    //     bouwvergadering
    //   </div>,
    // ];
    // const presentUsers = present.map(createToken(this.props.users));
    // const absentUsers = absent.map(createToken(this.props.users));
    return (
      <div className={stl.metaform}>
        <Field
          key="Name"
          label="Name"
          className={stl.nameField}
          onClick={this.editName}
        >
          {name}
          <i className="fa fa-pencil" />
        </Field>
        <Field
          key="Date"
          label="Date"
          className={stl.nameField}
          onClick={(!this.state.editingDate && this.editDate) || undefined}
        >
          {this.state.editingDate ? (
            <form
              onSubmit={this.handleDateSubmit}
              onReset={this.handleDateReset}
            >
              <DateTimeAny
                // tslint:disable-next-line: jsx-no-lambda
                renderInput={(props: any) => (
                  <input ref={this.dateInputRef} {...props} />
                )}
                timeFormat={false}
                dateFormat="D MMMM YYYY"
                onChange={this.handleChangeDate}
                value={this.state.editDate}
                onBlur={this.handleDateSubmit}
                // viewDate={this.state.editDate || new Date()}
              />
            </form>
          ) : (
            <span>
              {date ? <FormattedDate value={date} /> : '-'}{' '}
              <i className="fa fa-pencil" />
            </span>
          )}
        </Field>

        <Field key="placeholder" label="" className="visible-print">
          {/* FIX: fix the print placeholder more neatly */}{' '}
        </Field>
        <Field
          key="previous meeting"
          label="Previous meeting"
          className="hidden-print"
        >
          {this.props.previous_meeting ? (
            <Link to={`/meeting/${this.props.previous_meeting}`}>
              ← previous meeting
            </Link>
          ) : (
            <small className="text-muted text-light">No previous meeting</small>
          )}
          {/* {labels} */}
        </Field>
        <Field key="Present" label="Present">
          <AttendeesControl
            present={present}
            absent={absent}
            users={this.props.users}
            display="present"
            onChange={this.handleChangeAttendees}
          />
        </Field>
        <Field key="Absent" label="Absent">
          <AttendeesControl
            present={present}
            absent={absent}
            users={this.props.users}
            display="absent"
            onChange={this.handleChangeAttendees}
          />
        </Field>
        {this.props.hasBegun && (
          <Field
            key="next meeting"
            label="Next meeting"
            className="hidden-print"
          >
            {this.props.next_meeting ? (
              <Link to={`/meeting/${this.props.next_meeting}`}>
                next meeting →
              </Link>
            ) : (
              <>
                <small className="text-muted text-light">No next meeting</small>
                <Button
                  className={stl.nextMeeting}
                  bsStyle="link"
                  bsSize="small"
                  onClick={this.props.onScheduleNextMeeting}
                >
                  Schedule next meeting now →
                </Button>
              </>
            )}
          </Field>
        )}
      </div>
    );
  }
}

interface FieldProps {
  label: string;
  children: React.ReactNode;
  className?: string;
  onClick?(): void;
}

const Field: React.FC<FieldProps> = props => {
  return (
    <div
      className={classNames(stl.field, props.className)}
      onClick={props.onClick}
    >
      <label>{props.label}</label>
      {props.children}
    </div>
  );
};
