import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import Markdown from '../Markdown';
import msg from './msg';
import styles from './Tickets.module.scss';
import { Ticket } from '../../models/Ticket';
import { ExcludeNull } from '../../common/logic/types';
import { UsersState } from '../../modules/users';
import { StoreTicket } from '../../modules/tickets';

/**
 * Renders either a Markdown component or a text describing that there is no description
 */
const TicketDescription: React.FC<{
  description: string;
  localTickets: Record<string, StoreTicket>;
  allTickets: Record<string, StoreTicket>;
  users: ExcludeNull<UsersState>;
}> = ({ description, allTickets, localTickets, users }) =>
  description.trim() ? (
    <Markdown allTickets={allTickets} localTickets={localTickets} users={users}>
      {description}
    </Markdown>
  ) : (
    <small className={styles.noDescription}>
      <FormattedMessage {...msg.NO_DESCRIPTION} />
    </small>
  );

export default TicketDescription;
