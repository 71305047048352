import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Alert, Button, Col, Grid } from 'react-bootstrap';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { goBack } from 'redux-first-history';
import { Link } from 'wouter';
import {
  closeList,
  doesListHaveUnsavedModifications,
  openList,
} from '../../common/list';
import { buildListAndDependencies } from '../../common/list/buildList';
import { isListClosed, isListComplete } from '../../common/logic/list';
import { popOutField } from '../../modules/editor';
import {
  deleteList,
  fetchLists,
  modifyList,
  updateList,
} from '../../modules/lists';
import { toggleShowListDetails } from '../../modules/ui';
import { fetchUsers } from '../../modules/users';
import { fetchWorks } from '../../modules/works';
import { diffObjects } from '../../utils';
import { WorkDetailsChildBreadcrumb } from '../BreadCrumbs';
import FieldPopoutView from '../FieldPopoutView';
import LinkContainer from '../LinkContainer';
import List from '../List';
import ChecklistPropertyDialog from '../ListsOverview/ChecklistPropertyDialog';
import Page from '../Page';
import Spinner from '../Spinner';
import CloseButton from './CloseButton';
import styles from './ListDetails.module.scss';
import ListDetailsDetails from './ListDetailsDetails';

const msg = defineMessages({
  confirmDelete: {
    id: 'LIST_DETAILS.CONFIRM.DELETE',
    defaultMessage: 'Do you want to delete this list?',
  },
  confirmFinalize: {
    id: 'LIST_DETAILS.CONFIRM_FINALIZE',
    defaultMessage: `This checklist has not been completely filled in.
Do you wish to finalize it any way?`,
  },
});

export class ListDetails extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    lists: PropTypes.object,
    editor: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      showCreateListPanel: false,
      expandProtoTargetFieldId: null,
    };
  }

  componentWillMount() {
    const {
      dispatch,
      params: { listId },
    } = this.props;

    dispatch(fetchLists({ id: listId }));
    dispatch(fetchWorks());
    dispatch(fetchUsers());
    if (window.location.hash) {
      dispatch(popOutField(window.location.hash.substr(1)));
    }
  }

  handleToggleClosed = () => {
    const {
      lists,
      dispatch,
      user,
      intl,
      params: { listId },
    } = this.props;
    const list = lists[listId];

    if (!user || !user.recnum) {
      throw new Error('expected user.recnum');
    }

    if (
      !isListClosed(list) &&
      !isListComplete(buildListAndDependencies(list, lists)) &&
      // eslint-disable-next-line no-restricted-globals
      !confirm(intl.formatMessage(msg.confirmFinalize))
    ) {
      return;
    }

    let mod;
    if (isListClosed(list)) {
      mod = openList({}, user.recnum);
    } else {
      mod = closeList({}, user.recnum);
    }

    dispatch(modifyList(list.id, mod, true));
  };

  toggleDetails = () => {
    this.props.dispatch(toggleShowListDetails());
  };

  editListProperties = () => {
    this.setState({ showCreateListPanel: true });
  };

  handleCancel = () => {
    this.setState({ showCreateListPanel: false });
  };

  handleSubmit = async list => {
    const {
      dispatch,
      lists,
      user,
      params: { listId },
    } = this.props;
    const origList = lists[listId];

    const action = updateList(listId, diffObjects(origList, list), user.recnum);
    // persist list
    await dispatch(action);
    await dispatch(fetchWorks());

    // close panel
    this.setState({ showCreateListPanel: false });
  };

  handleDeleteList = async () => {
    const {
      params: { listId },
      dispatch,
      intl,
    } = this.props;
    // eslint-disable-next-line no-restricted-globals
    if (confirm(intl.formatMessage(msg.confirmDelete))) {
      await dispatch(deleteList(listId));
      dispatch(goBack());
    }
  };

  render() {
    const {
      lists,
      dispatch,
      user,
      works,
      attachments,
      users,
      ui,
      projects,
      params: { listId, nodeId },
    } = this.props;
    const { showCreateListPanel } = this.state;
    const { showListDetails: open } = ui;

    let list;
    let builtList;
    let content;
    let work;
    let listIsClosed;
    let project = null;

    if (works && lists && lists[listId]) {
      list = lists[listId];
      work = works[list.werk_id];
      listIsClosed = isListClosed(list);
      builtList = buildListAndDependencies(list, lists);
      if (projects && work) {
        project = projects[work.project_id];
      }
    }

    if (list && work) {
      const projectName = project && (
        <>
          {project.projectname} {project.id && <em>- {project.id}</em>}
        </>
      );

      let listHasUnsavedChanges = doesListHaveUnsavedModifications(list);

      const workName = (
        <>
          {work.id} - {work.klant}
        </>
      );
      content = (
        <div>
          <WorkDetailsChildBreadcrumb
            projectId={project.recnum}
            workId={work.recnum}
            workName={workName}
            projectName={projectName}
            name={list.name}
          />
          <Grid>
            <Col>
              {listHasUnsavedChanges && (
                <Alert bsStyle="warning">
                  This list has unsaved changes. Click edit questions and then
                  save or revert changes.
                </Alert>
              )}

              <div className={styles.infoSection}>
                <CloseButton
                  closed={listIsClosed}
                  onClick={this.handleToggleClosed}
                />
                <LinkContainer to={`/list/${list.id}/report`}>
                  <Button
                    disabled={listHasUnsavedChanges}
                    className="pull-right hidden-xs"
                    bsStyle="primary"
                  >
                    <i className="fa fa-file-o" />
                    <span className="hidden-xs">
                      {' '}
                      <FormattedMessage
                        id="REPORT"
                        defaultMessage="Report"
                        description=""
                      />
                    </span>
                  </Button>
                </LinkContainer>
                <h3>{list.name}</h3>
              </div>
              <Button
                bsSize="small"
                bsStyle="link"
                onClick={this.toggleDetails}
              >
                {open ? (
                  <FormattedMessage
                    id="HIDE_DETAILS"
                    defaultMessage="▼ Hide details"
                    description=""
                  />
                ) : (
                  <FormattedMessage
                    id="SHOW_DETAILS"
                    defaultMessage="▶︎ Show details"
                    description=""
                  />
                )}
              </Button>
            </Col>
          </Grid>

          <ListDetailsDetails
            onEditDetails={this.editListProperties}
            onDeleteList={this.handleDeleteList}
            onCancel={this.handleCancel}
            onSubmit={this.handleSubmit}
            list={list}
            builtList={builtList}
            users={users}
          />
          <Grid>
            <Col>
              <List
                title={false}
                list={list}
                tree={builtList.tree}
                dispatch={dispatch}
                user={user}
                users={users}
                onRequestExpandProto={this.handleRequestExpandProto}
                attachments={attachments}
              />
            </Col>
          </Grid>
          <FieldPopoutView
            list={list}
            user={user}
            users={users}
            dispatch={dispatch}
            fieldId={nodeId}
            builtList={builtList}
            attachments={attachments}
          />
          <ChecklistPropertyDialog
            list={list || null}
            dispatch={dispatch}
            show={showCreateListPanel}
            lists={lists}
            users={users}
            works={works}
            onCancel={this.handleCancel}
            onSubmit={this.handleSubmit}
          />
        </div>
      );
    } else if (lists && works && !list) {
      content = (
        <center>
          <p>
            <FormattedMessage
              id="THE_CHECKLIST_COULD_NOT_BE_FOUND"
              defaultMessage="The checklist could not be found"
              description=""
            />
            ;{' '}
            <Link to="/projects">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a>
                <FormattedMessage
                  id="RETURN_TO_OVERVIEW"
                  defaultMessage="Return home"
                  description=""
                />
              </a>
            </Link>{' '}
          </p>
        </center>
      );
    } else {
      content = <Spinner />;
    }

    return (
      <Page {...this.props} title="Controlelijst" lists={lists}>
        {content}
      </Page>
    );
  }
}

export default injectIntl(connect(x => x)(ListDetails));
