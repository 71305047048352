import { propEq } from 'ramda';
import * as React from 'react';
import stl from './FieldAnnotations.module.scss';
export interface FieldAnnotationsProps {
  field: any;
}

const FieldAnnotations: React.FC<FieldAnnotationsProps> = ({ field }) => {
  const hasComments =
    field.attachments &&
    !!field.attachments.filter(propEq('type', 'note')).length;
  const hasAttachments =
    field.attachments &&
    !!field.attachments.filter(propEq('type', 'attachment')).length;
  const hasGeotag =
    field.attachments &&
    !!field.attachments.filter(propEq('type', 'geotag')).length;
  return (
    <div className={stl.root}>
      {hasComments && <i className="fa fa-comment-o" />}
      {hasAttachments && <i className="fa fa-paperclip" />}
      {hasGeotag && <i className="fa fa-map-marker" />}
    </div>
  );
};

export default FieldAnnotations;
