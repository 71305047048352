import { Location } from '@certhon/domain-models/lib';
import { groupBy, sortBy } from 'lodash';

export function findChildren<T extends Location>(
  t: Location,
  parent: MakeTree<T> | null = null,
  parentIndex: Record<string, T[]>,
): MakeTree<T> {
  const me: MakeTree<T> = {
    ...t,
    parent,
    // children: parentIndex[t.id] || [],
  } as any;

  me.children = sortBy(
    (parentIndex[t.id] || []).map(l => findChildren(l, me as any, parentIndex)),
    ['ordering'],
  );

  return me;
}

export function constructTree<T extends Location>(
  locations: T[],
): MakeTree<T>[] {
  const parentIndex = groupBy(locations, 'parent_location_id');
  return sortBy(parentIndex['null'], ['ordering']).map(l =>
    findChildren(l, null, parentIndex),
  );
}

export const firstCommonElement = <T>(arr1: T[], arr2: T[]) =>
  arr1.find(e => arr2.includes(e));

export type MakeTree<T> = T & {
  children: MakeTree<T>[];
  parent: MakeTree<T> | null;
};

export type TreeStructure = MakeTree<{
  id: any;
  name: string;
}>;

export function makeTreeIndex<T extends { id: any }>(
  elements: MakeTree<T>[],
  index: { [id: string]: MakeTree<T> } = {},
): { [id: string]: MakeTree<T> } {
  for (const element of elements) {
    // index elements
    index[element.id] = element;

    // index children
    makeTreeIndex(element.children, index);
  }

  return index;
}

// FIXME: use generics
export function getChildren<T>(element: MakeTree<T>): MakeTree<T>[] {
  return [...element.children, ...element.children.map(getChildren).flat()];
}
