import React from 'react';
import AutoComplete from 'react-autocomplete';
import { LabelsState } from '../../../modules/labels';
import LabelToken from '../LabelToken';
import { Label } from '@certhon/domain-models/lib';
import { without } from 'ramda';
import styles from './LabelInput.module.scss';
import classNames from 'classnames';

interface Props {
  labels: LabelsState;
  value: number[];
  onChange: (recnums: number[]) => void;
}

const LabelInput: React.FC<Props> = ({ labels, value, onChange }) => {
  const [val, setVal] = React.useState('');

  const handleSelect = React.useCallback(
    (_: string, label: Label) => {
      setVal('');
      onChange([...value, label.recnum]);
    },
    [onChange, value],
  );

  if (!labels) {
    return null;
  }

  const tokens = value
    .map(recnum => labels[recnum])
    .filter(x => x)
    .map(l => (
      <LabelToken
        onRemove={() => onChange(without([l!.recnum], value))}
        label={l!}
        key={l!.recnum}
      />
    ));

  return (
    <div className={classNames('form-control', styles.root)}>
      {tokens}
      <AutoComplete
        onChange={(e, v) => setVal(v)}
        onSelect={handleSelect}
        items={Object.values(labels).filter(l => !value.includes(l!.recnum))}
        renderItem={(i, highlighted) => (
          <li key={i.recnum} className={highlighted ? styles.highlight : ''}>
            <a>
              <LabelToken label={i} />
            </a>
          </li>
        )}
        value={val}
        shouldItemRender={(label, val) =>
          label.name.toLowerCase().includes(val.toLowerCase())
        }
        renderMenu={children => (
          <ul className={`dropdown-menu ${styles.dropdown}`}>{children}</ul>
        )}
        // renderInput={props => <input {...props} />}
        getItemValue={l => l.name.toString()}
        wrapperProps={{
          className: 'dropdown open ' + styles.wrapper,
          style: { display: 'block' },
        }}
      />
    </div>
  );
};

export default LabelInput;
