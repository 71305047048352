import { defineMessages } from 'react-intl';

export const msg = defineMessages({
  signOutWarnContent: {
    id: 'SIGN_OUT.CONTENT_WARNING',
    defaultMessage:
      'Not all local content has been synced. This CONTENT WILL BE LOST if you sign out!\n\n Do you really want to sign out?',
  },
  signOutWarnOffline: {
    id: 'SIGN_OUT.OFFLINE_WARNING',
    defaultMessage:
      "The backend is not reachabe. If you sign out now, you won't be able to sign back in until the backend is reachable\n\n Do you really want to sign out?",
  },
  inventoryLocations: {
    id: 'NAVIGATION.INVENTORY_LOCATIONS',
    defineMessage: 'Locations',
  },
  inventorySearch: {
    id: 'NAVIGATION.INVENTORY_SEARCH',
    defineMessage: 'Search',
  },
  inventoryOrders: {
    id: 'NAVIGATION.INVENTORY_ORDERS',
    defineMessage: 'Inventory Orders',
  },
  countingRequests: {
    id: 'NAVIGATION.COUNTING_REQUESTS',
    defineMessage: 'Counting Requests',
  },
});
