import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Button, ButtonGroup } from 'react-bootstrap';

import styles from './CheckpointControl.module.scss';

export default class CheckpointControl extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any,
    disabled: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    value: null,
    disabled: false,
  }

  handleGoodTouch = (event) => {
    event.stopPropagation();
    const { value, onChange } = this.props;
    onChange(value === true ? null : true);
  }

  handleBadTouch = (event) => {
    event.stopPropagation();
    const { value, onChange } = this.props;
    onChange(value === false ? null : false);
  }

  render() {
    const { value, disabled } = this.props;

    return (
      <ButtonGroup className={`${styles.control} pull-right`} bsSize='small'>
        <Button
          disabled={disabled}
          className={`good ${value === true ? 'selected' : ''}`}
          onClick={this.handleGoodTouch}
        >✓</Button>
        <Button
          disabled={disabled}
          className={`bad ${value === false ? 'selected' : ''}`}
          onClick={this.handleBadTouch}
        >✗</Button>
      </ButtonGroup>
    );
  }
}
