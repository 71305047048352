import { map, mergeDeepRight } from 'ramda';
import { softAssertNever } from './utils';

export enum SyncStage {
  POST_ATTACHMENTS,
  POST_LISTS,
  POST_TICKETS,
  POST_FEEDBACK,
  POST_MINUTES,
  GET_ME,
  GET_LABELS,
  GET_USERS,
  GET_PROJECTS,
  GET_WORKS,
  GET_TICKETS,
  GET_LISTS,
  GET_CATALOGS,
}

/** array to conveniently loop over all stages chonologically */
export const allSyncStages = Object.values(SyncStage).filter(
  v => typeof v === 'number',
) as readonly SyncStage[];

export enum SyncStatus {
  STATUS_NONE,
  STATUS_INITIALIZING,
  STATUS_BUSY,
  STATUS_FAILED,
  STATUS_DONE,
  STATUS_SKIPPED,
}

interface SyncStartAction {
  type: 'SYNC_START';
  payload: { date: Date };
}
interface SyncUpdateAction {
  type: 'SYNC_UPDATE';
  payload: {
    status?: SyncStatus;
    stages: { [key in SyncStage]?: Partial<Stage> };
  };
}
interface SyncIncreaseProgressAction {
  type: 'SYNC_INCREASE_PROGRESS';
  payload: {
    stage: SyncStage;
    /** Relative progress to be increased */
    progress: number;
  };
}
interface SyncDoneAction {
  type: 'SYNC_DONE';
  payload: {
    date: Date;
    full: boolean;
  };
}
interface SyncFailedAction {
  type: 'SYNC_FAILED';
  payload: {
    date: Date;
  };
}
interface ForceSyncAction {
  type: 'FORCE_SYNC';
}
export interface Stage {
  stage: SyncStage;
  status: SyncStatus;
  tasksProgress: null | number;
  tasksTotal: null | number;
}
export interface SyncStatusState {
  stages: Stages;
  status: SyncStatus;
  lastAttempt: Date | null;
  lastSynced: Date | null;
  lastFullSynced: Date | null;
}

type Stages = { [key in SyncStage]: Stage };

type SyncStatusAction =
  | SyncStartAction
  | SyncUpdateAction
  | SyncIncreaseProgressAction
  | SyncDoneAction
  | SyncFailedAction
  | ForceSyncAction;

export function blankStages() {
  let stages: Stages = {} as any;
  for (const stage of allSyncStages) {
    stages[stage] = {
      stage: stage,
      status: SyncStatus.STATUS_NONE,
      tasksProgress: null,
      tasksTotal: null,
    };
  }

  return stages;
}
const initialState: SyncStatusState = {
  stages: blankStages(),
  status: SyncStatus.STATUS_NONE,
  lastAttempt: null,
  lastFullSynced: null,
  lastSynced: null,
};

export function syncStart(): SyncStartAction {
  return { type: 'SYNC_START', payload: { date: new Date() } };
}

export function syncUpdate(
  payload: SyncUpdateAction['payload'],
): SyncUpdateAction {
  return {
    type: 'SYNC_UPDATE',
    payload,
  };
}

export function syncProgress(
  stage: SyncStage,
  progress: number,
): SyncIncreaseProgressAction {
  return {
    type: 'SYNC_INCREASE_PROGRESS',
    payload: {
      stage,
      progress,
    },
  };
}

export function syncDone(full: boolean = false): SyncDoneAction {
  return {
    type: 'SYNC_DONE',
    payload: {
      date: new Date(),
      full,
    },
  };
}

export function syncFailed(): SyncFailedAction {
  return {
    type: 'SYNC_FAILED',
    payload: { date: new Date() },
  };
}

export function forceSync(): ForceSyncAction {
  return { type: 'FORCE_SYNC' };
}

export default function reducer(
  state: SyncStatusState = initialState,
  action: SyncStatusAction,
): SyncStatusState {
  switch (action.type) {
    case 'SYNC_START':
      return {
        ...state,
        stages: blankStages(),
        status: SyncStatus.STATUS_BUSY,
        lastAttempt: action.payload.date,
      };
    case 'SYNC_UPDATE':
      const { status = null, stages = {} } = action.payload;
      return {
        ...state,
        status: status || state.status,
        stages: mergeDeepRight(state.stages, stages),
      };
    case 'SYNC_DONE':
      const { full } = action.payload;
      return {
        ...state,
        status: SyncStatus.STATUS_DONE,
        // if any stages are still "busy"
        // asume that they have previously failed
        stages: map(
          stage => ({
            ...stage,
            status:
              stage.status === SyncStatus.STATUS_BUSY
                ? SyncStatus.STATUS_FAILED
                : stage.status,
          }),
          state.stages,
        ),
        lastSynced: action.payload.date,
        lastFullSynced: full ? action.payload.date : state.lastSynced,
      };
    case 'SYNC_FAILED':
      return {
        ...state,
        lastAttempt: action.payload.date,
        status: SyncStatus.STATUS_FAILED,
      };
    case 'SYNC_INCREASE_PROGRESS':
      const { progress, stage } = action.payload;
      let updatedStage = state.stages[stage];
      updatedStage = {
        ...updatedStage,
        tasksProgress: (updatedStage.tasksProgress || 0) + progress,
      };
      return {
        ...state,
        stages: {
          ...state.stages,
          [stage]: updatedStage,
        },
      };
    case 'FORCE_SYNC':
      return state;
  }
  softAssertNever(action);
  return state;
}
