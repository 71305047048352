import {
  complement,
  intersection,
  isEmpty,
  pipe,
  pluck,
  uniq,
  zipObj,
} from 'ramda';

// FIX fix any
export const mapBy: any = <T>(identifier: keyof T) => (arr: T[]) =>
  zipObj(pluck(identifier, arr) as any, arr);

export const mapByRecnum = mapBy('recnum');
export const mapByNumber = mapBy('number');
export const mapById = mapBy('id');

export const overlaps: <P>(l1: P[], l2: P[]) => boolean = pipe(
  intersection,
  complement(isEmpty),
);

// FIXME: accurate typing -> remove explicit any's
export const diffObjects = (o1: any, o2: any): any => {
  const obj: any = {};
  uniq([...Object.keys(o1), ...Object.keys(o2)]).forEach((k: string) => {
    if (o1[k] !== o2[k]) {
      obj[k] = o2[k];
    }
  });
  return obj;
};

export function includesEvery(subset: string[], superset: string[]) {
  return subset.every(e => superset.includes(e));
}
