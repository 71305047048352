import React from 'react';
import { Link, useLocation } from 'wouter';
import Page from '../components/Page';
import { Column } from '../components/Page/Columns';

// interface DrillDownProps {
//   children: React.ReactElement<any, typeof Column>[];
// }
// const DrillDown: React.FC<DrillDownProps> = ({ children }) => {
//   const isMobile = useMobileFlag();
//   if (isMobile) {
//     return <>{last(children)}</>;
//   }

//   return <>{children}</>;
// };

const Columnlayout: React.FC<{
  hello: boolean;
  params: { name?: string; hello?: string };
}> = props => {
  const [, goTo] = useLocation();

  const columns = [
    <Column title="1" uriPath="/drilldown">
      <h1>1</h1>
      <Link href="/drilldown/hello">make name</Link>
    </Column>,
  ];

  if (window.location.href.includes('/hello')) {
    columns.push(
      <Column title="2" uriPath="/drilldown/hello">
        <h1>2</h1>
        <form
          onSubmit={(ev: any) => {
            ev.preventDefault();
            goTo(`/drilldown/hello/${ev.currentTarget.elements['name'].value}`);
          }}
        >
          <input type="text" name="name" />
          <button>go</button>
        </form>
      </Column>,
    );
  }
  if (props?.params?.name) {
    columns.push(
      <Column title="3" uriPath={`/drilldown/hello/${props.params.name}`}>
        <h1>3</h1>
        <h2>hello {props.params.name}</h2>
      </Column>,
    );
  }
  return <Page columns={columns}></Page>;
};

export default Columnlayout;
