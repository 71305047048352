import { MakeTree } from '../../components/Tree/common';
import { LocationWithDetails } from '../../modules/locations';
import { replaceNumberingPlaceholder } from './replaceNumberingPlaceholder';

export function renderPathUntilParentId(
  loc: MakeTree<LocationWithDetails>,
  parentId: string,
) {
  let location: MakeTree<LocationWithDetails> | null = loc;
  let path: MakeTree<LocationWithDetails>[] = [];
  while (location) {
    path.unshift(location);
    if (location.id === parentId) {
      // include the parent than stop
      break;
    }
    location = location.parent;
  }

  return path.map(l => replaceNumberingPlaceholder(l.name, l.ordering));
}
