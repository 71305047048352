import * as React from 'react';
import Page, { PageProps } from '../Page';
import { Link } from 'wouter';

const NotFound: React.FC<{}> = props => {
  return (
    <Page {...props} title="Not found">
      <h4>The requested page was not found</h4>
      <Link to="/projects">Go to projects page</Link>
    </Page>
  );
};

export default NotFound;
