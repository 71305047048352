import classNames from 'classnames';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { Component } from 'react';
import {
  Button,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  HelpBlock,
  Panel,
  PanelGroup,
} from 'react-bootstrap';
import { findDOMNode } from 'react-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'wouter';
import {
  doesListHaveUnsavedModifications,
  patchNode,
} from '../../../common/list';
import { tagOptions } from '../../../common/logic/list';
import { modifyList, revertList } from '../../../modules/lists';
import FeedbackListItem from '../../FeedbackListItem';
import ParameterizedSearch from '../../ParameterizedSearch';
import { PropertyInput } from './PropertyInput';
import styles from './PropertyPanel.module.scss';
import { msg } from './msg';
import { propertyDescriptions } from './propertyDescriptions';

export default injectIntl(
  class PropertyPanel extends Component {
    static propTypes = {
      dispatch: PropTypes.func.isRequired,
      field: PropTypes.object,
      user: PropTypes.object.isRequired,
      list: PropTypes.object.isRequired,
      lists: PropTypes.object.isRequired,
      feedback: PropTypes.array.isRequired,
    };

    constructor(props) {
      super(props);
      this.state = { unlocked: [] };
    }

    componentDidMount() {
      const node = findDOMNode(this);
      const input = node.querySelector("input[name='title_nl']");
      this.titleNlInput = input;
      this.titleNlInput.focus();
      this.titleNlInput.setSelectionRange(0, this.titleNlInput.value.length);
    }

    componentWillReceiveProps(nextProps) {
      if (
        this.props.field &&
        nextProps.field &&
        nextProps.field.id !== this.props.field.id
      ) {
        this.setState({ unlocked: [] });
      }
    }

    componentDidUpdate(prevProps, prevState) {
      if (
        this.props.field &&
        (!prevProps.field ||
          !prevProps.field.id ||
          prevProps.field.id !== this.props.field.id) &&
        this.titleNlInput
      ) {
        this.titleNlInput.focus();
        this.titleNlInput.setSelectionRange(0, this.titleNlInput.value.length);
      }
    }

    handleChange = event => {
      const { name, value } = event.target;
      const { field, list, dispatch, user } = this.props;

      const mod = patchNode(
        {
          targetNodeId: field.id,
          partialNode: { [name]: value },
        },
        user.recnum,
      );

      dispatch(modifyList(list.id, mod));
    };

    toggleLocked = field => {
      const { unlocked } = this.state;
      this.setState({
        unlocked: unlocked.includes(field)
          ? R.without(field, unlocked)
          : [...unlocked, field],
      });
    };

    revert = () => {
      const { dispatch, list } = this.props;
      dispatch(revertList(list.id));
    };

    render() {
      const {
        field,
        list,
        lists,
        feedback,
        users,
        dispatch,
        index,
        tags,
        name,
        onTagsChange,
        onNameChange,
        onSave,
        tagsChanged,
        intl,
      } = this.props;
      const { unlocked } = this.state;
      const filterredFeedback = Object.values(feedback).filter(
        f => f.proto_list_id === list.id,
      );
      const modified =
        (doesListHaveUnsavedModifications(list) ||
          list.name !== name ||
          tagsChanged) &&
        name;

      let fieldPanels;

      const inputs = propertyDescriptions
        .filter(
          desc =>
            !desc.whitelist ||
            (field && desc.whitelist.indexOf(field.type) > -1),
        )
        .map(desc => (
          <PropertyInput
            {...desc}
            key={desc.name}
            field={field}
            unlocked={unlocked}
            toggleLocked={this.toggleLocked}
            onChange={this.handleChange}
          />
        ));
      fieldPanels = [
        <Panel key="props" defaultExpanded collapsible>
          <Panel.Heading>
            <Panel.Title toggle>
              {intl.formatMessage(msg.PROPS_PANEL_TITLE)}
            </Panel.Title>
          </Panel.Heading>
          <Panel.Body collapsible>
            <Form>{inputs}</Form>
          </Panel.Body>
        </Panel>,
        <FieldOriginPanel field={field} lists={lists} intl={intl} />,
        <Panel key="inspect">
          <Panel.Heading>
            <Panel.Title toggle>
              {intl.formatMessage(msg.INSPECT_PANEL_TITLE)}
            </Panel.Title>
          </Panel.Heading>
          <Panel.Body collapsible>
            <dl className="dl-horizontal">
              <dt>id</dt>
              <dd>{field && field.id}</dd>
              <dt>type</dt>
              <dd>{field && field.type}</dd>
            </dl>
            <pre>{JSON.stringify(field, null, 2)}</pre>
          </Panel.Body>
        </Panel>,
      ];
      // }

      let feedbackElem = <em>geen feedback</em>;
      let newCount;
      try {
        if (filterredFeedback && filterredFeedback.length) {
          newCount = filterredFeedback.filter(x => !x.handled).length;
          feedbackElem = R.sortBy(
            f => -new Date(f.createdAt),
            filterredFeedback,
          ).map(f => {
            let instanceList = R.values(lists).find(
              l => l.id === f.instanceListId,
            );
            return (
              <FeedbackListItem
                dispatch={dispatch}
                users={users}
                feedback={f}
                field={field}
                list={list}
                instanceList={instanceList}
                node={index[f.nodeId]}
                index={index}
                key={f.id}
              />
            );
          });
        }
      } catch (e) {
        feedbackElem = <em>Someting went wrong processing feedback</em>;
      }

      return (
        <PanelGroup className={classNames(styles.root, 'affix')}>
          <ListMetaPanel
            modified={modified}
            name={name}
            onNameChange={onNameChange}
            tags={tags}
            onTagsChange={onTagsChange}
            tagOptions={tagOptions}
            onSave={onSave}
            revert={this.revert}
            intl={intl}
          />
          <UsagePanel lists={lists} list={list} />
          <DisplayOptionsPanel
            msg={msg}
            locale={this.props.locale}
            onChangeLocale={this.props.onChangeLocale}
          />
          <Panel key="feedback" defaultExpanded={!!newCount} collapsible>
            <Panel.Heading>
              <Panel.Title toggle>
                {
                  <p>
                    <FormattedMessage {...msg.FEEDBACK_PANEL_TITLE} />{' '}
                    {!!newCount && (
                      <span className={`badge ${styles.newBadge}`}>
                        {newCount}
                      </span>
                    )}
                  </p>
                }
              </Panel.Title>
            </Panel.Heading>
            <Panel.Body collapsible>{feedbackElem}</Panel.Body>
          </Panel>
          {fieldPanels}
        </PanelGroup>
      );
    }
  },
);

function UsagePanel({ lists, list }) {
  // return null;
  const [templates, checklists] = R.partition(
    l => !l.werk_id,
    Object.values(lists).filter(l => l.dependencies?.includes(list.id)),
  );
  const dependencies = list.dependencies?.map(d =>
    Object.values(lists).find(({ id }) => id === d),
  );
  return (
    <Panel key="dependencies" collapsible>
      <Panel.Heading>
        <Panel.Title toggle>
          {
            <p>
              <FormattedMessage {...msg.DEPENDENCIES_PANEL_TITLE} />
            </p>
          }
        </Panel.Title>
      </Panel.Heading>

      <Panel.Body collapsible>
        <h4>Dependencies</h4>
        <em>
          {dependencies?.length ? (
            <FormattedMessage {...msg.DEPENDENCIES_EXPLANATION} />
          ) : (
            <FormattedMessage {...msg.NO_DEPENDENCIES_EXPLANATION} />
          )}
        </em>
        {dependencies?.length ? (
          <ul>
            {dependencies.map(l => (
              <li>
                <Link to={`/template/${l.id}`}>{l.name}</Link>
              </li>
            ))}
          </ul>
        ) : null}
        <h4>Dependants</h4>
        <em>
          {checklists.length + templates.length ? (
            <FormattedMessage {...msg.DEPENDANTS_EXPLANATION} />
          ) : (
            <FormattedMessage {...msg.NO_DEPENDANTS_EXPLANATION} />
          )}
        </em>
        {checklists.length ? (
          <>
            <h5>Checklists</h5>
            <ul>
              {checklists.length ? (
                checklists.map(l => (
                  <li>
                    <Link to={`/list/${l.id}`}>{l.name}</Link>
                  </li>
                ))
              ) : (
                <small>No checklists depend upon this list</small>
              )}
            </ul>{' '}
          </>
        ) : null}
        {templates.length ? (
          <>
            <h5>Templates</h5>
            <ul>
              {templates.length ? (
                templates.map(l => (
                  <li>
                    <Link to={`/template/${l.id}`}>{l.name}</Link>
                  </li>
                ))
              ) : (
                <small>no Templates depend upon this list</small>
              )}
            </ul>
          </>
        ) : null}
        <p className="small">
          <FormattedMessage {...msg.DEPENDENCIES_DISCLAIMER} />
        </p>
      </Panel.Body>
    </Panel>
  );
}

function DisplayOptionsPanel({ msg, locale, onChangeLocale }) {
  return (
    <Panel key="display_options" defaultExpanded={false} collapsible>
      <Panel.Heading>
        <Panel.Title toggle>
          {
            <p>
              <FormattedMessage
                id="TEMPLATE_DETAILS.DISPLAY_OPTIONS_PANEL_TITLE"
                defaultMessage="Display options"
              />
            </p>
          }
        </Panel.Title>
      </Panel.Heading>
      <Panel.Body collapsible>
        <FormGroup>
          <ControlLabel>
            <FormattedMessage
              id="TEMLPATE_DETAILS.DISPLAY_LANGUAGE"
              defaultMessage="Display language"
              description="Label of control for switch the language in which the template is displayed"
            />
          </ControlLabel>
          <FormControl
            componentClass="select"
            value={locale}
            onChange={onChangeLocale}
          >
            <FormattedMessage
              id="TEMPLATE_DETAILS.DISPLAY_LANGUAGE_OPTION_EN"
              defaultMessage="English"
            >
              {msg => <option value="en">{msg}</option>}
            </FormattedMessage>
            <FormattedMessage
              id="TEMPLATE_DETAILS.DISPLAY_LANGUAGE_OPTION_NL"
              defaultMessage="Dutch"
            >
              {msg => <option value="nl">{msg}</option>}
            </FormattedMessage>
          </FormControl>
          <HelpBlock>
            <FormattedMessage
              id="TEMPLATE_DETAILS.DISPLAY_LANGUAGE_HELP"
              defaultMessage="This setting only helps your to review the template in a specific language. It does not affect the template in any way."
              description=""
            />
          </HelpBlock>
        </FormGroup>
      </Panel.Body>
    </Panel>
  );
}

function ListMetaPanel({
  modified,
  name,
  onNameChange,
  tags,
  onTagsChange,
  tagOptions,
  onSave,
  revert,
  intl,
}) {
  return (
    <Panel key="List/Template" defaultExpanded collapsible>
      <Panel.Heading>
        <Panel.Title toggle>
          {intl.formatMessage(msg.TEMPLATE_PANEL_TITLE)}
        </Panel.Title>
      </Panel.Heading>
      <Panel.Body collapsible>
        {modified && (
          <p>
            {' '}
            <small>
              <FormattedMessage {...msg.TEMPLATE_HAS_UNSAVED_CHANGES} />
            </small>
          </p>
        )}
        <FormGroup>
          <ControlLabel>
            <FormattedMessage {...msg.TEMPLATE_NAME_LABEL} />
          </ControlLabel>
          <FormControl value={name} onChange={onNameChange} />
        </FormGroup>
        <FormGroup>
          <ControlLabel>
            <i className="fa fa-tag" />{' '}
            <FormattedMessage {...msg.TEMPLATE_TAG_LABEL} />
            {/* Tags */}
          </ControlLabel>
          <ParameterizedSearch
            value={tags}
            onChange={onTagsChange}
            options={tagOptions}
          />
        </FormGroup>
        <Button bsStyle="danger" disabled={!modified} onClick={revert}>
          <FormattedMessage {...msg.TEMPLATE_BUTTON_DISCARD_CHANGES} />
        </Button>{' '}
        <Button disabled={!modified} bsStyle="primary" onClick={onSave}>
          <FormattedMessage {...msg.TEMPLATE_BUTTON_SAVE} />
        </Button>
      </Panel.Body>{' '}
    </Panel>
  );
}

function FieldOriginPanel({ intl, field, lists }) {
  const protoChain =
    field &&
    field.protoChain.map(({ listId, nodeId }) => {
      const list = Object.values(lists).find(l => l.id === listId);
      return (
        <li>
          <Link to={`/template/${list.id}/${nodeId}`}>{list.name}</Link>
        </li>
      );
    });
  return (
    <Panel key="origin" defaultExpanded collapsible>
      <Panel.Heading>
        <Panel.Title toggle>
          {intl.formatMessage(msg.ORIGIN_PANEL_TITLE)}
        </Panel.Title>
      </Panel.Heading>
      <Panel.Body collapsible>
        <em>
          {!field ? (
            <FormattedMessage {...msg.ORIGIN_PANEL_NO_FIELD_SELECTED} />
          ) : protoChain?.length ? (
            <FormattedMessage {...msg.ORIGIN_PANEL_EXPLANATION} />
          ) : (
            <FormattedMessage {...msg.ORIGIN_PANEL_NO_EXPLANATION} />
          )}
        </em>
        {protoChain?.length ? <ul>{protoChain}</ul> : null}
      </Panel.Body>
    </Panel>
  );
}
