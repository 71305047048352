import * as React from 'react';
import OrigDropzone, { DropzoneProps } from 'react-dropzone';

import styles from './Dropzone.module.scss';

const Dropzone: React.FC<{
  innerDropzone?: React.Ref<any>;
} & React.PropsWithChildren<DropzoneProps>> = ({ innerDropzone, ...props }) => {
  return (
    <OrigDropzone
      ref={innerDropzone}
      maxSize={110e6}
      disableClick
      acceptClassName={styles.accept}
      rejectClassName={styles.reject}
      onDropRejected={(files, e) => {
        if (files.length === 1) {
          if (files[0].size > 110e6) {
            alert('File is too large, max 110mb');
            return;
          }
        }
        alert('All files must be an image or mp4 file no larger than 110Mb');
      }}
      style={{}}
      {...props}
    />
  );
};

export default Dropzone;
