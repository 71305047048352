import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Catalog } from '../../models/Catalog';
import { downloadFile } from '../../modules/fileDownloads';
import { FilesState } from '../../modules/files';

export default function useDeepLinkFileDownload(
  fileId?: number,
  catalog?: Catalog,
  files?: FilesState,
) {
  const [downloadFileId] = useState(fileId);
  const [done, setDone] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!done && downloadFileId && catalog?.files) {
      const file = catalog.files.find(c => c.recnum === downloadFileId);
      if (file && files) {
        if (!files[file.checksum]) {
          dispatch(downloadFile(file));
        }
        setDone(true);
      }
    }
  }, [catalog, downloadFileId, dispatch, done, setDone]);
}
