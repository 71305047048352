import PropTypes from 'prop-types';
import React, { Component } from 'react';

import CheckpointField from './CheckpointField';
import GroupField from './GroupField';
import RootField from './RootField';

import NumericField from './NumericField';
import TaskField from './TaskField';

const ROOT_FIELD = 'ROOT_FIELD';
const GROUP_FIELD = 'GROUP_FIELD';
const CHECKPOINT_FIELD = 'CHECKPOINT_FIELD';
const NUMERIC_FIELD = 'NUMERIC_FIELD';
const TASK_FIELD = 'TASK_FIELD';

const fieldMap = {
  [ROOT_FIELD]: RootField,
  [GROUP_FIELD]: GroupField,
  [CHECKPOINT_FIELD]: CheckpointField,
  [NUMERIC_FIELD]: NumericField,
  [TASK_FIELD]: TaskField,
};

export default class Field extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    field: PropTypes.object.isRequired,
  };

  render() {
    const { field } = this.props;
    const FieldComponent = fieldMap[field.type];

    //tslint:disable-next-line
    console.assert(FieldComponent, `No field-component (${field.type}) found`);

    return <FieldComponent {...this.props} />;
  }
}

export const renderFields = props => field => (
  <Field key={field.id} {...props} field={field} />
);
