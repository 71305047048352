import * as React from 'react';
import classNames from 'classnames';
import { Catalog } from '../../../models/Catalog';
import stl from './CatalogNotificationsButton.module.scss';
import { useDispatch } from 'react-redux';
import { patchCatalogDetails } from '../../../modules/catalogs';

interface Props {
  catalog: Catalog;
}

const CatalogNotificationsButton: React.FC<Props> = ({ catalog }) => {
  const icon = catalog.notifications_enabled ? 'fa-bell' : 'fa-bell-slash';
  const current = catalog.notifications_enabled ? 'enable' : 'disable';

  const [busy, setBusy] = React.useState<boolean>(false);

  const dispatch = useDispatch();
  const toggle = React.useCallback(async () => {
    setBusy(true);
    try {
      await dispatch(
        patchCatalogDetails(catalog.recnum, {
          notifications_enabled: !catalog.notifications_enabled,
        }),
      );
    } finally {
      setBusy(false);
    }
  }, [catalog, dispatch]);

  return (
    <button
      disabled={busy}
      onClick={toggle}
      className={classNames('btn btn-link', stl.root)}
    >
      <span>E-mail {current}d </span>
      <i className={classNames('fa', icon)}></i>
    </button>
  );
};

export default CatalogNotificationsButton;
