import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Stock } from '../modules/locations';
import StockSearch from './StockSearch';

interface ArtikelPickerModalProps {}

export interface ArtikelPickerModalRef {
  selectArtikel: () => Promise<Stock | null>;
}

const ArtikelPickerModal = React.forwardRef<
  ArtikelPickerModalRef,
  ArtikelPickerModalProps
>((props, ref) => {
  const [selectedArtikel, setSelectedArtikel] = React.useState<Stock | null>(
    null,
  );

  // Add state for promise callbacks
  const [resolveCallback, setResolveCallback] = React.useState<
    ((value: Stock | null) => void) | null
  >(null);
  const [rejectCallback, setRejectCallback] = React.useState<
    ((reason?: any) => void) | null
  >(null);

  React.useImperativeHandle(ref, () => ({
    selectArtikel: () => {
      return new Promise<Stock | null>((resolve, reject) => {
        setResolveCallback(() => resolve);
        setRejectCallback(() => reject);
      });
    },
  }));

  const handleClose = () => {
    resolveCallback?.(null);
    setRejectCallback(null);
    setResolveCallback(null);
  };

  const handleSelect = (stock: Stock | null) => {
    setSelectedArtikel(stock);
  };

  const handleConfirm = () => {
    resolveCallback?.(selectedArtikel);
    setRejectCallback(null);
    setResolveCallback(null);
  };

  return (
    <>
      <Modal show={!!resolveCallback} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Select stock</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <StockSearch
            onSelectStock={stock => stock && handleSelect(stock)}
          ></StockSearch>
        </Modal.Body>
        <Modal.Footer>
          <Button className="pull-left" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            disabled={!selectedArtikel}
            bsStyle="primary"
            onClick={handleConfirm}
          >
            Select
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
});

export default ArtikelPickerModal;
