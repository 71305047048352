import { offline, online } from '../modules/online';
import { resolveHeaders, resolveUrl } from '../utils';
/**
 * Service that monitors connectivity to the backend
 * dispatches change events to
 */
export default class OnlineService {
  constructor(store) {
    this.store = store;
    setInterval(
      this._check,
      process.env.NODE_ENV === 'production' ? 8e3 : 60e3,
    );
    this._check();
  }
  _check = async () => {
    const state = this.store.getState();
    const { online: _online } = state;
    try {
      await fetch(resolveUrl('/ping'), {
        method: 'GET',
        headers: resolveHeaders()(state),
      });
      if (_online !== true) {
        this.store.dispatch(online());
        return true;
      }
    } catch (e) {
      if (_online !== false) {
        this.store.dispatch(offline());
        return false;
      }
    }
  };
}
