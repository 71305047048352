import classNames from 'classnames';
import * as React from 'react';

import styles from './ProgressBullet.module.scss';

const r = 1 - 4 / 24 / 2;
const ProgressBullet: React.FC<{
  progress?: number;
  size?: number;
  complete?: boolean;
  subtle?: boolean;
  failed?: boolean;
  dashed?: boolean;
} & Pick<React.HTMLAttributes<HTMLDivElement>, 'style' | 'className'>> = ({
  progress = 0,
  size = 20,
  className,
  style,
  complete = false,
  subtle = false,
  failed = false,
  dashed = false,
}) => {
  if (complete) {
    progress = 1;
  }
  const arcProgress = Math.max(0.01, Math.min(progress, 0.99999)); // cant draw a full circle with an arc, so keep it shy of 100% (invisble)
  const rad = arcProgress * Math.PI * 2;
  const x = 1 + Math.sin(rad);
  const y = 1 + -Math.sin(rad + Math.PI * 0.5);
  const large = arcProgress > 0.5 ? 1 : 0;
  const sweep = arcProgress === 1 ? 0 : 1;

  const d = `
    M 1 1
    L 1 0
    A 1 1 0 ${large} ${sweep} ${x} ${y}
    L 1 1
  `;

  return (
    <div
      className={classNames(
        className,
        styles.root,
        subtle && styles.subtle,
        failed && styles.failed,
        dashed && styles.dashed,
      )}
      style={{ ...style, width: size + 'px', height: size + 'px' }}
    >
      <svg
        width={size}
        height={size}
        className={classNames(complete && styles.complete)}
        viewBox="0 0  2 2"
      >
        <circle cx="1" cy="1" r={r} />
        {!!progress && <path d={d} />}
      </svg>
      {complete && <i className="fa fa-check" style={{ fontSize: size - 6 }} />}
    </div>
  );
};

export default ProgressBullet;
