import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Page from '../Page';
import Spinner from '../Spinner';

const LazyRecoverChunk = React.lazy(() => import('./RecoverChunk'))

export class RecoverComponent extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired
  };

  static contextTypes = {
    intl: PropTypes.object.isRequired
  };

  render() {
    return (
      <Page dispatch={this.props.dispatch}>
        <React.Suspense fallback={<Spinner />}>
          <LazyRecoverChunk {...this.props} />
        </React.Suspense>
      </Page>
    );
  }
}

export default connect()(RecoverComponent);
