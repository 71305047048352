import { defineMessages } from 'react-intl';

export default defineMessages({
  BREADCRUM_LINK_CONTAINER_WORKS: {
    id: 'WORKS_DETAILS_COMPONENT.BREADCRUM_LINK_CONTAINER_WORKS',
    defaultMessage: 'Work items',
    description: '',
  },
  BREADCRUM_PROJECTS: {
    id: 'PROJECTS_OVERVIEW_PAGE.BREADCRUM_PROJECTS',
    defaultMessage: 'Projects',
  },
  BREADCRUM_DOCS_CATALOG: {
    id: 'PROJECT_CATALOG_DETAILS_PAGE.BREADCRUM_DOCS_CATALOG',
    defaultMessage: 'Docs Catalog',
  },
});
