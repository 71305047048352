import * as React from 'react';
import { ExcludeNull } from '../../common/logic/types';
import { StoreTicket } from '../../modules/tickets';
import { UsersState } from '../../modules/users';
import ReactMde, {
  getDefaultCommandMap,
  getDefaultToolbarCommands,
  ReactMdeProps,
} from '../../react-mde';
import { makeList } from '../../react-mde/commands/default-commands/listCommands';
import { CommandMap, ToolbarCommands } from '../../react-mde/types';
import Markdown from '../Markdown';
import Spinner from '../Spinner';
import StatusBadge from '../StatusBadge';
import UserToken from '../UserToken';
import './style.scss';

const toolbarCommands: ToolbarCommands = [
  ...getDefaultToolbarCommands(),
  ['new-ticket', 'help'],
];
const cmds: CommandMap = {
  ...getDefaultCommandMap(),
  'new-ticket': {
    execute({ initialState, textApi }) {
      makeList(initialState, textApi, (item, index) => `#'${item}'`);
    },
    icon: () => <i className="fa fa-hashtag"></i>,
  },
  help: {
    execute() {
      window.open('/docs/3-Tickets#text-editor', '_blank');
    },
    icon: () => <i className="fa fa-question-circle"></i>,
  },
};

export interface Command {
  keyword: string;
  description: string;
  parameter?: string;
}

export interface CerthonMarkdownEditorProps
  extends Omit<
    ReactMdeProps,
    'commands' | 'selectedTab' | 'onTabChange' | 'generateMarkdownPreview'
  > {
  commands?: Command[];
  localTickets: Record<string, StoreTicket>;
  allTickets: Record<string, StoreTicket>;
  users: ExcludeNull<UsersState>;
}

/**
 * This text editor is designed to loosely edit-, create- and reference entities in the certhon-web domain:
 *  - tickets
 *  - users
 *  -  deep links
 *
 * tickets and users can be referenced a/o created using a special charactar '@', '#'
 * this allows users to quickly create a references
 *
 * Other entities can be referenced using deep links, these links are inserted as normal markdown-hyperlinks. they are parsed and replaced with a proper name.
 *
 * Finally commands can be invoken as a quick means to operate on contextual information: i.e. '/close' to close a ticket
 */
const CerthonMarkdownEditor: React.FC<CerthonMarkdownEditorProps> = ({
  localTickets,
  allTickets,
  users,
  ...props
}) => {
  const [tab, setTab] = React.useState<'write' | 'preview'>('write');

  return (
    <React.Suspense fallback={<Spinner />}>
      <ReactMde
        renderCompletion={(value, lex, comleteLex, data) => {
          const { id } = lex;
          if (id === 'create_ticket') {
            return (
              <>
                <StatusBadge size="small" create />{' '}
                {value.replace(/#('|")(.*)\1/, '$2')}
                <small
                  className="pull-right"
                  style={{ marginLeft: 5, marginTop: 2 }}
                >
                  new ticket
                </small>
              </>
            );
          } else if (id === 'ticket') {
            const ticket: StoreTicket = data;
            return (
              <>
                <StatusBadge size="small" status={ticket.status} />{' '}
                <span>#{ticket.number}</span> {ticket.title}
              </>
            );
          } else if (id === 'user') {
            return <UserToken user={data} small withText />;
          }
        }}
        selectedTab={tab}
        onTabChange={setTab}
        generateMarkdownPreview={async str => (
          <Markdown
            localTickets={localTickets}
            allTickets={localTickets}
            users={users}
          >
            {str}
          </Markdown>
        )}
        {...props}
        toolbarCommands={toolbarCommands}
        commands={cmds}
      />
    </React.Suspense>
  );
};

export default CerthonMarkdownEditor;
