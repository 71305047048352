import React from 'react';
import Page from '../components/Page';
import { Column } from '../components/Page/Columns';
import CountingRequestCreationContainer from './CountingRequestCreation.Container';
import CountingRequestDetailsContainer from './CountingRequestDetails.Container';
import CountingRequestOverviewContainer from './CountingRequestOverview.Container';
import LocationCountingContainer from './LocationCounting.Container';

interface CountingRequestLayoutProps {
  params: { recnumOrNew?: string; parentLocationId?: string };
}

const CountingRequestLayout: React.FC<CountingRequestLayoutProps> = ({
  params: { recnumOrNew, parentLocationId },
}) => {
  const columns = [
    <Column key="overview" title="overview" uriPath="counting-requests">
      <CountingRequestOverviewContainer />
    </Column>,
  ];
  let recnum: number;
  if (recnumOrNew) {
    if (recnumOrNew === 'new') {
      columns.push(
        <Column
          key="create"
          title="Create"
          uriPath={`/counting-requests/${recnumOrNew}`}
        >
          <CountingRequestCreationContainer />
        </Column>,
      );
    } else if ((recnum = parseInt(recnumOrNew))) {
      columns.push(
        <Column
          key="details"
          title="Details"
          uriPath={`/counting-requests/${recnumOrNew}`}
        >
          <CountingRequestDetailsContainer recnum={recnum} />
        </Column>,
      );
      if (parentLocationId) {
        columns.push(
          <Column
            key="counting"
            title="Counting"
            uriPath={`/counting-requests/${recnum}/count/${parentLocationId}`}
          >
            <LocationCountingContainer
              parentLocationId={parentLocationId}
              recnum={recnum}
            ></LocationCountingContainer>
          </Column>,
        );
      }
    }
  }
  return <Page columns={columns} />;
};

export default CountingRequestLayout;
