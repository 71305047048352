import { CountingRequestFilter } from '@certhon/domain-models/lib';
import { Stock } from '../../modules/locations';

export function hashToNumber(value: string | null | undefined | number) {
  const input = value + '';
  var hash = 0,
    i,
    chr;
  if (input.length === 0) return hash;
  for (i = 0; i < input.length; i++) {
    chr = input.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}

export function createSeededHashFunc(seed: number = 0) {
  // https://gist.github.com/blixt/f17b47c62508be59987b
  seed = seed % 2147483647;
  if (seed <= 0) seed += 2147483646;

  return (key: string) => {
    let hashed = hashToNumber(key);
    if (hashed <= 0) {
      hashed += 2147483646;
    }
    return ((((hashed + seed) * 16807) % 2147483647) - 1) / 2147483646;
  };
}

export default function countingRequestFilter({
  samplePercentage,
  sampleSeed,
  unsalable,
}: CountingRequestFilter) {
  const sampleFunc = !samplePercentage
    ? null
    : createSeededHashFunc(sampleSeed);

  return (location_id: string, stock: Pick<Stock, 'recnum' | 'unsalable'>) => {
    if (unsalable !== null) {
      if (stock.unsalable !== unsalable) {
        return false;
      }
    }
    if (sampleFunc) {
      const newLocal =
        sampleFunc(location_id + stock.recnum) <= samplePercentage!;
      return newLocal;
    }
    return true;
  };
}
