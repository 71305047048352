import memoizee from 'memoizee';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import Types from 'Types';
import { isTicketWithEvents } from '../../common/logic/ticket';
import MeetingDetailsPage from '../../components/MeetingsDetailsPage';
import Meeting from '../../models/Meeting';
import { Project } from '../../models/Project';
import { fetchMeetings } from '../../modules/meetings';
import { fetchTickets, StoreTicket } from '../../modules/tickets';
import { fetchUser, UserState } from '../../modules/user';
import { fetchUsers, UsersState } from '../../modules/users';
import { fetchWorks, Work, WorkState } from '../../modules/works';
import ignoreRejection from '../../utils/ignoreRejection';

interface Props {
  params: {
    minuteId: string;
  };
  work: Work | null;
  works: WorkState;
  project: Project | null;
  meeting: Meeting | null;
  previousMeeting: Meeting | null;
  allTicketsHaveEvents: boolean;
  users: UsersState;
  user: UserState;
  tickets: any;
  dispatch: Dispatch;
}

class MeetingsDetailsContainer extends React.Component<Props> {
  componentDidMount() {
    const { dispatch } = this.props;
    ignoreRejection(
      dispatch(fetchWorks(null)),
      dispatch(fetchUsers()),
      dispatch(fetchTickets() as any),
      dispatch(fetchUser()),
      dispatch(fetchMeetings() as any),
    );
  }

  render() {
    const {
      tickets,
      work,
      meeting,
      previousMeeting,
      users,
      dispatch,
      user,
      project,
      allTicketsHaveEvents,
      works,
    } = this.props;
    return (
      <MeetingDetailsPage
        project={project}
        works={works}
        user={user}
        tickets={tickets}
        work={work}
        meeting={meeting}
        previousMeeting={previousMeeting}
        users={users}
        dispatch={dispatch}
        allTicketsHaveEvents={allTicketsHaveEvents}
      />
    );
  }
}
//tetete
const getMinute = (
  state: Types.RootState,
  minuteId: string,
): Meeting | null => {
  return state.minutes[minuteId] || null;
};
// const getMinuteWorkId = (
//   state: Types.RootState,
//   props: Props,
// ): number | null => {
//   return (
//     (state.minutes[props.params.minuteId] &&
//       state.minutes[props.params.minuteId].werk_id) ||
//     null
//   );
// };

const getProjectWorkIds = memoizee((works: WorkState, projectId: number) => {
  return (
    (works &&
      (Object.values(works) as Work[])
        .filter(w => w?.project_id === projectId)
        .filter(x => x)
        .map(w => w.recnum)) ||
    []
  );
});

const getTickets = memoizee(
  (tickets: any, workIds: number[], projectId: number) => {
    return Object.values(tickets).filter(
      (t: any) => workIds.includes(t.werk_id) || t.project_id === projectId,
    );
  },
  { max: 1 },
);

const doAllTicketsHaveEvents = memoizee(
  (tickets: StoreTicket[] | null): boolean =>
    tickets === null ? false : tickets.every(isTicketWithEvents),
);

function mapStateToProps(state: Types.RootState, props: Props) {
  const meeting = getMinute(state, props.params.minuteId!);
  let previousMeeting: Meeting | null = null;
  if (meeting && meeting.previous_meeting) {
    previousMeeting = getMinute(state, meeting.previous_meeting);
  }
  const work = (meeting?.werk_id && state.works?.[meeting.werk_id]) || null;
  const project =
    (meeting?.project_id && state.projects?.[meeting?.project_id]) ||
    (work?.project_id && state.projects?.[work.project_id]) ||
    null;

  const workIds =
    (project && getProjectWorkIds(state.works, project.recnum)) || [];

  const tickets = (meeting &&
    meeting?.project_id &&
    getTickets(state.tickets, workIds, meeting.project_id)) as any;

  const allTicketsHaveEvents = doAllTicketsHaveEvents(tickets);

  return {
    meeting,
    previousMeeting,
    user: state.user,
    users: state.users,
    project,
    work,
    works: state.works,
    tickets,
    allTicketsHaveEvents,
  };
}

export default connect(mapStateToProps)(MeetingsDetailsContainer);
