// tslint:disable: ordered-imports
import * as Sentry from '@sentry/react';
import debounce from 'lodash/debounce';
import pLimit from 'p-limit';
import { Action, Store } from 'redux';
import { CALL_API, RSAAction } from 'redux-api-middleware';
import Types from 'Types';
import calculateTicketEventCutoffDate from '../common/logic/calculateTicketEventCutoffDate';
import { getClientIdentifier } from '../getClientIdentifier';
import { Catalog } from '../models/Catalog';
import { AttachmentsState, saveAttachment } from '../modules/attachments';
import { fetchCatalogDetails, fetchCatalogs } from '../modules/catalogs';
import { FeedbackState, saveFeedback } from '../modules/feedback';
import { downloadFile } from '../modules/fileDownloads';
import { fetchLabels } from '../modules/labels';
import { fetchLists, saveList } from '../modules/lists';
import { MeetingState, saveMeeting } from '../modules/meetings';
import { fetchProjects } from '../modules/projects';
import {
  allSyncStages,
  syncDone,
  syncFailed,
  syncProgress,
  SyncStage,
  syncStart,
  SyncStatus,
  syncUpdate,
} from '../modules/syncStatus';
import { fetchTickets, saveTicket, TicketsState } from '../modules/tickets';
import { fetchUser } from '../modules/user';
import { fetchUsers } from '../modules/users';
import { fetchWorks } from '../modules/works';
import { resolveHeaders, resolveUrl } from '../utils';
import UpdateService from './UpdateService';

const shardCountAssumption = 20;
const performSharded = async <T>(
  resource: string,
  func: (shardDesc: string) => T,
): Promise<T[]> => {
  let count = shardCountAssumption;
  const uri = new URL(resolveUrl(resource), window.location.origin);
  uri.searchParams.set('shard', `0-${shardCountAssumption}`);
  const res = await fetch(uri, { method: 'HEAD' });
  const linkHeader = res.headers.get('Link');

  if (linkHeader) {
    const linkHeaderParts = linkHeader.split(',');
    for (const part of linkHeaderParts) {
      const match = part.match(/<([^>]+)>;\s*rel="?([^"]+)"?/);
      if (match && match[2] === 'preferredShardCount') {
        const uri = new URL(match[1]);
        count = parseInt(uri.searchParams.get('shard')!.split('-')[1]);
        console.log(
          `Server prefers shard-count ${count} for resource ${resource}`,
        );
        break;
      }
    }
  }
  const result: T[] = [];
  for (let index = 0; index < count; index++) {
    result.push(func(`${index}-${count}`));
  }
  return result;
};

type SyncJob =
  | (() => Promise<() => Promise<void>>)
  | (() => Promise<(() => Promise<void>)[]>)
  | SyncStatus;

// this service observes the store for lists to be synced

// and processes these as soon as internet is available
export default class SyncService {
  private lastLists?: any;
  private lastTickets?: TicketsState;
  private lastAttachments?: AttachmentsState;
  private lastFeedback?: FeedbackState;
  private lastMinutes?: MeetingState;
  private busy: false | Date = false;

  constructor(
    private store: Store<Types.RootState, Action<any>>,
    private updateService: UpdateService,
  ) {
    const regulatedUpdate = debounce(this.update, 1e3);

    setTimeout(() => {
      store.subscribe(regulatedUpdate);
      setInterval(() => regulatedUpdate(true), 15 * 60e3); // every 15 min
      regulatedUpdate();
    }, 4e3);
  }

  update = async (fullSync: boolean = false) => {
    if (this.busy) {
      return;
    }

    const state = this.store.getState();
    try {
      this.busy = new Date();

      // fragile dispatch
      // it throws when a api call resolves with an error
      const fragileDispatch = async (
        action: Action<any> | RSAAction<any, any, any>,
      ) => {
        const result = (await Promise.resolve(
          this.store.dispatch(action as Action<any>),
        )) as any;
        if (result && result.error) {
          const error: any = new Error(
            'Sync failed due to suspected API error response',
          );
          error.result = result;
          throw error;
        }
        return result;
      };

      const {
        online,
        lists,
        attachments,
        feedback,
        tickets,
        syncStatus,
        user,
        minutes,
        ui,
      } = state;

      const jobs: {
        [key in SyncStage]?: SyncJob;
      } = {};

      if (online && user) {
        if (attachments && this.lastAttachments !== attachments) {
          const attachmentsToPush = Object.values(attachments).filter(
            a => a && a._persist,
          );
          if (attachmentsToPush.length) {
            jobs[SyncStage.POST_ATTACHMENTS] = async () =>
              attachmentsToPush
                // saveAttachment will load attachment into memory, make sure save attachment is called as late as possible
                .map(a => () => fragileDispatch(saveAttachment(a!)));
          }
        }

        if (lists && this.lastLists !== lists) {
          const listsToPush = Object.values(lists as any[]).filter(
            l => l._persist,
          );
          if (listsToPush.length) {
            jobs[SyncStage.POST_LISTS] = async () =>
              listsToPush.map(l => () => fragileDispatch(saveList(l) as any));
          }
        }

        if (tickets && this.lastTickets !== tickets) {
          const ticketsToPush = Object.values(tickets as any[]).filter(
            t => t._modified,
          );
          if (ticketsToPush.length) {
            jobs[SyncStage.POST_TICKETS] = async () =>
              ticketsToPush.map(t => () => fragileDispatch(saveTicket(t)));
          }
        }

        if (feedback && this.lastFeedback !== feedback) {
          const feedbackToPush = Object.values(feedback).filter(f => f._sync);
          if (feedbackToPush.length) {
            jobs[SyncStage.POST_FEEDBACK] = async () =>
              feedbackToPush.map(f => () => fragileDispatch(saveFeedback(f)));
          }
        }
        if (minutes && this.lastMinutes !== minutes) {
          const minutesToPush = Object.values(minutes).filter(m => m._changed);
          if (minutesToPush.length) {
            jobs[SyncStage.POST_MINUTES] = async () =>
              minutesToPush.map(m => () =>
                fragileDispatch(saveMeeting(m) as any),
              );
          }
        }

        const internalOnly = (job: SyncJob) =>
          user.internal ? job : SyncStatus.STATUS_SKIPPED;

        if (fullSync || !syncStatus.lastSynced) {
          // get stuff
          jobs[SyncStage.GET_ME] = async () => () =>
            fragileDispatch(fetchUser());
          jobs[SyncStage.GET_LABELS] = internalOnly(async () => () =>
            fragileDispatch(fetchLabels()),
          );
          jobs[SyncStage.GET_PROJECTS] = async () => () =>
            fragileDispatch(fetchProjects());
          jobs[SyncStage.GET_WORKS] = internalOnly(async () => () =>
            fragileDispatch(fetchWorks()),
          );
          jobs[SyncStage.GET_USERS] = internalOnly(async () => () =>
            fragileDispatch(fetchUsers()),
          );
          jobs[SyncStage.GET_LISTS] = internalOnly(() =>
            performSharded('/lists', shard => () =>
              fragileDispatch(fetchLists({ shard }) as any),
            ),
          );
          jobs[SyncStage.GET_TICKETS] = internalOnly(async () => {
            const eventCutoffDate = calculateTicketEventCutoffDate();
            // lets use an endpoint that is likely to come frome cache
            const endpoint = fetchTickets({
              closed_since_or_open: eventCutoffDate,
            })[CALL_API].endpoint.replaceAll(/\/api/g, '');
            return (
              await performSharded(endpoint, shard => [
                () =>
                  fragileDispatch(
                    fetchTickets({
                      closed_before: eventCutoffDate,
                      skip_events: true,
                      shard,
                    }) as any,
                  ),
                () =>
                  fragileDispatch(
                    fetchTickets({
                      closed_since_or_open: eventCutoffDate,
                      shard,
                    }) as any,
                  ),
              ])
            ).flat(1);
          });
          if (ui.syncCatalogs) {
            // TODO: rename ui to settings
            jobs[SyncStage.GET_CATALOGS] = async () => {
              await fragileDispatch(fetchCatalogs());

              const { projects, catalogs, files } = this.store.getState();
              const localFileChecksums = Object.keys(files);
              const followedProjectIds = Object.values(projects!)
                .filter(p => !user.internal || p?.users.includes(user.recnum))
                .map(p => p?.recnum);

              const allFilesToSync: (() => Promise<void>)[] = [];

              for (const catalog of Object.values(catalogs!)) {
                if (followedProjectIds.includes(catalog!.project_id)) {
                  // Fetch catalog details and wait for the dispatch to complete
                  const { payload: details } = await fragileDispatch(
                    fetchCatalogDetails(catalog!.recnum),
                  );

                  // Filter and collect the files to sync from the catalog details
                  for (const file of (details as Catalog).files) {
                    if (
                      file.revision_nr !== null &&
                      file.mime === 'application/pdf' &&
                      !localFileChecksums.includes(file.checksum)
                    ) {
                      allFilesToSync.push(() =>
                        fragileDispatch(downloadFile(file)),
                      );
                    }
                  }
                }
              }

              return allFilesToSync;
            };
          } else {
            jobs[SyncStage.GET_CATALOGS] = SyncStatus.STATUS_SKIPPED;
          }
        }
      }

      if (Object.values(jobs).some(j => typeof j === 'function')) {
        try {
          const limit = pLimit(12);
          // perform jobs
          const syncStageUpdate = (
            stage: SyncStage,
            status: SyncStatus,
            tasksTotal?: number,
          ) =>
            fragileDispatch(
              syncUpdate({
                stages: {
                  [stage]: tasksTotal ? { status, tasksTotal } : { status },
                },
              }),
            );
          const syncStageProgress = (stage: SyncStage) =>
            fragileDispatch(syncProgress(stage, 1));

          let runningJob: Promise<void> = Promise.resolve();
          let nextJobInit: Promise<(() => Promise<void>)[]> = Promise.resolve(
            [],
          );

          // 1. loop through all stages
          // We run one stage in parallel always initialize the next

          fragileDispatch(syncStart());
          for (const stage of allSyncStages) {
            const nextJob = jobs[stage];
            if (typeof nextJob === 'function') {
              // 2  initialize the next job
              syncStageUpdate(stage, SyncStatus.STATUS_INITIALIZING);
              nextJobInit = nextJob().then(r => (Array.isArray(r) ? r : [r]));
            } else {
              const status = nextJob || SyncStatus.STATUS_DONE;
              syncStageUpdate(stage, status);
              continue; // no job to initialize, continue to the next
            }
            // 4. wait for both the running job and the initialization if the next job to complete
            const [jobsToRunNext] = await Promise.all([
              nextJobInit,
              runningJob,
            ]);

            // 5. Start running the next jobs
            // interweave syncstatus updates
            syncStageUpdate(
              stage,
              SyncStatus.STATUS_BUSY,
              jobsToRunNext.length,
            );

            runningJob = Promise.all(
              jobsToRunNext
                .map(limit)
                .map(p => p.then(() => syncStageProgress(stage))),
            ).then(
              () => syncStageUpdate(stage, SyncStatus.STATUS_DONE),
              err => {
                console.log(err);

                syncStageUpdate(stage, SyncStatus.STATUS_FAILED);
                throw err;
              },
            );
          }

          // 5. wait for the final job(s) to complete
          await runningJob;

          fragileDispatch(syncDone(fullSync));
        } catch (e) {
          fragileDispatch(syncFailed());
          throw e;
        }

        // send sync successfull
        const { preferredMinimumClientVersion } = await this.sendSyncResult(
          fullSync,
        );

        this.updateService.handleMinimumPreferredClientVersion(
          preferredMinimumClientVersion,
        );
      }
      // snapshot state
      this.lastLists = lists;
      this.lastTickets = tickets;
      this.lastAttachments = attachments;
      this.lastFeedback = feedback;
      this.lastMinutes = minutes;
    } catch (e) {
      const error = e as Error;
      console.error(error);

      Sentry.captureException(error);

      // send sync failed
      await this.sendSyncResult(fullSync, error);
    } finally {
      this.busy = false;
    }
  };

  private async sendSyncResult(fullSync: boolean, error?: Error) {
    const client = {
      version: process.env.REACT_APP_VERSION,
      id: getClientIdentifier(),
      useragent: window.navigator.userAgent,
    };

    const errorMeta = error && {
      ...error,
      message: error.message,
      stack: error.stack,
      name: error.name,
    };
    return await fetch(resolveUrl('/me/syncresults'), {
      method: 'POST',
      headers: resolveHeaders()(this.store.getState()),
      body: JSON.stringify({
        success: !error,
        client,
        meta: {
          duration: this.busy && Date.now() - this.busy.getTime(),
          fullSync,
          ...errorMeta,
        },
      }),
    }).then(res => res.json());
  }
}
