import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Field from './Field';

export const LISTMODE_REPORT = 'LISTMODE_REPORT';
export const LISTMODE_NORMAL = 'LISTMODE_NORMAL';
export const LISTMODE_MAINTAIN = 'LISTMODE_MAINTAIN';
export const LIST_MODES = [LISTMODE_MAINTAIN, LISTMODE_NORMAL, LISTMODE_MAINTAIN];

export default class List extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    list: PropTypes.object.isRequired,
    tree: PropTypes.object.isRequired,
    mode: PropTypes.oneOf(LIST_MODES).isRequired,
    focussedField: PropTypes.string,
  }

  static defaultProps = {
    mode: LISTMODE_NORMAL,
    title: true,
  }

  render() {
    const { tree, ...rest } = this.props;
    return (
      <Field field={tree} {...rest} />
    );
  }
}
