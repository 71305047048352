import * as React from 'react';

export interface IReportReadyProviderProps {
  onReady: () => void;
  children?: React.ReactNode;
}

export interface IReportReadyProviderState {
  registers: number;
  ready: number;
  invoked: boolean;
}

export interface IReportReadyProviderContext {
  register: () => void;
  ready: () => void;
  reportMode: boolean;
}

const defaultValue: IReportReadyProviderContext = {
  // tslint:disable-next-line:no-empty
  register: () => {},
  // tslint:disable-next-line:no-empty
  ready: () => {},
  reportMode: false,
};

export const ReportReadyContext = React.createContext<
  IReportReadyProviderContext
>(defaultValue);
const { Provider, Consumer } = ReportReadyContext;

class ReportReadyProvider extends React.Component<
  IReportReadyProviderProps,
  IReportReadyProviderState
> {
  private numRegisters = 0;
  private numReady = 0;
  private invoked = false;

  private _if: IReportReadyProviderContext | null = null;

  render() {
    return (
      <Provider value={this.getInterface()}>{this.props.children}</Provider>
    );
  }

  componentDidMount() {
    // fixme: dont rely on timeouts to wait for react to finish, use correct lifetime methods
    setTimeout(() => this.invoke(), 1e2);
  }

  ready = () => {
    this.numReady++;
    this.invoke();
  };

  register = () => {
    this.numRegisters++;
    this.invoke();
  };

  private invoke() {
    if (this.numRegisters === this.numReady && !this.invoked) {
      this.invoked = true;
      this.props.onReady();
    }
  }

  private getInterface(): IReportReadyProviderContext {
    if (!this._if) {
      this._if = {
        register: this.register,
        ready: this.ready,
        reportMode: true,
      };
    }
    return this._if;
  }
}

export const ReportReadyConsumer = Consumer;
export default ReportReadyProvider;
