import { reverse } from 'lodash';
import { LexMatch } from '../../utils/LexicalParsing';
import { replaceMatch } from './replaceMatch';

export function sanitizeText(matches: LexMatch[], text: string) {
  for (const match of reverse(matches)) {
    // remove all matches except for the following
    if (['user', 'ticket', 'create_ticket'].includes(match.id)) {
      continue;
    }
    text = replaceMatch(text, match);
  }
  return text;
}
