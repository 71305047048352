import * as Sentry from '@sentry/react';

/**
 * Service to monitor service workers and handle communication related to serviceworker updates
 */
export default class UpdateService extends EventTarget {
  constructor() {
    super();

    // aftes init check wether a new worker is waiting
    window.navigator.serviceWorker?.ready?.then(reg =>
      this.handleServicePossibleWorkerUpdate(reg),
    );
  }

  private _availableVersion?: string;
  public get availableVersion() {
    return this._availableVersion || null;
  }

  public get updateAvailable() {
    return !!this._registration?.waiting;
  }

  public get currentVersion() {
    return process.env.REACT_APP_VERSION as string;
  }

  public handleMinimumPreferredClientVersion(version: string) {
    if (
      !version ||
      !this.currentVersion ||
      !isValidVersion(version) ||
      !isValidVersion(this.currentVersion)
    ) {
      return;
    }
    try {
      if (isGreaterVersion(version, this.currentVersion)) {
        /*
        we need to upgrade, either:
        -  upgrade if an upgrade is available 
        -  refresh to check for any new upgrades
        */

        if (this.updateAvailable) {
          this.updateServiceWorker();
        } else {
          const key = `${this.currentVersion}_refresh`;
          let val = localStorage.getItem(key);
          let newVal = val ? parseInt(val) + 1 : 1;
          localStorage.setItem(key, newVal.toString());

          if (
            newVal < 2 ||
            window.confirm(
              'Your version is outdated and needs to be upgraded... refresh now?',
            )
          ) {
            window.location.reload();
          }
        }
      }
    } catch (error) {
      console.error('Could not upgrade');
      console.error(error);
      Sentry.captureException(error);
    }
  }

  // cache the registration so methods do not need to be async
  private _registration?: ServiceWorkerRegistration;
  public handleServicePossibleWorkerUpdate(
    registration: ServiceWorkerRegistration,
  ) {
    this._registration = registration;
    if (registration.waiting) {
      // Communicate using temp-channel
      const channel = new MessageChannel();
      channel.port1.onmessage = e => {
        channel.port1.close();
        this._availableVersion = e.data.version;
        if (isGreaterMajorVersion(e.data.version, this.currentVersion)) {
          // always attempt to upgrade to new major versions
          this.handleMinimumPreferredClientVersion(e.data.version);
        }
        this.dispatchEvent(new Event('updateAvailable'));
      };
      registration.waiting.postMessage({ type: 'GET_VERSION' }, [
        channel.port2,
      ]);
    }
  }

  /**
   * Signal to the serviceworker that it can stop waiting for activation
   */
  public updateServiceWorker() {
    if (!this._registration?.waiting) {
      throw new Error('No update available');
    }
    this._registration.waiting?.postMessage({ type: 'SKIP_WAITING' });
  }
}

export function isGreaterVersion(version1: string, version2: string) {
  const v1 = Array.from(version1.matchAll(/(\d+)/g)).map(([x]) => x);
  const v2 = Array.from(version2.matchAll(/(\d+)/g)).map(([x]) => x);

  for (const idx in v1) {
    const n1 = parseInt(v1[idx]);
    const n2 = parseInt(v2[idx]);
    if (n1 > n2) {
      return true;
    }
    if (n1 < n2) {
      return false;
    }
  }
  return false;
}

function isValidVersion(version: string) {
  return /^v\d+(\.\d+)*/.test(version);
}

function isGreaterMajorVersion(version1: string, version2: string) {
  return isGreaterVersion(
    version1.match(/^v\d+/)?.[0] || '',
    version2.match(/^v\d+/)?.[0] || '',
  );
}
