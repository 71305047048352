import React from 'react';
import { TreeTableRowProps } from '.';
import { firstCommonElement } from './common';
import { Identifiable } from './TreeElement';
export type DropLocation = 'above' | 'inside' | 'below';

export interface Draggable<T> {
  canDropItem: (
    draggedItem: T,
    targetItem: T,
    operation: 'move' | 'copy',
  ) => DropLocation[];
  onDropItem: (
    draggedItem: T,
    targetItem: T,
    location: DropLocation,
    operation: 'move' | 'copy',
  ) => void;
}

export function useTreeTableDraggableInterface<T>(
  { canDropItem, onDropItem }: Draggable<T>,
  dependencies: any[],
): Draggable<T> {
  const inf = {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    canDropItem: React.useCallback(canDropItem, dependencies),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    onDropItem: React.useCallback(onDropItem, dependencies),
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return React.useMemo(() => inf, dependencies);
}

export type DragEventHandlerWithId<RT> = (
  e: React.DragEvent<HTMLTableRowElement>,
  id: any,
) => RT;

export interface TreeElementDragEventHandlers {
  onDragStart: DragEventHandlerWithId<void>;
  onDragEnd: DragEventHandlerWithId<void>;
  onDragOver: DragEventHandlerWithId<boolean>;
  onDrop: DragEventHandlerWithId<void>;
}

export interface DropTargetVisualization {
  id: any;
  location: DropLocation;
}

export function useDraggableInternal<K extends Identifiable>(
  rowData: TreeTableRowProps<K>[],
  { canDropItem, onDropItem }: Draggable<K>,
): {
  dragEventHandlers: TreeElementDragEventHandlers;
  dropTargetVisualization: DropTargetVisualization | null;
} {
  const [draggedId, setDraggedId] = React.useState<any>(null);
  const [
    dropTargetVisualization,
    setDropTargetVisualization,
  ] = React.useState<DropTargetVisualization | null>(null);

  const dragEventHandlers: TreeElementDragEventHandlers = {
    // drop target
    onDragStart: React.useCallback(
      (ev, id) => {
        setDraggedId(id);
      },
      [setDraggedId],
    ),
    onDragEnd: React.useCallback(
      (ev, id) => {
        setDraggedId(null);
        setDropTargetVisualization(null);
      },
      [setDraggedId],
    ),

    // draggable
    onDragOver: React.useCallback(
      (event: React.DragEvent<HTMLTableRowElement>, id: any) => {
        let draggedItem = rowData.find(rd => rd.item.id === draggedId)?.item;
        let targetItem = rowData.find(rd => rd.item.id === id)?.item;
        if (!draggedItem || !targetItem) {
          return false;
        }
        const dropLocations = canDropItem(
          draggedItem,
          targetItem,
          event.getModifierState('Control') ? 'copy' : 'move',
        );
        const rowElement = (event.target as any).closest(
          'tr',
        ) as HTMLTableRowElement;
        const rect = rowElement.getBoundingClientRect();

        const yPercentage = (event.clientY - rect.top) / rect.height;

        if (dropLocations.length === 0) {
          setDropTargetVisualization(null);
          event.dataTransfer.dropEffect = 'none';
        }

        setDropTargetVisualization({
          id,
          location:
            (yPercentage < 0.15 &&
              firstCommonElement(
                ['above', 'inside', 'below'],
                dropLocations,
              )) ||
            (yPercentage < 0.85 &&
              firstCommonElement(
                ['inside', 'above', 'below'],
                dropLocations,
              )) ||
            firstCommonElement(['below', 'inside', 'above'], dropLocations) ||
            dropLocations[0],
        });

        return !!dropLocations.length;
      },
      [canDropItem, draggedId, rowData],
    ),
    onDrop: React.useCallback(
      (event: React.DragEvent<HTMLTableRowElement>, id: any) => {
        let draggedItem = rowData.find(rd => rd.item.id === draggedId)?.item;
        let targetItem = rowData.find(rd => rd.item.id === id)?.item;
        if (!draggedItem || !targetItem || !dropTargetVisualization?.location) {
          return;
        }
        onDropItem(
          draggedItem,
          targetItem,
          dropTargetVisualization?.location!,
          event.getModifierState('Control') ? 'copy' : 'move',
        );
      },
      [draggedId, dropTargetVisualization?.location, onDropItem, rowData],
    ),
  };

  return { dropTargetVisualization, dragEventHandlers };
}
