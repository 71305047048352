import * as React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';

import styles from './ActivityFeedExplanation.module.scss';
import { FormattedMessage } from 'react-intl';

export default class ActivityFeedExplanation extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired
  };

  state = {
    show: false
  };

  toggle = () => this.setState(({ show }) => ({ show: !show }));

  render() {
    return (
      <React.Fragment>
        <Button className={styles.root} onClick={this.toggle} bsStyle="link">
          <i className="fa fa-info-circle" />
        </Button>
        <Modal show={this.state.show} onHide={this.toggle}>
          <Modal.Header closeButton>
            <Modal.Title>
              <FormattedMessage
                id="ACTIVITY_FEED_EXPLANATION.TITLE"
                defaultMessage="Activity Feed"
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormattedMessage
              id="ACTIVITY_FEED_EXPLANATION.P1"
              defaultMessage="The activity feed shows a chronological feed of activity by all users for tickets and checklists which are relevant to you."
              tagName="p"
            />
            <FormattedMessage
              id="ACTIVITY_FEED_EXPLANATION.P2"
              defaultMessage="A ticket or checklist is considerred relevant to you when one of the following conditions apply:"
              tagName="p"
            />
            <ul>
              <FormattedMessage
                id="ACTIVITY_FEED_EXPLANATION.L1"
                defaultMessage="The ticket/checklist has been created by you"
                tagName="li"
              />
              <FormattedMessage
                id="ACTIVITY_FEED_EXPLANATION.L2"
                defaultMessage="The ticket/checklist is part of a 'work item' which you are following"
                tagName="li"
              />
              <FormattedMessage
                id="ACTIVITY_FEED_EXPLANATION.L3"
                defaultMessage="The ticket/checklist has been assigned to you"
                tagName="li"
              />
              <FormattedMessage
                id="ACTIVITY_FEED_EXPLANATION.L4"
                defaultMessage="You have posted something to the ticket"
                tagName="li"
              />
            </ul>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }
}
