import identity from 'lodash.identity';
import complement from 'ramda/es/complement';
import * as React from 'react';
import { Dispatch } from 'redux';
import { Catalog, CatalogFile, CatalogGroup } from '../../models/Catalog';
import { Project } from '../../models/Project';
import { CatalogUI } from '../../modules/catalogUI';
import { FileDownloadsState } from '../../modules/fileDownloads';
import { FilesState } from '../../modules/files';
import combineFilters from '../../utils/combineFilters';
import { ProjectCatalogDetailsBreadcrumbs } from '../BreadCrumbs';
import CatalogContents from '../CatalogContents';
import Page from '../Page';
import ParameterizedSearch from '../ParameterizedSearch';
import {
  ParametersOptions,
  parseParameters,
} from '../ParameterizedSearch/model';
import Spinner from '../Spinner';
import { useFoldAllHandler, useUnfoldAllHandler } from './catalogFoldingHooks';
import FoldComponent from './FoldComponent';
import stl from './ProjectCatalogDetailsPage.module.scss';
import useDeepLinkFileDownload from './useDeepLinkFileDownload';
import CatalogNotificationsButton from './CatalogNotificationsButton';

interface Props {
  catalog?: Catalog;
  catalogUI: CatalogUI;
  project?: Project;
  expandedFile?: number;
  dispatch: Dispatch;
  files: FilesState;
  fileDownloads: FileDownloadsState;
  online: boolean;
}

const DEFAULT_FILTER = 'has_file:"true"';
const ProjectCatalogDetailsPage: React.FC<Props> = ({
  project,
  dispatch,
  catalog,
  ...rest
}) => {
  // TODO substitute active language
  const lang = 'en';

  // fold/unfold
  const handleFoldAll = useFoldAllHandler(dispatch, catalog);
  const handleUnfoldAll = useUnfoldAllHandler(dispatch, catalog);

  // download file if deep link is used
  useDeepLinkFileDownload(rest.expandedFile, catalog, rest.files);

  //search
  const [search, setSearch] = React.useState<string>(DEFAULT_FILTER);
  const resetFilter = React.useCallback(() => setSearch(DEFAULT_FILTER), [
    setSearch,
  ]);
  const clearFilter = React.useCallback(() => setSearch(''), [setSearch]);
  const removeExtensionDot = (e: string): string => e.replace(/^\./, '');
  const options = React.useMemo<ParametersOptions>(
    () => ({
      small: false,
      noInput: false,
      parameters: {
        has_file: {
          name: 'has_file',
          icon: 'file',
          options: ['true', 'false'],
        },
        type: {
          name: 'type',
          icon: 'file-pdf-o',
          options:
            catalog?.files
              .map(f => f.extension)
              .filter(f => f)
              .reduce<string[]>(
                (fs, e) => (fs.includes(e) ? fs : [...fs, e]),
                [],
              )
              .map(removeExtensionDot) || [],
        },
      },
    }),
    [catalog],
  );

  const [catalogParamFilter, catalogTextFilter] = React.useMemo<
    [
      (f: CatalogFile) => boolean | null,
      (fg: CatalogFile | CatalogGroup) => boolean | null,
    ]
  >(() => {
    const { parameters, rest } = parseParameters(search);
    const paramFilters: Array<(f: CatalogFile) => boolean> = [];

    // filter by type param
    const type = parameters
      .filter(({ name }) => name === 'type')
      .map(p => p.value);
    if (type.length) {
      paramFilters.push(f =>
        type.includes(f.extension && removeExtensionDot(f.extension)),
      );
    }

    // filter by has file
    const hasFile = parameters.filter(({ name }) => name === 'has_file');
    if (hasFile.length) {
      const possiblyNegate: <T>(arg: T) => T = (hasFile[0].value === 'true'
        ? identity
        : complement) as any;

      paramFilters.push(possiblyNegate(f => f.revision_nr !== null));
    }
    let textFilter;
    if (rest) {
      const REST = rest.toUpperCase();
      textFilter = (fg: CatalogFile | CatalogGroup) =>
        !!fg?.[lang]?.toUpperCase().includes(REST);
    } else {
      textFilter = () => null;
    }

    return [
      paramFilters.length ? combineFilters(paramFilters) : () => null,
      textFilter,
    ];
  }, [search, lang]);

  return (
    <Page title="Docs Catalog" className={stl.root}>
      {project && (
        <ProjectCatalogDetailsBreadcrumbs
          projectName={
            <>
              {project.projectname} {project.id && <em>- {project.id}</em>}
            </>
          }
          projectId={project.recnum}
        />
      )}
      <ParameterizedSearch
        className={stl.search}
        onChange={setSearch}
        onClear={search ? clearFilter : undefined}
        onReset={search !== DEFAULT_FILTER ? resetFilter : undefined}
        options={options}
        value={search}
      />
      <div className={stl.title}>
        <h2>{project?.projectname}</h2>
        {catalog && <CatalogNotificationsButton catalog={catalog} />}
      </div>
      {catalog?.groups && project ? (
        <>
          <FoldComponent
            onFoldAll={handleFoldAll}
            onUnfoldAll={handleUnfoldAll}
          />
          <CatalogContents
            catalog={catalog}
            dispatch={dispatch}
            projectRecnum={project.recnum}
            catalogParamFilter={catalogParamFilter}
            catalogTextFilter={catalogTextFilter}
            onClear={search ? clearFilter : undefined}
            onReset={search !== DEFAULT_FILTER ? resetFilter : undefined}
            {...rest}
          />
        </>
      ) : (
        <Spinner />
      )}
    </Page>
  );
};

export default ProjectCatalogDetailsPage;
