export const departmentMap: any = {
  V: 'climate',
  E: 'electrical',
  K: 'greenhouse',
  W: 'irrigation',
  S: 'service',
  C: 'climate-cells',
  R: 'R&D',
};

export default function charToDepartment(dep: string): string | null {
  return departmentMap[dep] || null;
}
