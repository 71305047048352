import documentation from './documentation.json';

export interface TocEntry {
  content: string;
  slug: string;
  lvl: number;
}

export interface Documentation {
  /** The location of the documentation-file in the public folder with extension omitted*/
  path: string;
  /** Wether or not this page is exclusively for internal users */
  internal: boolean;
  /** The markdown contents of the documentation file */
  contents: string;
  toc: TocEntry[];
}

export default documentation as Documentation[];
