import * as React from 'react';
import {
  ButtonHTMLAttributes,
  DetailedHTMLFactory,
  TextareaHTMLAttributes,
} from 'react';
import {
  Completion,
  Lex,
  LexMatch,
  parseForCompletions,
} from '../../utils/LexicalParsing';
import { Suggestion } from '../types';
import { classNames, ClassValue } from '../util/ClassNames';
import { insertText } from '../util/InsertTextAtPosition';
import {
  CaretCoordinates,
  getCaretCoordinates,
} from '../util/TextAreaCaretPosition';
import { ComponentSimilarTo } from '../util/type-utils';
import { SuggestionsDropdown } from './SuggestionsDropdown';

const useTextareaSelection = (
  textareaRef: React.MutableRefObject<HTMLTextAreaElement | undefined>,
) => {
  const [selection, setSelection] = React.useState({ start: 0, end: 0 });

  const handleSelectionChange = React.useCallback(() => {
    const { selectionStart, selectionEnd } = textareaRef.current!;
    if (selection.start !== selectionStart || selection.end !== selectionEnd) {
      setSelection({ start: selectionStart, end: selectionEnd });
    }
  }, [selection.end, selection.start, textareaRef]);

  React.useEffect(() => {
    const textarea = textareaRef?.current;
    if (textarea) {
      textarea.addEventListener('mouseup', handleSelectionChange);
      textarea.addEventListener('keyup', handleSelectionChange);
      return () => {
        textarea.removeEventListener('mouseup', handleSelectionChange);
        textarea.removeEventListener('keyup', handleSelectionChange);
      };
    }
  }, [handleSelectionChange, textareaRef]);

  return [selection, handleSelectionChange] as const;
};

const useElementFocus = (
  elementRef: React.MutableRefObject<HTMLElement | undefined>,
) => {
  const [isFocused, setIsFocused] = React.useState(false);

  React.useEffect(() => {
    const handleFocus = () => {
      setIsFocused(true);
    };

    const handleBlur = () => {
      setIsFocused(false);
    };

    const element = elementRef?.current;

    if (element) {
      setIsFocused(element === document.activeElement);
      element.addEventListener('focus', handleFocus);
      element.addEventListener('blur', handleBlur);
    } else setIsFocused(false);

    return () => {
      if (element) {
        element.removeEventListener('focus', handleFocus);
        element.removeEventListener('blur', handleBlur);
      }
    };
  }, [elementRef]);

  return isFocused;
};

export type RenderCompletion = (
  value: string,
  lex: LexMatch,
  comleteLex: LexMatch,
  data: any,
) => React.ReactNode;

export interface TextAreaState {
  // mention: MentionState;
  caret: CaretCoordinates | null;
  focusIndex: number;
  declinedCompletions: boolean;
}

export interface TextAreaProps {
  classes?: ClassValue;
  suggestionsDropdownClasses?: ClassValue;
  value: string;
  onChange: (value: string) => void;
  refObject?: React.RefObject<HTMLTextAreaElement>;

  renderCompletion?: RenderCompletion;

  readOnly?: boolean;
  height?: number;
  heightUnits?: string;
  suggestionTriggerCharacters?: string[];
  suggestionsAutoplace?: boolean;
  loadSuggestions?: (
    text: string,
    triggeredBy: string,
  ) => Promise<Suggestion[]>;

  onPaste: React.ClipboardEventHandler;
  onDrop: React.DragEventHandler;

  lexicalCompletionTree?: Lex[];

  /**
   * Custom textarea component. "textAreaComponent" can be any React component which
   * props are a subset of the props of an HTMLTextAreaElement
   */
  textAreaComponent?: ComponentSimilarTo<
    HTMLTextAreaElement,
    TextareaHTMLAttributes<HTMLTextAreaElement>
  >;
  toolbarButtonComponent?: ComponentSimilarTo<
    HTMLButtonElement,
    ButtonHTMLAttributes<HTMLButtonElement>
  >;
  textAreaProps?: Partial<
    React.DetailedHTMLProps<
      React.TextareaHTMLAttributes<HTMLTextAreaElement>,
      HTMLTextAreaElement
    >
  >;
  /**
   * On keydown, the TextArea will trigger "onPossibleKeyCommand" as an opportunity for React-Mde to
   * execute a command. If a command is executed, React-Mde should return true, otherwise, false.
   */
  onPossibleKeyCommand?: (
    e: React.KeyboardEvent<HTMLTextAreaElement>,
  ) => boolean;
}

export const TextArea: React.FC<TextAreaProps> = ({
  renderCompletion = v => v,
  classes,
  height,
  heightUnits,
  lexicalCompletionTree = [],
  onChange,
  onDrop,
  onPaste,
  onPossibleKeyCommand,
  readOnly,
  suggestionsDropdownClasses,
  textAreaComponent,
  textAreaProps,
  refObject,
  value,
}) => {
  const [focusIndex, setFocusIndex] = React.useState<number>(0);
  const [caret, setCaret] = React.useState<CaretCoordinates>({
    left: 0,
    top: 0,
    lineHeight: 0,
  });
  const [declinedCompletions, setDeclinedCompletions] = React.useState<boolean>(
    false,
  );
  const [
    lastSelectedCompletion,
    setLastSelectedCompletion,
  ] = React.useState<null | { id: string; value: Completion; index: number }>(
    null,
  );

  const textareaRef = refObject as any; //React.useRef<HTMLTextAreaElement>();
  const [selection, triggerSelectionUpdate] = useTextareaSelection(textareaRef);

  React.useEffect(
    () =>
      textareaRef?.current &&
      setCaret(getCaretCoordinates(textareaRef.current!)),
    [selection, textareaRef],
  );

  const focus = useElementFocus(textareaRef);

  const completions = React.useMemo(() => {
    const c = parseForCompletions(
      value.substring(0, selection.start),
      lexicalCompletionTree!,
    );

    // if one of the completions is the last selection, discard them all, because the user has allready made a choice
    if (
      c.some(
        cc =>
          cc.lex.text === lastSelectedCompletion?.value.text &&
          cc.lex.index === lastSelectedCompletion.index,
      )
    ) {
      return [];
    }
    return c;
  }, [lastSelectedCompletion, lexicalCompletionTree, selection.start, value]);

  const renderedCompletions = React.useMemo(
    () =>
      completions.map(
        ({ value, lex, completeLex }) =>
          renderCompletion(value.text, lex, completeLex, value.data) ||
          value.text,
      ),
    [completions, renderCompletion],
  );

  const handleOnChange = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      textAreaProps?.onChange?.(event);

      const textarea = textareaRef.current!;
      if (
        declinedCompletions &&
        parseForCompletions(
          textarea.value.substring(0, textarea.selectionStart) || '',
          lexicalCompletionTree!,
        ).length === 0
      ) {
        setDeclinedCompletions(false);
      }

      onChange(event.target.value);
    },
    [
      declinedCompletions,
      lexicalCompletionTree,
      onChange,
      textAreaProps,
      textareaRef,
    ],
  );

  const handleSuggestionSelected = React.useCallback(
    (index: number) => {
      const textarea = textareaRef.current!;

      const { value, lex } = completions[index % completions.length];

      textarea.selectionStart = lex.index;
      textarea.selectionEnd = lex.index + lex.totalLength;
      insertText(textarea, value.text);

      // FIXME: the selection and is not updating if this is run via a non keyboard event
      triggerSelectionUpdate();

      setLastSelectedCompletion({
        id: lex.id,
        value,
        index: lex.index,
      });
    },
    [completions, textareaRef, triggerSelectionUpdate],
  );

  const handleKeyDown = React.useCallback<
    React.KeyboardEventHandler<HTMLTextAreaElement>
  >(
    event => {
      if (onPossibleKeyCommand?.(event)) {
        event.preventDefault();
        return;
      }
      const { key, shiftKey, ctrlKey } = event;
      if (
        completions.length &&
        !declinedCompletions &&
        (key === 'ArrowUp' || key === 'ArrowDown') &&
        !shiftKey
      ) {
        event.preventDefault();
        const focusDelta = key === 'ArrowUp' ? -1 : 1;
        setFocusIndex(idx => idx + focusDelta);
      } else if (key === 'Enter' && ctrlKey) {
        event.preventDefault();
        const submitEvent = new Event('submit', {
          bubbles: true, // Allow the event to bubble up the DOM tree
          cancelable: true, // Allow the event to be canceled
        });
        const textarea = textareaRef.current!;
        textarea.form.dispatchEvent(submitEvent);
      } else if (key === 'Enter' && !shiftKey && completions.length) {
        event.preventDefault();
        handleSuggestionSelected(focusIndex);
      } else if (
        key === 'Escape' &&
        !declinedCompletions &&
        completions.length
      ) {
        setDeclinedCompletions(true);
      } else if (key === ' ' && declinedCompletions && ctrlKey) {
        setDeclinedCompletions(false);
      }
    },
    [
      completions.length,
      declinedCompletions,
      focusIndex,
      handleSuggestionSelected,
      onPossibleKeyCommand,
      textareaRef,
    ],
  );

  const heightVal = height && heightUnits ? height + heightUnits : height;
  const TextAreaComponent = (textAreaComponent ||
    'textarea') as DetailedHTMLFactory<
    TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  >;

  return (
    <div className="mde-textarea-wrapper">
      <TextAreaComponent
        className={classNames('mde-text', classes)}
        style={{ height: heightVal }}
        ref={textareaRef as any}
        readOnly={readOnly}
        value={value}
        data-testid="text-area"
        {...textAreaProps}
        onChange={handleOnChange}
        onKeyDown={handleKeyDown}
        // onKeyPress={event => {
        //   textAreaProps?.onKeyPress?.(event);
        //   if (suggestionsEnabled) {
        //     this.handleKeyPress(event);
        //   }
        // }}
        onPaste={event => {
          textAreaProps?.onPaste?.(event);
          onPaste(event);
        }}
        onDragOver={event => {
          event.preventDefault();
          event.stopPropagation();
        }}
        onDrop={event => {
          textAreaProps?.onDrop?.(event);
          onDrop(event);
          event.preventDefault();
        }}
      />
      {(renderedCompletions?.length && !declinedCompletions && focus && (
        <SuggestionsDropdown
          classes={suggestionsDropdownClasses}
          caret={caret!}
          suggestions={renderedCompletions}
          onSuggestionSelected={handleSuggestionSelected}
          focusIndex={focusIndex % completions.length}
          textAreaRef={textareaRef as any}
        />
      )) ||
        null}
      {/* <Json>
        {{
          // lexes: parseAll(value, lexicalCompletionTree!).map(stripLexes),
          selection,
          focusIndex,
          completions: completions.map(c => c.value),
        }}
      </Json> */}
    </div>
  );
};
