import { defineMessages } from 'react-intl';
export const msg = defineMessages({
  FEEDBACK_DELETED_QUESTION: {
    id: 'TEMPLATE_DETAILS.FEEDBACK_DELETED_QUESTION',
    defaultMessage: 'Deleted Question',
    description: ''
  },
  FEEDBACK_INSERT_ABOUT: {
    id: 'TEMPLATE_DETAILS.FEEDBACK_INSERT_ABOUT',
    defaultMessage: 'about',
    description: ''
  },
  FEEDBACK_INSERT_ON: {
    id: 'TEMPLATE_DETAILS.FEEDBACK_INSERT_ON',
    defaultMessage: 'on',
    description: ''
  },
  FEEDBACK_DONE: {
    id: 'TEMPLATE_DETAILS.FEEDBACK_DONE',
    defaultMessage: 'Done',
    description: ''
  },
  FEEDBACK_OF_LIST: {
    id: 'TEMPLATE_DETAILS.FEEDBACK_OF_LIST',
    defaultMessage: 'of list'
  },
  FEEDBACK_HANDLED_BY: {
    id: 'TEMPLATE_DETAILS.FEEDBACK_HANDLED_BY',
    defaultMessage: 'handled by {user}'
  }
});
