import * as React from 'react';
import Autocomplete from 'react-autocomplete';
import { Button, ControlLabel, Form, FormGroup, Modal } from 'react-bootstrap';
import { defineMessages, FormattedMessage } from 'react-intl';
import { ReactReduxContext } from 'react-redux';
import useProjectSearch from '../../hooks/useProjectSearch';
import { Project } from '../../models/Project';
import { updateProject } from '../../modules/projects';
import stl from './ProjectSelection.module.scss';

const msg = defineMessages({
  SEARCH_HEADER: {
    id: 'PROJECTS_OVERVIEW_COMPONENT.SEARCH_HEADER',
    defaultMessage: 'Search for Project',
    description: '',
  },
  ADD_BUTTON: {
    id: 'PROJECTS_OVERVIEW_COMPONENT.ADD_BUTTON',
    defaultMessage: 'Add',
    description: '',
  },
  CANCEL_BUTTON: {
    id: 'PROJECTS_OVERVIEW_COMPONENT.CANCEL_BUTTON',
    defaultMessage: 'Cancel',
    description: '',
  },
});

const getProjectRecnum = (p: Project) => p.recnum.toString();
const matchProjectToTerm = (p: Project, term: string) => {
  const TERM = term.toUpperCase();
  return !!(
    (p.projectname && p.projectname.toUpperCase().includes(TERM)) ||
    (p.id && p.id.toUpperCase().includes(TERM)) ||
    (p.klant && p.klant.toUpperCase().includes(TERM)) ||
    (p.klant_naam && p.klant_naam.toUpperCase().includes(TERM))
  );
};
const renderMenu = (children: React.ReactNode) => (
  <ul className="dropdown-menu">{children}</ul>
);
const renderInput = (props: any) => (
  <input
    key="input"
    {...props}
    name="project"
    placeholder={'Typ om te zoeken (minstens 3 karakters)'}
    className="form-control"
  />
);

const renderProjectItem = (p: Project, isHighlighted: boolean) => (
  <li className={`item ${isHighlighted ? stl.highlighted : ''}`}>
    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
    <a title={p.id}>{p.projectname}</a>
  </li>
);

interface Props {
  visible: boolean;
  onToggleVisible: () => void;
}

const SearchProjectDialog: React.FC<Props> = ({ visible, onToggleVisible }) => {
  // search value and selected project
  const [value, setValue] = React.useState<string>('');
  const projects = useProjectSearch(value); // OMG hooks are awesome 😱

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.value);
    },
    [setValue],
  );

  const [projectId, setProjectId] = React.useState<number | null>(null);
  const handleSelect = React.useCallback(
    (value: string, project: Project) => {
      setValue(project.projectname);
      setProjectId(project.recnum);
    },
    [setValue, setProjectId],
  );

  const handleReset = React.useCallback(() => {
    setProjectId(null);
    setValue('');
    onToggleVisible();
  }, [onToggleVisible]);

  const {
    store: { dispatch, getState },
  } = React.useContext(ReactReduxContext);

  const handleSubmit = React.useCallback<React.FormEventHandler<Form>>(
    async event => {
      event.preventDefault();
      const { user } = getState();

      if (projectId) {
        await dispatch(
          updateProject(projectId, {
            associate: user.recnum,
          }) as any,
        );
        handleReset();
      }
    },
    [getState, projectId, dispatch, handleReset],
  );

  return (
    <Modal show={visible} onHide={handleReset}>
      <Modal.Header>
        <FormattedMessage {...msg.SEARCH_HEADER} />
      </Modal.Header>
      <Modal.Body>
        <Form id="daform" onSubmit={handleSubmit} onReset={handleReset}>
          <FormGroup controlId="project">
            <ControlLabel>Project</ControlLabel>

            <Autocomplete
              value={value}
              items={projects}
              onChange={handleChange}
              shouldItemRender={matchProjectToTerm}
              getItemValue={getProjectRecnum}
              onSelect={handleSelect}
              selectOnBlur
              renderInput={renderInput}
              renderItem={renderProjectItem}
              wrapperProps={{
                className: 'dropdown open',
                style: { display: 'block' },
              }}
              renderMenu={renderMenu}
            />
          </FormGroup>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button form="daform" type="reset">
          <FormattedMessage {...msg.CANCEL_BUTTON} />
        </Button>
        <Button
          form="daform"
          type="submit"
          bsStyle="primary"
          disabled={!projectId}
        >
          <FormattedMessage {...msg.ADD_BUTTON} />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SearchProjectDialog;
