import { useContext, useEffect, useState } from 'react';
import { ReactReduxContext } from 'react-redux';
import { ActionCreator } from 'redux';
import ignoreRejection from './ignoreRejection';

export default function useInitialDispatch(
  ...actionCreators: Array<ActionCreator<any>>
) {
  const {
    store: { dispatch },
  } = useContext(ReactReduxContext);
  const [initialActionCreators] = useState(actionCreators);

  useEffect(() => {
    // TODO: fix types
    const promises = initialActionCreators
      .map(actionCreator => actionCreator())
      .filter(x => x)
      .map(dispatch as any)
      .filter((x: any) => typeof x.then === 'function') as Array<Promise<any>>;

    ignoreRejection(...promises);
  }, [dispatch, initialActionCreators]);
}
