import * as React from 'react';
const FsLightboxProm = import('fslightbox-react');

function usePromise<T>(prom: Promise<T>): T | null {
  const [r, set] = React.useState<T | null>(null);
  React.useEffect(() => {
    let active = true;
    prom.then(c => active && set(c));
    return () => {
      active = false;
    };
  }, [prom]);
  return r;
}

interface ILightboxProps {}

const Lightbox: React.FC<ILightboxProps> = props => {
  const FsLightBox: any = usePromise(FsLightboxProm);
  React.useEffect(() => {
    return () => {
      document.documentElement.classList.remove('fslightbox-open');
    };
  });
  return FsLightBox ? <FsLightBox.default {...props} /> : null;
};

export default Lightbox;
