import { CountingRequest } from '@certhon/domain-models/lib';
import { MakeTree } from '../../components/Tree/common';
import { LocationWithDetails, Stock } from '../../modules/locations';
import { CountingState } from '../../stores/countingStore';
import { countingInCountingRequestRange } from './countingInCountingRequestRange';

export interface CountingStats {
  total: number;
  counted: number;
}

export type CountingsStatsIndex = Record<string, CountingStats>;

export function calculateRemainingCountingsStats(
  indexRef: CountingsStatsIndex,
  location: MakeTree<LocationWithDetails>,
  countings: CountingState,
  countingRequest: CountingRequest,
  filterFunction: (location_id: string, stock: Stock) => boolean,
): CountingStats {
  // process children, obtain stats of direct children
  const childStats = location.children.map(child =>
    calculateRemainingCountingsStats(
      indexRef,
      child,
      countings,
      countingRequest,
      filterFunction,
    ),
  );

  // process own stock
  const stats: CountingStats = { total: 0, counted: 0 };
  for (const stock of location.stock) {
    if (!filterFunction(location.id, stock)) {
      continue;
    }
    stats.total++;
    if (
      countings[location.id]?.[stock.recnum]?.some(
        countingInCountingRequestRange(countingRequest),
      )
    ) {
      stats.counted++;
    }
  }
  // agg stats of children
  for (const cstats of childStats) {
    stats.total += cstats.total;
    stats.counted += cstats.counted;
  }

  // assign to index
  indexRef[location.id] = stats;

  // diddelydonedidit
  return stats;
}
