import { assertNever } from '../../../../modules/utils';
import { FileAttachment } from '../../types';
import {
  createModificationCreator,
  ListEventReducer,
} from '../ListModification';
import {
  patchListNode,
  transferModificationPropertiesToAttachment,
} from '../util';

export interface AddAttachmentPayload {
  targetNodeId: string;
  attachment: {
    contentType: string;
    checksum: string;
    size: number;
  };
}

export interface RemoveAttachmentPayload {
  targetNodeId: string;
  checksum: string;
}

export const addAttachment = createModificationCreator<
  AddAttachmentPayload,
  'ADD_ATTACHMENT'
>('ADD_ATTACHMENT');
export const removeAttachment = createModificationCreator<
  RemoveAttachmentPayload,
  'REMOVE_ATTACHEMNT'
>('REMOVE_ATTACHEMNT');

export type AddAttachmentListModification = ReturnType<typeof addAttachment>;
export type RemoveAttachmentListModification = ReturnType<
  typeof removeAttachment
>;

export type AttachmentListModification =
  | AddAttachmentListModification
  | RemoveAttachmentListModification;

const attachment: ListEventReducer<AttachmentListModification> = (
  list,
  mod: AttachmentListModification,
) =>
  patchListNode(list, mod.payload.targetNodeId, targetNode => {
    if (targetNode.type === 'ROOT_FIELD' || targetNode.type === 'GROUP_FIELD') {
      throw new Error(
        `Unexpected non-value targetNode type (${targetNode.type}).`,
      );
    }
    if (mod.type === 'ADD_ATTACHMENT') {
      const fileAttachment: FileAttachment = {
        type: 'attachment',
        ...mod.payload.attachment,
        ...transferModificationPropertiesToAttachment(mod),
      };
      return {
        ...targetNode,
        attachments: [...targetNode.attachments, fileAttachment],
      };
    } else if (mod.type === 'REMOVE_ATTACHEMNT') {
      return {
        ...targetNode,
        attachments: targetNode.attachments.filter(
          att =>
            att.type === 'attachment' && att.checksum !== mod.payload.checksum,
        ),
      };
    }
    assertNever(mod);
    throw new Error();
  });

export default attachment;
