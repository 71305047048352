import { defineMessages } from 'react-intl';
export default defineMessages({
  descriptionPlaceholder: {
    id: 'GEOTAG_PANEL.DESCRIPTION_PLACEHOLDER',
    defaultMessage: 'Description of location (optional)',
    description: 'Placeholder for description field',
  },

  failureAccessDenied: {
    id: 'GEOTAG_PANEL.FAILURE_ACCESS_DENIED',
    defaultMessage: 'Failed: Access to position denied',
  },

  failurePositionUnavailable: {
    id: 'GEOTAG_PANEL.FAILURE_POSITION_UNAVAILABLE',
    defaultMessage: 'Failed: position unavailable.',
  },

  failureTimeout: {
    id: 'GEOTAG_PANEL.FAILURE_TIMEOUT',
    defaultMessage: 'Failed: Obtaining position takes too long.',
  },

  failureUnknown: {
    id: 'GEOTAG_PANEL.FAILURE_UNKNOWN',
    defaultMessage: 'Failed: Unknown reason.',
  },

  status: {
    id: 'GEOTAG_PANEL.STATUS',
    defaultMessage: 'Status',
    description: 'label for current status',
  },

  statusInactive: {
    id: 'GEOTAG_PANEL.STATUS_INACTIVE',
    defaultMessage: 'Inactive',
  },

  statusImproveAccuracy: {
    id: 'GEOTAG_PANEL.STATUS_IMPROVE_ACCURACY',
    defaultMessage: 'Improving accuracy...',
  },

  statusObtainingPosition: {
    id: 'GEOTAG_PANEL.STATUS_OBTAINING_POSITION',
    defaultMessage: 'Obtaining position...',
  },

  accuracy: {
    id: 'GEOTAG_PANEL.ACCURACY',
    defaultMessage: 'Accuracy',
    description: 'label for accuracy',
  },

  description: {
    id: 'GEOTAG_PANEL.DESCRIPTION',
    defaultMessage: 'Description',
    description: 'Label for description',
  },

  cancel: {
    id: 'GEOTAG_PANEL.CANCEL',
    defaultMessage: 'Cancel',
    description: 'label of cancel button',
  },

  add: {
    id: 'GEOTAG_PANEL.ADD',
    defaultMessage: 'Add',
    description: 'Label of submit button',
  },
});
