import unnest from 'lodash/fp/unnest';
import { propEq, update } from 'ramda';
import { BuiltList, List } from '../../../models/List';
import { softAssertNever } from '../../../modules/utils';
import { ObjectMap } from '../../logic/types';
import {
  Attachment,
  Field,
  PartialField,
  RootField,
  createCheckpointField,
  createGroupField,
  createNumericField,
  createTaskField,
} from '../types';
import { ListEvent } from './ListModification';
import { ExpandProtoListModification } from './operations/expandProto';
import { PatchListModification } from './operations/patch';

/**
 * Adjust a single node and patch the tree and index immutably
 * @param  {Object} list
 * @param  {string} targetNodeId
 * @param  {function} func
 */
export function patchListNode(
  list: BuiltList,
  targetNodeId: string,
  modFunc: (node: Field) => Field,
): BuiltList {
  // create a chain of fields representing the branch from the rootNode to the targetNode
  const nodes: Field[] = [
    /* rootNode, parentNode, ..., parentNode, targetNode */
  ];
  let curNode = list.index[targetNodeId];
  if (!curNode) {
    // if we can not find the targetNode we return an unmodified list
    // this can happen when:
    //   - a deletion and modification have been made seperately and are merged
    //   - changes in an unsaved protolist have been discarded
    return list;
  }
  do {
    nodes.unshift(curNode);
    if (!curNode.parentNodeId) {
      break; // the root node has been reached
    }
    const nextNode: Field | undefined = list.index[curNode.parentNodeId];
    if (!nextNode) {
      // if we can not find the parentNode we return a unmodified list
      // this can happen when:
      //   - a deletion and modification have been made seperately and are merged
      //   - changes in an unsaved protolist have been discarded
      return list;
    }
    curNode = nextNode;
  } while (curNode);

  const index = { ...list.index };
  const patch = (node: Field): Field => {
    const nextNode = nodes.shift();
    let newNode;
    if (nextNode) {
      if (node.type !== 'GROUP_FIELD' && node.type !== 'ROOT_FIELD') {
        throw new Error('Expected structure node whilst patching node chain');
      }
      const nextNodeIndex = node.children.indexOf(nextNode);
      const children = update(nextNodeIndex, patch(nextNode), node.children);
      newNode = { ...node, children };
    } else {
      newNode = modFunc(node);
    }

    index[node.id] = newNode;
    return newNode;
  };
  const tree = patch(nodes.shift() as Field) as RootField;

  return {
    ...list,
    tree,
    index,
  };
}

export const isModificationOfType = (type: ListEvent['type']) => (
  mod: ListEvent,
) => mod.type === type;

export const isExpandProtoListModification = isModificationOfType(
  'EXPAND_PROTO',
) as (mod: ListEvent) => mod is ExpandProtoListModification;

/**
 * recursively collect all child nodes, including self
 * @param node
 */
export function getAllDescendants(node: Field): Field[] {
  if (node.type === 'ROOT_FIELD' || node.type === 'GROUP_FIELD') {
    return [node, ...unnest(node.children.map(getAllDescendants))];
  }
  return [node];
}

/**
 * Takes a partial field and parentNodeId and create a 'complete' field of the approriate type
 * @param partialField
 * @param parentNodeId
 */
export function completePartialField(
  partialField: PartialField,
  parentNodeId: string,
): Field {
  if (partialField.type === 'GROUP_FIELD') {
    return createGroupField(partialField, parentNodeId);
  } else if (partialField.type === 'CHECKPOINT_FIELD') {
    return createCheckpointField(partialField, parentNodeId);
  } else if (partialField.type === 'NUMERIC_FIELD') {
    return createNumericField(partialField, parentNodeId);
  } else if (partialField.type === 'TASK_FIELD') {
    return createTaskField(partialField, parentNodeId);
  }
  softAssertNever(partialField);
  throw new Error('Unexpected partial field type');
}

/**
 * Parse a date or throw
 * @param str
 */
const parseDate = (str: string | Date): Date => {
  const date = new Date(str);
  if (isNaN(date.getTime())) {
    throw new Error(`Could not parse date string: ${str}`);
  }
  return date;
};

/**
 * This functions merges modifications that offer to much history granularity to reduce
 * data usage.
 * The main example, multiple sequential modifications (keystrokes) to the same node in a short period of time
 *
 */
export function mergeSimilarModifications(events: ListEvent[], since = null) {
  const newModifications = [];
  let sincePassed = !since;
  let pendingEvent: PatchListModification | null = null;
  for (const event of events) {
    if (sincePassed) {
      // dont do anything until AFTER the since-mod has passed
      if (pendingEvent) {
        // merge two sequential PATCH-mods of the same node if they occur within 60 sec
        if (
          pendingEvent &&
          event.type === 'PATCH' &&
          pendingEvent.user_id === event.user_id &&
          pendingEvent.payload.targetNodeId === event.payload.targetNodeId &&
          parseDate(event.created).getTime() -
            parseDate(pendingEvent.created).getTime() <
            60e3
        ) {
          pendingEvent = {
            ...event, // use timestmap of last mod (rest is the same)
            id: pendingEvent.id, // use id of first mod as this may allready be used as reference
            payload: {
              ...event.payload,
              partialNode: {
                ...(pendingEvent.payload.partialNode as object),
                ...event.payload.partialNode,
              },
            },
          };
          continue;
        }
      }
    }
    // pending mod from the previous iteration did not match -> push to array
    if (pendingEvent) {
      newModifications.push(pendingEvent);
      pendingEvent = null;
    }
    if (event.type === 'PATCH' && sincePassed) {
      pendingEvent = event;
    } else {
      newModifications.push(event);
    }
    sincePassed = sincePassed || event.id === since;
  }
  if (since && !sincePassed) {
    throw new Error(
      `"since" (${since}) was specified but not found in the provided modifications`,
    );
  }

  // finally push the last pending mod
  if (pendingEvent) {
    newModifications.push(pendingEvent);
  }
  return newModifications;
}

/**
 * Extracts common properties to be transferred to the Attachment-object
 * @param mod
 */
export function transferModificationPropertiesToAttachment(
  mod: ListEvent,
): Pick<Attachment, 'id' | 'user_id' | 'created'> {
  return {
    id: mod.id,
    user_id: mod.user_id,
    created: mod.created,
  };
}

/**
 * Checks a wether a inserted protolist wil cause a circular dependency
 * @param targetListId
 * @param protoListId
 * @param listMap
 */
export function hasCircularDependency(
  targetListId: string,
  protoListId: string,
  listMap: ObjectMap<List>,
) {
  const lists: List[] = Object.values(listMap);
  if (targetListId === protoListId) {
    return true;
  }

  const protoList = lists.find(propEq('id', protoListId));
  if (!protoList) {
    throw new Error(`Could not find list for protoListId (${protoListId})`);
  }

  function findCircularProto(list: List): boolean {
    const protoMods: ExpandProtoListModification[] = list.events.filter(
      isExpandProtoListModification,
    );
    const found = !!protoMods.find(
      mod => mod.payload.protoListId === targetListId,
    );
    if (found) {
      return true;
    }
    // need to go deeper!
    return protoMods
      .map(mod => lists.find(propEq('id', mod.payload.protoListId)))
      .map(protoList => {
        if (!protoList) {
          throw new Error(`could not find list for proto`);
        }
        return protoList;
      })
      .some(findCircularProto);
  }
  return findCircularProto(protoList);
}
