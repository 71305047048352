import classNames from 'classnames';
import * as React from 'react';
import { Button } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { parseDate } from '../../common/logic/misc';
import { ExcludeNull } from '../../common/logic/types';
import { Project } from '../../models/Project';
import { StoreTicket } from '../../modules/tickets';
import { UsersState } from '../../modules/users';
import { Work } from '../../modules/works';
import FormattedRelative from '../FormattedRelative';
import StatusBadge from '../StatusBadge';
import UserToken from '../UserToken';
import LabelList from './LabelList';
import msg from './msg';
import TicketDescription from './TicketDescription';
import TicketDetailsDropdownMenu from './TicketDetailsDropdownMenuProps';
import styles from './Tickets.module.scss';
import UserSelection from './UserSelection';

interface TicketDetailsProps {
  onEdit: () => void;
  onToggleNotifications: () => void;
  onToggleStatus: () => void;
  project: Project;
  localTickets: Record<string, StoreTicket>;
  allTickets: Record<string, StoreTicket>;
  ticket: StoreTicket;
  users: ExcludeNull<UsersState>;
  work: Work;
}

const TicketDetails: React.FC<TicketDetailsProps> = ({
  onEdit,
  onToggleStatus,
  onToggleNotifications,
  ticket,
  users,
  work,
  project,
  localTickets,
  allTickets,
}) => {
  const intl = useIntl();
  const { title, assignee_user_id, executor_user_id, number } = ticket;

  const assignee = users[assignee_user_id!] || null;
  const executor = users[executor_user_id!] || null;

  return (
    <div className={styles.ticketDetails}>
      <h1>
        <span className={styles.number}>#{number || '?'}</span> {title}
        <div className="pull-right">
          <TicketDetailsDropdownMenu
            onEdit={onEdit}
            onToggleNotifications={onToggleNotifications}
            ticket={ticket}
          ></TicketDetailsDropdownMenu>
        </div>
      </h1>
      <div className="row">
        <div className="col-xs-12">
          <div className="form-group">
            <label>
              <FormattedMessage {...msg['TICKET_FIELDS.DESCRIPTION']} />
            </label>
            <TicketDescription
              localTickets={localTickets}
              allTickets={allTickets}
              users={users}
              description={ticket.description}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-6">
          <div className="form-group">
            <label className="control-label">Labels</label>
            <LabelList labelIds={ticket.labels} />
          </div>
        </div>
        <div className="col-xs-6">
          <div className="form-group">
            <label className="control-label">
              <FormattedMessage {...msg.notifiedUsers} />
            </label>
            <div className={styles.involvedUsers}>
              {/* FIXME: Expose involved users on tickets without events */}
              {ticket.notification_opt_in.map(u => (
                <UserToken className={styles.token} small user={users[u]} />
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="row visible-print-block">
        <div className="col-xs-6">
          <label>Project</label>
          <p>{project?.projectname}</p>
        </div>
        <div className="col-xs-6">
          <label>Work item</label>
          <p>{work?.klant}</p>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-6">
          <UserSelection
            name={intl.formatMessage(msg['TICKET_FIELDS.ASSIGNEE_USER_ID'])}
            user={assignee}
          />
        </div>
        <div className="col-xs-6">
          <UserSelection
            name={intl.formatMessage(msg['TICKET_FIELDS.EXECUTOR_USER_ID'])}
            user={executor}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-6">
          <label className="control-label">Status</label>
          <div className={styles.statusCtrl}>
            <StatusBadge status={ticket.status} />
            <Button bsStyle="link" onClick={onToggleStatus}>
              {ticket.status === 'opened'
                ? intl.formatMessage(msg.CLOSE_TICKET)
                : intl.formatMessage(msg.OPEN_TICKET)}
            </Button>
          </div>
        </div>
        <div className="col-xs-6">
          <label className="control-label">Due</label>
          <div className={styles.due}>
            {ticket.due ? (
              <FormattedRelative
                className={classNames(
                  parseDate(ticket.due).getTime() < Date.now() &&
                    styles.overDue,
                )}
                value={ticket.due}
              />
            ) : (
              <small className="text-muted">No due date</small>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TicketDetails;
