import omit from 'lodash/fp/omit';
import without from 'lodash/fp/without';
import { insert } from 'ramda';
import {
  createModificationCreator,
  ListEventReducer,
} from '../ListModification';
import { getAllDescendants, patchListNode } from '../util';

export interface MovePayload {
  targetNodeId: string;
  subjectNodeId: string;
  index?: number;
}

export const moveNode = createModificationCreator<MovePayload, 'MOVE'>('MOVE');

export type MoveListModification = ReturnType<typeof moveNode>;

const move: ListEventReducer<MoveListModification> = (
  list,
  mod: MoveListModification,
) => {
  const {
    payload: { index = -1, subjectNodeId, targetNodeId },
  } = mod;

  const subjectNode = list.index[subjectNodeId];
  if (!subjectNode) {
    // dont do anything if the node could not be found
    // this can happen when modifications are merged
    return list;
  }
  if (!subjectNode.parentNodeId) {
    throw new Error('Unexpected move-subject without parentNodeId');
  }

  let found = false;
  let newList = list;
  const newSubjectNode = { ...subjectNode, parentNodeId: targetNodeId };
  // move the subjectId to the new parent
  // if the parent is not found. remove the subject from the index.
  newList = patchListNode(newList, targetNodeId, parentNode => {
    if (parentNode.type !== 'GROUP_FIELD' && parentNode.type !== 'ROOT_FIELD') {
      throw new Error(`Unexpected targetNode type (${parentNode.type})`);
    }
    found = true;
    return {
      // 1. update inserted node parentNodeId
      // 2. modify the targetNode to include the inserted node
      ...parentNode,
      // 2.
      children: insert(
        index === -1 ? parentNode.children.length : index,
        // 1.
        newSubjectNode,
        parentNode.children,
      ),
    };
  });

  // remove the subjectnode from its parent
  newList = patchListNode(newList, subjectNode.parentNodeId, parentNode => {
    if (parentNode.type !== 'GROUP_FIELD' && parentNode.type !== 'ROOT_FIELD') {
      throw new Error(`Unexpected targetNode type (${parentNode.type})`);
    }

    const rv = {
      ...parentNode,
      children: without([subjectNode], parentNode.children),
    };
    return rv;
  });

  if (!found) {
    // remove the subject + descendants from the index
    newList.index = omit(
      getAllDescendants(subjectNode).map(f => f.id),
      newList.index,
    );
  } else {
    newList.index = { ...newList.index, [subjectNodeId]: newSubjectNode };
  }

  return newList;
};

export default move;
