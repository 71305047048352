/* eslint react/no-multi-comp: 0 */
import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { replace } from 'redux-first-history';
import jwt_decode from 'jwt-decode';
import { LoginDialog } from '../components/Login/index';
import { fetchUser } from '../modules/user';

const mapStateToProps = ({ user, online }) => ({
  user,
  online,
});

export function requireAuthentication(WrappedComponent) {
  class AuthenticatedComponent extends React.Component {
    static propTypes = {
      user: PropTypes.object,
      online: PropTypes.bool.isRequired,
    };

    state = {
      hasAttemptedFetch: false,
    };

    componentDidMount() {
      this.checkAuth(this.props.user);
    }

    componentDidUpdate(nextProps) {
      this.checkAuth(nextProps.user);
    }

    // it is possible that the user is authenticating using only a httponly cookie; this happens when the backend renders a pdf report on behaf of the user.
    // to accomodate this; we attempt to fetch the user details, to see wether the session is authorized.
    async checkAuth(user) {
      let redirect = '/login';
      if (
        window.location.pathname !== '/' &&
        window.location.pathname !== '/projects' &&
        !window.location.pathname.startsWith('/login')
      ) {
        redirect += '?r=' + encodeURIComponent(window.location.pathname);
      }
      if (!user) {
        if (!this.state.hasAttemptedFetch) {
          // there might be an authorization cookie..
          const result = await this.props.dispatch(fetchUser(undefined, true));

          if (result.error) {
            this.props.dispatch(replace(redirect));
          }
          this.setState({ hasAttemptedFetch: true });
        } else {
          this.props.dispatch(replace(redirect));
        }
      }
    }

    render() {
      const { user, dispatch, online } = this.props;
      const component = !!user ? <WrappedComponent {...this.props} /> : null;

      const isJwtExpired =
        user && Date.now() > jwt_decode(user.accessToken).exp * 1e3;

      return (
        <React.Fragment>
          {component}
          <LoginDialog
            show={
              ((user && isJwtExpired) || (user && user.invalidAccessToken)) &&
              online
            }
            dispatch={dispatch}
            username={user && user.loginname}
          />
        </React.Fragment>
      );
    }
  }

  AuthenticatedComponent.propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  return connect(mapStateToProps)(AuthenticatedComponent);
}

export function adminOnly(WrappedComponent) {
  class AdminComponent extends React.Component {
    static propTypes = {
      user: PropTypes.object.isRequired,
    };
    componentWillMount() {
      this.checkAdmin(this.props.user);
    }

    componentWillReceiveProps(nextProps) {
      this.checkAdmin(nextProps.user);
    }

    checkAdmin(user) {
      if (user && (!user.permissions || !user.permissions.QA_ADMIN)) {
        // Redirect non-admin
        this.props.dispatch(replace('/'));
      }
    }

    render() {
      const component = !!this.props.user ? (
        <WrappedComponent {...this.props} />
      ) : null;
      return component;
    }
  }

  return connect(({ user }) => ({ user }))(AdminComponent);
}
export function projectManagerOnly(WrappedComponent) {
  class AdminComponent extends React.Component {
    static propTypes = {
      user: PropTypes.object.isRequired,
    };
    componentWillMount() {
      this.checkAdmin(this.props.user);
    }

    componentWillReceiveProps(nextProps) {
      this.checkAdmin(nextProps.user);
    }

    checkAdmin(user) {
      if (
        user &&
        (!user.permissions ||
          (!user.permissions.QA_PROJECT_MANAGER && !user.permissions.QA_ADMIN))
      ) {
        // Redirect non-admin
        this.props.dispatch(replace('/'));
      }
    }

    render() {
      const component =
        !!this.props.user === true ? (
          <WrappedComponent {...this.props} />
        ) : null;
      return component;
    }
  }

  return connect(({ user }) => ({ user }))(AdminComponent);
}
