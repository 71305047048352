import { createObjectURL } from 'blob-util';
import { pick } from 'ramda';
import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { createAction } from 'redux-actions';
import Types from 'Types';
import { v4 as uuidv4 } from 'uuid';
import { createAttachment } from '../modules/attachments';
import { addTicketAttachment, createTicket } from '../modules/tickets';

const pako = import('pako');

export interface ReportIssueUserInput {
  title: string;
  message: string;
  includeData: boolean;
  includeScreenshot: boolean;
}

export interface ReportFeedbackAction {
  type: 'REPORT_FEEDBACK';
  payload: ReportIssueUserInput;
}

export interface IssuePostData {
  title: string;
  message: string;
  state?: string;
  screenshot?: string;
}

export const REPORT_FEEDBACK = 'REPORT_FEEDBACK';
export const reportIssue = createAction<ReportIssueUserInput>(REPORT_FEEDBACK);
function isReportFeedbackAction(
  action: AnyAction,
): action is ReportFeedbackAction {
  return action.type === REPORT_FEEDBACK;
}

function IssueReportingMiddleware() {
  const mw: Middleware = (store: MiddlewareAPI) => (next: Dispatch) => (
    action: AnyAction,
  ) => {
    // ignore other actions
    if (!isReportFeedbackAction(action)) {
      return next(action);
    }

    let files: Blob[] = [];

    // handle issues actions
    return (async () => {
      const data: IssuePostData = {
        message: action.payload.message,
        title: action.payload.title || 'Untitled',
      };
      const { user, labels } = store.getState() as Types.RootState;

      if (!user) {
        throw new Error('Cant report bug without being logged in');
      }

      const p = await pako;

      // add state data
      if (action.payload.includeData) {
        let state: any = store.getState();
        // remove auth token (we dont want this publicly in gitlab)
        if (state.user && state.user.accessToken) {
          state = {
            ...state,
            user: {
              ...state.user,
              accessToken: 'xxx',
            },
          };
        }

        const data = p.gzip(JSON.stringify(state));
        files.push(
          new File([data], 'Dump.json.gz', { type: 'application/gzip' }),
        );

        console.log({
          data,
          json: JSON.stringify(state),
          files,
          url: createObjectURL(files[0]),
        });
      }

      // create ticket
      const id = uuidv4();
      await store.dispatch(
        createTicket(
          {
            id,
            title: data.title,
            description: data.message,
            project_id: parseInt(
              process.env.REACT_APP_BUG_REPORT_PROJECT_ID!,
              10,
            ),
            add_labels:
              (labels &&
                Object.values(labels)
                  .filter(l => l!.name === 'Reported-from-client')
                  .map(l => l!.recnum)) ||
              undefined,
          },
          user.recnum,
          {},
        ),
      );

      // add attachments
      const attachments = await Promise.all(
        files.map(createAttachment).map(store.dispatch),
      );

      const pickAttachmentFields = pick(['size', 'checksum', 'contentType']);
      attachments
        .map(file =>
          addTicketAttachment(
            id,
            pickAttachmentFields(file) as any,
            user.recnum,
          ),
        )
        .map(store.dispatch);

      return id;
    })();
  };
  return mw;
}

export default IssueReportingMiddleware;
