import { pathToRegexp } from 'path-to-regexp';
import makeMatcher from 'wouter/matcher';

const convertPathToRegexp = (path: string) => {
  let keys: any[] = [];
  // we use original pathToRegexp package here with keys
  const regexp = pathToRegexp(path, keys);
  return { keys, regexp };
};
const stringMatcher = makeMatcher(convertPathToRegexp);
const regexpMatcher = (pattern: RegExp, path: string) => {
  const match = pattern.exec(path);
  if (!match) {
    return [false, null];
  }
  return [true, match.groups];
};
/**
 * Custom matcher that handles strings as usual, but also allows custom regexes.
 */
const matcher = (pattern: string | string[] | RegExp, path: string): any => {
  if (pattern instanceof RegExp) {
    return regexpMatcher(pattern, path);
  }
  if (pattern instanceof Array) {
    let match;
    for (const p of pattern) {
      match = matcher(p, path);
      if (match[0]) {
        break;
      }
    }
    return match;
  }
  return stringMatcher(pattern, path);
};
export default matcher;
