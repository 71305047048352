import { CatalogFile } from '../models/Catalog';
import { mapByRecnum } from '../utils';
import { softAssertNever } from './utils';

export type OpenFileTarget = '_self' | '_blank' | 'download';
export interface OpenFileAction {
  type: 'OPEN_FILE';
  payload: { file: CatalogFile; target: OpenFileTarget; lang: string };
}

export function isOpenFileAction(action: any): action is OpenFileAction {
  return action?.type === 'OPEN_FILE';
}

export function openFile(
  file: CatalogFile,
  target: OpenFileTarget = '_self',
  lang = 'en',
): OpenFileAction {
  return {
    type: 'OPEN_FILE',
    payload: {
      file,
      target,
      lang,
    },
  };
}

export interface DownloadFileAction {
  type: 'DOWNLOAD_FILE';
  payload: CatalogFile & { punch?: boolean };
}

export function downloadFile(file: CatalogFile): DownloadFileAction {
  return {
    type: 'DOWNLOAD_FILE',
    payload: file,
  };
}
export function isDownloadFileAction(action: any): action is DownloadFileAction {
  return (action?.type === 'DOWNLOAD_FILE')
}

export interface DownloadFilesAction {
  type: 'DOWNLOAD_FILES';
  payload: CatalogFile[];
}

export function downloadFiles(files: CatalogFile[]): DownloadFilesAction {
  return {
    type: 'DOWNLOAD_FILES',
    payload: files,
  };
};

export function isDownloadFilesAction(action: any): action is DownloadFilesAction {
  return (action?.type === 'DOWNLOAD_FILES')
}

export interface DownloadFileInitAction {
  type: 'DOWNLOAD_FILE_INIT';
  payload: {
    recnum: number;
  };
}

export function downloadFileInit(file: CatalogFile): DownloadFileInitAction {
  return {
    type: "DOWNLOAD_FILE_INIT",
    payload: { recnum: file.recnum }
  }
}

export interface DownloadFileUpdateAction {
  type: 'DOWNLOAD_FILE_UPDATE';
  payload: {
    recnum: number;
    total: number;
    loaded: number;
  };
}
export function downloadFileUpdate(file: CatalogFile, total: number, loaded: number): DownloadFileUpdateAction {
  return {
    type: "DOWNLOAD_FILE_UPDATE",
    payload: { recnum: file.recnum, total, loaded }
  }
}

export interface DownloadFileErrorAction {
  type: 'DOWNLOAD_FILE_ERROR';
  payload: {
    recnum: number;
    message: string;
    status: number | null
  };
}
export function downloadFileError(file: CatalogFile, message: string, status: number | null): DownloadFileErrorAction {
  return {
    type: "DOWNLOAD_FILE_ERROR",
    payload: { recnum: file.recnum, status, message }
  }
}

export interface DownloadFileCompleteAction {
  type: 'DOWNLOAD_FILE_COMPLETE';
  payload: {
    recnum: number;
    checksum: string;
    timestamp: Date
  };
}
export function downloadFileComplete(file: CatalogFile): DownloadFileCompleteAction {
  return {
    type: "DOWNLOAD_FILE_COMPLETE",
    payload: { recnum: file.recnum, checksum: file.checksum, timestamp: new Date() }
  }
}


export type FileDownloadsAction =
  | OpenFileAction
  | DownloadFileAction
  | DownloadFilesAction
  | DownloadFileInitAction
  | DownloadFileUpdateAction
  | DownloadFileErrorAction
  | DownloadFileCompleteAction;

export enum FileDownloadState {
  PENDING,
  DOWNLOADING,
  FAILED,
  DONE,
}

export interface FileDownload {
  recnum: number;
  file: CatalogFile,
  state: FileDownloadState;
  total: number;
  loaded: number;
  errorMessage: string | null;
  errorStatusCode: number | null;
}

export interface FileDownloadsState {
  [recnum: string]: FileDownload | undefined;
};

function patchFileDownload(state: FileDownloadsState, fileRecnum: number, patch: Partial<FileDownload>): FileDownloadsState {
  return {
    ...state,
    [fileRecnum]: { ...state[fileRecnum], ...patch }
  }
}

export default function fileDownloads(
  state: FileDownloadsState = {},
  action: FileDownloadsAction,
) {
  if (action.type === 'DOWNLOAD_FILE' || action.type === 'DOWNLOAD_FILES' || action.type === 'OPEN_FILE') {
    let files: CatalogFile[]
    if (action.type === 'DOWNLOAD_FILES') {
      files = action.payload
    } else if (action.type === 'OPEN_FILE') {
      files = [action.payload.file]
    } else {
      files = [action.payload]
    }
    // create filedownloads 
    const newFiles = mapByRecnum(files.map((f): FileDownload => {
      const existingFile = state[f.recnum]
      // dont overwrite files allready busy
      if (existingFile && existingFile.state !== FileDownloadState.FAILED) {
        return existingFile
      }
      return ({
        recnum: f.recnum,
        file: f,
        total: f.size,
        loaded: 0,
        state: FileDownloadState.PENDING,
        errorMessage: null,
        errorStatusCode: null
      })
    }))
    return {
      ...state,
      ...newFiles
    }
  } else if (action.type === 'DOWNLOAD_FILE_INIT') {
    const { recnum } = action.payload
    return patchFileDownload(state, recnum, { state: FileDownloadState.DOWNLOADING })
  } else if (action.type === 'DOWNLOAD_FILE_UPDATE') {
    const { recnum, ...rest } = action.payload
    return patchFileDownload(state, recnum, rest)
  } else if (action.type === 'DOWNLOAD_FILE_ERROR') {
    const { recnum, message, status } = action.payload;
    return patchFileDownload(state, recnum, { state: FileDownloadState.FAILED, errorMessage: message, errorStatusCode: status });

  } else if (action.type === 'DOWNLOAD_FILE_COMPLETE') {
    const { recnum } = action.payload;
    return patchFileDownload(state, recnum, { state: FileDownloadState.DONE })
  }
  softAssertNever(action);
  return state;
}
