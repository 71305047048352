import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import * as ReactDOM from 'react-dom';

import { replace } from 'redux-first-history';

import { LISTMODE_NORMAL } from '../List';
import { renderFields } from '../List/Field';

import classNames from 'classnames';
import Feedback from './Feedback';
import styles from './FieldPopoutView.module.scss';

export default class FieldPopoutView extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    list: PropTypes.object.isRequired,
    mode: PropTypes.string.isRequired,
    user: PropTypes.object.isRequired,
    users: PropTypes.object.isRequired,
  };
  static defaultProps = {
    mode: LISTMODE_NORMAL,
  };

  constructor(props) {
    super(props);
    this.el = document.createElement('div');
  }

  componentDidMount() {
    document.getElementById('modal').appendChild(this.el);
  }

  componentWillUnmount() {
    document.getElementById('modal').removeChild(this.el);
  }

  findNextSibling() {
    const { fieldId, builtList } = this.props;
    const { index } = builtList;
    const currentField = index[fieldId];
    const parent = index[index[fieldId].parentNodeId];
    const currentIndex = parent.children.indexOf(currentField);

    // try to find next sibling in same parent
    if (currentIndex < parent.children.length - 1) {
      return parent.children[currentIndex + 1].id;
    }
    // try to find in next parent sibling
    const grandParent = index[parent.parentNodeId];
    const parentIndex = grandParent.children.indexOf(parent);
    for (let i = parentIndex + 1; i < grandParent.children.length; i++) {
      const parentSibling = grandParent.children[i];
      if (parentSibling.children.length) {
        return parentSibling.children[0].id;
      }
    }
    // there's no next sibling
    return null;
  }

  findPrevSibling() {
    const { fieldId, builtList } = this.props;
    const { index } = builtList;
    const currentField = index[fieldId];
    const parent = index[index[fieldId].parentNodeId];
    const currentIndex = parent.children.indexOf(currentField);

    // try to find prev sibling in same parent
    if (currentIndex > 0) {
      return parent.children[currentIndex - 1].id;
    }
    // try to find in prev parent sibling
    const grandParent = index[parent.parentNodeId];
    const parentIndex = grandParent.children.indexOf(parent);
    for (let i = parentIndex - 1; i >= 0; i--) {
      const parentSibling = grandParent.children[i];
      if (parentSibling.children.length) {
        return parentSibling.children[parentSibling.children.length - 1].id;
      }
    }
    // there's no next sibling
    return null;
  }

  getParent() {
    const { fieldId, builtList } = this.props;
    const { index } = builtList;
    return index[index[fieldId].parentNodeId];
  }

  handleClose = () => {
    const { dispatch, list } = this.props;
    dispatch(replace(`/list/${list.id}`));
  };

  handleNext = () => {
    const { dispatch, list } = this.props;
    const sib = this.findNextSibling();
    dispatch(replace(`/list/${list.id}/${sib}`));
  };

  handlePrev = () => {
    const { dispatch, list } = this.props;
    const sib = this.findPrevSibling();
    dispatch(replace(`/list/${list.id}/${sib}`));
  };

  render() {
    const { fieldId, builtList, ...rest } = this.props;
    const field = builtList.index[fieldId];

    let content;

    if (!field) {
      if (fieldId) {
        // tslint:disable-next-line
        console.warn('Was unable to find field');
      }
      content = <div />;
    } else {
      content = (
        <div className={styles.root}>
          <div className={classNames(styles.container, 'container row')}>
            <div className={styles.actionsAbove}>
              <div className="spacer" />
              <Button
                onClick={this.handlePrev}
                disabled={!this.findPrevSibling()}
                className="up"
                bsStyle="link"
              >
                <i className="fa fa-chevron-up" />
              </Button>
              <Button
                onClick={this.handleClose}
                className="closes"
                bsStyle="link"
              >
                <i className="fa fa-times" />
              </Button>
            </div>
            <h1 className={styles.groupTitle}>{this.getParent().title_en}</h1>
            <div className={styles.fieldBox}>
              {renderFields({ expanded: true, field, ...rest })(field)}
            </div>
            <div className={styles.actionsBelow}>
              <Button
                onClick={this.handleNext}
                disabled={!this.findNextSibling()}
                className="down"
                bsStyle="link"
              >
                <i className="fa fa-chevron-down" />
              </Button>
            </div>
            <div className={styles.feedback}>
              <Feedback
                dispatch={rest.dispatch}
                user={rest.user}
                list={builtList}
                nodeId={fieldId}
              />
            </div>
          </div>
        </div>
      );
    }

    return ReactDOM.createPortal(content, this.el);
  }
}
