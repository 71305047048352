import * as React from 'react';
import { Button } from 'react-bootstrap';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { Dispatch } from 'redux';
import { Link } from 'wouter';
import Feedback from '../../models/Feedback';
import { saveFeedback } from '../../modules/feedback';
import { UsersState } from '../../modules/users';
import stl from './FeedbackListItem.module.scss';
import { msg } from './msg';

export enum FeedbackListItemLinkMode {
  // link to template details page
  TEMPLATE,
  // link to checklist details page
  INSTANCE,
}

export interface FeedbackListItemProps {
  feedback: Feedback;
  dispatch: Dispatch;
  users: UsersState;
  list: any;
  instanceList: any;
  node: any;
  linkMode: FeedbackListItemLinkMode;
}

const FeedbackListItem: React.FC<FeedbackListItemProps> = ({
  dispatch,
  feedback,
  users,
  node,
  list,
  linkMode = FeedbackListItemLinkMode.TEMPLATE,
  instanceList,
}) => {
  // tslint:disable-next-line:no-console

  // look up reporter
  const reporter = users && users[feedback.user_id];

  // look up handler (if present)
  const handler =
    users && feedback.handled_user_id && users[feedback.handled_user_id];

  if (!reporter) {
    throw new Error('Could not find user');
  }

  let nodeElem;
  let link;
  if (list && linkMode === FeedbackListItemLinkMode.INSTANCE) {
    link = `/list/${list.id}`;
    if (feedback.instance_node_id) {
      link += `/${feedback.instance_node_id}`;
    }
  } else if (linkMode === FeedbackListItemLinkMode.TEMPLATE) {
    link = `/template/${list.id}`;
    if (feedback.proto_node_id) {
      link += `/${feedback.proto_node_id}`;
    }
  }

  if (list && link) {
    if (!node) {
      nodeElem = (
        <em>
          {' '}
          <FormattedMessage {...msg.FEEDBACK_DELETED_QUESTION} />{' '}
        </em>
      );
    } else {
      nodeElem = <Link to={link}>{node.title_nl}</Link>;
    }
    if (instanceList) {
      const listLink = `/list/${instanceList.id}/${feedback.instance_node_id}`;
      nodeElem = (
        <>
          {nodeElem} <FormattedMessage {...msg.FEEDBACK_OF_LIST} />
          <Link to={listLink}> {instanceList.name}</Link>
        </>
      );
    }
  }

  const handle = () =>
    dispatch(saveFeedback({ id: feedback.id, handled: true }));

  return (
    <div className={stl.root}>
      {!feedback.handled && (
        <Button
          className="pull-right"
          onClick={handle}
          bsSize="sm"
          bsStyle="primary"
        >
          <FormattedMessage {...msg.FEEDBACK_DONE} />
        </Button>
      )}
      <blockquote>
        <p>{feedback.message}</p>
        <footer>
          <cite>
            {reporter.name || reporter.loginname || 'unkown'}
            {nodeElem && (
              <>
                &nbsp;
                <FormattedMessage {...msg.FEEDBACK_INSERT_ABOUT} /> {nodeElem}
              </>
            )}
            &nbsp;
            <FormattedMessage {...msg.FEEDBACK_INSERT_ON} />{' '}
            <FormattedDate value={feedback.timestamp} />
          </cite>
        </footer>
      </blockquote>
      <span className="text-muted pull-right">
        {handler && (
          <>
            <FormattedMessage
              {...msg.FEEDBACK_HANDLED_BY}
              values={{
                user: handler.name || handler.loginname,
              }}
            />
            {feedback.updated && (
              <>
                {' '}
                <FormattedMessage {...msg.FEEDBACK_INSERT_ON} />{' '}
                <FormattedDate value={feedback.updated} />
              </>
            )}
          </>
        )}
      </span>
    </div>
  );
};

export default FeedbackListItem;
