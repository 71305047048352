import * as React from 'react';
import { FormattedDate } from 'react-intl';
import createFilters from '../../common/logic/createFilters';
import sortTickets, {
  createSortFunction,
  defaultSorting,
  fromString,
} from '../../common/logic/sortTickets';
import { LabelsState } from '../../modules/labels';
import { ProjectState } from '../../modules/projects';
import { UserState } from '../../modules/user';
import { UsersState } from '../../modules/users';
import { WorkState } from '../../modules/works';
import Paper from '../Paper';
import { parseParameters } from '../ParameterizedSearch/model';
import TicketDetailsPage from '../TicketDetails';

// FIXME: correct types
interface MultiTicketReportProps {
  tickets: any[];
  users: UsersState;
  works: WorkState;
  user: UserState;
  labels: LabelsState;
  filter: string;
  sorting: string;
  projects: ProjectState;
  projectId?: number;
  workId?: number;
}

function renderSearchParameterValue(
  parameter: {
    name: string;
    value: string;
  },
  users: UsersState,
  labels: LabelsState,
) {
  if (
    ['creator', 'associated', 'assigned', 'performedBy'].includes(
      parameter.name,
    )
  ) {
    return (
      users?.[parameter.value]?.name || users?.[parameter.value]?.loginname
    );
  }
  if (parameter.name === 'label') {
    return labels?.[parameter.value as any]?.name;
  }
  return parameter.value;
}

const MultiTicketReport: React.FC<MultiTicketReportProps> = ({
  tickets,
  user,
  users,
  works,
  filter,
  labels,
  sorting = defaultSorting,
  projects,
  projectId,
  workId,
}) => {
  let filterredTickets = createSortFunction(fromString(sorting))(
    Object.values(tickets),
  );
  if (filter) {
    const [ticketFilter] = createFilters(filter, works);
    filterredTickets = sortTickets(filterredTickets.filter(ticketFilter));
  }

  let project;
  let work;
  if (works && projects && workId) {
    work = works[workId];
    project = projects[work?.project_id!];
  }
  if (projectId && projects && !project) {
    project = projects[projectId];
  }

  const { parameters, rest } = parseParameters(filter);

  const cover = (
    <Paper.NewPage>
      <h1>{workId ? 'Work' : 'Project'} tickets report</h1>
      <h3>Selection</h3>
      <table className="table">
        <tbody>
          {project ? (
            <tr>
              <th>{workId ? 'Work' : 'Project'}</th>
              <td>
                {project.klant_naam} / {project.projectname}{' '}
                {project.id && <em>- {project.id}</em>}{' '}
                {work && '/ ' + ((work.oms && work.oms.trim()) || work.id)}
              </td>
            </tr>
          ) : null}
          <tr>
            <th>Date</th>
            <td>
              <FormattedDate value={new Date()} />
            </td>
          </tr>
          <tr>
            <th>Filter</th>
            {filter ? (
              <td>
                {parameters.map(p => (
                  <>
                    {`${p.name}: `}
                    {renderSearchParameterValue(p, users, labels)}
                    <br />
                  </>
                ))}
                {rest && (
                  <>
                    {`text: ${rest}`}
                    <br />
                  </>
                )}
              </td>
            ) : (
              <td>none</td>
            )}
          </tr>
        </tbody>
      </table>
      <h3>Overview</h3>
      <table className="table">
        <thead>
          <tr>
            <td>Number</td>
            <td>Title</td>
            <td>Due</td>
            {/* <td>Page</td> */}
          </tr>
        </thead>
        <tbody>
          {filterredTickets.map(t => (
            <tr key={t.id}>
              <td>
                <a href={`#${t.number}`}>{`#${t.number}`}</a>
              </td>
              <td>{t.title}</td>
              <td>{t.due ? <FormattedDate value={t.due} /> : '-'}</td>
              {/* <td className={stl.pageNum}>
                <a href={`#${t.number}`}>page </a>
              </td> */}
            </tr>
          ))}
        </tbody>
      </table>
    </Paper.NewPage>
  );

  const ticketPages = filterredTickets.map(t => (
    <Paper.NewPage key={t.id} id={t.number.toString()}>
      <TicketDetailsPage params={{ ticketId: t.id }} />
    </Paper.NewPage>
  ));
  return (
    <Paper title="tickets" downloadLink={''}>
      {cover}
      {ticketPages}
    </Paper>
  );
};

export default MultiTicketReport;
