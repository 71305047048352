import * as R from 'ramda';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearch } from 'wouter/use-location';
import MultiTicketReport from '../components/MultiTicketReport';
import useReportReady from '../hooks/useReportReady';
import { fetchLabels } from '../modules/labels';
import { fetchProjects } from '../modules/projects';
import { fetchTickets } from '../modules/tickets';
import { fetchUser } from '../modules/user';
import { fetchUsers } from '../modules/users';
import { fetchWorks } from '../modules/works';
import useInitialDispatch from '../utils/useInitalDispatch';

const isLoading = p =>
  !p.users || !p.tickets || !p.works || !p.user || !p.projects || !p.labels;

const MultiTicketReportContainer = ({ params }) => {
  // FIXME: selector function is not deterministic, causing unnessecaty rerenders
  const data = useSelector(
    ({ users, user, works, tickets, projects, labels }) => ({
      users,
      user,
      works,
      tickets,
      projects,
      labels,
    }),
  );
  const ready = useReportReady(() => !isLoading(data), [data]);
  const { works, users, user, projects, tickets, labels } = data;
  const dispatch = useDispatch();
  const search = useSearch();

  useInitialDispatch(
    fetchWorks,
    fetchProjects,
    fetchUsers,
    fetchUser,
    fetchTickets,
    fetchLabels,
  );

  const workRecnum = parseInt(params.workRecnum, 10);
  const workTickets = React.useMemo(() => {
    if (tickets && users) {
      return R.filter(ticket => ticket.werk_id === workRecnum, tickets);
    }
    return null;
  }, [tickets, users, workRecnum]);

  const { filter, sorting } = Object.fromEntries(new URLSearchParams(search));

  return (
    ready && (
      <MultiTicketReport
        works={Object.values(works)}
        tickets={workTickets}
        users={users}
        user={user}
        dispatch={dispatch}
        filter={filter}
        sorting={sorting}
        projects={projects}
        workId={workRecnum}
        labels={labels}
      />
    )
  );
};

export default MultiTicketReportContainer;
