import * as React from 'react';
import Page from './Page';

export default function BrokenComponent(props) {
  return (
    <Page>
      <h1>I am unaware of any `cause` of troubles</h1>
      <Cause />;
    </Page>
  );
}

function Cause() {
  return undefined.toString();
}
