import { Location } from '@certhon/domain-models';
import { MakeTree } from '../../components/Tree/common';

/**
 * Create a list of a location's ancestors
 * Ordered with top ancestor first
 */
export function locationAncestors(loc: MakeTree<Location>) {
  let location: MakeTree<Location> | null = loc;
  let path: MakeTree<Location>[] = [];
  while (location) {
    path.unshift(location);
    location = location.parent;
  }
  return path;
}
