import { AnyAction } from 'redux';

export interface SortingAction {
  type: 'SORTING';
  payload: {
    sortingKey: string;
    sorting: string | null;
  };
}

export const sorting = (
  sortingKey: string,
  sorting: string | null,
): SortingAction => ({
  type: 'SORTING',
  payload: {
    sortingKey,
    sorting,
  },
});

export interface SortingState {
  [filterKey: string]: any;
}

export default function sortingReducer(
  state: SortingState = {},
  action: SortingAction | AnyAction,
): SortingState {
  if (action.type === 'SORTING') {
    return {
      ...state,
      [action.payload.sortingKey]: action.payload.sorting,
    };
  }
  return state;
}
