export interface Iunitunit {
  unit: string;
  quantity: string;
  symbol: string;
  derived?: string;
}

const units: Iunitunit[] = [
  {
    unit: 'Meter',
    symbol: 'm',
    quantity: 'Length',
  },
  {
    unit: 'Milimeter',
    symbol: 'mm',
    quantity: 'Length',
  },
  {
    unit: 'Kilogram',
    symbol: 'kg',
    quantity: 'Mass',
  },
  {
    unit: 'Second',
    symbol: 's',
    quantity: 'Time',
  },
  {
    unit: 'Hours',
    symbol: 'h',
    quantity: 'Time',
  },
  {
    unit: 'Ampere',
    symbol: 'A',
    quantity: 'Electric current',
  },
  {
    unit: 'Kelvin',
    symbol: 'K',
    quantity: 'Thermodynamic temperature',
  },
  // {
  //   unit: 'Mole',
  //   symbol: 'mol',
  //   quantity: 'Amount of substance'
  // },
  // {
  //   unit: 'Candela',
  //   symbol: 'cd',
  //   quantity: 'Luminous intenunitty'
  // },
  {
    unit: 'Radian',
    symbol: 'rad',
    quantity: 'Plane angle (2D angle)',
  },
  // {
  //   unit: 'Steradian',
  //   symbol: 'sr',
  //   quantity: 'Solid angle (3D angle)'
  // },
  {
    symbol: 'Pa',
    unit: 'Pascal',
    quantity: 'Pressure, Stress',
    derived: 'kg·m-1·s-2',
  },
  {
    symbol: 'Bar',
    unit: 'Bar',
    quantity: 'Pressure, stress',
    derived: 'kg·m-1·s-2*1e-5',
  },
  {
    unit: 'Joule',
    symbol: 'J',
    derived: 'kg·m2·s-2',
    quantity: 'Energy, Work, Heat',
  },
  {
    unit: 'Watt',
    symbol: 'W',
    derived: 'kg·m2·s-3',
    quantity: 'Power',
  },
  {
    unit: 'Newton',
    symbol: 'N',
    derived: 'kg·m·s-2',
    quantity: 'Force, Weight',
  },
  // {
  //   unit: 'Tesla',
  //   symbol: 'T',
  //   derived: 'kg·s-2·A-1',
  //   quantity: 'Magnatic field'
  // },
  // {
  //   unit: 'Henry',
  //   symbol: 'H',
  //   derived: 'kg·m2·s-2·A-2',
  //   quantity: 'Inductance'
  // },
  // {
  //   unit: 'Coulomb',
  //   symbol: 'C',
  //   derived: 'A·s',
  //   quantity: 'Electric Charge'
  // },
  {
    unit: 'Volt,',
    symbol: 'V',
    derived: 'kg·m2·s-3·A-1',
    quantity: 'Voltage',
  },
  // {
  //   unit: 'Farad',
  //   symbol: 'F',
  //   derived: 'kg-1·m-2·s4·A2',
  //   quantity: 'Electric Capacitance'
  // },
  // {
  //   unit: 'unitemens',
  //   symbol: 'S',
  //   derived: 'kg-1·m-2·s3·A2',
  //   quantity: 'Electrical Conductance'
  // },
  // {
  //   unit: 'Weber',
  //   symbol: 'Wb',
  //   derived: 'kg·m2·s-2·A-1',
  //   quantity: 'Magnetic Flux'
  // },
  {
    unit: 'Ohm',
    symbol: 'Ω',
    derived: 'kg·m2·s-3·A-3',
    quantity: 'Electric Resistance',
  },
  {
    unit: 'Lux',
    symbol: 'lx',
    derived: 'cd·sr·m-2',
    quantity: 'Illuminance',
  },
  {
    unit: 'Lumen',
    symbol: 'lm',
    derived: 'cd·sr',
    quantity: 'Luminous Flux',
  },
  // {
  //   unit: 'Becquerel',
  //   symbol: 'Bq',
  //   derived: 's-1',
  //   quantity: 'Radioactivity'
  // },
  // {
  //   unit: 'Gray',
  //   symbol: 'Gy',
  //   derived: 'm2·s-1',
  //   quantity: 'Absorbed Dose'
  // },
  // {
  //   unit: 'Sievert',
  //   symbol: 'Sv',
  //   derived: 'm2·s-1',
  //   quantity: 'Equivalent Dose'
  // },
  {
    unit: 'Hertz',
    symbol: 'Hz',
    derived: 's-1',
    quantity: 'Frequency',
  },
  // {
  //   unit: 'Katal',
  //   symbol: 'Kat',
  //   derived: 'mol·s-1',
  //   quantity: 'Catalytic Activity'
  // }
];

export default units;
