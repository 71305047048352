import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Types from 'Types';
import { Link } from 'wouter';
import Spinner from '../components/Spinner';
import { useCountingRequestStore } from '../stores/countingRequestStore';
import { formatPeriod } from './formatPeriod';

interface CountingRequestOverviewContainerProps {}
const CountingRequestOverviewContainer: React.FC<CountingRequestOverviewContainerProps> = ({}) => {
  const [{ countingRequests }, { fetch }] = useCountingRequestStore();
  const [hasFetched, setHasFetched] = React.useState<boolean>(false);

  const isCountingRequestAdmin = useSelector<Types.RootState, boolean>(
    ({ user }) => !!user?.permissions?.COUNTING_REQUEST_ADMIN,
  );

  useEffect(() => {
    fetch().then(() => setHasFetched(true));
  }, [fetch]);

  return !hasFetched ? (
    <Spinner></Spinner>
  ) : (
    <table className="table table-striped">
      <thead>
        <tr>
          <td>Name</td>
          <td>Period</td>
          <td>Status</td>
        </tr>
      </thead>
      <tbody>
        {Object.values(countingRequests).map(cr => (
          <tr key={cr.recnum}>
            <td>
              <Link to={`/counting-requests/${cr.recnum}`}>{cr.name}</Link>
            </td>
            <td>{formatPeriod(cr.start_date, cr.end_date)}</td>
            <td>{cr.status}</td>
          </tr>
        ))}
      </tbody>
      <tfoot>
        {isCountingRequestAdmin && (
          <tr>
            <td colSpan={3}>
              <Link
                className="btn btn-default pull-right"
                to="/counting-requests/new"
              >
                New Counting Request
              </Link>
            </td>
          </tr>
        )}
      </tfoot>
    </table>
  );
};

export default CountingRequestOverviewContainer;
