export function resolveHeaders(headers: HeadersInit = {}) {
  return (state?: any) => {
    let auth;
    if (state && state.user && state.user.accessToken) {
      auth = { Authorization: `Bearer ${state.user.accessToken}` };
    }
    return {
      'content-type': 'application/json',
      'x-client-version': process.env.REACT_APP_VERSION || undefined,
      ...auth,
      ...headers,
    } as HeadersInit;
  };
}

let apiBackend: string | null = null;
export function setApiBackend(url: string) {
  apiBackend = url;
}

export function resolveUrl(path: string) {
  if (apiBackend) {
    return `${apiBackend}${path}`;
  }
  return '/api' + path;
}
