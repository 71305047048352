import React from 'react';
import { useDispatch } from 'react-redux';
import { replaceNumberingPlaceholder } from '../../common/location/replaceNumberingPlaceholder';
import {
  labeltypes,
  LocationWithDetails,
  saveLocation,
} from '../../modules/locations';
import FormattedRelative from '../FormattedRelative';
import Icon from '../Icon';
import { LocationStockTableData } from '../InventoryTree/LocationStockTableData';
import TreeTable, { TreeTableProps } from '../Tree';
import { MakeTree } from '../Tree/common';
import { Element, TreeElementProps } from '../Tree/TreeElement';

type LocationChange = 'location' | 'stock';
export const getLocationChanges = (
  location: LocationWithDetails,
): null | LocationChange[] => {
  const changes: LocationChange[] = [];

  if (location.label_created) {
    const label_created = new Date(location.label_created);
    if (label_created < new Date(location.modified)) {
      changes.push('location');
    }
    if (location.stock.some(s => label_created < new Date(s.modified))) {
      changes.push('stock');
    }
  }

  return changes.length ? changes : null;
};

interface InventoryLabelSelectionTreeItemProps {
  props: TreeElementProps;
  item: MakeTree<LocationWithDetails>;
  onToggleChecked: (id: any) => void;
  checked: boolean;
}
const InventoryLabelSelectionTreeItem: React.FC<InventoryLabelSelectionTreeItemProps> = ({
  props,
  item,
  checked,
  onToggleChecked,
}) => {
  const dispatch = useDispatch();

  const changes = React.useMemo(() => getLocationChanges(item), [item]);

  return (
    <>
      <Element
        additionalColumns={
          <>
            <td>
              {item.label_created ? (
                <FormattedRelative value={item.label_created} />
              ) : (
                '-'
              )}
            </td>
            <LocationStockTableData location={item} />
            <td>
              {changes ? (
                <i
                  title={changes.join()}
                  className="text-warning fa fa-warning"
                ></i>
              ) : (
                '-'
              )}
            </td>
            <td>
              <select
                key={item.id}
                defaultValue={item.labeltype || ''}
                name="labeltype"
                onChange={React.useCallback<any>(
                  ({ target: { value } }: { target: { value: any } }) => {
                    dispatch(
                      saveLocation({ id: item.id, labeltype: value || null }),
                    );
                  },
                  [dispatch, item],
                )}
              >
                <option value="">-</option>
                {labeltypes.map(type => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </select>
            </td>
          </>
        }
        icons={
          <>
            {!item.children?.length && <Icon name={'box'} />}
            <input
              type="checkbox"
              style={{ margin: '3px' }}
              key={item.id}
              checked={item.labeltype !== null && checked}
              disabled={item.labeltype === null}
              onChange={onToggleChecked}
              id=""
            />
          </>
        }
        {...props}
        name={replaceNumberingPlaceholder(item.name, item.ordering)}
        checked={checked}
        onToggleChecked={onToggleChecked}
      />
    </>
  );
};

interface InventoryLabelSelectionTreeProps
  extends Pick<
    TreeTableProps<MakeTree<LocationWithDetails>>,
    'onExpandedElementIdsChanged' | 'expandedElementIds' | 'index'
  > {
  locations: MakeTree<LocationWithDetails>[];
  selectedLocationId?: string | null;
  selectedIds: any[];
  toggleSelectedId: (id: any) => void;
}
const InventoryLabelSelectionTree: React.FC<InventoryLabelSelectionTreeProps> = ({
  locations,
  index,
  toggleSelectedId,
  selectedLocationId,
  selectedIds,
  expandedElementIds,
  onExpandedElementIdsChanged,
}) => {
  return (
    <TreeTable<MakeTree<LocationWithDetails>>
      thead={
        <tr>
          <th>Locatie</th>
          <th>geprint</th>
          <th>Voorraad</th>
          <th>Gewijzigd</th>
          <th>label</th>
        </tr>
      }
      index={index}
      elements={locations}
      renderRow={(props, item) => (
        <InventoryLabelSelectionTreeItem
          onToggleChecked={() => toggleSelectedId(item.id)}
          props={{ ...props, selected: item.id === selectedLocationId }}
          item={item}
          checked={selectedIds.includes(item.id)}
        />
      )}
      onExpandedElementIdsChanged={onExpandedElementIdsChanged}
      expandedElementIds={expandedElementIds}
    ></TreeTable>
  );
};

export default InventoryLabelSelectionTree;
