import * as React from 'react';
import { CurrentUser } from '../modules/user';

export interface FilterInterface {
  filter: string;
  changeFilter: (filter: string | null) => void;
  clearFilter: (() => void) | undefined;
  resetFilter: (() => void) | undefined;
}

export default function useProjectFilter(
  onChangeFilter: (filter: string | null) => void,
  filter: string,
  user: CurrentUser | null,
): FilterInterface {
  const resetFilter = React.useCallback(() => onChangeFilter(null), [
    onChangeFilter,
  ]);
  const clearFilter = React.useCallback(() => onChangeFilter(''), [
    onChangeFilter,
  ]);
  const defaultFilter = React.useMemo(() => {
    const f = user?.internal ? 'following:"true"' : 'archived:"null"';
    return f;
  }, [user]);

  const defaultedFilter = React.useMemo<string>(
    () => (filter == null ? defaultFilter : filter),
    [filter, defaultFilter],
  );

  return {
    resetFilter: defaultedFilter !== defaultFilter ? resetFilter : undefined,
    clearFilter: filter !== '' ? clearFilter : undefined,
    changeFilter: onChangeFilter,
    filter: defaultedFilter,
  };
}
