import units from '../../List/NumericField/units';

const NUMERIC_FIELD = 'NUMERIC_FIELD';
const TASK_FIELD = 'TASK_FIELD';
const CHECKPOINT_FIELD = 'CHECKPOINT_FIELD';

export const propertyDescriptions = [
  {
    name: 'type',
    label: 'Veldtype',
    help: 'Het veld-type',
    componentClass: 'select',
    options: [
      {
        value: CHECKPOINT_FIELD,
        children: 'Controlepunt'
      },
      {
        value: TASK_FIELD,
        children: 'Taak'
      },
      {
        value: NUMERIC_FIELD,
        children: 'Numeriek'
      }
    ],
    whitelist: [NUMERIC_FIELD, TASK_FIELD, CHECKPOINT_FIELD]
  },
  {
    name: 'unit',
    label: 'Grootheid',
    componentClass: 'select',
    options: [
      { value: '', children: 'No unit' },
      ...units.map(unit => ({
        children: `${unit.quantity} (${unit.unit})`,
        value: unit.symbol
      }))
    ],
    whitelist: [NUMERIC_FIELD]
  },
  {
    name: 'title_nl',
    label: 'Titel (nl)',
    help: 'nederlandse titel van deze groep of vraag',
    ref: 'titleNlInput'
  },
  {
    name: 'title_en',
    label: 'Titel (en)'
    // help: 'engelse titel van deze groep of vraag',
  },
  {
    name: 'description_nl',
    label: 'Omschrijving (nl)',
    help: 'zichtbaar als vraag-details-paneel geopend is',
    componentClass: 'textarea',
    whitelist: [CHECKPOINT_FIELD, TASK_FIELD, NUMERIC_FIELD]
  },
  {
    name: 'description_en',
    label: 'Omschrijving (en)',
    componentClass: 'textarea',
    whitelist: [CHECKPOINT_FIELD, TASK_FIELD, NUMERIC_FIELD]
  }
];
