import { add, set, startOfWeek, subDays } from 'date-fns';

const TICKET_EVENTS_CUTOFF_PERIOD_IN_DAYS = 30;

/*
 * Calculate the eventCutoffDate
 * Snap to the middle of the week to allow for more efficient caching
 */
export default function calculateTicketEventCutoffDate() {
  let eventCutoffDate = subDays(
    set(new Date(), { milliseconds: 0, seconds: 0, minutes: 0, hours: 0 }),
    TICKET_EVENTS_CUTOFF_PERIOD_IN_DAYS,
  );
  eventCutoffDate = add(startOfWeek(eventCutoffDate), { days: 2 });

  return eventCutoffDate;
}
