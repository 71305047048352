import bytes from 'bytes';
import * as React from 'react';
import { Link } from 'wouter';
import { Catalog } from '../../models/Catalog';
import {
  FileDownload,
  FileDownloadsState,
  FileDownloadState,
} from '../../modules/fileDownloads';
import { FilesState } from '../../modules/files';
import ProgressBullet from '../ProgressBullet';
import Spinner from '../Spinner';
import stl from './CatalogSection.module.scss';

interface Props {
  catalog?: Catalog;
  hasCatalog: boolean;
  fileDownloads: FileDownloadsState;
  files: FilesState;
  projectFollowed: boolean;
}

const CatalogSection: React.FC<Props> = ({
  catalog,
  hasCatalog,
  files,
  projectFollowed,
  fileDownloads,
}) => {
  let content: React.ReactNode;
  let status = null;
  let size = React.useMemo(() => {
    let byteSize, tokens, numFiles;
    if (catalog?.files) {
      [byteSize, tokens, numFiles] = catalog.files.reduce(
        ([byteSize, tokens, numFiles], b) => [
          byteSize + b.size,
          tokens + 1,
          b.checksum ? numFiles + 1 : numFiles,
        ],
        [0, 0, 0],
      );
    }
    if (byteSize) {
      return (
        <em className={stl.bytes}>
          {numFiles}/{tokens} files, {bytes(byteSize)}
        </em>
      );
    }
  }, [catalog]);
  if (catalog?.files) {
    const downloads = catalog.files
      .map(f => fileDownloads[f.recnum])
      .filter(f => f !== undefined) as FileDownload[];
    const fileChecksums = Object.keys(files);
    const pendingDownloads = downloads.filter(
      d =>
        d?.state === FileDownloadState.DOWNLOADING ||
        d?.state === FileDownloadState.PENDING,
    );
    const missingDocuments = catalog.files.filter(
      f => f.checksum && !fileChecksums.includes(f.checksum),
    );
    if (!projectFollowed) {
      // TODO: i18n
      status = (
        <small className={stl.statusDescription}>
          Documents for this projects are NOT synced for offline
        </small>
      );
    } else if (pendingDownloads.length) {
      const sum = downloads.reduce<{ loaded: number; total: number }>(
        (sum, { total = 0, loaded = 0, file }) => {
          return {
            loaded: sum.loaded + loaded || 0,
            total: sum.total + file?.size || 0,
          };
        },
        { total: 0, loaded: 0 },
      );
      const progress = sum.total !== 0 ? sum.loaded / sum.total : 0;
      // TODO: i18n
      status = (
        <small className={stl.statusDescription}>
          <ProgressBullet
            size={15}
            className={stl.progress}
            progress={progress}
          />{' '}
          Downloading documents
        </small>
      );
    } else if (missingDocuments.length) {
      // TODO: i18n
      status = (
        <small className={stl.statusDescription}>
          <ProgressBullet size={15} className={stl.progress} progress={0} />{' '}
          Waiting for document sync to start
        </small>
      );
    } else {
      // TODO: i18n
      status = (
        <small className={stl.statusDescription}>
          Documents for this projects ARE synced for offline{' '}
        </small>
      );
    }
  }
  if (hasCatalog) {
    if (!catalog) {
      content = <Spinner />;
    } else {
      // TODO: i18n
      content = (
        <>
          <Link
            className="btn btn-default"
            to={`/project/${catalog.project_id}/docs`}
          >
            View files
          </Link>
          <br />
          {status}
        </>
      );
    }
  } else {
    // TODO: i18n
    content = <em>This project does not have a document catalog</em>;
  }
  return (
    <div className={stl.root}>
      <h4>Docs {size}</h4>
      {content}
    </div>
  );
};

export default CatalogSection;
