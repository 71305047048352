import { MaterialOrderType } from '@certhon/domain-models/lib';
import React, {
  FormEvent,
  FormEventHandler,
  useCallback,
  useEffect,
} from 'react';
import {
  Alert,
  Button,
  Col,
  ControlLabel,
  FormControl,
  FormGroup,
  Glyphicon,
  InputGroup,
  ListGroup,
  ListGroupItem,
  Row,
  ToggleButton,
  ToggleButtonGroup,
} from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import artikelCode from '../../common/logic/artikelCode';
import { MakeOptionalExcept } from '../../common/logic/types';
import { UsersState } from '../../modules/users';
import {
  MaterialList,
  MaterialListItem,
  MaterialListStoreInterface,
  UpdatableMaterialList,
} from '../../stores/materialListStore';
import ArtikelPickerModal, {
  ArtikelPickerModalRef,
} from '../ArtikelPickerModal';
import ClientOrProjectSelectionControl from '../ClientOrProjectSelectionControl/ClientOrProjectSelectionControl';
import { useContextMenu } from '../ContextMenu';
import FormattedRelative from '../FormattedRelative';
import UserToken from '../UserToken';
import msg from './msg';

interface MaterialListDetailsEditorProps
  extends Partial<
    Pick<
      MaterialListStoreInterface,
      'updateMaterial' | 'addMaterial' | 'removeMaterial'
    >
  > {
  error: Error | null;
  onChange: (cr: Partial<UpdatableMaterialList>, save?: boolean) => void;
  onSubmit?: (ev?: FormEvent) => void;
  onRemove?: () => void;
  users?: UsersState;
  // materialList: MaterialList;
  materialList: MakeOptionalExcept<
    MaterialList,
    | 'project_id'
    | 'klant_id'
    | 'name'
    | 'note'
    | 'order_type'
    | 'inventory_direction'
  >;
}

function isValidMaterialList(
  materialList: Pick<MaterialList, 'order_type' | 'klant_id' | 'project_id'>,
) {
  const { order_type, project_id, klant_id /* note */ } = materialList;
  return !!(
    (order_type !== 'PROJECT' || project_id || klant_id)
    // &&(order_type !== 'SUPPLIER' || !!note)
  );
}

const orderTypes: MaterialOrderType[] = [
  'PROJECT',
  'SCRAP',
  'SUPPLIER',
  'WORKSHOP',
];

const MaterialListDetailsEditor: React.FC<MaterialListDetailsEditorProps> = ({
  materialList,
  error,
  onChange,
  onRemove,
  onSubmit,
  updateMaterial,
  removeMaterial,
  addMaterial,
  users,
}) => {
  const {
    created_at,
    created_by_user_id,
    recnum,
    material,
    inventory_direction,
    name,
    order_type,
    status,
    note,
    klant_id,
    project_id,
    processed_at,
    processed_by_user_id,
    modified_at,
  } = materialList;
  const artikelPickerModalRef = React.useRef<ArtikelPickerModalRef>(null);
  const intl = useIntl();
  /*
   * callbacks
   */
  const handleChange = useCallback<FormEventHandler<any>>(
    ev => {
      const change: Partial<UpdatableMaterialList> = {
        [ev.currentTarget.name]: ev.currentTarget.value,
      };
      if (change.order_type === 'SCRAP') {
        change.inventory_direction = 'OUT';
      }
      onChange(change);
    },
    [onChange],
  );

  const handleChangeClientOrProject = React.useCallback(
    (change: { clientId: number | null; projectId: number | null }) => {
      onChange({ project_id: change.projectId, klant_id: change.clientId });
    },
    [onChange],
  );

  const handleAddItem = useCallback<FormEventHandler<any>>(
    ev => {
      artikelPickerModalRef.current!.selectArtikel().then(stock => {
        if (stock && recnum) {
          addMaterial?.(recnum, [
            {
              amount: 1,
              artikel_id: stock.recnum,
              location_id: stock.location_id,
            },
          ]);
        }
      });
    },
    [addMaterial, recnum],
  );

  return (
    <div className="container">
      <form
        onSubmit={ev => {
          ev.preventDefault();
          onSubmit?.(ev);
        }}
      >
        {error && <Alert bsStyle="danger">{error.message}</Alert>}
        <Row>
          <Col xs={12}>
            <FormGroup>
              <ControlLabel>
                <FormattedMessage {...msg.NAME} />
              </ControlLabel>
              <FormControl
                type="text"
                disabled={status !== 'DRAFTED'}
                onChange={handleChange}
                value={name || ''}
                name="name"
                placeholder={intl.formatMessage(msg.NAME)}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <FormGroup>
              <ControlLabel>
                <FormattedMessage {...msg.ORDER_TYPE} />
              </ControlLabel>
              <FormControl
                disabled={status !== 'DRAFTED'}
                componentClass="select"
                value={order_type}
                name="order_type"
                onChange={handleChange}
              >
                {orderTypes.map(t => (
                  <option key={t} value={t}>
                    <FormattedMessage {...msg[t]} />
                  </option>
                ))}
              </FormControl>
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup>
              <ControlLabel>
                <FormattedMessage {...msg.inventoryDirection} />
              </ControlLabel>
              <ToggleButtonGroup
                style={{ display: 'block' }}
                type="radio"
                name="inventory_direction"
                value={inventory_direction}
                onChange={dir =>
                  handleChange({
                    currentTarget: { name: 'inventory_direction', value: dir },
                  } as any)
                }
              >
                <ToggleButton
                  disabled={status !== 'DRAFTED' || order_type === 'SCRAP'}
                  value={'IN'}
                >
                  <FormattedMessage {...msg.IN} />
                </ToggleButton>
                <ToggleButton disabled={status !== 'DRAFTED'} value={'OUT'}>
                  <FormattedMessage {...msg.OUT} />
                </ToggleButton>
              </ToggleButtonGroup>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <FormGroup>
              <ControlLabel>
                <FormattedMessage {...msg.note} />
              </ControlLabel>
              <FormControl
                componentClass="textarea"
                onChange={handleChange}
                disabled={status !== 'DRAFTED'}
                value={note || ''}
                name="note"
                placeholder={
                  order_type !== 'SUPPLIER'
                    ? intl.formatMessage(msg.notePlaceholder)
                    : intl.formatMessage(msg.notePlaceholderSupplier)
                }
              />
            </FormGroup>
          </Col>
        </Row>
        {order_type === 'PROJECT' && (
          <ClientOrProjectSelectionControl
            clientId={klant_id || null}
            projectId={project_id || null}
            disabled={status !== 'DRAFTED'}
            onChange={handleChangeClientOrProject}
          />
        )}
        <Row>
          <Col xs={6}>
            <FormGroup>
              <ControlLabel>
                <FormattedMessage {...msg.CREATED_BY} />
              </ControlLabel>
              <div>
                {created_by_user_id && users![created_by_user_id] ? (
                  <UserToken small withText user={users![created_by_user_id]} />
                ) : (
                  <p className="form-control-static">
                    <FormattedMessage {...msg.UNKNOWN_USER} />
                  </p>
                )}
              </div>
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup>
              <ControlLabel>
                <FormattedMessage {...msg.CREATED} />
              </ControlLabel>
              <div>
                {created_at ? (
                  <FormattedRelative value={created_at} />
                ) : (
                  <p className="form-control-static">-</p>
                )}
              </div>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <FormGroup>
              <ControlLabel>
                <FormattedMessage {...msg.STATUS} />
              </ControlLabel>
              {status === 'DRAFTED' ? (
                <InputGroup>
                  <FormControl
                    type="text"
                    value={intl.formatMessage(msg.DRAFTED)}
                    disabled
                  />
                  <InputGroup.Button>
                    <Button
                      disabled={!isValidMaterialList(materialList)}
                      onClick={() => {
                        onChange({ status: 'SUBMITTED' }, true);
                      }}
                      bsStyle="primary"
                    >
                      <FormattedMessage
                        {...msg.SUBMIT_FOR_PROCESSING_BUTTON}
                      ></FormattedMessage>{' '}
                      &rarr;
                    </Button>
                  </InputGroup.Button>
                </InputGroup>
              ) : status === 'SUBMITTED' ? (
                <InputGroup>
                  <FormControl type="text" value={status} disabled />
                  <InputGroup.Button>
                    <Button
                      onClick={() => {
                        onChange({ status: 'DRAFTED' }, true);
                      }}
                      bsStyle="primary"
                    >
                      &larr; <FormattedMessage {...msg.REVERT_TO_DRAFT} />
                    </Button>
                  </InputGroup.Button>
                </InputGroup>
              ) : (
                <FormControl
                  type="text"
                  value={intl.formatMessage(msg[status!])}
                  disabled
                />
              )}
            </FormGroup>
          </Col>
        </Row>
        {/* <Row>
          <Col xs={6}>
            <FormGroup>
              <ControlLabel>Processed by</ControlLabel>
              <div>
                {processed_by_user_id && users![processed_by_user_id] ? (
                  <UserToken
                    small
                    withText
                    user={users![processed_by_user_id]}
                  />
                ) : (
                  <p className="form-control-static">Unknown user</p>
                )}
              </div>
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup>
              <ControlLabel>Processed at</ControlLabel>
              <p className="form-control-static">
                {processed_at ? (
                  <FormattedRelative value={processed_at} />
                ) : (
                  '-'
                )}
              </p>
            </FormGroup>
          </Col>
        </Row> */}

        <Row>
          <Col xs={12}>
            <Button disabled={!onSubmit || status !== 'DRAFTED'} type="submit">
              <FormattedMessage {...msg.SAVE} />
            </Button>
            {onRemove && (
              <Button
                type="button"
                disabled={status !== 'DRAFTED'}
                className="pull-right btn-link danger"
                onClick={() => {
                  const confirmMessage = intl.formatMessage(msg.CONFIRM_DELETE);
                  // eslint-disable-next-line no-restricted-globals
                  if (confirm(confirmMessage)) {
                    onRemove();
                  }
                }}
              >
                <FormattedMessage {...msg.DELETE} />
              </Button>
            )}
          </Col>
        </Row>
        {recnum && (
          <Row>
            <hr />
            <Col xs={12}>
              <FormGroup>
                <ControlLabel>Material</ControlLabel>
                <ListGroup>
                  <ListGroupItem>
                    <Row>
                      <Col xs={2}>
                        <FormattedMessage {...msg.CODE} />
                      </Col>
                      <Col xs={7}>
                        <FormattedMessage {...msg.DESCRIPTION} />
                      </Col>
                      <Col xs={2}>
                        <FormattedMessage {...msg.AMOUNT} />
                      </Col>
                    </Row>
                  </ListGroupItem>
                  {material &&
                    material.map((item, index) => (
                      <MaterialListItemDisplay
                        key={index}
                        item={item}
                        disabled={status !== 'DRAFTED'}
                        removeMaterial={removeMaterial!}
                        updateMaterial={updateMaterial!}
                      />
                    ))}
                  <ListGroupItem>
                    <Row>
                      <Button
                        // bsStyle={'secondary'}
                        disabled={status !== 'DRAFTED'}
                        bsSize={'sm'}
                        onClick={handleAddItem}
                        className="pull-right"
                      >
                        <FormattedMessage {...msg.ADD_MATERIAL} />
                      </Button>
                    </Row>
                  </ListGroupItem>
                </ListGroup>
              </FormGroup>
            </Col>
          </Row>
        )}
        <ArtikelPickerModal ref={artikelPickerModalRef} />
      </form>
    </div>
  );
};

export default MaterialListDetailsEditor;

type MaterialListItemProps = {
  item: MaterialListItem;
  disabled?: boolean;
} & Pick<MaterialListStoreInterface, 'updateMaterial' | 'removeMaterial'>;

const MaterialListItemDisplay: React.FC<MaterialListItemProps> = ({
  item,
  disabled = false,
  removeMaterial,
  updateMaterial,
}) => {
  const amountInputRef = React.useRef<HTMLInputElement>(null);
  const intl = useIntl();
  const { node, onContextMenuHandler } = useContextMenu([
    {
      type: 'action',
      label: intl.formatMessage(msg.DELETE_ITEM),
      className: 'danger',
      action: () => removeMaterial?.(item.material_list_id, [item.recnum]),
    },
  ]);
  const LGI = ListGroupItem as any;

  useEffect(() => {
    if (amountInputRef.current) {
      amountInputRef.current.value = item.amount.toString();
    }
  }, [item]);

  return (
    <LGI onContextMenu={disabled ? undefined : onContextMenuHandler}>
      <Row>
        <Col xs={2}>{artikelCode(item.artikel)}</Col>
        <Col xs={7}>{item.artikel.description}</Col>
        <Col xs={2}>
          <FormControl
            inputRef={amountInputRef as any}
            disabled={disabled}
            bsSize="sm"
            type="number"
            data-item-recnum={item.recnum}
            onBlur={ev => {
              const { value } = (ev.currentTarget as any) as HTMLInputElement;
              const amount = parseFloat(value);
              if (!isNaN(amount)) {
                updateMaterial(item.material_list_id, [
                  { recnum: item.recnum, amount },
                ]);
              }
            }}
          />
        </Col>
        <Col xs={1}>
          {!disabled && (
            <Button
              style={{
                marginRight: -16,
              }}
              // bsSize="sm"
              bsStyle={'link'}
              className="pull-right"
              onClick={onContextMenuHandler as any}
            >
              <Glyphicon glyph="option-vertical" />
            </Button>
          )}
        </Col>
      </Row>
      {node}
    </LGI>
  );
};
