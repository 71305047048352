import * as React from 'react';
// import PropTypes from 'prop-types';
import classNames from 'classnames';

import { defineMessages, injectIntl } from 'react-intl';
import styles from './StatusBadge.module.scss';

const msg = defineMessages({
  OPEN: {
    id: 'STATUS_BADGE.OPEN',
    defaultMessage: 'Open',
    description: 'Label of open status badge',
  },
  CLOSED: {
    id: 'STATUS_BADGE.CLOSED',
    defaultMessage: 'Closed',
    description: 'Label of closed status badge',
  },
});

const StatusBadge = ({
  status = 'opened',
  size = 'large',
  className = '',
  intl,
  create = false,
  onClick = undefined,
}) => (
  <div
    className={classNames(
      className,
      styles.root,
      styles[status],
      styles[size],
      create && styles.create,
    )}
    onClick={onClick}
  >
    <i className={status === 'closed' ? 'fa fa-check' : 'fa fa-times'} />{' '}
    {size !== 'small' &&
      (status === 'closed'
        ? intl.formatMessage(msg.CLOSED)
        : intl.formatMessage(msg.OPEN))}
  </div>
);

export default injectIntl(StatusBadge);
