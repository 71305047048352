export const detectExtraBackspace = (clb: () => void) => (
  event: React.KeyboardEvent<HTMLInputElement>
) => {
  const {
    currentTarget: { value },
    key
  } = event;
  if (!value && key === 'Backspace') {
    clb();
  }
};
