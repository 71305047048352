export default function undefinedPassthough<I, O>(
  fn: (i: I) => O,
): (i: I | undefined) => O | undefined {
  return function(input: I | undefined) {
    if (input === undefined) {
      return undefined;
    }
    return fn(input);
  };
}
