import { FileDownload } from '../modules/fileDownloads';
import { FileRef } from '../modules/files';

export interface CatalogNode {
  recnum: number;
  catalog_id: number;
  catalog_group_id: number | null;
  ordering: number;
  nl: string | null;
  en: string | null;
  de: string | null;
  fr: string | null;
  ru: string | null;
  es: string | null;
  tr: string | null;
}
export type DocsNodeLangKey = 'nl' | 'en' | 'de' | 'fr' | 'ru' | 'es' | 'tr';

export type CatalogGroup = CatalogNode;
export interface CatalogFile extends CatalogNode {
  duplicatenr: number;
  prefix: string;
  mime: string;
  extension: string;
  date_in: string;
  revision_nr: number;
  revision_message: string;
  size: number;
  checksum: string;
}

export interface Catalog {
  recnum: number;
  project_id: number;
  groups: CatalogGroup[];
  files: CatalogFile[];
  notifications_enabled: boolean;
}

export interface AugmentedCatalogFile extends CatalogFile {
  download: FileDownload | null;
  file: FileRef | null;
  /**
   * wether or not the file is matched based on search params
   *
   * null if search is innactive
   */
  paramMatch: boolean | null;
  /**
   * wether or not the file is matched based on text search.
   *
   * null if search is innactive
   */
  textSearchMatch: boolean | null; // bla
}

export interface CatalogStructureGroup extends CatalogGroup {
  groups: CatalogStructureGroup[];
  files: AugmentedCatalogFile[];
  /**
   * wether or not the group is matched based on search params.
   *
   * null if search is innactive
   */
  textSearchMatch: boolean | null;
  /**
   * Wether or not the group contains matched files
   * null if search is innactive
   */
  containsMatch: boolean | null;
  /**
   * Wether or not the group contains the file referenced by the deep-link
   * this is usefull so that these groups can be expanded
   */
  containsDeepLinkFile: boolean;
}

export type CatalogStructure = CatalogStructureGroup[];

export const SUPPORTED_LANGUAGES = [
  'en',
  'nl',
  'de',
  'es',
  'fr',
  'ru',
  'tr',
] as const;
