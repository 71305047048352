import * as Sentry from '@sentry/react';
import { createObjectURL } from 'blob-util';
import classNames from 'classnames';

import * as React from 'react';
import { Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import * as uuid from 'uuid';
import loader from '../../assets/loader.gif';
import stl from './DownloadButton.module.scss';

export interface DownloadButtonProps {
  downloadUri: string;
  className?: string;
  bsStyle?: string;
  component?: any;
  children?: React.ReactNode;
  showLabelXs?: boolean;
}

type DownloadStatus = null | 'downloading' | 'failure' | 'success';

export interface DownloadButtonState {
  status: DownloadStatus;
}

class DownloadButton extends React.Component<
  DownloadButtonProps,
  DownloadButtonState
> {
  state = { status: null };
  handleDownload = async () => {
    this.setState({ status: 'downloading' });
    const { downloadUri } = this.props;
    try {
      const jobId = uuid.v4();
      const pollingUrl = new URL(downloadUri, window.location as any);
      pollingUrl.searchParams.append('jobId', jobId);

      let blob: Blob | null = null;
      do {
        const res = await fetch(pollingUrl, {});
        if (res.status === 200) {
          blob = await res.blob();
          break; // were done
        }
        if (res.status !== 504) {
          // this is a failure
          throw new Error(
            `Unexpected result when downloading report (status: ${
              res.status
            }, body: ${await res.text()})`,
          );
        }
      } while (true);

      const element = document.createElement('a');
      const url = createObjectURL(blob);
      element.setAttribute('href', url);
      element.setAttribute('download', 'report.pdf');
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
      this.setState({ status: 'success' });
    } catch (e) {
      console.log(e, downloadUri);

      Sentry.captureException(e);
      this.setState({ status: 'failure' });
      // alert('Something went wrong downloading the report.');
    }
  };

  render() {
    const style = this.state.status === 'failure' ? 'danger' : 'primary';
    const Component: any = this.props.component || Button;
    return (
      <FormattedMessage
        id="PAPER.NAV.DOWNLOAD_BUTTON"
        defaultMessage="Download"
      >
        {msg => (
          <Component
            bsStyle={style}
            className={classNames(
              this.props.className,
              stl[this.state.status || ''],
            )}
            disabled={this.state.status === 'downloading'}
            onClick={this.handleDownload}
            title={msg.toString()}
          >
            {this.state.status === 'downloading' ? (
              <img alt="progress indicator" src={loader} />
            ) : this.state.status === 'failure' ? (
              <i className="fa fa-times" />
            ) : this.state.status === 'success' ? (
              <i className="fa fa-check" />
            ) : (
              <i className="fa fa-download" />
            )}{' '}
            <span
              className={classNames(!this.props.showLabelXs && 'hidden-xs')}
            >
              {msg}
            </span>
          </Component>
        )}
      </FormattedMessage>
    );
  }
}

export default DownloadButton;
