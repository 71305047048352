import {
  constructTree,
  MakeTree,
  makeTreeIndex,
} from '../components/Tree/common';
import { LocationWithDetails } from '../modules/locations';

const locations: LocationWithDetails[] = [
  {
    name: 'Plank {n}',
    code: '.{n}',
    ordering: 2,
    parent_location_id: 'e86df074-436d-4ad4-abc9-e20fa6db7ffe',
    labeltype: 'middel',
    label_created: null,
    recnum: 35,
    id: '778329b3-dc12-4e44-b9b3-1006405af29d',
    stock: [
      {
        recnum: 1852,
        subset: '1A4A',
        unsalable: true,
        volgnummer: 1010,
        description: 'Pomp CL  32-125-0.13kW ND6  50Hz-4P 400V',
        unit: 'S',
        subset_description: 'Johnson CL pompen 50Hz 400V',
      },
    ],
    identifier: null,
  },
  {
    name: 'Magazijn',
    code: 'M',
    ordering: 0,
    parent_location_id: null,
    labeltype: null,
    label_created: null,
    recnum: 22,
    id: 'de87b53c-dc92-41ba-be27-0212dc33ac97',
    stock: [],
    identifier: null,
  },
  {
    name: 'Pad {L}',
    code: '-{L}',
    ordering: 0,
    parent_location_id: 'de87b53c-dc92-41ba-be27-0212dc33ac97',
    labeltype: 'a4',
    label_created: null,
    recnum: 23,
    id: '3a83d98d-e641-4891-a272-7930aada536a',
    stock: [
      {
        recnum: 1852,
        subset: '1A4A',
        unsalable: true,
        volgnummer: 1010,
        description: 'Pomp CL  32-125-0.13kW ND6  50Hz-4P 400V',
        unit: 'S',
        subset_description: 'Johnson CL pompen 50Hz 400V',
      },
    ],
    identifier: {
      id: '5b3aba9d-4d96-4dea-aa65-7702812632a4',
      created: '2023-05-16T12:30:36.133Z',
      location_id: '3a83d98d-e641-4891-a272-7930aada536a',
    },
  },
  {
    name: 'Stelling {n}',
    code: '-{n}',
    ordering: 0,
    parent_location_id: '3a83d98d-e641-4891-a272-7930aada536a',
    labeltype: '',
    label_created: null,
    recnum: 24,
    id: 'e86df074-436d-4ad4-abc9-e20fa6db7ffe',
    stock: [],
    identifier: null,
  },
  {
    name: 'Vak {n}',
    code: '.{n}',
    ordering: 0,
    parent_location_id: '446fe06b-09dc-44cb-9e0e-f44361c5a9c0',
    labeltype: 'klein',
    label_created: null,
    recnum: 28,
    id: '3db37fe2-ee48-426f-a63c-d12130d53758',
    stock: [
      {
        recnum: 499977,
        subset: '3A3B',
        volgnummer: 4,
        unsalable: true,
        description: 'Slang PVC 4x2,5mm zwart',
        unit: 'M',
        subset_description: 'Druppelslang PVC zwart Meteor',
      },
    ],
    identifier: null,
  },
  {
    name: 'Vak {n}',
    code: '.{n}',
    ordering: 1,
    parent_location_id: '446fe06b-09dc-44cb-9e0e-f44361c5a9c0',
    labeltype: 'klein',
    label_created: null,
    recnum: 29,
    id: '69122cc3-aac7-4fb7-b11a-e15c7a0204aa',
    stock: [
      {
        recnum: 499978,
        subset: '3A3B',
        volgnummer: 8,
        description: 'Slang PVC 8x5,0mm zwart',
        unit: 'M',
        subset_description: 'Druppelslang PVC zwart Meteor',
      },
    ],
    identifier: null,
  },
  {
    name: 'Vak {n}',
    code: '.{n}',
    ordering: 2,
    parent_location_id: '446fe06b-09dc-44cb-9e0e-f44361c5a9c0',
    labeltype: 'klein',
    label_created: null,
    recnum: 30,
    id: 'a417b4dc-adce-486a-b4d5-0fbed98b25ba',
    stock: [
      {
        recnum: 2163,
        subset: '3C1A',
        volgnummer: 16,
        description: 'Kogelkraan  16mm CH PVC EPDM',
        unit: 'S',
        subset_description: 'Kogelkraan CH PVC EPDM',
      },
    ],
    identifier: null,
  },
  {
    name: 'Plank {n}',
    code: '.{n}',
    ordering: 0,
    parent_location_id: 'e86df074-436d-4ad4-abc9-e20fa6db7ffe',
    labeltype: '',
    label_created: null,
    recnum: 27,
    id: '446fe06b-09dc-44cb-9e0e-f44361c5a9c0',
    stock: [],
    identifier: null,
  },
  {
    name: 'Plank {n}',
    code: '.{n}',
    description: 'Diverse flenzen',
    ordering: 1,
    parent_location_id: 'e86df074-436d-4ad4-abc9-e20fa6db7ffe',
    labeltype: 'middel',
    label_created: null,
    name_replaces_path: true,
    unsalable: true,
    recnum: 31,
    id: '8162abfe-9448-4e3a-8cee-e823a5088965',
    stock: [
      {
        recnum: 2163,
        subset: '3C1A',
        volgnummer: 16,
        description: 'Kogelkraan  16mm CH PVC EPDM',
        unit: 'S',
        subset_description: 'Kogelkraan CH PVC EPDM',
      },
    ],
    identifier: null,
  },
] as any[];

export default locations;

export const printingTestLabels = (Object.values(
  makeTreeIndex(constructTree(locations)),
).filter(l => l.labeltype) as any[]) as MakeTree<LocationWithDetails>[];
