import * as uuid from 'uuid';
import { MakeOptional, PartialExcept } from '../logic/types';

const uuidv4 = uuid.v4;

// structure fields
// export enum FieldType {
//   ROOT = 'ROOT_FIELD',
//   GROUP = 'GROUP_FIELD',
//   NESTEDGROUP = 'NESTEDGROUP_FIELD',
//   COLLECTION = 'COLLECTION_FIELD',
//   TEXT = 'TEXT_FIELD',
//   BOOLEAN = 'BOOLEAN_FIELD',
//   NUMERIC = 'NUMERIC_FIELD',
//   TASK = 'TASK_FIELD',
//   SELECTION = 'SELECTION_FIELD',
//   CHECKPOINT = 'CHECKPOINT_FIELD'
// }

interface BaseAttachment {
  id: string;
  type: string;
  user_id: number;
  created: Date;
}

export interface Geotag {
  label?: string | null;
  position: GeolocationPosition;
}

export interface FileAttachment extends BaseAttachment {
  type: 'attachment'; // awkward name, because it was there first
  checksum: string;
  contentType: string;
  size: number;
}
export interface GeotagAttachment extends BaseAttachment {
  type: 'geotag';
  geotag: Geotag;
}
export interface NoteAttachment extends BaseAttachment {
  type: 'note';
  text: string;
}

export type Attachment = GeotagAttachment | FileAttachment | NoteAttachment;

type MakePartialAttachment<T extends BaseAttachment> = Omit<
  T,
  'id' | 'created' | 'type'
>;

export type PartialGeotagAttachment = MakePartialAttachment<GeotagAttachment>;
export type PartialFileAttachment = MakePartialAttachment<FileAttachment>;
export type PartialNoteAttachment = MakePartialAttachment<NoteAttachment>;
export type PartialAttachment =
  | PartialFileAttachment
  | PartialGeotagAttachment
  | PartialNoteAttachment;

interface ProtoReference {
  listId: string;
  nodeId: string;
}

export interface BaseField {
  id: string;
  created: Date;
  type: string;
  parentNodeId: string | null;
  protoChain: ProtoReference[];
  modifiedFields: string[];
}

export interface StructureField extends BaseField {
  children: Field[];
}

export interface RootField extends StructureField {
  type: 'ROOT_FIELD';
}

export interface GroupField extends StructureField {
  type: 'GROUP_FIELD';
  title_en: string;
  title_nl: string;
}

export interface ValueField extends BaseField {
  value: any;
  title_en: string;
  title_nl: string;
  description_en: string;
  description_nl: string;
  attachments: Attachment[];
}

export interface CheckpointField extends ValueField {
  type: 'CHECKPOINT_FIELD';
  value: null | boolean;
}
export interface NumericField extends ValueField {
  type: 'NUMERIC_FIELD';
  value: null | number;
}
export interface TaskField extends ValueField {
  type: 'TASK_FIELD';
  value: null | boolean;
}

export type Field =
  | RootField
  | GroupField
  | CheckpointField
  | NumericField
  | TaskField;

type MakePartialField<T extends BaseField> = Omit<
  PartialExcept<T, 'id' | 'created' | 'type'>,
  'parentNodeId' | 'modifiedFields' | 'protoChain'
>;

export type PartialGroupField = Omit<MakePartialField<GroupField>, 'children'>;
export type PartialCheckpointField = MakePartialField<CheckpointField>;
export type PartialNumericField = MakePartialField<NumericField>;
export type PartialTaskField = MakePartialField<TaskField>;

export type PartialField =
  | PartialGroupField
  | PartialCheckpointField
  | PartialNumericField
  | PartialTaskField;

export type PatchField = Partial<Omit<PartialField, 'id' | 'created' | 'type'>>;

/*
 * convenience (partial)field creators
 */
type MakePartialPartialField<
  PF extends { id: string; created: Date; type: string }
> = Omit<MakeOptional<PF, 'id'>, 'created' | 'type'>;

// type MakeNotPartial<F extends MakePartialField<BaseField>> = F &
//   Pick<BaseField, 'parentNodeId'>;

export const createRootField = (
  partial: PartialExcept<RootField, 'id'>,
): RootField => ({
  created: new Date(),
  type: 'ROOT_FIELD',
  children: [],
  protoChain: [],
  modifiedFields: [],
  parentNodeId: null,
  ...partial,
});

export const createPartialGroupField = (
  partial: MakePartialPartialField<PartialGroupField>,
): PartialGroupField => ({
  type: 'GROUP_FIELD',
  id: partial.id || uuidv4(),
  created: new Date(),
  ...partial,
});

export const createGroupField = (
  partial: PartialGroupField,
  parentNodeId: string,
): GroupField => ({
  title_en: '',
  title_nl: '',
  children: [],
  modifiedFields: [],
  protoChain: [],
  parentNodeId,
  ...partial,
});

export const createPartialCheckpointField = (
  partial: MakePartialPartialField<PartialCheckpointField>,
): PartialCheckpointField => ({
  type: 'CHECKPOINT_FIELD',
  id: partial.id || uuidv4(),
  created: new Date(),
  ...partial,
});

export const createCheckpointField = (
  partial: PartialCheckpointField,
  parentNodeId: string,
): CheckpointField => ({
  title_en: '',
  title_nl: '',
  description_en: '',
  description_nl: '',
  value: null,
  modifiedFields: [],
  protoChain: [],
  parentNodeId,
  attachments: [],
  ...partial,
});

export const createPartialNumericField = (
  partial: MakePartialPartialField<PartialNumericField>,
): PartialNumericField => ({
  type: 'NUMERIC_FIELD',
  id: partial.id || uuidv4(),
  created: new Date(),
  ...partial,
});

export const createNumericField = (
  partial: PartialNumericField,
  parentNodeId: string,
): NumericField => ({
  title_en: '',
  title_nl: '',
  description_en: '',
  description_nl: '',
  value: null,
  modifiedFields: [],
  protoChain: [],
  parentNodeId,
  attachments: [],

  ...partial,
});

export const createPartialTaskField = (
  partial: MakePartialPartialField<PartialTaskField>,
): PartialTaskField => ({
  type: 'TASK_FIELD',
  id: partial.id || uuidv4(),
  created: new Date(),
  ...partial,
});

export const createTaskField = (
  partial: PartialTaskField,
  parentNodeId: string,
): TaskField => ({
  title_en: '',
  title_nl: '',
  description_en: '',
  description_nl: '',
  value: null,
  modifiedFields: [],
  protoChain: [],
  parentNodeId,
  attachments: [],

  ...partial,
});
