import * as React from 'react';
import { Breadcrumb } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Page from '../Page';
import ActivityList from '../ActivityList/index';
import { heuristicChronicalGrouping } from '../../utils/heuristicalChronologicalGrouping';
import ActivityFeedExplanation from '../ActivityFeedExplanation/index';
import LinkContainer from '../LinkContainer';

export default class MyActivityComponent extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  render() {
    const { activity, tickets, users, lists, works } = this.props;
    return (
      <Page>
        <Breadcrumb>
          <LinkContainer to="/dashboard">
            <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
          </LinkContainer>
          <Breadcrumb.Item active>Activiteit</Breadcrumb.Item>
        </Breadcrumb>

        {/* Activity */}
        <h3>
          <span>Activiteit</span>
          <ActivityFeedExplanation />
          {/* <pre>
            {JSON.stringify(heuristicChronicalGrouping(activity), null, 2)}
          </pre> */}
        </h3>
        {activity ? (
          heuristicChronicalGrouping(activity).map(g => (
            <React.Fragment>
              <h5>{g.title}</h5>
              <ActivityList
                activity={g.elements}
                users={users}
                works={works}
                lists={lists}
                tickets={tickets}
              />
            </React.Fragment>
          ))
        ) : (
          <span>Er gaat iets mis met het samenstellen van activiteit</span>
        )}
      </Page>
    );
  }
}
