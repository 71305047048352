import React from 'react';

export default class ScrollIntoView extends React.Component<{
  id: string;
  children?: React.ReactNode;
}> {
  componentDidMount() {
    this.scroll();
  }

  componentDidUpdate() {
    this.scroll();
  }

  scroll() {
    const { id } = this.props;
    if (!id) {
      return;
    }
    const element = document.querySelector(id);
    if (element) {
      element.scrollIntoView();
    }
  }

  render() {
    return this.props.children;
  }
}
