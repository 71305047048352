import PropTypes from 'prop-types';
import * as React from 'react';
import { Button, ListGroup } from 'react-bootstrap';
import { ContextMenu, ContextMenuTrigger } from 'react-contextmenu';
import { LISTMODE_MAINTAIN } from './';

import { renderFields } from './Field';

import { injectIntl } from 'react-intl';
import BaseField from './BaseField';
import styles from './FieldStyles.module.scss';

export default injectIntl(
  class GroupField extends BaseField {
    static propTypes = {
      dispatch: PropTypes.func.isRequired,
      field: PropTypes.object.isRequired,
      list: PropTypes.object.isRequired,
      user: PropTypes.object.isRequired,
    };

    renderReport() {
      const { field } = this.props;
      return (
        <div className={styles.group}>
          <h4>{this.getLocalizedfield('title')}</h4>
          <div>{field.children.map(renderFields(this.props))}</div>
        </div>
      );
    }

    renderUI() {
      const { field, mode } = this.props;
      const { children } = field;
      const contextMenuOptions = this.renderContextMenuOptions();
      return (
        <div
          className={this.isSelected() ? styles.selected : ''}
          onClick={this.select}
        >
          <ContextMenuTrigger
            id={field.id}
            disable={contextMenuOptions.length === 0}
          >
            <div className={styles.groupTitle}>
              {mode === LISTMODE_MAINTAIN && (
                <div>
                  <Button
                    title="Insert new checkpoint"
                    className="pull-right"
                    bsStyle="link"
                    onClick={this.addField}
                  >
                    <i className="fa fa-plus-square fa-lg" />
                  </Button>
                  {/* <Button
                    title="Insert checkpoint template"
                    className="pull-right"
                    bsStyle="link"
                    onClick={this.expandProto}
                  >
                    <i className="fa fa-share-square fa-rotate-180 fa-lg" />
                  </Button> */}
                </div>
              )}
              <h5>{this.getLocalizedfield('title')}</h5>
            </div>
            <ListGroup>{children.map(renderFields(this.props))}</ListGroup>
          </ContextMenuTrigger>

          <ContextMenu id={field.id}>{contextMenuOptions}</ContextMenu>
        </div>
      );
    }
  },
);
