/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import Autocomplete from 'react-autocomplete';
import { ControlLabel, FormGroup } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Project } from '../../models/Project';
import { Work } from '../../modules/works';
import msg from './msg';
import styles from './WorkSelection.module.scss';

export interface DisplayProps {
  inline?: boolean;
}

interface Props extends DisplayProps {
  disabled?: boolean;
  projects: Project[];
  works: Work[];
  projectValue: string;
  workValue: string;
  projectOk: boolean;
  workOk: boolean;
  onProjectValueChanged: (string: string) => void;
  onWorkValueChanged: (string: string) => void;
}

function matchProjectToTerm(p: Project, term: string = '') {
  const TERM = term.toUpperCase();
  return (
    TERM === '' ||
    p.projectname.toUpperCase().includes(TERM) ||
    p.klant_naam.toUpperCase().includes(TERM)
  );
}

function matchWorkToTerm(work: Work, term: string = '') {
  const TERM = term.toUpperCase();
  return (
    TERM === '' ||
    (work.id && work.id.toLowerCase().includes(TERM.toLowerCase())) ||
    // (work.klant && work.klant.toLowerCase().includes(value.toLowerCase())) ||
    (work.oms && work.oms.toLowerCase().includes(TERM.toLowerCase())) ||
    (work.oms2 && work.oms2.toLowerCase().includes(TERM.toLowerCase()))
  );
}

export const generic = {
  wrapperProps: {
    className: 'dropdown open',
    style: { display: 'block' },
  },
  selectOnBlur: true,
  // getItemValue: identity,
  renderInput: (props: any) => (
    <input
      key="input"
      {...props}
      placeholder={!props.disabled && 'Typ om te zoeken'}
      className="form-control"
    />
  ),
  renderMenu: (children: any) => <ul className="dropdown-menu">{children}</ul>,
};

const WorkSelectionComponent: React.FC<Props> = ({
  projects,
  works,
  projectValue,
  workValue,
  projectOk,
  workOk,
  onProjectValueChanged,
  onWorkValueChanged,
  disabled = false,
  inline = true,
}) => {
  const project = (
    <FormGroup validationState={(projectOk && 'success') || null}>
      <ControlLabel>
        {' '}
        <FormattedMessage {...msg.FORM_PROJECT} />
      </ControlLabel>
      <Autocomplete
        inputProps={{ disabled }}
        value={projectValue}
        items={projects}
        onChange={({ target: { value } }) => onProjectValueChanged(value)}
        getItemValue={(p: Project) => p.projectname}
        shouldItemRender={matchProjectToTerm}
        onSelect={value => onProjectValueChanged(value)}
        renderItem={(project: Project, isHighlighted) => (
          <li className={`item ${isHighlighted ? styles.highlighted : ''}`}>
            <a title={`${project.id}`}>{project.projectname}</a>
          </li>
        )}
        {...generic}
      />
    </FormGroup>
  );
  const work = (
    <FormGroup validationState={(workOk && 'success') || null}>
      <ControlLabel>
        {' '}
        <FormattedMessage {...msg.FORM_WORK} />
      </ControlLabel>
      <Autocomplete
        value={workValue}
        items={works}
        onChange={({ target: { value } }) => onWorkValueChanged(value)}
        getItemValue={(w: Work) => w.id}
        onSelect={value => onWorkValueChanged(value)}
        renderItem={(work: Work, isHighlighted) => (
          <li className={`item ${isHighlighted ? styles.highlighted : ''}`}>
            <a
              title={
                [work.oms, work.oms2]
                  .map(x => x.trim())
                  .filter(x => x)
                  .join('\n') || 'geen omschrijving'
              }
            >
              {work.id}
            </a>
          </li>
        )}
        {...generic}
        inputProps={{ disabled }}
        renderInput={(props: any) =>
          // inject disabled prop
          generic.renderInput({ ...props, disabled: !projectOk })
        }
      />
    </FormGroup>
  );

  return inline ? (
    <div className="row">
      <div className="col-sm-6">{project}</div>
      <div className="col-sm-6">{work}</div>
    </div>
  ) : (
    <>
      {project}
      {work}
    </>
  );
};

export default WorkSelectionComponent;
