import { defineMessages } from 'react-intl';

export default defineMessages({
  'TICKET_FIELDS.TITLE': {
    id: 'TICKET_FIELDS.TITLE',
    defaultMessage: 'title',
    description: 'title field of ticket',
  },
  'TICKET_FIELDS.DESCRIPTION': {
    id: 'TICKET_FIELDS.DESCRIPTION',
    defaultMessage: 'Description',
    description: 'description field of ticket',
  },
  'TICKET_FIELDS.ASSIGNEE_USER_ID': {
    id: 'TICKET_FIELDS.ASSIGNEE_USER_ID',
    defaultMessage: 'Assigned user',
    description: 'description field of ticket',
  },
  'TICKET_FIELDS.EXECUTOR_USER_ID': {
    id: 'TICKET_FIELDS.EXECUTOR_USER_ID',
    defaultMessage: 'To be performed by',
    description: 'description field of ticket',
  },
  'TICKET_FIELDS.CREATOR_USER_ID': {
    id: 'TICKET_FIELDS.CREATOR_USER_ID',
    defaultMessage: 'Created by',
    description: 'description field of ticket',
  },
  'TICKET_FIELDS.WERK_ID': {
    id: 'TICKET_FIELDS.WERK_ID',
    defaultMessage: 'Work item',
    description: 'description field of ticket',
  },
  'TICKET_FIELDS.PROJECT_ID': {
    id: 'TICKET_FIELDS.PROJECT_ID',
    defaultMessage: 'Project',
    description: 'description field of ticket',
  },
  'TICKET_FIELDS.DUE': {
    id: 'TICKET_FIELDS.DUE',
    defaultMessage: 'Due date',
    description: 'due date field of ticket',
  },
  'TICKET_FIELDS.STATUS': {
    id: 'TICKET_FIELDS.STATUS',
    defaultMessage: 'Status',
    description: 'description field of ticket',
  },
  // TicketDetailsEditor
  CLOSE_TICKET: {
    id: 'TICKETS.CLOSE_TICKET',
    defaultMessage: 'Close ticket',
    description: '',
  },
  OPEN_TICKET: {
    id: 'TICKETS.OPEN_TICKET',
    defaultMessage: 'Reopen ticket',
    description: '',
  },
  NO_DESCRIPTION: {
    id: 'TICKETS.NO_DESCRIPTION',
    defaultMessage: 'No description',
    description: '',
  },
  EDIT_TICKET: {
    id: 'TICKETS.EDIT_TICKET_BUTTON',
    defaultMessage: 'Edit',
    description: 'Label of edit-ticket-button',
  },
  notifiedUsers: {
    id: 'TICKETS.NOTIFIED_USERS_LABEL.',
    defaultMessage: 'Notified users',
  },
  FORM_TITLE: {
    id: 'TICKET_DETAILS_TICKET_DETAILS_EDITOR.FORM_TITLE',
    defaultMessage: 'Title (english)',
    description: '',
  },
  FORM_DESCRIPTION: {
    id: 'TICKET_DETAILS_TICKET_DETAILS_EDITOR.FORM_DESCRIPTION',
    defaultMessage: 'Description',
    description: '',
  },
  FORM_CREATED_BY: {
    id: 'TICKET_DETAILS_TICKET_DETAILS_EDITOR.FORM_CREATED_BY',
    defaultMessage: 'Created by',
    description: '',
  },
  FORM_EXECUTED_BY: {
    id: 'TICKET_DETAILS_TICKET_DETAILS_EDITOR.FORM_EXECUTED_BY',
    defaultMessage: 'To be performed by',
    description: '',
  },
  FORM_ASSIGNED_TO: {
    id: 'TICKET_DETAILS_TICKET_DETAILS_EDITOR.FORM_ASSIGNED_TO',
    defaultMessage: 'Assigned user',
    description: '',
  },
  FORM_DUE_DATE: {
    id: 'TICKET_DETAILS_TICKET_DETAILS_EDITOR.FORM_DUE_DATE',
    defaultMessage: 'Due',
    description: '',
  },
  BUTTON_SAVE: {
    id: 'TICKET_DETAILS_TICKET_DETAILS_EDITOR.BUTTON_SAVE',
    defaultMessage: 'Save',
    description: '',
  },
  BUTTON_CANCEL: {
    id: 'TICKET_DETAILS_TICKET_DETAILS_EDITOR.BUTTON_CANCEL',
    defaultMessage: 'Cancel',
    description: '',
  },
});

export const TICKET_FIELDS_PREFIX = 'TICKET_FIELDS.';
