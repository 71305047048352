import classNames from 'classnames';
import React, { PropsWithChildren, ReactNode } from 'react';
import { Link } from 'wouter';
import { AllOrNone } from '../../common/logic/types';
import { DropLocation, TreeElementDragEventHandlers } from './Draggable';
import TrailIcon, { TrailIconType } from './TrailIcon';
import stl from './Tree.module.scss';

export interface Identifiable {
  id: string;
}

export interface BaseTreeElementProps extends Identifiable {
  name: string;
  open: boolean;
  hasChildren: boolean;
  trailIcons: TrailIconType[];
}

interface Checkable {
  checked: boolean;
  onToggleChecked: (id: any) => void;
}

export interface TreeElementProps
  extends BaseTreeElementProps,
    Pick<JSX.IntrinsicElements['tr'], 'onContextMenu'>,
    Pick<JSX.IntrinsicElements['a'], 'href'> {
  onToggleOpen: (id: any, all?: boolean) => void;
  selected?: boolean;
  icons?: ReactNode;
  additionalColumns?: ReactNode;
  dropLocationVisualization: DropLocation | null;
}

export const Element: React.FC<PropsWithChildren<
  TreeElementProps &
    AllOrNone<TreeElementDragEventHandlers> &
    AllOrNone<Checkable>
>> = ({
  id,
  name,
  trailIcons,
  selected,
  icons,
  additionalColumns,
  hasChildren,
  open,
  onToggleOpen,
  onContextMenu,
  onDragStart,
  onDragOver,
  onDragEnd,
  onDrop,
  href,
  dropLocationVisualization,
}) => {
  const handleToggle = React.useCallback<React.MouseEventHandler>(
    e => {
      onToggleOpen(id, e.altKey);
    },
    [id, onToggleOpen],
  );

  return (
    <tr
      draggable={!!onDrop}
      onDragEnter={ev => {
        // implemented to make the tr a valid droptarget
        ev.preventDefault();
      }}
      onDragOver={ev => {
        // implemented to make the tr a valid droptarget
        ev.preventDefault();
        ev.dataTransfer.dropEffect = ev.getModifierState('Control')
          ? 'copy'
          : 'move';
        return onDragOver?.(ev, id);
      }}
      onDragStart={ev => {
        onDragStart?.(ev, id);
      }}
      onDragEnd={ev => {
        onDragEnd?.(ev, id);
      }}
      onDrop={ev => {
        ev.preventDefault();
        onDrop?.(ev, id);
      }}
      onContextMenu={onContextMenu}
      className={classNames(
        selected && stl.selected,
        dropLocationVisualization && stl[dropLocationVisualization],
      )}
    >
      <td>
        <div className={stl.tree}>
          {trailIcons.map(type => (
            <TrailIcon type={type}></TrailIcon>
          ))}
          {hasChildren && (
            <button className={stl.btn} type="button" onClick={handleToggle}>
              <i
                className={classNames(
                  'fa',
                  open ? 'fa-caret-down' : 'fa-caret-right',
                )}
              ></i>
            </button>
          )}
          {icons}
          {href ? (
            <Link draggable={false} href={href} className={stl.leafLink}>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a>
                {name ? (
                  <span>{name}</span>
                ) : (
                  <span className={stl.nameMissing}>?</span>
                )}
              </a>
            </Link>
          ) : name ? (
            <span>{name}</span>
          ) : (
            <span className={stl.nameMissing}>?</span>
          )}
        </div>
      </td>
      {additionalColumns}
    </tr>
  );
};
