import { AnyAction } from 'redux';

export type OnlineState = boolean | null;
export interface OnlineAction {
  type: 'SET_ONLINE';
  payload: OnlineState;
}
export const online = (): OnlineAction => ({
  type: 'SET_ONLINE',
  payload: true,
});

export const offline = (): OnlineAction => ({
  type: 'SET_ONLINE',
  payload: false,
});

export default function(
  state: OnlineState = null,
  action: OnlineAction | AnyAction,
): OnlineState {
  if (action.type === 'SET_ONLINE') {
    return action.payload;
  }
  return state;
}
