import { MakeTree } from '../../components/Tree/common';
import { LocationWithDetails, Stock } from '../../modules/locations';

export type CountingTarget = {
  location: MakeTree<LocationWithDetails>;
  stock: Stock | null;
};

/**
 * Create a list of stock items to be counted by traversing a location tree in depth-first order
 * Locations with neither child locations nor stock are also listed (stock = null)
 * This function simply returns all stock without considering unsalable items or anything else
 */
export function createCountingList(
  location: MakeTree<LocationWithDetails>,
): CountingTarget[] {
  if (location.children.length) {
    return location.children.map(createCountingList).flat();
  }
  if (location.stock.length) {
    return location.stock.map(stock => ({ location, stock }));
  }
  return [{ location, stock: null }];
}
