import * as React from 'react';

export default function Spinner({ title = undefined }) {
  const [numFrequencies] = React.useState(1 + Math.round(Math.random() * 4));
  const [numCircles] = React.useState(4 + Math.round(Math.random() * 12));
  const [circleOffset] = React.useState(
    1 / (3 + Math.round(Math.random() * 8)),
  );

  const SIZE = 100;
  const PADDING = 10;
  const OFFSET = SIZE * circleOffset;
  const CIRCLE_SIZE = SIZE - 2 * OFFSET;
  const CENTER = SIZE / 2 + PADDING;

  const circles = [];

  const AnimatedCircle = ({
    center,
    size = 90,
    gradient,
    timeFactor = 1,
    props,
  }) => {
    const cX = center[0];
    const cY = center[1];
    const radius = size / 2;
    return (
      <g>
        <path
          d={`
            M${cX - radius}, ${cY}
            a ${radius},${radius} 0 1, 0 ${2 * radius}, 0
            a ${radius},${radius} 0 1, 0 ${-2 * radius}, 0
          `}
          stroke={`url(#gradient)`}
          strokeOpacity="0.7"
          strokeWidth="1"
          className=""
        >
          <animateTransform
            attributeName="transform"
            attributeType="XML"
            type="rotate"
            from={`${timeFactor > 0 ? 0 : 360} ${cX} ${cY}`}
            to={`${timeFactor > 0 ? 360 : 0} ${cX} ${cY}`}
            dur={`${Math.abs(timeFactor)}s`}
            repeatCount="indefinite"
          />
        </path>
      </g>
    );
  };

  for (let i = 0; i < numCircles; i++) {
    const angle = ((2 * Math.PI) / numCircles) * i;
    const center = [
      CENTER + Math.cos(angle) * OFFSET,
      CENTER + Math.sin(angle) * OFFSET,
    ];
    circles.push(
      <AnimatedCircle
        key={i}
        center={center}
        size={CIRCLE_SIZE}
        timeFactor={1.5 + (i % numFrequencies)}
      />,
    );
  }

  return (
    <div className="spinner" title={title}>
      <svg
        width={SIZE + 2 * PADDING}
        height={SIZE + 2 * PADDING}
        viewBox={`0 0 ${SIZE + 2 * PADDING} ${SIZE + 2 * PADDING}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>{circles}</g>
        <defs>
          <linearGradient
            id={'gradient'}
            x1={'0%'}
            x2={'100%'}
            y1={'50%'}
            y2={'50%'}
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0" stopColor="black" stopOpacity="0.98"></stop>
            <stop offset="0.13" stopColor="black" stopOpacity="0.92"></stop>
            <stop offset="0.71" stopColor="black" stopOpacity="0.6"></stop>
            <stop offset="1" stopColor="black" stopOpacity="0"></stop>
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
}
