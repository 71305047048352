import classNames from 'classnames';
import { tryCatch } from 'ramda';
import * as React from 'react';
import { defaultOptions, ParametersOptions } from './model';
import styles from './ParameterizedSearch.module.scss';

const { contrastColor } = require('contrast-color'); // tslint:disable-line

export interface ParameterComponentProps {
  name?: string;
  value: string | any;
  color?: string;
  options?: ParametersOptions;
  onRemove?: () => void;
}

const ParameterComponent: React.FC<ParameterComponentProps> = ({
  name,
  value,
  color,
  options = defaultOptions,
  onRemove,
}) => {
  let renderValue = value;
  const getLabelFromValue =
    name &&
    options.parameters[name] &&
    options.parameters[name].getLabelFromValue;
  if (getLabelFromValue) {
    renderValue = getLabelFromValue(value);
  }

  const style: React.CSSProperties = {};
  if (color) {
    style.backgroundColor = color;
    style.color = contrastColor({
      bgColor: style.backgroundColor,
      threshold: 200,
    });
  }

  return (
    <div
      className={classNames(
        styles.parameter,
        options.small && styles.small,
        styles[value],
      )}
    >
      {name && (
        <div className={styles.name}>
          <i
            className={classNames(
              `fa`,
              `fa-${tryCatch(
                () => options.parameters[name].icon,
                () => 'question',
              )()}`,
            )}
          />
          <span>{name}</span>
        </div>
      )}
      <div style={style} className={styles.value}>
        <span>{renderValue}</span>
        {onRemove && (
          <button type="button" className={styles.removeBtn} onClick={onRemove}>
            <i className={`fa fa-times`} />
          </button>
        )}
      </div>
    </div>
  );
};

export default ParameterComponent;
