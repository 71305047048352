import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import Types from 'Types';
import ProjectCatalogDetailsPage from '../../components/ProjectCatalogDetailsPage';
import { Project } from '../../models/Project';
import {
  CatalogState,
  fetchProjectCatalogDetails,
} from '../../modules/catalogs';
import { CatalogUIState, getCatalogUIFromState } from '../../modules/catalogUI';
import { FileDownloadsState } from '../../modules/fileDownloads';
import { FilesState } from '../../modules/files';
import { fetchProjects, ProjectState } from '../../modules/projects';
import { fetchWorks } from '../../modules/works';
import useInitialDispatch from '../../utils/useInitalDispatch';
import assertAndParseInt from '../../utils/assertAndParseInt';
import undefinedPassthough from '../../utils/undefinedPassthough';

export interface Props {
  params: { fileId?: string; projectId: string };
  projects: ProjectState;
  dispatch: Dispatch;
  catalogs: CatalogState;
  catalogUI: CatalogUIState;
  files: FilesState;
  fileDownloads: FileDownloadsState;
  online: boolean;
}
const FILTER_KEY = 'PROJECT_DETAILS_FILTER';

const ProjectCatalogDetailsContainer: React.FC<Props> = ({
  projects,
  catalogs,
  catalogUI,
  params,
  ...rest
}) => {
  const projectId = assertAndParseInt(params.projectId);
  const fileId = undefinedPassthough(assertAndParseInt)(params.fileId);

  useInitialDispatch(fetchProjects, fetchWorks, () =>
    fetchProjectCatalogDetails(projectId),
  );

  let project: Project | undefined;
  if (projects) {
    project = projects[projectId];
  }

  let catalog;
  if (catalogs) {
    catalog = Object.values(catalogs).find(c => c?.project_id === projectId);
  }

  return (
    <ProjectCatalogDetailsPage
      project={project}
      expandedFile={fileId}
      // id={routeParams.recnum}
      // onChangeFilter={handleChangeFilter}
      catalog={catalog}
      catalogUI={getCatalogUIFromState(catalogUI, catalog?.recnum)}
      {...rest}
    />
  );
};

function mapStateToProps(state: Types.RootState) {
  return {
    projects: state.projects,
    works: state.works,
    users: state.users,
    user: state.user,
    filter: state.filters[FILTER_KEY],
    catalogs: state.catalogs,
    catalogUI: state.catalogUI,
    files: state.files,
    fileDownloads: state.fileDownloads,
    online: !!state.online,
  };
}

export default connect(mapStateToProps)(ProjectCatalogDetailsContainer);
