import {
  createModificationCreator,
  ListEventReducer,
} from '../ListModification';

export const closeList = createModificationCreator<{}, 'CLOSE_LIST'>(
  'CLOSE_LIST',
);

export type CloseListModification = ReturnType<typeof closeList>;

const close: ListEventReducer<CloseListModification> = (
  list,
  mod: CloseListModification,
) => {
  return list;
};

export default close;
