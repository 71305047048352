import * as React from 'react';
import AccountDetailsComponent from '../components/AccountDetailsComponent';
import { useApiCall, useInitApiCall } from './useApiCall';
import Account from '../models/Account';
// import withRouter, { RouterType } from '../utils/withRouter';

const AccountDetailsContainer: React.FC<{
  params: { id: number };
}> = ({ params: { id: accountId } }) => {
  const [account, err] = useInitApiCall<Account>(
    `/admin/accounts/${accountId}`,
  );
  const apiCall = useApiCall();
  const [patchErr, setPatchErr] = React.useState<Error | null>(null);
  const [patchSuccess, setPatchSuccess] = React.useState<Account | null>(null);
  const patchAccount = React.useCallback(
    (account: Partial<Account> & { recnum: number }) => {
      setPatchErr(null);
      setPatchSuccess(null);
      return apiCall<Account>(`/admin/accounts/${account.recnum}`, {
        method: 'PATCH',
        body: JSON.stringify(account),
      })
        .then(setPatchSuccess)
        .catch(setPatchErr);
    },
    [apiCall],
  );
  return (
    <AccountDetailsComponent
      {...{
        account,
        err,
        patchAccount,
        patchErr,
        patchSuccess,
      }}
    ></AccountDetailsComponent>
  );
};

export default AccountDetailsContainer;
