import React, { createContext, PropsWithChildren, useContext } from 'react';

/* TODO: make lazy */
export function createUsableSingleton<T>(instantiate: () => T) {
  const Ctx = createContext<T | undefined>(undefined);
  const Provider: React.FC<PropsWithChildren> = ({ children }) => {
    const instance = instantiate();
    return <Ctx.Provider value={instance}>{children}</Ctx.Provider>;
  };

  function useInstance() {
    const instance = useContext(Ctx);
    if (!instance) {
      throw new Error(
        'createUsableSingleton useInstance must be used within a Provider',
      );
    }
    return instance;
  }

  return [Provider, useInstance] as const;
}
