import { stringify } from 'query-string';
import { CALL_API } from 'redux-api-middleware';
import { mapByRecnum, resolveHeaders, resolveUrl } from '../utils';
import { softAssertNever } from './utils';

export interface Work {
  recnum: number;
  id: string;

  project_id: number | null;

  klant: string;
  klant_id: number;
  klant_naam: string;

  afd: string;
  oms: string;
  oms2: string;

  users: number[];
}

export interface RequestFetchWorksAction {
  type: 'REQUEST_FETCH_WORKS';
}
export interface SuccessFetchWorksAction {
  type: 'SUCCESS_FETCH_WORKS';
  payload: Work[];
  meta: { params: any };
}
export interface FailureFetchWorksAction {
  type: 'FAILURE_FETCH_WORKS';
}

export function fetchWorks(params: {} | null = null): any {
  let query = '';
  if (params) {
    query = `?${stringify(params)}`;
  }
  return {
    [CALL_API]: {
      endpoint: resolveUrl(`/works${query}`),
      method: 'GET',
      headers: resolveHeaders(),
      types: [
        'REQUEST_FETCH_WORKS',
        { type: 'SUCCESS_FETCH_WORKS', meta: { params } },
        'FAILURE_FETCH_WORKS',
      ],
    },
  };
}

export interface RequestAssociateWorkAction {
  type: 'REQUEST_ASSOCIATE_WORK';
}
export interface SuccessAssociateWorkAction {
  type: 'SUCCESS_ASSOCIATE_WORK';
  payload: Work;
}
export interface FailureAssociateWorkAction {
  type: 'FAILURE_ASSOCIATE_WORK';
}

interface WorkAssociateInstruction {
  associate?: number;
  dissociate?: number;
}

export function associateWorkUser(
  workId: number,
  instruction: WorkAssociateInstruction,
) {
  return {
    [CALL_API]: {
      endpoint: resolveUrl(`/works/${workId}`),
      method: 'PATCH',
      body: JSON.stringify(instruction),
      headers: resolveHeaders(),
      types: [
        'REQUEST_ASSOCIATE_WORK',
        'SUCCESS_ASSOCIATE_WORK',
        'FAILURE_ASSOCIATE_WORK',
      ],
    },
  };
}

export type WorkAction =
  | RequestAssociateWorkAction
  | SuccessAssociateWorkAction
  | FailureAssociateWorkAction
  | RequestFetchWorksAction
  | SuccessFetchWorksAction
  | FailureFetchWorksAction;

export type WorkState = Record<number, Work>;

export default function works(
  state: WorkState = {},
  action: WorkAction,
): WorkState {
  if (action.type === 'SUCCESS_FETCH_WORKS') {
    if (action.meta.params) {
      // when user performs a queried search
      //  we dont store the results
      return { ...state, ...mapByRecnum(action.payload) };
    }
    return mapByRecnum(action.payload);
  } else if (action.type === 'SUCCESS_ASSOCIATE_WORK') {
    return {
      ...state,
      [action.payload.recnum]: action.payload,
    };
  } else if (
    action.type === 'FAILURE_ASSOCIATE_WORK' ||
    action.type === 'FAILURE_FETCH_WORKS' ||
    action.type === 'REQUEST_ASSOCIATE_WORK' ||
    action.type === 'REQUEST_FETCH_WORKS'
  ) {
    return state;
  }
  softAssertNever(action);
  return state;
}
