import { AnyAction } from 'redux';
import { DownloadFileCompleteAction } from './fileDownloads';
import { softAssertNever } from './utils';

interface PurgeFileAction {
  type: 'PURGE_FILE';
  payload: string;
}

export function purgeFile(checksum: string): PurgeFileAction {
  return {
    type: 'PURGE_FILE',
    payload: checksum,
  };
}

export function isPurgeFileAction(
  action: AnyAction,
): action is PurgeFileAction {
  return action.type === 'PURGE_FILE';
}

interface PurgeFilesAction {
  type: 'PURGE_FILES';
}

export function purgeFiles(checksum: string): PurgeFilesAction {
  return {
    type: 'PURGE_FILES',
  };
}
export function isPurgeFilesAction(
  action: AnyAction,
): action is PurgeFilesAction {
  return action.type === 'PURGE_FILES';
}

// XXX is this kind of strange, we respond to the action of another module
// not sure if this can be better structered.
export type FilesAction =
  | DownloadFileCompleteAction
  | PurgeFileAction
  | PurgeFilesAction;

export const actions = {
  purgeFile,
  purgeFiles,
};

// XXX: TODO clean up File* classes and choose propper names
export interface FileRef {
  downloadDate: Date | string;
}

export interface FilesState {
  [checksum: string]: FileRef | undefined;
}

export default function files(
  state: FilesState = {},
  action: FilesAction,
): FilesState {
  if (action.type === 'DOWNLOAD_FILE_COMPLETE') {
    const { checksum, timestamp } = action.payload;
    return {
      ...state,
      [checksum]: {
        downloadDate: timestamp,
      },
    };
  } else if (action.type === 'PURGE_FILE') {
    const newState = { ...state };
    delete newState[action.payload];
    return newState;
  } else if (action.type === 'PURGE_FILES') {
    return {};
  }
  softAssertNever(action);
  return state;
}
