/* eslint-disable jsx-a11y/anchor-is-valid */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  complement,
  filter,
  groupBy,
  identity,
  prop,
  propEq,
  sortBy,
} from 'ramda';
import * as React from 'react';
import { Button, ListGroup, ListGroupItem } from 'react-bootstrap';
import { connect } from 'react-redux';
import { push } from 'redux-first-history';
import {
  doesListHaveUnsavedModifications,
  getParamsFromTags,
  parametersToTags,
  tagOptions,
  templateTagOptions,
} from '../../common/logic/list';
import { fetchUnhandledFeedback } from '../../modules/feedback';
import { filter as filterAction } from '../../modules/filters';
import {
  createList,
  deleteList,
  fetchLists,
  saveList,
} from '../../modules/lists';
import InternalOnly from '../../utils/InternalOnly';
import { adminOnly } from '../../utils/auth';
import ignoreRejection from '../../utils/ignoreRejection';
import LinkContainer from '../LinkContainer';
import Page from '../Page';
import ParameterizedSearch, {
  renderParameters,
} from '../ParameterizedSearch/index';
import { parseParameters } from '../ParameterizedSearch/model';
import Spinner from '../Spinner';
import styles from './TemplatesOverview.module.scss';

const FILTER_KEY = 'TEMPLATES_FILTER';

export class TemplatesOverview extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  getDefaultSearch() {
    const { user } = this.props;
    let search = 'label:"composed"';
    if (user.department) {
      search = `department:"${user.department}" ${search}`;
    }
    return search;
  }

  handleSearchChange = value => {
    this.props.dispatch(filterAction(FILTER_KEY, value));
  };

  componentWillMount() {
    ignoreRejection(
      this.props.dispatch(fetchLists({ werk_id: null })),
      this.props.dispatch(fetchUnhandledFeedback()),
    );
  }

  createList = async () => {
    const { user } = this.props;
    const name = prompt('Geef naam voor sjabloon');
    if (name) {
      const tags = ['template', 'label:composed'];
      if (user.department) {
        tags.push(`department:${user.department}`);
      }

      const res = await this.props.dispatch(createList({ name, tags }));
      try {
        this.props.dispatch(push(`/template/${res.payload.id}`));
      } catch (error) {} //tslint:disable-line
    }
  };

  deleteList = (id, event) => {
    event.preventDefault();
    // eslint-disable-next-line no-restricted-globals
    if (confirm('Wil je dit sjabloon verwijderen?')) {
      this.props.dispatch(deleteList(id));
    }
  };

  changeListName = (recnum, event) => {
    const list = this.props.lists[recnum];
    if (doesListHaveUnsavedModifications(list)) {
      alert('Sla het sjabloon eerst op');
      return;
    }
    event.preventDefault();
    const name = prompt('Geef een nieuwe naam voor dit sjabloon', list.name);
    if (name && name !== list.name) {
      this.props.dispatch(saveList({ recnum, name }));
    }
  };

  render() {
    const { lists, feedback, filters } = this.props;
    let search = filters[FILTER_KEY];
    if (search === null || search === undefined) {
      search = this.getDefaultSearch();
    }
    const groupedFeedback = groupBy(
      prop('proto_list_id'),
      Object.values(feedback).filter(f => !f.handled),
    );
    let content;
    if (lists) {
      let templates = Object.values(lists).filter(
        l => l.tags && l.tags.includes('template') && l.deleted === false,
      );
      const { parameters, rest } = parseParameters(search);
      let filterTags = parametersToTags({
        parameters: filter(complement(propEq('name', 'feedback')), parameters),
      });

      if (filterTags.length) {
        templates = templates.filter(t =>
          filterTags.every(tag => t.tags && t.tags.includes(tag)),
        );
      }
      let feedbackParam = parameters.find(p => p.name === 'feedback');
      if (feedbackParam) {
        templates = templates.filter(
          JSON.parse(feedbackParam.value)
            ? template =>
                Object.values(feedback).some(
                  f => !f.handled && f.proto_list_id === template.id,
                )
            : template =>
                Object.values(feedback).every(
                  f => f.handled || f.proto_list_id !== template.id,
                ),
        );
      }
      if (rest) {
        const REST = rest.toUpperCase();
        templates = templates.filter(({ name }) =>
          name.toUpperCase().includes(REST),
        );
      }

      templates = sortBy(l => l.name.toLowerCase(), templates);

      if (templates.length) {
        content = (
          <ListGroup className={styles.list}>
            {templates.map(t => {
              let spanClass = '';
              let title;
              if (doesListHaveUnsavedModifications(t)) {
                spanClass = styles.unsaved;
                title = 'Dit sjabloon heeft onopeslagen wijzigingen';
              }
              const newFeedbackCount =
                (groupedFeedback[t.id] && groupedFeedback[t.id].length) || 0;
              return (
                <LinkContainer to={`/template/${t.id}`} key={t.id}>
                  <ListGroupItem title={title} className={styles.listItem}>
                    <span className={classNames(spanClass, styles.title)}>
                      {t.name}
                      &nbsp;
                      {newFeedbackCount ? (
                        <a
                          title="You've got feedback!"
                          className={`badge ${styles.newBadge}`}
                        >
                          {newFeedbackCount}
                        </a>
                      ) : (
                        undefined
                      )}
                    </span>
                    <div className={styles.right}>
                      {getParamsFromTags(
                        sortBy(identity, t.tags),
                      ).parameters.map(
                        renderParameters({ ...tagOptions, small: true }),
                      )}
                    </div>
                    <div className={styles.right}>
                      <Button
                        bsStyle="link"
                        onClick={this.deleteList.bind(this, t.id)}
                      >
                        <i className="fa fa-trash" />
                      </Button>
                    </div>
                  </ListGroupItem>
                </LinkContainer>
              );
            })}
          </ListGroup>
        );
      } else if (lists) {
        content = (
          <div className={styles.noTemplates}>
            <p>
              <small>
                <em>geen templates</em>
              </small>
            </p>
          </div>
        );
      } else {
        content = <Spinner />;
      }
    }

    return (
      <Page {...this.props} title="Sjablonen">
        <h3 className="listHeader">
          <span>Templates</span>
          <InternalOnly user={this.props.user}>
            <Button
              bsSize="small"
              bsStyle="link"
              className="pull-right"
              onClick={this.createList}
            >
              <i className="fa fa-plus" /> new template
            </Button>
          </InternalOnly>
        </h3>

        <ParameterizedSearch
          value={search}
          onChange={this.handleSearchChange}
          options={templateTagOptions}
        />
        {content}
      </Page>
    );
  }
}

export default connect(x => x)(adminOnly(TemplatesOverview));
