import * as React from 'react';
import { Breadcrumb } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Page from '../Page';
import TicketsList from '../TicketsList';
import LinkContainer from '../LinkContainer';

export default class MyTicketsComponent extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  render() {
    const { tickets, users } = this.props;
    return (
      <Page>
        <Breadcrumb>
          <LinkContainer to="/dashboard">
            <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
          </LinkContainer>
          <Breadcrumb.Item active>Tickets</Breadcrumb.Item>
        </Breadcrumb>

        <h3>
          <span>Open tickets</span>
        </h3>
        <TicketsList tickets={tickets} users={users} />
      </Page>
    );
  }
}
