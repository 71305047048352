// import * as PropTypes from 'prop-types';
import BaseField from './BaseField';

// tslint:disable

export default class BaseValueField extends BaseField {
  // static propTypes = {
  //   dispatch: PropTypes.func.isRequired,
  //   field: PropTypes.object.isRequired
  // };

  handleValueChange = (value: any) => {
    this.patch({ value });
  };

  handleCreateEvent = (event: any) => {
    if (event.type === 'MESSAGE') {
      this.addNote(event.message);
    } else if (event.type === 'GEOTAG') {
      this.addGeotag(event.geotag);
    } else if (event.type === 'ATTACHMENT') {
      this.addAttachment(event.attachment);
    }
  };

  handleEditEvent = (message: string, originalEvent: any) => {
    if (originalEvent.type === 'ADD_MESSAGE') {
      this.editNote(originalEvent._id, message);
    } else if (originalEvent.type === 'GEOTAG') {
      this.editGeotag(originalEvent._id, { label: message });
    }
  };

  handleDeleteEvent = (event: any) => {
    if (event.type === 'ADD_MESSAGE') {
      this.removeNote(event._id);
    } else if (event.type === 'ADD_GEOTAG') {
      this.removeGeotag(event._id);
    } else if (event.type === 'ADD_ATTACHMENT') {
      this.removeAttachment(event.payload.checksum);
    }
  };
}
