import React from 'react';
import InventorySearchPanel from '../components/InventorySearchPanel';
import Page from '../components/Page';
import { Column } from '../components/Page/Columns';

interface InventorySearchContainerProps {}

const InventorySearchContainer: React.FC<InventorySearchContainerProps> = ({}) => {
  const columns = [
    <Column title="Search" uriPath="/inventory/search">
      <InventorySearchPanel />
    </Column>,
  ];
  return <Page fluid title="Magazijn" columns={columns} />;
};

export default InventorySearchContainer;
