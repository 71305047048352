import md5 from 'md5';
import { AnyAction, Dispatch } from 'redux';
import { CALL_API } from 'redux-api-middleware';
import { ThunkAction } from 'redux-thunk';
import attachmentStorage from '../services/attachmentStorage';
import { resolveHeaders, resolveUrl } from '../utils';
import blobToBuffer from '../utils/blobToBuffer';

export interface Attachment {
  size: number;
  contentType: string;
  checksum: string;
  blob?: Blob;
}

export function assertValidAttachment(subject: any) {
  if (
    // typeof subject.size !== 'number' ||
    typeof subject.contentType !== 'string' ||
    typeof subject.checksum !== 'string'
  ) {
    throw new Error('not a valid attachment');
  }
  return subject as Attachment;
}

export interface AttachmentWithContent extends Attachment {
  blob: Blob;
}

interface CreateAttachmentAction {
  type: 'CREATE_ATTACHMENT';
  payload: Attachment;
}

export function createAttachment(
  blob: Blob,
): ThunkAction<Promise<any>, {}, undefined, CreateAttachmentAction> {
  return function createAttachmentThunk(dispatch: Dispatch) {
    return new Promise((resolve, reject) => {
      blobToBuffer(blob, (err, buffer) => {
        const checksum = md5(new Uint8Array(buffer as ArrayBuffer));
        const action: CreateAttachmentAction = {
          type: 'CREATE_ATTACHMENT',
          payload: assertValidAttachment({
            size: blob.size,
            contentType: blob.type,
            checksum,
            blob,
          }),
        };
        resolve(dispatch(action));
      });
    });
  };
}

export interface RequestSaveAttachmentAction {
  payload: Attachment;
  type: 'REQUEST_SAVE_ATTACHMENT';
}
export interface SuccessSaveAttachmentAction {
  payload: Attachment;
  type: 'SUCCESS_SAVE_ATTACHMENT';
}
export interface FailureSaveAttachmentAction {
  type: 'FAILURE_SAVE_ATTACHMENT';
  payload: unknown;
  meta: Attachment;
}

// type SaveAttachmentRSAA = RSAAction<
//   RequestSaveAttachmentAction,
//   SuccessSaveAttachmentAction,
//   FailureSaveAttachmentAction
// > &
//   AnyAction;

export function saveAttachment(attachment: Attachment): any {
  return async function saveAttachmentThunk(dispatch: any) {
    const blob = await attachmentStorage.getItem(attachment.checksum);
    const reader = new FileReader();
    const readerDone: Promise<ProgressEvent> = new Promise(
      (resolve, reject) => {
        reader.onload = resolve;
        reader.onerror = reject;
      },
    );
    reader.readAsArrayBuffer(blob);
    await readerDone;
    const rsaa = {
      [CALL_API]: {
        endpoint: resolveUrl(`/attachments/${attachment.checksum}`),
        method: 'POST',
        body: reader.result,
        headers: resolveHeaders({
          'content-type': attachment.contentType,
        }),
        types: [
          { type: 'REQUEST_SAVE_ATTACHMENT', payload: attachment },
          { type: 'SUCCESS_SAVE_ATTACHMENT', payload: attachment },
          { type: 'FAILURE_SAVE_ATTACHMENT', meta: attachment },
        ],
      },
    };
    return dispatch(rsaa);
  };
}

export interface RequestFetchAttachment {
  type: 'REQUEST_FETCH_ATTACHMENT';
  payload: { checksum: string };
}
export interface SuccessFetchAttachment {
  type: 'SUCCESS_FETCH_ATTACHMENT';
  payload: Attachment;
}
export interface FailureFetchAttachment {
  type: 'FAILURE_FETCH_ATTACHMENT';
  payload: { checksum: string; error: true };
}

let defaultProfile = 'medium';
export function setDefaultProfile(profile: string) {
  defaultProfile = profile;
}

export function fetchAttachment(
  checksum: string,
  profile = defaultProfile,
  punch: boolean = false,
) {
  return {
    [CALL_API]: {
      endpoint: resolveUrl(
        `/attachments/${checksum}/${profile}${punch ? '?punch=true' : ''}`,
      ),
      method: 'GET',
      headers: resolveHeaders(),
      types: [
        {
          type: 'REQUEST_FETCH_ATTACHMENT',
          payload: { checksum },
        },
        {
          type: 'SUCCESS_FETCH_ATTACHMENT',
          payload: async (action: AnyAction, state: any, res: Response) =>
            assertValidAttachment({
              checksum,
              size: res.headers.get('content-length'),
              contentType: res.headers.get('content-type'),
              blob: await res.blob(),
            }),
        },
        {
          type: 'FAILURE_FETCH_ATTACHMENT',
          payload: async (action: any, state: any, res: Response) => ({
            ...res,
            checksum,
          }),
        },
      ],
    },
  };
}

type AttachmentAction =
  | CreateAttachmentAction
  | RequestFetchAttachment
  | SuccessFetchAttachment
  | FailureFetchAttachment
  | RequestSaveAttachmentAction
  | SuccessSaveAttachmentAction
  | FailureSaveAttachmentAction;

/**
 * StoreAttachment has some additional properties to facilitate sync
 */
interface StoreAttachment extends Attachment {
  _persist: boolean;
  _persisting: boolean;
}

export interface AttachmentsState {
  [checksum: string]: StoreAttachment | undefined;
}

// we dont store the blob in redux-store, we use attachmentStorage for this
const omitBlob = (att: Attachment): Attachment => {
  const newAtt = { ...att };
  delete newAtt.blob;
  return newAtt;
};

const resetPersistingInfo = (att: Attachment): StoreAttachment => ({
  ...att,
  _persist: false,
  _persisting: false,
});

export default function attachments(
  state: AttachmentsState = {},
  action: AttachmentAction,
): AttachmentsState {
  if (action.type === 'CREATE_ATTACHMENT') {
    const { checksum } = action.payload;

    // no need to change anything if we already have this attachment
    if (state[checksum]) {
      return state;
    }

    return {
      ...state,
      [checksum]: {
        ...omitBlob(action.payload),
        _persist: true,
        _persisting: false,
      },
    };
  } else if (action.type === 'REQUEST_SAVE_ATTACHMENT') {
    const { checksum } = action.payload;
    const attachment = state[checksum];
    if (!attachment) {
      throw new Error('Request save on non existing attachment');
    }
    return {
      ...state,
      [checksum]: {
        ...attachment,
        _persisting: true,
      },
    };
  } else if (action.type === 'SUCCESS_SAVE_ATTACHMENT') {
    const { checksum } = action.payload;
    const attachment = state[checksum];
    if (!attachment) {
      throw new Error('Request save on non existing attachment');
    }
    return {
      ...state,
      [checksum]: resetPersistingInfo(attachment),
    };
  } else if (action.type === 'FAILURE_SAVE_ATTACHMENT') {
    const { checksum } = action.meta;
    const attachment = state[checksum];
    if (!attachment) {
      throw new Error('Request save on non existing attachment');
    }
    return {
      ...state,
      [checksum]: {
        ...attachment,
        _persisting: false,
      },
    };
  } else if (action.type === 'SUCCESS_FETCH_ATTACHMENT') {
    const { checksum } = action.payload;
    return {
      ...state,
      [checksum]: resetPersistingInfo(omitBlob(action.payload)),
    };
  } else if (
    action.type === 'FAILURE_FETCH_ATTACHMENT' ||
    action.type === 'REQUEST_FETCH_ATTACHMENT'
  ) {
    return state;
  }

  // softAssertNever(action);
  return state;
}
