import React, { MouseEvent, ReactElement } from 'react';
import { useLocation, useRouter } from 'wouter';

const isModifiedEvent = (event: MouseEvent) =>
  !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);

interface LinkContainerProps
  extends React.BaseHTMLAttributes<HTMLAnchorElement> {
  to: string;
  children: ReactElement;
  replace?: boolean;
}
const LinkContainer: React.FC<LinkContainerProps> = ({
  to,
  children,
  replace = false,
  className,
  href: incomingHref,
  ...props
}) => {
  const { matcher } = useRouter();
  const [isActive] = matcher(to, window.location.pathname);
  const [, navigate] = useLocation();
  const href = incomingHref || to;

  const handleClick = (event: MouseEvent) => {
    if (children.props.onClick) {
      children.props.onClick(event);
    }

    if (
      !event.defaultPrevented && // onClick prevented default
      event.button === 0 && // ignore right clicks
      !isModifiedEvent(event) // ignore clicks with modifier keys
    ) {
      event.preventDefault();

      navigate(to, {
        replace,
      });
    }
  };

  return React.cloneElement(children, {
    ...props,
    className: [className, children.props.className, isActive ? 'active' : null]
      .join(' ')
      .trim(),
    href,
    onClick: handleClick,
  });
};

export default LinkContainer;
