import * as React from 'react';
import { Breadcrumb as BSBreadcrumb, BreadcrumbItem } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { ReactElement } from 'react-markdown/lib/react-markdown';
import { Catalog } from '../../models/Catalog';
import { Project } from '../../models/Project';
import { StoreTicket } from '../../modules/tickets';
import { Work } from '../../modules/works';
import LinkContainer from '../LinkContainer';
import msg from './msg';

export const ProjectsOverviewBreadcrumb: React.FC = () => (
  <BSBreadcrumb>
    <BreadcrumbItem active>
      <FormattedMessage {...msg.BREADCRUM_PROJECTS} />
    </BreadcrumbItem>
  </BSBreadcrumb>
);

const PROJECTS_PATH = '/projects';

export const ProjectDetailsBreadcrumbs: React.FC<{
  name: React.ReactNode;
}> = ({ name }) => (
  <BSBreadcrumb>
    <LinkContainer to={PROJECTS_PATH}>
      <BreadcrumbItem href={PROJECTS_PATH}>
        <FormattedMessage {...msg.BREADCRUM_PROJECTS} />
      </BreadcrumbItem>
    </LinkContainer>
    <BreadcrumbItem active>{name}</BreadcrumbItem>
  </BSBreadcrumb>
);

export const ProjectDetailsChildBreadcrumbs: React.FC<{
  name: React.ReactNode;
  projectName: React.ReactNode;
  projectId: number;
}> = ({ name, projectName, projectId }) => (
  <BSBreadcrumb>
    <LinkContainer to={PROJECTS_PATH}>
      <BreadcrumbItem href={PROJECTS_PATH}>
        <FormattedMessage {...msg.BREADCRUM_PROJECTS} />
      </BreadcrumbItem>
    </LinkContainer>
    <LinkContainer to={projectPath(projectId)}>
      <BreadcrumbItem href={projectPath(projectId)}>
        {projectName}
      </BreadcrumbItem>
    </LinkContainer>
    <BreadcrumbItem active>{name}</BreadcrumbItem>
  </BSBreadcrumb>
);

export const ProjectCatalogDetailsBreadcrumbs: React.FC<{
  projectName: React.ReactNode;
  projectId: number;
}> = ({ projectName, projectId }) => (
  <BSBreadcrumb>
    <LinkContainer to={PROJECTS_PATH}>
      <BreadcrumbItem href={PROJECTS_PATH}>
        <FormattedMessage {...msg.BREADCRUM_PROJECTS} />
      </BreadcrumbItem>
    </LinkContainer>
    <LinkContainer to={projectPath(projectId)}>
      <BreadcrumbItem href={projectPath(projectId)}>
        {projectName}
      </BreadcrumbItem>
    </LinkContainer>
    <BreadcrumbItem active>
      <FormattedMessage {...msg.BREADCRUM_DOCS_CATALOG} />
    </BreadcrumbItem>
  </BSBreadcrumb>
);

const projectPath = (projectId: number) => `/project/${projectId}`;
const workPath = (workId: number) => `/works/${workId}`;
const ticketPath = (ticketId: string) => `/tickets/${ticketId}`;
const catalogPath = (projectId: number) => `/project/${projectId}/docs`;

const WORKS_PATH = '/works';
export const WorkDetailsBreadcrumbs: React.FC<{
  name: string;
  projectId: number;
  projectName: React.ReactNode;
}> = ({ name, projectId, projectName }) =>
  projectId && projectName ? (
    <BSBreadcrumb>
      <LinkContainer to={PROJECTS_PATH}>
        <BreadcrumbItem href={PROJECTS_PATH}>
          <FormattedMessage {...msg.BREADCRUM_PROJECTS} />
        </BreadcrumbItem>
      </LinkContainer>
      <LinkContainer to={projectPath(projectId)}>
        <BreadcrumbItem href={projectPath(projectId)}>
          {projectName}
        </BreadcrumbItem>
      </LinkContainer>
      <BreadcrumbItem active>{name}</BreadcrumbItem>
    </BSBreadcrumb>
  ) : (
    <BSBreadcrumb>
      <LinkContainer to={WORKS_PATH}>
        <BreadcrumbItem href={WORKS_PATH}>
          <FormattedMessage {...msg.BREADCRUM_LINK_CONTAINER_WORKS} />
        </BreadcrumbItem>
      </LinkContainer>
      <BreadcrumbItem active>{name}</BreadcrumbItem>
    </BSBreadcrumb>
  );

export const WorkDetailsChildBreadcrumb: React.FC<{
  projectId: number;
  projectName: React.ReactNode;
  workId: number;
  workName: React.ReactNode;
  name: React.ReactNode;
}> = ({ projectId, projectName, workId, workName, name }) => {
  return projectId && projectName ? (
    workId && workName ? (
      <BSBreadcrumb>
        <LinkContainer to={PROJECTS_PATH}>
          <BreadcrumbItem href={PROJECTS_PATH}>
            <FormattedMessage {...msg.BREADCRUM_PROJECTS} />
          </BreadcrumbItem>
        </LinkContainer>
        <LinkContainer to={projectPath(projectId)}>
          <BreadcrumbItem href={projectPath(projectId)}>
            {projectName}
          </BreadcrumbItem>
        </LinkContainer>
        <LinkContainer to={workPath(workId)}>
          <BreadcrumbItem href={workPath(workId)}>{workName}</BreadcrumbItem>
        </LinkContainer>
        <BreadcrumbItem active>{name}</BreadcrumbItem>
      </BSBreadcrumb>
    ) : (
      <BSBreadcrumb>
        <LinkContainer to={PROJECTS_PATH}>
          <BreadcrumbItem href={PROJECTS_PATH}>
            <FormattedMessage {...msg.BREADCRUM_PROJECTS} />
          </BreadcrumbItem>
        </LinkContainer>
        <LinkContainer to={projectPath(projectId)}>
          <BreadcrumbItem href={projectPath(projectId)}>
            {projectName}
          </BreadcrumbItem>
        </LinkContainer>
        <BreadcrumbItem active>{name}</BreadcrumbItem>
      </BSBreadcrumb>
    )
  ) : (
    <BSBreadcrumb>
      <LinkContainer to={WORKS_PATH}>
        <BreadcrumbItem href={WORKS_PATH}>
          <FormattedMessage {...msg.BREADCRUM_LINK_CONTAINER_WORKS} />
        </BreadcrumbItem>
      </LinkContainer>
      <LinkContainer to={workPath(workId)}>
        <BreadcrumbItem href={workPath(workId)}>{workName}</BreadcrumbItem>
      </LinkContainer>
      <BreadcrumbItem active>{name}</BreadcrumbItem>
    </BSBreadcrumb>
  );
};

const ProjectName: React.FC<{ project: Project }> = ({ project }) => (
  <>
    {project.projectname} {project.id && <em>- {project.id}</em>}
  </>
);

const WorkName: React.FC<{ work: Work }> = ({ work }) => (
  <>
    {work.id} - {work.klant}
  </>
);

const makeLastBreadcrumbActive = (
  items: (null | undefined | React.ReactElement)[],
): ReactElement[] =>
  items
    .filter((x => x) as (a: any) => a is React.ReactElement)
    .map((elem, idx) =>
      idx !== items.length - 1
        ? elem
        : React.cloneElement(elem, {
            children: React.cloneElement(elem.props.children, { active: true }),
          }),
    );

export const Breadcrumb: React.FC<{
  project?: Project | null;
  work?: Work | null;
  ticket?: StoreTicket | null;
  catalog?: Catalog | null;
}> = ({ project, work, ticket, catalog }) => (
  <BSBreadcrumb>
    {makeLastBreadcrumbActive([
      <LinkContainer key="ps" to={PROJECTS_PATH}>
        <BreadcrumbItem href={PROJECTS_PATH}>
          <FormattedMessage {...msg.BREADCRUM_PROJECTS} />
        </BreadcrumbItem>
      </LinkContainer>,
      project && (
        <LinkContainer key="p" to={projectPath(project.recnum)}>
          <BreadcrumbItem href={projectPath(project.recnum)}>
            <ProjectName project={project} />
          </BreadcrumbItem>
        </LinkContainer>
      ),
      work && (
        <LinkContainer key="w" to={workPath(work.recnum)}>
          <BreadcrumbItem href={workPath(work.recnum)}>
            <WorkName work={work} />
          </BreadcrumbItem>
        </LinkContainer>
      ),
      ticket && (
        <LinkContainer key="t" to={ticketPath(ticket.id)}>
          <BreadcrumbItem href={ticketPath(ticket.id)}>
            {ticket.title}
          </BreadcrumbItem>
        </LinkContainer>
      ),
      catalog && (
        <LinkContainer key="c" to={catalogPath(catalog.project_id)}>
          <BreadcrumbItem href={catalogPath(catalog.project_id)}>
            Docs
          </BreadcrumbItem>
        </LinkContainer>
      ),
    ])}
  </BSBreadcrumb>
);
