import classNames from 'classnames';
import partition from 'lodash/fp/partition';
import take from 'lodash/fp/take';
import * as React from 'react';
import StatusBadge from '../StatusBadge';
import stl from './Minutes.module.scss';

export interface TicketReferenceCreatorProps {
  query: null | string;
  tickets: any;
  onSelectTicket(t: any): void;
  onCreateTicket(partialTicket: any): void;
}

export default class TicketReferenceCreator extends React.Component<
  TicketReferenceCreatorProps,
  any
> {
  handleCreateTicket = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    if (!this.props.query?.trim()) {
      throw new Error('Cant create ticket without title');
    }
    this.props.onCreateTicket({ title: this.props.query?.trim() });
  };

  handleClick = (ticket: any) => {
    // tslint:disable-next-line: no-console
    this.props.onSelectTicket(ticket);
  };

  // TODO: optimize
  render() {
    const { query } = this.props;
    let selection: any[] = this.props.tickets;
    if (query) {
      let filter: (t: any) => boolean = (t: { title: string }) =>
        t.title.toLowerCase().includes(query.toLowerCase());
      const num = parseInt(query, 10);
      if (num) {
        filter = (t: { number: number }) =>
          t.number.toString().substr(0, query.length) === query;
      }
      selection = selection.filter(filter);
    }
    selection = take(8, selection);

    const [open, closed] = partition(
      ({ status }) => status === 'opened',
      selection,
    ).map((arr: any[]) =>
      arr.map((ticket: any) => (
        <li
          key={ticket.id}
          className={classNames('dropdown-item', stl.ticketItem)}
        >
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a role="button" onClick={this.handleClick.bind(this, ticket)}>
            <StatusBadge size="small" status={ticket.status} />{' '}
            <span>#{ticket.number}</span> {ticket.title}
          </a>
        </li>
      )),
    );

    const create = query?.trim() ? (
      <li
        key={'create'}
        className={classNames('dropdown-item', stl.ticketItem)}
      >
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a role="button" onClick={this.handleCreateTicket}>
          <StatusBadge
            size="small"
            status={'opened'}
            className={stl.washedStatus}
          />{' '}
          {query}
        </a>
      </li>
    ) : null;

    return (
      <ul className="dropdown-menu">
        {create ? (
          <>
            <li className="dropdown-header">Create new issue</li>
            {create}
          </>
        ) : null}
        {!!open.length && <li className="dropdown-header">Open</li>}
        {open}
        {!!closed.length && <li className="dropdown-header">Closed</li>}
        {closed}
      </ul>
    );
  }
}
