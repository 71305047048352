import { curriedMatchUserToTerm } from '../../common/user/matchUserToTerm';
import { loginnameValidator } from '../../models/Account';
import { LabelsState } from '../../modules/labels';
import { StoreTicket } from '../../modules/tickets';
import { UsersState } from '../../modules/users';
import { CommandLex, Lex } from '../../utils/LexicalParsing';

export function constructTicketLexes(
  users: UsersState,
  labels: LabelsState,
  tickets: Record<number, StoreTicket>,
) {
  const [user, ticket, createTicket] = constructReferenceLexes(users, tickets);

  const date: Lex = {
    id: 'date',
    match: /((?<year>\d{4})-)?(?<month>\d{1,2})-(?<day>\d{1,2})/,
  };

  const period: Lex = {
    id: 'period',
    match: /(?<plus>\+)?(?<number>\d+)(?<unit>(d|w|m|y))/,
  };

  const text: Lex = {
    id: 'text',
    match: /(?<text>[^\n]*)\n/,
  };

  const labelsOrNone: Lex = {
    id: 'labelsOrNone',
    match: /(~(["']?)(?<name>[^'"]+)\2 ?)?/,
    next: [],
    getCompletions(text: string) {
      if (!labels) {
        return [];
      }
      return Object.values(labels)
        .filter(l =>
          l.name.toLowerCase().includes(
            text
              .trim()
              .toLowerCase()
              .replace(/[~'"]/g, ''),
          ),
        )
        .map(data => ({ text: `~'${data.name}' `, data }));
    },
  };
  labelsOrNone.next!.push(labelsOrNone);

  const labelsLex: Lex = {
    id: 'labels',
    match: /~(["']?)(?<name>[^'"]+)\1 ?/,
    next: [labelsOrNone],
    getCompletions: labelsOrNone.getCompletions,
  };

  const commands: Lex[] = [
    new CommandLex('assign', 'Assign a user', '@user', [user]),
    new CommandLex('unassign', 'Remove assigned user'),
    new CommandLex('executor', 'Assign a executor', '@user', [user]),
    new CommandLex('unexecutor', 'Remove executor'),
    new CommandLex('title', 'Change title', 'New title', [text]),
    new CommandLex('open', 'Reopen ticket'),
    new CommandLex('close', 'Close ticket'),
    new CommandLex('due', 'Change due-date', '1w|5-22', [date, period]),
    new CommandLex('label', 'Assign labels', '~Urgent', [labelsLex]),
    new CommandLex('unlabel', 'Remove labels', undefined, [labelsLex]),
  ];

  return [...commands, user, createTicket, ticket];
}
export function constructReferenceLexes(
  users: UsersState,
  tickets: Record<number, StoreTicket>,
) {
  const user: Lex = {
    id: 'user',
    match: /@(?<loginname>[a-z0-9][a-z0-9_.]+[a-z0-9]+)/,
    incompleteMatch: /@([a-z0-9]([a-z0-9_.]+([a-z0-9]+)?)?)?/,
    activate: /@([a-z0-9][a-z0-9_.]+[a-z0-9]+)?/,
    getCompletions(text: string) {
      if (!users) {
        return [];
      }
      return Object.values(users)
        .filter(u => loginnameValidator.test(u.loginname))
        .filter(curriedMatchUserToTerm(text.trim().replace('@', '')))
        .map(data => ({ text: '@' + data.loginname, data }));
    },
  };

  const ticket: Lex = {
    id: 'ticket',
    match: /#((?<number>\d+)|(?<id>[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}))(\+)?/,
    incompleteMatch: /#[\w\d ]*/,
    activate: /#/,
    getCompletions(_text: string) {
      const text = _text.replace('#', '');
      return Object.values(tickets)
        .filter(
          t =>
            //fixme: stupid _persist: {version: -1, rehydrated: true} prop poluting the ticketstore
            t.number?.toString(10).startsWith(text) ||
            t.title?.toLowerCase().includes(text.toLowerCase()),
        )
        .map(data => ({ text: `#${data.number || data.id}+`, data }));
    },
  };

  const createTicket: Lex = {
    id: 'create_ticket',
    match: /#(['"])(?<title>[^\n\t]+)\1/,
    incompleteMatch: /#([^\s\n#]?[^\n#]*)?/,
    activate: /#([^\s\n#]?[^\n#]*)?/,
    getCompletions(text: string) {
      return [{ text: `#'${text.replace(/^#/, '')}'` }];
    },
  };
  return [user, createTicket, ticket];
}
