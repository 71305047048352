export default function blobToBuffer(
  blob: Blob,
  cb: (e: Error | undefined, b: ArrayBuffer | undefined) => any
) {
  // cypress injects a different Blob instance so this is breaks type check
  // breaks the tests
  // hence it's disabled
  // if (typeof Blob === 'undefined' || !(blob instanceof Blob)) {
  //   throw new Error('first argument must be a Blob');
  // }
  if (typeof cb !== 'function') {
    throw new Error('second argument must be a function');
  }

  const reader = new FileReader();

  function onLoadEnd(e: any) {
    reader.removeEventListener('loadend', onLoadEnd, false);
    if (e.error) {
      cb(e.error, undefined);
    } else {
      cb(undefined, reader.result as ArrayBuffer);
    }
  }

  reader.addEventListener('loadend', onLoadEnd, false);
  reader.readAsArrayBuffer(blob);
}
