import * as React from 'react';
import { ComponentType } from 'react';

export const withStaticProps = <SP extends Exclude<{}, keyof React.Attributes>>(
  staticProps: SP,
) => <EP extends SP>(WrappedComponent: ComponentType<EP>) => (
  externalProps: EP,
) => <WrappedComponent {...staticProps} {...externalProps} />;

export const flag = <
  K extends string,
  P extends {
    [key in K]?: boolean;
  }
>(
  flagKey: K,
  WrappedComponent: ComponentType<P>,
) => (props: Omit<P, K>) => (
  <WrappedComponent {...{ [flagKey]: true }} {...(props as any)} />
);

export const withKey = <Props extends {}>(
  key: keyof Props | ((props: Props) => string),
  Component: React.ComponentType<Props>,
) => (props: Props) => (
  <Component
    {...(props as any)}
    key={typeof key === 'function' ? key(props) : props[key]}
  />
);
