import { User } from '../../models/User';

const reg = /^[A-Z]/i;
const sortUsers = [
  function sortUsers(a: User, b: User) {
    if (reg.test(a.name || a.loginname) && !reg.test(b.name || b.loginname)) {
      return -1;
    } else if (
      !reg.test(a.name || a.loginname) &&
      reg.test(b.name || b.loginname)
    ) {
      return 1;
    }
    return 0;
  },
  function sortUsers(a: User, b: User) {
    return (a.name || a.loginname).localeCompare(
      b.name || b.loginname,
      undefined,
      { sensitivity: 'base' },
    );
  },
];
export default sortUsers;
