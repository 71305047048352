import classNames from 'classnames';
import * as React from 'react';
import { ListGroupItem } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { CatalogStructureGroup } from '../../../models/Catalog';
import { getCatalogNodeName } from '../catalog';
import stl from './CatalogContents.module.scss';

const CatalogGroupComponent: React.FC<{
  group: CatalogStructureGroup;
  depth?: number;
  closed: boolean;
  onToggleClosed: () => void;
}> = ({ group, depth = 0, closed, onToggleClosed }) => {
  const { locale } = useIntl();

  return (
    <ListGroupItem
      className={classNames(
        stl.group,
        stl[`depth_${depth}`],
        closed && stl.closed,
        group.textSearchMatch && stl.match,
      )}
      onClick={onToggleClosed}
    >
      <div className={stl.primary}>
        <span className={stl.icon} />
        <span className={stl.title}>
          {getCatalogNodeName(group, locale as any)}
        </span>
      </div>
    </ListGroupItem>
  );
};

export default CatalogGroupComponent;
