import { defineMessages } from 'react-intl';

export default defineMessages({
  settings: {
    id: 'SETTINGS.TITLE',
    defaultMessage: 'Settings',
    description: 'Title for settings page',
  },
  info: {
    id: 'SETTINGS.INFO',
    defaultMessage: 'Personal info',
    description: 'Title for personal info section on settings page',
  },
  general: {
    id: 'SETTINGS.GENERAL',
    defaultMessage: 'General',
    description: 'Title for general section on settings page',
  },
  departmentLabel: {
    id: 'SETTINGS.DEPARTMENT_LABEL',
    defaultMessage: 'Department',
    description: 'Label for department input on settings page',
  },
  nameLabel: {
    id: 'SETTINGS.NAME_LABEL',
    defaultMessage: 'Full Name',
    description: 'Label for name input on settings page',
  },
  invalidName: {
    id: 'SETTINGS.INVALID_NAME',
    defaultMessage:
      'Name should have less than 30 characters and no line endings',
    description:
      'Label indicating that the provided email address is incorrect',
  },
  emailLabel: {
    id: 'SETTINGS.EMAIL_LABEL',
    defaultMessage: 'Email address',
    description: 'Label for email input on settings page',
  },
  invalidEmail: {
    id: 'SETTINGS.INVALID_EMAIL',
    defaultMessage: 'Provide a valid email address',
    description:
      'Label indicating that the provided email address is incorrect',
  },
  changePasswordTitle: {
    id: 'SETTINGS.CHANGE_PASSWORD_TITLE',
    defaultMessage: 'Change password',
    description: 'Title of change password section on settings page',
  },
  passwordStrengthTitle: {
    id: 'SETTINGS.PASSWORD_STRENTGH_TITLE',
    defaultMessage: 'Password strength',
    description: 'Title of password strenght estimate alert on settings page',
  },
  weakPassword: {
    id: 'SETTINGS.WEAK_PASSWORD',
    defaultMessage: 'Please choose a stronger password',
    description:
      'Message explaining that the chosen password is not strong enough',
  },
  newPasswordLabel: {
    id: 'SETTINGS.NEW_PASSWORD_LABEL',
    defaultMessage: 'New password',
    description: 'Label for new password fields',
  },
  newPasswordPlaceholder: {
    id: 'SETTINGS.NEW_PASSWORD_PLACEHOLDER',
    defaultMessage: 'New password',
    description: 'Placeholder for new password field on settings page',
  },
  unmatchingPassword: {
    id: 'SETTINGS.UNMATCHING_PASSWORD',
    defaultMessage: 'Your repeated password does not match',
    description:
      'Message explaining that the repeated password does not match ',
  },
  repeatNewPaswwordPlaceholder: {
    id: 'SETTINGS.REPEAT_NEW_PASWWORD_PLACEHOLDER',
    defaultMessage: 'Repeat new password',
    description: 'Placeholder for repeat new password field on settings page',
  },
  notificationsTitle: {
    id: 'SETTINGS.NOTIFICATION_TAB_TITLE',
    defaultMessage: 'Notifications',
    description: 'Title for notifications tab',
  },
  ticketNotificationsEnabled: {
    id: 'SETTINGS.TICKET_NOTIFICATIONS_ENABLED',
    defaultMessage: 'Enable ticket notifications',
    description: 'Label for the ticket_notifications_enabled setting',
  },
  meetingsEnabled: {
    id: 'SETTINGS.MEETINGS_ENABLED',
    defaultMessage: 'Enable meetings',
    description: 'Label for the meetings_enabled setting',
  },
  saveButtonLabel: {
    id: 'SETTINGS.SAVE_BUTTON_LABEL',
    defaultMessage: 'Save settings',
    description: 'Label for save button on settings page',
  },
});
