import { isBefore, parseISO } from 'date-fns';
import { filter, findLast, last, map, pipe, unnest } from 'ramda';
import { BuiltList, List } from '../../models/List';
import { CheckpointField, Field, NumericField, TaskField } from '../list';
import { parseDate } from './misc';

/**
 * Check if a list is closed
 * @param  {[type]}  list
 * @return {false|Date} false when open or a Date of the time of closing
 */
export function isListClosed(list: List) {
  const lastStatusChange = findLast(
    mod => mod.type === 'OPEN_LIST' || mod.type === 'CLOSE_LIST',
    list.events,
  );
  if (!lastStatusChange || lastStatusChange.type === 'OPEN_LIST') {
    return false;
  }
  return new Date(lastStatusChange.created);
}

// TODO: propper typing
export function doesListHaveUnsavedModifications(list: List) {
  return list._modified && list._persist !== last(list.events)?.id;
}

// TODO: propper typing
export function doesListHaveUnsyncedModifications(list: List) {
  return !!list._persist;
}

export function isListComplete(builtList: BuiltList): boolean {
  return !Object.values(builtList.index).some(node => {
    if (!node) {
      return false;
    }
    if (
      node.type === 'CHECKPOINT_FIELD' ||
      node.type === 'NUMERIC_FIELD' ||
      node.type === 'TASK_FIELD'
    ) {
      return node.value === null || node.value === undefined;
    }
    return false;
  });
}

export function traverse(builtList: BuiltList): Field[] {
  function iterate(node: Field): Field[] {
    if (node.type === 'GROUP_FIELD' || node.type === 'ROOT_FIELD') {
      return [node, ...unnest(node.children.map(iterate))];
    }
    return [node];
  }
  return iterate(builtList.tree);
}

/**
 * Calculate progress of a list based on ansered questions and completed tasks
 * @param builtList
 * @return {number} 0-1
 */
export function calculateProgress(builtList: BuiltList): number {
  const cps = traverse(builtList).filter(
    f =>
      f.type === 'CHECKPOINT_FIELD' ||
      f.type === 'TASK_FIELD' ||
      f.type === 'NUMERIC_FIELD',
  ) as Array<TaskField | CheckpointField | NumericField>;
  const completeCps = cps.filter(f => {
    if (f.type !== 'TASK_FIELD') {
      return f.value !== undefined && f.value !== null;
    }
    return f.value === true;
  });
  return completeCps.length / cps.length || 0;
}

export const tagOptions = {
  parameters: {
    label: {
      icon: 'tag',
      options: ['partial', 'composed'],
    },
    department: {
      name: 'department',
      icon: 'university',
      options: [
        'climate',
        'electrical',
        'greenhouse',
        'irrigation',
        'service',
        'climate-cells',
      ],
    },
  },
};
export const templateTagOptions = {
  parameters: {
    ...tagOptions.parameters,
    feedback: {
      icon: 'bullhorn',
      options: ['true', 'false'],
    },
  },
};

export const userListTagRegex = /(department|label):(.*$)/;

// FIX: type is not strictly compatible with ParameterizedSearch
export const getParamsFromTags = pipe<
  [string[]],
  string[],
  { name: string; value: string }[],
  { parameters: { name: string; value: string }[] }
>(
  filter((tag: any) => userListTagRegex.test(tag)) as any,
  map((tag: any) => {
    const result = userListTagRegex.exec(tag);
    if (!result) {
      throw new Error('Could not parse List-tags');
    }
    const [, name, value] = result;
    return { name, value };
  }),
  parameters => ({ parameters }),
);

// TODO: propper types
export const parametersToTags = pipe(
  (o: any) => o.parameters,
  map(({ name, value }) => `${name}:${value}`),
);

export function isListOverdue(list: List) {
  if (!list.end_date) {
    return false;
  }
  const d = parseDate(list.end_date);
  return d < new Date();
}

export function listWasClosedDuringPeriod(
  period_start: Date,
  period_end: Date,
  l: List,
) {
  const lastStatusChange = findLast(mod => {
    const ts = parseISO(mod.created.toString());
    return (
      (mod.type === 'OPEN_LIST' || mod.type === 'CLOSE_LIST') &&
      isBefore(period_start, ts) &&
      isBefore(ts, period_end)
    );
  }, l.events);
  return lastStatusChange?.type === 'CLOSE_LIST';
}

export function listWasOpenedDuringPeriod(
  period_start: Date,
  period_end: Date,
  l: List,
) {
  const lastStatusChange = findLast(mod => {
    const ts = parseISO(mod.created.toString());
    return (
      (mod.type === 'OPEN_LIST' || mod.type === 'CLOSE_LIST') &&
      isBefore(period_start, ts) &&
      isBefore(ts, period_end)
    );
  }, l.events);
  const created = parseISO(l.created.toString());
  return (
    lastStatusChange?.type === 'CLOSE_LIST' ||
    (isBefore(period_start, created) && isBefore(created, period_end))
  );
}
