import classNames from 'classnames';
import * as React from 'react';
import { Button, Checkbox, HelpBlock, Modal } from 'react-bootstrap';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import Types from 'Types';
import { OnlineState } from '../../modules/online';

import {
  allSyncStages,
  Stage,
  SyncStage,
  SyncStatus,
  SyncStatusState,
} from '../../modules/syncStatus';
import { setSyncCatalogs, UIState } from '../../modules/ui';
import { useSyncService } from '../../services/ServiceProvider';
import FormattedRelative from '../FormattedRelative';
import ProgressBullet from '../ProgressBullet';
import styles from './SyncStatusDialog.module.scss';

const msg = defineMessages({
  POST_ATTACHMENTS: {
    id: 'SYNC_STAGE.POST_ATTACHMENTS',
    defaultMessage: 'Uploading attachments',
  },

  POST_LISTS: {
    id: 'SYNC_STAGE.POST_LISTS',
    defaultMessage: 'Uploading lists',
  },

  POST_TICKETS: {
    id: 'SYNC_STAGE.POST_TICKETS',
    defaultMessage: 'Uploading tickets',
  },

  POST_FEEDBACK: {
    id: 'SYNC_STAGE.POST_FEEDBACK',
    defaultMessage: 'Uploading feedback',
  },

  POST_MINUTES: {
    id: 'SYNC_STAGE.POST_MINUTES',
    defaultMessage: 'Uploading meetings',
  },

  GET_ME: {
    id: 'SYNC_STAGE.GET_ME',
    defaultMessage: 'Getting user profile',
  },

  GET_LABELS: {
    id: 'SYNC_STAGE.GET_LABELS',
    defaultMessage: 'Getting labels',
  },

  GET_USERS: {
    id: 'SYNC_STAGE.GET_USERS',
    defaultMessage: 'Getting users',
  },

  GET_WORKS: {
    id: 'SYNC_STAGE.GET_WORKS',
    defaultMessage: 'Getting work items',
  },

  GET_PROJECTS: {
    id: 'SYNC_STAGE.GET_PROJECTS',
    defaultMessage: 'Getting projects',
  },

  GET_TICKETS: {
    id: 'SYNC_STAGE.GET_TICKETS',
    defaultMessage: 'Getting tickets',
  },

  GET_LISTS: {
    id: 'SYNC_STAGE.GET_LISTS',
    defaultMessage: 'Getting lists',
  },

  GET_CATALOGS: {
    id: 'SYNC_STAGE.GET_CATALOGS',
    defaultMessage: 'Getting documents',
  },

  title: {
    id: 'APP_STATUS_DIALOG.TITLE',
    defaultMessage: 'App status',
  },

  onlineTitle: {
    id: 'APP_STATUS_DIALOG.ONLINE_TITLE',
    defaultMessage: '{icon} Online',
  },

  onlineDescriptionOnline: {
    id: 'APP_STATUS_DIALOG.ONLINE_DESCRIPTION_ONLINE',
    defaultMessage: 'The backend is currently reachable',
  },

  onlineDescriptionOffline: {
    id: 'APP_STATUS_DIALOG.ONLINE_DESCRIPTION_OFFLINE',
    defaultMessage: 'The backend is currently not reachable',
  },

  syncNow: {
    id: 'SYNC_DIALOG.SYNC_NOW',
    defaultMessage: 'Sync now',
  },

  syncTitle: {
    id: 'APP_STATUS_DIALOG.SYNC_TITLE',
    defaultMessage: '{icon} Sync',
  },

  syncDescriptionAllContentSynced: {
    id: 'APP_STATUS_DIALOG.SYNC_DESCRIPTION_ALL_CONTENT_SYNCED',
    defaultMessage: 'All local content has been synced to the backend.',
  },

  syncDescriptionNotAllContentSynced: {
    id: 'APP_STATUS_DIALOG.SYNC_DESCRIPTION_NOT_ALL_CONTENT_SYNCED',
    defaultMessage: 'Not all local content has been synced to the backend',
  },

  syncDescriptionLastSync: {
    id: 'APP_STATUS_DIALOG.SYNC_DESCRIPTION_LAST_SYNC',
    defaultMessage: 'The last succesfull, full sync was {relTime}.',
  },

  syncDescriptionNotSynced: {
    id: 'APP_STATUS_DIALOG.SYNC_DESCRIPTION_NOT_SYNCED',
    defaultMessage: 'There hase not yet been a full sync',
  },

  syncDescriptionLastAttemptFailed: {
    id: 'APP_STATUS_DIALOG.SYNC_DESCRIPTION_LAST_ATTEMPT_FAILED',
    defaultMessage: 'The last attempt has failed {relTime}',
  },

  lastAttemptTitle: {
    id: 'APP_STATUS_DIALOG.LAST_ATTEMPT_TITLE',
    defaultMessage: 'Last attempt results:',
  },

  dismiss: {
    id: 'SYNC_DIALOG.DISMISS',
    defaultMessage: 'Dismiss',
  },
});

const SyncStageComponent: React.FC<Stage> = ({
  stage,
  status,
  tasksTotal,
  tasksProgress,
}) => {
  const intl = useIntl();

  let bullet = null;
  let bulletProps = { size: 16, subtle: true };
  if (status === SyncStatus.STATUS_NONE) {
    bullet = <ProgressBullet {...bulletProps} dashed />;
  } else if (status === SyncStatus.STATUS_BUSY) {
    const progress = (tasksProgress || 0) / (tasksTotal || 0);
    bullet = <ProgressBullet {...bulletProps} progress={progress} />;
  } else if (status === SyncStatus.STATUS_INITIALIZING) {
    bullet = <ProgressBullet {...bulletProps} />;
  } else if (status === SyncStatus.STATUS_DONE) {
    bullet = <ProgressBullet {...bulletProps} complete progress={1} />;
  } else if (status === SyncStatus.STATUS_FAILED) {
    const progress = (tasksProgress || 0) / (tasksTotal || 0);
    bullet = <ProgressBullet {...bulletProps} progress={progress} failed />;
  } else if (status === SyncStatus.STATUS_SKIPPED) {
    bullet = <ProgressBullet {...bulletProps} complete />;
  }

  return (
    <div className={classNames(styles.row, styles[SyncStatus[status]])}>
      {bullet}&nbsp;
      <span>
        {intl.formatMessage(msg[SyncStage[stage] as keyof typeof msg])}
      </span>
    </div>
  );
};

const SyncDialog: React.FC<{
  show: boolean;
  hasUnsynced: boolean;
  onDismiss: () => void;
}> = ({ show, hasUnsynced, onDismiss }) => {
  const dispatch = useDispatch();
  const [syncStatus, ui, online] = useSelector<
    Types.RootState,
    [SyncStatusState, UIState, OnlineState]
  >(({ syncStatus, ui, online }) => {
    return [syncStatus, ui, online];
  });
  const syncService = useSyncService();

  const handleForceSync = React.useCallback(() => syncService?.update(true), [
    syncService,
  ]);

  const handleSyncDocsChange = React.useCallback<
    React.FormEventHandler<HTMLInputElement>
  >(
    event => {
      const syncCatalogs = event.currentTarget.checked;
      dispatch(setSyncCatalogs(syncCatalogs));
    },
    [dispatch],
  );

  const stages = allSyncStages.map(stage => (
    <SyncStageComponent
      key={stage}
      {...syncStatus.stages[stage]}
      stage={stage}
    />
  ));

  return (
    <Modal show={show} onHide={onDismiss}>
      <Modal.Header>
        <FormattedMessage {...msg.title} />
      </Modal.Header>
      <Modal.Body>
        {/* <Json>{blankStages()}</Json> */}
        {/* <Json>{syncStatus}</Json> */}
        <FormattedMessage
          {...msg.onlineTitle}
          tagName="h4"
          values={{
            icon: (
              <i
                className={classNames(
                  'fa',
                  'navbar-button',
                  'fa-plug',
                  !online ? styles.bad : styles.good,
                )}
              />
            ),
          }}
        />
        <div>
          <p>
            {online ? (
              <FormattedMessage {...msg.onlineDescriptionOnline} />
            ) : (
              <FormattedMessage {...msg.onlineDescriptionOffline} />
            )}
          </p>
        </div>
        <h4>
          <Button
            onClick={handleForceSync}
            bsStyle="primary"
            bsSize="small"
            className="pull-right"
            disabled={!online || syncStatus.status === SyncStatus.STATUS_BUSY}
          >
            <FormattedMessage {...msg.syncNow} />
          </Button>
          <FormattedMessage
            {...msg.syncTitle}
            values={{
              icon: (
                <i
                  className={classNames(
                    'fa',
                    'fa-refresh',
                    hasUnsynced ? styles.bad : styles.good,
                    syncStatus.status === SyncStatus.STATUS_BUSY && 'fa-spin',
                  )}
                />
              ),
            }}
          />
        </h4>
        <div>
          <p>
            {!hasUnsynced ? (
              <FormattedMessage {...msg.syncDescriptionAllContentSynced} />
            ) : (
              <FormattedMessage {...msg.syncDescriptionNotAllContentSynced} />
            )}
            <br />
            {syncStatus.lastFullSynced ? (
              <FormattedMessage
                {...msg.syncDescriptionLastSync}
                values={{
                  relTime: (
                    <FormattedRelative value={syncStatus.lastFullSynced} />
                  ),
                }}
              />
            ) : (
              <FormattedMessage {...msg.syncDescriptionNotSynced} />
            )}
            <br />
            {syncStatus.status === SyncStatus.STATUS_FAILED ? (
              <FormattedMessage
                {...msg.syncDescriptionLastAttemptFailed}
                values={{
                  relTime: (
                    <FormattedRelative value={syncStatus.lastAttempt!} />
                  ),
                }}
              />
            ) : null}
          </p>
        </div>
        <h5>
          <FormattedMessage {...msg.lastAttemptTitle} />
        </h5>
        {stages}
        <hr />
        <Checkbox
          checked={ui.syncCatalogs}
          onChange={handleSyncDocsChange as any}
        >
          Automatically sync documents of followed projects
          <HelpBlock>
            The first initial sync can take very long for large projects.
          </HelpBlock>
        </Checkbox>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onDismiss}>
          <FormattedMessage {...msg.dismiss} />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SyncDialog;
