import { MakeTree } from '../../components/Tree/common';
import { LocationWithDetails } from '../../modules/locations';

function nextSiblings<T extends MakeTree<{}>>(node: T): T[] {
  if (!node.parent) {
    return [];
  }
  const children = node.parent.children as T[];
  return children.slice(children.indexOf(node) + 1);
}
function nextSibling<T extends MakeTree<{}>>(node: T): T | null {
  if (!node.parent) {
    return null;
  }
  const children = node.parent.children as T[];
  return children[children.indexOf(node) + 1] || null;
}

function previousSibling<T extends MakeTree<{}>>(node: T): T | null {
  if (!node.parent) {
    return null;
  }
  const children = node.parent.children as T[];
  return children[children.indexOf(node) - 1] || null;
}

// export function* continueDepthFirstTraversal<T extends MakeTree<{}>>(
//   node: T,
// ): Generator<T> {
//   for (const child  of node.children) {
//     yield*
//   }
//   for (const sibling of nextSiblings(node)) {
//     yield* continueDepthFirstTraversal(sibling);
//   }
// }

/**
 * This function facilitates the feature: suggesting a new location to continue counting in, at the end of a counting list
 * Selection of the most usefull next location can be very sophisticated, for now we use a very simple approach
 */
export function findNextCountingListTargetSatisfyingConstraint(
  location: MakeTree<LocationWithDetails>,
  // constraint: (loc: MakeTree<LocationWithDetails>) => boolean,
): MakeTree<LocationWithDetails> | null {
  return (
    nextSibling(location) ||
    (location.parent && nextSibling(location.parent)?.children[0]) ||
    (location.parent?.parent &&
      nextSibling(location.parent.parent)?.children[0]?.children[0]) ||
    null
  );
}
export function findPreviousCountingListTargetSatisfyingConstraint(
  location: MakeTree<LocationWithDetails>,
  // constraint: (loc: MakeTree<LocationWithDetails>) => boolean,
): MakeTree<LocationWithDetails> | null {
  return (
    previousSibling(location) ||
    (location.parent && previousSibling(location.parent)?.children[0]) ||
    (location.parent?.parent &&
      previousSibling(location.parent.parent)?.children[0]?.children[0]) ||
    null
  );
}
