import * as React from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { resolveHeaders, resolveUrl } from '../../utils';
import Dropzone from '../Dropzone';

let globalVisible = false;
let globalSetVisible: (visible: Boolean) => void | undefined;

function toggleVisible() {
  globalVisible = !globalVisible;
  if (globalSetVisible) globalSetVisible(globalVisible);
}
(window as any).toggleReplaceStateDialog = toggleVisible;

interface Props {}

const ReplaceStateDialog: React.FC<Props> = props => {
  const [, setVisible] = React.useState<boolean>(globalVisible);
  globalSetVisible = setVisible as any;
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.user);
  const handleDrop = React.useCallback(
    (files: File[]) => {
      const fr = new FileReader();
      fr.onload = async function() {
        const state = JSON.parse(fr.result as string);
        // obtain impersonation token
        const userId = state?.user?.recnum;
        const impersonate = await fetch(resolveUrl(`/impersonate/${userId}`), {
          headers: resolveHeaders() as any,
        }).then(res => res.json());

        state.user.accessToken = impersonate?.accessToken || user.accessToken;
        dispatch({
          type: 'REPLACE_STATE',
          payload: state,
        });
      };
      fr.readAsText(files[0], 'utf8');
    },
    [dispatch, user],
  );

  return (
    <Modal onHide={toggleVisible} show={globalVisible}>
      <Modal.Header>
        <Modal.Title>Replace State</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Dropzone onDrop={handleDrop}>
          <p>Drop a state file here</p>
        </Dropzone>
      </Modal.Body>
    </Modal>
  );
};

export default ReplaceStateDialog;
