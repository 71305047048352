import * as React from 'react';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { Dispatch } from 'redux';
import { CurrentUser, fetchUser } from '../../modules/user';
import Page from '../Page';
import Spinner from '../Spinner';
import msg from './msg';

const LazySettingsChunk = React.lazy(() => import('./SettingsChunk'));

export interface SettingsProps extends WrappedComponentProps {
  user?: CurrentUser;
  dispatch: Dispatch;
}

export class SettingsComponent extends React.Component<SettingsProps> {
  componentDidMount() {
    this.props.dispatch(fetchUser());
  }

  render() {
    const { intl, user, dispatch } = this.props;

    return (
      <Page title={intl.formatMessage(msg.settings)}>
        <React.Suspense fallback={<Spinner />}>
          <LazySettingsChunk dispatch={dispatch} user={user} />
        </React.Suspense>
      </Page>
    );
  }
}

export default injectIntl(SettingsComponent);
