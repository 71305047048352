import { ListEventType } from '@certhon/domain-models';
import * as Sentry from '@sentry/react';
import qs from 'query-string';
import * as R from 'ramda';
import { createAction, handleActions } from 'redux-actions';
import { CALL_API } from 'redux-api-middleware';
import {
  createList as createCreateListModification,
  updateList as createUpdateListModification,
} from '../common/list/modifications/operations/insert';
import { mergeSimilarModifications } from '../common/list/modifications/util';
import { mapById, resolveHeaders, resolveUrl } from '../utils';

function parseShardStr(shardStr) {
  let index;
  let size;
  try {
    const res = /^(?<index>\d+)-(?<size>\d+)$/.exec(shardStr);
    index = parseInt(res.groups.index);
    size = parseInt(res.groups.size);
    if (isNaN(index) || isNaN(size)) {
      throw new Error('Invalid shard string');
    }
  } catch (e) {
    throw new Error(`Could not parse shard-string "${shardStr}"`);
  }
  if (index >= size) {
    throw new Error('shard index to large');
  }

  return [index, size];
}

function getBucket(uuid, numBuckets) {
  return parseInt(uuid.slice(0, 4), 16) % numBuckets;
}

/*
 * Local actions
 */
export const MODIFY_LIST = 'MODIFY_LIST';

/** modify a list's contents by pushing an modification-event */
export const modifyList = createAction(
  MODIFY_LIST,
  (listId, event, persist = false) => ({
    listId,
    event,
    persist,
  }),
);

export const UPDATE_LIST = 'UPDATE_LIST';
export const updateList = (
  id,
  change /* { name, tags, associated_users, start_date, end_date } */,
  user_id,
  persist = true,
) => modifyList(id, createUpdateListModification(change, user_id), persist);

export const FLAG_PERSIST = 'FLAG_PERSIST';
export const flagPersist = createAction(FLAG_PERSIST);

export const REVERT_LIST = 'REVERT_LIST';
export const revertList = createAction(REVERT_LIST);

export const REQUEST_CREATE_LIST = 'REQUEST_CREATE_LIST';
export const SUCCESS_CREATE_LIST = 'SUCCESS_CREATE_LIST';
export const FAILURE_CREATE_LIST = 'FAILURE_CREATE_LIST';

export function createList(list, events = []) {
  return (dispatch, getState) => {
    const { user } = getState();
    const data = {
      events: [
        createCreateListModification(list, user.recnum, list.id),
        ...events,
      ],
    };
    return dispatch({
      [CALL_API]: {
        endpoint: resolveUrl('/lists'),
        method: 'POST',
        body: JSON.stringify(data),
        headers: resolveHeaders(),
        types: [
          {
            type: REQUEST_SAVE_LIST,
            payload: { listId: list.id, modId: list._persist },
          },
          SUCCESS_SAVE_LIST,
          {
            type: FAILURE_SAVE_LIST,
            payload: list.id,
          },
        ],
      },
    });
  };
}

/*
 * API actions
 */
export const REQUEST_SAVE_LIST = 'REQUEST_SAVE_LIST';
export const SUCCESS_SAVE_LIST = 'SUCCESS_SAVE_LIST';
export const FAILURE_SAVE_LIST = 'FAILURE_SAVE_LIST';

export function saveList(list) {
  return (dispatch, getState) => {
    const { user } = getState();
    let endpoint;
    let method;
    method = 'POST';
    endpoint = resolveUrl(`/lists/${list.id}/events`);

    const data = { ...list };

    // XXX this is probably not the right place for this logic
    // make sure no mods past the persist flag are send to the backend
    if (list._persist) {
      const persistingIdx = R.findIndex(
        R.propEq('id', list._persist),
        data.events,
      );
      if (persistingIdx === -1) {
        // skip slicing, just post everything
        console.warn('Could not find persisting index');
        Sentry.captureMessage('Could not find persisting index');
      } else {
        data.events = R.slice(0, persistingIdx + 1, data.events);
      }
    }

    if (data.events) {
      data.events = data.events.filter(event => event.user_id === user.recnum);
    }
    return dispatch({
      [CALL_API]: {
        endpoint,
        method,
        body: JSON.stringify({ events: data.events }),
        headers: resolveHeaders(),
        types: [
          {
            type: REQUEST_SAVE_LIST,
            payload: { listId: list.id, modId: list._persist },
          },
          SUCCESS_SAVE_LIST,
          {
            type: FAILURE_SAVE_LIST,
            payload: list.id,
          },
        ],
      },
    });
  };
}

export const REQUEST_FETCH_LISTS = 'REQUEST_FETCH_LISTS';
export const SUCCESS_FETCH_LISTS = 'SUCCESS_FETCH_LISTS';
export const FAILURE_FETCH_LISTS = 'FAILURE_FETCH_LISTS';

export function fetchLists(query) {
  let successType = SUCCESS_FETCH_LISTS;
  let qryString = '';
  if (query) {
    if (query.werk_id !== undefined) {
      query.werk_id = JSON.stringify(query.werk_id);
    }
    successType = { type: SUCCESS_FETCH_LISTS, meta: { query } };
    qryString = '?' + qs.stringify(query, { arrayFormat: 'bracket' });
  }
  return {
    [CALL_API]: {
      endpoint: resolveUrl(`/lists${qryString}`),
      method: 'GET',
      headers: resolveHeaders(),
      types: [REQUEST_FETCH_LISTS, successType, FAILURE_FETCH_LISTS],
    },
  };
}

export const REQUEST_DELETE_LIST = 'REQUEST_DELETE_LIST';
export const SUCCESS_DELETE_LIST = 'SUCCESS_DELETE_LIST';
export const FAILURE_DELETE_LIST = 'FAILURE_DELETE_LIST';

export function deleteList(listOrId) {
  const id = (listOrId && listOrId.id) || listOrId;
  return {
    [CALL_API]: {
      endpoint: resolveUrl(`/lists/${id}`),
      method: 'DELETE',
      headers: resolveHeaders(),
      types: [
        REQUEST_DELETE_LIST,
        { type: SUCCESS_DELETE_LIST, payload: id },
        FAILURE_DELETE_LIST,
      ],
    },
  };
}

/*
 * Sync strategy
 *
 * 1. when a list is modified, we flag it as modified by setting the id of the first non-saved modification
 * 2. we never overwrite modified lists until they are persisted to the backend
 * 3. when a user intents to persist a modified list, we send it to the backend as soon as internet is available
 *
 * store model:
 * {
 *  [list.id]: {
 *    ...normal list fields (id, title, events, etc)
 *
 *    _modified: (string containing modification id of first locally made modification)
 *    _persist: (string containing modification id of wich user has approved persisting to backend)
 *    _persisting: (bool indicating wether persisting is in progress)
 *  }
 * }
 */

export default handleActions(
  {
    [SUCCESS_FETCH_LISTS]: (state, { payload, meta }) => {
      const indexedPayload = mapById(payload);
      let parialNewState = R.pipe(
        R.toPairs,
        R.map(pair => {
          // cant overwrite 'modified' or 'scheduled' lists
          const [id] = pair;
          if (state?.[id]?._modified || state?.[id]?._persist) {
            return [id, state[id]];
          }
          return pair;
        }),
        R.fromPairs,
      )(indexedPayload);

      let nonExcludedLists = state ? Object.values(state) : [];

      if (meta && meta.query) {
        // we want to exclude deleted lists from the result
        // if a list is deleted, the backend will not return it, unless is is depended upon.
        // so considering the shard query param we want to exclude any list that is not returned by the backend

        if (meta.query.shard) {
          const [index, size] = parseShardStr(meta.query.shard);
          function inShard(id) {
            // TODO: put in shared lib, copied from api/src/utils/sharding.ts
            return getBucket(id, size) === index;
          }
          nonExcludedLists = nonExcludedLists.filter(
            ({ id, dependedUpon }) => !inShard(id) || dependedUpon,
          );
          // NOTE
          //  - we're not considering any other query params
          // because shard guarantees that eventually every deleted list pruned
          //  - it may take two sync passes for deleted list which are depended upon to be pruned
          //    because pruning may not happen because dependedUpon flag is only cleared in a later shard
          //    this behavior is acceptable given the complexity
        }
      }
      return {
        ...mapById(nonExcludedLists),
        ...parialNewState,
      };
    },
    [REQUEST_SAVE_LIST]: (
      state,
      { payload: { listId: id, modId: _persist } },
    ) => {
      const list = state[id];
      if (!list) {
        return state;
      }
      return {
        ...state,
        [id]: {
          ...list,
          _persisting: _persist || R.last(list.events).id,
        },
      };
    },
    [FAILURE_SAVE_LIST]: (state, { payload: id }) => {
      if (!id) {
        return state;
      }
      return {
        ...state,
        [id]: {
          ...state[id],
          _persisting: false,
        },
      };
    },
    [SUCCESS_SAVE_LIST]: (state, { payload }) => {
      // if the user has continued editing the list whilst the server is processing the save,
      // make sure to merge these changes before overwriting the local copy with the servers response
      let list = { ...payload };
      const origList = state[payload.id];

      if (origList && origList._persisting && origList._persisting !== true) {
        const persistingIdx = R.findIndex(
          R.propEq('id', origList._persisting),
          origList.events,
        );
        let newEvents;
        if (persistingIdx === -1) {
          // find all local changes which are not present in the remote list
          newEvents = [];
          for (let idx = origList.events.length; idx === 0; idx--) {
            const element = origList.events[idx];
            if (list.events.find(m => m.id === element.id)) {
              newEvents.push(element);
            } else {
              break;
            }
          }
          console.warn(
            `Could not find persisting persistingIdx, manually merging ${newEvents.length} events`,
          );

          Sentry.captureMessage(
            `Could not find persisting persistingIdx, manually merging ${newEvents.length} events`,
          );
        } else {
          newEvents = R.slice(persistingIdx + 1, Infinity, origList.events);
        }
        if (newEvents.length) {
          // events have been made. transfer them
          // tslint:disable-next-line:no-console
          list.events = [...list.events, ...newEvents];
          list._modified = newEvents[0].id;
          list._persist =
            origList._persist === origList._persisting
              ? false
              : origList._persist;
          // XXX: backend should always provide dependencies
          list.dependencies = origList.dependencies;
        }
      }
      return {
        ...state,
        [payload.id]: list,
      };
    },
    [SUCCESS_DELETE_LIST]: (state, { payload }) => ({
      ...state,
      [payload]: {
        ...state[payload],
        deleted: true,
      },
    }),
    [MODIFY_LIST]: (state, { payload }) => {
      const { event, listId, persist = false } = payload;
      const list = state[listId];
      const events = persist
        ? // FIXME: since _modified contains the first local modification and 'mergeSimilarModifications' starts merging after the since mod-id
          //   the first mod is never merged, this is techically incorrect bun acceptable, not refactoring this hairy code right now. this needs to be done more thourougly anyway
          mergeSimilarModifications(
            [...list.events, event],
            list._persisting ||
              list._modified ||
              (R.last(list.events) || []).id,
          )
        : [...list.events, event];
      // note that the mergeSimilarModifications uses the id of the FIRST mod when merging
      // therefore we the new modification in this context may not exist
      const lastId = R.last(events).id;
      let patch;
      if (event.type === ListEventType.MODIFY_LIST) {
        patch = event.payload;
      }
      return {
        ...state,
        [listId]: {
          ...list,
          ...patch,
          _modified: list._modified || lastId,
          _persist: persist ? lastId : list._persist,
          events: events,
        },
      };
    },
    [UPDATE_LIST]: (state, { payload: { id, ...changes } }) => ({
      ...state,
      [id]: {
        ...state[id],
        ...changes,
        _persist: (R.last(state[id].events) || []).id || true,
      },
    }),
    [FLAG_PERSIST]: (state, { payload: id }) => {
      const events = mergeSimilarModifications(
        state[id].events,
        state[id]._modified,
      );
      return {
        ...state,
        [id]: {
          ...state[id],
          _persist: (R.last(events) || []).id || true,
          events,
        },
      };
    },
    [REVERT_LIST]: (state, { payload: id }) => {
      const list = state[id];
      return {
        ...state,
        [id]: {
          ...list,
          events: R.splitWhen(R.propEq('id', list._modified), list.events)[0],
          _modified: false,
        },
      };
    },
  },
  null,
);
