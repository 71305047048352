import * as React from 'react';
import { FilterInterface } from '../../hooks/useProjectFilter';
import { Project } from '../../models/Project';
import { UsersState } from '../../modules/users';
import ParameterizedSearch from '../ParameterizedSearch';
import { ParametersOptions } from '../ParameterizedSearch/model';

const extendedTagOptions: ParametersOptions = {
  noInput: false,
  small: false,
  parameters: {
    following: {
      icon: 'star',
      options: ['true', 'false'],
      name: 'following',
    },
    archived: {
      icon: 'archive',
      options: ['true', 'false', 'null'],
      name: 'archived',
      getLabel(value) {
        if (value === 'true') return 'yes';
        if (value === 'false') return 'no';
        if (value === 'null') return 'both';
        return value;
      },
      getLabelFromValue(value) {
        if (value === 'true') return 'yes';
        if (value === 'false') return 'no';
        if (value === 'null') return 'both';
        return value;
      },
    },
  },
};

interface ProjectFilterProps {
  filter: FilterInterface;
  projects: Project[] | null;
  users: UsersState;
}

const ProjectFilter: React.FC<ProjectFilterProps> = ({ filter }) => {
  return (
    <ParameterizedSearch
      value={filter.filter}
      onChange={filter.changeFilter}
      options={{ ...extendedTagOptions, noInput: false, small: false }}
      onReset={filter.resetFilter}
      onClear={filter.clearFilter}
    />
  );
};

export default ProjectFilter;
