import uniq from 'lodash/fp/uniq';
import { BuiltList, List } from '../../models/List';
import { ObjectMap } from '../logic/types';
import listModificationsReducer from './modifications/reducer';
import { isExpandProtoListModification } from './modifications/util';
import { Field, RootField, createRootField } from './types';

// export type BuildOtherStructureField = BuildFieldMeta &
//   OtherStructureField & { fields: BuildField[] };

// export type BuildRootField = BuildFieldMeta &
//   RootField & { fields: BuildField[] };

// export type BuildStructureField = BuildOtherStructureField | BuildRootField;
// export type BuildValueField = BuildFieldMeta & ValueField;
// export type BuildField = BuildStructureField | BuildValueField;

// interface BuildListMeta {
//   tree: BuildRootField;
//   index: ObjectMap<BuildField>;
//   builtDependencies: ObjectMap<BuildList>;
// }

// export type BuildList = List & BuildListMeta;
// export type BuiltList = Omit<BuildList, 'builtDependencies'>;

export function getListDependencies(list: List): string[] {
  return uniq(
    list.events
      .filter(isExpandProtoListModification)
      .map(pm => pm.payload.protoListId),
  );
}

export function initializeBuiltList(
  list: List,
  builtDependencies: ObjectMap<BuiltList>,
): BuiltList {
  const tree = createRootField({ id: list.id });

  const initial = {
    ...list,
    tree,
    builtDependencies,
    index: {
      [tree.id]: tree,
    },
  };

  initial._persist = null;
  initial._modified = null;

  return initial;
}

export default function buildList(
  list: List,
  builtDependencies: ObjectMap<BuiltList>,
): BuiltList {
  // check if all dependencies are available
  for (const dependencyId of getListDependencies(list)) {
    if (!builtDependencies[dependencyId]) {
      throw new Error('Dependency missing: ' + dependencyId);
    }
  }

  const initial = initializeBuiltList(list, builtDependencies);

  const result = list.events.reduce(listModificationsReducer, initial);

  return result;
}

export function buildListAndDependencies(
  list: List,
  lists: { [idOrRecnum: string]: List } | List[],
): BuiltList {
  const listsArray = lists instanceof Array ? lists : Object.values(lists);
  const dependencies: ObjectMap<BuiltList> = {};
  for (const dependencyId of getListDependencies(list)) {
    const dep = listsArray.find(l => l.id === dependencyId);
    if (!dep) {
      throw new Error(
        `could not find dependency (${dependencyId}) for list (${list.id})`,
      );
    }
    dependencies[dep.id] = buildListAndDependencies(dep, listsArray);
  }
  return buildList(list, dependencies);
}

export function buildIndex(tree: RootField): ObjectMap<Field> {
  const index: ObjectMap<Field> = {};
  function addToIndex(f: Field) {
    if (process.env.NODE_ENV !== 'production' && index[f.id]) {
      throw new Error('Field allready in index');
    }
    index[f.id] = f;
    if (f.type === 'GROUP_FIELD' || f.type === 'ROOT_FIELD') {
      f.children.forEach(addToIndex);
    }
  }
  addToIndex(tree);
  return index;
}
