import { identity } from 'ramda';
import * as React from 'react';
import Autocomplete from 'react-autocomplete';
import { findDOMNode } from 'react-dom';
import { detectExtraBackspace } from './detectExtraBackspace';
import { renderItem, renderMenu } from './index';
import { Parameter, ParameterOptions } from './model';
import styles from './ParameterizedSearch.module.scss';

export interface ParameterValueAutocompleteProps {
  value: string;
  onExtraBackspace: () => void;
  focusOnMount: boolean;
  onFocus: () => void;
  onBlur: () => void;
  parameter: Parameter;
  options: ParameterOptions;
}
export default class ParameterValueAutocomplete extends React.Component<
  ParameterValueAutocompleteProps & Partial<Autocomplete.Props>
> {
  handleKeyDown = detectExtraBackspace(() => this.props.onExtraBackspace());
  componentDidMount() {
    if (this.props.focusOnMount) {
      const node = findDOMNode(this) as Element;
      (node.querySelector('input') as HTMLInputElement).focus();
    }
  }
  render() {
    const {
      parameter,
      onFocus,
      onBlur,
      options,
      getItemValue,
      value,
      ...rest
    } = this.props;
    return (
      <div className={styles.input}>
        <div className={styles.name}>
          <i className={`fa fa-${(options && options.icon) || 'question'}`} />{' '}
          {parameter.name}
        </div>
        <Autocomplete
          value={value || parameter.value}
          items={options.options || []}
          renderItem={(u, iH, stl) =>
            renderItem((options.getLabel && options.getLabel(u)) || u, iH, stl)
          }
          getItemValue={getItemValue || identity}
          renderMenu={renderMenu}
          wrapperProps={{ className: 'dropdown open ' + styles.wrapper }}
          inputProps={{
            size: 1,
            onBlur,
            onFocus,
            onKeyDown: this.handleKeyDown,
          }}
          {...rest}
        />
      </div>
    );
  }
}
