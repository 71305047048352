import { AnyAction } from 'redux';

export interface FilterAction {
  type: 'FILTER';
  payload: {
    filterKey: string;
    props: any;
  };
}

export const filter = (filterKey: string, props: any): FilterAction => ({
  type: 'FILTER',
  payload: {
    filterKey,
    props
  }
});

export interface FilterState {
  [filterKey: string]: any;
}

export default function(
  state: FilterState = {},
  action: FilterAction | AnyAction
): FilterState {
  if (action.type === 'FILTER') {
    return {
      ...state,
      [action.payload.filterKey]: action.payload.props
    };
  }
  return state;
}
