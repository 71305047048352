import React from 'react';
import useHashParam from './useHashParam';

export interface LocationStockParam {
  location_id: string;
  artikel_id: number | null;
}

export const serialize = (lsp: LocationStockParam | null): string =>
  lsp ? `${lsp.location_id || ''}_${lsp.artikel_id || ''}` : '';

export const deserialize = (param: string): LocationStockParam | null => {
  if (!param) {
    return null;
  }
  const [location_id, raw_artikel_id] = param.split('_');
  let artikel_id: number | null;
  if (raw_artikel_id === '') {
    artikel_id = null;
  } else {
    artikel_id = JSON.parse(raw_artikel_id);
    if (!artikel_id) {
      throw new Error(
        'Could not parse LocationStockParam due to invalid artikel_id',
      );
    }
  }
  return { location_id, artikel_id };
};

export default function useLocationStockParamHash(
  initialValue: LocationStockParam | null,
) {
  const [serializedParam, setSerializedParam] = useHashParam(
    initialValue ? serialize(initialValue) : '',
  );
  const setLocationStockHashParam = React.useCallback(
    (lsp: LocationStockParam | null) =>
      setSerializedParam(serialize(lsp), { replace: true }),
    [setSerializedParam],
  );

  const locationStockParam = React.useMemo(
    () => (serializedParam === '' ? null : deserialize(serializedParam)),
    [serializedParam],
  );

  return [locationStockParam, setLocationStockHashParam] as const;
}
