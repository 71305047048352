import PropTypes from 'prop-types';
import * as React from 'react';
import { Button, DropdownButton, MenuItem } from 'react-bootstrap';
import { FormattedMessage, defineMessages } from 'react-intl';
import { push } from 'redux-first-history';
import * as uuid from 'uuid';
import { expandProtoList } from '../../common/list';
import createFilters from '../../common/logic/createFilters';
import getFilterOptions from '../../common/logic/getFilterOptions';
import {
  TicketSorting,
  createSortFunction,
  defaultSorting,
  fromString,
  sortingPresets,
} from '../../common/logic/sortTickets';
import { filter } from '../../modules/filters';
import { createList } from '../../modules/lists';
import { fetchWorks } from '../../modules/works';
import { WorkDetailsBreadcrumbs } from '../BreadCrumbs';
import ChecklistsList from '../ChecklistsList/index';
import DownloadButton from '../DownloadButton';
import ChecklistPropertyDialog from '../ListsOverview/ChecklistPropertyDialog/index';
import Page from '../Page';
import ParameterizedSearch from '../ParameterizedSearch';
import { IsProjectManager } from '../Permissions/index';
import Spinner from '../Spinner';
import TicketsList from '../TicketsList/index';

const uuidv4 = uuid.v4;

const FILTER_KEY = 'WORK_DETAILS_FILTER';

const msg = defineMessages({
  LIST_CONTROL_LIST_HEADER_CONTROLLISTS: {
    id: 'WORKS_DETAILS_COMPONENT.LIST_CONTROL_LIST_HEADER_CONTROLLISTS',
    defaultMessage: 'Control lists',
    description: '',
  },
  LIST_CONTROL_LIST_BUTTON_NEW_LIST: {
    id: 'WORKS_DETAILS_COMPONENT.LIST_CONTROL_LIST_BUTTON_NEW_LIST',
    defaultMessage: 'New List',
    description: '',
  },
  LIST_TICKET_HEADER_TICKET: {
    id: 'WORKS_DETAILS_COMPONENT.LIST_TICKET_HEADER_TICKET',
    defaultMessage: 'Tickets',
    description: '',
  },
  LIST_TICKET_BUTTON_NEW_TICKET: {
    id: 'WORKS_DETAILS_COMPONENT.LIST_TICKET_BUTTON_NEW_TICKET',
    defaultMessage: 'New Ticket',
    description: '',
  },

  //LIST_TICKET_HEADER_TICKET
});

export default class WorkDetailsComponent extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    work: PropTypes.object,
    checklists: PropTypes.array,
  };

  constructor(props) {
    super(props);

    this.state = {
      showCreateListPanel: false,
    };
  }

  /* List creation */
  handleNewList = () => {
    this.setState({
      showCreateListPanel: true,
      initialList: {
        werk_id: this.props.work.recnum,
      },
    });
  };

  handleCancel = () => {
    this.setState({ initialList: null, showCreateListPanel: false });
  };

  handleSubmit = async list => {
    const { user, dispatch } = this.props;
    let preparedList = list;
    const events = [];
    if (list.template_id) {
      // prepare list
      preparedList = {
        ...list,
        id: uuidv4(),
      };
      delete preparedList.template_id;

      // expand list-template proto
      events.push(
        expandProtoList(
          {
            targetNodeId: preparedList.id,
            protoListId: list.template_id,
          },
          user.recnum,
        ),
      );
    }
    // persist list
    await dispatch(createList(preparedList, events));
    await dispatch(fetchWorks());

    // close panel
    this.setState({ showCreateListPanel: false, initialList: null });
  };

  /* Ticket creation */
  handleNewTicket = () => {
    this.props.dispatch(push(`/tickets/new?werk_id=${this.props.work.recnum}`));
  };
  handleSearchChange = value => {
    this.props.dispatch(filter(FILTER_KEY, value));
  };
  handleSearchClear = () => {
    this.handleSearchChange('');
  };

  render() {
    const {
      work,
      lists,
      checklists,
      users,
      tickets,
      dispatch,
      works,
      user,
      loading,
      filters,
      project,
      sorting,
      handleChangeSorting,
      labels,
    } = this.props;
    const { showCreateListPanel, initialList } = this.state;
    if (loading) {
      return (
        <Page>
          <Spinner />
        </Page>
      );
    }
    const name = (work.oms && work.oms.trim()) || work.id;
    const projectName = project && (
      <>
        {project.projectname} {project.id && <em> - {project.id}</em>}
      </>
    );
    let search = filters[FILTER_KEY];
    if (search === null || search === undefined) {
      search = '';
    }

    const sortingString = sorting['tickets'] || defaultSorting;
    const cnf = fromString(sortingString);
    const sortDescr = TicketSorting[cnf[1][0]].toLowerCase();
    const sortFun = createSortFunction(cnf);

    const [ticketFilter, listFilter] = createFilters(search, [work]);

    const options = getFilterOptions(this.props.users, labels);
    delete options.parameters.projectTickets;
    delete options.parameters.department;
    return (
      <Page>
        <WorkDetailsBreadcrumbs
          name={name}
          projectId={work.project_id}
          projectName={projectName}
        />

        <h3>
          {(work.klant_naam && work.klant_naam.trim()) || work.klant} -{' '}
          {(work.oms && work.oms.trim()) || work.id}
        </h3>

        <ParameterizedSearch
          value={search}
          onChange={this.handleSearchChange}
          options={options}
          onClear={search && this.handleSearchClear}
        />

        {/* Lists */}
        <h5 className="listHeader">
          <span>
            {' '}
            <FormattedMessage
              {...msg.LIST_CONTROL_LIST_HEADER_CONTROLLISTS}
            />{' '}
          </span>
          <IsProjectManager user={user}>
            <Button bsSize="small" bsStyle="link" onClick={this.handleNewList}>
              <i className="fa fa-plus" />{' '}
              <FormattedMessage {...msg.LIST_CONTROL_LIST_BUTTON_NEW_LIST} />
            </Button>
          </IsProjectManager>
        </h5>
        <ChecklistsList
          dispatch={dispatch}
          lists={lists}
          checklists={checklists.filter(listFilter)}
          users={users}
          totalItems={checklists.length}
          onClear={search && this.handleSearchClear}
        />
        <ChecklistPropertyDialog
          list={initialList}
          dispatch={dispatch}
          show={showCreateListPanel}
          lists={lists}
          users={users}
          works={works}
          onCancel={this.handleCancel}
          onSubmit={this.handleSubmit}
        />

        {/* Tickets         */}
        <h5 className="listHeader">
          <span>
            <FormattedMessage {...msg.LIST_TICKET_HEADER_TICKET} />
            {user && user.internal && (
              <DownloadButton
                bsStyle="link"
                className="btn-link"
                downloadUri={`/api/works/${
                  work.recnum
                }/ticketsreport?filter=${encodeURIComponent(
                  search,
                )}&sorting=${sortingString}`}
              >
                <i className="fa fa-download" /> download
              </DownloadButton>
            )}
          </span>
          <span>
            <DropdownButton
              bsStyle="link"
              title={
                <>
                  order by: {sortDescr}
                  <i className="fa fa-carret-down" />
                </>
              }
              id={`actions`}
            >
              {Array.from(sortingPresets).map(([name, preset]) => (
                <MenuItem
                  disabled={false}
                  onClick={() => handleChangeSorting('tickets', preset)}
                  title={name}
                >
                  <span>{name}</span>
                </MenuItem>
              ))}
            </DropdownButton>
          </span>
          <Button bsSize="small" bsStyle="link" onClick={this.handleNewTicket}>
            <i className="fa fa-plus" />{' '}
            <FormattedMessage {...msg.LIST_TICKET_BUTTON_NEW_TICKET} />
          </Button>
        </h5>
        <TicketsList
          labels={labels}
          users={users}
          dispatch={dispatch}
          tickets={sortFun(tickets.filter(ticketFilter))}
          totalItems={tickets.length}
          onClear={search && this.handleSearchClear}
        />
      </Page>
    );
  }
}
