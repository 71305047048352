export const departmentMap: any = {
  climate: 'V',
  electrical: 'E',
  greenhouse: 'K',
  irrigation: 'W',
  service: 'S',
  'R&D': 'R',
  // FIX: departments should be 1-to-1
  breedingcells: 'C',
  'climate-cells': 'C',
};

export default function departmentToChar(dep: string): string | null {
  return departmentMap[dep] || null;
}
