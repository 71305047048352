import { createObjectURL } from 'blob-util';
import classNames from 'classnames';
import * as R from 'ramda';
import * as React from 'react';
import {
  Button,
  ButtonToolbar,
  DropdownButton,
  MenuItem,
} from 'react-bootstrap';
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps,
} from 'react-intl';
import { Link } from 'wouter';
import { getParamsFromTags } from '../../../common/list';
import getAttachmentExtension from '../../../common/logic/getAttachmentExtension';
import { ExcludeNull } from '../../../common/logic/types';
import { User } from '../../../models/User';
import { LabelsState } from '../../../modules/labels';
import { StoreTicket } from '../../../modules/tickets';
import { UsersState } from '../../../modules/users';
import { attachmentStorage } from '../../../services';
import { Lex } from '../../../utils/LexicalParsing';
import { lingualJoin } from '../../../utils/messages';
import Attachment from '../../Attachment';
import FormattedRelative from '../../FormattedRelative/index';
import LabelToken from '../../Labels/LabelToken';
import Markdown from '../../Markdown';
import CerthonMarkdownEditor from '../../MarkdownEditor';
import '../../TicketDetails/msg';
import UserToken from '../../UserToken';
import styles from './Events.module.scss';

export interface TimelineEvent {
  // FIX: uniform event ids
  id?: string;

  user_id: number;
  // FIX: define possible types & use generic names
  type: string;
  meta?: {
    meetingId?: string;
    ticketId?: string;
  } | null;
  created: Date | string;
  // FIX: completely define payload
  payload: any & {
    message?: string;
    label?: string;
    name?: string;
    link?: string;
  };
}

interface TimelineEventCallbacks {
  onEditEvent: (message: string, event: TimelineEvent) => void;
  onDeleteEvent: (event: TimelineEvent) => void;
  canChangeEvent?: (event: TimelineEvent) => boolean;
}

export function createEventList(
  events: TimelineEvent[],
  users: ExcludeNull<UsersState>,
  localTickets: Record<string, StoreTicket>,
  allTickets: Record<string, StoreTicket>,
  labels: LabelsState,
  lexes: Lex[],
  callbacks: TimelineEventCallbacks,
) {
  const eventSections = R.groupWith(
    ({ user_id: uid1 }, { user_id: uid2 }) => uid1 === uid2,
    events,
  );

  return eventSections.map((eventSection, id) => (
    <EventSection
      lexes={lexes}
      users={users}
      localTickets={localTickets}
      allTickets={allTickets}
      key={id}
      labels={labels}
      events={eventSection}
      user={users?.[eventSection[0].user_id] as User} // FIX: type cast
      {...callbacks}
    />
  ));
}

interface EventSectionProps extends TimelineEventCallbacks {
  localTickets: Record<string, StoreTicket>;
  allTickets: Record<string, StoreTicket>;
  users: ExcludeNull<UsersState>;
  user: User;
  labels: LabelsState;
  events: TimelineEvent[];
  lexes: Lex[];
}

export default class EventSection extends React.Component<EventSectionProps> {
  render() {
    const { user, events, ...rest } = this.props;

    const eventElements = events.map((event, id) => (
      <EventComponent key={id} user={user} event={event} {...rest} />
    ));

    return (
      <div className={styles.eventBox}>
        <div className={styles.userToken}>
          <UserToken collapse user={user} />
        </div>
        <div className={styles.content}>{eventElements}</div>
      </div>
    );
  }
}

interface EventComponentProps
  extends TimelineEventCallbacks,
    WrappedComponentProps {
  event: TimelineEvent;
  user: User;
  labels: LabelsState;
  localTickets: Record<string, StoreTicket>;
  allTickets: Record<string, StoreTicket>;
  users: ExcludeNull<UsersState>;
  lexes: Lex[];
}
export const EventComponent = injectIntl(
  class extends React.Component<
    EventComponentProps,
    { editMessage: boolean; message: string; selection?: any }
  > {
    constructor(props: EventComponentProps) {
      super(props);
      this.state = {
        editMessage: false,
        message: props.event.payload.message || props.event.payload.label,
      };
    }

    handleEdit = () => {
      this.setState({ editMessage: true });
    };
    handleCancelEdit = (ev: React.FormEvent<HTMLFormElement>) => {
      ev.preventDefault();
      this.setState({
        editMessage: false,
        message:
          this.props.event.payload.message || this.props.event.payload.label,
      });
    };

    handleSubmitEdit = (ev: React.FormEvent<HTMLFormElement>) => {
      ev.preventDefault();
      const { event, onEditEvent } = this.props;
      const { message } = this.state;
      if (
        message &&
        message !== (event.payload.message || event.payload.label)
      ) {
        onEditEvent(message, event);
      }
      this.setState({ editMessage: false });
    };

    handleDownloadAttachment = async (
      event: TimelineEvent,
      ev: React.MouseEvent<Button>,
    ) => {
      const blob = await attachmentStorage.getItem(
        event.payload.checksum,
        event.payload.contentType,
      );

      if (blob) {
        const element = document.createElement('a');
        const url = createObjectURL(blob);
        element.setAttribute('href', url);
        element.setAttribute(
          'download',
          `${event.payload.checksum}.${getAttachmentExtension(event.payload)}`,
        );
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      }
    };

    handleDelete = () => {
      const { event, onDeleteEvent } = this.props;
      // eslint-disable-next-line no-restricted-globals
      if (confirm('Wil je het verwijderen?')) {
        onDeleteEvent(event);
      }
    };

    render() {
      const {
        user,
        labels,
        event,
        onEditEvent,
        onDeleteEvent,
        canChangeEvent,
        intl,
        allTickets,
        localTickets,
        users,
        lexes,
      } = this.props;
      const {
        type,
        payload: {
          add_tags,
          remove_tags,
          add_labels,
          remove_labels,
          ...payload
        },
        created,
        meta,
      } = event;
      const userName = (
        <span title={user.loginname}>{user.name || user.loginname}</span>
      );
      let relTimestamp = (
        <a href={`#event-${event.id}`} title={new Date(created).toString()}>
          <FormattedRelative value={created} />
        </a>
      );
      let extraClasses = '';
      let duringMeeting = null;
      if (meta && meta.meetingId) {
        duringMeeting = (
          <FormattedMessage
            id="EVENTS.DURING_MINUTE"
            defaultMessage="during {meeting}"
            description=""
            values={{
              loginname: userName,
              relTimestamp,
              meeting: <Link to={`/meeting/${meta.meetingId}`}>meeting</Link>,
            }}
          />
        );
      }
      let content;
      if (type === 'CREATE_TICKET') {
        content = (
          <p
            className={styles.light}
            title={JSON.stringify(event.payload, null, 2)}
          >
            <i className="fa fa-plus" />
            <FormattedMessage
              id="TICKET_EVENT.USER_HAS_CREATED_TICKET"
              defaultMessage="{loginname} has created this ticket {relTimestamp}"
              description=""
              values={{ loginname: userName, relTimestamp }}
            />{' '}
            {duringMeeting}
          </p>
        );
      } else if (type === 'UPDATE_TICKET') {
        extraClasses = classNames(extraClasses, 'hidden-print');
        content = (
          <p
            className={styles.light}
            title={JSON.stringify(event.payload, null, 2)}
          >
            <i className="fa fa-pencil" />
            <FormattedMessage
              id="TICKET_EVENT.USER_HAS_UPDATED_TICKET"
              defaultMessage="{loginname} has updated {fields} {relTimestamp}."
              description=""
              values={{
                loginname: userName,
                relTimestamp,
                fields: lingualJoin(
                  'TICKET_FIELDS.',
                  Object.keys(payload),
                  intl.formatMessage,
                ),
              }}
            />{' '}
            {add_tags && add_tags.length && (
              <FormattedMessage
                id="TICKET_EVENT.HAS_ADDED_TAGS"
                defaultMessage="Has added tags: {tags}"
                values={{
                  tags: (
                    <>
                      {getParamsFromTags(add_tags).parameters.map((p, id) => (
                        <em key={id}>{p.value}</em>
                      ))}
                    </>
                  ),
                }}
              />
            )}{' '}
            {remove_tags && remove_tags.length && (
              <FormattedMessage
                id="TICKET_EVENT.HAS_ADDED_TAGS"
                defaultMessage="Has removed tags: {tags}. "
                values={{
                  tags: (
                    <>
                      {getParamsFromTags(remove_tags).parameters.map(p => (
                        <em>{p.value}</em>
                      ))}
                    </>
                  ),
                }}
              />
            )}
            {add_labels && add_labels.length && (
              <FormattedMessage
                id="TICKET_EVENT.HAS_ADDED_LABELS"
                defaultMessage="Has added labels: {labels}"
                values={{
                  labels: (
                    <>
                      {(add_labels as number[])
                        .map(recnum => labels?.[recnum])
                        .filter(x => x)
                        .map(l => (
                          <LabelToken label={l!} />
                        ))}
                    </>
                  ),
                }}
              />
            )}{' '}
            {remove_labels && remove_labels.length && (
              <FormattedMessage
                id="TICKET_EVENT.HAS_ADDED_LABELS"
                defaultMessage="Has removed labels: {labels}. "
                values={{
                  labels: (
                    <>
                      {(remove_labels as number[])
                        .map(recnum => labels?.[recnum])
                        .filter(x => x)
                        .map(l => (
                          <LabelToken label={l!} />
                        ))}
                    </>
                  ),
                }}
              />
            )}
            {duringMeeting}
          </p>
        );
      } else if (type === 'ADD_MESSAGE') {
        content = (
          <div>
            <p
              className={styles.light}
              title={JSON.stringify(payload, null, 2)}
            >
              <i className="fa fa-comment" />
              <FormattedMessage
                id="EVENTS.USER_COMMENTED"
                defaultMessage="{loginname} commented {relTimestamp}"
                description=""
                values={{ loginname: userName, relTimestamp }}
              />{' '}
              {duringMeeting}
            </p>
            <Markdown
              allTickets={allTickets}
              localTickets={localTickets}
              users={users}
              className={styles.markdown}
            >
              {payload.message}
            </Markdown>
          </div>
        );
      } else if (type === 'ADD_ATTACHMENT') {
        content = (
          <div>
            <p className={styles.light}>
              <i className="fa fa-camera" />
              <FormattedMessage
                id="EVENTS.USER_UPLOADED_ATTACHMENT"
                defaultMessage="{loginname} uploaded {relTimestamp}"
                description=""
                values={{ loginname: userName, relTimestamp }}
              />{' '}
              {duringMeeting}
            </p>
            <Attachment attachment={payload} />
          </div>
        );
      } else if (type === 'ADD_LOCATION') {
        if (!payload.position || !payload.position.coords) {
          return <em>invalid geotag</em>;
        }
        const { latitude, longitude, accuracy } = payload.position.coords;
        content = (
          <div>
            <p
              className={styles.light}
              title={JSON.stringify(payload, null, 2)}
            >
              <i className="fa fa-map-marker" />
              <FormattedMessage
                id="EVENTS.USER_TAGGED_LOCATION"
                defaultMessage="{loginname} tagged has {link} {relTimestamp}"
                description=""
                values={{
                  loginname: userName,
                  relTimestamp,
                  link: (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`https://www.google.com/maps/?q=${latitude},${longitude}`}
                    >
                      <FormattedMessage
                        id="EVENT.LOCATION_LINK_NAME"
                        defaultMessage="location"
                        description=""
                      />
                    </a>
                  ),
                }}
              />{' '}
              {duringMeeting}
            </p>
            <dl className="dl-horizontal">
              <dt>
                <FormattedMessage
                  id="LOCATION.DESCRIPITON"
                  defaultMessage="Description"
                  description=""
                />
              </dt>
              <dd>{payload.label}</dd>
              <dt>
                <FormattedMessage
                  id="LOCATION.LATITUDE"
                  defaultMessage="Latitude"
                  description=""
                />
              </dt>
              <dd>{latitude}</dd>
              <dt>
                <FormattedMessage
                  id="LOCATION.LONGITUDE"
                  defaultMessage="Longitude"
                  description=""
                />
              </dt>
              <dd>{longitude}</dd>
              <dt>
                <FormattedMessage
                  id="LOCATION.ACCURACY"
                  defaultMessage="Accuracy"
                  description=""
                />
              </dt>
              <dd>{accuracy}m</dd>
            </dl>
            <Button
              target="_blank"
              href={`https://www.google.com/maps/?q=${latitude},${longitude}`}
            >
              <FormattedMessage
                id="LOCATION.SHOW_LOCATION"
                defaultMessage="Show location"
                description=""
              />
            </Button>
          </div>
        );
      } else if (type === 'NOTIFICATION_OPT_IN') {
        content = (
          <p
            className={styles.light}
            title={JSON.stringify(event.payload, null, 2)}
          >
            <i className="fa fa-bell" />
            <FormattedMessage
              id="TICKET_EVENT.USER_HAS_OPTED_IN"
              defaultMessage="{loginname} has turned on notifications {relTimestamp}"
              description=""
              values={{ loginname: userName, relTimestamp }}
            />{' '}
            {duringMeeting}
          </p>
        );
      } else if (type === 'NOTIFICATION_OPT_OUT') {
        content = (
          <p
            className={styles.light}
            title={JSON.stringify(event.payload, null, 2)}
          >
            <i className="fa fa-bell-slash" />
            <FormattedMessage
              id="TICKET_EVENT.USER_HAS_OPTED_OUT"
              defaultMessage="{loginname} has turned off notifications {relTimestamp}"
              description=""
              values={{ loginname: userName, relTimestamp }}
            />{' '}
            {duringMeeting}
          </p>
        );
      } else if (type === 'REFERENCE') {
        content = (
          <p
            className={styles.light}
            title={JSON.stringify(event.payload, null, 2)}
          >
            <i className="fa fa-external-link" />
            <FormattedMessage
              id="TICKET_EVENT.REFERENCED"
              defaultMessage="{loginname} has mentioned in {ref} {relTimestamp}"
              description=""
              values={{
                loginname: userName,
                relTimestamp,
                ref: <Link children={payload.name} to={payload.link} />,
              }}
            />{' '}
            {duringMeeting}
          </p>
        );
      } else {
        content = <p className="error">unkown event</p>;
      }

      const canChange = !canChangeEvent || canChangeEvent(event);
      const canEdit =
        !!onEditEvent &&
        event.type !== 'ADD_ATTACHMENT' &&
        (!canChangeEvent || canChangeEvent(event));

      if (this.state.editMessage) {
        content = (
          <>
            <form
              onSubmit={this.handleSubmitEdit}
              onReset={this.handleCancelEdit}
            >
              <CerthonMarkdownEditor
                allTickets={allTickets}
                users={users}
                lexes={lexes}
                localTickets={localTickets}
                classes={{ reactMde: 'column' }}
                onChange={text => this.setState({ message: text })}
                childProps={{
                  textArea: {
                    id: 'edit-message',
                    name: 'edit-message',
                  },
                }}
                value={this.state.message}
              />
              <Button type="submit">Submit</Button>
              <Button type="reset">Cancel</Button>
            </form>
          </>
        );
      }

      const canDelete = !!onDeleteEvent;
      const editControl = canChange && (canEdit || canDelete) && (
        <ButtonToolbar
          bsSize="small"
          bsStyle="link"
          className={styles.editControl}
        >
          {type === 'ADD_ATTACHMENT' && (
            <Button
              title="download attachment"
              bsSize="small"
              bsStyle="link"
              onClick={this.handleDownloadAttachment.bind(undefined, event)}
            >
              <i className="fa fa-download" />
            </Button>
          )}
          <DropdownButton
            id={`event-dropdown-${event.id}`}
            pullRight
            bsSize="small"
            bsStyle="link"
            title={<i className="fa fa-pencil" />}
            noCaret
          >
            {canEdit && (
              <MenuItem onClick={this.handleEdit}>
                <FormattedMessage
                  id="EDIT"
                  defaultMessage="Edit"
                  description=""
                />
              </MenuItem>
            )}
            {canEdit && canDelete && <MenuItem divider />}
            {canDelete && (
              <MenuItem className="danger" onClick={this.handleDelete}>
                <FormattedMessage
                  id="DELETE"
                  defaultMessage="Delete"
                  description=""
                />
              </MenuItem>
            )}
          </DropdownButton>
        </ButtonToolbar>
      );
      return (
        <div className={classNames(styles.event, extraClasses)}>
          {editControl}
          {content}
          <hr />
        </div>
      );
    }
  },
);
