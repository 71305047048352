import { Label } from '@certhon/domain-models/lib';
import { tagOptions } from '../../common/list';
import { ParametersOptions } from '../../components/ParameterizedSearch/model';
import { LabelsState } from '../../modules/labels';
import { UsersState } from '../../modules/users';
import matchUserToTerm from '../user/matchUserToTerm';

export default function getFilterOptions(
  usersstate: UsersState,
  labelsstate: LabelsState,
): ParametersOptions {
  const labels = Object.values(labelsstate || {});
  const labelOpts = {
    options: labels.filter(l => !l?.deleted),
    search: (label: Label, value: string) => {
      const lowerCaseValue = value.toLowerCase();
      return !!label?.name?.toLowerCase().includes(lowerCaseValue);
    },
    getValue: (l: any) => {
      return l.recnum.toString();
    },
    getLabel: (l: any) => {
      return l.name;
    },
    getLabelFromValue: (l: any) => labelsstate?.[l]?.name || l,
  };
  const users = Object.values(usersstate || {});
  const userOpts = {
    options: users,
    search: matchUserToTerm,
    getValue: (u: any) => {
      return u.recnum.toString();
    },
    getLabel: (u: any) => {
      return u.name || u.loginname;
    },
    getLabelFromValue: (u: any) =>
      (usersstate?.[u] &&
        (usersstate?.[u]?.name || usersstate?.[u]?.loginname)) ||
      u,
  };
  return {
    parameters: {
      creator: {
        name: 'creator',
        icon: 'user-plus',
        ...userOpts,
      },
      associated: {
        name: 'associated',
        icon: 'users',
        ...userOpts,
      },
      assigned: {
        name: 'assigned',
        icon: 'user',
        ...userOpts,
      },
      performedBy: {
        name: 'performedBy',
        icon: 'user',
        ...userOpts,
      },
      label: {
        ...labelOpts,
        name: 'label',
        icon: 'tag',
      },
      status: {
        name: 'status',
        icon: 'check-circle',
        options: ['open', 'closed'],
      },
      projectTickets: {
        name: 'projectTickets',
        icon: 'home',
        options: ['true', 'false'],
      },
      overdue: {
        name: 'overdue',
        icon: 'clock-o',
        options: ['true', 'false'],
      },
      department: tagOptions.parameters.department,
    },
    small: false,
    noInput: false,
  };
}
