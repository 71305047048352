import React from 'react';
import countingRequestFilter from '../common/counting/countingRequestFilter';
import { createCountingList } from '../common/counting/createCountingList';
import LocationCountingComponent from '../components/LocationCountingComponent/LocationCounting.Component';
import { makeTreeIndex } from '../components/Tree/common';
import useLocations from '../hooks/useLocations';
import useLocationStockParamHash from '../hooks/useLocationStockParamHash';
import { fetchLocations } from '../modules/locations';
import { useCountingRequestStore } from '../stores/countingRequestStore';
import { useCountingStore } from '../stores/countingStore';
import useInitialDispatch from '../utils/useInitalDispatch';

interface LocationCountingContainerProps {
  parentLocationId: string;
  recnum: number;
}
const LocationCountingContainer: React.FC<LocationCountingContainerProps> = ({
  parentLocationId,
  recnum,
}) => {
  const [{ countingRequests }, { fetch }] = useCountingRequestStore();
  const countingRequest = countingRequests?.[recnum];

  React.useEffect(() => {
    fetch(recnum);
  }, [fetch, recnum]);

  const [
    locationStockParam,
    setLocationStockHashParam,
  ] = useLocationStockParamHash(null);

  /*
   data fetching
   */
  useInitialDispatch(fetchLocations);
  // TODO: for performance allow fetching/querying a subtree
  const locations = useLocations();
  const [countingState, { save, fetch: fetchCountings }] = useCountingStore();
  React.useEffect(() => {
    fetchCountings({ locations: [parentLocationId], children: true });
  }, [fetchCountings, parentLocationId]);

  /*
   data processing
   */
  const locationIndex = React.useMemo(() => makeTreeIndex(locations), [
    locations,
  ]);
  const parentLocation = locationIndex[parentLocationId];

  const countingList = React.useMemo(
    () => parentLocation && createCountingList(parentLocation),
    [parentLocation],
  );

  // Disabled because it might be better to show just the list and let the user pick a record to start with
  /* select the first location when selection is empy */
  // React.useEffect(() => {
  //   if (parentLocation && !locationStockParam) {
  //     let loc = parentLocation;
  //     while (loc.children.length) {
  //       loc = loc.children[0];
  //     }
  //     setLocationStockHashParam({
  //       location_id: loc.id,
  //       // FIXME: allow artkel_id = null for empty locations
  //       artikel_id: (loc.stock[0]!.recnum || null) as any,
  //     });
  //   }
  // }, [locationStockParam, parentLocation, setLocationStockHashParam]);

  const [location, stock] = React.useMemo(() => {
    if (locationStockParam && locationIndex) {
      const loc = locationIndex[locationStockParam?.location_id!];
      // index may be emppy
      if (loc) {
        const stock =
          loc.stock.find(s => s.recnum === locationStockParam.artikel_id) ||
          null;
        return [loc, stock] as const;
      }
    }
    return [null, null] as const;
  }, [locationIndex, locationStockParam]);

  /*
   callbacks
   */
  const handleSelectNext = React.useCallback(() => {
    const idx = countingList.findIndex(
      ({ location, stock }) =>
        location.id === locationStockParam?.location_id &&
        (stock?.recnum || null) === locationStockParam?.artikel_id,
    );

    const next = countingList[idx + 1];
    if (next) {
      setLocationStockHashParam({
        location_id: next.location.id,
        artikel_id: next.stock?.recnum || null,
      });
    } else {
      setLocationStockHashParam(null);
    }
  }, [
    countingList,
    locationStockParam?.artikel_id,
    locationStockParam?.location_id,
    setLocationStockHashParam,
  ]);
  const handleSaveCounting = React.useCallback<typeof save>(
    c =>
      save(c)
        .then(handleSelectNext)
        .then(() =>
          fetchCountings({ locations: [parentLocationId], children: true }),
        ),
    [fetchCountings, handleSelectNext, parentLocationId, save],
  );

  const filterFunction = React.useMemo(
    () => countingRequest && countingRequestFilter(countingRequest.filter),
    [countingRequest],
  );

  return (
    <LocationCountingComponent
      onGoNext={handleSelectNext}
      countingRequest={countingRequest}
      parentLocation={parentLocation}
      countingState={countingState}
      saveCounting={handleSaveCounting}
      countingList={countingList}
      selectedLocation={location}
      filterFunction={filterFunction}
      stock={stock}
    />
  );
};

export default LocationCountingContainer;
