import { Artikel, Counting } from '@certhon/domain-models';
import { groupBy } from 'lodash/fp';
import qs from 'qs';
import {
  defaultTo,
  descend,
  lensPath,
  prop,
  set,
  sortWith,
  uniqBy,
  view,
} from 'ramda';
import React, { useReducer } from 'react';
import { AllOrNone, DTO, MakeOptional } from '../common/logic/types';
import { createUsableSingleton } from '../hooks/createUsableSingleton';
import { assertNever } from '../modules/utils';
import { resolveHeaders, resolveUrl } from '../utils';
import { verifyResponse } from '../utils/verifyResponse';

type CountingWithMaybeArtikel = Counting & {
  artikel?: Pick<Artikel, 'subset' | 'volgnummer' | 'description'>;
};

// FIXME: share with api
type FetchCountingsQuery = {
  locations: string[];
  /** also fetch countings for nested child-locations */
  children?: true;
  /** fetch all countings instead of just the last one */
  allCountings?: true;
  /** also fetch artikel information to display */
  artikelInfo?: true;
} & AllOrNone<{ from_date: Date; to_date: Date }>;

function parseCountingDTO({
  created,
  ...rest
}: DTO<CountingWithMaybeArtikel>): CountingWithMaybeArtikel {
  return { created: new Date(created), ...rest };
}

export type InsertableCounting = MakeOptional<
  Pick<
    Counting,
    'amount' | 'amount_input' | 'context' | 'location_id' | 'artikel_id'
  >,
  'amount_input'
>;

export type CountingState = Record<
  string,
  Record<number, CountingWithMaybeArtikel[]>
>;

type CountingAction =
  | { type: 'insert'; data: DTO<CountingWithMaybeArtikel>[] }
  | { type: 'delete'; data: number };

function reducer(
  state: CountingState = {},
  action: CountingAction,
): CountingState {
  if (action.type === 'insert') {
    const countings = action.data.map(parseCountingDTO);
    let newState = { ...state };
    const countingsGroupedByStock = Object.values(
      groupBy((c: Counting) => c.location_id + c.artikel_id, countings),
    );
    for (const stockCountings of countingsGroupedByStock) {
      const { location_id, artikel_id } = stockCountings[0]!;
      const lens = lensPath<CountingState>([
        location_id,
        artikel_id.toString(),
      ]);
      const current: Counting[] = defaultTo(
        [] as Counting[],
        view(lens, state),
      );

      // crude optimisation #1
      // skip if the newest countings are equal and the number is equal or there is only one fetched
      if (
        current[0] &&
        stockCountings[0].created.getTime() === current[0]?.created.getTime() &&
        stockCountings.length === 1
      ) {
        continue;
      }

      // crude optimization #2
      // if the location does not yet exist in the current state, skip the immutable merging/sorting pipeline and simply modify the newState directly
      if (!newState[location_id]) {
        newState[location_id] = { [artikel_id]: stockCountings };
        continue;
      }

      newState = set(
        lens,
        sortWith(
          [descend((c: Counting) => c.created.getTime())],
          uniqBy(prop('created'), [...stockCountings, ...current]),
        ),
        newState,
      );
    }
    return newState;
  } else if (action.type === 'delete') {
    const newState = { ...state };
    delete state[action.data];
    return newState;
  }
  assertNever(action);
}

export const [CountingStoreProvider, useCountingStore] = createUsableSingleton(
  () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [state, dispatch] = useReducer(reducer, {});
    const inf = React.useMemo(
      () => ({
        async fetch(query: FetchCountingsQuery) {
          const response = await fetch(
            resolveUrl(
              `/countings?${qs.stringify(query, {
                arrayFormat: 'brackets',
              })}`,
            ),
            {
              credentials: 'same-origin',
              headers: resolveHeaders()(),
            },
          );
          await verifyResponse(response);
          dispatch({ type: 'insert', data: await response.json() });
        },
        async save(counting: InsertableCounting) {
          const response = await fetch(resolveUrl('/countings'), {
            method: 'POST',
            body: JSON.stringify(counting),
            credentials: 'same-origin',
            headers: resolveHeaders()(),
          });
          await verifyResponse(response);
          dispatch({ type: 'insert', data: [await response.json()] });
        },
        async delete(recnum: number) {
          const response = await fetch(resolveUrl(`/countings/${recnum}`), {
            method: 'delete',
            credentials: 'same-origin',
          });
          await verifyResponse(response);
          dispatch({ type: 'delete', data: recnum });
        },
      }),
      [],
    );
    return React.useMemo(() => [state, inf] as const, [state, inf]);
  },
);
