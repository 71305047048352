import { defineMessages } from 'react-intl';

export default defineMessages({
  USER_HAS_PERFORMED_CHECKS: {
    id: 'ACTIVITY_LIST.USER_HAS_PERFORMED_CHECKS',
    defaultMessage: `{users} {numUsers, plural, one {has} other {have}} {failedChecks, plural, =0 {successfully} other {}} 
    performed { totalChecks, plural,
    =0 { no checks }
    one { a check }
    other { # checks }
    }
    { failedChecks, plural,
      =0 {}
      one {; of which one failed }
      other {; of which # failed}
    }
      `,
    description: ''
  },
  USER_HAS_ENROLLED_LIST: {
    id: 'ACTIVITY_LIST.USER_HAS_ENROLLED_LIST',
    defaultMessage: '{user} has enrolled a new checklist',
    description: ''
  },
  USER_HAS_CLOSED_LIST: {
    id: 'USER_HAS_CLOSED_LIST',
    defaultMessage: '{user} has closed a checklist',
    description: ''
  },
  USER_HAS_REOPENED_LIST: {
    id: 'USER_HAS_REOPENED_LIST',
    defaultMessage: '{user} has reopened a checklist',
    description: ''
  },
  USER_HAS_POSTED_MESSAGE: {
    id: 'USER_HAS_POSTED_MESSAGE',
    defaultMessage: '{user} has posted a message',
    description: ''
  },
  USER_HAS_CREATED_TICKET: {
    id: 'USER_HAS_CREATED_TICKET',
    defaultMessage: '{user} has created a new ticket',
    description: ''
  },
  USER_HAS_UPDATED_TICKET: {
    id: 'USER_HAS_UPDATED_TICKET',
    defaultMessage: '{user} has updated ticket {fields}',
    description: ''
  },
  USER_HAS_UPLOADED_ATTACHMENT: {
    id: 'USER_HAS_UPLOADED_ATTACHMENT',
    defaultMessage: '{user} has uploaded an attachment',
    description: ''
  },
  UNKNOWN_ACTIVITY: {
    id: 'UNKNOWN_ACTIVIITY',
    defaultMessage: 'Unknown activity',
    description: ''
  },
  NO_ACTIVITY: {
    id: 'ACTIVITY_LIST.NO_ACTIVITY',
    defaultMessage: 'No Activity',
    description: ''
  }
});
