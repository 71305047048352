import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { Alert, Form, FormControl, FormGroup } from 'react-bootstrap';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { recover } from '../../modules/user';
import Page from '../Page';

const msg = defineMessages({
  title: {
    id: 'FORGOT.title',
    defaultMessage: 'Recover your credentials',
    description: 'Title for recovery page',
  },
  inputLabel: {
    id: 'FORGOT.INPUT_LABEL',
    defaultMessage: 'Username or email',
    description: 'Label for input field',
  },
  buttonLabel: {
    id: 'FORGOT.BUTTON_LABEL',
    defaultMessage: 'Recover',
    description: 'Label for submit button',
  },
  success: {
    id: 'LOST.SUCCESS_MESSAGE',
    defaultMessage: 'A recovery email has been sent if an account was found',
    description: 'Success message',
  },
  failure: {
    id: 'FORGOT.FAILURE_MESSAGE',
    defaultMessage: 'Something went wrong',
    description:
      'Message describing that something went wrong whilst requesting password recovery',
  },
});

class ForgotComponent extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { value: '' };
  }

  handleChange = event => {
    const { value } = event.target;
    this.setState({ value });
  };

  handleSubmit = async event => {
    event.preventDefault();
    const { value } = this.state;
    const result = await this.props.dispatch(recover(value));

    const state = {
      result: 'success',
    };
    if (result.error) {
      state.result = 'failure';
    }
    this.setState(state);
  };

  render() {
    const { intl } = this.props;
    const { value, result } = this.state;
    const { dispatch } = this.props;
    const validationState = {
      success: 'success',
      failure: 'danger',
    }[result];
    return (
      <Page dispatch={dispatch}>
        <Form className="form-signin" onSubmit={this.handleSubmit}>
          <h3>
            <FormattedMessage {...msg.title} />
          </h3>
          {result && (
            <Alert bsStyle={validationState}>
              <FormattedMessage {...msg[result]} />
            </Alert>
          )}
          <FormGroup>
            <FormControl
              value={value}
              onChange={this.handleChange}
              placeholder={intl.formatMessage(msg.inputLabel)}
            />
          </FormGroup>
          <input
            className="btn btn-lg btn-primary btn-block"
            type="submit"
            value={intl.formatMessage(msg.buttonLabel)}
          />
        </Form>
      </Page>
    );
  }
}

export default connect()(injectIntl(ForgotComponent));
