import { CurrentUser } from '../../modules/user';
import Meeting, { TicketReference } from '../../models/Meeting';
import { WorkState } from '../../modules/works';
import { overlaps } from '../../utils';
import { parseDate } from '../../common/logic/misc';
import { ticketWasOpenAt } from '../../common/logic/ticket';
import { addMeetingSection, updateMeeting } from '../../modules/meetings';
import { add } from 'date-fns';

function createTicketSection(
  title: string,
  description: string,
  tickets: any[],
  ticketTags: string[],
  ticketDiscipline: string | null,
  ticketRemainder: boolean,
  meetingId: string,
  created: Date,
  userId: number,
): any {
  return addMeetingSection({
    meetingId,
    editor: userId,
    edited: new Date(),
    section: {
      ticketTags,
      ticketDiscipline,
      ticketRemainder,
      title,
      content: [description, ...tickets.map(t => '')],
      ticketReferences: tickets.map(
        (t: any): TicketReference => ({
          created,
          userId,
          ticketId: t.id as string,
        }),
      ) as TicketReference[],
    },
  });
}

export function createStartMeetingsActions(
  meeting: Meeting,
  previousMeeting: Meeting | null,
  tickets: any[],
  user: CurrentUser,
  works: WorkState,
): any[] {
  const actions: any[] = [];

  const sections: {
    title: string;
    descr: string;
    ticketTags: string[];
    ticketDiscipline: string | null;
    ticketRemainder: boolean;
    tickets: any[];
  }[] = [
    {
      title: 'General',
      descr: '',
      ticketTags: [],
      ticketDiscipline: null,
      ticketRemainder: false,
      tickets: [],
    },
    {
      title: 'Health & Safety',
      descr: '',
      ticketTags: ['label:Health-and-Safety'],
      ticketDiscipline: null,
      ticketRemainder: false,
      tickets: [],
    },
    {
      title: 'Project',
      descr: '',
      ticketTags: [],
      ticketDiscipline: null,
      ticketRemainder: true,
      tickets: [],
    },
    {
      title: 'Logistics',
      descr: '',
      ticketTags: ['label:Logistics'],
      ticketDiscipline: null,
      ticketRemainder: false,
      tickets: [],
    },
    {
      title: 'Groundwork',
      descr: '',
      ticketTags: ['label:Groundwork'],
      ticketDiscipline: null,
      ticketRemainder: false,
      tickets: [],
    },
    {
      title: 'Greenhouse',
      descr: '',
      ticketTags: [],
      ticketDiscipline: 'K',
      ticketRemainder: false,
      tickets: [],
    },
    {
      title: 'Climate',
      descr: '',
      ticketTags: [],
      ticketDiscipline: 'V',
      ticketRemainder: false,
      tickets: [],
    },
    {
      title: 'Water',
      descr: '',
      ticketTags: [],
      ticketDiscipline: 'W',
      ticketRemainder: false,
      tickets: [],
    },
    {
      title: 'Electro',
      descr: '',
      ticketTags: [],
      ticketDiscipline: 'E',
      ticketRemainder: false,
      tickets: [],
    },
    {
      title: 'Planning',
      descr: '',
      ticketTags: [],
      ticketDiscipline: null,
      ticketRemainder: false,
      tickets: [],
    },
    {
      title: 'Failure costs',
      descr: '',
      ticketTags: [],
      ticketDiscipline: null,
      ticketRemainder: false,
      tickets: [],
    },
    {
      title: 'Commissioning',
      descr: '',
      ticketTags: ['Commissioning'],
      ticketDiscipline: null,
      ticketRemainder: false,
      tickets: [],
    },
    {
      title: 'Evaluation',
      descr: '',
      ticketTags: [],
      ticketDiscipline: null,
      ticketRemainder: false,
      tickets: [],
    },
  ];

  const previousMeetingDateEndProximation: Date | null = previousMeeting?.date
    ? add(parseDate(previousMeeting.edited), { minutes: 15 })
    : null;

  const ticketRemainderSection = sections.find(s => s.ticketRemainder);

  tickets.forEach((ticket: any) => {
    // 0. ticket must be opened OR closed since last meeting
    if (
      ticket.status === 'opened' ||
      (ticket.status === 'closed' &&
        previousMeetingDateEndProximation &&
        (ticketWasOpenAt(previousMeetingDateEndProximation, ticket) ||
          parseDate(ticket.created).getTime() >=
            previousMeetingDateEndProximation.getTime()))
    ) {
      // 1. tickets placed in section with matching tag
      if (ticket?.tags?.length) {
        for (const section of sections) {
          if (overlaps(section.ticketTags, ticket.tags)) {
            section.tickets.push(ticket);
            return;
          }
        }
      }
      const afd = works?.[ticket.werk_id]?.afd || null;
      // 2. tickets placed in section with matching discipline
      if (afd) {
        for (const section of sections) {
          if (section.ticketDiscipline === afd) {
            section.tickets.push(ticket);
            return;
          }
        }
      }
      // 3. tickets placed in section with ticketRemainer-flag
      if (ticketRemainderSection) {
        ticketRemainderSection.tickets.push(ticket);
      }
    }
  });

  const created = new Date();
  const userId = user.recnum;
  const meetingId = meeting.id;

  sections.forEach(
    ({
      title,
      tickets,
      descr,
      ticketDiscipline,
      ticketRemainder,
      ticketTags,
    }) =>
      actions.push(
        createTicketSection(
          title,
          descr,
          tickets,
          ticketTags,
          ticketDiscipline,
          ticketRemainder,
          meetingId,
          created,
          userId,
        ),
      ),
  );

  return actions;
}
