import { Artikel } from '@certhon/domain-models/lib';
import { ReactNode } from 'react';
import artikelCode from '../../common/logic/artikelCode';
import React from 'react';

export default function matchArtikelToTerm(searchString: string) {
  let { key, volgnr, terms } = extractSearchParams(searchString);

  return function innerMatchArtikelToTerm(artikel: Artikel) {
    return (
      terms.every(T => artikel.description.toUpperCase().includes(T)) ||
      (artikel.subset.startsWith(key.toUpperCase()) &&
        volgnr &&
        artikel.volgnummer == volgnr) ||
      (!volgnr && key && artikel.subset.startsWith(key))
    );
  };
}

export function highlightedFields(searchString: string) {
  let { key, volgnr, terms } = extractSearchParams(searchString);

  return function innerHighlightedFields(artikel: Artikel) {
    const description = terms.some(T =>
      artikel.description.toUpperCase().includes(T),
    )
      ? terms.reduce<ReactNode[]>(
          (nodes, term) => {
            return nodes
              .map(node => {
                if (typeof node !== 'string') {
                  return node;
                }

                const NODE = node.toUpperCase();
                let results = [];

                let searchIdx = 0;
                let idx = -1;

                do {
                  idx = NODE.indexOf(term, searchIdx);
                  if (idx > -1) {
                    if (idx - searchIdx) {
                      results.push(node.slice(searchIdx, idx));
                    }

                    results.push(
                      <mark style={{ paddingLeft: 0, paddingRight: 0 }}>
                        {node.slice(idx, idx + term.length)}
                      </mark>,
                    );
                    searchIdx = idx + term.length;
                  } else {
                    if (searchIdx === 0) {
                      // we found nothing
                      return node;
                    }

                    results.push(node.slice(searchIdx));
                  }
                } while (idx > -1);

                return results;
              })
              .flat();
          },
          [artikel.description],
        )
      : artikel.description;

    let code: ReactNode;
    if (
      artikel.subset.startsWith(key.toUpperCase()) &&
      volgnr &&
      artikel.volgnummer === volgnr
    ) {
      code = <mark>{artikelCode(artikel)}</mark>;
    } else if (!volgnr && key && artikel.subset.startsWith(key)) {
      code = (
        <>
          <mark>{artikel.subset.slice(0, key.length)}</mark>
          <span style={{ marginLeft: '-0.2em' }}>
            {artikel.subset.slice(key.length)}
          </span>
          -{artikel.volgnummer.toString().padStart(4, '0')}
        </>
      );
    } else {
      code = artikelCode(artikel);
    }
    return {
      description,
      code,
    };
  };
}

function extractSearchParams(term: string) {
  let { key, volgnr } = /(?<key>\w{0,4})(?:[-\s]?)(?<volgnr>\d{0,4})/.exec(term)
    ?.groups as any;
  if (key) {
    key = key.toUpperCase();
  }

  const terms = term
    .split(' ')
    .map(t => t.toUpperCase())
    .filter(Boolean);
  return { key, volgnr: Number(volgnr), terms };
}
