import { project } from 'ramda';
import React, { useEffect } from 'react';
import Autocomplete from 'react-autocomplete';
import { Col, ControlLabel, FormGroup, Row } from 'react-bootstrap';
import groupByClient, { GroupedByClient } from '../../common/groupByClient';
import { Project } from '../../models/Project';
import { useProject, useProjects } from '../../modules/projects';
import Spinner from '../Spinner';
import { generic } from '../WorkSelection/WorkSelectionComponent';

interface ClientOrProjectSelectionControlProps {
  onChange: (change: {
    projectId: number | null;
    clientId: number | null;
  }) => void;
  clientId: number | null;
  projectId: number | null;
  disabled?: boolean;
}

const ClientOrProjectSelectionControl: React.FC<ClientOrProjectSelectionControlProps> = ({
  projectId,
  clientId,
  onChange,
  disabled = false,
}) => {
  const projects = useProjects();
  const project = useProject(projectId);

  const clients = projects && groupByClient(projects);

  const [clientName, setClientName] = React.useState<string>(
    project?.klant || '',
  );
  const [projectName, setProjectName] = React.useState<string>(
    project?.projectname || '',
  );

  React.useEffect(() => {
    if (
      ((projectId && !projectName) || (clientId && !clientName)) &&
      projects &&
      clients
    ) {
      if (project) {
        setProjectName(project.projectname);
        setClientName(project.klant);
      } else {
        const client = projects.find(p => p.klant_id === clientId);
        if (client) {
          setClientName(client.klant_naam);
        }
      }
    }
  }, [projects]);

  const handleClientValueChanged = React.useCallback(
    (value: string) => {
      if (clientName === value) {
        return;
      }
      setClientName(value);
      const client = clients!.find(c => c.client === value);
      if (client) {
        setProjectName('');
        onChange({ clientId: client.clientId, projectId: null });
      } else {
        setProjectName('');
      }
    },
    [clients, onChange],
  );
  const handleProjectValueChanged = React.useCallback(
    (value: string) => {
      if (projectName === value) {
        return;
      }
      setProjectName(value);
      const project = projects!.find(c => c.projectname === value);
      if (project) {
        onChange({ clientId: null, projectId: project.recnum });
      }
    },
    [clients, onChange],
  );

  if (!clients) {
    return (
      <Row>
        <Spinner />
      </Row>
    );
  }

  return (
    <Row>
      <Col xs={6}>
        <FormGroup>
          <ControlLabel>Client</ControlLabel>
          <Autocomplete
            value={clientName}
            items={clients}
            onChange={({ target: { value } }) =>
              handleClientValueChanged(value)
            }
            inputProps={{ disabled }}
            getItemValue={(client: GroupedByClient<any>) => client.client}
            shouldItemRender={(client: GroupedByClient<any>, term: string) =>
              client.client.toUpperCase().includes(term.toUpperCase())
            }
            onSelect={value => handleClientValueChanged(value)}
            renderItem={(client: GroupedByClient<any>, isHighlighted) => (
              <li className={`item ${isHighlighted ? 'highlighted' : ''}`}>
                <a>{client.client}</a>
              </li>
            )}
            {...generic}
          />
        </FormGroup>
      </Col>
      <Col xs={6}>
        <FormGroup>
          <ControlLabel>Project</ControlLabel>
          <Autocomplete
            value={projectName}
            items={projects}
            onChange={({ target: { value } }) =>
              handleProjectValueChanged(value)
            }
            inputProps={{ disabled }}
            getItemValue={(project: Project) => project.projectname}
            shouldItemRender={(project: Project, term: string) =>
              project.projectname.toUpperCase().includes(term.toUpperCase())
            }
            onSelect={value => handleProjectValueChanged(value)}
            renderItem={(project: Project, isHighlighted) => (
              <li
                className={`item ${isHighlighted ? 'styles.highlighted' : ''}`}
              >
                <a>{project?.projectname}</a>
              </li>
            )}
            {...generic}
          />
        </FormGroup>
      </Col>
    </Row>
  );
};

export default ClientOrProjectSelectionControl;
