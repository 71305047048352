import { AnyAction, Dispatch, Middleware } from 'redux';
import Types from 'Types';
import { getCatalogFileNodeName } from '../components/CatalogContents/catalog';
import { CatalogFile } from '../models/Catalog';
import {
  downloadFileComplete,
  downloadFileError,
  downloadFileInit,
  downloadFileUpdate,
  FileDownloadsAction,
  isDownloadFileAction,
  isDownloadFilesAction,
  isOpenFileAction,
  OpenFileTarget,
} from '../modules/fileDownloads';
import fileStorage from '../services/fileStorage';
import { resolveUrl } from '../utils';

function openArrayBuffer(
  ab: ArrayBuffer,
  target: OpenFileTarget,
  file: CatalogFile,
  lang: string,
) {
  const blob = new Blob([ab], { type: file.mime });
  const url = URL.createObjectURL(blob);
  if (target === 'download') {
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute(
      'download',
      `${getCatalogFileNodeName(file, lang as any)}.${file.extension}`,
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } else {
    window.open(url, target);
  }
}

const MAX_NUM_PARALLEL_JOBS = 5;

interface FileQueueEntry {
  file: CatalogFile & { punch?: boolean };
  promise: Promise<ArrayBuffer>;
  resolve: (ab: ArrayBuffer) => void;
  reject: () => void;
}

function createFileQueueEntry(file: CatalogFile): FileQueueEntry {
  let _resolve: ((ab: ArrayBuffer) => void) | undefined;
  let _reject: (() => void) | undefined;
  const resolve = (ab: ArrayBuffer) => {
    if (!_resolve) {
      throw new Error('resolve not set');
    }
    _resolve(ab);
  };
  const reject = () => {
    if (!_reject) {
      throw new Error('reject not set');
    }
    _reject();
  };

  const promise = new Promise<ArrayBuffer>((resolve, reject) => {
    _resolve = resolve;
    _reject = reject;
  });
  return {
    file,
    promise,
    resolve,
    reject,
  };
}

/**
 * download manager which manages the downloading of multiple files and reports progress to the store.
 *
 * it is statefull and maintains its own internal queue
 */
const createFileDownloadMiddleware: (
  storage: LocalForageDbMethods,
) => Middleware<
  {},
  Types.RootState,
  Dispatch<FileDownloadsAction>
> = storage => store => next => {
  const queue: FileQueueEntry[] = [];
  const active: FileQueueEntry[] = [];

  const downloadDone = () => {
    manageQueue();
  };
  const removeActiveEntry = (entry: FileQueueEntry) => {
    const idx = active.findIndex(q => q === entry);
    active.splice(idx, 1);
  };
  const beginDownload = (queueEntry: FileQueueEntry) => {
    active.push(queueEntry);
    const { file, resolve, reject } = queueEntry;
    const { user } = store.getState();
    if (!user?.accessToken) {
      reject();
      throw new Error('Cant download file without active session');
    }
    const xhr = new XMLHttpRequest();
    xhr.responseType = 'arraybuffer';

    // TODO: check for checksum in local database first, so we dont have to download duplicates

    // handle updates
    xhr.addEventListener('progress', event => {
      next(downloadFileUpdate(file, event.total, event.loaded));
    });

    // handle result
    xhr.addEventListener('load', async () => {
      if (xhr.status !== 200) {
        removeActiveEntry(queueEntry);
        next(downloadFileError(file, xhr.statusText, xhr.status));
        reject();
        return;
      }

      try {
        const arrayBuffer = xhr.response as ArrayBuffer;
        await storage.setItem(file.checksum, arrayBuffer);
        removeActiveEntry(queueEntry);
        resolve(arrayBuffer);
        next(downloadFileComplete(file));
      } catch (e) {
        removeActiveEntry(queueEntry);
        next(downloadFileError(file, (e as any)?.toString(), null));
        reject();
      }
    });

    // handle error
    xhr.addEventListener('error', event => {
      removeActiveEntry(queueEntry);
      next(
        downloadFileError(file, xhr.statusText || 'Unknown error', xhr.status),
      );
    });

    // decrement and manage the queue and
    xhr.addEventListener('loadend', downloadDone);

    xhr.open(
      'GET',
      resolveUrl(`/files/${file.recnum}${file.punch ? '?punch=true' : ''}`),
    );
    xhr.setRequestHeader('Authorization', `Bearer ${user.accessToken}`);
    xhr.send();
    next(downloadFileInit(file));
  };

  // manage queue checks wether or not new parallel download can be started
  function manageQueue() {
    while (active.length < MAX_NUM_PARALLEL_JOBS && queue.length) {
      const entry = queue.shift() as FileQueueEntry;
      beginDownload(entry);
    }
  }

  function insertToQueue(file: CatalogFile): FileQueueEntry {
    let queueItem = queue.find(i => i.file.recnum === file.recnum);
    if (!queueItem) {
      queueItem = active.find(i => i.file.recnum === file.recnum);
    }
    if (queueItem) {
      return queueItem;
    }
    queueItem = createFileQueueEntry(file);
    queue.push(queueItem);
    return queueItem;
  }
  function shiftToFront(queueEntry: FileQueueEntry) {
    const idx = queue.findIndex(q => q === queueEntry);
    queue.unshift(...queue.splice(idx, 1));
  }

  return (action: AnyAction) => {
    if (isDownloadFileAction(action)) {
      const queueItem = insertToQueue(action.payload);
      manageQueue();
      next(action);
      return queueItem.promise;
    } else if (isDownloadFilesAction(action)) {
      const items = action.payload.map(insertToQueue);
      manageQueue();
      next(action);
      return Promise.all(items.map(i => i.promise));
    }

    if (isOpenFileAction(action)) {
      const { file, target, lang } = action.payload;
      const { files } = store.getState();
      let obtainFile: Promise<ArrayBuffer | null> | undefined;

      if (files[file.checksum]) {
        obtainFile = fileStorage.getItem<ArrayBuffer>(file.checksum);
      } else {
        const queueItem = insertToQueue(file);
        shiftToFront(queueItem);
        manageQueue();
        obtainFile = queueItem.promise;
      }
      obtainFile.then(ab => {
        if (ab) {
          openArrayBuffer(ab, target, file, lang);
        }
      });
      next(action);
      return obtainFile;
    }
    return next(action);
  };
};

export default createFileDownloadMiddleware;
