import PropTypes from 'prop-types';
import qs from 'query-string';
import * as R from 'ramda';
import React, { Component } from 'react';
import { Alert, Modal } from 'react-bootstrap';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { replace } from 'redux-first-history';
import { Link } from 'wouter';
import { signIn } from '../../modules/user';
import Page from '../Page';

import styles from './Login.module.scss';

const responseErrorView = R.view(R.lensPath(['payload', 'response', 'error']));

const msg = defineMessages({
  signIn: {
    id: 'LOGIN.SIGN_IN',
    defaultMessage: 'Sign in',
    description: 'Label of sign-in button on login form',
  },
  forgot: {
    id: 'LOGIN.FORGOT',
    defaultMessage: 'Forgot username/password?',
    description: 'label of password or username button',
  },
});

export class LoginComponent extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isSubmitting: false,
      passwordVisible: false,
    };
  }

  static contextTypes = {
    // intl: PropTypes.object.isRequired,
    syncService: PropTypes.object.isRequired,
  };

  redirect() {
    let { r } = qs.parse(window.location.search);
    if (!r) {
      r = '/';
    } else if (Array.isArray(r)) {
      r = r[0] || '/';
    }
    this.props.dispatch(replace(r));
  }

  componentDidMount() {
    if (this.props.user) {
      this.redirect();
    }
  }

  handleSubmit = async event => {
    event.preventDefault();
    const password = event.target.querySelector('[name="password"]').value;
    const username =
      this.props.username ||
      event.target.querySelector('[name="username"]').value;

    const { dispatch } = this.props;

    if (username === '' || password === '') {
      return;
    }

    this.setState({ isSubmitting: true, passwordVisible: false });

    const res = await dispatch(signIn(username, password));

    this.context.syncService.update(true);

    //  dont accept the accesstoken if the user is not a internal user
    if (res.error) {
      const passwordElement = event.target.querySelector('[name="password"]');
      passwordElement.value = '';
      passwordElement.focus();

      this.setState({
        error: true,
        message: responseErrorView(res),
        isSubmitting: false,
      });
    } else {
      this.redirect();
    }
  };

  togglePasswordVisible = event => {
    event.preventDefault();
    this.setState({ passwordVisible: !this.state.passwordVisible });
  };

  getError() {
    const { error, message } = this.state;
    if (error) {
      // TODO: figure out backend error i18n
      return (
        <Alert bsStyle="danger" onDismiss={this.handleAlertDismiss}>
          {message || 'Something went wrong'}
        </Alert>
      );
    }
    return null;
  }

  render() {
    const { intl } = this.props;
    return (
      <form className="form-signin" onSubmit={this.handleSubmit}>
        <h2 className="form-signin-heading">
          <FormattedMessage
            id="LOGIN.PLEASE_SIGN_IN"
            defaultMessage="Please sign in"
            description="Instruction sentence above login form"
          />
        </h2>
        {this.getError()}
        <input
          tabIndex={1}
          className="form-control"
          placeholder="Username"
          name="username"
          disabled={!!this.props.username}
          type="text"
          autoCorrect="off"
          autoCapitalize="none"
          defaultValue={this.props.username || ''}
        />
        <div className={styles.passwordWrapper}>
          <button
            type="button"
            tabIndex={3}
            className={styles.toggleVisibility}
            onClick={this.togglePasswordVisible}
          >
            {!this.state.passwordVisible ? (
              <i className="fa fa-eye" />
            ) : (
              <i className="fa fa-eye-slash" />
            )}
          </button>
          <input
            className="form-control"
            tabIndex={2}
            placeholder="Password"
            name="password"
            type={this.state.passwordVisible ? 'text' : 'password'}
          />
        </div>
        <input
          tabIndex={4}
          className="btn btn-lg btn-primary btn-block"
          type="submit"
          value={intl.formatMessage(msg.signIn)}
          disabled={this.state.isSubmitting}
        />
      </form>
    );
  }
}

export class LoginPage extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <Page {...this.props}>
        <LoginComponent {...this.props} />
        <Link to="/forgot" style={{ width: '100%', display: 'block' }}>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a>
            <FormattedMessage {...msg.forgot}>
              {txt => <button className="btn btn-link btn-block">{txt}</button>}
            </FormattedMessage>
          </a>
        </Link>
      </Page>
    );
  }
}
class LoginDialogComponent extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  render() {
    return (
      <Modal show={this.props.show}>
        <Modal.Body>
          <LoginComponent {...this.props} />
        </Modal.Body>
      </Modal>
    );
  }
}

export default connect(R.pick(['user']))(injectIntl(LoginPage));
export const LoginDialog = connect()(injectIntl(LoginDialogComponent));
