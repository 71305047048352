import PropTypes from 'prop-types';
import { identity, prop, values } from 'ramda';
import * as React from 'react';
import { connect } from 'react-redux';
import { isListClosed } from '../../common/logic/list';
import { parseDate } from '../../common/logic/misc';
import MyTasksComponent from '../../components/MyTasksComponent/index';
import { fetchLists } from '../../modules/lists';
import { fetchTickets } from '../../modules/tickets';
import { fetchUsers } from '../../modules/users';
import { fetchWorks } from '../../modules/works';
import ignoreRejection from '../../utils/ignoreRejection';

export class MyTasksContainerComponent extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  componentDidMount() {
    ignoreRejection(
      this.props.dispatch(fetchWorks()),
      this.props.dispatch(fetchUsers()),
      this.props.dispatch(fetchLists()),
      this.props.dispatch(fetchTickets()),
    );
  }

  render() {
    const { works, dispatch, users, lists, user } = this.props;

    const assWorkIds = values(works)
      .filter(w => w.users && w.users.includes(user.recnum))
      .map(prop('recnum'));
    const filterredChecklists = values(lists)
      .filter(list => list.end_date)
      .filter(
        list => parseDate(list.end_date) > Date.now() || !isListClosed(list),
      )

      .filter(
        list =>
          assWorkIds.includes(list.werk_id) ||
          (list.associated_users &&
            list.associated_users.includes(user.recnum)),
      );

    return (
      <MyTasksComponent
        checklists={filterredChecklists}
        lists={lists}
        users={users}
        dispatch={dispatch}
      />
    );
  }
}

export default connect(identity)(MyTasksContainerComponent);
