import regEscape from 'escape-string-regexp';
import { complement } from 'ramda';
import { parseParameters } from '../../components/ParameterizedSearch/model';
import { Project } from '../../models/Project';
import { CurrentUser } from '../../modules/user';
import combineFilters from '../../utils/combineFilters';

export default function createProjectFilter(search: string, user: CurrentUser) {
  const { parameters, rest } = parseParameters(search);

  const projectFilters: Array<(p: Project) => boolean> = [];

  const following = parameters.filter(({ name }) => name === 'following');
  if (following.length) {
    let followFilter = (p: Project) => p.users.includes(user.recnum);
    if (following[0].value === 'false') {
      followFilter = complement(followFilter);
    }
    projectFilters.push(followFilter);
  }
  const archived = parameters.filter(({ name }) => name === 'archived');
  if (archived.length && archived[0].value === 'true') {
    projectFilters.push((p: Project) => {
      return p.archived === true;
    });
  } else if (!archived.length || archived[0].value === 'false') {
    projectFilters.push((p: Project) => {
      return p.archived === false;
    });
  }

  if (rest) {
    const regex = new RegExp(regEscape(rest), 'i');
    projectFilters.push(
      p =>
        regex.test(p.projectname) ||
        regex.test(p.id) ||
        regex.test(p.klant) ||
        regex.test(p.klant_naam),
    );
  }

  return combineFilters(projectFilters);
}
