import * as React from 'react';
import { createPortal } from 'react-dom';
import { MenuItem } from 'react-bootstrap';
import useWindowWidth from '../../hooks/useWindowWidth';
import classNames from 'classnames';

interface ContextMenuInterface {
  onContextMenuHandler: React.MouseEventHandler;
  node: React.ReactNode;
}

interface ContextMenuActionItem {
  title?: string;
  label: string;
  type: 'action';
  action: () => void;
  disabled?: boolean;
  className?: 'danger';
}
interface ContextMenuDividerItem {
  type: 'divider';
}
interface ContextMenuHeaderItem {
  type: 'header';
  label: string;
}
type ContextMenuItem =
  | ContextMenuActionItem
  | ContextMenuDividerItem
  | ContextMenuHeaderItem;

export const useContextMenu = (
  contextMenuItems: ContextMenuItem[],
): ContextMenuInterface => {
  const [active, setActive] = React.useState<false | { x: number; y: number }>(
    false,
  );

  const deactivateFunction = React.useCallback<React.MouseEventHandler>(
    ev => {
      console.log('deactivate', ev.type);

      setActive(false);
      document.removeEventListener('click', deactivateFunction as any);
      document.removeEventListener('contextmenu', deactivateFunction as any);
    },
    [setActive],
  );

  const windowWidth = useWindowWidth();

  const contextMenuHandler = React.useCallback<React.MouseEventHandler>(
    e => {
      e.stopPropagation();
      e.preventDefault();
      setActive({ x: e.pageX, y: e.pageY });
      document.addEventListener('click', deactivateFunction as any, {
        // capture: true,
      });
      document.addEventListener('contextmenu', deactivateFunction as any, {
        capture: true,
      });
    },
    [deactivateFunction],
  );

  return {
    onContextMenuHandler: contextMenuHandler,
    node: active
      ? createPortal(
          <div
            className={classNames(
              'dropdown open',
              active.x > windowWidth * 0.75 && 'pull-right',
            )}
            style={{
              position: 'absolute',
              display: 'block',
              top: active?.y,
              left: active?.x,
            }}
          >
            <ul className="dropdown-menu">
              {contextMenuItems.map(i =>
                i.type === 'action' ? (
                  <MenuItem
                    onClick={i.action}
                    title={i.title}
                    className={i.className}
                    disabled={i.disabled}
                  >
                    {i.label}
                  </MenuItem>
                ) : i.type === 'divider' ? (
                  <MenuItem divider />
                ) : i.type === 'header' ? (
                  <MenuItem header>{i.label}</MenuItem>
                ) : null,
              )}
            </ul>
          </div>,
          document.body,
        )
      : null,
  };
};
