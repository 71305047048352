import * as Sentry from '@sentry/react';

if (process.env.NODE_ENV !== 'development') {
  const dsn = 'https://ab4ab2350e9146509dcbd9b71fb2de96@sentry.io/248957';
  Sentry.init({
    dsn,
    environment: process.env.NODE_ENV,
  });

  window.Sentry = Sentry;
}
