import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import { identity, prop, values } from 'ramda';
import * as React from 'react';
import { connect } from 'react-redux';
import {
  createActivityIterator,
  parseDate,
  takeFromIterableUntil,
} from '../../common/logic/misc';
import { isUserInvolvedWithTicket } from '../../common/logic/ticket';
import MyActivityComponent from '../../components/MyActivityComponent/index';
import { fetchLists } from '../../modules/lists';
import { fetchTickets } from '../../modules/tickets';
import { fetchUsers } from '../../modules/users';
import { fetchWorks } from '../../modules/works';
import ignoreRejection from '../../utils/ignoreRejection';

export class MyDashboardContainerComponent extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  componentDidMount() {
    ignoreRejection(
      this.props.dispatch(fetchWorks()),
      this.props.dispatch(fetchUsers()),
      this.props.dispatch(fetchLists()),
      this.props.dispatch(fetchTickets()),
    );
  }

  render() {
    const { works, dispatch, users, lists, user, tickets } = this.props;

    const assWorkIds = values(works)
      .filter(w => w.users && w.users.includes(user.recnum))
      .map(prop('recnum'));
    const filterredTickets = values(tickets).filter(
      t =>
        t.id && // HACK: filter out the weird redux-persist properties
        (assWorkIds.includes(t.werk_id) ||
          isUserInvolvedWithTicket(user.recnum, t)),
    );
    const filterredChecklists = values(lists)
      .filter(list => !!list.werk_id)
      .filter(list => !list.deleted)
      .filter(
        list =>
          list.creator_user_id === user.recnum ||
          assWorkIds.includes(list.werk_id) ||
          // eslint-disable-next-line eqeqeq
          (list.associated_users &&
            list.associated_users.some(u => u == user.recnum)),
      );

    // console.log({ lists: pluck('name', filterredChecklists) });
    // console.log({ lists: Object.values(lists).map(l => [l.name, l.users]) });

    let activity = false;
    try {
      const activityIterator = createActivityIterator({
        lists: filterredChecklists,
        tickets: filterredTickets,
      });
      const beginningOfLastMonth = DateTime.local()
        .startOf('month')
        .minus({ month: 1 })
        .toJSDate();
      activity = takeFromIterableUntil(
        activityEvent =>
          beginningOfLastMonth > parseDate(activityEvent.timestamp),
        activityIterator,
      );
    } catch (error) {
      console.error(error); // tslint:disable-line
    }

    return (
      <MyActivityComponent
        activity={activity}
        tickets={tickets}
        checklists={filterredChecklists}
        lists={lists}
        users={users}
        works={works}
        dispatch={dispatch}
      />
    );
  }
}

export default connect(identity)(MyDashboardContainerComponent);
