import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import Types from 'Types';
import ProjectDetailsPage from '../../components/ProjectDetailsPage';
import Meeting from '../../models/Meeting';
import { Project } from '../../models/Project';
import {
  CatalogState,
  fetchProjectCatalogDetails,
} from '../../modules/catalogs';
import { FileDownloadsState } from '../../modules/fileDownloads';
import { FilesState } from '../../modules/files';
import { filter } from '../../modules/filters';
import { fetchLabels, LabelsState } from '../../modules/labels';
import { fetchMeetings, MeetingState } from '../../modules/meetings';
import { fetchProject, ProjectState } from '../../modules/projects';
import { sorting } from '../../modules/sorting';
import { fetchTickets } from '../../modules/tickets';
import { UserState } from '../../modules/user';
import { fetchUsers, UsersState } from '../../modules/users';
import { fetchWorks, Work, WorkState } from '../../modules/works';
import assertAndParseInt from '../../utils/assertAndParseInt';
import useInitialDispatch from '../../utils/useInitalDispatch';

export interface Props {
  params: { projectId: string };
  projects: ProjectState;
  tickets: any;
  works: WorkState;
  users: UsersState;
  filter: string;
  user: UserState;
  dispatch: Dispatch;
  catalogs: CatalogState;
  fileDownloads: FileDownloadsState;
  files: FilesState;
  labels: LabelsState;
  meetings: MeetingState;
}
const FILTER_KEY = 'PROJECT_DETAILS_FILTER';

const ProjectDetailsContainer: React.FC<Props> = ({
  projects,
  works,
  tickets,
  catalogs,
  meetings,
  fileDownloads,
  labels,
  params,
  ...rest
}) => {
  const projectId = assertAndParseInt(params.projectId);

  useInitialDispatch(
    fetchUsers,
    fetchMeetings,
    () => fetchProject(projectId),
    fetchLabels,
    () => fetchWorks({ projectId }),
    () => fetchProjectCatalogDetails(projectId),
    () => fetchTickets({ project_id: projectId }),
  );
  const { dispatch } = rest;
  let project: Project | undefined;
  if (projects) {
    project = projects[projectId];
  }

  let catalog;
  if (catalogs) {
    catalog = Object.values(catalogs).find(c => c?.project_id === projectId);
  }

  const handleChangeFilter = React.useCallback(
    (newFilter: string | null) => {
      dispatch(filter(FILTER_KEY, newFilter));
    },
    [dispatch],
  );
  const handleChangeSorting = React.useCallback(
    (scope: 'tickets', newFilter: string | null) => {
      dispatch(sorting(scope, newFilter));
    },
    [dispatch],
  );

  const projectWorks = React.useMemo<null | Work[]>(() => {
    if (works) {
      return (Object.values(works) as Work[]).filter(
        // WTF ts
        w => w.project_id === projectId,
      );
    }
    return null;
  }, [projectId, works]);

  const projectMeetings = React.useMemo<null | Meeting[]>(() => {
    if (meetings) {
      return Object.values(meetings).filter(
        // WTF ts
        m => m.project_id === projectId,
      );
    }
    return null;
  }, [meetings, projectId]);

  const workIds = projectWorks?.map(w => w.recnum);

  const projectTickets = Object.values(tickets).filter(
    (t: any) => t.project_id === projectId || workIds?.includes(t.werk_id),
  );

  return (
    <ProjectDetailsPage
      project={project || null}
      id={projectId}
      meetings={projectMeetings}
      handleChangeSorting={handleChangeSorting}
      works={projectWorks}
      onChangeFilter={handleChangeFilter}
      catalog={catalog}
      fileDownloads={fileDownloads}
      sorting={sorting}
      labels={labels}
      tickets={projectTickets}
      {...rest}
    />
  );
};

function mapStateToProps(state: Types.RootState) {
  return {
    projects: state.projects,
    works: state.works,
    users: state.users,
    user: state.user,
    tickets: state.tickets,
    filter: state.filters[FILTER_KEY],
    sorting: state.sorting,
    catalogs: state.catalogs,
    fileDownloads: state.fileDownloads,
    files: state.files,
    labels: state.labels,
    meetings: state.minutes,
  };
}

export default connect(mapStateToProps)(ProjectDetailsContainer);
