import { stringify } from 'query-string';
import * as R from 'ramda';
import { useSelector } from 'react-redux';
import { CALL_API } from 'redux-api-middleware';
import { Project } from '../models/Project';
import { mapByRecnum, resolveHeaders, resolveUrl } from '../utils';
import { softAssertNever } from './utils';
import Types from 'Types';

export interface RequestFetchProjectsAction {
  type: 'REQUEST_FETCH_PROJECTS';
}
export interface SuccessFetchProjectsAction {
  type: 'SUCCESS_FETCH_PROJECTS';
  payload: Project[];
  meta: { params?: FetchProjectsParams };
}
export interface FailureFetchProjectsAction {
  type: 'FAILURE_FETCH_PROJECTS';
}

type FetchProjectsParams = {
  q?: string;
  archived?: string;
};

export function fetchProjects(params?: FetchProjectsParams): any {
  let query = '';
  if (params) {
    query = `?${stringify(params)}`;
  }
  return {
    [CALL_API]: {
      endpoint: resolveUrl(`/projects${query}`),
      method: 'GET',
      headers: resolveHeaders(),
      types: [
        'REQUEST_FETCH_PROJECTS',
        { type: 'SUCCESS_FETCH_PROJECTS', meta: { params } },
        'FAILURE_FETCH_PROJECTS',
      ],
    },
  };
}
export interface RequestFetchProjectAction {
  type: 'REQUEST_FETCH_PROJECT';
}
export interface SuccessFetchProjectAction {
  type: 'SUCCESS_FETCH_PROJECT';
  payload: Project;
}
export interface FailureFetchProjectAction {
  type: 'FAILURE_FETCH_PROJECT';
}
export function fetchProject(projectRecnum: number): any {
  return {
    [CALL_API]: {
      endpoint: resolveUrl(`/projects/${projectRecnum}`),
      method: 'GET',
      headers: resolveHeaders(),
      types: [
        'REQUEST_FETCH_PROJECT',
        'SUCCESS_FETCH_PROJECT',
        'FAILURE_FETCH_PROJECT',
      ],
    },
  };
}

export interface RequestUpdateProjectAction {
  type: 'REQUEST_UPDATE_PROJECT';
}
export interface SuccessUpdateProjectAction {
  type: 'SUCCESS_UPDATE_PROJECT';
  payload: Project;
}
export interface FailureUpdateProjectAction {
  type: 'FAILURE_UPDATE_PROJECT';
}

interface UpdateProject {
  associate?: number;
  dissociate?: number;
  archived?: boolean;
}

export function updateProject(
  projectId: number,
  instruction: UpdateProject,
): any {
  return {
    [CALL_API]: {
      endpoint: resolveUrl(`/projects/${projectId}`),
      method: 'PATCH',
      body: JSON.stringify(instruction),
      headers: resolveHeaders(),
      types: [
        'REQUEST_UPDATE_PROJECT',
        'SUCCESS_UPDATE_PROJECT',
        'FAILURE_UPDATE_PROJECT',
      ],
    },
  };
}

export const actions = {
  associateProjectUser: updateProject,
  fetchProjects,
};

export type ProjectAction =
  | RequestFetchProjectAction
  | SuccessFetchProjectAction
  | FailureFetchProjectAction
  | RequestFetchProjectsAction
  | SuccessFetchProjectsAction
  | FailureFetchProjectsAction
  | RequestUpdateProjectAction
  | SuccessUpdateProjectAction
  | FailureUpdateProjectAction;

export type ProjectState = {
  [recnum: string]: Project | undefined;
} | null;

export default function projects(
  state: ProjectState = null,
  action: ProjectAction,
): ProjectState {
  if (action.type === 'SUCCESS_FETCH_PROJECTS') {
    if (action.meta?.params?.q) {
      // when user performs a queried search
      //  we dont store the results
      return state;
    }

    const newProjects = mapByRecnum(action.payload);
    let matchFilter: (p: Project) => boolean = p => p.archived === false;
    const archived =
      action.meta?.params?.archived && JSON.parse(action.meta.params.archived);
    if (typeof archived === 'boolean') {
      matchFilter = (p: Project) => p.archived === archived;
    }
    const prunedState = R.filter((p: Project) => {
      return newProjects[p.recnum] || !matchFilter(p);
    }, (state || {}) as { [recnum: string]: Project });

    return { ...prunedState, ...newProjects };
  } else if (
    action.type === 'SUCCESS_UPDATE_PROJECT' ||
    action.type === 'SUCCESS_FETCH_PROJECT'
  ) {
    return { ...state, [action.payload.recnum]: action.payload };
  } else if (
    action.type === 'FAILURE_FETCH_PROJECT' ||
    action.type === 'REQUEST_FETCH_PROJECT' ||
    action.type === 'FAILURE_FETCH_PROJECTS' ||
    action.type === 'REQUEST_FETCH_PROJECTS' ||
    action.type === 'FAILURE_UPDATE_PROJECT' ||
    action.type === 'REQUEST_UPDATE_PROJECT'
  ) {
    return state;
  }
  softAssertNever(action);
  return state;
}

export function useProjectMap() {
  return useSelector<
    Types.RootState,
    Record<number, Project | undefined> | null
  >(s => s.projects);
}

export function useProjects(): null | Project[] {
  const projectMap = useProjectMap();
  return projectMap ? (Object.values(projectMap) as any) : null;
}
export function useProject(id: number | null): Project | null {
  const projectMap = useProjectMap();
  return (id && projectMap?.[id]) || null;
}
