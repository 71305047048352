import { softAssertNever } from './utils';

// focus a field
export interface FocusFieldAction {
  type: 'FOCUS_FIELD';
  payload: {
    listId: string;
    focussedField: string;
  };
}
export const focusField = (
  listId: string,
  focussedField: string
): FocusFieldAction => ({
  type: 'FOCUS_FIELD',
  payload: { listId, focussedField }
});

// blur a field
export interface BlurAction {
  type: 'BLUR';
}
export const blur = (): BlurAction => ({ type: 'BLUR' });

// pop out a field
export interface PopOutFieldAction {
  type: 'POP_OUT_FIELD';
  payload: { poppedOutField: string };
}
export const popOutField = (poppedOutField: string): PopOutFieldAction => ({
  type: 'POP_OUT_FIELD',
  payload: { poppedOutField }
});

// state

export interface EditorState {
  focussedField: string | null;
  poppedOutFieldField: string | null;
  listId: string | null;
}

const INITIAL_STATE: EditorState = {
  focussedField: null,
  poppedOutFieldField: null,
  listId: null
};

export type EditorAction = BlurAction | FocusFieldAction | PopOutFieldAction;

export default function editor(
  state: EditorState = INITIAL_STATE,
  action: EditorAction
): EditorState {
  if (action.type === 'BLUR') {
    return { ...state, focussedField: null, listId: null };
  } else if (action.type === 'FOCUS_FIELD') {
    return { ...state, ...action.payload };
  } else if (action.type === 'POP_OUT_FIELD') {
    return { ...state, ...action.payload };
  }
  softAssertNever(action);
  return state;
}
