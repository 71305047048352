import { AnyAction, Reducer } from 'redux';

export interface ReplaceStateAction {
  type: 'REPLACE_STATE';
  payload: any;
}

export default function replaceState(reducer: Reducer) {
  return (state: any, action: AnyAction) => {
    if (action.type === 'REPLACE_STATE') {
      return reducer(action.payload, action);
    }
    return reducer(state, action);
  };
}
