import React, { Component } from 'react';
import {
  Button,
  ControlLabel,
  FormControl,
  FormGroup,
  Modal,
} from 'react-bootstrap';

import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';
import Spinner from '../Spinner';
import msgs from './msgs';

function encodePosition(obj: any) {
  if (obj === null || !(obj instanceof Object)) {
    return obj;
  }
  const temp: any = obj instanceof Array ? [] : {};
  // tslint:disable-next-line
  for (var key in obj) {
    temp[key] = encodePosition(obj[key]);
  }
  return temp;
}

function failureMessage({ code }: GeolocationPositionError) {
  if (code === 1) {
    return <FormattedMessage {...msgs.failureAccessDenied} />;
  } else if (code === 2) {
    return <FormattedMessage {...msgs.failurePositionUnavailable} />;
  } else if (code === 3) {
    return <FormattedMessage {...msgs.failureTimeout} />;
  } else {
    return <FormattedMessage {...msgs.failureUnknown} />;
  }
}

interface Props {
  onCancelGeotag: () => void;
  onAddGeotag: (data: { position: GeolocationPosition; label: string }) => void;
  show: boolean;
  intl: IntlShape;
}

export default injectIntl(
  class GeotagPanel extends Component<
    Props,
    {
      active: boolean;
      position: null | GeolocationPosition;
      error: null | GeolocationPositionError;
      label: string;
    }
  > {
    watchId: number | null = null;

    constructor(props: Props) {
      super(props);

      if (props.show) {
        this.begin();
      }
      this.state = {
        position: null,
        error: null,
        label: '',
        active: false,
      };
    }

    componentWillReceiveProps(nextProps: Props) {
      if (!this.props.show && nextProps.show) {
        this.begin();
      }
    }

    componentWillUnmount() {
      this.stop();
    }

    begin = () => {
      if (this.watchId) {
        navigator.geolocation.clearWatch(this.watchId);
      }
      this.watchId = navigator.geolocation.watchPosition(
        this.handleSuccessfullLocation,
        this.handleFailedLocation,
        { enableHighAccuracy: true },
      );
      this.setState({ active: true, error: null, position: null, label: '' });
    };

    stop() {
      if (this.watchId) {
        navigator.geolocation.clearWatch(this.watchId);
        this.watchId = null;
      }
      this.setState({ active: false });
    }

    handleSuccessfullLocation: PositionCallback = position => {
      this.setState({ position });
    };

    handleFailedLocation: PositionErrorCallback = error => {
      this.setState({ error });
    };

    handleCancel = () => {
      this.stop();
      this.props.onCancelGeotag();
    };
    handleSubmit = () => {
      const { position, label } = this.state;
      this.stop();
      this.props.onAddGeotag({
        position: encodePosition(position),
        label,
      });
    };

    render() {
      const { show, intl } = this.props;
      const { position, error, label, active } = this.state;
      return (
        <Modal onHide={this.handleCancel} show={show}>
          <Modal.Header>
            <Modal.Title>Geotag toevoegen</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <dl className="dl-horizontal">
              {!position && !error && <Spinner />}
              <dt>
                <FormattedMessage {...msgs.status} />
              </dt>
              <dd>
                {!active ? (
                  <FormattedMessage {...msgs.statusInactive} />
                ) : error ? (
                  failureMessage(error!)
                ) : position ? (
                  <FormattedMessage {...msgs.statusImproveAccuracy} />
                ) : (
                  <FormattedMessage {...msgs.statusObtainingPosition} />
                )}
              </dd>
              <dt>
                <FormattedMessage {...msgs.accuracy} />
              </dt>
              <dd>
                {position?.coords?.accuracy
                  ? `${position.coords.accuracy} meter`
                  : '-'}
              </dd>
            </dl>
            <FormGroup>
              <ControlLabel>
                <FormattedMessage {...msgs.description} />
              </ControlLabel>
              <FormControl
                value={label}
                onChange={
                  (event: any) => this.setState({ label: event.target.value }) // fixme
                }
                type="text"
                // input="textarea"
                placeholder={intl.formatMessage(msgs.descriptionPlaceholder)}
              />
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleCancel}>
              <FormattedMessage {...msgs.cancel} />
            </Button>
            <Button
              bsStyle="primary"
              disabled={!position}
              onClick={this.handleSubmit}
            >
              <FormattedMessage {...msgs.add} />
            </Button>
          </Modal.Footer>
        </Modal>
      );
    }
  },
);
