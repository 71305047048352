import { defineMessages } from 'react-intl';

const msg = defineMessages({
  FORM_CUSTOMER: {
    id: 'WORKS_DETILS_COMPONENT_WORK_SELECTION.FORM_CUSTOMER',
    defaultMessage: 'Customer',
    description: '',
  },
  FORM_PROJECT: {
    id: 'WORKS_DETILS_COMPONENT_WORK_SELECTION.FORM_PROJECT',
    defaultMessage: 'Project',
    description: '',
  },
  FORM_WORK: {
    id: 'WORKS_DETILS_COMPONENT_WORK_SELECTION.FORM_WORK',
    defaultMessage: 'Work item',
    description: '',
  },
});

export default msg;
