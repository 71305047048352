import React from 'react';
import { useDispatch } from 'react-redux';

// TODO: use dom to show error rather than `alert`
// TODO: Check connectivity before suggesting internet is unavailable
export function useDispatchWithErrorNotifictions() {
  const dispatch = useDispatch();
  return React.useCallback(
    async (action: any) => {
      try {
        const res = await dispatch(action);
        if (res.error) {
          alert(`Error: ${res?.payload?.message}
Contolleer de internetverbinding of neem contact op`);
        }
        return res;
      } catch (e) {
        console.error(e);
        alert(e);
        throw e;
      }
    },
    [dispatch],
  );
}
