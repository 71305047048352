import classNames from 'classnames';
import * as React from 'react';
import { Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import IssueReportDialog from './IssueReportDialog';
import styles from './ReportButton.module.scss';

export interface IReportButtonState {
  show: boolean;
}

class ReportButton extends React.Component<
  { className?: string },
  IReportButtonState
> {
  state = { show: false };

  handleHide = () => {
    this.setState({ show: false });
  };
  handleToggle = () => {
    this.setState({ show: !this.state.show });
  };
  render() {
    return (
      <>
        <Button
          className={classNames(styles.root, this.props.className)}
          bsStyle="link"
          onClick={this.handleToggle}
        >
          <i className="fa fa-bullhorn" />{' '}
          <FormattedMessage
            id="REPORT_BUTTON.LABEL"
            defaultMessage="Report bug"
          />
        </Button>
        <IssueReportDialog show={this.state.show} onHide={this.handleHide} />
      </>
    );
  }
}

export default ReportButton;
