import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'wouter';
import { useLocationProperty } from 'wouter/use-location';

/**
 * ```js
 * // Usage
 * const App = () => {
 *   const [searchParam, setSearchParam] = useSearchParamState<string>('query', '');
 *
 *   const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
 *     setSearchParam(event.target.value);
 *   };
 *
 *   return (
 *     <div>
 *       <input type="text" value={searchParam} onChange={handleChange} />
 *       <p>Search parameter: {searchParam}</p>
 *     </div>
 *   );
 * };
 *
 * export default App;
 * ```
 */
export default function useHashParam(
  initialValue: string,
): [string, (value: string, options?: { replace?: boolean }) => void] {
  const [, go] = useLocation();
  // Get the initial value from the URL hash or use the provided initialValue
  const getInitialValue = () => {
    const hashValue = window.location.hash.slice(1); // Remove the '#' symbol
    return hashValue ? decodeURIComponent(hashValue) : initialValue;
  };
  const hash = useLocationProperty(() =>
    window.location.hash.replace(/^#/, ''),
  );

  const [stringValue, setStringValue] = useState(getInitialValue);

  useEffect(() => {
    if (hash !== stringValue) {
      setStringValue(hash);
    }
  }, [hash]);

  const setHash = useCallback<typeof go>(
    (value, options: any) => {
      go('#' + value, options);
    },
    [go],
  );

  return [stringValue, setHash];
}
