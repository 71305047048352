import Types from 'Types';
import * as React from 'react';
import { useSelector } from 'react-redux';
import * as Showdown from 'showdown';
import { constructTicketLexes } from '../components/TicketDetails/constructLexes';
import { LabelsState } from '../modules/labels';
import { StoreTicket } from '../modules/tickets';
import { UsersState } from '../modules/users';
import ReactMde from '../react-mde';

const ReactMdeTestPage: React.FC<{}> = () => {
  const [value, setValue] = React.useState<string>('');
  const [preview, setPreview] = React.useState<boolean>(false);

  const [users, labels, tickets] = useSelector<
    Types.RootState,
    [UsersState, LabelsState, StoreTicket[]]
  >(s => {
    const tickets: StoreTicket[] = s.tickets
      ? Object.values(s.tickets as any[]).filter(
          (t: any) => t.project_id === 3773,
        )
      : [];
    return [s.users, s.labels, tickets];
  });

  const lexes = React.useMemo(
    () => constructTicketLexes(users, labels, tickets),
    [labels, tickets, users],
  );

  const converter = React.useMemo(
    () =>
      new Showdown.Converter({
        tables: true,
        simplifiedAutoLink: true,
        strikethrough: true,
        tasklists: true,
      }),
    [],
  );

  const handleTabChange = (tab: 'write' | 'preview') => {
    setPreview(tab === 'preview');
  };

  // const save: SaveImageHandler = async function*(
  //   data: ArrayBuffer,
  //   file: Blob,
  // ) {
  //   // Promise that waits for "time" milliseconds
  //   const wait = function(time: number) {
  //     return new Promise((a, r) => {
  //       setTimeout(() => a(undefined), time);
  //     });
  //   };

  //   // Upload "data" to your server
  //   // Use XMLHttpRequest.send to send a FormData object containing
  //   // "data"
  //   // Check this question: https://stackoverflow.com/questions/18055422/how-to-receive-php-image-data-over-copy-n-paste-javascript-with-xmlhttprequest

  //   await wait(2000);
  //   // yields the URL that should be inserted in the markdown
  //   yield 'https://picsum.photos/300';
  //   await wait(2000);

  //   // returns true meaning that the save was successful
  //   return true;
  // };

  return (
    <div className="container">
      <ReactMde
        onChange={setValue}
        onTabChange={handleTabChange}
        value={value}
        generateMarkdownPreview={(markdown: string) =>
          Promise.resolve(converter.makeHtml(markdown))
        }
        lexes={lexes}
        selectedTab={preview ? 'preview' : 'write'}
        // paste={{
        //   saveImage: save,
        // }}
      />
    </div>
  );
};

export default ReactMdeTestPage;
