import * as Sentry from '@sentry/react';
import * as React from 'react';
import {
  Alert,
  Button,
  Checkbox,
  ControlLabel,
  FormControl,
  FormGroup,
  Modal,
} from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Link } from 'wouter';
import loaderSrc from '../../assets/loader.gif';
import {
  reportIssue,
  ReportIssueUserInput,
} from '../../middleware/IssueReportingMiddleware';

export interface IssueReportDialogProps {
  show: boolean;
  onHide: () => void;
  dispatch: Dispatch;
  online: boolean;
}

export interface IssueReportDialogState {
  userInput: ReportIssueUserInput;
  loading: boolean;
  error: string | null;
  success: boolean;
  id?: string;
}

const defaultState = {
  userInput: {
    message: '',
    title: '',
    includeData: true,
    includeScreenshot: false,
  },
  loading: false,
  success: false,
  error: null,
  id: undefined,
};

class IssueReportDialog extends React.Component<
  IssueReportDialogProps,
  IssueReportDialogState
> {
  state = defaultState;

  handleChange = (event: React.FormEvent<FormControl>) => {
    const { name, value, checked } = event.target as HTMLInputElement;
    const userInput: ReportIssueUserInput = { ...this.state.userInput };

    if (name === 'title') {
      userInput.title = value;
    } else if (name === 'message') {
      userInput.message = value;
    } else if (name === 'includeData') {
      userInput.includeData = checked;
    } else if (name === 'includeScreenshot') {
      userInput.includeScreenshot = checked;
    }
    this.setState({ userInput });
  };

  handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let id;
    try {
      this.setState({ loading: true, error: null, success: false });
      id = ((await this.props.dispatch(
        reportIssue(this.state.userInput),
      )) as any) as string;
    } catch (e) {
      Sentry.captureException(e);
      this.setState({
        error:
          'Something went wrong submitting your issue: \n\n' +
          (((e as any).message || e) as string),
        loading: false,
      });
      return;
    }
    this.setState({
      loading: false,
      success: true,
      id,
    });
  };
  handleReset = () => {
    this.setState(defaultState);
    this.props.onHide();
  };

  render() {
    const disabled = this.state.loading || !this.props.online;
    return (
      <Modal onHide={this.props.onHide} show={this.props.show}>
        <Modal.Header closeButton={true}>
          <h2>
            <FormattedMessage
              id="REPORT_DIALOG.HEADER_TITLE"
              defaultMessage="Report issue"
            />
          </h2>
        </Modal.Header>
        <Modal.Body>
          {this.state.error && (
            <Alert bsStyle="danger">{this.state.error}</Alert>
          )}
          {this.state.success && (
            <Alert bsStyle="success">
              <FormattedMessage
                id="REPORT_DIALOG.SUCCESS_ALERT"
                defaultMessage="You {ticket} has been submitted"
                values={{
                  ticket: (
                    <Link
                      onClick={() => this.props.onHide()}
                      to={`/tickets/${this.state.id!}`}
                    >
                      ticket
                    </Link>
                  ),
                }}
              />
            </Alert>
          )}
          {!this.props.online && (
            <Alert bsStyle="warning">
              <FormattedMessage
                id="REPORT_DIALOG.OFFLINE_WARNING"
                defaultMessage="You can only report an issue when you are online"
              />
            </Alert>
          )}
          <form
            id="issueForm"
            onSubmit={this.handleSubmit}
            onReset={this.handleReset}
          >
            <FormGroup>
              <ControlLabel>
                <FormattedMessage
                  id="REPORT_DIALOG.TITLE_INPUT_LABEL"
                  defaultMessage="Title"
                />
              </ControlLabel>
              <FormControl
                value={this.state.userInput.title}
                disabled={disabled}
                name="title"
                onChange={this.handleChange}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>
                <FormattedMessage
                  id="REPORT_DIALOG.MESSAGE_INPUT_LABEL"
                  defaultMessage="Message"
                  description=""
                />
              </ControlLabel>
              <FormControl
                value={this.state.userInput.message}
                disabled={disabled}
                componentClass="textarea"
                name="message"
                rows={5}
                onChange={this.handleChange}
              />
            </FormGroup>
            <Checkbox
              checked={this.state.userInput.includeData}
              id="includeData"
              name="includeData"
              disabled={disabled}
              onChange={this.handleChange}
            >
              <FormattedMessage
                id="REPORT_DIALOG.INCLUDE_DATA_CHECKBOX_LABEL"
                defaultMessage="include copy of data"
              />
            </Checkbox>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="pull-left"
            type="reset"
            form="issueForm"
            disabled={this.state.loading}
          >
            <FormattedMessage
              id="REPORT_DIALOG.CANCEL"
              defaultMessage="Cancel"
              description=""
            />
          </Button>
          <Button
            bsStyle="primary"
            type="submit"
            form="issueForm"
            disabled={disabled}
          >
            {this.state.loading ? (
              <img src={loaderSrc} alt="loading indicator" />
            ) : (
              <FormattedMessage
                id="REPORT_DIALOG.SUBMIT"
                defaultMessage="Submit"
                description=""
              />
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default connect((state: any) => ({ online: state.online as boolean }))(
  IssueReportDialog,
);
