import * as R from 'ramda';

export const isNewFile = R.curryN(3, (projectDownloads = {}, projectLocalFiles = {}, file = {}) =>
  !!file.token &&                                  // has a file available
  !projectDownloads[file.recnum] &&                // is not being downloaded
  (                                                // is not allready downloaded
    !projectLocalFiles[file.recnum] ||                   // isnt downloaded allready
    projectLocalFiles[file.recnum].token !== file.token  // or is of another version
  )
);

/**
 * Counts newly available files
 * returns and integer representing the number of newly available files
 */
export function newFilesAvailable(projectDownloads = {}, projectLocalFiles = {}, files) {
  return files.filter(isNewFile(projectDownloads, projectLocalFiles)).length;
}
