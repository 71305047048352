import { Ticket } from '../../models/Ticket';
import { endOfMonth, subMonths } from 'date-fns';
import { startOfMonth } from 'date-fns/esm';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import Types from 'Types';
import {
  calculateProgress,
  isListClosed,
  listWasClosedDuringPeriod,
  listWasOpenedDuringPeriod,
} from '../../common/list';
import { buildListAndDependencies } from '../../common/list/buildList';
import {
  getTicketAt,
  ticketTimeOpen,
  wasTicketClosedDuringPeriod,
  wasTicketCreatedDuringPeriod,
  wasTicketReopenedDuringPeriod,
} from '../../common/logic/ticket';
import { List } from '../../models/List';
import { fetchTickets } from '../../modules/tickets';
import { fetchWorks } from '../../modules/works';
import ignoreRejection from '../../utils/ignoreRejection';
import Spinner from '../../components/Spinner';

const NUM_MONTHS = 24;

/**
 * Lazily load the stats page because the graphing library (recharts) is pretty big
 */
const LazyStatsPage = React.lazy(() => import('../../components/StatsPage'));

interface TicketStats {
  open: any[];
  closed: any[];
  unassigned: any[];
  noDueDate: any[];
  reopenedDuringPeriod: any[];
  createdDuringPeriod: any[];
  closedDuringPeriod: any[];
  avgTimeToClose: number;
}
interface ListStats {
  open: List[];
  closedDuringPeriod: List[];
  openedDuringPeriod: List[];
  // avgAssignedUsersPerList: number;
  moreThan50PercentAnsweredAndNotClosed: List[];
  noDueDate: List[];
  unassigned: List[];
}

export interface Stats {
  month: Date;
  tickets: TicketStats;
  lists: ListStats | null;
}

function computeTicketStats(
  tickets: any[],
  period_start: Date,
  period_end: Date,
): TicketStats {
  let open: any[] = [];
  let closed: any[] = [];
  let unassigned: any[] = [];
  let noDueDate: any[] = [];
  let reopenedDuringPeriod: any[] = [];
  let closedDuringPeriod: any[] = [];
  let createdDuringPeriod: any[] = [];
  let sumTimeOpen = 0;

  tickets.forEach(t => {
    const ticketAtEndOfPeriod: Partial<Ticket> | null = getTicketAt(
      period_end,
      t,
    );

    if (ticketAtEndOfPeriod) {
      if (ticketAtEndOfPeriod.status === 'opened') {
        // was ticket open at end of period
        open.push(t);
      } else if (ticketAtEndOfPeriod.status === 'closed') {
        closed.push(t);
      }
      if (ticketAtEndOfPeriod.assignee_user_id === null) {
        // ticket has no assignee at end of period
        unassigned.push(t);
      }
      if (ticketAtEndOfPeriod.due === null) {
        // ticket has no due date at end of period
        noDueDate.push(t);
      }
      if (wasTicketClosedDuringPeriod(period_start, period_end, t)) {
        // was ticket closed during period
        closedDuringPeriod.push(t);
        // add time to close

        sumTimeOpen += ticketTimeOpen(t);
      }
      if (wasTicketCreatedDuringPeriod(period_start, period_end, t)) {
        // was ticket created during period
        createdDuringPeriod.push(t);
      } else if (wasTicketReopenedDuringPeriod(period_start, period_end, t)) {
        // was ticket reopened during period
        reopenedDuringPeriod.push(t);
      }
    }
  });
  return {
    open,
    closed,
    createdDuringPeriod,
    reopenedDuringPeriod,
    closedDuringPeriod,
    avgTimeToClose: sumTimeOpen / closedDuringPeriod.length,
    noDueDate,
    unassigned,
  };
}

function computeListStats(
  lists: List[],
  period_start: Date,
  period_end: Date,
): ListStats {
  let open: List[] = [];
  let closedDuringPeriod: List[] = [];
  let openedDuringPeriod: List[] = [];
  let moreThan50PercentAnsweredAndNotClosed: List[] = [];
  let noDueDate: List[] = [];
  let unassigned: List[] = [];

  lists.forEach(l => {
    if (!l.werk_id) {
      return; // skip templates
    }

    /*
     * XXX: fix functions to account for specified period!
     */
    if (!isListClosed(l)) {
      open.push(l);
      if (listWasOpenedDuringPeriod(period_start, period_end, l)) {
        openedDuringPeriod.push(l);
      }
      if (calculateProgress(buildListAndDependencies(l, lists)) >= 0.5) {
        moreThan50PercentAnsweredAndNotClosed.push(l);
      }
      if (!l.end_date) {
        noDueDate.push(l);
      }
    } else if (listWasClosedDuringPeriod(period_start, period_end, l)) {
      closedDuringPeriod.push(l);
    }

    // if (!isListClosed(l)) {
    //   open.push(l);
    //   if (l.users.length === 0) {
    //     unassigned.push(l);
    //   }
    //   if (!l.end_date) {
    //     noDueDate.push(l);
    //   }
    //   if (calculateProgress(buildListAndDependencies(l, lists)) >= 0.5) {
    //     moreThan50PercentAnsweredAndNotClosed.push(l);
    //   }
    // } else {
    //   closed.push(l);
    // }
  });

  return {
    open,
    openedDuringPeriod,
    closedDuringPeriod,
    // avgAssignedUsersPerList: avgAssignedUsersPerList / numLists,
    moreThan50PercentAnsweredAndNotClosed,
    noDueDate,
    unassigned,
  };
}

function computeStats(lists: List[], tickets: Ticket[]): Stats[] {
  const stats: Stats[] = [];
  const now = new Date();
  for (let month = 0; month < NUM_MONTHS; month++) {
    let periodStart = startOfMonth(subMonths(now, month));
    let periodend = endOfMonth(subMonths(now, month));

    stats.push({
      month: periodStart,
      tickets: computeTicketStats(tickets, periodStart, periodend),
      lists:
        month === 0 ? computeListStats(lists, periodStart, periodend) : null,
    });
  }
  return stats;
}

const StatsContainer: React.FC<Types.RootState & {
  dispatch: Dispatch;
}> = ({ tickets, lists, works, dispatch, users, labels }) => {
  React.useEffect(() => {
    ignoreRejection(
      dispatch(fetchWorks(null)),
      dispatch(fetchTickets() as any),
    );
  }, [dispatch]);

  const [start, setStart] = React.useState(false);

  const stats = React.useMemo(
    () =>
      start &&
      lists &&
      tickets &&
      computeStats(
        Object.values(lists),
        Object.values(tickets as any[]).filter(t => t.id),
      ),
    [tickets, lists, start],
  );

  // allow the spinner to render before starting the computation
  React.useEffect(() => setStart(true), []);

  return (
    <React.Suspense fallback={<Spinner />}>
      <LazyStatsPage
        labels={labels}
        stats={stats}
        works={works}
        users={users}
        lists={Object.values(lists)}
      />
    </React.Suspense>
  );
};

export default connect(x => x)(StatsContainer);
