import classNames from 'classnames';
import PropTypes from 'prop-types';
import * as React from 'react';
import { doesListHaveUnsyncedModifications } from '../../../common/logic/list';
import { doesTicketHaveUnsyncedEvents } from '../../../common/logic/ticket';

import { injectIntl } from 'react-intl';
import { SyncStatus } from '../../../modules/syncStatus';
import SyncStatusDialog from '../../SyncStatusDialog';
import stl from './AppStatus.module.scss';

export default injectIntl(
  class AppStatus extends React.Component {
    static propTypes = {
      lists: PropTypes.object,
      online: PropTypes.bool,
      syncStatus: PropTypes.object.isRequired,
      user: PropTypes.object,
    };

    static defaultProps = {
      online: true,
    };
    static contextTypes = {
      onlineService: PropTypes.object.isRequired,
    };

    state = {
      // null means: show if not yet synced
      showSyncDialog: null,
    };

    toggleSyncDialog = () => {
      this.context.onlineService._check();
      this.setState({
        showSyncDialog: !this.shouldShowSyncStatusDialog(),
      });
    };

    shouldShowSyncStatusDialog() {
      const { syncStatus, user } = this.props;
      let { showSyncDialog } = this.state;
      return (
        // user &&
        // ((!syncStatus.lastSynced && showSyncDialog === null) ||
        showSyncDialog || false
        // )
      );
    }

    render() {
      if (!this.context.onlineService) {
        return null;
      }
      const { online, lists, tickets, syncStatus, feedback } = this.props;

      const hasUnsynced =
        (lists &&
          Object.values(lists).some(doesListHaveUnsyncedModifications)) ||
        (tickets &&
          Object.values(tickets).some(doesTicketHaveUnsyncedEvents)) ||
        (feedback && Object.values(feedback).some(f => f._sync));

      const hasErros = syncStatus.status === SyncStatus.STATUS_FAILED;
      return (
        <>
          <button
            className={classNames('btn', this.props.className, stl.root)}
            onClick={this.toggleSyncDialog}
          >
            <i
              className={classNames(
                'fa',
                'fa-plug',
                !online ? stl.bad : stl.good,
              )}
            />
            &nbsp; &nbsp;
            <i
              className={classNames(
                'fa',
                'fa-refresh',
                hasErros ? stl.bad : hasUnsynced ? stl.pending : stl.good,
                syncStatus.status === SyncStatus.STATUS_BUSY && 'fa-spin',
              )}
            />
          </button>
          <SyncStatusDialog
            show={this.shouldShowSyncStatusDialog()}
            onDismiss={this.toggleSyncDialog}
            hasUnsynced={hasUnsynced}
            online={online}
          />
        </>
      );
    }
  },
);
