import { descend, prop, propEq, sortWith, values } from 'ramda';
import * as React from 'react';
import { ListGroup, ListGroupItem } from 'react-bootstrap';
import { FormattedMessage, defineMessages } from 'react-intl';
import { Dispatch } from 'redux';
import { UsersState } from '../../modules/users';
import FeedbackListItem, {
  FeedbackListItemLinkMode,
} from '../FeedbackListItem';
import Spinner from '../Spinner';
import commonStl from '../commonStyles.module.scss';
// import UserToken from '../UserToken/index';
import { buildListAndDependencies } from '../../common/list/buildList';
import Feedback from '../../models/Feedback';
import styles from './TicketsList.module.scss';

const msg = defineMessages({
  NO_FEEDBACK: {
    id: 'FEEDBACk_LIST.NO_FEEDBACK',
    defaultMessage: 'No Feedback',
  },
});

const sortFeedback = sortWith<Feedback>([descend(prop('timestamp'))]);

export interface FeedbackListProps {
  feedback: Feedback[] | null;
  users: UsersState;
  lists: any;
  dispatch: Dispatch;
}

const FeedbackList: React.FC<FeedbackListProps> = ({
  feedback,
  users,
  lists,
  dispatch,
}) => {
  if (!users || !feedback) {
    return <Spinner />;
  }
  // dont fail on feedback referencing archived lists
  // TODO: show feedback of which lists are archived
  const filterredFeedback = feedback.filter(f => lists[f.instance_list_id!]);
  if (filterredFeedback.length === 0) {
    return (
      <p className={commonStl.noEntities}>
        <FormattedMessage {...msg.NO_FEEDBACK} />
      </p>
    );
  }
  const items = sortFeedback(filterredFeedback).map(f => {
    // look up protoList list & node
    // look up instance list & node
    let node;
    let list;
    if (f.instance_list_id && f.instance_node_id) {
      list = values(lists).find(propEq('id', f.instance_list_id));

      const builtList = buildListAndDependencies(list, lists);
      node = builtList.index[f.instance_node_id];
    }

    return (
      <ListGroupItem key={f.id} className={styles.root}>
        <FeedbackListItem
          instanceList={list}
          feedback={f}
          users={users}
          linkMode={FeedbackListItemLinkMode.INSTANCE}
          list={list}
          node={node}
          dispatch={dispatch}
        />
      </ListGroupItem>
    );
  });
  return <ListGroup>{items}</ListGroup>;
};

export default FeedbackList;
