import PropTypes from 'prop-types';
import * as React from 'react';
import { Button } from 'react-bootstrap';
import { ContextMenu, ContextMenuTrigger } from 'react-contextmenu';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { LISTMODE_MAINTAIN } from './';

import { isListClosed } from '../../common/logic/list';
import { renderFields } from './Field';

import Paper from '../Paper';
import { CoverTable } from '../Report/Cover';
import BaseField from './BaseField';
import styles from './FieldStyles.module.scss';

export default class RootField extends BaseField {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    field: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    list: PropTypes.object.isRequired,
    title: PropTypes.bool.isRequired,
    work: PropTypes.object,
  };

  renderReport() {
    const { field, list, work } = this.props;
    const closed = isListClosed(list);
    return (
      <>
        <Paper.NewPage>
          <h1>{list.name}</h1>
          <div>
            <CoverTable>
              <tr>
                <td>
                  <FormattedMessage
                    id="CLIENT"
                    defaultMessage="Client"
                    description=""
                  />
                  :
                </td>
                <td>{work.klant_naam || work.klant}</td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage
                    id="WORK"
                    defaultMessage="Work item"
                    description=""
                  />
                  :
                </td>
                <td>{work.oms || work.id}</td>
              </tr>
              {closed && (
                <tr>
                  <td>
                    <FormattedMessage
                      id="COMPLETED"
                      defaultMessage="Completed"
                      description=""
                    />
                    :
                  </td>
                  <td>
                    <FormattedDate value={closed} />
                  </td>
                </tr>
              )}
            </CoverTable>
          </div>
        </Paper.NewPage>
        <Paper.NewPage>
          {field.children.map(renderFields(this.props))}
        </Paper.NewPage>
      </>
    );
  }

  renderUI() {
    const { field, list, title, mode } = this.props;
    const { children } = field;

    const contextMenuOptions = this.renderContextMenuOptions();

    if (!title) {
      return <div>{children.map(renderFields(this.props))}</div>;
    }
    return (
      <div>
        <ContextMenuTrigger id={field.id} disable={!contextMenuOptions.length}>
          <div className={styles.rootTitle}>
            {mode === LISTMODE_MAINTAIN && (
              <div>
                <Button
                  title="Insert new group"
                  className="pull-right"
                  bsStyle="link"
                  onClick={this.addGroup}
                >
                  <i className="fa fa-plus-square fa-lg" />
                </Button>
                <Button
                  title="Insert group template"
                  className="pull-right"
                  bsStyle="link"
                  onClick={this.expandProto}
                >
                  <i className="fa fa-share-square fa-rotate-180 fa-lg" />
                </Button>
              </div>
            )}
            <h2>{list.name}</h2>
          </div>
          {children.map(renderFields(this.props))}
        </ContextMenuTrigger>

        <ContextMenu id={field.id}>{contextMenuOptions}</ContextMenu>
      </div>
    );
  }
}
