import { AnyAction, Dispatch, Middleware } from 'redux';
import Types from 'Types';
import {
  FilesAction,
  isPurgeFileAction,
  isPurgeFilesAction,
} from '../modules/files';

/**
 * Middleware which performs the deletion of local files from the database
 *
 * the deletion is performed AFTER the file placeholder has been removed from the store.
 * When a purge is triggered, a promise is returned
 */
const createPurgeFilesMiddleware: (
  storage: LocalForageDbMethods,
) => Middleware<
  {},
  Types.RootState,
  Dispatch<FilesAction>
> = storage => () => next => {
  return (action: AnyAction) => {
    const rv = next(action);
    if (isPurgeFileAction(action)) {
      return storage.removeItem(action.payload);
    } else if (isPurgeFilesAction(action)) {
      return storage.clear();
    }
    return rv;
  };
};

export default createPurgeFilesMiddleware;
