import debounce from 'lodash/debounce';
import delay from 'lodash/delay';
import last from 'lodash/fp/last';
import unnest from 'lodash/fp/unnest';
import zip from 'lodash/fp/zip';
import { insert, pipe, remove, update } from 'ramda';
import * as React from 'react';
import { Dispatch } from 'redux';
import { getParamsFromTags } from '../../common/list';
import { MeetingSection, TicketReference } from '../../models/Meeting';
import { deleteMeetingSection } from '../../modules/meetings';
import { createTicket } from '../../modules/tickets';
import { UserState } from '../../modules/user';
import { UsersState } from '../../modules/users';
import ParameterizedSearch from '../ParameterizedSearch';
import { serializeParameters } from '../ParameterizedSearch/model';
import stl from './Minutes.module.scss';
import TicketReferenceComponent from './TicketReferenceComponent';
import TicketReferenceCreator from './TicketReferenceCreator';

export interface MinuteSheetSectionProps
  extends Pick<
    MeetingSection,
    'ticketTags' | 'ticketDiscipline' | 'ticketRemainder'
  > {
  dispatch: Dispatch;
  title: string | null;
  content: string[];
  ticketReferences: TicketReference[];
  tickets: any;
  index: number;
  workId: number | null;
  projectId: number | null;
  meetingId: string;
  meetingDate: string | Date | null;
  user: UserState;
  users: UsersState;
  onChange(idx: number, partialSection: Partial<MeetingSection>): void;
}
export interface MinuteSheetSectionState {
  focus: null | {
    x: number;
    y: number;
    idx: number;
    ticketQuery: string | null;
    selectionStart: number;
  };
  activeTicketReference: number | null;
}

const calcRows = (content: string) => (content.match(/\n/g) || []).length + 1;

const getXPos = (content: string) =>
  (last(content.split('\n')) || '').length * 8.5;

const getYPos = (content: string) =>
  ((content.match(/\n/g) || []).length + 1) * 19;

const ticketIndicatorRegex = /#([0-9\w ]*)$/;

export default class MinuteSheetSection extends React.Component<
  MinuteSheetSectionProps,
  MinuteSheetSectionState
> {
  rootRef = React.createRef<HTMLDivElement>();

  constructor(props: MinuteSheetSectionProps) {
    super(props);
    this.state = {
      focus: null,
      activeTicketReference: null,
    };
  }

  removeSection = () => {
    const { user } = this.props;
    if (!user) {
      throw new Error('expected user');
    }
    // eslint-disable-next-line
    if (confirm('Sectie verwijderen?')) {
      this.props.dispatch(
        deleteMeetingSection({
          meetingId: this.props.meetingId,
          idx: this.props.index,
          editor: user.recnum,
          edited: new Date(),
        }),
      );
    }
  };

  handleSelectTicket = (t: any) => {
    const { focus } = this.state;
    if (!focus) {
      return;
    }
    const { idx } = focus;

    const content = pipe(
      update(
        idx,
        this.props.content[idx]
          .substr(0, focus.selectionStart)
          .replace(ticketIndicatorRegex, '')
          .replace(/\n$/, ''),
      ),
      insert(
        idx + 1,
        this.props.content[idx].substr(
          focus.selectionStart,
          this.props.content[idx].length,
        ),
      ),
    )(this.props.content);

    const ticketReferences: TicketReference[] = insert(
      idx,
      {
        ticketId: t.id,
        created: new Date(),
        userId: 0,
      },
      this.props.ticketReferences,
    );

    this.props.onChange(this.props.index, {
      content,
      ticketReferences,
    });
  };

  handleCreateTicket = async (t: any) => {
    if (!this.props.user) {
      throw new Error('Expected a user');
    }
    if (!this.props.projectId) {
      throw Error('Expected a project ID');
    }
    let tagsMod: any = {};
    if (this.props.ticketTags) {
      tagsMod.add_tags = this.props.ticketTags;
    }
    const {
      payload: { id },
    } = await this.props.dispatch(
      (createTicket as any)(
        {
          status: 'opened',
          ...t,
          ...tagsMod,
          project_id: this.props.projectId,
          description: '',
          creator_user_id: this.props.user.recnum, // FIXME: this is not nessecary
        },
        this.props.user.recnum,
        { meetingId: this.props.meetingId },
      ),
    );

    this.handleSelectTicket({ id });
  };

  updateCursorPos = (
    event:
      | React.KeyboardEvent<HTMLTextAreaElement>
      | React.FocusEvent<HTMLTextAreaElement>,
  ) => {
    const target = event && (event.target as HTMLTextAreaElement);
    let hasFocus;
    let idx: number = -1;
    let rangeSelected = false;
    let strBeforCursor = '';
    let hasTicketIndicator = false;
    let ticketQuery: string | null = null;
    if (target) {
      idx = parseInt(target.getAttribute('data-idx') || '-1', 10);
      hasFocus = event.target === document.activeElement;
      if (hasFocus) {
        rangeSelected = target.selectionStart !== target.selectionEnd;
        if (!rangeSelected) {
          // tslint:disable-next-line: no-console
          // console.log([
          //   event.type,
          //   ,
          //   target.selectionStart,
          //   idx,
          //   (event as any).code,
          //   (event as any).keyCode
          // ]);
          // allow user to delete ref
          if (
            event.type === 'keydown' &&
            target.selectionStart === 0 &&
            idx > 0 &&
            // backspace
            (event as any).keyCode === 8 &&
            // eslint-disable-next-line
            confirm('Ticket referentie verwijderen?')
          ) {
            const content = this.props.content;
            // tslint:disable: no-console
            // console.log(1, content);
            let contentTmp = content.map((c, i) => {
              if (i === idx) {
                return null;
              } else if (i === idx - 1) {
                return c + this.props.content[idx];
              }
              return c;
            });

            contentTmp = contentTmp.filter(x => x !== null);
            this.props.onChange(this.props.index, {
              content: contentTmp as any,
              ticketReferences: remove(idx - 1, 1, this.props.ticketReferences),
            });
            this.setState({ focus: null });
            if (this.rootRef.current) {
              const node: HTMLTextAreaElement | null = this.rootRef.current.querySelector(
                `textarea[data-idx='${idx - 2}']`,
              );
              if (node) {
                node.focus();
              }
            }
            return;
          }
          // update the focus state for TicketReferenceCreator
          strBeforCursor = target.value.substr(0, target.selectionStart);
          const match = strBeforCursor.match(ticketIndicatorRegex);
          if (match) {
            hasTicketIndicator = true;
            ticketQuery = match[1] || null;
          }
        }
      }
    }
    if (hasTicketIndicator) {
      const bounds = target.getBoundingClientRect();
      this.setState({
        focus: {
          ticketQuery,
          selectionStart: target.selectionStart,
          idx,
          x: getXPos(strBeforCursor) + bounds.left,
          y: getYPos(strBeforCursor) + bounds.top,
        },
      });
    } else {
      this.setState({ focus: null });
    }
  };

  // tslint:disable-next-line: member-ordering
  debouncedUpdateCursorPos = debounce(this.updateCursorPos, 50);

  handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const idx = parseInt(event.target.getAttribute('data-idx') || '-1', 10);
    let content: string[];
    if (isNaN(idx)) {
      throw new Error('no idx');
    } else if (idx === -1) {
      content = [...this.props.content, event.target.value];
    } else {
      content = this.props.content.map((c, i) =>
        i === idx ? event.target.value : c,
      );
    }
    this.props.onChange(this.props.index, { content });
  };
  handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.props.onChange(this.props.index, {
      title: event.target.value,
    });
  };

  handleBlurComment = () => {
    this.setState({
      activeTicketReference: null,
    });
  };
  handleFocusComment = (idx: number) => {
    this.setState({ activeTicketReference: idx });
  };
  handleNextComment = () => {
    this.setState(({ activeTicketReference }) => ({
      activeTicketReference:
        activeTicketReference !== null ? activeTicketReference + 1 : null,
    }));
  };
  handlePreviousComment = () => {
    this.setState(({ activeTicketReference }) => ({
      activeTicketReference:
        activeTicketReference != null ? activeTicketReference - 1 : null,
    }));
  };

  render() {
    let title = null;
    if (this.props.title !== null) {
      title = (
        <h3>
          <input onChange={this.handleTitleChange} value={this.props.title} />
          {/* <pre>{JSON.stringify(getParamsFromTags(this.props.ticketTags))}</pre> */}
        </h3>
      );
    }
    const { focus } = this.state;
    let content: React.ReactNode[] = [];
    const pairs = zip(this.props.content, this.props.ticketReferences);
    if (pairs.length) {
      content = unnest(
        pairs.map(([text, ticketReference], idx) => {
          if (!text && text !== '') {
            throw new Error('Unexpected: missing content in ticket section');
          }
          return (
            <React.Fragment key={idx}>
              <textarea
                key={idx}
                onChange={this.handleTextChange}
                rows={calcRows(text)}
                data-idx={idx}
                className={stl.sheetTextarea}
                onFocus={this.updateCursorPos}
                // tslint:disable-next-line: jsx-no-lambda
                onKeyDown={e => {
                  e.persist();
                  this.debouncedUpdateCursorPos(e);
                }}
                // tslint:disable-next-line: jsx-no-lambda
                onBlur={e => {
                  e.persist();
                  delay(this.updateCursorPos, 200, e);
                }}
                value={text}
              />

              {focus && focus.idx === idx && (
                <div
                  className="dropdown open"
                  style={{
                    display: 'block',
                    position: 'fixed',
                    top: focus.y,
                    left: focus.x,
                    zIndex: 99,
                  }}
                >
                  <TicketReferenceCreator
                    onSelectTicket={this.handleSelectTicket}
                    onCreateTicket={this.handleCreateTicket}
                    query={focus.ticketQuery}
                    tickets={this.props.tickets}
                  />
                </div>
              )}
              {ticketReference && (
                <TicketReferenceComponent
                  ticketReference={ticketReference}
                  users={this.props.users}
                  user={this.props.user}
                  dispatch={this.props.dispatch}
                  tickets={this.props.tickets}
                  meetingId={this.props.meetingId}
                  meetingDate={this.props.meetingDate}
                  onBlurComment={this.handleBlurComment}
                  onNextComment={this.handleNextComment}
                  onPreviousComment={this.handlePreviousComment}
                  onFocusComment={this.handleFocusComment.bind(null, idx)}
                  commentBoxActive={this.state.activeTicketReference === idx}
                />
              )}
            </React.Fragment>
          );
        }),
      );
    }
    if (this.props.content.length === this.props.ticketReferences.length) {
      content.push(
        <React.Fragment key={-1}>
          <textarea
            key={this.props.content.length}
            rows={1}
            value={''}
            data-idx={-1}
            className={stl.sheetTextarea}
            onChange={this.handleTextChange}
          />
        </React.Fragment>,
      );
    }

    return (
      <div ref={this.rootRef} className={stl.section} draggable={true}>
        {title && <div className={stl.handle} />}
        {(title && this.props.ticketTags?.length && (
          <div className={stl.labels}>
            <ParameterizedSearch
              options={{
                noInput: true,
                small: true,
                parameters: {
                  label: { name: 'label', icon: 'tag', options: [] },
                },
              }}
              value={serializeParameters(
                getParamsFromTags(this.props.ticketTags) as any,
              )}
            />
          </div>
        )) ||
          false}

        {title && (
          <button className={stl.removeSection} onClick={this.removeSection}>
            &times;
          </button>
        )}
        {title}
        {content}
      </div>
    );
  }
}
