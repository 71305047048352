import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Types from 'Types';
import { useLocation } from 'wouter';
import {
  calculateRemainingCountingsStats,
  CountingsStatsIndex,
  CountingStats,
} from '../common/counting/calculateRemainingCountingsStats';
import countingRequestFilter from '../common/counting/countingRequestFilter';
import CountingRequestDetailsEditor from '../components/CountingRequestDetailsEditor';
import InventoryRemainingCountingsTree from '../components/InventoryRemainingCountingsTree';

import Spinner from '../components/Spinner';
import { MakeTree, makeTreeIndex } from '../components/Tree/common';
import useLocations from '../hooks/useLocations';
import { LocationWithDetails } from '../modules/locations';
import {
  UpdatableCountingRequest,
  useCountingRequestStore,
} from '../stores/countingRequestStore';
import { useCountingStore } from '../stores/countingStore';
import { withKey } from '../utils/component';

export const MAX_COUNTINGS_TO_BE_COUNTABLE = 50;
const DISPLAY_NUM_COUNTABLE_ITEMS = 20;

interface CountingRequestDetailsContainerProps {
  recnum: number;
}

const CountingRequestDetailsContainer: React.FC<CountingRequestDetailsContainerProps> = ({
  recnum,
}) => {
  const [, goTo] = useLocation();
  const [
    { countingRequests },
    { fetch, save, remove },
  ] = useCountingRequestStore();
  const [countings, { fetch: fetchCountings }] = useCountingStore();
  const [hasFetched, sethasFetched] = React.useState<boolean>(false);
  const [hasFetchedCountings, setHasFetchedCountings] = React.useState<boolean>(
    false,
  );
  const originalCountingRequest = countingRequests?.[recnum];
  const [error, setError] = React.useState<Error | null>(null);
  const rootLocations = useLocations();
  const isCountingRequestAdmin = useSelector<Types.RootState, boolean>(
    ({ user }) => !!user?.permissions?.COUNTING_REQUEST_ADMIN,
  );

  const handleRemove = React.useCallback(() => {
    remove(originalCountingRequest.recnum).then(() =>
      goTo('/counting-requests'),
    );
  }, [originalCountingRequest, remove]);

  const rootLocationsIndex = React.useMemo(() => makeTreeIndex(rootLocations), [
    rootLocations,
  ]);

  const selectedLocations = React.useMemo(() => {
    if (!originalCountingRequest) {
      return [];
    }
    const locs = originalCountingRequest.locations
      .map(id => rootLocationsIndex[id])
      .filter(Boolean);
    if (locs.length !== originalCountingRequest.locations.length) {
      console.log(
        `could not find ${originalCountingRequest.locations.length -
          locs.length} of ${
          originalCountingRequest.locations.length
        } locations`,
      );
      console.log(
        originalCountingRequest.locations.filter(id => !rootLocationsIndex[id]),
      );
    }
    return locs;
  }, [originalCountingRequest, rootLocationsIndex]);

  /*
   * calculate counting-statistics for all all selected locations and children
   * aggregate for countingrequest stats
   */
  const [countingStatsIndex, topLevelStat] = React.useMemo(() => {
    const index: CountingsStatsIndex = {};
    const topLevelStat: CountingStats = { counted: 0, total: 0 };

    for (const location of selectedLocations) {
      const stats = calculateRemainingCountingsStats(
        index /* by reference */,
        location,
        countings,
        originalCountingRequest,
        countingRequestFilter(originalCountingRequest.filter),
      );

      // extend stats up in the tree in case we start in the middle (or, more likely, at the leaves)
      let parent = location.parent;
      while (parent) {
        let parentStats: CountingStats = index[parent.id];
        if (!parentStats) {
          parentStats = index[parent.id] = {
            counted: 0,
            total: 0,
          };
        }
        parentStats.counted += stats.counted;
        parentStats.total += stats.total;

        parent = parent.parent;
      }

      //agg
      topLevelStat.counted += stats.counted;
      topLevelStat.total += stats.total;
    }
    return [index, topLevelStat];
  }, [selectedLocations, countings, originalCountingRequest]);

  useEffect(() => {
    fetch(recnum).then(
      () => sethasFetched(true),
      err => {
        setError(err);
        sethasFetched(true);
      },
    );
  }, [fetch, recnum]);

  useEffect(() => {
    if (originalCountingRequest) {
      // fetch countings in batches of 10 nodes, to avoid 414 Request-URI Too Large
      let chunks: string[][] = [];
      const locationsCopy = [...originalCountingRequest.locations];
      while (locationsCopy.length) {
        chunks.push(locationsCopy.splice(0, 20));
      }

      const sequetialFetch = chunks.reduce(
        (curr, nextChunk) =>
          curr.then(() =>
            fetchCountings({
              children: true,
              locations: nextChunk,
              from_date: originalCountingRequest.start_date,
              to_date: originalCountingRequest.end_date,
            }),
          ),
        Promise.resolve(),
      );

      sequetialFetch.then(
        () => setHasFetchedCountings(true),
        err => {
          setError(err);
          setHasFetchedCountings(true);
        },
      );
    }
  }, [fetchCountings, originalCountingRequest]);

  const [changes, setChanges] = React.useState<
    Omit<UpdatableCountingRequest, 'recnum'>
  >({});

  const countingRequest = React.useMemo(
    () => ({ ...originalCountingRequest, ...changes }),
    [changes, originalCountingRequest],
  );

  const [expandedIds, setExpandedIds] = React.useState<any[]>(
    originalCountingRequest?.locations || [],
  );

  // only expand the tree once
  const [didExpandTree, setdidExpandTree] = React.useState<boolean>(false);
  React.useEffect(() => {
    let countable = 0;
    if (didExpandTree || !hasFetchedCountings) {
      return;
    }
    const expanded: string[] = [];
    function iter(loc: MakeTree<LocationWithDetails>) {
      if (
        countable < DISPLAY_NUM_COUNTABLE_ITEMS &&
        countingStatsIndex?.[loc.id]?.total >= MAX_COUNTINGS_TO_BE_COUNTABLE &&
        countingStatsIndex[loc.id].total !==
          countingStatsIndex?.[loc.id]?.counted
      ) {
        expanded.push(loc.id);
        loc.children.forEach(iter);
      } else {
        // is deemed countable
        countable++;
      }
    }
    rootLocations.forEach(iter);
    setdidExpandTree(true);
    setExpandedIds(expanded);
  }, [
    countingStatsIndex,
    countings,
    originalCountingRequest,
    hasFetchedCountings,
  ]);

  const handleChange = React.useCallback(
    (pcr: Partial<UpdatableCountingRequest>) => {
      setChanges({ ...changes, ...pcr });
    },
    [changes],
  );

  const hasChanges = Object.keys(changes).length > 0;

  const handleSubmit = React.useCallback(async () => {
    try {
      await save({ recnum, ...changes });
      setChanges({});
    } catch (error) {
      setError(error as Error);
    }
  }, [changes, recnum, save]);

  return !(countingRequest && originalCountingRequest) ? (
    !hasFetched ? (
      <>
        <Spinner></Spinner>
      </>
    ) : (
      <>Counting Request not found</>
    )
  ) : (
    <>
      <CountingRequestDetailsEditor
        isCountingRequestAdmin={isCountingRequestAdmin}
        countingRequest={countingRequest}
        error={error}
        onChange={handleChange}
        onSubmit={hasChanges ? handleSubmit : undefined}
        rootLocations={rootLocations}
        onRemove={handleRemove}
      />

      {rootLocations.length === 0 || !hasFetchedCountings || !didExpandTree ? (
        <Spinner></Spinner>
      ) : (
        <>
          <h6>Statistics</h6>
          <dl>
            <dt>Countings</dt>
            <dd>{topLevelStat.total}</dd>
            <dt>Completed countings</dt>
            <dd>{topLevelStat.counted}</dd>
            <dt>Progress</dt>
            <dd>
              {topLevelStat.total === 0
                ? 100
                : Math.round((topLevelStat.counted / topLevelStat.total) * 100)}
              %
            </dd>
          </dl>

          <InventoryRemainingCountingsTree
            linkBase={`/counting-requests/${countingRequest.recnum}`}
            locations={rootLocations}
            countingStatsIndex={countingStatsIndex}
            expandedElementIds={expandedIds}
            index={rootLocationsIndex}
            onExpandedElementIdsChanged={setExpandedIds}
          />
        </>
      )}
    </>
  );
};

export default withKey('recnum', CountingRequestDetailsContainer);
