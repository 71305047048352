import * as React from 'react';
import { useLocation } from 'wouter';

// something is broken with the combination of wouter and redux first router
// causing the initial redirect to not render
// redirecting in the next runloop cycle (setTimeout) avoids the issue
// TODO: create issue
export const Redirect = (props: any) => {
  const { to, href = to } = props;
  const [, navigate] = useLocation();
  const redirect = () => navigate(to || href, props);

  React.useEffect(() => {
    setTimeout(redirect, 0);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};
