import boxIconSvg from './icons/box.svg';
import moreIconSvg from './icons/More.svg';
import stl from './Icon.module.scss';
import React from 'react';

type IconName = 'box' | 'more';

const srcMap: { [name: string]: string } = {
  box: boxIconSvg,
  more: moreIconSvg,
};

interface IconProps extends Pick<JSX.IntrinsicElements['button'], 'onClick'> {
  name: IconName;
}
const Icon: React.FC<IconProps> = ({ name, onClick }) => {
  let el = <img className={stl.root} src={srcMap[name]} alt={name} />;
  if (onClick) {
    el = (
      <button onClick={onClick} className={stl.btn}>
        {el}
      </button>
    );
  }
  return el;
};

export default Icon;
