import {
  Artikel,
  MaterialList as MaterialListInterface,
  MaterialListItem as MaterialListItemInterface,
} from '@certhon/domain-models/lib';
import { useMemo, useReducer } from 'react';
import { parseDateOrNull } from '../common/logic/misc';
import { DTO, MakeOptionalExcept } from '../common/logic/types';
import { createUsableSingleton } from '../hooks/createUsableSingleton';
import { assertNever } from '../modules/utils';
import { mapByRecnum, resolveHeaders, resolveUrl } from '../utils';
import { verifyResponse } from '../utils/verifyResponse';

function parseMaterialListItemDTO({
  created_at,
  modified_at,
  deleted_at,
  ...rest
}: DTO<MaterialListItem>): MaterialListItem {
  return {
    ...rest,
    created_at: new Date(created_at),
    modified_at: new Date(modified_at),
    deleted_at: deleted_at === null ? null : new Date(deleted_at),
  };
}

function parseMaterialListDTO({
  created_at,
  modified_at,
  deleted_at,
  material,
  processed_at,
  ...rest
}: DTO<MaterialList>): MaterialList {
  return {
    ...rest,
    created_at: new Date(created_at),
    modified_at: new Date(modified_at),
    deleted_at: new Date(deleted_at),
    processed_at: parseDateOrNull(processed_at),
    material: material && material.map(parseMaterialListItemDTO),
  };
}

export interface State {
  materialLists: Record<number, MaterialList>;
  hasFetchedAll: boolean;
}

export interface MaterialListItem extends MaterialListItemInterface {
  artikel: Artikel;
}

export interface MaterialList extends Omit<MaterialListInterface, 'material'> {
  material?: null | MaterialListItem[];
}

export type UpdatableMaterialList = MakeOptionalExcept<
  Pick<
    MaterialList,
    | 'recnum'
    | 'status'
    | 'order_type'
    | 'name'
    | 'note'
    | 'created_by_user_id'
    | 'project_id'
    | 'klant_id'
    | 'inventory_direction'
  >,
  'recnum'
>;
export type InsertableMaterialList = MakeOptionalExcept<
  Omit<MaterialList, 'recnum'>,
  | 'status'
  | 'order_type'
  | 'name'
  | 'note'
  | 'project_id'
  | 'klant_id'
  // | 'created_at'
  // | 'created_by_user_id'
  | 'inventory_direction'
>;

export type SavableMaterialList =
  | InsertableMaterialList
  | UpdatableMaterialList;

export type InsertableMaterialListItem = Pick<
  MaterialListItem,
  'amount' | 'artikel_id' | 'location_id'
>;
export type UpdatableMaterialListItem = MakeOptionalExcept<
  Pick<MaterialListItem, 'amount' | 'artikel_id' | 'location_id' | 'recnum'>,
  'recnum'
>;
export type SavableMaterialListItem =
  | InsertableMaterialListItem
  | UpdatableMaterialListItem;

type MaterialListAction =
  | { type: 'fetch_material_lists'; data: MaterialList[] }
  | { type: 'fetch_material_list'; data: MaterialList }
  | { type: 'delete_material_lists'; data: number };

function reducer(state: State, action: MaterialListAction): State {
  const { type, data } = action;
  if (type === 'fetch_material_lists') {
    return {
      materialLists: mapByRecnum(data),
      hasFetchedAll: true,
    };
  } else if (type === 'fetch_material_list') {
    return {
      ...state,
      materialLists: {
        ...state.materialLists,
        [data.recnum]: data,
      },
    };
  } else if (type === 'delete_material_lists') {
    const newState = {
      ...state,
      materialLists: { ...state.materialLists },
    };

    delete newState.materialLists[data];
    return newState;
  }
  assertNever(type);
}

export type MaterialListStoreInterface = ReturnType<
  typeof useMaterialListStore
>[1];

export const [
  MaterialListStoreProvider,
  useMaterialListStore,
] = createUsableSingleton(() => {
  const [state, dispatch] = useReducer(reducer, {
    materialLists: {},
    hasFetchedAll: false,
  });

  const inf = useMemo(
    () => ({
      /** Fetch one or all material lists */
      async fetch(recnum?: number) {
        const data = await fetch(
          resolveUrl(recnum ? `/material_lists/${recnum}` : '/material_lists'),
          { credentials: 'same-origin', headers: resolveHeaders()() },
        )
          .then(verifyResponse)
          .then(res => res.json());
        dispatch({
          type: recnum ? 'fetch_material_list' : 'fetch_material_lists',
          data: recnum
            ? parseMaterialListDTO(data)
            : data.map(parseMaterialListDTO),
        });
        return data;
      },
      async remove(recnum: number) {
        await fetch(resolveUrl(`/material_lists/${recnum}`), {
          method: 'DELETE',
          credentials: 'same-origin',
          headers: resolveHeaders()(),
        }).then(verifyResponse);

        dispatch({
          type: 'delete_material_lists',
          data: recnum,
        });
      },
      async save(savable: SavableMaterialList) {
        let response;
        if ('recnum' in savable) {
          response = fetch(resolveUrl(`/material_lists/${savable.recnum}`), {
            credentials: 'same-origin',
            headers: resolveHeaders()(),
            body: JSON.stringify({
              ...savable,
            }),
            method: 'PATCH',
          });
        } else {
          response = fetch(resolveUrl('/material_lists'), {
            credentials: 'same-origin',
            body: JSON.stringify(savable),
            headers: resolveHeaders()(),
            method: 'POST',
          });
        }

        const data = await response
          .then(verifyResponse)
          .then(res => res.json())
          .then(parseMaterialListDTO);

        dispatch({
          type: 'fetch_material_list',
          data,
        });
        return data;
      },
      async addMaterial(
        recnum: number,
        material: InsertableMaterialListItem[],
      ) {
        const data = await fetch(
          resolveUrl(`/material_lists/${recnum}/material`),
          {
            credentials: 'same-origin',
            body: JSON.stringify(material),
            headers: resolveHeaders()(),
            method: 'POST',
          },
        )
          .then(verifyResponse)
          .then(res => res.json())
          .then(parseMaterialListDTO);
        dispatch({ type: 'fetch_material_list', data });
        return data;
      },
      async updateMaterial(
        recnum: number,
        material: UpdatableMaterialListItem[],
      ) {
        const data = await fetch(
          resolveUrl(`/material_lists/${recnum}/material`),
          {
            credentials: 'same-origin',
            body: JSON.stringify(material),
            headers: resolveHeaders()(),
            method: 'PATCH',
          },
        )
          .then(verifyResponse)
          .then(res => res.json())
          .then(parseMaterialListDTO);
        dispatch({ type: 'fetch_material_list', data });
        return data;
      },
      async removeMaterial(recnum: number, itemRecnums: number[]) {
        const data = await fetch(
          resolveUrl(`/material_lists/${recnum}/material`),
          {
            credentials: 'same-origin',
            body: JSON.stringify(itemRecnums),
            headers: resolveHeaders()(),
            method: 'DELETE',
          },
        )
          .then(verifyResponse)
          .then(res => res.json())
          .then(parseMaterialListDTO);
        dispatch({ type: 'fetch_material_list', data });
        return data;
      },
    }),
    [],
  );

  return useMemo(() => [state, inf] as const, [state, inf]);
});
