import uniq from 'lodash/fp/uniq';
import { PatchField } from '../../types';
import {
  createModificationCreator,
  ListEventReducer,
} from '../ListModification';
import { patchListNode } from '../util';

export interface PatchPayload {
  partialNode: PatchField;
  targetNodeId: string;
}

export const patchNode = createModificationCreator<PatchPayload, 'PATCH'>(
  'PATCH',
);

export type PatchListModification = ReturnType<typeof patchNode>;

const patch: ListEventReducer<PatchListModification> = (
  list,
  mod: PatchListModification,
) => {
  const { partialNode, targetNodeId } = mod.payload;

  return patchListNode(list, targetNodeId, node => ({
    ...node,
    // XXX: technically we would have to ensure that the specific PatchField type matches the patched type
    ...(partialNode as any),
    modifiedFields: uniq([...node.modifiedFields, ...Object.keys(partialNode)]),
  }));
};

export default patch;
