import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { MenuItem, SubMenu } from 'react-contextmenu';
import { replace } from 'redux-first-history';
import {
  addAttachment,
  addGeotag,
  addNote,
  copyNode,
  createPartialCheckpointField,
  createPartialGroupField,
  editGeotag,
  editNote,
  insertNode,
  moveNode,
  patchNode,
  removeAttachment,
  removeGeotag,
  removeNode,
  removeNote,
} from '../../common/list/';
import { blur } from '../../modules/editor';
import { modifyList } from '../../modules/lists';
import { LISTMODE_MAINTAIN, LISTMODE_NORMAL, LISTMODE_REPORT } from './';

const modeUrlMap = {
  LISTMODE_MAINTAIN: 'template',
  LISTMODE_NORMAL: 'list',
  LISTMODE_REPORT: 'report',
};

/**
 * Abstract class that combines some methods that multiple fields have in common
 * @type {Object}
 */
export default class BaseField extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    list: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    field: PropTypes.object.isRequired,
  };

  getLocalizedfield(fieldName) {
    const {
      intl: { locale },
      field,
    } = this.props;
    return (
      field[fieldName + '_' + locale] ||
      field[fieldName + '_en'] ||
      field[fieldName + '_nl']
    );
  }

  select = e => {
    if (e) {
      e.stopPropagation();
    }
    const { dispatch, field, list, mode } = this.props;
    if (field.type === 'GROUP_FIELD' && mode === LISTMODE_NORMAL) {
      return;
    }
    dispatch(replace(`/${modeUrlMap[mode]}/${list.id}/${field.id}`));
  };

  blur = e => {
    e.stopPropagation();
    this.props.dispatch(blur());
  };

  renderContextMenuOptions() {
    const { mode, focussedField, index, field } = this.props;
    const { type, _id } = field;
    let focussedType = null;
    if (focussedField && index[focussedField]) {
      focussedType = index[focussedField].type;
    }

    const options = [];
    if (mode !== LISTMODE_MAINTAIN) {
      return options;
    }

    if (type === 'ROOT_FIELD') {
      options.push(
        <MenuItem onClick={this.addGroup} key="addGroup">
          New group
        </MenuItem>,
      );
      options.push(
        <MenuItem key="expandProto" onClick={this.expandProto}>
          Insert template
        </MenuItem>,
      );
    }
    if (type === 'GROUP_FIELD') {
      options.push(
        <MenuItem key="addField" onClick={this.addField}>
          New checkpoint
        </MenuItem>,
      );
    }
    if (type !== 'ROOT_FIELD') {
      if (options.length) {
        options.push(<MenuItem key="divider" divider />);
      }
      options.push(
        <MenuItem key="removeField" onClick={this.removeField}>
          Delete
        </MenuItem>,
      );
    }
    // allow moving/copying if of same type
    if (type !== 'ROOT_FIELD' && type === focussedType) {
      const indexInParent = index[field.parentNodeId].children.indexOf(field);
      const cantMove = focussedField === _id;
      options.push(
        <SubMenu hoverDelay={0} title="Copy" key="copyField">
          <MenuItem onClick={this.copyAbove} disabled={indexInParent !== 0}>
            Copy selection to above this field
          </MenuItem>
          <MenuItem onClick={this.copyBelow}>
            Copy selection to below this field
          </MenuItem>
        </SubMenu>,
      );
      options.push(
        <SubMenu key="cutField" hoverDelay={0} title="Move">
          <MenuItem
            disabled={cantMove || indexInParent !== 0}
            onClick={this.moveAbove}
          >
            Move selection above this field
          </MenuItem>
          <MenuItem disabled={cantMove} onClick={this.moveBelow}>
            Move selection below this field
          </MenuItem>
        </SubMenu>,
      );
    }
    return options;
  }

  addField = e => {
    if (e) {
      e.stopPropagation();
    }
    const { dispatch, list, field, user, mode } = this.props;
    const mod = insertNode(
      {
        targetNodeId: field.id,
        node: createPartialCheckpointField({ title_nl: 'Nieuw controlepunt' }),
      },
      user.recnum,
    );
    dispatch(modifyList(list.id, mod));
    dispatch(replace(`/${modeUrlMap[mode]}/${list.id}/${mod.payload.node.id}`));
  };

  addGroup = e => {
    if (e) {
      e.stopPropagation();
    }
    const { dispatch, list, field, user, mode } = this.props;
    const mod = insertNode(
      {
        targetNodeId: field.id,
        node: createPartialGroupField({
          title_nl: 'Nieuwe groep',
        }),
      },
      user.recnum,
    );
    dispatch(modifyList(list.id, mod));
    dispatch(replace(`/${modeUrlMap[mode]}/${list.id}/${mod.payload.node.id}`));
  };

  removeField = event => {
    event.stopPropagation();
    const { dispatch, list, field, user } = this.props;
    const mod = removeNode(
      {
        targetNodeId: field.id,
      },
      user.recnum,
    );
    dispatch(modifyList(list.id, mod));
  };

  patch(partialNode) {
    const { dispatch, list, field, user, mode } = this.props;
    const persist = mode === LISTMODE_NORMAL;
    const mod = patchNode(
      {
        targetNodeId: field.id,
        partialNode,
      },
      user.recnum,
    );
    dispatch(modifyList(list.id, mod, persist));
  }

  expandProto = () => {
    const { field, onRequestExpandProto } = this.props;
    if (onRequestExpandProto) {
      onRequestExpandProto(field.id, field);
    }
  };

  addAttachment(attachment) {
    const { dispatch, field, list, user, mode } = this.props;
    const persist = mode === LISTMODE_NORMAL;
    if (
      field.attachments &&
      field.attachments.find(a => a.checksum === attachment.checksum)
    ) {
      return alert('Deze bijlage is al toegevoegd');
    }
    const mod = addAttachment(
      {
        targetNodeId: field.id,
        attachment,
      },
      user.recnum,
    );
    dispatch(modifyList(list.id, mod, persist));
  }

  removeAttachment = checksum => {
    const { dispatch, field, list, user, mode } = this.props;
    const persist = mode === LISTMODE_NORMAL;
    const mod = removeAttachment(
      {
        targetNodeId: field.id,
        checksum,
      },
      user.recnum,
    );
    dispatch(modifyList(list.id, mod, persist));
  };

  addNote = text => {
    const { dispatch, field, list, user, mode } = this.props;
    const persist = mode === LISTMODE_NORMAL;
    const mod = addNote(
      {
        targetNodeId: field.id,
        note: text,
      },
      user.recnum,
    );
    dispatch(modifyList(list.id, mod, persist));
  };

  editNote = (noteId, text) => {
    const { dispatch, field, list, user, mode } = this.props;
    const persist = mode === LISTMODE_NORMAL;
    const mod = editNote(
      {
        targetNodeId: field.id,
        noteId,
        text,
      },
      user.recnum,
    );
    dispatch(modifyList(list.id, mod, persist));
  };

  removeNote = noteId => {
    const { dispatch, field, list, user, mode } = this.props;
    const persist = mode === LISTMODE_NORMAL;
    const mod = removeNote(
      {
        targetNodeId: field.id,
        noteId,
      },
      user.recnum,
    );
    dispatch(modifyList(list.id, mod, persist));
  };

  addGeotag = geotag => {
    const { dispatch, field, list, user, mode } = this.props;
    const persist = mode === LISTMODE_NORMAL;
    const mod = addGeotag(
      {
        targetNodeId: field.id,
        geotag,
      },
      user.recnum,
    );
    dispatch(modifyList(list.id, mod, persist));
  };

  editGeotag = (geotagId, geotag) => {
    const { dispatch, field, list, user, mode } = this.props;
    const persist = mode === LISTMODE_NORMAL;
    const mod = editGeotag(
      {
        targetNodeId: field.id,
        geotagId,
        geotag, // partial
      },
      user.recnum,
    );
    dispatch(modifyList(list.id, mod, persist));
  };

  removeGeotag = geotagId => {
    const { dispatch, field, list, user, mode } = this.props;
    const persist = mode === LISTMODE_NORMAL;
    const mod = removeGeotag(
      {
        targetNodeId: field.id,
        geotagId,
      },
      user.recnum,
    );
    dispatch(modifyList(list.id, mod, persist));
  };

  getTabIndex() {
    const { mode } = this.props;
    return mode === LISTMODE_MAINTAIN ? '0' : undefined;
  }

  isSelected() {
    return this.props.field.id === this.props.focussedField;
  }

  copyAbove = () => {
    const { field } = this.props;
    const parent = this.props.index[field.parentNodeId];
    const index = parent.children.indexOf(field);
    this.copy(index);
  };
  copyBelow = () => {
    const { field } = this.props;
    const parent = this.props.index[field.parentNodeId];
    const index = parent.children.indexOf(field);
    this.copy(index + 1);
  };
  copy(index) {
    const { dispatch, list, field, user, mode, focussedField } = this.props;
    const parent = this.props.index[field.parentNodeId];
    const persist = mode === LISTMODE_NORMAL;

    const mod = copyNode(
      {
        targetNodeId: parent._id,
        subjectNodeId: focussedField,
        index,
      },
      user.recnum,
    );
    dispatch(modifyList(list.id, mod, persist));
  }
  moveAbove = () => {
    const { field } = this.props;
    const parent = this.props.index[field.parentNodeId];
    const index = parent.children.indexOf(field);
    this.move(index);
  };
  moveBelow = () => {
    const { field } = this.props;
    const parent = this.props.index[field.parentNodeId];
    const index = parent.children.indexOf(field);
    this.move(index + 1);
  };

  move(index) {
    const { dispatch, list, field, user, mode, focussedField } = this.props;
    const parent = this.props.index[field.parentNodeId];
    const persist = mode === LISTMODE_NORMAL;

    const mod = moveNode(
      {
        targetNodeId: parent._id,
        subjectNodeId: focussedField,
        index,
      },
      user.recnum,
    );
    dispatch(modifyList(list.id, mod, persist));
  }

  render() {
    const { mode } = this.props;
    if (mode === LISTMODE_REPORT) {
      return this.renderReport();
    } else {
      return this.renderUI();
    }
  }
}
