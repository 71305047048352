import { blobToArrayBuffer, arrayBufferToBlob } from 'blob-util';

export default class IndexedDbStorage {
  constructor(name) {
    this.name = name;
    this.db = new Promise(function(resolve, reject) {
      const request = indexedDB.open(name, 1);
      request.onerror = () => {
        alert('Kan bestandsdatabase niet aanmaken');
        reject(
          new Error(
            `Could not create file database (${request.error.message}(${request.error.code}))`,
          ),
        );
      };
      request.onsuccess = () => {
        resolve(request.result);
      };
      request.onupgradeneeded = event => {
        const db = event.target.result;
        db.createObjectStore('files', { autoincrement: true });
      };
    });
  }

  drop() {
    return this.db
      .then(db => {
        return new Promise((resolve, reject) => {
          db.close();
          db.onclose = resolve;
        });
      })
      .then(indexedDB.deleteDatabase(this.name));
  }

  purge() {
    return this.db.then(
      db =>
        new Promise((resolve, reject) => {
          const transaction = db.transaction('files', 'readwrite');
          const request = transaction.objectStore('files').clear();
          request.onsuccess = resolve;
          request.onerror = reject;
        }),
    );
  }

  setItem(key, blob) {
    return new Promise(async (resolve, reject) => {
      const ab = await blobToArrayBuffer(blob); // this is to support safari, ideally we'd use blobs
      const transaction = (await this.db).transaction('files', 'readwrite');
      const req = transaction.objectStore('files').put(ab, key);
      req.onsuccess = resolve;
      req.onerror = reject;
    });
  }
  getItem(key, contentType) {
    return new Promise(async (resolve, reject) => {
      const transaction = (await this.db).transaction('files');
      const req = transaction.objectStore('files').get(key);
      req.onsuccess = event =>
        arrayBufferToBlob(event.target.result, contentType).then(
          resolve,
          reject,
        );
      req.onerror = reject;
    });
  }
}
