import localForage from 'localforage';
import {
  applyMiddleware,
  compose,
  /*legacy_createStore as*/ createStore,
  Store,
} from 'redux';
import { apiMiddleware } from 'redux-api-middleware';
import {
  PersistConfig,
  Persistor,
  persistReducer,
  persistStore,
} from 'redux-persist';
import thunk from 'redux-thunk';
import { apiErrorMiddleware, attachmentStorageMiddleware } from '../middleware';
import createFileDownloadMiddleware from '../middleware/createFileDownloadMiddleware';
import createPurgeFilesMiddleware from '../middleware/createPurgeFilesMiddleware';
import IssueReportingMiddleware from '../middleware/IssueReportingMiddleware';
import createRootReducer from '../modules';
import { dropDataOnSignOut } from '../modules/dropDataOnSignOut';
import replaceState from '../modules/replaceState';
import fileStorage from '../services/fileStorage';
import { routerMiddleware } from './routerContext';

export interface StoreOptions {
  // create a store without local persistance layer
  tmp?: boolean;
  // hydrate the store with prior state
  initialState?: any;
}

export default function configureStore(
  opts: Partial<StoreOptions> = {},
): { store: Store; persistor?: Persistor } {
  const options = {
    tmp: false,
    ...opts,
  };

  let composeEnhancers;
  const extraMiddleware: any[] = [];

  if (process.env.NODE_ENV === 'development') {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  } else {
    composeEnhancers = compose;
  }

  const enhancers = [
    applyMiddleware(
      thunk,
      ...extraMiddleware,
      IssueReportingMiddleware(),
      apiMiddleware,
      apiErrorMiddleware,
      attachmentStorageMiddleware(),
      createFileDownloadMiddleware(fileStorage),
      createPurgeFilesMiddleware(fileStorage),
      routerMiddleware,
    ),
  ];

  const reducer = replaceState(dropDataOnSignOut(createRootReducer()));

  if (!options.tmp) {
    (window as any).localForage = localForage;

    const persistConfig: PersistConfig<any, any, any, any> = {
      key: 'root',
      blacklist: [
        'router',
        'online',
        'tickets',
        'lists',
        'meetings',
        'fileDownloads',
        'locations',
      ],
      throttle: 200,
      storage: localForage,
    };
    const persistedReducer = persistReducer(persistConfig, reducer);
    // TODO: migrate to redux toolkit
    const store = createStore(
      persistedReducer,
      options.initialState,
      composeEnhancers(...enhancers),
    );
    const persistor = persistStore(store);
    return {
      store,
      persistor,
    };
  }
  return {
    // TODO: migrate to redux toolkit
    store: createStore(
      reducer,
      options.initialState,
      composeEnhancers(...enhancers),
    ),
  };
}
