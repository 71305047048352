import { useEffect, useState } from 'react';

/**
 * ```js
 * // Usage
 * const App = () => {
 *   const [searchParam, setSearchParam] = useSearchParamState<string>('query', '');
 *
 *   const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
 *     setSearchParam(event.target.value);
 *   };
 *
 *   return (
 *     <div>
 *       <input type="text" value={searchParam} onChange={handleChange} />
 *       <p>Search parameter: {searchParam}</p>
 *     </div>
 *   );
 * };
 *
 * export default App;
 * ```
 */
const useSearchParam = <T extends string>(
  paramName: string,
  initialValue: T,
): [T, (value: T) => void] => {
  const [state, setState] = useState<T>(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const paramValue = searchParams.get(paramName);
    return (paramValue as T) || initialValue;
  });

  useEffect(() => {
    const handlePopstate = () => {
      const searchParams = new URLSearchParams(window.location.search);
      const paramValue = searchParams.get(paramName);
      setState((paramValue as T) || initialValue);
    };

    window.addEventListener('popstate', handlePopstate);

    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, [paramName, initialValue]);

  const updateState = (value: T) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set(paramName, value);

    const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
    window.history.replaceState({ path: newUrl }, '', newUrl);

    setState(value);
  };

  return [state, updateState];
};

export default useSearchParam;
