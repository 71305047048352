import { adjust } from 'ramda';
import { insert } from 'ramda';
import { remove } from 'ramda';
import { CALL_API } from 'redux-api-middleware';
import Types from 'Types';
import { ActionType, createStandardAction, getType } from 'typesafe-actions';
import Meeting, { makeMeeting, MeetingSection } from '../models/Meeting';
import { mapById, resolveHeaders, resolveUrl } from '../utils';

export interface RequestFetchMeetingsAction {
  type: 'REQUEST_FETCH_MEETINGS';
}
export interface SuccessFetchMeetingsAction {
  type: 'SUCCESS_FETCH_MEETINGS';
  payload: Meeting[];
}
export interface FailureFetchMeetingsAction {
  type: 'FAILURE_FETCH_MEETINGS';
  error: true;
}

export function fetchMeetings() {
  return {
    [CALL_API]: {
      endpoint: resolveUrl(`/meetings`),
      method: 'GET',
      headers: resolveHeaders(),
      types: [
        'REQUEST_FETCH_MEETINGS',
        'SUCCESS_FETCH_MEETINGS',
        'FAILURE_FETCH_MEETINGS',
      ],
    },
  };
}
export const REQUEST_SAVE_MEETING = 'REQUEST_SAVE_MEETING';
export const SUCCESS_SAVE_MEETING = 'SUCCESS_SAVE_MEETING';
export const FAILURE_SAVE_MEETING = 'FAILURE_SAVE_MEETING';
export interface RequestSaveMeetingAction {
  type: 'REQUEST_SAVE_MEETING';
}
export interface SuccessSaveMeetingAction {
  type: 'SUCCESS_SAVE_MEETING';
  payload: Meeting;
}
export interface FailureSaveMeetingAction {
  type: 'FAILURE_SAVE_MEETING';
  error: true;
}

export function saveMeeting(meeting: Meeting) {
  return {
    [CALL_API]: {
      endpoint: resolveUrl(`/meetings/${meeting.id}`),
      method: 'PUT',
      body: JSON.stringify(meeting),
      headers: resolveHeaders(),
      types: [REQUEST_SAVE_MEETING, SUCCESS_SAVE_MEETING, FAILURE_SAVE_MEETING],
    },
  };
}

export const REQUEST_DELETE_MEETING = 'REQUEST_DELETE_MEETING';
export const SUCCESS_DELETE_MEETING = 'SUCCESS_DELETE_MEETING';
export const FAILURE_DELETE_MEETING = 'FAILURE_DELETE_MEETING';
export interface RequestDeleteMeetingAction {
  type: 'REQUEST_DELETE_MEETING';
}
export interface SuccessDeleteMeetingAction {
  type: 'SUCCESS_DELETE_MEETING';
  payload: string;
}
export interface FailureDeleteMeetingAction {
  type: 'FAILURE_DELETE_MEETING';
  error: true;
}
export function deleteMeeting(meetingId: string) {
  return {
    [CALL_API]: {
      endpoint: resolveUrl(`/meetings/${meetingId}`),
      method: 'DELETE',
      headers: resolveHeaders(),
      types: [
        REQUEST_DELETE_MEETING,
        { type: SUCCESS_DELETE_MEETING, payload: meetingId },
        FAILURE_DELETE_MEETING,
      ],
    },
  };
}

export const CREATE_MEETING = 'CREATE_MEETING';
export const createMeeting = createStandardAction(CREATE_MEETING).map(
  (partial: Partial<Meeting> & Pick<Meeting, 'creator'>) => ({
    payload: makeMeeting(partial),
  }),
);

export const UPDATE_MEETING = 'UPDATE_MEETING';
export const updateMeeting = createStandardAction(UPDATE_MEETING)<
  Partial<Meeting> & { id: string; editor: number }
>();

export const ADD_MEETING_SECTION = 'ADD_MEETING_SECTION';
export const addMeetingSection = createStandardAction(ADD_MEETING_SECTION)<{
  meetingId: string;
  idx?: number;
  section: MeetingSection;
  editor: number;
  edited: Date;
}>();

export const UPDATE_MEETING_SECTION = 'UPDATE_MEETING_SECTION';
export const updateMeetingSection = createStandardAction(
  UPDATE_MEETING_SECTION,
)<{
  meetingId: string;
  idx: number;
  partialSection: Partial<MeetingSection>;
  editor: number;
  edited: Date;
}>();

export const DELETE_MEETING_SECTION = 'DELETE_MEETING_SECTION';
export const deleteMeetingSection = createStandardAction(
  DELETE_MEETING_SECTION,
)<{
  meetingId: string;
  idx: number;
  editor: number;
  edited: Date;
}>();

export const actions = {
  createMeeting,
  updateMeeting,
  addMeetingSection,
  updateMeetingSection,
  fetchMeetings,
  saveMeeting,
  deleteMeetingSection,
};

export type MeetingAction =
  | ActionType<typeof actions>
  | RequestFetchMeetingsAction
  | SuccessFetchMeetingsAction
  | FailureFetchMeetingsAction
  | RequestSaveMeetingAction
  | SuccessSaveMeetingAction
  | FailureSaveMeetingAction
  | RequestDeleteMeetingAction
  | SuccessDeleteMeetingAction
  | FailureDeleteMeetingAction;

export interface MeetingState {
  [id: string]: Meeting & { _changed?: boolean };
}

export default function meetings(
  state: MeetingState = {},
  action: Types.RootAction,
): MeetingState {
  if (getType(createMeeting) === action.type) {
    return {
      ...state,
      [action.payload.id]: { ...action.payload, _changed: true },
    };
  } else if (UPDATE_MEETING === action.type) {
    const { id, ...partial } = action.payload;
    return {
      ...state,
      [id]: {
        ...state[id],
        ...partial,
        _changed: true,
      },
    };
  } else if (ADD_MEETING_SECTION === action.type) {
    const { meetingId, idx, section, editor, edited } = action.payload;
    return {
      ...state,
      [meetingId]: {
        ...state[meetingId],
        editor,
        edited,
        sections: insert(
          idx || state[meetingId].sections.length,
          section,
          state[meetingId].sections,
        ),
        _changed: true,
      },
    };
  } else if (UPDATE_MEETING_SECTION === action.type) {
    const { meetingId, idx, partialSection, editor, edited } = action.payload;
    return {
      ...state,
      [meetingId]: {
        ...state[meetingId],
        editor,
        edited,
        sections: adjust(
          idx,
          s => ({ ...s, ...partialSection }),
          state[meetingId].sections,
        ),
        _changed: true,
      },
    };
  } else if (DELETE_MEETING_SECTION === action.type) {
    const { meetingId, idx, editor, edited } = action.payload;
    return {
      ...state,
      [meetingId]: {
        ...state[meetingId],
        editor,
        edited,
        sections: remove(idx, 1, state[meetingId].sections),
        _changed: true,
      },
    };
  } else if (action.type === 'SUCCESS_FETCH_MEETINGS') {
    const meetings = action.payload;
    // dont overwrite changed
    const changed = Object.values(state).filter(m => m._changed);
    return { ...mapById(meetings), ...mapById(changed) };
  } else if (action.type === 'SUCCESS_SAVE_MEETING') {
    const meeting = action.payload;
    return {
      ...state,
      [meeting.id]: meeting,
    };
  } else if (action.type === 'SUCCESS_DELETE_MEETING') {
    const newState = { ...state };
    delete newState[action.payload];
    return state;
  }
  // softAssertNever(action);
  return state;
}
