import { CountingRequest } from '@certhon/domain-models';
import React, { useReducer } from 'react';
import { DTO, MakeOptionalExcept } from '../common/logic/types';
import { createUsableSingleton } from '../hooks/createUsableSingleton';
import { assertNever } from '../modules/utils';
import { mapByRecnum } from '../utils';
import { resolveHeaders, resolveUrl } from '../utils/request';
import { verifyResponse } from '../utils/verifyResponse';

function parseCountingRequestDTO({
  end_date,
  start_date,
  created,
  modified,
  filter,
  ...rest
}: DTO<CountingRequest>): CountingRequest {
  return {
    ...rest,
    filter: {
      sample: null,
      unsalable: false,
      ...(filter as any),
    },
    end_date: new Date(end_date),
    start_date: new Date(start_date),
    modified: new Date(modified),
    created: new Date(created),
  };
}

export interface CountingState {
  countingRequests: Record<number, CountingRequest>;
  hasFetchedAll: boolean;
}

export type UpdatableCountingRequest = MakeOptionalExcept<
  Pick<
    CountingRequest,
    'name' | 'start_date' | 'end_date' | 'locations' | 'recnum' | 'filter'
  >,
  'recnum'
>;
export type InsertableCountingRequest = Pick<
  CountingRequest,
  'name' | 'start_date' | 'end_date' | 'locations' | 'filter'
>;
export type SavableCountingRequest =
  | InsertableCountingRequest
  | UpdatableCountingRequest;

type CountingAction =
  | { type: 'fetch_counting_requests'; data: CountingRequest[] }
  | { type: 'fetch_counting_request'; data: CountingRequest }
  | { type: 'delete_counting_request'; data: number };

function reducer(state: CountingState, action: CountingAction): CountingState {
  const { type, data } = action;
  if (type === 'fetch_counting_requests') {
    return {
      ...state,
      countingRequests: mapByRecnum(data),
      hasFetchedAll: true,
    };
  } else if (type === 'fetch_counting_request') {
    return {
      ...state,
      countingRequests: {
        ...state.countingRequests,
        [data.recnum]: data,
      },
    };
  } else if (type === 'delete_counting_request') {
    const newState = {
      ...state,
      countingRequests: { ...state.countingRequests },
    };
    delete newState.countingRequests[data];
    return newState;
  }
  assertNever(type);
}

export const [
  CountingRequestStoreProvider,
  useCountingRequestStore,
] = createUsableSingleton(() => {
  const [state, dispatch] = useReducer(reducer, {
    countingRequests: {},
    hasFetchedAll: false,
  });
  const inf = React.useMemo(
    () => ({
      async save(savableCountingRequest: SavableCountingRequest) {
        let response;
        if ('recnum' in savableCountingRequest) {
          response = await fetch(
            resolveUrl(`/counting_requests/${savableCountingRequest.recnum}`),
            {
              credentials: 'same-origin',
              headers: resolveHeaders()(),
              body: JSON.stringify({
                ...savableCountingRequest,
              }),
              method: 'PATCH',
            },
          );
        } else {
          response = await fetch(resolveUrl('/counting_requests'), {
            credentials: 'same-origin',
            body: JSON.stringify(savableCountingRequest),
            headers: resolveHeaders()(),
            method: 'POST',
          });
        }
        await verifyResponse(response);
        const data = await response.json().then(parseCountingRequestDTO);

        dispatch({
          type: 'fetch_counting_request',
          data,
        });
        return data;
      },
      async fetch(recnum?: number) {
        const response = await fetch(
          resolveUrl(
            recnum ? `/counting_requests/${recnum}` : '/counting_requests',
          ),
          {
            credentials: 'same-origin',
            headers: resolveHeaders()(),
          },
        );
        await verifyResponse(response);
        const data = await response.json();
        dispatch({
          type: recnum ? 'fetch_counting_request' : 'fetch_counting_requests',
          data: recnum
            ? parseCountingRequestDTO(data)
            : data.map(parseCountingRequestDTO),
        });
      },
      async remove(recnum: number) {
        const response = await fetch(
          resolveUrl(`/counting_requests/${recnum}`),
          {
            credentials: 'same-origin',
            headers: resolveHeaders()(),
            method: 'DELETE',
          },
        );
        await verifyResponse(response);
        dispatch({
          type: 'delete_counting_request',
          data: recnum,
        });
      },
    }),
    [],
  );

  return React.useMemo(() => [state, inf] as const, [state, inf]);
});
