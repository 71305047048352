import { CountingRequest, Location } from '@certhon/domain-models';
import { Moment } from 'moment';
import React, {
  ChangeEventHandler,
  FormEvent,
  FormEventHandler,
  useCallback,
} from 'react';
import {
  Alert,
  Button,
  Checkbox,
  ControlLabel,
  FormControl,
  FormGroup,
} from 'react-bootstrap';
import ReactDateTime from 'react-datetime';
import { replaceNumberingPlaceholder } from '../common/location/replaceNumberingPlaceholder';
import { UpdatableCountingRequest } from '../stores/countingRequestStore';

interface CountingRequestDetailsEditorProps {
  error: Error | null;
  onChange: (cr: Partial<UpdatableCountingRequest>) => void;
  onSubmit?: (ev: FormEvent) => void;
  onRemove?: () => void;
  rootLocations: Location[];
  isCountingRequestAdmin: boolean;
  countingRequest: Pick<
    CountingRequest,
    'name' | 'start_date' | 'end_date' | 'locations' | 'filter'
  >;
}
const CountingRequestDetailsEditor: React.FC<CountingRequestDetailsEditorProps> = ({
  countingRequest: { name, end_date, locations, start_date, filter },
  error,
  isCountingRequestAdmin,
  onChange,
  onRemove,
  onSubmit,
  rootLocations,
}) => {
  const isNormalSelectionSet = React.useMemo(
    () =>
      locations.length === 0 ||
      locations.some(id => rootLocations.some(loc => loc.id === id)),
    [locations, rootLocations],
  );

  /*
   * Change callbacks
   */
  const handleChange = useCallback<FormEventHandler<any>>(
    ev => {
      onChange({ [ev.currentTarget.name]: ev.currentTarget.value });
    },
    [onChange],
  );
  const handleStartDateChange = React.useCallback(
    (date: Moment | string) => {
      if (!(typeof date === 'string')) {
        onChange({ start_date: date.toDate() });
      }
    },
    [onChange],
  );
  const handleEndDateChange = React.useCallback(
    (date: Moment | string) => {
      if (!(typeof date === 'string')) {
        onChange({ end_date: date.toDate() });
      }
    },
    [onChange],
  );
  const handleLocationsChange = useCallback<
    ChangeEventHandler<HTMLSelectElement>
  >(
    ev => {
      onChange({
        locations: Array.from(ev.currentTarget.selectedOptions).map(
          o => o.value!,
        ),
      });
    },
    [onChange],
  );

  return (
    <form
      onSubmit={ev => {
        ev.preventDefault();
        onSubmit?.(ev);
      }}
    >
      {error && <Alert bsStyle="danger">{error.message}</Alert>}
      <div className="row">
        <div className="col-xs-12">
          <FormGroup controlId="formBasicText">
            <ControlLabel>Name</ControlLabel>
            <FormControl
              type="text"
              value={name}
              name="name"
              disabled={!isCountingRequestAdmin}
              placeholder="Enter name"
              onChange={handleChange}
            />
          </FormGroup>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-6">
          <FormGroup controlId="formBasicText">
            <ControlLabel>Start date</ControlLabel>
            <ReactDateTime
              timeFormat={false}
              dateFormat="D MMMM YYYY"
              onChange={handleStartDateChange}
              inputProps={{
                name: 'start_date',
                disabled: !isCountingRequestAdmin,
              }}
              value={start_date}
            />
          </FormGroup>
        </div>
        <div className="col-xs-6">
          <FormGroup controlId="formBasicText">
            <ControlLabel>End date</ControlLabel>
            <style>{`.right .rdtPicker {right: 0;}`}</style>
            <ReactDateTime
              timeFormat={false}
              className="right"
              dateFormat="D MMMM YYYY"
              onChange={handleEndDateChange}
              inputProps={{
                name: 'end_date',
                disabled: !isCountingRequestAdmin,
              }}
              value={end_date}
            />
          </FormGroup>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <FormGroup controlId="formBasicText">
            <ControlLabel>Locations</ControlLabel>
            {isNormalSelectionSet ? (
              <FormControl
                componentClass="select"
                multiple
                value={locations}
                name="locations"
                onChange={handleLocationsChange as any}
                disabled={!isCountingRequestAdmin}
              >
                {rootLocations.map(l => (
                  <option value={l.id}>
                    {replaceNumberingPlaceholder(l.name, l.ordering)}
                  </option>
                ))}
              </FormControl>
            ) : (
              <p className="help-block">
                This counting request has a special set of {locations.length}{' '}
                non-root locations, which can not be altered.
              </p>
            )}
          </FormGroup>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <FormGroup controlId="formBasicText">
            <ControlLabel>Incourant wel/niet tellen</ControlLabel>
            <FormControl
              componentClass="select"
              value={JSON.stringify(filter.unsalable)}
              disabled={!isCountingRequestAdmin}
              onChange={
                ((ev =>
                  onChange({
                    filter: {
                      ...filter,
                      unsalable: JSON.parse(ev.currentTarget.value),
                    },
                  })) as ChangeEventHandler<HTMLSelectElement>) as any
              }
            >
              {[
                ['Wel', 'true'],
                ['Niet', 'false'],
                ['Beide', 'null'],
              ].map(([label, value]) => (
                <option value={value}>{label}</option>
              ))}
            </FormControl>
          </FormGroup>
          <FormGroup controlId="formBasicText">
            <Checkbox
              checked={filter.samplePercentage !== null}
              disabled={!isCountingRequestAdmin}
              onChange={
                ((ev =>
                  onChange({
                    filter: {
                      ...filter,
                      samplePercentage: ev.currentTarget.checked ? 0.05 : null,
                    },
                  })) as ChangeEventHandler<HTMLInputElement>) as any
              }
            >
              Steekproef
            </Checkbox>
          </FormGroup>
          {filter.samplePercentage !== null && (
            <FormGroup>
              <ControlLabel>Percentage</ControlLabel>
              <FormControl
                type="number"
                inputMode="numeric"
                disabled={!isCountingRequestAdmin}
                value={Math.round(filter.samplePercentage * 100)}
                onChange={
                  ((ev =>
                    onChange({
                      filter: {
                        ...filter,
                        samplePercentage:
                          parseFloat(ev.currentTarget.value as any) / 100,
                      },
                    })) as ChangeEventHandler<HTMLInputElement>) as any
                }
              ></FormControl>
            </FormGroup>
          )}
        </div>
      </div>

      {/* <Json>{{ filter }}</Json> */}
      {isCountingRequestAdmin && (
        <Button disabled={!onSubmit} type="submit">
          submit
        </Button>
      )}
      {onRemove && isCountingRequestAdmin && (
        <Button
          type="button"
          className="pull-right btn-link danger"
          onClick={() => {
            // eslint-disable-next-line no-restricted-globals
            confirm(
              'Do you want to permanently delete this counting request?',
            ) && onRemove();
          }}
        >
          delete
        </Button>
      )}
    </form>
  );
};

export default CountingRequestDetailsEditor;
