import React from 'react';

type Props = {
  children?: any;
  value?: any;
};

const Json: React.FC<Props> = ({ children, value }) => {
  return <pre>{JSON.stringify(children || value, null, 2)}</pre>;
};

export default Json;
