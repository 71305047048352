import * as React from 'react';

const CustomContext = React.createContext<boolean>(false);

export const useCustomContext = () => React.useContext(CustomContext);

const CustomContextProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [altKeyDown, setAltKeyDown] = React.useState<boolean>(false);
  const toggle = React.useCallback((event: KeyboardEvent) => {
    if (event.key === 'Alt') {
      setAltKeyDown(v => !v);
    }
  }, []);

  React.useEffect(() => {
    document.addEventListener('keydown', toggle);
    document.addEventListener('keyup', toggle);

    return () => {
      document.removeEventListener('keydown', toggle);
      document.removeEventListener('keyup', toggle);
    };
  }, []);

  return (
    <CustomContext.Provider value={altKeyDown}>
      {children}
    </CustomContext.Provider>
  );
};

export default CustomContextProvider;
