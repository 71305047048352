import * as React from 'react';
import { Button, Modal, ModalBody } from 'react-bootstrap';
import { Dispatch } from 'redux';
import { Link } from 'wouter';
import { useDevProjectContext } from '../../hooks/useDevProjectContext';
import { User } from '../../models/User';
import { useUpdateService } from '../../services/ServiceProvider';
import CerthonMarkdown from '../Markdown';
import ReportButton from '../ReportButton/ReportButton';
import AppStatus from './AppStatus';
import stl from './Page.module.scss';

interface Props {
  dispatch: Dispatch;
  user: User | null;
  [x: string]: any; //sigh
}

const Footer: React.FC<Props> = ({ dispatch, user, ...rest }) => {
  const changelog = React.useMemo(() => {
    if (
      !process.env.REACT_APP_CHANGELOG ||
      typeof process.env.REACT_APP_CHANGELOG !== 'string'
    ) {
      return '*no changelog*';
    }
    return process.env.REACT_APP_CHANGELOG.replace(/\\n/g, '\n').replace(
      /\n/g,
      '\n\n',
    );
  }, []);

  const [showNotes, setShowNotes] = React.useState<boolean>(false);
  const updateService = useUpdateService();

  const [users, allTickets, localTickets] = useDevProjectContext();

  const toggleShowNotes = React.useCallback(() => setShowNotes(show => !show), [
    setShowNotes,
  ]);

  return (
    <>
      <footer id="footer">
        <div className="container">
          <div className="navbar-text navbar-right">
            <Link to={`/docs`}>
              <i className="fa fa-book"></i> Documentation
            </Link>
          </div>
          <ReportButton className="navbar-btn navbar-right" />
          <button
            className={'navbar-btn btn btn-link navbar-right'}
            onClick={toggleShowNotes}
          >
            <i className="fa fa-tag"></i> {process.env.REACT_APP_VERSION}{' '}
            {updateService?.updateAvailable && (
              <i className="fa fa-exclamation-circle text-warning"></i>
            )}
          </button>
          <AppStatus className="navbar-btn" {...rest} user={user} />
        </div>
      </footer>
      <Modal show={showNotes} onHide={toggleShowNotes}>
        <Modal.Header closeButton={true}>Changelog</Modal.Header>
        <ModalBody>
          {updateService?.updateAvailable && (
            <>
              <span style={{ display: 'inline-block', padding: 6 }}>
                Release {updateService?.availableVersion} is installed and ready
                to upgrade.
              </span>
              <Button
                onClick={() => updateService?.updateServiceWorker()}
                className="pull-right"
                bsStyle="warning"
              >
                Upgrade now
              </Button>
              <hr />
            </>
          )}

          <CerthonMarkdown
            allTickets={allTickets}
            localTickets={localTickets}
            users={users}
            className={stl.changelogMarkdown}
          >
            {changelog}
          </CerthonMarkdown>
        </ModalBody>
      </Modal>
    </>
  );
};

export default Footer;
