import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { Component } from 'react';
import { Col, Grid } from 'react-bootstrap';
import {
  defineMessages,
  FormattedMessage,
  injectIntl,
  IntlProvider,
} from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'wouter';
import {
  createPartialCheckpointField,
  createPartialGroupField,
  doesListHaveUnsavedModifications,
  expandProtoList,
  insertNode,
} from '../../common/list';
import {
  getParamsFromTags,
  parametersToTags,
  userListTagRegex,
} from '../../common/logic/list';
import { fetchFeedbackForList } from '../../modules/feedback';
import {
  fetchLists,
  flagPersist,
  modifyList,
  updateList,
} from '../../modules/lists';
import { fetchUsers } from '../../modules/users';
import { projectManagerOnly } from '../../utils/auth';
import List, { LISTMODE_MAINTAIN } from '../List';
import Page from '../Page';
import {
  parseParameters,
  serializeParameters,
} from '../ParameterizedSearch/model';
import Spinner from '../Spinner';
import WithBuiltList from '../WithBuiltList';
import ExpandProtoDialog from './ExpandProtoDialog';
import PropertyPanel from './PropertyPanel';
import styles from './TemplateDetails.module.scss';

const msg = defineMessages({
  TEMPLATE_NOT_FOUNND_l1: {
    id: 'TEMPLATE_DETAILS.TEMPLATE_NOT_FOUNND_l1',
    defaultMessage: 'Template can not be found;',
    description: '',
  },
  TEMPLATE_NOT_FOUNND_l2: {
    id: 'TEMPLATE_DETAILS.TEMPLATE_NOT_FOUNND_l2',
    defaultMessage: 'Go back to overview',
    description: '',
  },
});

export class ListDetails extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    lists: PropTypes.object,
    editor: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
  };

  static contextTypes = {
    syncService: PropTypes.object.isRequired,
  };

  routerWillLeave = nextLocation => {
    const {
      lists,
      params: { listId },
    } = this.props;
    const list = lists[listId];
    if (
      list &&
      doesListHaveUnsavedModifications(list) &&
      !nextLocation.pathname.includes(`/template/${listId}`)
    ) {
      return 'Niet alle wijzigingen zijn opgeslagen. Weet je zeker dat je deze pagina wil verlaten?';
    }
  };

  constructor(props) {
    super(props);

    this.state = {
      showExpandProtoDialog: false,
      expandProtoTargetFieldId: null,
      tags: '',
      name: '',
      locale: props.intl.locale,
      ...this.getListState(),
    };
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.params.listId !== this.props.params.listId ||
      (nextProps.lists && !this.props.lists)
    ) {
      this.setState(this.getListState());
    }
  }

  getListState() {
    const {
      lists,
      params: { listId },
    } = this.props;
    const list = lists[listId];

    return {
      name: list.name,
      tags: serializeParameters(getParamsFromTags(list.tags)),
    };
  }

  handleChangeTags = tags => {
    this.setState({ tags });
  };

  handleChangeName = event => {
    this.setState({ name: event.target.value });
  };

  handleChangeLocale = ({ target: { value: locale } }) => {
    this.setState({ locale });
  };

  async componentWillMount() {
    const {
      dispatch,
      params: { listId },
    } = this.props;

    this.props.dispatch(fetchUsers());
    await dispatch(fetchLists({ id: listId }));
    this.props.dispatch(fetchFeedbackForList(listId));
  }

  handleSaveList = () => {
    const { name, tags: tagsString } = this.state;
    const {
      lists,
      dispatch,
      params: { listId },
    } = this.props;
    const list = lists[listId];

    const parsedTags = parametersToTags(parseParameters(tagsString));
    const nonUserEditableTags = list.tags.filter(
      t => !userListTagRegex.test(t),
    );
    const newTags = [...parsedTags, ...nonUserEditableTags];

    const mod = {};

    if (list.name !== name) {
      mod.name = name;
    }
    if (!R.equals(R.sort(newTags), R.sort(list.tags))) {
      mod.tags = newTags;
    }
    if (Object.values(mod).length > 0) {
      dispatch(updateList(list.id, mod, this.props.user.recnum));
    }
    dispatch(flagPersist(list.id));
    this.context.syncService.update();
  };

  renderPropertyPanel(index, list, filteredFeedback) {
    const {
      dispatch,
      user,
      users,
      lists,
      params: { nodeId },
    } = this.props;
    const { name, tags, locale } = this.state;

    return (
      <PropertyPanel
        field={index[nodeId]}
        dispatch={dispatch}
        user={user}
        users={users}
        list={list}
        lists={lists}
        index={index}
        feedback={filteredFeedback}
        name={name}
        tags={tags}
        onNameChange={this.handleChangeName}
        onTagsChange={this.handleChangeTags}
        locale={locale}
        onChangeLocale={this.handleChangeLocale}
        onSave={this.handleSaveList}
        tagsChanged={tags !== serializeParameters(getParamsFromTags(list.tags))}
      />
    );
  }

  handleRequestExpandProto = (
    expandProtoTargetFieldId,
    expandProtoTargetField,
  ) => {
    this.setState({
      showExpandProtoDialog: true,
      expandProtoTargetFieldId,
      expandProtoTargetField,
    });
  };

  handleCancelRequestExpandProto = () => {
    this.setState({
      showExpandProtoDialog: false,
      expandProtoTargetFieldId: null,
      expandProtoTargetField: null,
    });
  };

  handleSubmitExpandProto = protoListId => {
    const {
      dispatch,
      lists,
      user,
      params: { listId },
    } = this.props;
    if (!lists || !lists[listId]) {
      throw new Error('List not available');
    }
    const list = lists[listId];

    const { expandProtoTargetFieldId: targetNodeId } = this.state;

    const mod = expandProtoList(
      {
        targetNodeId,
        protoListId,
      },
      user.recnum,
    );
    dispatch(modifyList(list.id, mod));

    this.handleCancelRequestExpandProto();
  };

  handlePastedData = event => {
    const {
      dispatch,
      lists,
      user,
      params: { listId },
    } = this.props;
    const list = lists[listId];
    const data = event.clipboardData.getData('text');
    if (data.indexOf('\t') !== -1) {
      event.preventDefault();
      // parse tabular data
      const lines = data.split(/(\r|\n)/);
      let groupMod;
      const mods = [];
      lines
        .filter(l => !!l.trim())
        .map(l => l.split(/\t/g))
        .map(l => l.map(f => f.trim()))
        .map(
          ([
            group_nl,
            group_en,
            title_nl,
            title_en,
            description_nl,
            description_en,
          ]) => ({
            group_nl,
            group_en,
            title_nl,
            title_en,
            description_nl,
            description_en,
          }),
        )
        .forEach(cp => {
          if (!groupMod || groupMod.payload.node.title_nl !== cp.group_nl) {
            // create a new group to add checkpoints to
            groupMod = insertNode(
              {
                targetNodeId: list.id,
                node: createPartialGroupField({
                  title_nl: cp.group_nl,
                  title_en: cp.group_en,
                }),
              },
              user.recnum,
            );
            mods.push(groupMod);
          }
          // create the checkpoint
          mods.push(
            insertNode(
              {
                targetNodeId: groupMod.payload.node.id,
                node: createPartialCheckpointField(
                  R.pick(
                    [
                      'title_nl',
                      'title_en',
                      'description_nl',
                      'description_en',
                    ],
                    cp,
                  ),
                ),
              },
              user.recnum,
            ),
          );
        });
      mods.map(mod => modifyList(list.id, mod)).forEach(dispatch);
    }
  };

  componentDidMount() {
    // FIXME: fix route leave confirmation
    // this.props.router.setRouteLeaveHook(this.props.route, this.routerWillLeave);
    window.document.body.addEventListener('paste', this.handlePastedData);
  }

  componentWillUnmount() {
    window.document.body.removeEventListener('paste', this.handlePastedData);
  }

  render() {
    const {
      params: { nodeId, listId },
    } = this.props;
    return (
      <WithBuiltList listId={listId}>
        {builtList => {
          const { lists, dispatch, user, feedback } = this.props;
          const {
            showExpandProtoDialog,
            expandProtoTargetField,
            locale,
          } = this.state;

          let content;
          let list;
          if (lists && lists[listId]) {
            list = lists[listId];
          }

          const filteredFeedback = Object.values(feedback).filter(
            f => builtList && builtList.id === f.proto_list_id,
          );
          if (builtList) {
            content = (
              <Grid fluid>
                <Col xs={7}>
                  <div className={styles.listView}>
                    <IntlProvider locale={locale}>
                      <List
                        list={builtList}
                        mode={LISTMODE_MAINTAIN}
                        tree={builtList.tree}
                        index={builtList.index}
                        dispatch={dispatch}
                        focussedField={nodeId}
                        user={user}
                        onRequestExpandProto={this.handleRequestExpandProto}
                      />
                    </IntlProvider>
                  </div>
                </Col>
                <Col xs={5}>
                  {this.renderPropertyPanel(
                    builtList.index,
                    list,
                    filteredFeedback,
                  )}
                </Col>
              </Grid>
            );
          } else if (lists && !list) {
            content = (
              <center>
                <p>
                  <FormattedMessage {...msg.TEMPLATE_NOT_FOUNND_l1} />;{' '}
                  <Link to="/templates">
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a>
                      <FormattedMessage {...msg.TEMPLATE_NOT_FOUNND_l2} />
                    </a>
                  </Link>{' '}
                </p>
              </center>
            );
          } else {
            content = <Spinner />;
          }

          return (
            <Page {...this.props} title="Sjabloon" fluid>
              {content}
              {showExpandProtoDialog && (
                <ExpandProtoDialog
                  targetListId={list.id}
                  field={expandProtoTargetField}
                  lists={lists}
                  onCancel={this.handleCancelRequestExpandProto}
                  onSubmit={this.handleSubmitExpandProto}
                />
              )}
            </Page>
          );
        }}
      </WithBuiltList>
    );
  }
}

export default R.pipe(
  injectIntl,
  connect(({ lists, user, users, editor, feedback }) => ({
    user,
    users,
    lists,
    editor,
    feedback,
  })),
)(projectManagerOnly(ListDetails));
