import { PermissionsObject } from '@certhon/cloud-permissions/lib'; // FIXME: why lib
import * as React from 'react';

interface InventoryCounterOnlyProps {
  user: { internal: boolean; permissions: PermissionsObject } | null;
  children: any;
}

const InventoryCounterOnly: React.FC<InventoryCounterOnlyProps> = ({
  user,
  children,
}) => {
  if (user?.internal || user?.permissions.INVENTORY_COUNTER) {
    return children;
  }
  return null;
};

export default InventoryCounterOnly;
