import { insert } from 'ramda';
import * as uuid from 'uuid';
import { ObjectMap } from '../../../logic/types';
import { Field } from '../../types';
import {
  createModificationCreator,
  ListEventReducer,
} from '../ListModification';
import { patchListNode } from '../util';

const uuidv5 = uuid.v5;

export interface CopyPayload {
  targetNodeId: string;
  subjectNodeId: string;
  index?: number;
}

export const copyNode = createModificationCreator<CopyPayload, 'COPY'>('COPY');

export type CopyListModification = ReturnType<typeof copyNode>;

const copy: ListEventReducer<CopyListModification> = (
  list,
  mod: CopyListModification,
) => {
  const {
    payload: { index = -1, subjectNodeId, targetNodeId },
  } = mod;

  const subjectNode = list.index[subjectNodeId];
  if (!subjectNode) {
    // dont do anything if the node could not be found
    // this can happen when modifications are merged
    return list;
  }
  if (!subjectNode.parentNodeId) {
    throw new Error('Unexpected copy-subject without parentNodeId');
  }

  // create copy of subjectNode, generate new ids for it and its children
  const newNodes: ObjectMap<Field> = {};
  const namespace = mod.id;
  function newIds(node: Field, parentNodeId: string) {
    const id = uuidv5(namespace, node.id);
    const innerNewNode = {
      ...node,
      id,
      parentNodeId,
    };
    if (innerNewNode.type === 'ROOT_FIELD') {
      throw new Error(
        `Unexpected node type in copy-subject children (${node.type})`,
      );
    }
    if (innerNewNode.type === 'GROUP_FIELD') {
      innerNewNode.children = innerNewNode.children.map(child =>
        newIds(child, id),
      );
    }

    newNodes[id] = innerNewNode;

    return innerNewNode;
  }

  const newNode = newIds(subjectNode, targetNodeId);
  let found = false;

  const newList = patchListNode(list, targetNodeId, parentNode => {
    found = true;
    if (parentNode.type !== 'GROUP_FIELD' && parentNode.type !== 'ROOT_FIELD') {
      throw new Error(`Unexpected targetNode type (${parentNode.type})`);
    }

    return {
      //  modify the targetNode to include the copied node
      ...parentNode,
      children: insert(
        index === -1 ? parentNode.children.length : index,
        newNode,
        parentNode.children,
      ),
    };
  });

  if (found) {
    // add the subject and its children to the index
    newList.index = { ...newNodes, ...newList.index };
  }

  return newList;
};

export default copy;
