import React from 'react';
import Json from '../components/Json';

interface ViewPortTestProps {}
const ViewPortTest: React.FC<ViewPortTestProps> = ({}) => {
  const [render, setRender] = React.useState<number>(1);
  const rerender = React.useCallback(() => setRender(x => x + 1), []);
  React.useEffect(() => {
    window.addEventListener('resize', rerender);
    window.addEventListener('scroll', rerender);
    return () => {
      window.removeEventListener('resize', rerender);
      window.removeEventListener('scroll', rerender);
    };
  }, []);

  return (
    <div>
      <Json>
        {{
          layout: render,
          innerHeight: window.innerHeight,
          innerWidth: window.innerWidth,
          outerHeight: window.outerHeight,
          outerWidth: window.outerWidth,
          screen: window.screen,
          screenLeft: window.screenLeft,
          screenTop: window.screenTop,
          screenX: window.screenX,
          screenY: window.screenY,
          scrollX: window.scrollX,
          scrollY: window.scrollY,
          visualViewport: {
            height: window.visualViewport?.height,
            offsetLeft: window.visualViewport?.offsetLeft,
            offsetTop: window.visualViewport?.offsetTop,
            pageLeft: window.visualViewport?.pageLeft,
            pageTop: window.visualViewport?.pageTop,
            scale: window.visualViewport?.scale,
            width: window.visualViewport?.width,
          },
        }}
      </Json>
      <input onFocus={rerender} onBlur={rerender} type="text" />
    </div>
  );
};

export default ViewPortTest;
