import { RelativeTimeFormatSingularUnit } from '@formatjs/ecma402-abstract';
import classNames from 'classnames';
import * as React from 'react';
import {
  FormattedDate,
  FormattedRelativeTime as FormattedRelativeOriginal,
  FormattedTime,
} from 'react-intl';
import { Props as OrigProps } from 'react-intl/src/components/relative';
import { useCustomContext } from '../CustomContextProvicer';
import stl from './FormattedRelative.module.scss';

function determineRelativeTime(
  inputDate: Date | string | number,
): { value: number; unit: RelativeTimeFormatSingularUnit } {
  const currentDate = new Date();
  const targetDate = new Date(inputDate);
  const timeDifference = +targetDate - +currentDate;
  const absTimeDifference = Math.abs(timeDifference);
  const minute = 60 * 1000;
  const hour = 60 * minute;
  const day = 24 * hour;
  const week = 7 * day;
  const month = 30 * day;
  const year = 365 * day;

  if (absTimeDifference < minute) {
    const seconds = Math.round(timeDifference / 1000);
    return { unit: 'second', value: seconds };
  } else if (absTimeDifference < hour) {
    const minutes = Math.round(timeDifference / minute);
    return { unit: 'minute', value: minutes };
  } else if (absTimeDifference < day) {
    const hours = Math.round(timeDifference / hour);
    return { unit: 'hour', value: hours };
  } else if (absTimeDifference < week) {
    const days = Math.round(timeDifference / day);
    return { unit: 'day', value: days };
  } else if (absTimeDifference < month) {
    const weeks = Math.round(timeDifference / week);
    return { unit: 'week', value: weeks };
  } else if (absTimeDifference < year) {
    const months = Math.round(timeDifference / month);
    return { unit: 'month', value: months };
  } else {
    const years = Math.round(timeDifference / year);
    return { unit: 'year', value: years };
  }
}

interface Props extends Omit<OrigProps, 'value'> {
  value: number | string | Date;
  className?: string;
  time?: boolean;
}

const FormattedRelative: React.FC<Props> = props => {
  const { value: date, time = true, ...rest } = props;
  const altKeyDown = useCustomContext();
  const { value, unit } = determineRelativeTime(date);
  return (
    <span
      className={classNames(stl.root, altKeyDown && stl.alt, rest.className)}
      title={date.toLocaleString()}
    >
      <FormattedRelativeOriginal
        {...rest}
        value={value}
        unit={unit}
        numeric="auto"
      >
        {props => <span className={stl.relative} children={props} />}
      </FormattedRelativeOriginal>
      <FormattedDate {...props}>
        {(props: any) => <span className={stl.absolute} children={props} />}
      </FormattedDate>{' '}
      {time !== false && (
        <FormattedTime {...props}>
          {(props: any) => <span className={stl.absolute} children={props} />}
        </FormattedTime>
      )}
    </span>
  );
};

export default FormattedRelative;
