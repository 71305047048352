/** *
 * we want to complete several entity references and commands; all denoted by a unique prefix-symbol.
 * abstractly we consider it as a tree of posibilities and offer suggestions to the user to progress in this tree.
 *
 * example:
 *  - '@'                             // user
 *    - jan
 *    - piet
 *    - klaas
 *
 *  - '#'                             // ticket
 *    - 153 Kapotte schoen
 *    - 183 vleesetende jus
 *    - Nieuw ticket direct aanmaken  // generic text, requires submission
 *
 *  - '/'
 *    - 'assign'
 *      - @                           // user
 *        - jan
 *        - piet
 *        - klaas
 *    - 'executor'
 *      - @                           // user
 *        - jan
 *        - piet
 *        - klaas
 *    - 'close'
 *    - 'open'
 *    - 'title'
 *      - Nieuwe titel                // generic text, free
 *    - 'create_ticket'
 *      - Nieuwe ticket               // generic text, free
 *    - 'due'
 *      - 1w                          // generic text, parsing constraint
 *      - 12 maart
 *      - march 12th
 *      - 3-12
 *
 *
 *  note that:
 *    - the 'user' branch occurs multiple times
 *    - some leaves allow generic text input
 *       - some of which require submission at the end of the
 *       - some of which need to be parsed/validated
 *       - and some can be entered freely
 *
 *  during the user writing the text and after submission, we need to also parse these entity references and commands according to the same tree,
 *  therefore we should be able to use the same abstraction for both completion and parsing.
 *
 */

import regEscape from 'escape-string-regexp';

export interface Completion {
  text: string;
  data?: any;
}

export interface Lex {
  id: string;
  match: RegExp;
  incompleteMatch?: RegExp;
  activate?: RegExp;
  next?: Lex[];
  getCompletions?: (text: string) => Completion[];
  argumentNames?: string[];
}

export interface LexMatch {
  id: string;
  text: string;
  index: number;
  totalLength: number;
  /** wether a complete match or a parial one */
  completeMatch: boolean;
  // FIXME: rename to something that doesnt potentially shadow the global arguments var
  arguments?: Record<string, string>;
  next?: LexMatch;
  completions?: Completion[];
  lex: Lex;
}

export class CommandLex implements Lex {
  public activate = /^\s*\//m;
  public id: string;
  public match: RegExp;
  public incompleteMatch?: RegExp | undefined;
  // public incompleteMatch = /(\/(\w+\s?)?)?/;
  constructor(
    public command: string,
    public description: string,
    public exampleNext: string = '',
    public next: Lex[] = [],
  ) {
    this.id = command + '_command';
    this.match = new RegExp(
      '^[ \\t]*\\/' + command + '(\\s+)' + (next.length ? '' : '?'),
      'm',
    );

    this.incompleteMatch = new RegExp(
      '^[ \\t]*' +
        Array.from('/' + command).reduceRight(
          (p, c) => `${regEscape(c)}(${p})?`,
          next.length ? '[ \\t]*' : '',
        ) +
        '[ \\t]*',
      'm',
    );
  }
  getCompletions(text: string) {
    return this.incompleteMatch!.test(text)
      ? [
          {
            text: '/' + this.command + (this.next.length ? ' ' : ''),
            data: this,
          },
        ]
      : [];
  }
}

/** find the first match */
export function parse(
  text: string,
  lexes: Lex[],
  index: number = 0,
): LexMatch | null {
  let bestMatch: LexMatch | null = null;
  let bestMatchIndex: number = Infinity;

  for (const lex of lexes) {
    const match = lex.match.exec(text);
    if (match && match.index < bestMatchIndex) {
      let result: LexMatch = {
        id: lex.id,
        index: match.index + index,
        completeMatch: true,
        totalLength: match[0].length,
        text: match[0],
        arguments: { ...(match.groups as any) },
        lex,
      };
      if (lex.next?.length) {
        if (result.text !== '') {
          const nextResults = parse(
            text.slice(match.index + match[0].length),
            lex.next,
            index + match.index + match[0].length,
          );
          if (!nextResults) {
            continue;
          }
          result.next = nextResults;
          result.totalLength += nextResults.totalLength;
        }
      }
      bestMatch = result;
      bestMatchIndex = match.index;
    }
  }
  return bestMatch;
}

/** find a partial matches with completions, assuming there are no complete matches */
export function parsePartial(
  text: string,
  lexes: Lex[],
  requiresActivation: boolean = true,
  isNext: boolean = false,
  index: number = 0,
): LexMatch[] {
  const results: LexMatch[] = [];
  for (const lex of lexes) {
    const match = lex.match.exec(text);
    if (match) {
      let result: LexMatch = {
        id: lex.id,
        completeMatch: true,
        index: match.index + index,
        totalLength: match[0].length,
        text: match[0],
        arguments: { ...(lex.match.exec(text)!.groups as any) },
        lex,
      };
      if (lex.next?.length) {
        const nextResults = parsePartial(
          text.slice(match.index + match[0].length),
          lex.next,
          false,
          true,
          index + match.index + match[0].length,
        );
        for (const next of nextResults) {
          results.push({
            ...result,
            next,
            totalLength: match[0].length + next.totalLength,
          });
        }
      } else {
        results.push(result);
      }
    }
  }

  if (results.length === 0) {
    for (const lex of lexes) {
      const incompleteMatch = lex.incompleteMatch?.exec(text);
      if (
        ((isNext && text.length === 0) ||
          !lex.incompleteMatch ||
          (incompleteMatch &&
            incompleteMatch.index + incompleteMatch[0].length ===
              text.length)) &&
        lex.getCompletions &&
        (!requiresActivation || lex.activate?.test(text))
      ) {
        const theText =
          (isNext && text.length === 0) || !lex.incompleteMatch
            ? text
            : incompleteMatch![0];
        results.push({
          id: lex.id,
          index:
            (isNext && text.length === 0) || !lex.incompleteMatch
              ? index
              : index + incompleteMatch!.index,
          totalLength: theText.length,
          text: theText,
          completeMatch: false,
          completions: lex.getCompletions(theText),
          lex,
        });
      }
    }
  }
  return results;
}

/** find all complete matches in a text */
export function parseAll(text: string, lexes: Lex[]): LexMatch[] {
  const matches: LexMatch[] = [];
  let endOfLastMatch = 0;
  let found = false;
  do {
    found = false;
    const result = parse(text.slice(endOfLastMatch), lexes, endOfLastMatch);

    if (result) {
      // result.index += endOfLastMatch;

      found = true;
      endOfLastMatch = result.index + result.totalLength;
      matches.push(result);
    }
  } while (found);
  return matches;
}

/** Find all complete matches in a text and look for parials in the remaining text */
export function parseAllAndPartial(
  text: string,
  lexes: Lex[],
): { matches: LexMatch[]; partial: LexMatch[] } {
  const matches = parseAll(text, lexes);
  const lastMatch = matches.length && findLastNext(matches[matches.length - 1]);
  const endOfLastMatch = lastMatch
    ? lastMatch.index + lastMatch.totalLength
    : 0;

  const partial = parsePartial(
    text.slice(endOfLastMatch),
    lexes,
    true,
    false,
    endOfLastMatch,
  );

  if (lastMatch && partial.length === 0 && endOfLastMatch === text.length) {
    lastMatch.completions = lastMatch.lex.getCompletions?.(lastMatch.text);
  }

  return { matches, partial };
}

const findLastNext = (lex: LexMatch): LexMatch =>
  lex.next ? findLastNext(lex.next) : lex;

export function parseForCompletions(
  text: string,
  lexes: Lex[],
): { value: Completion; lex: LexMatch; completeLex: LexMatch }[] {
  const { matches, partial } = parseAllAndPartial(text, lexes);
  const lastMatch = matches[matches.length - 1];
  const last = partial.length ? partial : lastMatch ? [lastMatch] : [];

  return last
    .map(l => {
      const lex = findLastNext(l);

      return (
        lex.completions?.map(value => ({ value, lex, completeLex: l })) || []
      );
    })
    .flat();
}
