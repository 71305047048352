import classNames from 'classnames';
import intersperse from 'ramda/es/intersperse';
import toPairs from 'ramda/es/toPairs';
import unnest from 'ramda/es/unnest';
import * as React from 'react';
import { Button, FormControl } from 'react-bootstrap';
import DateTime from 'react-datetime';
import {
  injectIntl,
  WrappedComponentProps as InjectedIntlProps,
} from 'react-intl';
import { Dispatch } from 'redux';
import { Link } from 'wouter';
import activityIndicatorImg from '../../assets/loader.gif';
import { parseDate } from '../../common/logic/misc';
import matchUserToTerm from '../../common/user/matchUserToTerm';
import { TicketReference } from '../../models/Meeting';
import { User } from '../../models/User';
import {
  addTicketMessage,
  updateTicket,
  updateTicketMessage,
} from '../../modules/tickets';
import { UserState } from '../../modules/user';
import { UsersState } from '../../modules/users';
import FormattedRelative from '../FormattedRelative';
import InputList from '../InputList';
import StatusBadge from '../StatusBadge';
import { TICKET_FIELDS_PREFIX } from '../TicketDetails/msg';
import UserToken from '../UserToken';
import stl from './TicketReferenceComponent.module.scss';

function findLastMeetingMessageEvent(meetingId: string, events: any[]) {
  let deletedMessages: string[] = [];
  let messageEventId: string;
  let matches = events.filter(event => {
    // if (event.type === 'ADD_MESSAGE') {
    //   messageEventId = event.id;
    // }
    if (event.type === 'DELETE_MESSAGE') {
      deletedMessages.push(event.payload.eventId);
    }
    return (
      (event.type === 'ADD_MESSAGE' || event.type === 'UPDATE_MESSAGE') &&
      event.payload.message &&
      event.meta &&
      event.meta.meetingId === meetingId //||
      // event.payload.eventId === messageEventId)
    );
  });
  matches = matches.filter(
    event =>
      !(
        deletedMessages.includes(event.id) ||
        deletedMessages.includes(event.payload.eventId)
      ),
  );
  return matches[matches.length - 1];
}

const DateTimeAny: any = DateTime;

interface TicketReferenceProps {
  ticketReference: TicketReference;
  tickets: any;
  user: UserState;
  users: UsersState;
  dispatch: Dispatch;
  meetingId: string;
  meetingDate: Date | string | null;
  commentBoxActive: boolean;
  onBlurComment: () => void;
  onFocusComment: () => void;
  onNextComment: () => void;
  onPreviousComment: () => void;
}

interface State {
  editDate: Date | null;
  editAssignee: boolean;
}

const TicketReferenceComponent = injectIntl(
  class extends React.Component<
    TicketReferenceProps & InjectedIntlProps,
    State
  > {
    dateInputRef = React.createRef<HTMLInputElement>();

    messageTextareaRef = React.createRef<HTMLTextAreaElement>();
    constructor(props: TicketReferenceProps & InjectedIntlProps) {
      super(props);
      this.state = { editAssignee: false, editDate: null };
    }

    getTicket() {
      const { ticketId } = this.props.ticketReference;
      return this.props.tickets.find((t: any) => t.id === ticketId);
    }

    editDate = () => {
      this.setState({
        editDate: parseDate(this.getTicket().due || new Date()),
      });
    };
    editAssignee = () => {
      this.setState({ editAssignee: true });
    };

    handleChangeDate = (m: any) => {
      this.setState({ editDate: (m && m.toDate && m.toDate()) || null });
    };
    toggleStatus = () => {
      const ticket = this.getTicket();
      if (!ticket || !this.props.users || !this.props.user) {
        return;
      }
      const { status } = ticket;
      this.props.dispatch(
        // FIXME: propper typing
        (updateTicket as any)(
          ticket.id,
          { status: status === 'opened' ? 'closed' : 'opened' },
          this.props.user.recnum,
          { meetingId: this.props.meetingId },
        ),
      );
    };

    handleDateSubmit = () => {
      const ticket = this.getTicket();
      if (!ticket || !this.props.users || !this.props.user) {
        return;
      }
      if (this.state.editDate && this.props.user) {
        // const event = createTicketEvent(, 'UPDATE_TICKET', );
        this.props.dispatch(
          // FIXME: propper typing
          (updateTicket as any)(
            ticket.id,
            { due: this.state.editDate },
            this.props.user.recnum,
            { meetingId: this.props.meetingId },
          ),
        );
        this.setState({ editDate: null });
      }
    };
    handleSelectAssignee = (user: User) => {
      const ticket = this.getTicket();
      if (!ticket || !this.props.users) {
        return;
      }
      if (this.props.user) {
        // const event = createTicketEvent(, 'UPDATE_TICKET', );
        this.props.dispatch(
          // FIXME: propper typing
          (updateTicket as any)(
            ticket.id,
            { assignee_user_id: user.recnum },
            this.props.user.recnum,
            { meetingId: this.props.meetingId },
          ),
        );
        this.setState({ editAssignee: false });
      }
    };
    handleClearAssignee = () => {};
    handleAssigneeKeyDown = (event: React.KeyboardEvent<FormControl>) => {
      if (event.key === 'Escape') {
        event.preventDefault();
        this.setState({ editAssignee: false });
      }
    };

    handleMessageSubmit = (event?: React.FormEvent, blur: boolean = true) => {
      event?.preventDefault();
      const ticket = this.getTicket();
      if (!ticket || !this.props.user) {
        return;
      }
      if (!this.messageTextareaRef.current) {
        return;
      }
      const value = this.messageTextareaRef.current.value;
      if (value) {
        const meetingMessageEvent = findLastMeetingMessageEvent(
          this.props.meetingId,
          ticket.events,
        );
        if (meetingMessageEvent) {
          if (value !== meetingMessageEvent.payload.message) {
            this.props.dispatch(
              (updateTicketMessage as any)(
                ticket.id,
                meetingMessageEvent.payload.eventId || meetingMessageEvent.id,
                value,
                this.props.user.recnum,
                {
                  meetingId: this.props.meetingId,
                },
              ),
            );
          }
        } else {
          this.props.dispatch(
            (addTicketMessage as any)(
              ticket.id,
              value,
              this.props.user.recnum,
              {
                meetingId: this.props.meetingId,
              },
            ),
          );
        }
      }
      if (blur) {
        this.messageTextareaRef.current.value = '';
        this.props.onBlurComment();
      }
    };
    handleReset = (event: React.FormEvent) => {
      event.preventDefault();
      this.props.onBlurComment();
    };
    handleCommentKeypress = (event: React.KeyboardEvent<FormControl>) => {
      if (event.key === 'Escape') {
        event.preventDefault();
        this.props.onBlurComment();
      } else if (event.key === 'Enter' && event.ctrlKey) {
        event.preventDefault();
        this.handleMessageSubmit();
      } else if (event.key === 'Tab') {
        event.preventDefault();
        this.handleMessageSubmit(undefined, false);
        if (event.shiftKey) {
          this.props.onPreviousComment();
        } else {
          this.props.onNextComment();
        }
      }
    };
    componentDidUpdate(prevProps: TicketReferenceProps, prevState: State) {
      if (
        !prevState.editDate &&
        this.state.editDate &&
        this.dateInputRef.current
      ) {
        this.dateInputRef.current.focus();
      }
      if (!prevProps.commentBoxActive && this.props.commentBoxActive) {
        const ticket = this.getTicket();
        const meetingMessageEvent: any =
          ticket?.events &&
          findLastMeetingMessageEvent(this.props.meetingId, ticket.events);
        if (meetingMessageEvent && this.messageTextareaRef.current) {
          this.messageTextareaRef.current.value =
            meetingMessageEvent.payload.message;
        }
        this.messageTextareaRef.current?.focus();
      }
    }

    render() {
      const { users, commentBoxActive } = this.props;
      const ticket = this.getTicket();
      if (!ticket || !users) {
        return (
          <div className={stl.root} draggable={true}>
            <img alt="activity indicator" src={activityIndicatorImg} />
          </div>
        );
      }
      const { due } = ticket;

      let changes: {
        created: boolean;
        fields: { [key: string]: any };
      } | null = null;
      let changesMessage;
      const meetingTicketEvents = ticket.events.filter(
        (e: any) =>
          ['UPDATE_TICKET', 'CREATE_TICKET'].includes(e.type) &&
          e.meta &&
          e.meta.meetingId === this.props.meetingId,
      );
      if (meetingTicketEvents.length) {
        changes = meetingTicketEvents.reduce(
          (changes: any, event: any) => ({
            created: changes.created || event.type === 'CREATE_TICKET',
            fields: { ...changes.fields, ...event.payload },
            events: [...changes.events, event],
          }),
          { created: false, fields: {}, events: [] },
        );
        if (changes) {
          changesMessage = unnest(
            intersperse(
              [', '],
              toPairs(changes.fields)
                .filter(
                  ([field]) => !['werk_id', 'description'].includes(field),
                )
                .map(([field, value]) => ({
                  field,
                  value:
                    field === 'assignee_user_id' && users[value as string] ? (
                      (users[value as string] as User).name ||
                      (users[value as string] as User).loginname
                    ) : field === 'due' ? (
                      <FormattedRelative value={value} />
                    ) : (
                      (value as string)
                    ),
                  message: this.props.intl
                    .formatMessage({
                      id: TICKET_FIELDS_PREFIX + field.toUpperCase(),
                    })
                    .toLowerCase(),
                }))
                .map(({ message, value }) => [message, ' ', value]),
            ),
          );
        }
      }

      let updates;
      if (changes) {
        updates = changes.created ? (
          <>
            <hr />
            <span>Ticket created during this meeing</span>
          </>
        ) : (
          <>
            <hr />
            <span>Ticket updated: {changesMessage}</span>
          </>
        );
      }

      const meetingMessageEvent =
        ticket?.events &&
        findLastMeetingMessageEvent(this.props.meetingId, ticket.events);

      return (
        <div className={stl.root} draggable={true}>
          <span>
            <StatusBadge
              onClick={this.toggleStatus as any}
              className={stl.status}
              status={ticket.status}
              size={'small'}
            />{' '}
            <Link to={`/tickets/${ticket.id}`}>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a className={stl.number}>#{ticket.number}</a>
            </Link>{' '}
          </span>
          <span className={stl.title}>{ticket.title}</span>
          <span className={classNames(stl.right, stl.comment)}>
            <Button
              className="hidden-print"
              bsSize="xs"
              bsStyle="primary"
              active={this.props.commentBoxActive}
              onClick={
                this.props.commentBoxActive
                  ? this.props.onBlurComment
                  : this.props.onFocusComment
              }
            >
              <i className="fa fa-comment"></i>
            </Button>
          </span>
          <span className={classNames(stl.right, stl.assignee)}>
            {this.state.editAssignee ? (
              <InputList
                items={Object.values(this.props.users as any)}
                name={'users'}
                selectedItems={ticket.assignee ? [ticket.assignee] : []}
                shouldItemRender={matchUserToTerm}
                small={true}
                onKeyDown={this.handleAssigneeKeyDown}
                // tslint:disable-next-line: jsx-no-lambda
                getItemValue={(user: User) => user.loginname}
                onSelectItem={this.handleSelectAssignee}
                onDeselectItem={this.handleClearAssignee}
              />
            ) : ticket.assignee_user_id ? (
              <span onClick={this.editAssignee}>
                <UserToken
                  small={true}
                  withText={true}
                  collapse={false}
                  className={stl.token}
                  user={users[ticket.assignee_user_id] as User}
                />{' '}
                <i className="fa fa-pencil" />
              </span>
            ) : (
              <span onClick={this.editAssignee}>
                <em>no assignee</em> <i className="fa fa-pencil" />
              </span>
            )}
          </span>
          <span className={classNames(stl.right, stl.date)}>
            {// FIXME: Copypaste inheritance from MinuteMetaForm
            this.state.editDate ? (
              <form onSubmit={this.handleDateSubmit}>
                <DateTimeAny
                  // tslint:disable-next-line: jsx-no-lambda
                  renderInput={(props: any) => (
                    <input
                      ref={this.dateInputRef}
                      {...props}
                      className={classNames(props.className, 'input-sm')}
                    />
                  )}
                  timeFormat={false}
                  dateFormat="D MMMM YYYY"
                  onChange={this.handleChangeDate}
                  value={this.state.editDate}
                  onBlur={this.handleDateSubmit}
                />
              </form>
            ) : !due ? (
              <span onClick={this.editDate}>
                <em>no due date</em> <i className="fa fa-pencil" />
              </span>
            ) : (
              <span onClick={this.editDate}>
                <FormattedRelative value={due} time={false} />
                <i className="fa fa-pencil" />
              </span>
            )}
          </span>
          <div className={stl.message}>
            {
              // join updates
              updates
            }
          </div>
          {commentBoxActive ? (
            <div className={stl.messageBox}>
              <hr />
              <form
                onSubmit={this.handleMessageSubmit}
                onReset={this.handleReset}
              >
                <FormControl
                  componentClass="textarea"
                  inputRef={this.messageTextareaRef as any}
                  onKeyDown={this.handleCommentKeypress}
                />
                <button className="btn btn-sm btn-primary" type="submit">
                  submit
                </button>
                <button
                  className="btn btn-sm pull-right btn-default"
                  type="reset"
                >
                  cancel
                </button>
              </form>
            </div>
          ) : (
            meetingMessageEvent && (
              <>
                <hr />
                <span>{meetingMessageEvent?.payload?.message}</span>
              </>
            )
          )}
        </div>
      );
    }
  },
);

export default TicketReferenceComponent;
