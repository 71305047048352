import debounce from 'lodash/debounce';
import { useCallback, useContext, useEffect, useState } from 'react';
import { ReactReduxContext } from 'react-redux';
import { Project } from '../models/Project';
import { fetchProjects } from '../modules/projects';

export default function useProjectSearch(query: string) {
  const [projects, setProjects] = useState<Project[]>([]);
  const {
    store: { dispatch },
  } = useContext(ReactReduxContext);

  const fetchResults = useCallback(
    // wait for user to stop typing before querying backend
    debounce(async (q: string) => {
      // minimum 3 character search query
      if (q.length < 3) {
        return;
      }
      const result = await dispatch(fetchProjects({ q }));

      setProjects(result.payload);
    }, 400),
    [dispatch],
  );

  useEffect(() => {
    fetchResults(query);
  }, [fetchResults, query]);

  return projects;
}
