import {
  Button,
  ControlLabel,
  FormControl,
  FormGroup,
  HelpBlock,
  InputGroup,
} from 'react-bootstrap';

import * as React from 'react';
import styles from './PropertyPanel.module.scss';

export interface PropertyInputProps
  extends Pick<
    FormControl['props'],
    'label' | 'type' | 'componentClass' | 'onChange'
  > {
  name: string;
  help?: string;
  field?: any;
  unlocked?: string[];
  toggleLocked: (name: string) => void;
  options: Array<{ key: string; children: string; value: string }>;
}

export const PropertyInput: React.FC<PropertyInputProps> = ({
  name,
  label,
  help,
  type = 'text',
  componentClass = 'input',
  onChange,
  field,
  unlocked = [],
  toggleLocked,
  options,
}) => {
  const fromProto = field && field.protoChain.length > 0;
  const modifiable =
    unlocked.includes(name) ||
    (fromProto && field && field.modifiedFields.includes(name));
  const lockable = fromProto && field && !field.modifiedFields.includes(name);
  let content;
  let optionElements;
  if (options) {
    optionElements = options.map(({ key, ...option }) => (
      <option key={key} {...option} />
    ));
  }
  if (fromProto) {
    if (modifiable) {
      content = (
        <InputGroup>
          <InputGroup.Button>
            <Button
              className={styles.linked}
              onClick={toggleLocked.bind(undefined, name)}
              disabled={!lockable}
            >
              <i className="fa" />
            </Button>
          </InputGroup.Button>
          <FormControl
            disabled={!field}
            name={name}
            type={type}
            componentClass={componentClass}
            value={(field && field[name]) || ''}
            onChange={onChange}
            rows={1}
          >
            {optionElements}
          </FormControl>
        </InputGroup>
      );
    } else {
      content = (
        <InputGroup>
          <InputGroup.Button>
            <Button
              className={styles.unlinked}
              onClick={toggleLocked.bind(undefined, name)}
            >
              <i className={`fa`} />
            </Button>
          </InputGroup.Button>
          <FormControl
            disabled={true}
            name={name}
            type={type}
            componentClass={componentClass}
            value={(field && field[name]) || ''}
            onChange={onChange}
            rows={1}
          >
            {optionElements}
          </FormControl>
        </InputGroup>
      );
    }
  } else {
    content = (
      <FormControl
        disabled={!field}
        name={name}
        type={type}
        componentClass={componentClass}
        value={(field && field[name]) || ''}
        onChange={onChange}
        rows={1}
      >
        {optionElements}
      </FormControl>
    );
  }
  return (
    <FormGroup>
      <ControlLabel>{label}</ControlLabel>
      {content}
      {help && <HelpBlock>{help}</HelpBlock>}
    </FormGroup>
  );
};
