export interface ToggleShowListDetailsAction {
  type: 'TOGGLE_SHOW_LIST_DETAILS_DETAILS';
}

export const toggleShowListDetails = (): ToggleShowListDetailsAction => ({
  type: 'TOGGLE_SHOW_LIST_DETAILS_DETAILS',
});

export interface HideDepartmentWarningAction {
  type: 'HIDE_DEPARTMENT_WARNING';
}

export const hideDepartmentWarning = (): HideDepartmentWarningAction => ({
  type: 'HIDE_DEPARTMENT_WARNING',
});

interface SetSyncCatalogsAction {
  type: 'SET_SYNC_CATALOGS';
  payload: boolean;
}
export const setSyncCatalogs = (
  syncCatalogs: boolean,
): SetSyncCatalogsAction => ({
  type: 'SET_SYNC_CATALOGS',
  payload: syncCatalogs,
});

export interface UIState {
  showListDetails: boolean;
  departmentWarningHidden: boolean;
  syncCatalogs: boolean;
}

const DEFAULT_STATE: UIState = {
  showListDetails: true,
  departmentWarningHidden: false,
  syncCatalogs: false,
};

export type UIAction =
  | ToggleShowListDetailsAction
  | SetSyncCatalogsAction
  | HideDepartmentWarningAction;

export default function ui(
  state: UIState = DEFAULT_STATE,
  action: UIAction,
): UIState {
  if (action.type === 'TOGGLE_SHOW_LIST_DETAILS_DETAILS') {
    return { ...state, showListDetails: !state.showListDetails };
  } else if (action.type === 'HIDE_DEPARTMENT_WARNING') {
    return { ...state, departmentWarningHidden: true };
  } else if (action.type === 'SET_SYNC_CATALOGS') {
    const syncCatalogs = action.payload;
    return {
      ...state,
      syncCatalogs,
    };
  }

  return state;
}
